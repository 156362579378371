import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Trans } from "react-i18next";
import Styles from "./UnAuthorized.styles";
import secureSync from "src/assets/images/secure_sync.png";
import LockIcon from "src/components/Icons/LockIcon/LockIcon";
import { routePaths } from "src/constants/routeConstants/routeConstants";

export const UnAuthorized = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const onClick = async () => {
        await oktaAuth.signOut();
        navigate(routePaths.signIn);
    };

    return (
        <Grid container justifyContent="center" sx={Styles.container}>
            <Grid item xs={8}>
                <Box sx={Styles.logoSection}>
                    <img loading="lazy" src={secureSync} alt="Company Logo" style={Styles.companyLogo} />
                </Box>
                <Box sx={Styles.iconSection}>
                    <LockIcon fill={theme.palette.im_grey[100]} width={180} height={180} />
                </Box>
                <Typography variant="h4" sx={Styles.titleSection}>
                    <Trans i18nKey={"unAuthPage.accessRestricted"} />
                </Typography>
                <Typography component="p" sx={Styles.textContent}>
                    <Trans i18nKey={"unAuthPage.textContent"} />
                </Typography>
                <Box sx={Styles.btnArea}>
                    <Button variant="contained" color="primary" sx={Styles.HomeBtn} onClick={onClick}>
                        <Trans i18nKey={"buttonLabels.goHome"} />
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
