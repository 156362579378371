/* eslint-disable max-lines */
import { Box, Button, Divider, Switch, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import styles from "./VaultStatusReports.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import AccountSummary from "src/components/Operations/Research/VaultStatusReports/AccountSummary/AccountSummary";
import OpenMediaDiscrepancySummary from "src/components/Operations/Research/VaultStatusReports/OpenMediaDiscrepancySummary/OpenMediaDiscrepancySummary";
import OpenMediaOtherExceptions from "src/components/Operations/Research/VaultStatusReports/OpenMediaOtherExceptions/OpenMediaOtherExceptions";
import ContainerDiscrepancySummary from "src/components/Operations/Research/VaultStatusReports/ContainerDiscrepancySummary/ContainerDiscrepancySummary";
import CustomerListDropdown from "src/components/AppComponents/Header/CustomerListDropdown/CustomerListDropdown";
import {
    getExportSelectedExceptions,
    getOperationServiceReference,
    getOtherOpenMediaExceptionData,
    getVaultStatusReportData,
} from "src/services/researchService";
import {
    datesSameComparator,
    endOfTheDate,
    formatDate,
    getCurrentDateTime,
    getDisplayFormat,
    startOfTheDate,
} from "src/utils/dateHelper";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import { retrieveGlobalAttribute } from "src/services/homeService";
import { filterData } from "src/utils/commonHelper";
import {
    allOption,
    exportSearchAttributeNames,
    getAllCaps,
    getAllValue,
    maxIntegerValue,
    mediaOptions,
    mediaValues,
    otherExceptionHelperHeader,
    sortCriteriaResearch,
} from "src/constants/researchConstants";
import SecureSyncMultiSelect from "src/components/common/SecureSyncMultiSelect/SecureSyncMultiSelect";
import { getAllowedCustomers, getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import {
    generateCSV,
    generateOtherMediaExceptionFileName,
    getDistinctValues,
    getFileSectionTypeIdList,
    getSystemFunctionIdList,
    getDetectedInTranslatedArray,
    getDiscrepancyTypeTranslatedArray,
    detectedInDiscrepancySort,
} from "src/utils/researchHelper";
import { multipleSort } from "src/utils/processMediaHelper";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { programIds } from "src/constants/programIdsConstants";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { vaultStatusReportPayload } from "src/services/printServices";
import { noFlag, yesFlag } from "src/constants/serviceConstants";

const validationSchema = ({ t }) => {
    const maxDate = dayjs(getCurrentDateTime());
    const minDate = dayjs(getCurrentDateTime()).subtract(1, dateTimeUnits.year).startOf(dateTimeUnits.day);

    const displayMaxDate = formatDate(maxDate);
    const displayMinDate = formatDate(minDate);
    return Yup.object().shape({
        reportDateFrom: Yup.string().test("validateStartDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isToDateValid = dayjs(parent.reportDateTo).isValid();
            const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                dayjs(parent.reportDateTo, getDisplayFormat())
            );
            const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(
                dayjs(displayMinDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: parent.reportDateTo,
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && !parent.reportDateTo) {
                message = t("messages.fromDateRequired");
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        reportDateTo: Yup.string().test("validateEndDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(
                dayjs(displayMaxDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && !parent.reportDateFrom) {
                message = t("messages.toDateRequired");
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const initialValues = {
    reportDateFrom: "",
    reportDateTo: "",
    customer: getAllValue,
    media: getAllValue,
    discrepancyType: getAllValue,
    detectedIn: [],
    includeOMOtherExceptions: true,
};

const VaultStatusReports = () => {
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const customerLists = useSelector(getAllowedCustomers);
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);

    const [isLoading, setLoading] = useState(false);
    const [discrepancyDropdownValues, setDiscrepancyDropdownValues] = useState({
        containerDetected: [],
        openMediaDetected: [],
        containerDiscrepancyType: [],
        openMediaDiscrepancyType: [],
    });
    const [globalMediaAttribute, setGlobalMediaAttribute] = useState({ globalMediaAt: [] });
    const [vaultStatusData, setVaultStatusData] = useState({});
    const [otherOpenMediaExceptions, setOtherOpenMediaExceptions] = useState([]);
    const [errorAlert, setErrorAlert] = useState("");
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalShow, setPrintModalShow] = useState(false);

    const viewPermission = isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_329);

    useEffect(() => {
        const restoreSearchResults = async () => {
            setLoading(true);
            const [operationServiceRef, retrievedAttribute] = await Promise.all([
                getOperationServiceReference({ modeId: "3" }),
                retrieveGlobalAttribute({}),
            ]);

            if (!operationServiceRef.failure) {
                const formattedObj = {
                    containerDetected: multipleSort(
                        getDetectedInTranslatedArray(operationServiceRef.containerDetected),
                        sortCriteriaResearch.formattedNameSort
                    ),
                    openMediaDetected: multipleSort(
                        getDetectedInTranslatedArray(operationServiceRef.openMediaDetected),
                        sortCriteriaResearch.formattedNameSort
                    ),
                    containerDiscrepancyType: multipleSort(
                        getDiscrepancyTypeTranslatedArray(operationServiceRef.containerDiscrepancyType, "container"),
                        sortCriteriaResearch.formattedNameSort
                    ),
                    openMediaDiscrepancyType: multipleSort(
                        getDiscrepancyTypeTranslatedArray(operationServiceRef.openMediaDiscrepancyType, "openMedia"),
                        sortCriteriaResearch.formattedNameSort
                    ),
                };
                setDiscrepancyDropdownValues(formattedObj);
            }

            if (!retrievedAttribute.failure) {
                setGlobalMediaAttribute(retrievedAttribute);
            }

            const searchMaxDisplay = filterData(
                retrievedAttribute.globalMediaAt,
                "global_attribute_type_descr",
                exportSearchAttributeNames.searchMaxDisplay
            );

            let newFormValues = {
                reportDateFrom: formatDate(dateRange.maxDate),
                reportDateTo: formatDate(dateRange.maxDate),
                customer: getAllValue,
                media: getAllValue,
                discrepancyType: getAllValue,
                detectedIn: [],
                includeOMOtherExceptions: true,
            };

            if (locationState) {
                newFormValues = {
                    reportDateFrom: locationState?.reportDateFrom || initialValues.reportDateFrom,
                    reportDateTo: locationState?.reportDateTo || initialValues.reportDateFrom,
                    customer: locationState?.customer || initialValues.customer,
                    media: locationState?.media || initialValues.media,
                    discrepancyType: locationState?.discrepancyType || initialValues.discrepancyType,
                    detectedIn: locationState?.detectedIn ? JSON.parse(locationState?.detectedIn) : [],
                    includeOMOtherExceptions:
                        locationState?.includeOMOtherExceptions || initialValues.includeOMOtherExceptions,
                };
                await onFind(newFormValues, parseInt(searchMaxDisplay[0]?.global_attribute_value));
            }
            await setValues(newFormValues);
            setLoading(false);
        };

        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        errors,
        handleSubmit,
        handleChange,
        touched,
        values,
        setValues,
        setFieldTouched,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema({ t }),
        onSubmit: async (values) => await onFind(values, searchMaxDisplay),
    });

    const dateRange = useMemo(() => {
        return {
            maxDate: dayjs(getCurrentDateTime()),
            minDate: dayjs(getCurrentDateTime()).subtract(1, dateTimeUnits.year),
        };
    }, []);

    const detectedInAndDiscrepancyArray = useMemo(() => {
        let detectedInArray,
            discrepancyArray = [];

        const containerDetected = discrepancyDropdownValues.containerDetected.sort(detectedInDiscrepancySort);
        const openMediaDetected = discrepancyDropdownValues.openMediaDetected.sort(detectedInDiscrepancySort);

        const containerDiscrepancyType =
            discrepancyDropdownValues.containerDiscrepancyType.sort(detectedInDiscrepancySort);
        const openMediaDiscrepancyType =
            discrepancyDropdownValues.openMediaDiscrepancyType.sort(detectedInDiscrepancySort);

        if (values.media === getAllValue) {
            detectedInArray = [...containerDetected, ...openMediaDetected];
            discrepancyArray = [...containerDiscrepancyType, ...openMediaDiscrepancyType];
        } else if (values.media === mediaValues.container) {
            detectedInArray = [...containerDetected];
            discrepancyArray = [...containerDiscrepancyType];
        } else if (values.media === mediaValues.openMedia) {
            detectedInArray = [...openMediaDetected];
            discrepancyArray = [...openMediaDiscrepancyType];
        }
        return {
            detectedInArray,
            discrepancyArray,
        };
    }, [
        discrepancyDropdownValues.containerDetected,
        discrepancyDropdownValues.containerDiscrepancyType,
        discrepancyDropdownValues.openMediaDetected,
        discrepancyDropdownValues.openMediaDiscrepancyType,
        values.media,
    ]);

    const detectedInDropdown = useMemo(() => {
        const uniqueArr = Array.from(
            new Set(
                detectedInAndDiscrepancyArray.detectedInArray.map((item) => item.translatedName) // Extracting unique formatted names
            )
        ).map((uniqueName) => uniqueName);

        setFieldValue("detectedIn", [...uniqueArr]);
        return [...uniqueArr];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detectedInAndDiscrepancyArray]);

    const discrepancyDropdown = useMemo(() => {
        const uniqueArr = Array.from(
            new Set(detectedInAndDiscrepancyArray.discrepancyArray.map((item) => item.translatedName)) // Extracting unique formatted names
        ).map((uniqueName, index) => {
            const foundItem = detectedInAndDiscrepancyArray.discrepancyArray.find(
                (item) => item.translatedName === uniqueName
            );
            return {
                id: index,
                label: foundItem.translatedName,
                value: `${index}-${foundItem.uniqueid}`,
            };
        });

        setFieldValue("discrepancyType", allOption().value);
        return [allOption(), ...uniqueArr];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detectedInAndDiscrepancyArray.discrepancyArray]);

    const { searchMaxDisplay, exportMaxRecords } = useMemo(() => {
        const searchMaxDisplay = filterData(
            globalMediaAttribute.globalMediaAt,
            "global_attribute_type_descr",
            exportSearchAttributeNames.searchMaxDisplay
        );
        const exportMaxRecords = filterData(
            globalMediaAttribute.globalMediaAt,
            "global_attribute_type_descr",
            exportSearchAttributeNames.exportMaxRecords
        );

        return {
            searchMaxDisplay: parseInt(searchMaxDisplay[0]?.global_attribute_value) || 0,
            exportMaxRecords: parseInt(exportMaxRecords[0]?.global_attribute_value) || 0,
        };
    }, [globalMediaAttribute.globalMediaAt]);

    const selectedCustomerList = useMemo(() => {
        if (values.customer && values.customer === getAllValue) {
            return customerLists.map((customer) => customer.customer_id).join(",");
        }
        return values.customer;
    }, [customerLists, values.customer]);

    const discrepancyTypeList = useMemo(() => {
        const discrepancyList = [
            ...discrepancyDropdownValues.openMediaDiscrepancyType,
            ...discrepancyDropdownValues.containerDiscrepancyType,
        ];

        const selectedDiscrepancy = filterData(discrepancyDropdown, "value", values.discrepancyType);
        return filterData(discrepancyList, "translatedName", selectedDiscrepancy[0].label);
    }, [
        discrepancyDropdownValues.openMediaDiscrepancyType,
        discrepancyDropdownValues.containerDiscrepancyType,
        discrepancyDropdown,
        values.discrepancyType,
    ]);

    const detectedInList = useMemo(() => {
        if (values?.detectedIn?.length > 0) {
            return values.detectedIn
                .map((element) => filterData(detectedInAndDiscrepancyArray.detectedInArray, "translatedName", element))
                .flat();
        }
        return [];
    }, [values.detectedIn, detectedInAndDiscrepancyArray.detectedInArray]);

    const { exportBtnDisable, printBtnDisable } = useMemo(() => {
        let exportBtnDisable = true,
            printBtnDisable = true;

        if (
            vaultStatusData?.accountSummary?.length > 0 ||
            vaultStatusData?.openMediaDiscrepancySummary?.length > 0 ||
            otherOpenMediaExceptions.length > 0 ||
            vaultStatusData?.containerDiscrepancySummary?.length > 0
        ) {
            printBtnDisable = false;
        }

        if (otherOpenMediaExceptions.length > 0) {
            exportBtnDisable = false;
        }

        return { exportBtnDisable, printBtnDisable };
    }, [
        otherOpenMediaExceptions,
        vaultStatusData?.accountSummary,
        vaultStatusData?.containerDiscrepancySummary,
        vaultStatusData?.openMediaDiscrepancySummary,
    ]);

    const clearTableState = () => {
        setVaultStatusData({});
        setOtherOpenMediaExceptions([]);
    };

    const onChangeIncludeOMException = (e) => {
        setFieldValue("includeOMOtherExceptions", e.target.checked);
        clearTableState();
    };

    const onResetHandler = () => {
        const newFormValues = {
            ...initialValues,
            reportDateFrom: formatDate(dateRange.maxDate),
            reportDateTo: formatDate(dateRange.maxDate),
        };
        setValues(newFormValues);
        clearTableState();
        setErrorAlert("");
        setErrors({});
    };

    const onFind = async (data, maxDisplay) => {
        setLoading(true);
        setErrorAlert("");
        const detectedInListValues = values.detectedIn.length === detectedInDropdown.length ? null : detectedInList;
        const systemFunctionIdList = await getSystemFunctionIdList(
            data.media,
            detectedInListValues,
            discrepancyTypeList
        );
        const fileSectionTypeIdList = await getFileSectionTypeIdList(
            data.media,
            detectedInListValues,
            discrepancyTypeList
        );
        const inputObj = {
            customerIdList: selectedCustomerList,
            startDate: startOfTheDate(data.reportDateFrom),
            endDate: endOfTheDate(data.reportDateTo),
            targetMediaType: data.media,
            fileSectionTypeIdList: fileSectionTypeIdList,
            systemFunctionIdList: systemFunctionIdList,
            discrepancyTypeCode: data.discrepancyType === getAllValue ? null : data.discrepancyType.split("-")[1],
        };

        const isIncludeOtherOMException = data.includeOMOtherExceptions;
        const vaultDataResult = await getVaultStatusReportData(inputObj);
        if (!vaultDataResult.failure) {
            let otherOpenMediaExceptionResults = {};
            if (isIncludeOtherOMException) {
                otherOpenMediaExceptionResults = await getOtherOpenMediaExceptionData(inputObj);
            }

            if (
                vaultDataResult.openMediaDiscrepancySummary.length > maxDisplay ||
                vaultDataResult.containerDiscrepancySummary.length > maxDisplay ||
                vaultDataResult.accountSummary.length > maxDisplay ||
                (isIncludeOtherOMException &&
                    otherOpenMediaExceptionResults.otherOpenMediaExceptions.length > maxDisplay)
            ) {
                setErrorAlert(
                    t("messages.recordMoreThanSearchMaxDisplay", {
                        searchMaxDisplay: maxDisplay,
                    })
                );
                return;
            } else {
                if (isIncludeOtherOMException && !otherOpenMediaExceptionResults.failure) {
                    if (
                        otherOpenMediaExceptionResults.otherOpenMediaExceptions.length > 0 &&
                        !("severity" in otherOpenMediaExceptionResults.otherOpenMediaExceptions[0])
                    ) {
                        const sortedArray = multipleSort(
                            otherOpenMediaExceptionResults.otherOpenMediaExceptions.map((value) => {
                                // eslint-disable-next-line camelcase
                                return { ...value, sequence_id: parseInt(value.sequence_id) };
                            }),
                            sortCriteriaResearch.sortBySequenceId
                        );
                        setOtherOpenMediaExceptions(sortedArray);
                    }
                }
                const sortedData = {
                    openMediaDiscrepancySummary: multipleSort(
                        vaultDataResult.openMediaDiscrepancySummary.map((value) => {
                            // eslint-disable-next-line camelcase
                            return { ...value, sequence_id: parseInt(value.sequence_id) };
                        }),
                        sortCriteriaResearch.sortBySequenceId
                    ),
                    containerDiscrepancySummary: multipleSort(
                        vaultDataResult.containerDiscrepancySummary,
                        sortCriteriaResearch.containerDiscrepancySort
                    ),
                    accountSummary: multipleSort(
                        vaultDataResult.accountSummary,
                        sortCriteriaResearch.accountSummarySort
                    ),
                };
                setVaultStatusData(sortedData);
                const pageState = getPageState(
                    {
                        ...data,
                        reportDateFrom: data.reportDateFrom,
                        reportDateTo: data.reportDateTo,
                        customer: data.customer,
                        media: data.media,
                        discrepancyType: data.discrepancyType,
                        detectedIn: JSON.stringify(data.detectedIn),
                        includeOMOtherExceptions: data.includeOMOtherExceptions,
                    },
                    true
                );
                navigate(vaultStatusReport, {
                    state: pageState,
                    replace: true,
                });
            }
        }

        setLoading(false);
    };

    const onClickExportException = async () => {
        setLoading(true);
        setErrorAlert("");
        if (otherOpenMediaExceptions.length > 0) {
            if (otherOpenMediaExceptions.length > exportMaxRecords) {
                setErrorAlert(
                    t("apiErrorMessages.80275", {
                        exportMaxRecords: exportMaxRecords,
                    })
                );
            } else {
                const payload = {
                    customerIdList: getDistinctValues(otherOpenMediaExceptions, "customer_id"),
                    fileSectionTypeIdList: getDistinctValues(otherOpenMediaExceptions, "detected_in_fst_id"),
                    exceptionTypeList: getDistinctValues(otherOpenMediaExceptions, "exception_type"),
                    fromDate: startOfTheDate(values.reportDateFrom),
                    toDate: endOfTheDate(values.reportDateTo),
                    maxSearchResults: datesSameComparator(values.reportDateFrom, values.reportDateTo)
                        ? maxIntegerValue
                        : exportMaxRecords.toString(),
                };
                const result = await getExportSelectedExceptions(payload);
                if (result.failure || result?.otherOpenMediaExceptions[0]?.error) {
                    setErrorAlert(
                        t("apiErrorMessages.80275", {
                            exportMaxRecords: datesSameComparator(values.reportDateFrom, values.reportDateTo)
                                ? maxIntegerValue
                                : exportMaxRecords.toString(),
                        })
                    );
                } else {
                    const csvData = result.otherOpenMediaExceptions.map((value) => {
                        const detectedIn = detectedInAndDiscrepancyArray.detectedInArray.filter(
                            (data) => data.id === parseInt(value.file_section_type_id)
                        );
                        return {
                            number: value.customer_number,
                            name: value.customer_name.trim(),
                            detectedIn: detectedIn[0].translatedName || "",
                            exception: value.exception_type,
                            media: value.mediaid,
                            detectedDate: formatDate(value.detected_datetime, dateFormats.localized24HAndSecFormat),
                            task: "",
                            logicalVault: value.logical_vault.trim(),
                            requestType: value.request_type_id ? t(`requestTypes.${value.request_type_id}`) : "",
                            serviceDate: value.service_date ? formatDate(value.service_date) : "",
                            comment: value.additionalinformation.trim(),
                            carID: value.carid,
                        };
                    });
                    await generateCSV(csvData, otherExceptionHelperHeader, generateOtherMediaExceptionFileName());
                }
            }
        }
        setLoading(false);
    };

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            //Account summary table
            newNewPageState.accountSummaryPageNumber = locationState?.accountSummaryPageNumber;
            newNewPageState.accountSummaryRowsPerPage = locationState?.accountSummaryRowsPerPage;
            newNewPageState.accountSummaryOrderBy = locationState?.accountSummaryOrderBy;
            newNewPageState.accountSummaryOrder = locationState?.accountSummaryOrder;
            //OpenMediaDiscrepancySummary Table
            newNewPageState.openMediaDiscrepancySummaryPageNumber =
                locationState?.openMediaDiscrepancySummaryPageNumber;
            newNewPageState.openMediaDiscrepancySummaryRowsPerPage =
                locationState?.openMediaDiscrepancySummaryRowsPerPage;
            newNewPageState.openMediaDiscrepancySummaryOrderBy = locationState?.openMediaDiscrepancySummaryOrderBy;
            newNewPageState.openMediaDiscrepancySummaryOrder = locationState?.openMediaDiscrepancySummaryOrder;
            //OpenMediaOtherExceptions Table
            newNewPageState.openMediaOtherExceptionsPageNumber = locationState?.openMediaOtherExceptionsPageNumber;
            newNewPageState.openMediaOtherExceptionsRowsPerPage = locationState?.openMediaOtherExceptionsRowsPerPage;
            newNewPageState.openMediaOtherExceptionsOrderBy = locationState?.openMediaOtherExceptionsOrderBy;
            newNewPageState.openMediaOtherExceptionsOrder = locationState?.openMediaOtherExceptionsOrder;
            // ContainerDiscrepancySummary Table
            newNewPageState.containerDiscrepancySummaryPageNumber =
                locationState?.containerDiscrepancySummaryPageNumber;
            newNewPageState.containerDiscrepancySummaryRowsPerPage =
                locationState?.containerDiscrepancySummaryRowsPerPage;
            newNewPageState.containerDiscrepancySummaryOrderBy = locationState?.containerDiscrepancySummaryOrderBy;
            newNewPageState.containerDiscrepancySummaryOrder = locationState?.containerDiscrepancySummaryOrder;
        }
        return newNewPageState;
    };

    const printReport = async () => {
        const detectedInListValues = values.detectedIn.length === detectedInDropdown.length ? null : detectedInList;
        const detectedInListCodes =
            values.detectedIn.length === detectedInDropdown.length
                ? getAllCaps
                : values.detectedIn.map((element) => element).join(", ");
        const systemFunctionIdList = await getSystemFunctionIdList(
            values.media,
            detectedInListValues,
            discrepancyTypeList
        );
        const fileSectionTypeIdList = await getFileSectionTypeIdList(
            values.media,
            detectedInListValues,
            discrepancyTypeList
        );

        const containerDetected = discrepancyDropdownValues.containerDetected
            .sort(detectedInDiscrepancySort)
            .map((element) => {
                return { id: element?.id, description: element?.formattedname };
            });
        const openMediaDetected = discrepancyDropdownValues.openMediaDetected
            .sort(detectedInDiscrepancySort)
            .map((element) => {
                return { id: element?.id, description: element?.formattedname };
            });

        let selectedCustomerDisplayName = getAllCaps;
        if (values.customer !== getAllValue) {
            const selectedCustomerObj = customerLists.find((e) => e.customer_id === values.customer);
            selectedCustomerDisplayName = selectedCustomerObj?.customer_number
                ? selectedCustomerObj.customer_number
                : getAllCaps;
        }
        const payload = vaultStatusReportPayload({
            customer: selectedCustomerDisplayName,
            customerIdList: selectedCustomerList,
            detectedIn: detectedInListCodes,
            fileSectionTypeIdList: fileSectionTypeIdList,
            systemFunctionIdList: systemFunctionIdList,
            targetMediaType: values.media === getAllValue ? getAllCaps : values.media,
            discrepancyType: values.discrepancyType === getAllValue ? getAllCaps : values.discrepancyType.split("-")[1],
            includeOtherOpenMediaException: values.includeOMOtherExceptions ? yesFlag : noFlag,
            endDate: endOfTheDate(values.reportDateTo),
            startDate: startOfTheDate(values.reportDateFrom),
            openMediaDetectedInCollection: openMediaDetected,
            containerDetectedInCollection: containerDetected,
        });

        setPrintPayload(payload);
        setPrintModalShow(true);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <PageTitle>
                <Trans i18nKey={"titles.vaultStatusReport"} />
            </PageTitle>
            {errorAlert && (
                <SecureSyncAlert
                    severity="error"
                    message={errorAlert}
                    onClose={() => {
                        setErrorAlert("");
                    }}
                />
            )}
            <SecureSyncAlert message={<Trans i18nKey={"vaultStatusReport.headerInfo"} />} />
            <form onSubmit={handleSubmit}>
                <Box sx={styles.searchFields}>
                    <SecureSyncDatePicker
                        name="reportDateFrom"
                        value={values.reportDateFrom}
                        label={t("formFields.reportDateFrom")}
                        helperText={touched.reportDateFrom && errors.reportDateFrom}
                        error={Boolean(touched.reportDateFrom && errors.reportDateFrom)}
                        onChange={async (value) => {
                            await setFieldValue("reportDateFrom", value);
                            setFieldTouched("reportDateFrom", true, true);
                            clearTableState();
                        }}
                        minDate={dateRange.minDate}
                        maxDate={dateRange.maxDate}
                    />
                    <SecureSyncDatePicker
                        name="reportDateTo"
                        value={values.reportDateTo}
                        disableFuture
                        label={t("formFields.reportDateTo")}
                        helperText={touched.reportDateTo && errors.reportDateTo}
                        error={Boolean(touched.reportDateTo && errors.reportDateTo)}
                        onChange={async (value) => {
                            await setFieldValue("reportDateTo", value);
                            setFieldTouched("reportDateTo", true, true);
                            clearTableState();
                        }}
                        minDate={dateRange.minDate}
                        maxDate={dateRange.maxDate}
                    />
                </Box>
                <Box sx={styles.searchFields}>
                    <Box sx={styles.gridSpan2}>
                        <CustomerListDropdown
                            id="login-page"
                            label={t("formFields.customer")}
                            name="customer"
                            defaultOption={t("common.all")}
                            type="outlined"
                            enableAll
                            value={values.customer}
                            onChange={(e) => {
                                handleChange(e);
                                clearTableState();
                            }}
                        />
                    </Box>
                    <SecureSyncSelect
                        value={values.media}
                        label={t("formFields.media")}
                        defaultOption={t("common.all")}
                        options={mediaOptions}
                        name="media"
                        onChange={(e) => {
                            handleChange(e);
                            clearTableState();
                        }}
                    />
                </Box>
                <Divider />
                <PageSubTitle>
                    <Trans i18nKey={"titles.discrepancySummary"} />
                </PageSubTitle>
                <Box sx={styles.searchFields}>
                    <SecureSyncMultiSelect
                        label={t("formFields.detectedIn")}
                        options={detectedInDropdown}
                        name="detectedIn"
                        value={values.detectedIn}
                        onChange={(e) => {
                            setFieldValue("detectedIn", e.target.value);
                            clearTableState();
                        }}
                        defaultSelectAll
                    />
                    <SecureSyncSelect
                        value={values.discrepancyType}
                        label={t("formFields.discrepancyType")}
                        options={discrepancyDropdown}
                        name="discrepancyType"
                        onChange={(e) => {
                            setFieldValue("discrepancyType", e.target.value);
                            clearTableState();
                        }}
                        defaultOption={t("common.all")}
                    />
                    <Box sx={styles.locksFieldWrapper}>
                        <Switch
                            checked={values.includeOMOtherExceptions}
                            name="includeOMOtherExceptions"
                            onChange={onChangeIncludeOMException}
                        />
                        <Box sx={styles.locksLabel}>
                            <Trans i18nKey={"formFields.includeOtherOMExceptions"} />
                        </Box>
                        <Typography sx={styles.locksCaption} variant="caption">
                            <Trans i18nKey={"newContainerLockInsert.locksCaption"} />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.footerButtons}>
                    <Button variant="outlined" onClick={onResetHandler}>
                        <Trans i18nKey={"buttonLabels.clear"} />
                    </Button>
                    <Button variant="contained" type="submit">
                        <Trans i18nKey={"buttonLabels.find"} />
                    </Button>
                    <Button variant="contained" disabled={printBtnDisable} onClick={printReport}>
                        <Trans i18nKey={"buttonLabels.print"} />
                    </Button>
                    <Button variant="contained" disabled={exportBtnDisable} onClick={onClickExportException}>
                        <Trans i18nKey={"buttonLabels.exportExceptions"} />
                    </Button>
                </Box>
            </form>

            <Box sx={styles.accordionSection}>
                <AccountSummary rows={vaultStatusData?.accountSummary || []} />
                <Divider />
                {values.media === mediaValues.container ? (
                    ""
                ) : (
                    <>
                        <OpenMediaDiscrepancySummary
                            rows={vaultStatusData?.openMediaDiscrepancySummary || []}
                            detectedInDataList={detectedInAndDiscrepancyArray?.detectedInArray}
                            dateRange={{ fromDate: values.reportDateFrom, toDate: values.reportDateTo }}
                            viewPermission={viewPermission}
                        />
                        <Divider />
                    </>
                )}
                {values.includeOMOtherExceptions ? (
                    <>
                        <OpenMediaOtherExceptions
                            dateRange={{ fromDate: values.reportDateFrom, toDate: values.reportDateTo }}
                            rows={otherOpenMediaExceptions || []}
                            detectedInDataList={detectedInAndDiscrepancyArray?.detectedInArray}
                            setErrorAlert={setErrorAlert}
                            searchMaxDisplay={searchMaxDisplay}
                            viewPermission={viewPermission}
                        />
                        <Divider />
                    </>
                ) : (
                    ""
                )}
                {values.media === mediaValues.openMedia ? (
                    ""
                ) : (
                    <ContainerDiscrepancySummary
                        rows={vaultStatusData?.containerDiscrepancySummary || []}
                        detectedInDataList={detectedInAndDiscrepancyArray?.detectedInArray}
                        dateRange={{ fromDate: values.reportDateFrom, toDate: values.reportDateTo }}
                        viewPermission={viewPermission}
                    />
                )}
            </Box>
            <SecureSyncPrintModal
                open={printModalShow}
                payload={printPayload}
                onClose={() => setPrintModalShow(false)}
                targetReport={targetUrl.vaultStatusReport}
            />
        </>
    );
};

export default VaultStatusReports;
