const styles = {
    box: {
        px: { xs: 3, sm: 5 },
        paddingBottom: "20px",
    },
    boxShortage: {
        padding: "0px 15px 0px 12px",
    },
    listItem: {
        color: "grey.900",
        fontSize: "16px",
        fontWeight: 400,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    title: {
        color: "im_dark_blue.500",
        fontSize: "26px",
        fontWeight: 400,
        lineHeight: "123.5%",
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    description: {
        color: "im_grey.900",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    linkContainer: {
        display: "flex",
        alignItems: "center",
        color: "info.main",
        mb: 1.5,
    },
    serviceLink: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
};

export default styles;
