const styles = {
    textContent: {
        fontWeight: "bold",
    },
    containerSection: {
        mb: 3,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    msgBox: {
        width: "100%",
        textAlign: "left",
        fontWeight: 500,
    },
};

export default styles;
