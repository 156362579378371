import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useMatch, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import styles from "./CorrectiveActionRequestDetail.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import {
    correctiveActionRequest,
    correctiveActionsDetails,
} from "src/constants/routeConstants/operationsRouterFullPaths";
import { getCorrectiveActionDetails, getCorrectiveActionUpdateComments } from "src/services/researchService";
import { useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getCorrectiveActionRequestDetailPayload } from "src/services/printServices";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) => {
    return Yup.object().shape({
        comments: Yup.string().max(1024, t("messages.max255Char")).required(t("messages.commentRequired")),
    });
};

const CorrectiveActionRequestDetail = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const { carId } = useParams();
    const { navigate, goBack } = useSecureSyncNavigate();
    const [loading, setLoading] = useState(false);
    const [correctiveActionData, setCorrectiveActionData] = useState([]);
    const [existingMsg, setExistingMsg] = useState([]);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => resetForm() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const correctiveActionResponse = await getCorrectiveActionDetails({ carId });
            setCorrectiveActionData(correctiveActionResponse?.correctiveResults1);
            setExistingMsg(correctiveActionResponse?.correctiveResults2);
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { values, errors, touched, handleBlur, handleChange, resetForm, handleSubmit, isSubmitting } = useFormik({
        initialValues: {
            comments: "",
        },
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            const payload = {
                carId: carId,
                comments: values.comments,
            };
            const response = await getCorrectiveActionUpdateComments(payload);
            setCorrectiveActionData(response?.correctiveResults1);
            setExistingMsg(response?.correctiveResults2);
            resetForm();
        },
    });

    const sortedArr = existingMsg.sort((a, b) => {
        const dateA = a.comments.match(/\d{2}-[A-Za-z]{3}-\d{4} \d{2}:\d{2}/)[0];
        const dateB = b.comments.match(/\d{2}-[A-Za-z]{3}-\d{4} \d{2}:\d{2}/)[0];
        return dayjs(dateA, "DD-MMM-YYYY HH:mm").isAfter(dayjs(dateB, "DD-MMM-YYYY HH:mm")) ? -1 : 1;
    });

    const formattedValue = sortedArr.map((item) => item.comments).join("");

    const fromCorrectiveActions = useMatch(correctiveActionsDetails);
    const onClickBack = () => {
        if (fromCorrectiveActions) {
            goBack(correctiveActionRequest);
        } else {
            navigate(dashboard);
        }
    };
    const description = correctiveActionData[0]?.description.replace(/[\r\n]/g, "");
    const resolution = correctiveActionData[0]?.resolution.replace(/[\r\n]/g, "");

    const printReport = () => {
        setPrintPayload(
            getCorrectiveActionRequestDetailPayload({
                carId: carId,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <CircularLoaderFullPage show={loading || isSubmitting} />
            <Box>
                <Box sx={styles.titleContainer}>
                    <PageTitle>{t("titles.correctiveActionRequestDetail")}</PageTitle>
                    <Typography styles={styles.description}>
                        <Trans
                            i18nKey={"correctiveActionDetails.description"}
                            values={{ buttonLabel: t("buttonLabels.submit") }}
                        />
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box sx={styles.rowGrid}>
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.id || " "}
                            label={t("formFields.id")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.status || " "}
                            label={t("formFields.status")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.date || " "}
                            label={t("formFields.date")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.type || " "}
                            label={t("formFields.type")}
                        />
                    </Box>
                    <Box sx={{ display: "grid", gap: "20px", paddingBottom: "20px" }}>
                        <SecureSyncReadOnlyTextField
                            value={description || " "}
                            isMultiline={true}
                            maxRows={10}
                            label={t("formFields.description")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.failure_point || " "}
                            label={t("formFields.failurePoint")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={correctiveActionData[0]?.root_cause || " "}
                            label={t("formFields.rootCause")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={resolution || " "}
                            isMultiline={true}
                            maxRows={10}
                            label={t("formFields.resolution")}
                        />
                        {existingMsg[0]?.comments && (
                            <SecureSyncReadOnlyTextField
                                value={formattedValue || " "}
                                label={t("formFields.existingComments")}
                                maxRows={10}
                                isMultiline={true}
                                variant={"outlined"}
                            />
                        )}
                    </Box>
                    <Box sx={styles.titleContainer}>
                        <TextField
                            id="authorization-comments"
                            name="comments"
                            required
                            label={t("common.comments")}
                            variant="outlined"
                            multiline
                            fullWidth
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 1024 }}
                            error={touched.comments && Boolean(errors.comments)}
                            helperText={touched.comments && errors.comments}
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            rows={4}
                        />
                    </Box>
                    <Divider />
                    <Box sx={styles.buttonContainer}>
                        <Button
                            id="cancel"
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={() => onClickBack()}
                            startIcon={<IconLeft />}
                        >
                            {t("buttonLabels.back")}
                        </Button>
                        <Button
                            id="cancel"
                            variant="outlined"
                            color="primary"
                            type="button"
                            onClick={() => printReport()}
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        >
                            {t("buttonLabels.prepareReport")}
                        </Button>
                        <Button
                            id="reset"
                            variant="outlined"
                            color="primary"
                            type="reset"
                            onClick={resetForm}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            {t("common.reset")}
                        </Button>
                        <Button
                            id="submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                            title={getKeyBoardLabel(shortCutKeys.submit)}
                        >
                            {t("common.submit")}
                        </Button>
                    </Box>
                </form>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.carSearchResults}
            />
        </>
    );
};

export default CorrectiveActionRequestDetail;
