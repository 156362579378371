import { Box, Button, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import styles from "./GlobalMediaLocator.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { globalMediaLocator } from "src/constants/routeConstants/operationsRouterFullPaths";
import GlobalMediaLocatorTable from "src/components/Operations/Research/GlobalMediaLocator/GlobalMediaLocatorTable/GlobalMediaLocatorTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { sortHelper } from "src/utils/sortHelper";
import { getGlobalMediaLocator } from "src/services/researchService";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { globalAttributeTypeId, systemId } from "src/constants/serviceConstants";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) => {
    return Yup.object().shape({
        mediaNumber: Yup.string().required(t("globalMediaLocator.mediaNumberIsRequired")),
    });
};
const GlobalMediaLocator = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.clear, callback: () => onCancel() });
    const {
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        isSubmitting,
        resetForm,
        values,
        setValues,
        setSubmitting,
    } = useFormik({
        initialValues: {
            mediaNumber: "",
        },
        validationSchema: validationSchema(t),
        onSubmit: async (values) => await onFindClick(values, true),
    });

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState && locationState?.mediaNumber) {
                const newFormValues = {
                    mediaNumber: locationState?.mediaNumber || "",
                };
                await setValues(newFormValues);
                setSubmitting(true);
                await onFindClick(newFormValues, false);
                setSubmitting(false);
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const volserMaxLength = globalAttributes?.data?.rs0?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id94
    )?.global_attribute_value;

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }

        return newNewPageState;
    };

    const onFindClick = async (values, isButtonClick) => {
        const pageState = getPageState({ ...values }, !isButtonClick);
        navigate(globalMediaLocator, {
            state: pageState,
            replace: true,
        });
        setIsSearched(true);
        const requestData = await getGlobalMediaLocator({ mediaNumber: values.mediaNumber });
        if (!requestData[0]?.hasError) {
            const combinedData = [...requestData.resultSet0, ...requestData.resultSet1];
            const result = sortHelper({
                data: combinedData,
                primaryProperty: "Customer_Number",
                secondaryProperty: null,
                sortOrder: "asc",
            });
            setSearchResults(result);
        }
    };

    const onCancel = () => {
        resetForm();
        setIsSearched(false);
        setSearchResults([]);
        navigate(globalMediaLocator, {
            state: {},
            replace: true,
        });
    };

    const mediaNumberTitle = t("globalMediaLocator.pageDescription").replace("<strong>", "").replace("</strong>", "");
    return (
        <Box>
            <CircularLoaderFullPage show={isSubmitting} />
            <PageTitle>
                <Trans i18nKey={"titles.globalMediaLocatorSearch"} />
            </PageTitle>
            <Typography sx={styles.desc}>
                <Trans
                    i18nKey={"globalMediaLocator.pageDescription"}
                    values={{ buttonLabel: t("buttonLabels.find") }}
                />
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    title={mediaNumberTitle}
                    label={t("common.mediaNumber")}
                    name="mediaNumber"
                    value={values.mediaNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.mediaNumber && touched.mediaNumber)}
                    helperText={touched.mediaNumber && errors.mediaNumber}
                    inputProps={{ maxLength: parseInt(volserMaxLength) || 20 }}
                />
                <Box sx={styles.searchButtons}>
                    <Button
                        variant="outlined"
                        type="reset"
                        onClick={onCancel}
                        title={getKeyBoardLabel(shortCutKeys.clear)}
                    >
                        {t("buttonLabels.clear")}
                    </Button>
                    <Button variant="contained" type="submit" title={getKeyBoardLabel(shortCutKeys.search)}>
                        {t("buttonLabels.find")}
                    </Button>
                </Box>
            </form>
            <GlobalMediaLocatorTable records={searchResults} isSearched={isSearched} mediaNumber={values.mediaNumber} />
        </Box>
    );
};

export default GlobalMediaLocator;
