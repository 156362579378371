import dayjs from "dayjs";
import { useMemo } from "react";
import { dateTimeUnits } from "src/constants/dateFormatConstants";

const useGetAttrsBasedOnAddCutOffValues = (runDetails, onCallStatus) => {
    return useMemo(() => {
        const { noOfServices, addOnCutoffFlag, previousDayFlag, noOfServicesTom, maxServiceDate } = runDetails || {};
        const currentDate = dayjs();
        let addDays = 0;
        let addOnCutOffToday = null;
        let addOnCutOffTomorrow = null;

        if (addOnCutoffFlag || previousDayFlag) {
            if (
                (previousDayFlag &&
                    noOfServicesTom === 1 &&
                    onCallStatus &&
                    maxServiceDate.isSame(currentDate, dateTimeUnits.day)) ||
                (noOfServices === 1 && !previousDayFlag) ||
                (previousDayFlag && (noOfServicesTom === 0 || noOfServicesTom > 1) && noOfServices === 1)
            ) {
                addDays = 1;
                addOnCutOffToday = currentDate;
            } else if (previousDayFlag && noOfServicesTom === 1) {
                addDays = 2;
                addOnCutOffToday = currentDate;
                addOnCutOffTomorrow = currentDate.add(1, dateTimeUnits.day);
            }
        }
        return { addDays, addOnCutOffToday, addOnCutOffTomorrow };
    }, [runDetails, onCallStatus]);
};

export default useGetAttrsBasedOnAddCutOffValues;
