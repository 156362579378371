import { t } from "i18next";
import {
    constructFbHeaders,
    downloadFileFromFB,
    getCustomers,
    getLanguageLocaleId,
    getPersonId,
    getPersonalLocalId,
    getSelectedCustomerDb,
    getSelectedCustomerDistrictName,
    getSelectedCustomerId,
    getSelectedCustomerTimeZoneOffset,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";
import { getAllCaps } from "src/constants/researchConstants";
import { noFlag, yesFlag } from "src/constants/serviceConstants";

export const populateDownloadReport = async (payload, userName, format, url) => {
    const personalId = getPersonId();
    const { selectedCustomer = null } = getCustomers();
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: payload.customer_id ? payload.customer_id : getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        locale_id: getPersonalLocalId() ? getPersonalLocalId() : "1",
        ...payload,
        user_name: userName,
        report_format: format,
        personnel_id: personalId,
        customer_number: payload.customer_number
            ? payload.customer_number
            : selectedCustomer?.customer_number
              ? selectedCustomer.customer_number
              : "",
    };
    const headers = constructFbHeaders();
    const { traceStart, traceStop } = traceWrapper(url);
    traceStart();
    const fullUrl = `${process.env.REACT_APP_CF_URL_SSRS}${url}`;
    try {
        const res = await invokeApi("POST", fullUrl, body, {}, headers);
        if (res?.error) {
            throw new Error(res.error);
        }
        if (res?.data?.response?.gcs_path) {
            const gscPath = res?.data?.response?.gcs_path;
            const namePrefix = res?.data?.request?.namePrefix;
            await downloadFileFromFB({
                fbFilePath: gscPath,
                fileName: namePrefix ? namePrefix : "NA",
                onComplete: () => {
                    return true;
                },
            });
        } else {
            throw new Error("file not found!");
        }
    } catch (err) {
        console.log(" error in create ssrs document", err);
        throw new Error(err);
    } finally {
        traceStop();
    }
};

export const getSecurityVerificationReportPayload = () => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        label_report_title: t("reports.securityVerificationReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_branch: t("reports.securityVerificationReport.labelBranch"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_user_name: t("reports.securityVerificationReport.labelUserName"),
        label_interaction_authority: t("reports.securityVerificationReport.labelInteractionAuthority"),
        label_dr_authority: t("reports.securityVerificationReport.labelDrAuthority"),
        label_last_verification_update_date: t("reports.securityVerificationReport.labelLastVerificationUpdateDate"),
        label_report_name: t("reports.securityVerificationReport.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getCustomerAuthReportPayload = () => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        label_page_number: t("reports.labelOf"),
        label_report_title: "Customer Authorization",
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_interaction_authority: t("reports.customerAuthReport.labelInteractionAuthority"),
        label_dr_authority: t("reports.customerAuthReport.labelDrAuthority"),
        label_first_name: t("reports.customerAuthReport.labelFirstName"),
        label_middle_name: t("reports.customerAuthReport.labelMiddleName"),
        label_last_name: t("reports.customerAuthReport.labelLastName"),
        label_business_phone: t("reports.customerAuthReport.labelBusinessPhone"),
        label_extention: t("reports.customerAuthReport.labelExt"),
        label_home_phone: t("reports.customerAuthReport.labelHomePhone"),
        label_cellular_phone: t("reports.customerAuthReport.labelCellularPhone"),
        label_pager_pin: t("reports.customerAuthReport.labelPagerPin"),
        label_auth_title: t("reports.customerAuthReport.labelAuthTitle"),
        label_dr_auth_title: t("reports.customerAuthReport.labelDrAuthTitle"),
        label_dr_dp_title: t("reports.customerAuthReport.labelDrDpTitle"),
        label_auth_description: t("reports.customerAuthReport.labelAuthDescription"),
        label_dr_auth_description: t("reports.customerAuthReport.labelDrAuthDescription"),
        label_auth_1: t("reports.customerAuthReport.labelAuth1"),
        label_auth_2: t("reports.customerAuthReport.labelAuth2"),
        label_auth_3: t("reports.customerAuthReport.labelAuth3"),
        label_auth_4: t("reports.customerAuthReport.labelAuth4"),
        label_auth_5: t("reports.customerAuthReport.labelAuth5"),
        label_auth_6: t("reports.customerAuthReport.labelAuth6"),
        label_dr_auth_1: t("reports.customerAuthReport.labelDrAuth1"),
        label_dr_auth_2: t("reports.customerAuthReport.labelDrAuth2"),
        label_dr_auth_3: t("reports.customerAuthReport.labelDrAuth3"),
        label_dr_auth_4: t("reports.customerAuthReport.labelDrAuth4"),
        label_dp_1: t("reports.customerAuthReport.labelDp1"),
        label_dp_2: t("reports.customerAuthReport.labelDp2"),
        label_dp_3: t("reports.customerAuthReport.labelDp3"),
        label_dp_4: t("reports.customerAuthReport.labelDp4"),
        label_dp_5: t("reports.customerAuthReport.labelDp5"),
        label_report_name: t("reports.labelReportName"),
    };
};

export const getSearchUserReportPayload = ({
    firstName,
    lastName,
    businessPhone,
    businessPhoneExt,
    eLevelAuth = "N",
    drLevelAuth = "N",
}) => {
    return {
        main_district_id: getSelectedCustomerDb(), //corp-regional
        customer_id: getSelectedCustomerId(),
        first_name: firstName,
        last_name: lastName,
        home_phone: "",
        business_phone: businessPhone,
        business_phone_ext: businessPhoneExt,
        e_level_authorization: eLevelAuth,
        customer_parent_id: "",
        secure_sync_flag: "Y",
        auth_number: "",
        dr_level_authorization: drLevelAuth,
        label_report_title: t("reports.searchUserReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_user_name: t("reports.searchUserReport.labelUserName"),
        label_interaction_authority: t("reports.searchUserReport.labelInteractionAuthority"),
        label_dr_authority: t("reports.searchUserReport.labelDrAuthority"),
        label_business_phone: t("reports.searchUserReport.labelBusinessPhone"),
        label_home_phone: t("reports.searchUserReport.labelHomePhone"),
        label_pager_phone: t("reports.searchUserReport.labelPagerPhone"),
        label_pager_pin: t("reports.searchUserReport.labelPagerPin"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getOmDistributionListPayload = ({
    customerFileSectionId,
    omListId,
    serviceDate,
    drpCode,
    returnDate,
    description,
}) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        customer_file_section_id: customerFileSectionId,
        open_media_list_id: omListId,
        service_date: serviceDate,
        descr: description,
        drp_code: drpCode,
        return_date: returnDate,
        label_report_title: t("reports.omDistributionListReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_service_date: t("reports.omDistributionListReport.labelServiceDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_media_number: t("reports.omDistributionListReport.labelMediaNumber"),
        label_return_date: t("reports.omDistributionListReport.labelReturnDate"),
        label_drp_code: t("reports.omDistributionListReport.labelDrpCode"),
        label_open_media_status: t("reports.omDistributionListReport.labelOpenMediaStatus"),
        label_description: t("reports.omDistributionListReport.labelDescription"),
        label_total_open_media_count: t("reports.omDistributionListReport.labelTotalOpenMediaCount"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getUserVerificationPayload = ({ limitedCustomerId }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_info_only: "0",
        target_personnel_id: "",
        locale_id: getPersonalLocalId(),
        limit_to_customer_id: limitedCustomerId,
        label_report_title: t("reports.userVerificationReports.labelReportTitle"),
        label_report_date: t("reports.userVerificationReports.labelReportDate"),
        label_printed_by: t("reports.userVerificationReports.labelPrintedBy"),
        label_user_name: t("reports.userVerificationReports.labelUserName"),
        label_interaction_authority: t("reports.userVerificationReports.labelInteractionAuthority"),
        label_interaction_last_update: t("reports.userVerificationReports.labelInteractionLastUpdate"),
        label_dr_authority: t("reports.userVerificationReports.labelDrAuthority"),
        label_dr_last_update: t("reports.userVerificationReports.labelDrLastUpdate"),
        label_last_verification_update: t("reports.userVerificationReports.labelLastVerificationUpdate"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_customer_number: t("reports.userVerificationReports.labelCustomerNumber"),
        label_branch: t("reports.userVerificationReports.labelBranch"),
    };
};

export const getPickupManifestListPayload = (containerNumberList) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        container_number_list: containerNumberList || "",
        descr: "",
        from_return_date: "",
        to_return_date: "",
        summary_type: "",
        media_type_id: "",
        prepare_for_pickup_flag: "Y",
        locale_id: getPersonalLocalId() ? getPersonalLocalId() : "1",
        customer_media_descr: "",
        language_locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        deleted_phrase: t("localizedServiceDetails.deleted0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("containerPickup.indefinite"),
        all_phrase: `<${convertToLocaleUpperCase(t("common.all"))}>`,
        all_excluding_deleted_phrase: t("containerPickup.allExcludingDeleted"),
        label_report_title: t("reports.containerPickupManifestReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_customer_signature: t("reports.containerPickupManifestReport.labelCustomerSignature"),
        label_security_guard_signature: t("reports.containerPickupManifestReport.labelSecurityGuardSignature"),
        label_ims_signature: t("reports.containerPickupManifestReport.labelImsSignature"),
        label_container_number: t("reports.containerPickupManifestReport.labelContainerNumber"),
        label_media_type: t("reports.containerPickupManifestReport.labelMediaType"),
        label_return_date: t("reports.containerPickupManifestReport.labelReturnDate"),
        label_description: t("reports.containerPickupManifestReport.labelDescription"),
        label_total_record_count: t("reports.containerPickupManifestReport.labelTotalRecordCount"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_conatainer_number: t("reports.containerPickupManifestReport.labelContainerNumber"),
        label_descr: t("reports.containerPickupManifestReport.labelDescription"),
    };
};

export const scanVerificationReportPayload = ({
    verifyResultId,
    isFullReport = false,
    list,
    createdDate,
    verifiedItems,
    originalVerificationDate,
    lastVerificationDate,
}) => {
    const reportType = isFullReport ? "full" : "default";

    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        verify_result_id: verifyResultId ? verifyResultId : "0",
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        locale_id: getPersonalLocalId() ? getPersonalLocalId() : "1",
        language_locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        scanned_phrase: t("scanVerification.scannedPhrase"),
        customer_id: getSelectedCustomerId(),
        list: list,
        created_date: createdDate,
        verified_items: verifiedItems,
        original_verification_date: originalVerificationDate,
        last_verification_date: lastVerificationDate,
        label_page_number: t("reports.labelOf"),
        label_report_title: t(`reports.scanVerificationReport.labelReportTitle.${reportType}`),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_branch: "",
        label_report_name: t("reports.labelReportName"),
        label_user_name: "",
        label_comp_summery: t("reports.scanVerificationReport.labelCompSummery"),
        label_action: t("reports.scanVerificationReport.labelAction"),
        label_unique_items_scanned: t("reports.scanVerificationReport.labelUniqueItemsScanned"),
        label_list: t("reports.scanVerificationReport.labelList"),
        label_total_items_in_list: t("reports.scanVerificationReport.labelTotalItemsInList"),
        label_verified_items: t("reports.scanVerificationReport.labelVerifiedItems"),
        label_original_verification_user: t("reports.scanVerificationReport.labelOriginalVerificationUser"),
        label_original_verification_datetime: t("reports.scanVerificationReport.labelOriginalVerificationDateTime"),
        label_last_verification_user: t("reports.scanVerificationReport.labelLastVerificationUser"),
        label_last_verification_datetime: t("reports.scanVerificationReport.labelLastVerificationDateTime"),
        label_created_datetime: t("reports.scanVerificationReport.labelCreatedDateTime"),
        label_unresolved_verification: t("reports.scanVerificationReport.labelUnresolvedVerification"),
        label_issue_a: t("reports.scanVerificationReport.labelIssueA"),
        label_media_number_a: t("reports.scanVerificationReport.labelMediaNumberA"),
        label_last_comment_a: t("reports.scanVerificationReport.labelLastCommentA"),
        label_orgverification_user_a: t("reports.scanVerificationReport.labelOrgVerificationUserA"),
        label_resolved_user_datetime_a: t("reports.scanVerificationReport.labelResolvedUserDateTimeA"),
        label_resolved_verification: t("reports.scanVerificationReport.labelResolvedVerification"),
        label_issue_b: t("reports.scanVerificationReport.labelIssueB"),
        label_media_number_b: t("reports.scanVerificationReport.labelMediaNumberB"),
        label_last_comment_b: t("reports.scanVerificationReport.labelLastCommentB"),
        label_orgverification_user_b: t("reports.scanVerificationReport.labelOrgVerificationUserB"),
        label_resolved_user_datetime_b: t("reports.scanVerificationReport.labelResolvedUserDateTimeB"),
        label_scanned_items_c: t("reports.scanVerificationReport.labelScannedItemsC"),
        label_media_number_c: t("reports.scanVerificationReport.labelMediaNumberC"),
    };
};

export const getCloseContainerPayload = ({ containerList }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        container_list: containerList,
        invalid_container_number_phrase: t("reports.closeContainerReport.labelInvalidContainerNumber"),
        invalid_customer_submitted_phrase: t("reports.closeContainerReport.labelInvalidCustomerSubmit"),
        invalid_container_status_phrase: t("reports.closeContainerReport.labelInvalidCustomerStatus"),
        label_page_number: t("reports.labelOf"),
        label_report_title: t("reports.closeContainerReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_name: t("reports.closeContainerReport.labelReportName"),
        label_report_date: t("reports.labelReportDate"),
        label_container_type: t("reports.closeContainerReport.labelContainerTypeReject"),
        label_container_type_valid: t("reports.closeContainerReport.labelContainerTypeValid"),
        label_items_test: t("reports.closeContainerReport.labelLastTestedItem"),
    };
};

export const getMediaDestructionDetailsPayload = ({
    destructRequestTypeId,
    destructRequestStatusId,
    destructRequestId,
    dateFrom,
    dateTo,
}) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        destruct_request_type_id: destructRequestTypeId,
        destruct_request_status_id: destructRequestStatusId,
        destruct_request_id: destructRequestId,
        date_from: dateFrom,
        date_to: dateTo,
        row_limit: "1000",
        system_id: "50",
        tracking_number: "",
        authorized_pending_review_status_flag: "N",
        criteriasectioncaption: t("reports.searchMediaDestructionRequests.criteriaSectionCaption"),
        criteriamdridcaption: t("reports.searchMediaDestructionRequests.criteriaMdrIdCaption"),
        criteriatype: t("reports.searchMediaDestructionRequests.criteriaType"),
        criteriastatus: t("reports.searchMediaDestructionRequests.criteriaStatus"),
        criteriafromdate: t("reports.searchMediaDestructionRequests.criteriaFromDate"),
        criteriatodate: t("reports.searchMediaDestructionRequests.criteriaToDate"),
        mdridcaption: t("reports.searchMediaDestructionRequests.mdrIdCaption"),
        requesttype: t("reports.searchMediaDestructionRequests.requestType"),
        requeststatus: t("reports.searchMediaDestructionRequests.requestStatus"),
        requestdate: t("reports.searchMediaDestructionRequests.requestDate"),
        totalrecordcount: t("reports.searchMediaDestructionRequests.totalRecordCount"),
        label_report_title: t("reports.searchMediaDestructionRequests.reportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_date: t("reports.labelReportDate"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getSearchRequestPayload = ({
    requestId = "",
    requestTypeId = "",
    requestStatusId = "",
    serviceDateFrom = "",
    serviceDateTo = "",
}) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        service_date_from: serviceDateFrom,
        service_date_to: serviceDateTo,
        request_type_id: requestTypeId,
        request_status_id: requestStatusId,
        request_id: requestId,
        named_sort: "",
        sort_descending: "",
        label_report_title: t("reports.searchRequests.labelReportTitle"),
        label_search_criteria: t("reports.searchRequests.labelSearchCriteria"),
        label_request_id: t("reports.searchRequests.labelRequestId"),
        label_request_type: t("reports.searchRequests.labelRequestType"),
        label_request_status: t("reports.searchRequests.labelRequestStatus"),
        label_from_date: t("reports.searchRequests.labelFromDate"),
        label_to_date: t("reports.searchRequests.labelToDate"),
        label_request_id_column: t("reports.searchRequests.labelRequestIdColumn"),
        label_service_date_column: t("reports.searchRequests.labelServiceDateColumn"),
        label_request_type_column: t("reports.searchRequests.labelRequestTypeColumn"),
        label_request_status_column: t("reports.searchRequests.labelRequestStatusColumn"),
        label_request_action_column: t("reports.searchRequests.labelRequestActionColumn"),
        label_requested_by_column: t("reports.searchRequests.labelRequestedByColumn"),
        label_record_count: t("reports.searchRequests.labelRecordCount"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_date: t("reports.labelReportDate"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getNewOrEmptyTransportPayload = ({ requestId, reportLabel }) => {
    const { selectedCustomer } = getCustomers();
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_number,
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        request_id: requestId,
        label_report_title: reportLabel,
        label_customer_number: t("reports.newOrEmptyTransport.labelCustomerNumber"),
        request_display_id: t("reports.newOrEmptyTransport.requestDisplayId"),
        label_report_date: t("reports.newOrEmptyTransport.labelReportDate"),
        label_printed_by: t("reports.newOrEmptyTransport.labelPrintedBy"),
        request_information: t("reports.newOrEmptyTransport.requestInformation"),
        service_date: t("reports.newOrEmptyTransport.serviceDate"),
        request_type: t("reports.newOrEmptyTransport.requestType"),
        service_location: t("reports.newOrEmptyTransport.serviceLocation"),
        request_status: t("reports.newOrEmptyTransport.requestStatus"),
        requestdate: t("reports.newOrEmptyTransport.requestDate"),
        requested_by: t("reports.newOrEmptyTransport.requestedBy"),
        locks: t("reports.newOrEmptyTransport.locks"),
        insert_config: t("reports.newOrEmptyTransport.insertConfig"),
        transport_quantity: t("reports.newOrEmptyTransport.transportQuantity"),
        transport_type: t("reports.newOrEmptyTransport.transportType"),
        Vault_id: t("reports.newOrEmptyTransport.vaultId"),
        totalrecordcount: t("reports.newOrEmptyTransport.totalRecordCount"),
        comments: t("reports.comments"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getDeliverMediaPayload = ({ requestId }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        customer_id: getSelectedCustomerId(),
        request_id: requestId,
        no_longer_tied_to_request_status: t("reports.deliverMedia.noLongerTiedToRequestStatus"),
        label_report_title: t("reports.deliverMedia.labelReportTitle"),
        label_customer_number: t("reports.deliverMedia.labelCustomerNumber"),
        label_request_display_id: t("reports.deliverMedia.labelRequestDisplayId"),
        label_report_date: t("reports.deliverMedia.labelReportDate"),
        label_printed_by: t("reports.deliverMedia.labelPrintedBy"),
        label_request_information: t("reports.deliverMedia.labelRequestInformation"),
        label_service_date: t("reports.deliverMedia.labelServiceDate"),
        label_request_type: t("reports.deliverMedia.labelRequestType"),
        label_service_location: t("reports.deliverMedia.labelServiceLocation"),
        label_request_status: t("reports.deliverMedia.labelRequestStatus"),
        label_date_requested: t("reports.deliverMedia.labelDateRequested"),
        label_requested_by: t("reports.deliverMedia.labelRequestedBy"),
        label_date_canceled: t("reports.deliverMedia.labelDateCanceled"),
        label_canceled_by: t("reports.deliverMedia.labelCanceledBy"),
        label_open_media_details: t("reports.deliverMedia.labelOpenMediaDetails"),
        label_container_details: t("reports.deliverMedia.labelContainerDetails"),
        label_open_media_number_column: t("reports.deliverMedia.labelOpenMediaNumberColumn"),
        label_open_media_status_column: t("reports.deliverMedia.labelOpenMediaStatusColumn"),
        label_current_status_column: t("reports.deliverMedia.labelCurrentStatusColumn"),
        label_container_number_column: t("reports.deliverMedia.labelContainerNumberColumn"),
        label_container_type_column: t("reports.deliverMedia.labelContainerTypeColumn"),
        label_container_status_column: t("reports.deliverMedia.labelContainerStatusColumn"),
        label_open_media_count: t("reports.deliverMedia.labelOpenMediaCount"),
        label_container_count: t("reports.deliverMedia.labelContainerCount"),
        label_comments: t("reports.comments"),
        label_report_display_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getOtherServicePayload = (requestId) => {
    return {
        request_id: requestId,
        label_report_title: t("reports.otherRequest.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_request_display_id: t("reports.otherRequest.labelId"),
        label_request_information: t("reports.otherRequest.labelInfo"),
        label_service_date: t("reports.otherRequest.labelServiceDate"),
        label_request_type: t("reports.otherRequest.labelRequestType"),
        label_service_location: t("reports.otherRequest.labelLocation"),
        label_request_status: t("reports.otherRequest.labelStatus"),
        label_date_requested: t("reports.otherRequest.labelRequestedDate"),
        label_requested_by: t("reports.otherRequest.labelRequestedBy"),
        label_date_canceled: t("reports.otherRequest.labelCancelDate"),
        label_canceled_by: t("reports.otherRequest.labelCanceledBy"),
        label_description: t("reports.otherRequest.labelDescription"),
        label_comments: t("reports.otherRequest.labelComments"),
        label_report_display_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
    };
};

export const getSearchEmployeePayload = ({ firstName, lastName, districtId, title, districtName }) => {
    const personalId = getPersonId();
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: districtId,
        first_name: firstName ? firstName : "",
        last_name: lastName ? lastName : "",
        job_title_id: title ? title : "",
        emergency_contact_flag: "Y",
        named_sort: "Name",
        sort_descending: "N",
        language_locale_id: getLanguageLocaleId(),
        customer_number: "",
        ui_val_first_name: firstName ? firstName : "",
        ui_val_last_name: lastName ? lastName : "",
        ui_val_title_name: title ? title : "",
        ui_val_district: districtName === "0" ? convertToLocaleUpperCase(t("common.all")) : districtName,
        personnel_id: personalId,
        district_name: "",
        user_name: "",
        report_format: "PDF",
        label_report_title: t("reports.searchEmployee.labelReportTitle"),
        label_customer_number: t("reports.searchEmployee.labelCustomerNumber"),
        label_report_date: t("reports.searchEmployee.labelReportDate"),
        label_printed_by: t("reports.searchEmployee.labelPrintedBy"),
        label_report_name: t("reports.searchEmployee.labelReportName"),
        label_name: t("reports.searchEmployee.labelName"),
        label_title: t("reports.searchEmployee.labelJobTitle"),
        label_business_phone: t("reports.searchEmployee.labelBusinessPhone"),
        label_cellular_phone: t("reports.searchEmployee.labelCellularPhone"),
        label_support_center: t("reports.searchEmployee.labelSupportCenterContact"),
        label_support_us_and_other: t("reports.searchEmployee.labelOtherCountries"),
        label_center_phone1: t("reports.searchEmployee.labelPhone"),
        label_center_email1: t("reports.searchEmployee.labelEmail"),
        label_support_uk: t("reports.searchEmployee.labelSupportUk"),
        label_center_phone2: t("reports.searchEmployee.labelCenterPhone2"),
        label_center_email2: t("reports.searchEmployee.labelCenterEmail2"),
        label_search_criteria: t("reports.searchEmployee.labelSearchCriteria"),
        label_first_name: t("reports.searchEmployee.labelFirstName"),
        label_last_name: t("reports.searchEmployee.labelLastName"),
        label_district: t("reports.searchEmployee.labelDistrict"),
        label_job_title: t("reports.searchEmployee.labelJobTitle2"),
        label_record_count: t("reports.searchEmployee.labelRecordCount"),
        label_sort_order: t("reports.searchEmployee.labelSortOrder"),
        label_of: t("reports.labelOf"),
    };
};

export const getNewContainerLocksPayload = ({ requestId }) => {
    const { selectedCustomer } = getCustomers();
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_number,
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        request_id: requestId,
        itemtype_extra_inserts: t("reports.newLockContainer.itemTypeExtraInserts"),
        itemtype_locks: t("reports.newLockContainer.itemTypeLocks"),
        itemtype_clips: t("reports.newLockContainer.itemTypeClips"),
        resultset_number: "1",
        label_report_title: t("reports.newLockContainer.labelReportTitle"),
        label_customer_number: t("reports.newLockContainer.labelCustomerNumber"),
        label_request_id: t("reports.newLockContainer.labelRequestId"),
        label_report_date: t("reports.newLockContainer.labelReportDate"),
        label_printed_by: t("reports.newLockContainer.labelPrintedBy"),
        label_search_criteria: t("reports.newLockContainer.labelSearchCriteria"),
        label_service_date: t("reports.newLockContainer.labelServiceDate"),
        label_request_type: t("reports.newLockContainer.labelRequestType"),
        label_service_location: t("reports.newLockContainer.labelServiceLocation"),
        label_request_status: t("reports.newLockContainer.labelRequestStatus"),
        label_from_date: t("reports.newLockContainer.labelFromDate"),
        requested_by: t("reports.newLockContainer.requestedBy"),
        label_logical_vault_code_column: t("reports.newLockContainer.labelLogicalVaultCodeColumn"),
        label_descr_column: t("reports.newLockContainer.labelDescrColumn"),
        label_quantity_column: t("reports.newLockContainer.labelQuantityColumn"),
        label_inserts_column: t("reports.newLockContainer.labelInsertsColumn"),
        label_lock_type_code_column: t("reports.newLockContainer.labelLockTypeCodeColumn"),
        label_request_status_id_column: t("reports.newLockContainer.labelRequestStatusIdColumn"),
        label_item_column: t("reports.newLockContainer.labelItemColumn"),
        label_type_column: t("reports.newLockContainer.labelTypeColumn"),
        label_container_count: t("reports.newLockContainer.labelContainerCount"),
        label_customer_owned_container_flag_column: t("reports.newLockContainer.labelCustomerOwnedContainerFlagColumn"),
        label_quantity_column_1: t("reports.newLockContainer.labelQuantityColumn1"),
        label_comments: t("reports.comments"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getRequestDeleteContainerPayload = ({ requestId }) => {
    const { selectedCustomer } = getCustomers();
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_number,
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        request_id: requestId,
        no_longer_tied_to_request_status: t("reports.requestDeleteContainer.noLongerTiedToRequestStatus"),
        label_report_title: t("reports.requestDeleteContainer.labelReportTitle"),
        label_customer_number: t("reports.requestDeleteContainer.labelCustomerNumber"),
        label_request_display_id: t("reports.requestDeleteContainer.labelRequestDisplayId"),
        label_report_date: t("reports.requestDeleteContainer.labelReportDate"),
        label_printed_by: t("reports.requestDeleteContainer.labelPrintedBy"),
        label_request_information: t("reports.requestDeleteContainer.labelRequestInformation"),
        label_service_date: t("reports.requestDeleteContainer.labelServiceDate"),
        label_request_type: t("reports.requestDeleteContainer.labelRequestType"),
        label_service_location: t("reports.requestDeleteContainer.labelServiceLocation"),
        label_request_status: t("reports.requestDeleteContainer.labelRequestStatus"),
        label_date_requested: t("reports.requestDeleteContainer.labelDateRequested"),
        label_requested_by: t("reports.requestDeleteContainer.labelRequestedBy"),
        label_date_canceled: t("reports.requestDeleteContainer.labelDateCanceled"),
        label_canceled_by: t("reports.requestDeleteContainer.labelCanceledBy"),
        label_container_number_column: t("reports.requestDeleteContainer.labelContainerNumberColumn"),
        label_container_type_column: t("reports.requestDeleteContainer.labelContainerTypeColumn"),
        label_container_status_column: t("reports.requestDeleteContainer.labelContainerStatusColumn"),
        label_container_count: t("reports.requestDeleteContainer.labelContainerCount"),
        label_comments: t("reports.requestDeleteContainer.labelComments"),
    };
};

export const getMediaDestructionRequestPayload = ({ destructRequestId, pageTitle, description }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        district_id: getSelectedCustomerDb(),
        system_id: "50",
        destruct_request_id: destructRequestId,
        district_name: getSelectedCustomerDistrictName(),
        im_comment_caption: pageTitle,
        im_comment_description: description,
        label_report_title: t("reports.mediaDestructionRequest.labelReportTitle"),
        label_customer_number_caption: t("reports.mediaDestructionRequest.labelCustomerNumberCaption"),
        label_destruction_request_id_caption: t("reports.mediaDestructionRequest.labelDestructionRequestIdCaption"),
        label_report_date_caption: t("reports.mediaDestructionRequest.labelReportDateCaption"),
        label_printed_by_caption: t("reports.mediaDestructionRequest.labelPrintedByCaption"),
        label_request_info_caption: t("reports.mediaDestructionRequest.labelRequestInfoCaption"),
        label_request_status_caption: t("reports.mediaDestructionRequest.labelRequestStatusCaption"),
        label_request_date_caption: t("reports.mediaDestructionRequest.labelRequestDateCaption"),
        label_requested_by_caption: t("reports.mediaDestructionRequest.labelRequestedByCaption"),
        label_notify_via_email: t("reports.mediaDestructionRequest.labelNotifyViaEmail"),
        label_authorized_by_caption: t("reports.mediaDestructionRequest.labelAuthorizedByCaption"),
        label_authorized_date_caption: t("reports.mediaDestructionRequest.labelAuthorizedDateCaption"),
        label_certified_date_caption: t("reports.mediaDestructionRequest.labelCertifiedDateCaption"),
        label_cancelled_by_caption: t("reports.mediaDestructionRequest.labelCancelledByCaption"),
        label_cancelled_date_caption: t("reports.mediaDestructionRequest.labelCancelledDateCaption"),
        label_pickup_instructions_caption: t("reports.mediaDestructionRequest.labelPickupInstructionsCaption"),
        label_quantity_of_boxes_on_order_caption: t(
            "reports.mediaDestructionRequest.labelQuantityOfBoxesOnOrderCaption"
        ),
        "------------------Sub report 01---------------------------": "",
        label_media_list_caption: t("reports.mediaDestructionRequest.labelMediaListCaption"),
        label_open_media_number_caption: t("reports.mediaDestructionRequest.labelOpenMediaNumberCaption"),
        label_status_caption: t("reports.mediaDestructionRequest.labelStatusCaption"),
        label_description_caption: t("reports.mediaDestructionRequest.labelDescriptionCaption"),
        label_open_media_count_caption: t("reports.mediaDestructionRequest.labelOpenMediaCountCaption"),
        "------------------Sub report 02---------------------------": "",
        label_service_date_caption: t("reports.mediaDestructionRequest.labelServiceDateCaption"),
        label_service_location_caption: t("reports.mediaDestructionRequest.labelServiceLocationCaption"),
        label_order_quantity_caption: t("reports.mediaDestructionRequest.labelOrderQuantityCaption"),
        label_pickup_quantity_caption: t("reports.mediaDestructionRequest.labelPickupQuantityCaption"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getCarSearchResultsPayload = ({ fromDate, toDate }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        district_name: getSelectedCustomerDistrictName(),
        from_date: fromDate,
        to_date: toDate,
        label_report_title: t("reports.carSearchResults.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_search_criteria: t("reports.carSearchResults.labelSearchCriteria"),
        label_id: t("reports.carSearchResults.labelId"),
        label_date: t("reports.carSearchResults.labelDate"),
        label_type: t("reports.carSearchResults.labelType"),
        label_description: t("reports.carSearchResults.labelDescription"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
    };
};

export const getOpenMediaExceptionDetailReportPayload = ({
    districtName,
    districtId,
    customerNumber,
    customerName,
    customerId,
    detectedIn,
    exceptionType,
    detectedDateFrom,
    detectedDateTo,
    operationalModeId,
    logicalVaultCode,
    fileSectionTypeId,
}) => {
    return {
        main_district_id: districtId ? districtId : getSelectedCustomerDb(),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_customer_number: t("reports.openMediaExceptionDetail.labelCustomerNumber"),
        district_id: districtId ? districtId : getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
        district_name: districtName ? districtName : getSelectedCustomerDistrictName(),
        label_report_title: t("reports.openMediaExceptionDetail.labelReportTitle"),
        label_user_name: t("reports.openMediaExceptionDetail.labelUserName"),
        label_detected_in: t("reports.openMediaExceptionDetail.labelDetectedIn"),
        label_Exception_Type: t("reports.openMediaExceptionDetail.labelExceptionType"),
        label_Media: t("reports.openMediaExceptionDetail.labelMedia"),
        label_Detected_Datetime: t("reports.openMediaExceptionDetail.labelDetectedatetime"),
        label_Task: t("reports.openMediaExceptionDetail.labelTask"),
        label_Logical_Vault: t("reports.openMediaExceptionDetail.labelLogicalVault"),
        label_Request_Type: t("reports.openMediaExceptionDetail.labelRequestType"),
        label_Service_Date: t("reports.openMediaExceptionDetail.labelServiceDate"),
        label_Additional_Information: t("reports.openMediaExceptionDetail.labelAdditionalInformation"),
        label_CAR_ID: t("reports.openMediaExceptionDetail.labelCARID"),
        file_section_type_id: fileSectionTypeId,
        customer_name: customerName,
        customer_id: customerId,
        detected_in: detectedIn,
        exception_type: exceptionType,
        detected_date_from: detectedDateFrom,
        detected_date_to: detectedDateTo,
        operational_mode_id: operationalModeId,
        logical_vault_code: logicalVaultCode,
        customer_number: customerNumber,
        label_page: t("reports.labelPage"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
    };
};

export const getEmployeeContactInformationPayload = ({ employeeId }) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
        district_name: getSelectedCustomerDistrictName(),
        employee_id: employeeId,
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_title: t("reports.employeeContactInformation.labelTitle"),
        label_job_title: t("reports.employeeContactInformation.labelJobTitle"),
        label_business_phone: t("reports.employeeContactInformation.labelBusinessPhone"),
        label_Ext: t("reports.employeeContactInformation.labelExt"),
        label_employee_pager: t("reports.employeeContactInformation.labelEmployeePager"),
        label_employee_cellular: t("reports.employeeContactInformation.labelEmployeeCellular"),
        label_employee_emial: t("reports.employeeContactInformation.labelEmployeeEmail"),
        label_employee_fax: t("reports.employeeContactInformation.labelEmployeeFax"),
        label_toll_free_number: t("reports.employeeContactInformation.labelTollFreeNumber"),
        label_emergency_contact: t("reports.employeeContactInformation.labelEmergencyContact"),
        label_home_phone: t("reports.employeeContactInformation.labelHomePhone"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const getOpenMediaSearchResultsPayload = ({
    mediaNumber,
    mediaDescription,
    drpCode,
    returnDateFrom,
    returnDateTo,
    currentStatus,
    logicalVault,
    logicalVaultCriteria,
}) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
        locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_report_title: t("reports.openMediaSearchResults.labelReportTitle"),
        label_search_result: t("reports.openMediaSearchResults.labelSearchResult"),
        label_media_number_column: t("reports.openMediaSearchResults.labelMediaNumberColumn"),
        label_return_date_column: t("reports.openMediaSearchResults.labelReturnDateColumn"),
        label_media_type_column: t("reports.openMediaSearchResults.labelMediaTypeColumn"),
        label_drp_code_column: t("reports.openMediaSearchResults.labelDrpCodeColumn"),
        label_current_status_column: t("reports.openMediaSearchResults.labelCurrentStatusColumn"),
        label_logical_vault_column: t("reports.openMediaSearchResults.labelLogicalVaultColumn"),
        label_in_descrepancy_column: t("reports.openMediaSearchResults.labelInDescrepancyColumn"),
        label_description: t("reports.openMediaSearchResults.labelDescription"),
        label_record_count: t("reports.openMediaSearchResults.labelRecordCount"),
        report_flag: "Y",
        volser: mediaNumber,
        descr: mediaDescription,
        drp_code: drpCode,
        from_return_date: returnDateFrom,
        to_return_date: returnDateTo,
        summary_type: currentStatus,
        logical_vault_id: logicalVault,
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("localizedServiceDetails.indefinite"),
        destroyed_phrase: t("reports.openMediaSearchResults.destroyed"),
        descr_criteria: mediaDescription,
        drpcode_criteria: drpCode,
        current_status_criteria: currentStatus,
        logical_vault_criteria: logicalVaultCriteria,
    };
};

export const getDiscrepancyDetailReportPayload = ({
    omDiscrepancyId,
    serviceDate,
    pendingReturnDate,
    resolution,
    location,
    detectedIn,
    cstomerMediaIdentifier,
    mediaType,
}) => {
    return {
        om_discrepancy_id: omDiscrepancyId,
        service_date: serviceDate,
        pending_return_date: pendingReturnDate,
        resolution: resolution,
        location: location,
        detected_in: detectedIn,
        cstomer_media_identifier: cstomerMediaIdentifier,
        media_type: mediaType,
        show_additional_system_comments: "1",
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_report_name: t("reports.labelReportName"),
        label_print_by_name: t("reports.labelPrintedBy"),
        label_additional_system_comments: t("reports.discrepancyDetail.labelAdditionalSystemComments"),
        label_authorized_by: t("reports.discrepancyDetail.labelAuthorizedBy"),
        label_branch: t("reports.discrepancyDetail.labelBranch"),
        label_bypass_reason: t("reports.discrepancyDetail.labelBypassReason"),
        label_card_id: t("reports.discrepancyDetail.labelCardId"),
        label_contact_date: t("reports.discrepancyDetail.labelContactDate"),
        label_contact_method: t("reports.discrepancyDetail.labelContactMethod"),
        label_contacted_by: t("reports.discrepancyDetail.labelContactedBy"),
        label_container_upload_errors: t("reports.discrepancyDetail.labelContainerUploadErrors"),
        label_courtesy_contact_information: t("reports.discrepancyDetail.labelCourtesyContactInformation"),
        label_courtesy_contact: t("reports.discrepancyDetail.labelCourtesyContact"),
        label_customer_caption3: t("reports.discrepancyDetail.labelCustomerCaption3"),
        label_customer_comments: t("reports.discrepancyDetail.labelCustomerComments"),
        label_customer_media_identifier: t("reports.discrepancyDetail.labelCustomerMediaIdentifier"),
        label_customer_name: t("reports.discrepancyDetail.labelCustomerName"),
        label_customer_number: t("reports.discrepancyDetail.labelCustomerNumber"),
        label_detected_date: t("reports.discrepancyDetail.labelDetectedDate"),
        label_detected_datetime_caption1: t("reports.discrepancyDetail.labelDetectedDatetimeCaption1"),
        label_detected_in: t("reports.discrepancyDetail.labelDetectedIn"),
        label_discrepancy_type_caption1: t("reports.discrepancyDetail.labelDiscrepancyTypeCaption1"),
        label_discrepancy_type: t("reports.discrepancyDetail.labelDiscrepancyType"),
        label_display_to_customer_date: t("reports.discrepancyDetail.labelDisplayToCustomerDate"),
        label_last_known_location: t("reports.discrepancyDetail.labelLastKnownLocation"),
        label_location: t("reports.discrepancyDetail.labelLocation"),
        label_logical_vault: t("reports.discrepancyDetail.labelLogicalVault"),
        label_media_number: t("reports.discrepancyDetail.labelMediaNumber"),
        label_media_type: t("reports.discrepancyDetail.labelMediaType"),
        label_om_discrepancy_details_caption: t("reports.discrepancyDetail.labelOmDiscrepancyDetailsCaption"),
        label_pending_return_date: t("reports.discrepancyDetail.labelPendingReturnDate"),
        label_person_contacted: t("reports.discrepancyDetail.labelPersonContacted"),
        label_personnel_caption3: t("reports.discrepancyDetail.labelPersonnelCaption3"),
        label_pickup_returned_date_caption3: t("reports.discrepancyDetail.labelPickupReturnedDateCaption3"),
        label_print_date: t("reports.discrepancyDetail.labelPrintDate"),
        label_report_title: t("reports.discrepancyDetail.labelReportTitle"),
        label_request_id: t("reports.discrepancyDetail.labelRequestId"),
        label_resolution: t("reports.discrepancyDetail.labelResolution"),
        label_resolved_by: t("reports.discrepancyDetail.labelResolvedBy"),
        label_resolved_date: t("reports.discrepancyDetail.labelResolvedDate"),
        label_run_caption3: t("reports.discrepancyDetail.labelRunCaption3"),
        label_run: t("reports.discrepancyDetail.labelRun"),
        label_scanned_barcode_caption3: t("reports.discrepancyDetail.labelScannedBarcodeCaption3"),
        label_service_date: t("reports.discrepancyDetail.labelServiceDate"),
        label_system_function_caption1: t("reports.discrepancyDetail.labelSystemFunctionCaption1"),
    };
};
export const scanVerificationHistoryResult = ({
    verifyResultId,
    list,
    createdDate,
    verifiedItems,
    originalVerificationDate,
    lastVerificationDate,
}) => {
    return {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        verify_result_id: verifyResultId ? verifyResultId : "0",
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        locale_id: getPersonalLocalId() ? getPersonalLocalId() : "1",
        language_locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        scanned_phrase: t("scanVerification.scannedPhrase"),
        customer_id: getSelectedCustomerId(),
        list: list,
        created_date: createdDate,
        verified_items: verifiedItems,
        original_verification_date: originalVerificationDate,
        last_verification_date: lastVerificationDate,
        label_page_number: t("reports.labelOf"),
        label_report_title: t("reports.scanVerificationReport.labelReportTitle.default"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_branch: "",
        label_report_name: t("reports.labelReportName"),
        label_user_name: "",
        label_comp_summery: t("reports.scanVerificationReport.labelCompSummery"),
        label_action: t("reports.scanVerificationReport.labelAction"),
        label_unique_items_scanned: t("reports.scanVerificationReport.labelUniqueItemsScanned"),
        label_list: t("reports.scanVerificationReport.labelList"),
        label_total_items_in_list: t("reports.scanVerificationReport.labelTotalItemsInList"),
        label_verified_items: t("reports.scanVerificationReport.labelVerifiedItems"),
        label_original_verification_user: t("reports.scanVerificationReport.labelOriginalVerificationUser"),
        label_original_verification_datetime: t("reports.scanVerificationReport.labelOriginalVerificationDateTime"),
        label_last_verification_user: t("reports.scanVerificationReport.labelLastVerificationUser"),
        label_last_verification_datetime: t("reports.scanVerificationReport.labelLastVerificationDateTime"),
        label_created_datetime: t("reports.scanVerificationReport.labelCreatedDateTime"),
        label_unresolved_verification: t("reports.scanVerificationReport.labelUnresolvedVerification"),
        label_issue_a: t("reports.scanVerificationReport.labelIssueA"),
        label_media_number_a: t("reports.scanVerificationReport.labelMediaNumberA"),
        label_last_comment_a: t("reports.scanVerificationReport.labelLastCommentA"),
        label_orgverification_user_a: t("reports.scanVerificationReport.labelOrgVerificationUserA"),
        label_resolved_user_datetime_a: t("reports.scanVerificationReport.labelResolvedUserDateTimeA"),
        label_resolved_verification: t("reports.scanVerificationReport.labelResolvedVerification"),
        label_issue_b: t("reports.scanVerificationReport.labelIssueB"),
        label_media_number_b: t("reports.scanVerificationReport.labelMediaNumberB"),
        label_last_comment_b: t("reports.scanVerificationReport.labelLastCommentB"),
        label_orgverification_user_b: t("reports.scanVerificationReport.labelOrgVerificationUserB"),
        label_resolved_user_datetime_b: t("reports.scanVerificationReport.labelResolvedUserDateTimeB"),
        label_scanned_items_c: t("reports.scanVerificationReport.labelScannedItemsC"),
        label_media_number_c: t("reports.scanVerificationReport.labelMediaNumberC"),
    };
};

export const inboundOutboundSummaryReport = ({
    logicalVault = "",
    programType = "A",
    dateFrom = "",
    dateTo = "",
    limitRows = "N",
    detailFlag = "N",
    requestId = "",
    labelReportTitle = "",
}) => {
    return {
        logical_vault: logicalVault,
        program_type: programType,
        date_from: dateFrom,
        date_to: dateTo,
        limit_rows: limitRows,
        detail_flag: detailFlag,
        request_id: requestId,
        label_report_title: labelReportTitle,
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        selection_value: t("reports.inboundOutboundSummaryReport.selectionValue"),
        logical_vault_r: t("reports.inboundOutboundSummaryReport.logicalVaultR"),
        date_value: t("reports.inboundOutboundSummaryReport.dateValue"),
        inbound_irm: t("reports.inboundOutboundSummaryReport.inboundIrm"),
        newly_issued: t("reports.inboundOutboundSummaryReport.newlyIssued"),
        outbound_irm: t("reports.inboundOutboundSummaryReport.outboundIrm"),
        total_value: t("reports.inboundOutboundSummaryReport.totalValue"),
        total_inbound_outbound: t("reports.inboundOutboundSummaryReport.totalInboundOutbound"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
        label_page: t("reports.labelPage"),
    };
};

export const inboundOutboundDetailReport = ({
    logicalVault = "",
    programType = "A",
    dateFrom = "",
    dateTo = "",
    limitRows = noFlag,
    detailFlag = yesFlag,
    labelReportTitle = "",
    districtName = getSelectedCustomerDistrictName(),
}) => {
    return {
        program_type: programType,
        logical_vault: logicalVault,
        district_id: getSelectedCustomerDb(),
        date_from: dateFrom,
        date_to: dateTo,
        limit_rows: limitRows,
        detail_flag: detailFlag,
        label_report_title: labelReportTitle,
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_Selection_Criteria: t("reports.inboundOutboundDetailReports.selectionCriteria"),
        label_user_name: t("reports.labelReportDate"),
        label_report_name: t("reports.inboundOutboundDetailReports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_Logical_Vault: t("reports.inboundOutboundSummaryReport.logicalVaultR"),
        label_Date: t("reports.inboundOutboundSummaryReport.dateValue"),
        label_Inbound_to_Iron_Mountain: t("reports.inboundOutboundDetailReports.labelInboundToIronMountain"),
        label_Newly_Issued: t("reports.inboundOutboundDetailReports.labelNewlyIssued"),
        label_Outbound_from_IronMountain: t("reports.inboundOutboundDetailReports.labelOutboundFromIronMountain"),
        district_name: districtName,
    };
};

export const getCorrectiveActionRequestDetailPayload = ({ carId }) => {
    return {
        car_id: carId,
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId() ? getPersonalLocalId() : "1",
        language_locale_id: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
        open_media_list_phrase: t("reports.correctiveActionRequestDetail.openMediaListPhrase"),
        containers_phrase: t("reports.correctiveActionRequestDetail.containersPhrase"),
        carDescription: t("reports.correctiveActionRequestDetail.carDescription"),
        msg_om_missing_pick: t("reports.correctiveActionRequestDetail.msgOmMissingPick"),
        msg_cont_miss_prep: t("reports.correctiveActionRequestDetail.msgContMissPrep"),
        msg_cont_miss_arrival: t("reports.correctiveActionRequestDetail.msgContMissArrival"),
        msg_incomplete_stop: t("reports.correctiveActionRequestDetail.msgIncompleteStop"),
        label_report_title: t("reports.correctiveActionRequestDetail.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_name: t("reports.labelReportName"),
        label_page: "Page",
        label_of: t("reports.labelOf"),
        label_id: t("reports.correctiveActionRequestDetail.labelId"),
        label_status: t("reports.correctiveActionRequestDetail.labelStatus"),
        label_date: t("reports.correctiveActionRequestDetail.labelDate"),
        label_type: t("reports.correctiveActionRequestDetail.labelType"),
        label_description: t("reports.correctiveActionRequestDetail.labelDescription"),
        label_failure_point: t("reports.correctiveActionRequestDetail.labelFailurePoint"),
        label_root_cause: t("reports.correctiveActionRequestDetail.labelRootCause"),
        label_resolution: t("reports.correctiveActionRequestDetail.labelResolution"),
        label_comments: t("reports.correctiveActionRequestDetail.labelComments"),
    };
};

export const vaultStatusReportPayload = ({
    customer = getAllCaps,
    targetMediaType = getAllCaps,
    detectedIn = getAllCaps,
    discrepancyType = getAllCaps,
    includeOtherOpenMediaException = "",
    customerIdList = "",
    startDate = "",
    endDate = "",
    customerFacingFlag = yesFlag,
    fileSectionTypeIdList = "",
    systemFunctionIdList = "",
    discrepancyTypeCode = "",
    openMediaDetectedInCollection = [],
    containerDetectedInCollection = [],
}) => {
    return {
        district_id: getSelectedCustomerDb(),
        customer: customer,
        top: 10,
        target_media_type: targetMediaType,
        detected_in: detectedIn,
        discrepancy_type: discrepancyType,
        include_other_open_media_exception: includeOtherOpenMediaException,
        customer_id_list: customerIdList,
        start_date: startDate,
        end_date: endDate,
        customer_facing_flag: customerFacingFlag,
        file_section_type_id_list: fileSectionTypeIdList,
        system_function_id_list: systemFunctionIdList,
        discrepancy_type_code: discrepancyTypeCode,
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_date: t("reports.labelReportDate"),
        label_report_display_name: t("reports.labelReportName"),
        report_type: t("reports.vaultStatusReport.reportType"),
        label_report_name: t("reports.vaultStatusReport.labelReportName"),
        label_report_type: t("reports.vaultStatusReport.labelReportType"),
        label_customer: t("reports.vaultStatusReport.labelCustomer"),
        label_top: t("reports.vaultStatusReport.labelTop"),
        label_branch: t("reports.vaultStatusReport.labelBranch"),
        label_report_date_range: t("reports.vaultStatusReport.labelReportDateRange"),
        label_to: t("reports.vaultStatusReport.labelTo"),
        label_media: t("reports.vaultStatusReport.labelMedia"),
        label_discrepancy_type: t("reports.vaultStatusReport.labelDiscrepancyType"),
        label_include_other_open_meida_exceptions: t("reports.vaultStatusReport.labelIncludeOtherOpenMeidaExceptions"),
        label_account_summary: t("reports.vaultStatusReport.labelAccountSummary"),
        label_no_of_inboud: t("reports.vaultStatusReport.labelNoOfInboud"),
        label_no_of_outbound: t("reports.vaultStatusReport.labelNoOfOutbound"),
        label_no_of_media_audited: t("reports.vaultStatusReport.labelNoOfMediaAudited"),
        label_no_of_destruction: t("reports.vaultStatusReport.labelNoOfDestruction"),
        label_open_meida_discrepancy_summary: t("reports.vaultStatusReport.labelOpenMeidaDiscrepancySummary"),
        label_logical_vault: t("reports.vaultStatusReport.labelLogicalVault"),
        label_not_at_iron_mountain: t("reports.vaultStatusReport.labelNotAtIronMountain"),
        label_not_on_customer_inventory: t("reports.vaultStatusReport.labelNotOnCustomerInventory"),
        label_open_media_other_exceptions: t("reports.vaultStatusReport.labelOpenMediaOtherExceptions"),
        label_exception_type: t("reports.vaultStatusReport.labelExceptionType"),
        label_exception_count: t("reports.vaultStatusReport.labelExceptionCount"),
        label_container_discrepancy_summary: t("reports.vaultStatusReport.labelContainerDiscrepancySummary"),
        label_detected_in: t("reports.vaultStatusReport.labelDetectedIn"),
        label_missing: t("reports.vaultStatusReport.labelMissing"),
        label_not_received: t("reports.vaultStatusReport.labelNotReceived"),
        label_unexpected: t("reports.vaultStatusReport.labelUnexpected"),
        label_no_valid_return_date: t("reports.vaultStatusReport.labelNoValidReturnDate"),
        label_incomplete_stop: t("reports.vaultStatusReport.labelIncompleteStop"),
        label_no_of_unresolved_discrepancy: t("reports.vaultStatusReport.labelNoOfUnresolvedDiscrepancy"),
        label_name: t("reports.searchEmployee.labelName"),
        open_media_detected_in_collection: openMediaDetectedInCollection,
        container_detected_in_collection: containerDetectedInCollection,
        region: process.env.REACT_APP_CF_REGION,
    };
};

export const libraryAuditReport = ({ auditId = "", ssieFlag = yesFlag, auditTypeCode = "", requestId = "" }) => {
    return {
        audit_id: auditId,
        ssie_flag: ssieFlag,
        audit_type_code: auditTypeCode,
        request_id: requestId,
        missing_phrase: t("reports.libraryAuditReport.missingPhrase"),
        unexpected_phrase: t("reports.libraryAuditReport.unexpectedPhrase"),
        label_report_title: t("reports.libraryAuditReport.labelReportTitle"),
        label_customer_number: t("reports.libraryAuditReport.labelCustomerNumber"),
        label_report_date: t("reports.libraryAuditReport.labelReportDate"),
        label_printed_by: t("reports.libraryAuditReport.labelPrintedBy"),
        completed_date: t("reports.libraryAuditReport.completedDate"),
        completed_by: t("reports.libraryAuditReport.completedBy"),
        requested_by: t("reports.libraryAuditReport.requestedBy"),
        status: t("reports.libraryAuditReport.status"),
        logical_vaults: t("reports.libraryAuditReport.logicalVaults"),
        label_audit_id: t("reports.libraryAuditReport.labelAuditId"),
        media_in_inventory: t("reports.libraryAuditReport.mediaInInventory"),
        exceptions: t("reports.libraryAuditReport.exceptions"),
        Accurancy: t("reports.libraryAuditReport.accuracy"),
        media_number: t("reports.libraryAuditReport.mediaNumber"),
        exception_type: t("reports.libraryAuditReport.exceptionType"),
        logical_vault: t("reports.libraryAuditReport.logicalVault"),
        logical_vault_1: t("reports.libraryAuditReport.logicalVault1"),
        label_report_title_1: t("reports.libraryAuditReport.labelReportTitle1"),
        label_report_title_2: t("reports.libraryAuditReport.labelReportTitle2"),
        comments: t("reports.comments"),
        label_report_name: t("reports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
    };
};

export const searchContainerReport = ({
    containerNumberList = "",
    description = "",
    fromDate = "",
    toDate = "",
    summaryType = "",
    mediaType = "",
    containerId = "",
    customerMediaDescription = "",
    allowCustomerMediaIdentifiers = false,
}) => {
    return {
        district_id: getSelectedCustomerDb(),
        container_number_list: containerNumberList,
        descr: description,
        from_return_date: fromDate,
        to_return_date: toDate,
        summary_type: summaryType,
        media_type_id: mediaType,
        container_id: containerId,
        customer_media_descr: customerMediaDescription,
        prepare_for_pickup_flag: "N",
        DisplayCustomerMediaID: allowCustomerMediaIdentifiers ? "N" : "Y",
        indefinite_phrase: t("containerPickup.indefinite"),
        all_phrase: `<${convertToLocaleUpperCase(t("common.all"))}>`,
        all_excluding_deleted_phrase: t("containerPickup.allExcludingDeleted"),
        label_page: t("reports.labelPage"),
        label_customer_number: t("reports.searchContainerReport.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
        label_report_title: t("reports.searchContainerReport.labelReportTitle"),
        label_selection_value: t("reports.searchContainerReport.labelSelectionValue"),
        label_container_number: t("reports.searchContainerReport.labelContainerNumber"),
        label_media_type: t("reports.searchContainerReport.labelMediaType"),
        lable_CustomerMediaIdentifier: t("reports.searchContainerReport.labelCustomerMediaIdentifier"),
        label_return_date: t("reports.searchContainerReport.labelReturnDate"),
        Current_Status: t("reports.searchContainerReport.currentStatus"),
        Issued_Date: t("reports.searchContainerReport.issuedDate"),
        Deleted_Date: t("reports.searchContainerReport.deletedDate"),
        label_description: t("reports.searchContainerReport.labelDescription"),
        RetentionProfile: t("reports.searchContainerReport.retentionProfile"),
        label_total_record_count: t("reports.searchContainerReport.labelTotalRecordCount"),
    };
};

export const inboundOutboundSummaryImeReport = ({
    logicalVault = "",
    programType = "A",
    dateFrom = "",
    dateTo = "",
    limitRows = "N",
    detailFlag = "N",
    requestId = "",
    labelReportTitle = "",
}) => {
    return {
        logical_vault: logicalVault,
        program_type: programType,
        date_from: dateFrom,
        date_to: dateTo,
        limit_rows: limitRows,
        detail_flag: detailFlag,
        request_id: requestId,
        label_report_title: labelReportTitle,
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        selection_value: t("reports.inboundOutboundSummaryReport.selectionValue"),
        logical_vault_r: t("reports.inboundOutboundSummaryReport.logicalVaultR"),
        date_value: t("reports.inboundOutboundSummaryReport.dateValue"),
        inbound_irm: t("reports.inboundOutboundSummaryReport.inboundIrm"),
        newly_issued: t("reports.inboundOutboundSummaryReport.newlyIssued"),
        outbound_irm: t("reports.inboundOutboundSummaryReport.outboundIrm"),
        total_value: t("reports.inboundOutboundSummaryReport.totalValue"),
        total_inbound_outbound: t("reports.inboundOutboundSummaryReport.totalInboundOutbound"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
        label_page: t("reports.labelPage"),
        inventary_items: t("reports.inboundOutboundSummaryReport.inventaryItems"),
        foot_note: t("reports.inboundOutboundSummaryReport.footNote"),
        arrived_at_im: t("reports.inboundOutboundSummaryReport.arrivedAtIm"),
        arrived_at_cs: t("reports.inboundOutboundSummaryReport.arrivedAtCs"),
    };
};

export const getGlobalMediaLocatorResultsReport = ({ mediaNumber, userName, customerIdList }) => {
    return {
        media_number: mediaNumber,
        login: userName,
        customer_id_list: customerIdList,
        vt_available_flag: "N",
        sent_to_phrase: t("reports.globalMediaLocatorResultsReport.sentTo0"),
        container_phrase: t("reports.globalMediaLocatorResultsReport.containerPhrase"),
        open_media_phrase: t("reports.globalMediaLocatorResultsReport.openMediaPhrase"),
        media_in_container_phrase: t("reports.globalMediaLocatorResultsReport.mediaInContainerPhrase"),
        in_phrase: t("reports.globalMediaLocatorResultsReport.inPhrase"),
        label_report_title: t("reports.globalMediaLocatorResultsReport.labelReportTitle"),
        label_report_date: t("reports.globalMediaLocatorResultsReport.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_search_criteria: t("reports.globalMediaLocatorResultsReport.labelSearchCriteria"),
        label_search_results_header: t("reports.globalMediaLocatorResultsReport.labelSearchResultsHeader"),
        label_media_number: t("reports.globalMediaLocatorResultsReport.labelMediaNumber"),
        label_media_type: t("reports.globalMediaLocatorResultsReport.labelMediaType"),
        label_branch: t("reports.globalMediaLocatorResultsReport.labelBranch"),
        label_customer_number: t("reports.globalMediaLocatorResultsReport.labelCustomerNumber"),
        label_return_date_or_currenct_status: t(
            "reports.globalMediaLocatorResultsReport.labelReturnDateOrCurrenctStatus"
        ),
        label_description: t("reports.globalMediaLocatorResultsReport.labelDescription"),
        label_report_name: t("reports.labelReportName"),
        label_of: t("reports.labelOf"),
        label_page: t("reports.labelPage"),
    };
};

export const inboundOutboundDetailIMEReport = ({
    logicalVault = "",
    programType = "A",
    dateFrom = "",
    dateTo = "",
    limitRows = noFlag,
    detailFlag = yesFlag,
    labelReportTitle = "",
    districtName = getSelectedCustomerDistrictName(),
}) => {
    return {
        program_type: programType,
        logical_vault: logicalVault,
        date_from: dateFrom,
        date_to: dateTo,
        limit_rows: limitRows,
        detail_flag: detailFlag,
        label_report_title: labelReportTitle,
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        selection_value: t("reports.inboundOutboundDetailReports.selectionCriteria"),
        logical_vault_r: t("reports.inboundOutboundSummaryReport.logicalVaultR"),
        date_value: t("reports.inboundOutboundSummaryReport.dateValue"),
        inbound_irm: t("reports.inboundOutboundSummaryReport.inboundIrm"),
        newly_issued: t("reports.inboundOutboundDetailReports.labelNewlyIssued"),
        outbound_irm: t("reports.inboundOutboundDetailReports.labelOutboundFromIronMountain"),
        total_value: t("reports.inboundOutboundSummaryReport.totalValue"),
        total_inbound_outbound: t("reports.inboundOutboundSummaryReport.totalInboundOutbound"),
        label_report_name: t("reports.inboundOutboundDetailReports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        inventary_items: t("reports.inboundOutboundSummaryReport.inventaryItems"),
        foot_note: t("reports.inboundOutboundSummaryReport.footNote"),
        arrived_at_im: t("reports.inboundOutboundSummaryReport.arrivedAtIm"),
        arrived_at_cs: t("reports.inboundOutboundSummaryReport.arrivedAtCs"),
        CustomerMediaIdentifier: t("reports.searchContainerReport.labelCustomerMediaIdentifier"),
        total_media: t("reports.inboundOutboundDetailReports.totalMedia"),
        district_name: districtName,
    };
};

export const discrepancySearchReport = ({
    customerIdList = "",
    mediaProgramType = "all",
    discrepancyStatus = "All",
    logicalVaultId = "",
    fromDetectedDate = "",
    toDetectedDate = "",
    fromResolvedDate = "",
    toResolvedDate = "",
    mediaNo = "",
    districtId = "",
    uiDiscrepancyType = "",
    uiMedia = "All",
    uiLogicalVault = "",
    uiDetectedIn = "All",
    detectedInIds = "",
    detectedInDecs = "",
    detectedInContexts = "",
    uniqueIds = "",
    alternateId = "",
    idContext = "",
    culture = "",
}) => {
    return {
        main_district_id: "50",
        district_id: districtId,
        detected_in_id: detectedInIds,
        detected_in_descr: detectedInDecs,
        detected_in_context: detectedInContexts,
        operational_mode_id: "3",
        district_id_for_function: districtId,
        customer_id_list: customerIdList,
        discrepancy_status: discrepancyStatus,
        media_program_type: mediaProgramType,
        logical_vault_id: logicalVaultId,
        from_detected_date: fromDetectedDate,
        to_detected_date: toDetectedDate,
        from_resolved_date: fromResolvedDate,
        to_resolved_date: toResolvedDate,
        volser_number: mediaNo,
        service_date: "",
        run_id: "",
        exclude_unresolved_no_action_flag: "Y",
        exclude_auto_resolve_flag: "N",
        om_cont_audit_id: "",
        om_cont_discrepancy_id: "",
        car_id: "",
        ga_max_num_search_results: "1000",
        ga_courtesy_followup_days: "3",
        customer_media_descr: "",
        region: process.env.REACT_APP_CF_REGION,
        district_name: getSelectedCustomerDistrictName(),
        // -----------NewModifications----------------------
        uniqueid: uniqueIds,
        alternateid: alternateId,
        idcontext: idContext,
        // -----------ValuetobepassfromUI----------------------
        ui_discrepancy_type: uiDiscrepancyType,
        ui_media_hash: mediaNo,
        ui_media: uiMedia,
        ui_logical_vault: uiLogicalVault,
        ui_detected_in: uiDetectedIn,
        ui_run: "",
        // -----------ReportHeaderLabels----------------------,
        label_report_title: t("reports.discrepancySearchReport.labelReportTitle"),
        label_custome_number_caption: `${t("reports.discrepancySearchReport.labelCustomer")}:`,
        label_discrepancy_type_caption: `${t("reports.discrepancySearchReport.labelDiscrepancyType")}:`,
        label_meida_type_caption: `${t("reports.discrepancySearchReport.labelMedia")}:`,
        label_dateced_from_date_caption: `${t("reports.discrepancySearchReport.labelDetectedFrom")}:`,
        label_resolved_from_date_caption: `${t("reports.discrepancySearchReport.labelResolvedFrom")}:`,
        label_customer_media_identifier_caption: t("reports.discrepancySearchReport.labelCustomerMediaIdentifier"),
        label_discrepancy_status_caption: `${t("reports.discrepancySearchReport.labelDiscrepancyStatus")}:`,
        label_detected_in_caption: `${t("reports.discrepancySearchReport.labelDetectedIn")}:`,
        label_media_number_caption: t("reports.discrepancySearchReport.labelMedia"),
        label_detected_to_date_caption: `${t("reports.discrepancySearchReport.labelDetectedTo")}:`,
        label_resolved_to_date_caption: `${t("reports.discrepancySearchReport.labelResolvedTo")}:`,
        label_branch_caption: `${t("reports.discrepancySearchReport.labelBranch")}:`,
        label_logical_valult_caption: `${t("reports.discrepancySearchReport.labelLogicalVault")}:`,
        label_service_date_caption: `${t("reports.discrepancySearchReport.labelServiceDate")}:`,
        label_run_caption: `${t("reports.discrepancySearchReport.labelRun")}:`,
        label_exclude_auto_resolved_caption: `${t("reports.discrepancySearchReport.labelExcludeAutoResolved")}:`,
        // -----------ReportContainerSubreportLabels----------------------
        label_container_discrepancy_search_caption: t("reports.discrepancySearchReport.labelContainer"),
        label_customer_number_caption2: t("reports.discrepancySearchReport.labelCustomer"),
        label_media_number_caption2: t("reports.discrepancySearchReport.labelMedia"),
        label_media_type_caption2: t("reports.discrepancySearchReport.labelMediaType"),
        label_discrepancy_type_caption2: t("reports.discrepancySearchReport.labelDiscrepancyType"),
        label_detected_date_caption2: t("reports.discrepancySearchReport.labelDetectedDate"),
        label_detected_in_caption2: t("reports.discrepancySearchReport.labelDetectedIn"),
        label_pending_return_date_caption2: t("reports.discrepancySearchReport.labelPendingReturnDate"),
        label_car_id_caption2: t("reports.discrepancySearchReport.labelCarId"),
        label_resolved_date_caption2: t("reports.discrepancySearchReport.labelResolvedDate"),
        label_resolution_caption2: t("reports.discrepancySearchReport.labelResolution"),
        label_customer_media_identifier_caption2: t("reports.discrepancySearchReport.labelCustomerMediaIdentifier"),
        label_display_to_customer_date_caption2: t("reports.discrepancySearchReport.labelDisplayToCustomerDate"),
        // -----------ReportOpenMediaSubreportLabels----------------------,
        label_om_discrepancy_search_caption: t("reports.discrepancySearchReport.labelOpenMedia"),
        label_customer_number_caption1: t("reports.discrepancySearchReport.labelCustomer"),
        label_media_number_caption1: t("reports.discrepancySearchReport.labelMedia"),
        label_discrepancy_type_caption1: t("reports.discrepancySearchReport.labelDiscrepancyType"),
        label_detected_in_caption1: t("reports.discrepancySearchReport.labelDetectedIn"),
        label_detected_date_caption1: t("reports.discrepancySearchReport.labelDetectedDate"),
        label_resolution_caption1: t("reports.discrepancySearchReport.labelResolution"),
        label_car_id_caption1: t("reports.discrepancySearchReport.labelCarId"),
        label_logical_vault_caption1: t("reports.discrepancySearchReport.labelLogicalVault"),
        label_media_type_caption1: t("reports.discrepancySearchReport.labelMediaType"),
        label_display_to_customer_date_caption1: t("reports.discrepancySearchReport.labelDisplayToCustomerDate"),
        label_resolved_date_caption1: t("reports.discrepancySearchReport.labelResolvedDate"),
        label_print_date: t("reports.discrepancySearchReport.labelPrintDate"),
        label_print_by_name: t("reports.discrepancySearchReport.labelPrintBy"),
        label_report_name: t("reports.inboundOutboundDetailReports.labelReportName"),
        label_of: t("reports.labelOf"),
        label_page: t("reports.labelPage"),
        culture: culture,
    };
};
