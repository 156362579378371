import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./RequestData.styles";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { formatDate } from "src/utils/dateHelper";

const RequestData = ({ requestData, hasSubmitAccess, hasCancelAccess }) => {
    const cancelDateTime =
        requestData?.Cancelled === "1" && requestData?.cancel_datetime ? formatDate(requestData?.cancel_datetime) : "";
    const { t } = useTranslation();

    return (
        <>
            <Box sx={styles.container}>
                <Typography>
                    <Trans
                        i18nKey={
                            hasSubmitAccess && hasCancelAccess
                                ? t("requestDetails.changeCommentsDesc")
                                : t("requestDetails.requestDetailsDesc")
                        }
                    />
                </Typography>
            </Box>
            <Box sx={styles.reqDetailsContainer}>
                <Box sx={styles.reqDetailRow}>
                    <SecureSyncReadOnlyTextField
                        value={formatDate(requestData?.service_date) || " "}
                        label={t("requestDetails.serviceDate")}
                    />
                    <SecureSyncReadOnlyTextField
                        value={requestData?.status_code || " "}
                        label={t("requestDetails.requestStatus")}
                    />
                    <SecureSyncReadOnlyTextField
                        value={requestData?.descr || " "}
                        label={t("requestDetails.requestType")}
                    />
                    <SecureSyncReadOnlyTextField
                        value={formatDate(requestData?.logged_datetime) || " "}
                        label={t("requestDetails.dateRequested")}
                    />
                </Box>
                <Box sx={styles.rowGrid}>
                    <SecureSyncReadOnlyTextField
                        value={requestData?.request_csl_number || " "}
                        label={t("formFields.serviceLocation")}
                    />
                    <SecureSyncReadOnlyTextField
                        value={requestData?.personnel_full_name || " "}
                        label={t("requestDetails.requestedBy")}
                    />
                    {requestData?.Cancel_By && (
                        <>
                            {" "}
                            <SecureSyncReadOnlyTextField
                                value={cancelDateTime || " "}
                                label={t("requestDetails.dateCanceled")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={requestData?.Cancel_By || " "}
                                label={t("requestDetails.canceledBy")}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default RequestData;
