import { Box, Divider, InputLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./OtherRequests.styles";

const OtherRequests = ({ comment }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.container}>
                <Typography variant="h5" sx={styles.heading}>
                    {t("requestDetails.otherRequests")}
                </Typography>
                <InputLabel SX={styles.requestDetails}>{t("requestDetails.requestDetails")}</InputLabel>
                <Typography variant="body2" sx={styles.requestDetailBody}>
                    {comment}
                </Typography>
            </Box>
            <Divider />
        </>
    );
};

export default OtherRequests;
