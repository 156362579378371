import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import DeliverMediaModal from "../DeliverMediaModal/DeliverMediaModal";
import styles from "./AddContainers.styles";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import { enterKeyCode } from "src/constants/processMediaConstants";
import { handleFileRead, insertBulkData } from "src/utils/requestHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";
import { sortHelper } from "src/utils/sortHelper";

const maxCount = 150;
const AddContainers = ({
    selectedContainer,
    allowCustomerMediaIdentifiers,
    setContainerList,
    setErrorMessage,
    gridCount = 0,
    containerTypeList,
    setContainerTypeList,
    downloadInstructionFile,
    maxContainer,
    sequenceNumber,
    incrementSequenceNumber,
    initialErrorState,
    hasValidatedData,
}) => {
    const { t } = useTranslation();
    const noDataMessage = t("messages.pleaseAddContainer");
    const columns = [
        {
            field: "delete",
            width: "10px",
            headerName: "",
            sortable: false,
            renderCell: (params) => (
                <SecureSyncIconTooltip
                    title={t("deliverMedia.enteredMediaRemoveButton")}
                    icon={
                        <IconButton
                            onClick={() => {
                                deleteSingleMediaType(params.id);
                            }}
                        >
                            <ClearIcon sx={styles.clearIcon} />
                        </IconButton>
                    }
                ></SecureSyncIconTooltip>
            ),
        },
        { field: "mediaNumber", headerName: convertToLocaleUpperCase(t("common.containers")), flex: 1 },
    ];
    const [containerNumber, setContainerNumber] = useState("");
    const [isSelectedContainerModalOpen, setIsSelectedContainerModalOpen] = useState(false);

    useEffect(() => {
        setContainerList(containerTypeList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerTypeList]);

    const addContainerHandler = async () => {
        if (containerNumber) {
            if (maxCount > gridCount + containerTypeList.length) {
                addMediaType(containerNumber);
                setContainerNumber("");
                setErrorMessage(initialErrorState);
            } else {
                setErrorMessage({
                    show: true,
                    status: "error",
                    message: <Trans i18nKey="messages.maxContainer" values={{ maxCount: maxCount }} />,
                });
            }
        }
    };

    const enterToAddScan = (event) => {
        if (event.keyCode === enterKeyCode) {
            addContainerHandler();
        }
    };

    const addMediaType = (value) => {
        const haveDuplicates = containerTypeList.some((item) => item.mediaNumber === convertToLocaleUpperCase(value));
        if (!haveDuplicates) {
            setContainerTypeList((prevData) => [
                { id: uuidv4(), sequenceNumber, mediaNumber: convertToLocaleUpperCase(value) },
                ...prevData,
            ]);
            incrementSequenceNumber(1);
        }
    };

    const deleteSingleMediaType = (id) => {
        const mediaArray = containerTypeList.filter((val) => val.id !== id);
        setContainerTypeList(mediaArray);
    };

    const fileReadHandler = async (event) => {
        const {
            csvData,
            hasError,
            errorMsg = "",
        } = await handleFileRead(event, maxCount, maxContainer, containerTypeList.length + gridCount);
        const uniqueRows = [...new Set(csvData)];
        if (!hasError) {
            const containerTypeData = insertBulkData(uniqueRows, [], sequenceNumber, hasValidatedData);
            setContainerTypeList((prevData) => {
                return [...containerTypeData.reverse(), ...prevData];
            });
            incrementSequenceNumber(containerTypeData.length);
            setErrorMessage(initialErrorState);
        } else {
            setErrorMessage({
                status: "error",
                show: hasError,
                message: errorMsg,
            });
        }
    };

    const openSelectedListModalHandler = () => {
        setIsSelectedContainerModalOpen(true);
    };

    const closeSelectedListModalHandler = () => {
        setIsSelectedContainerModalOpen(false);
    };

    const handleSubmit = (selectedContainerList) => {
        const selectedContainerListData = [];
        for (let i = 0; i < selectedContainerList.length; i++) {
            const container = selectedContainer.containerList.find(
                (container) => container.container_number === selectedContainerList[i]
            );
            if (allowCustomerMediaIdentifiers && container.customer_media_identifier) {
                selectedContainerListData.push(container.customer_media_identifier);
            } else {
                selectedContainerListData.push(container.container_number);
            }
        }
        const containerTypeData = insertBulkData(selectedContainerListData, [], sequenceNumber, hasValidatedData);
        setContainerTypeList((prevData) => {
            return [...containerTypeData.reverse(), ...prevData];
        });
        incrementSequenceNumber(containerTypeData.length);
    };

    const selectListColumns = useMemo(() => {
        const baseColumns = [
            { field: "container_number", headerName: convertToLocaleUpperCase(t("tableHeader.containerNumber")) },
            allowCustomerMediaIdentifiers && {
                field: "customer_media_identifier",
                headerName: convertToLocaleUpperCase(t("tableHeader.customerMediaIdentifier")),
            },
            { field: "status", headerName: convertToLocaleUpperCase(t("tableHeader.status")) },
            { field: "description", headerName: convertToLocaleUpperCase(t("common.description")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowCustomerMediaIdentifiers]);

    const sortedContainerList = sortHelper({
        data: selectedContainer?.containerList || [],
        primaryProperty: "container_number",
        sortOrder: "asc",
    });
    const getRowId = (row) => row.container_number;

    return (
        <SecureSyncTableCard
            title={
                <Typography variant="h6" sx={styles.cardHederText}>
                    {t("deliverMedia.enterContainer")}
                </Typography>
            }
        >
            {isSelectedContainerModalOpen && (
                <DeliverMediaModal
                    rowData={sortedContainerList}
                    onClose={closeSelectedListModalHandler}
                    allowCustomerMediaIdentifiers={allowCustomerMediaIdentifiers}
                    handleSubmit={handleSubmit}
                    getRowId={getRowId}
                    modelTitle={t("deliverMedia.containerModel")}
                    description={
                        <Trans
                            i18nKey={"deliverMedia.containerModalDesc"}
                            values={{ buttonLabel: t("buttonLabels.select") }}
                        />
                    }
                    columns={selectListColumns}
                />
            )}
            <Box sx={styles.allPadding16}>
                <Typography variant="h6" sx={styles.cardDesc}>
                    <Trans
                        i18nKey={"deliverMedia.enterContainerNumber"}
                        values={{ buttonLabel: t("buttonLabels.add") }}
                    />
                </Typography>
                <Box sx={styles.boxContainer}>
                    <TextField
                        label={t("common.containerNumber")}
                        name="mediaNumber"
                        variant="outlined"
                        size="small"
                        value={containerNumber}
                        onChange={(e) => {
                            setContainerNumber(e.target.value);
                        }}
                        sx={styles.textFieldWidth}
                        onKeyDown={enterToAddScan}
                    />
                    <Button variant="outlined" onClick={addContainerHandler}>
                        {t("buttonLabels.add")}
                    </Button>
                    <Button variant="outlined" onClick={openSelectedListModalHandler}>
                        {t("buttonLabels.selectFromList")}
                    </Button>
                    <SecureSyncIconTooltip title={t("messages.selectListToolTip")} placement="right" />
                </Box>
                <Typography sx={styles.uploadText}>
                    <Trans i18nKey={"deliverMedia.containerUpload"} values={{ buttonLabel: t("common.uploadFile") }} />
                </Typography>
                <Box sx={styles.bottomPadding}>
                    <Typography style={styles.importFile}>{t("common.importFile")} *</Typography>
                    <Box display="flex" alignItems="center" justifyContent="left" gap={2}>
                        <SecureSyncFileUpload
                            startIcon={<UploadIcon />}
                            variant="contained"
                            onChange={fileReadHandler}
                            accept=".txt"
                            name={t("common.uploadFile")}
                        />
                        <SecureSyncIconTooltip
                            title={t("containerPickup.fileFormatHelp")}
                            placement="right"
                            onClick={downloadInstructionFile}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.tblCardContent}>
                <SecureSyncTable
                    rows={containerTypeList}
                    columns={columns}
                    tableMinWidth="100px"
                    noDataMessage={noDataMessage}
                    getRowId={(row) => row.mediaNumber}
                    headerHeight="36px"
                    rowHeight="32px"
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default AddContainers;
