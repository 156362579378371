import { useTheme } from "@mui/material";

const ArrowDown = ({ fill, fillOpacity, ...props }) => {
    const theme = useTheme();
    const fillColor = fill ? fill : theme.palette.common.black;
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="arrow-down">
                <path
                    id="Vector (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.46967 0.531193C0.762563 0.2383 1.23744 0.2383 1.53033 0.531193L6.46972 5.47059C6.76016 5.76103 7.23984 5.76103 7.53028 5.47059L12.4697 0.531193C12.7626 0.2383 13.2374 0.2383 13.5303 0.531193C13.8232 0.824087 13.8232 1.29896 13.5303 1.59185L8.59094 6.53125C7.71471 7.40747 6.28529 7.40747 5.40906 6.53125L0.46967 1.59185C0.176777 1.29896 0.176777 0.824087 0.46967 0.531193Z"
                    fill={fillColor}
                    fillOpacity={fillOpacity}
                />
            </g>
        </svg>
    );
};

export default ArrowDown;
