const styles = {
    iconColor: { color: "primary.main" },
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    starIcon: { color: "im_red.500" },
    tableCaptionWrapper: { p: "16px" },
    editField: { display: "flex", gap: "20px" },
    marginLeft: { ml: 2 },
    buttonSubmit: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
};

export default styles;
