const styles = {
    rootContainer: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        height: "100%",
    },
    testEnv: {
        p: 2,
        backgroundColor: "primary.main",
        color: "background.default",
    },
    body: {
        maxWidth: "1440px",
    },
};

export default styles;
