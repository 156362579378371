import { Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./EditMediaModal.styles";
import { getOpenMediaStatus } from "src/services/processMediaService";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import { generateXML } from "src/utils/processMediaHelper";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { dateTimeUnits } from "src/constants/dateFormatConstants";

const EditMediaModal = ({ recordData, onClose, onSubmit, volserMaxLength = "20", addedMediaNos, serviceDates }) => {
    const { t } = useTranslation();
    useEffect(() => {
        setValues(recordData.row);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordData]);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchemaEditMedia = Yup.object({
        mediaNo: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, t("omDistribution.invalidCharacters"))
            .required(t("omDistribution.fieldRequired"))
            .max(volserMaxLength, t("omDistribution.maxLength", { charLength: volserMaxLength }))
            .test("is-forbidden", t("omDistribution.duplicateMediaNo"), (value) => !addedMediaNos.includes(value)),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setValues } = useFormik({
        initialValues: {
            mediaNo: "",
            returnDate: "",
            drpCode: "",
            description: "",
        },
        validationSchema: validationSchemaEditMedia,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const openMediaStatusRes = await getOpenMediaStatus(generateXML([values.mediaNo]));
                if (!openMediaStatusRes.failure) {
                    setFieldValue("openMediaStatus", openMediaStatusRes?.rs0[0].open_media_status || "");
                    onSubmit(values);
                }
            } catch (error) {
                console.error("Get Open Media Status - catch error: ", error);
            } finally {
                setIsLoading(false);
            }
        },
    });

    const handleDateChange = (date) => {
        setFieldValue("returnDate", date);
    };

    const mediaNoChange = (event) => {
        setFieldValue("mediaNo", event.target.value);
    };

    const isReturnDateIndefinite = values.returnDate === t("omDistribution.indefinite");
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={true}
                title={t("omDistribution.editMedia")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={onClose}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button variant="contained" onClick={handleSubmit}>
                            {t("buttonLabels.update")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                            <Box sx={styles.formControlSection} style={{ width: "50%" }}>
                                <TextField
                                    label={t("omDistribution.mediaNumber")}
                                    name="mediaNo"
                                    sx={{ marginTop: 1 }}
                                    fullWidth
                                    required
                                    value={values.mediaNo}
                                    onChange={mediaNoChange}
                                    onBlur={handleBlur}
                                    error={touched.mediaNo && Boolean(errors.mediaNo)}
                                    helperText={touched.mediaNo && errors.mediaNo}
                                />
                            </Box>
                            <Box sx={styles.formControlSection} style={{ width: "50%" }}>
                                <SecureSyncDatePicker
                                    name="returnDate"
                                    label={t("omDistribution.returnDate")}
                                    value={isReturnDateIndefinite ? "" : values.returnDate}
                                    onChange={handleDateChange}
                                    disablePast
                                    getDateProp={(dateProp) => {
                                        const isServiceDate = serviceDates.find((serviceDate) =>
                                            serviceDate.isSame(dateProp, dateTimeUnits.day)
                                        );
                                        return { highlight: isServiceDate };
                                    }}
                                    customDateValue={isReturnDateIndefinite ? t("omDistribution.indefinite") : ""}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                            <Box sx={styles.formControlSection} style={{ width: "50%" }}>
                                <TextField
                                    id="drpCode"
                                    name="drpCode"
                                    label={t("omDistribution.drpCode")}
                                    aria-label="DRPC"
                                    fullWidth
                                    value={values.drpCode}
                                    inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        setFieldValue("drpCode", event.target.value.toUpperCase());
                                    }}
                                />
                            </Box>
                            <Box sx={styles.formControlSection} style={{ width: "50%" }} />
                        </Box>
                        <Box sx={styles.formControlSection}>
                            <TextField
                                id="description"
                                name="description"
                                label={t("omDistribution.description")}
                                aria-label="description"
                                multiline
                                rows={3}
                                fullWidth
                                value={values.description}
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>
                </Box>
            </SecureSyncModal>
        </>
    );
};

export default EditMediaModal;
