import i18n from "src/i18n";

const shortCutKeys = {
    altA: "Alt+A",
    altB: "Alt+B",
    altC: "Alt+C",
    altG: "Alt+G",
    altL: "Alt+L",
    altM: "Alt+M",
    altN: "Alt+N",
    altI: "Alt+I",
    altO: "Alt+O",
    altP: "Alt+P",
    altR: "Alt+R",
    altS: "Alt+S",
    altV: "Alt+V",
    altT: "Alt+T",
    shiftAltD: "Shift+Alt+D",
    shiftAltE: "Shift+Alt+E",
    shiftAltF: "Shift+Alt+F",
};

const defaultShortCutKeys = {
    add: shortCutKeys.altA,
    cancel: shortCutKeys.altC,
    clear: shortCutKeys.altC,
    changePin: shortCutKeys.altC,
    download: shortCutKeys.shiftAltD,
    find: shortCutKeys.shiftAltF,
    nextRecord: shortCutKeys.altN,
    ok: shortCutKeys.altO,
    pickupManifest: shortCutKeys.altP,
    prepareFullReport: shortCutKeys.shiftAltF,
    prepareReport: shortCutKeys.altP,
    printFileDetails: shortCutKeys.altP,
    prevRecord: shortCutKeys.altP,
    reset: shortCutKeys.altR,
    scanMore: shortCutKeys.altC,
    search: shortCutKeys.altS,
    submit: shortCutKeys.altS,
    verify: shortCutKeys.altV,
    //Custom shortcuts
    searchContainersFind: shortCutKeys.shiftAltF,
    searchContainersSave: shortCutKeys.altS,
};

const spanish419ShortCutKeys = {
    add: shortCutKeys.altG,
    cancel: shortCutKeys.altC,
    clear: shortCutKeys.altA,
    changePin: shortCutKeys.altC,
    download: shortCutKeys.shiftAltD,
    find: shortCutKeys.altS,
    nextRecord: shortCutKeys.altS,
    ok: shortCutKeys.altT,
    pickupManifest: shortCutKeys.altL,
    prepareFullReport: shortCutKeys.altO,
    prepareReport: shortCutKeys.altP,
    printFileDetails: shortCutKeys.altI,
    prevRecord: shortCutKeys.altA,
    reset: shortCutKeys.altR,
    scanMore: shortCutKeys.altC,
    search: shortCutKeys.altB,
    submit: shortCutKeys.shiftAltE,
    verify: shortCutKeys.altV,
    //Custom shortcuts
    searchContainersFind: shortCutKeys.altB,
    searchContainersSave: shortCutKeys.shiftAltE,
};

const portugueseShortCutKeys = {
    add: shortCutKeys.altA,
    cancel: shortCutKeys.altC,
    clear: shortCutKeys.altL,
    changePin: shortCutKeys.altC,
    download: shortCutKeys.altB,
    find: shortCutKeys.altC,
    nextRecord: shortCutKeys.altP,
    ok: shortCutKeys.altO,
    pickupManifest: shortCutKeys.altC,
    prepareFullReport: shortCutKeys.altC,
    prepareReport: shortCutKeys.altP,
    printFileDetails: shortCutKeys.altI,
    prevRecord: shortCutKeys.altA,
    reset: shortCutKeys.altR,
    scanMore: shortCutKeys.altM,
    search: shortCutKeys.shiftAltE,
    submit: shortCutKeys.shiftAltE,
    verify: shortCutKeys.altV,
    //Custom shortcuts
    searchContainersFind: shortCutKeys.shiftAltE,
    searchContainersSave: shortCutKeys.altS,
};

export const getShortCutKeys = () => {
    const languageBasedShortCutKeys = {
        "es-MX": spanish419ShortCutKeys,
        "pt-BR": portugueseShortCutKeys,
    };
    return languageBasedShortCutKeys[i18n.language] || defaultShortCutKeys;
};

export const hotKeysScopes = {
    deliverMedia: "deliverMedia",
    deliverMediaModal: "deliverMediaModal",
    newContainerLockInsert: "newContainerLockInsert",
    newContainerLockInsertCMIModal: "newContainerLockInsertCMIModal",
    containerPickup: "containerPickup",
    containerContentsSummary: "containerContentsSummary",
};

export default shortCutKeys;
