const styles = {
    oktaContainer: {
        my: 8,
        "#okta-sign-in.auth-container .button-primary": {
            background: "initial",
            backgroundColor: "#1662dd !important",
            border: "none",
        },
    },
};

export default styles;
