import { Button, styled } from "@mui/material";
import PropTypes from "prop-types";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const SecureSyncFileUpload = ({ name, accept, ...props }) => {
    return (
        <Button component="label" role={undefined} variant="contained" tabIndex={-1} {...props}>
            {name}
            <VisuallyHiddenInput type="file" accept={accept} />
        </Button>
    );
};

SecureSyncFileUpload.propTypes = {
    name: PropTypes.string,
    accept: PropTypes.string,
    props: PropTypes.any,
};
export default SecureSyncFileUpload;
