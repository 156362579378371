import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "./ActionContainer.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import IconRight from "src/components/Icons/IconRight/IconRight";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const ActionContainer = ({ resetForm, updateValues, nextRecord, previousRecord, nextMedia, prevMedia, disable }) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => updateValues() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => resetForm() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prevRecord, callback: () => previousRecord() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.nextRecord, callback: () => nextRecord() });
    return (
        <>
            <Box sx={styles.boxPaddingBottom}>
                <Box sx={styles.buttonContainer}>
                    <Button
                        disabled={disable}
                        variant="outlined"
                        onClick={resetForm}
                        title={getKeyBoardLabel(shortCutKeys.reset)}
                    >
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        disabled={disable}
                        variant="contained"
                        onClick={updateValues}
                        color="primary"
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                    >
                        {t("buttonLabels.update")}
                    </Button>
                </Box>
                <Box sx={styles.buttonContainer}>
                    <Button
                        disabled={!prevMedia}
                        onClick={previousRecord}
                        variant="outlined"
                        color="primary"
                        startIcon={<IconLeft />}
                        title={getKeyBoardLabel(shortCutKeys.prevRecord)}
                    >
                        {t("buttonLabels.previousRecord")}
                    </Button>
                    <Button
                        disabled={!nextMedia}
                        onClick={nextRecord}
                        variant="outlined"
                        color="primary"
                        endIcon={<IconRight />}
                        title={getKeyBoardLabel(shortCutKeys.nextRecord)}
                    >
                        {t("buttonLabels.nextRecord")}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default ActionContainer;
