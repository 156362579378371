import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

export const styles = {
    centeredSelect: {
        "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingBottom: 1,
        },
        "& .MuiSelect-icon": {
            right: "25%",
            top: "42%",
            transform: "translateX(25%)",
        },
        mr: 2,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: 1.66,
        letterSpacing: "0.4px",
        display: "flex",
        alignItems: "center",
    },
    jumpToPageContainer: {
        flexShrink: 0,
        ml: 2.5,
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
    },
    jumpToPageLabel: {
        fontSize: "14px",
        pr: 1,
    },
    arrowIcon: {
        width: 24,
        height: 24,
        transition: "none",
        transform: "none",
    },
    arrowButtonContainer: { flexShrink: 0, ml: 2.5, display: "flex", alignItems: "center" },
    pageArrows: {
        flexShrink: 0,
        ml: 2.5,
        display: "flex",
        alignItems: "center",
    },
    tableMinWidth: {
        minWidth: 650,
    },
    tableContainer: {
        borderRadius: "0px",
    },
    noDataMsgCell: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "15px",
    },
    tableHeader: {
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "grey.A100",
        height: "56px",
        padding: "0",
    },
    rowCell: (rowHeight) => ({
        color: "im_grey.900",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: "0.17px",
        height: rowHeight,
        paddingX: "10px",
        paddingY: "0",
    }),
    noDataMsg: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "im_grey.300",
    },
    columnHeader: (headerHeight) => ({
        fontSize: "13px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.17px",
        color: "text.secondary",
        paddingX: "10px",
        paddingY: "0",
        height: headerHeight,
    }),
    tablePagination: {
        "& .jumpToPage": {
            order: 0,
        },
        "& .MuiTablePagination-spacer": {
            order: 0,
        },
        "& .MuiTablePagination-selectLabel": {
            order: 1,
        },
        "& .MuiTablePagination-input": {
            order: 1,
        },
        "& .MuiTablePagination-displayedRows": {
            order: 2,
        },
        "& .pageArrows": {
            order: 3,
        },
        "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: 1,
        },
        "& .MuiSelect-icon": {
            right: "25%",
            top: "45%",
            transform: "translateX(30%)",
        },
    },
    tablePaginationOutSideTable: {
        display: "flex",
        flexDirection: "column-reverse",
    },
    tableFooter: {
        position: "sticky",
        bottom: 0,
        backgroundColor: "background.default",
        zIndex: 1,
    },
    selectAllHeader: { paddingLeft: "16px" },
};

export const CenteredSelect = styled(Select)(styles.centeredSelect);
