export const styles = {
    summaryTypography: {
        color: "#000000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
    },
    detailsTypography: {
        color: "im_grey.900",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
    },
    detailsList: {
        color: "im_grey.900",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        listStyle: "disc",
        listStylePosition: "inside",
        ml: "25px",
    },
    detailsListItem: {
        display: "list-item",
        px: 0,
    },
    dataGrid: {
        maxHeight: 400,
    },
    accord: { borderTopLeftRadius: "8px", borderTopRightRadius: "8px" },
    tableBox: { height: 400, width: "100%" },
};
