import { getDocs } from "firebase/firestore";
import {
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    apiResultSet3Collection,
} from "./firebase";
import { sendPinChangeMail } from "./mailService";
import {
    constructFbHeaders,
    createArrayObj,
    getCustomers,
    getLanguageLocaleId,
    getLoggedUserDetails,
    getPersonId,
    getPersonalId,
    getPersonalLocalId,
    getResultSets,
    getSelectedCustomerDb,
    getSelectedCustomerId,
    getSessionGuid,
    getUserIdentifier,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { preferenceTypeId } from "src/constants/preferenceServiceConstants";
import store from "src/redux/store";
import { getXml } from "src/utils/updatePreferencesXmlHelper";

const apiUrls = {
    getAuthCodes: "/ssadmin/getauthcodes",
    updatePreference: "/ssadmin/updatepreference",
    customerPreference: "/ssadmin/updatenonpreferenceandpreferencetablesupdation",
    allPreferencesList: "/ssadmin/allpreferenceslist",
    validatePersonnel: "/ssadmin/validatepersonnel",
    getMediaProgramTypes: "/ssadmin/getmediaprogramtypes",
    authNumberReset: "/ssadmin/authnumberreset",
    resetAuthPin: "/ssadmin/resetauthpin",
    getDefaultRetentionProfile: "/ssadmin/defaultretentionprofileexist",
    deleteRetentionProfile: "/ssadmin/deleteretentionprofile",
    insertRetentionProfile: "/ssadmin/insertretentionprofile",
    defaultRetentionProfile: "/ssadmin/isdefualtretentionprofileexist",
    updateRetentionProfile: "/ssadmin/updateretentionprofile",
    getRetentionProfile: "/ssadmin/getretentionprofile",
    getNonPreferenceData: "/ssadmin/getnonpreferencedata",
    holidayYears: "/ssadmin/returnbranchofholidayyears",
    returnBranchHolidaysForYear: "/ssadmin/returnbranchholidaysforyear",
    cancellableMR: "/ssadmin/getcancellablemr",
};
/**
 * Returns authorization codes
 * @param {*} code
 * @returns
 */
export const getAuthorizationCodes = async (code) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateUserLogin);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/getauthcodes";
    const body = {
        main_district_id: "50",
        code: code,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        // const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const authCodes = {
            failure: false,
            rs0: rs0Data,
        };
        return authCodes;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * updatePreferencesService
 * @param {*} applyToAllCustomer
 * @param {*} ValutStatusRecipientFlag
 * @param {array} prefArr
 *  array format
 * [
 *  {
    "sys_user_id": "",
    "customer_id": "",
    "system_id": "",
    "preference_type_descr": "",
    "preference_value": "",
    "apply_to_all_customers_bit": "",
    "preference_type_id": "",
    "preference_level_type_id": ""
    }
 * ]
 * @returns
 */
export const updateUserPreferencesService = async (
    applyToAllCustomer,
    valutStatusRecipientFlag,
    businessEmail,
    prefArr
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updatePreference);
    traceStart();
    let prefXml = `<NewDataSet>`;
    const custMetaData = getCustomers();
    prefArr.forEach((e) => {
        const xmlString = getXml(
            e.customer_id,
            e.sys_user_id,
            e.preference_type_id,
            e.preference_type_descr,
            e.preference_value,
            e.preference_level_type_id,
            e.apply_to_all_customers_bit,
            e.system_id
        );
        if (e.preference_level_type_id === preferenceTypeId.user) {
            prefXml = `${prefXml}${xmlString}`;
        }
    });
    prefXml = `${prefXml}</NewDataSet>`;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/updatepreference";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        personnel_id: getPersonId(),
        customer_id: custMetaData.selectedCustomer.customer_id,
        vault_status_recipient_flag: valutStatusRecipientFlag ? "Y" : "N",
        business_email_addr: businessEmail,
        non_pref_apply_to_all_cust_flag: applyToAllCustomer,
        xml_information: prefXml,
        logged_in_user: getUserIdentifier(),
        session_guid: getSessionGuid(),
        ssie_app_version: "5.5.6",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers, true);
        const response = await getResultSets(res.docId, 1);
        if (response.failure) {
            return response;
        }
        return {
            resultSet0: response?.results[0],
            resultSet1: response?.results[1],
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * updatePreferencesService
 * @param {*} applyToAllCustomer
 * @param {*} ValutStatusRecipientFlag
 * @param {array} prefArr
 *  array format
 * [
 *  {
    "sys_user_id": "",
    "customer_id": "",
    "system_id": "",
    "preference_type_descr": "",
    "preference_value": "",
    "apply_to_all_customers_bit": "",
    "preference_type_id": "",
    "preference_level_type_id": ""
    }
 * ]
 * @returns
 */
export const updateCustPreferencesService = async (
    applyToAllCustomer = "N",
    ValutStatusRecipientFlag,
    allCustomersForElevel,
    personnelEmail,
    prefArr
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.customerPreference);
    traceStart();
    let prefXml = "<NewDataSet>";
    const custMetaData = getCustomers();
    prefArr.forEach((e) => {
        const xmlString = getXml(
            e.customer_id,
            e.sys_user_id,
            e.preference_type_id,
            e.preference_type_descr,
            e.preferenceValue,
            e.preference_level_type_id,
            e.applyToAllCustomersBit,
            e.system_id
        );
        if (e.preference_level_type_id === preferenceTypeId.customer) {
            prefXml = `${prefXml}${xmlString}`;
        }
    });
    prefXml = `${prefXml}</NewDataSet>`;

    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/updatenonpreferenceandpreferencetablesupdation";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: custMetaData.selectedCustomer.customer_id,
        personnel_id: getPersonId(),
        non_pref_apply_to_all_cust_flag: applyToAllCustomer,
        vault_status_recipient_flag: ValutStatusRecipientFlag,
        logged_in_user: getUserIdentifier(),
        xml_information: prefXml,
        session_guid: getSessionGuid(),
        ssie_app_version: "5.5.6",
        customer_list: allCustomersForElevel,
        email_personnel_id: personnelEmail,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers, true);
        const response = await getResultSets(res.docId, 1);
        if (response.failure) {
            return response;
        }
        return {
            resultSet0: response?.results[0],
            resultSet1: response?.results[1],
            customerList: response?.results[0][0].customer_list,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Validates the personnel against the customer id provided
 * @param {*} customerId
 * @param {*} personnelId
 * @returns
 */
export const getPreferencesService = async (
    preferenceType,
    typeList = [],
    preferenceTypeId = "",
    emptyLangLocaleId = false
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.allPreferencesList);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/allpreferenceslist";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        system_id: "50",
        customer_id: custMetaData.selectedCustomer.customer_id,
        sys_user_id: getPersonId(),
        preference_level_type_id: preferenceType,
        preference_type_id: preferenceTypeId,
        language_locale_id: emptyLangLocaleId ? "" : getLanguageLocaleId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
            getDocs(apiResultSet3Collection(resDoc)),
        ]);

        const res0 = createArrayObj(rs0Snapshot);
        const res1 = createArrayObj(rs1Snapshot);
        const res2 = createArrayObj(rs2Snapshot);
        const res3 = createArrayObj(rs3Snapshot);

        let tempPref = [];
        if (typeList.length > 0) {
            res0.forEach((e) => {
                if (typeList.find((obj) => obj === e.preference_type_id)) {
                    tempPref.push({
                        ...e,
                        preference_level_type_id: preferenceType,
                    });
                }
            });
        } else {
            tempPref = res0;
        }

        const pref = {
            failure: false,
            preferences: tempPref,
            personal: res1,
            email: res2,
            vaultExist: res3,
        };
        return pref;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
/**
 * Validates the personnel against the customer id provided
 * @param {*} customerId
 * @param {*} personnelId
 * @returns
 */
export const validatePersonnel = async (customerId, personnelId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validatePersonnel);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/validatepersonnel";
    const body = {
        main_district_id: "50",
        customer_id: customerId,
        personnel_id: personnelId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const validPersonnel = {
            failure: false,
            rs0: rs0Data,
        };
        return validPersonnel;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns list of media program types
 * @param {*} localId
 * @returns
 */
export const getMediaProgramTypes = async (localId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getMediaProgramTypes);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/getmediaprogramtypes";
    const body = {
        main_district_id: "50",
        language_locale_id: localId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const programTypes = {
            failure: false,
            rs0: rs0Data,
        };
        return programTypes;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Resets auth number for the user
 * @param {*} userId - user who is making the change
 * @param {*} username  - modifying username
 * @param {*} newAuthNumber  new auth number
 * @param {*} bypassReason  by pass reason
 * @returns an object of data
 */
export const resetAuthNumber = async (userId, username, newAuthNumber, bypassReason) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.authNumberReset);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/authnumberreset";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        sys_user_id: userId,
        new_auth_number: newAuthNumber,
    };

    if (username) {
        body.last_mod_user = username;
    }
    if (bypassReason) {
        body.auth_bypass_reason = bypassReason;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const resetAuth = {
            failure: false,
            rs0: rs0Data,
        };
        return resetAuth;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Resets authoirzation pin
 * @param {*} authPin  - new auth pin
 * @param {*} username  user who pin in getting reset
 * @returns an object
 */
export const resetAuthPin = async (authPin, username, accountNumbers, name, modified, mailSendTo, notified = false) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.resetAuthPin);
    traceStart();
    let mailHasError = false;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/resetauthpin";
    const userDetails = getLoggedUserDetails();
    const LogedInUserName = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: "50",
        login: username ? username : LogedInUserName,
        new_auth_pin: authPin,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        if (notified) {
            const authTo = [
                {
                    emailAddress: mailSendTo,
                    name: name,
                },
            ];

            const resMail = await sendPinChangeMail(accountNumbers, name, modified, authTo, mailSendTo);
            if (!resMail.isSuccess) {
                mailHasError = true;
            }
        }
        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs0Data[0].error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        const authPin = {
            failure: false,
            rs0: rs0Data,
            mailHasError,
        };
        return authPin;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns if default retention profile exist for customer
 * @param {*} customerId customer id
 * @returns
 */
export const getDefaultRetentionProfile = async (customerId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDefaultRetentionProfile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/defaultretentionprofileexist";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const defaultProfile = {
            failure: false,
            rs0: rs0Data,
        };
        return defaultProfile;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Deletes provided retention profile
 * @param {*} retentionProfileId
 * @returns
 */
export const deleteRetentionProfile = async (retentionProfileId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.deleteRetentionProfile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/deleteretentionprofile";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        retention_profile_id: retentionProfileId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0].error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        return { failure: false, rs0: rs0Data };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const addRetentionProfile = async (retentionProfile, customerId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertRetentionProfile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/insertretentionprofile";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId,
    };

    if (retentionProfile.name) {
        body.profile_name = retentionProfile.name;
    }
    if (retentionProfile.description) {
        body.descr = retentionProfile.description;
    }
    if (retentionProfile.defaultOmDistListFlag) {
        body.default_om_dist_list_flag = retentionProfile.defaultOmDistListFlag;
    }
    if (retentionProfile.mediaProgramTypeId) {
        body.media_program_type_id = retentionProfile.mediaProgramTypeId;
    }
    if (retentionProfile.retentionDays) {
        body.retention_days = retentionProfile.retentionDays;
    }
    if (retentionProfile.indefiniteRetention) {
        body.indefinite_retention_flag = retentionProfile.indefiniteRetention;
    }
    if (retentionProfile.drpCode) {
        body.drp_code = retentionProfile.drpCode;
    }
    if (retentionProfile.mediaDescr) {
        body.media_descr = retentionProfile.mediaDescr;
    }
    if (retentionProfile.containerFlag) {
        body.overwrite_container_descr_flag = retentionProfile.containerFlag;
    }
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const retentionProfiles = {
            failure: false,
            rs0: rs0Data,
        };
        return retentionProfiles;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const isDefaultRetentionProfileExist = async (customerId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.defaultRetentionProfile);
    traceStart();
    const districtId = store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        ? store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        : "50";
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/isdefualtretentionprofileexist";
    const custMetaData = getCustomers();

    const selectedCustomer = custMetaData.selectedCustomer;

    const body = {
        main_district_id: districtId,
        customer_id: customerId ? customerId : selectedCustomer.customer_id,
        default_retention_profile_exists: "",
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot] = await Promise.all([getDocs(apiResultSet0Collection(resDoc))]);

        const rs0Data = await createArrayObj(rs0Snapshot);

        return rs0Data && rs0Data[0] ? rs0Data[0] : null;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Updates retention profile
 * @param {*} retentionProfile
 * @param {*} retentionProfileId
 * @returns
 */
export const updateRetentionProfile = async (retentionProfile, retentionProfileId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateRetentionProfile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/updateretentionprofile";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        retention_profile_id: retentionProfileId,
    };

    if (retentionProfile.name) {
        body.profile_name = retentionProfile.name;
    }
    if (retentionProfile.description) {
        body.descr = retentionProfile.description;
    }
    if (retentionProfile.defaultOmDistListFlag) {
        body.default_om_dist_list_flag = retentionProfile.defaultOmDistListFlag;
    }
    if (retentionProfile.mediaProgramTypeId) {
        body.media_program_type_id = retentionProfile.mediaProgramTypeId;
    }
    if (retentionProfile.retentionDays) {
        body.retention_days = retentionProfile.retentionDays;
    }
    if (retentionProfile.indefiniteRetention) {
        body.indefinite_retention_flag = retentionProfile.indefiniteRetention;
    }
    if (retentionProfile.drpCode) {
        body.drp_code = retentionProfile.drpCode;
    }
    if (retentionProfile.mediaDescr) {
        body.media_descr = retentionProfile.mediaDescr;
    }
    if (retentionProfile.containerFlag) {
        body.overwrite_container_descr_flag = retentionProfile.containerFlag;
    }
    if (retentionProfile.timeStamp) {
        body.retention_profile_timestamp = retentionProfile.timeStamp;
    }
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const profile = {
            failure: false,
            rs0: rs0Data,
        };
        return profile;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns list of retention profiles for the customer
 * @param {*} customerId
 * @param {*} mediaProgramId
 * @param {*} localeId
 * @param {*} languageId
 * @param {*} indefinitePhrase
 * @param {*} daysPhrase
 * @returns
 */
export const listRetentionProfiles = async (indefinitePhrase, daysPhrase, mediaProgramTypeId = "") => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRetentionProfile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/getretentionprofile";

    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        media_program_type_id: mediaProgramTypeId,
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
    };

    if (indefinitePhrase) {
        body.indefinite_phrase = indefinitePhrase;
    }
    if (daysPhrase) {
        body.days_phrase = daysPhrase;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }
        const retentionProfiles = {
            failure: false,
            rs0: rs0Data,
        };
        return retentionProfiles;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getNonPreferenceDataService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getNonPreferenceData);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/getnonpreferencedata";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        personnel_id: getPersonId(),
        customer_id: custMetaData.selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        const res0 = createArrayObj(rs0Snapshot);
        const res1 = createArrayObj(rs1Snapshot);
        return {
            data: res0,
            error: res1,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
/**
 * Returns preferences
 * @param {*} preferenceLevel
 * @param {*} preferenceType
 * @returns
 */
export const getPreferences = async (preferenceLevelTypeId, preferenceTypeId, noOfResultSet = 4) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.allPreferencesList);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/allpreferenceslist";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        system_id: "50",
        sys_user_id: getPersonalId(),
    };
    if (preferenceLevelTypeId) {
        body.preference_level_type_id = preferenceLevelTypeId;
    }
    if (preferenceTypeId) {
        body.preference_type_id = preferenceTypeId;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resultSetResponse = await getResultSets(res.docId, noOfResultSet);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const resultsObj = resultSetResponse.results.reduce((prevValue, rs, index) => {
            return { ...prevValue, [`rs${index}`]: rs };
        }, {});
        const preferences = {
            failure: false,
            ...resultsObj,
        };
        return preferences;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getHolidayYears = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.holidayYears);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/returnbranchofholidayyears";
    const body = {
        main_district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const holidayYears = {
            failure: false,
            rs0: rs0Data,
        };
        return holidayYears;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getHolidayLists = async (holidayYear) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnBranchHolidaysForYear);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/returnbranchholidaysforyear";
    const body = {
        main_district_id: getSelectedCustomerDb(),
    };
    if (holidayYear) {
        body.year = holidayYear;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }
        const preferences = {
            failure: false,
            rs0: rs0Data,
        };
        return preferences;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getCancellableMediaRequest = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.cancellableMR);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const loginUrl = process.env.REACT_APP_CF_URL_ADMIN + "/ssadmin/getcancellablemr";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: custMetaData.selectedCustomer.customer_id,
        results_in_list: "Y",
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        const res0 = createArrayObj(rs0Snapshot);
        const res1 = createArrayObj(rs1Snapshot);
        return {
            data: res0,
            error: res1,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
