import commonStyles from "../NewOrEmptyTransport.styles";

const styles = {
    ...commonStyles,
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    locksFieldWrapper: { display: "grid", gridTemplateColumns: "auto 1fr" },
    locksLabel: { display: "flex", alignItems: "center" },
    locksCaption: { gridColumnStart: 2 },
    tooltip: { fontSize: "10px", fontWeight: 500 },
    marginBottom20: { mb: "20px" },
    transportTypeSection: { gridColumn: "span 2" },
    insertConfigurationsSection: { display: "flex", alignItems: "center", my: "20px" },
    linkSection: { fontWeight: 500, mb: "20px" },
    btnSection: { display: "flex", columnGap: "20px" },
    cmiTooltipDesc: { fontSize: "10px", fontWeight: 500 },
};

export default styles;
