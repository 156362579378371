export const allowedPrefList = {
    customer: ["2", "3", "6", "7", "11", "12", "15", "18", "20", "21", "39", "40"],
    user: ["1", "13", "14", "30", "31"],
};

export const defaultHolidayListId = "53";

export const dropDownListTypeId = {
    holidayRule: "11",
};
