const styles = {
    header: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "1.235", // 41.99px
        letterSpacing: "0.25px",
        paddingTop: "20px",
    },
    divHeader: {
        color: "im_grey.900",
        fontSize: "12px",
        fontWeight: 400,
        paddingTop: "20px",
    },
    boxPaddingBottom: {
        display: "grid",
        gridTemplateColumns: "285px 285px",
    },
    title: {
        m: 0,
        p: 2,
    },
    closeButon: {
        position: "absolute",
        right: 8,
        top: 8,
        color: "im_grey.500",
    },
};

export default styles;
