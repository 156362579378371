import { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./EditContainerContentsModal.styles";
import PrevPreparedContainerModalConfirmation from "src/components/ProcessMedia/ConfirmationModals/PrevPreparedContainer/PrevPreparedContainerModalConfirmation";
import { getUserDetails, getBasicUserInfo } from "src/redux/userDetailsSlice";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { formatDate, getServiceDateRange, serviceDateMapper } from "src/utils/dateHelper";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { convertToLocaleUpperCase, filterData } from "src/utils/commonHelper";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import { getServiceDateForCustomerService } from "src/services/requestServices";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";

const EditContainerContentsModal = ({ recordData, onClose, onSubmit, retentionProfiles, isPrePrepared }) => {
    const { t } = useTranslation();
    const oktaUserDetails = useSelector(getUserDetails);
    const userFlags = useSelector(getBasicUserInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [serviceDates, setServiceDates] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState({ show: false, message: "" });
    const [showPreContainerConfirmation, setShowPreContainerConfirmation] = useState({ show: false, data: null });
    const { values, setValues, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            newReturnDate: "",
            containerId: "",
            newDescription: "",
            personnelId: userFlags.sys_user_id || "",
            prepareForPickup: "",
            returnDateChange: "N",
            username: oktaUserDetails.email || "",
            containerTimestamp: "",
            retentionProfileId: "",
            deletedPhrase: t("localizedServiceDetails.deleted0"),
            sentToPhrase: t("localizedServiceDetails.sentTo0"),
            notAtIronmountainPhrase: t("localizedServiceDetails.notAtIronMountain"),
            indefinitePhrase: t("containerPickup.indefinite"),
            allPhrase: `<${convertToLocaleUpperCase(t("common.all"))}>`,
            allExcludingDeletedPhrase: t("containerPickup.allExcludingDeleted"),
            returnDate: "",
        },
        onSubmit: async (values) => {
            onSubmit(values);
        },
    });

    const { fromDate, toDate } = getServiceDateRange();

    useEffect(() => {
        let returnDate = "";
        if (recordData?.isReturnDateEditable || !recordData?.hasAccessToEditReturnDate) {
            returnDate = formatDate(recordData?.Pending_Return_Date, dateFormats.shortDate);
        } else if (recordData?.isContainer) {
            if (recordData?.isDeleted) {
                returnDate = formatDate(recordData?.Display_Return_Date, dateFormats.shortDate);
            } else {
                returnDate = recordData?.Summary_Type;
            }
        }

        setValues({
            ...values,
            newReturnDate: returnDate || "",
            containerId: recordData?.Container_Id || "",
            newDescription: recordData?.Descr || "",
            prepareForPickup: recordData?.includeOnManifestFlag || 0,
            returnDate: returnDate === t("containerPickup.indefinite") ? "" : returnDate,
            retentionProfileId: recordData?.Retention_Profile_Id || "",
            profileName: recordData?.Profile_Name || "",
        });

        const getServiceDateList = async () => {
            setIsLoading(true);
            const response = await getServiceDateForCustomerService({
                fromDate: fromDate,
                toDate: toDate,
                rescheduleFlag: noFlag,
                addonCutoffFlag: "",
            });
            if (!response.failure) {
                if (response?.resultSet0?.length > 0) {
                    setServiceDates(serviceDateMapper(response?.resultSet0));
                }
            }
            setIsLoading(false);
        };
        getServiceDateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordData]);

    const retentionProfileHandler = (event) => {
        const selectedRetentionProfile = filterData(
            retentionProfiles.retentionProfileList,
            "retention_profile_id",
            event.target.value
        );
        if (selectedRetentionProfile.length > 0) {
            const nextScheduleServiceDate = selectedRetentionProfile[0].next_sched_service_date;
            setFieldValue("retentionProfileId", event.target.value);
            setFieldValue("profileName", selectedRetentionProfile[0].profile_name);
            setFieldValue("newReturnDate", nextScheduleServiceDate);
            setFieldValue(
                "returnDate",
                nextScheduleServiceDate === t("containerPickup.indefinite") ? "" : nextScheduleServiceDate
            );
            if (selectedRetentionProfile[0].overwrite_container_descr_flag === yesFlag) {
                setFieldValue("newDescription", selectedRetentionProfile[0].media_descr);
            }
            if (recordData?.isCheckboxEditable) {
                setFieldValue("prepareForPickup", 1);
            }
        } else {
            setFieldValue("retentionProfileId", "");
            setFieldValue("profileName", "");
        }
    };
    const showCustomDateValue =
        values.newReturnDate === t("containerPickup.indefinite") ||
        (values.prepareForPickup === 1 && !values.returnDate);

    const isPrePreparedContainer = (event) => {
        if (isPrePrepared && !event.target.checked) {
            setShowPreContainerConfirmation({ show: true, data: event.target.checked });
        } else {
            checkContainerPickupHandler(event.target.checked);
        }
    };
    const checkContainerPickupHandler = (event) => {
        setFieldValue("prepareForPickup", event ? 1 : 0);
        if (event && !showCustomDateValue) {
            if (values.retentionProfileId) {
                const selectedRetentionProfile = filterData(
                    retentionProfiles.retentionProfileList,
                    "retention_profile_id",
                    values.retentionProfileId
                );
                const nextScheduleServiceDate = selectedRetentionProfile[0].next_sched_service_date;
                setFieldValue(
                    "newReturnDate",
                    nextScheduleServiceDate === t("containerPickup.indefinite") ? "" : nextScheduleServiceDate
                );
                setFieldValue(
                    "returnDate",
                    nextScheduleServiceDate === t("containerPickup.indefinite") ? "" : nextScheduleServiceDate
                );
            } else {
                setFieldValue("newReturnDate", t("containerPickup.indefinite"));
                setFieldValue("returnDate", "");
                setShowPreContainerConfirmation({ show: false, data: null });
            }
        } else {
            setFieldValue("newReturnDate", "");
            setFieldValue("returnDate", "");
            setShowPreContainerConfirmation({ show: false, data: null });
        }
    };

    const dateChangeHandler = (date) => {
        setFieldValue("newReturnDate", date);
        setFieldValue("returnDate", date);
        setFieldValue("returnDateChange", "Y");
        if (recordData?.isCheckboxEditable) {
            setFieldValue("prepareForPickup", date ? 1 : 0);
        }
    };

    const retentionDefaultOption =
        retentionProfiles.retentionProfileDropdownOption.length === 1 &&
        retentionProfiles.retentionProfileDropdownOption[0].label === t("common.defaultDropdownOption");
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={true}
                title={t("containerPickup.editContainerContents")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={onClose}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button variant="contained" onClick={handleSubmit}>
                            {t("buttonLabels.update")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        {showErrorAlert.show && (
                            <Box sx={styles.marginBottomBreak}>
                                <SecureSyncAlert
                                    message={showErrorAlert.message}
                                    severity="error"
                                    onClose={() => {
                                        setShowErrorAlert({ show: false, message: "" });
                                    }}
                                />
                            </Box>
                        )}
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={2}
                            sx={styles.formControlSection}
                        >
                            {recordData?.isReturnDateEditable && (
                                <SecureSyncDatePicker
                                    name="newReturnDate"
                                    label={t("containerPickup.returnDate")}
                                    value={showCustomDateValue ? "" : values.returnDate}
                                    onChange={dateChangeHandler}
                                    customDateValue={showCustomDateValue ? t("containerPickup.indefinite") : ""}
                                    disablePast
                                    getDateProp={(dateProp) => {
                                        const isServiceDate = serviceDates.find((serviceDate) =>
                                            serviceDate.isSame(dateProp, dateTimeUnits.day)
                                        );
                                        return { highlight: isServiceDate };
                                    }}
                                />
                            )}
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    id="prepareForPickup"
                                    name="prepareForPickup"
                                    control={<Switch />}
                                    label={t("containerPickup.pickup")}
                                    disabled={!recordData?.isCheckboxEditable}
                                    checked={recordData?.isCheckboxEditable ? values?.prepareForPickup === 1 : false}
                                    onChange={isPrePreparedContainer}
                                />
                            </Box>
                        </Box>
                        {recordData?.isContainer && (
                            <Box sx={styles.formControlSection}>
                                <SecureSyncSelect
                                    id="retentionProfileId"
                                    name="retentionProfileId"
                                    label={t("containerPickup.retentionProfile")}
                                    value={values?.retentionProfileId || ""}
                                    onChange={retentionProfileHandler}
                                    options={retentionProfiles.retentionProfileDropdownOption || []}
                                    disabled={!recordData?.isProfileEditable}
                                    defaultOption={retentionDefaultOption ? t("common.defaultDropdownOption") : ""}
                                    sx={{ width: "100%", paddingRight: "0px" }}
                                    showBlankOption={!retentionDefaultOption}
                                />
                            </Box>
                        )}
                        {recordData?.isContainer && (
                            <Box sx={styles.formControlSection}>
                                <TextField
                                    label={t("containerPickup.description")}
                                    aria-label="description"
                                    id="newDescription"
                                    name="newDescription"
                                    multiline
                                    rows={3}
                                    inputProps={{ maxLength: 1024 }}
                                    fullWidth
                                    value={values?.newDescription}
                                    disabled={!recordData?.isDescriptionEditable}
                                    onChange={handleChange}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </SecureSyncModal>
            <PrevPreparedContainerModalConfirmation
                handleConfirm={() => {
                    checkContainerPickupHandler(showPreContainerConfirmation.data);
                }}
                onClose={() => {
                    setShowPreContainerConfirmation({ show: false, data: null });
                }}
                open={showPreContainerConfirmation.show}
            />
        </>
    );
};

export default EditContainerContentsModal;
