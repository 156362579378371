import { Accordion, AccordionSummary, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { styles } from "./ReportsHeaderSection.styles";
import { InLineLink } from "src/components/common/Link/InLineLink/InLineLink";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { yesFlag } from "src/constants/serviceConstants";

const ReportsHeaderSection = (props) => {
    const navigate = useNavigate();
    const [mostRecentVaultReport, setMostRecentVaultReport] = useState("");

    useEffect(() => {
        setMostRecentVaultReport(props?.mostRecentVaultReport ? props.mostRecentVaultReport : "");
    }, [props?.mostRecentVaultReport]);

    const navigateVaultStatusReport = () => {
        navigate(vaultStatusReport, {
            state: {
                reportDateFrom: mostRecentVaultReport,
                reportDateTo: mostRecentVaultReport,
                doSearch: yesFlag,
            },
        });
    };

    return (
        <Accordion sx={styles.accordion}>
            <AccordionSummary aria-controls="vault-status-report-content" id="vault-status-report">
                <Typography variant="body1" sx={styles.typography}>
                    <Typography variant="body1" sx={styles.headerTxt}>
                        {t("homeModule.VaultStatusReport")}
                    </Typography>
                    {t("homeModule.ThisIsTheMostRecentVaultStatusReportForAllYourAccounts")}
                    <InLineLink onClickFunction={navigateVaultStatusReport}>{mostRecentVaultReport}</InLineLink>
                    <br />
                    {t("homeModule.ToCreateACustomReportPleaseUseTheMenuToAccessThe")}
                    <InLineLink onClickFunction={navigateVaultStatusReport}>
                        {" "}
                        {t("homeModule.VaultStatusReport")}{" "}
                    </InLineLink>
                    {t("homeModule.Page")}
                </Typography>
            </AccordionSummary>
        </Accordion>
    );
};

ReportsHeaderSection.propTypes = {
    mostRecentVaultReport: PropTypes.string,
};

export default ReportsHeaderSection;
