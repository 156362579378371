export const fontrem = (val) => ({
    fontSize: `${val / 16}rem`,
});

export const header = (val = 16) => ({
    color: "text.secondary",
    ...fontrem(val),
});

export const subHeader = (val = 14) => ({
    color: "text.secondary",
    ...fontrem(val),
});

export const formLabels = {
    label: {
        color: "text.primary",
    },
};

export const closeIcon = {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#707070",
};

export const pxToRem = (val) => `${val / 16}rem`;
