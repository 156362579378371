const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    formControl: {
        width: "100%",
        paddingRight: 2,
    },
    card: {
        width: "100%",
    },
    cardContent: {
        width: "100%",
        height: "auto",
        p: 0,
    },
    tblCardContent: {
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        height: "80px",
        py: 1,
        px: 2,
    },
    recordStart: {
        justifyContent: "flex-start",
    },
    recordEnd: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
        py: 1,
    },
    footNoteText: {
        fontWeight: 400,
        fontSize: "12px",
        marginY: "15px",
    },
    instructionText: {
        fontWeight: 400,
        fontSize: "12px",
    },
    statusRecord: {
        fontWeight: 500,
        fontSize: "14px",
        alignContent: "center",
    },
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    errorText: {
        fontWeight: 400,
        fontSize: "12px",
        color: "im_red.500",
    },
    confirmationModalFooter: {
        display: "flex",
        gap: 2,
    },
    importFileTooltip: { fontWeight: 400, fontSize: 12 },
    addMediaSection: {
        display: "flex",
        justifyContent: "space-around",
        gap: 2,
        py: 1,
        height: "50px",
    },
    importFileSection: {
        fontWeight: 400,
        fontSize: 12,
        mb: "15px",
    },
    clearIcon: {
        color: "im_dark_blue.500",
    },
    addBtnSection: {
        display: "flex",
        gap: 0.5,
    },
    marginBottomBrake: {
        mb: "16px",
    },
    marginLeft: {
        ml: 2,
    },
};

export default styles;
