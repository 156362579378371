import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./CustomerPreferences.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { getCancellableMediaRequest } from "src/services/adminService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";

const MediaDestructionModal = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    const [mdrList, setMdrList] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const getCancellableRequest = async () => {
        setIsLoading(true);
        const res = await getCancellableMediaRequest();
        setMdrList(res.data[0].mdr_list);
        setIsLoading(false);
    };
    useEffect(() => {
        if (open) {
            getCancellableRequest();
        }
    }, [open]);

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={open}
                title={t("customerPreferences.placeHoldOnMedia")}
                footer={
                    <Box sx={styles.buttonContainer2}>
                        <Button variant="outlined" color="primary" onClick={handleConfirm}>
                            {t("common.yes")}
                        </Button>
                        <Button variant="contained" color="primary" onClick={onClose}>
                            {t("common.no")}
                        </Button>
                    </Box>
                }
            >
                <Typography sx={styles.contactFieldContainer}>
                    {t("customerPreferences.aboutToPlaceHold")}
                    {mdrList !== "" && t("customerPreferences.existingMediaDestructionRequests")}
                </Typography>
                <Typography sx={styles.mediaRequestHeader}>
                    {mdrList !== "" && t("customerPreferences.mediaDestructionRequestId")}
                </Typography>
                {mdrList !== "" && <Typography sx={styles.textDescription}>{mdrList}</Typography>}
                <Typography>{t("customerPreferences.yesAcknowledge")}</Typography>
            </SecureSyncModal>
        </>
    );
};

export default MediaDestructionModal;
