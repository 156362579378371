import { useTheme } from "@mui/material";

const DateRangeFilled = (props) => {
    const theme = useTheme();
    const fillColor = theme.palette.im_dark_blue[500];
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="DateRangeFilled">
                <g id="Vector">
                    <path
                        d="M8.25 14.2501C8.12 14.2501 7.99 14.2201 7.87 14.1701C7.74 14.1201 7.64 14.0501 7.54 13.9601C7.36 13.7701 7.25 13.5201 7.25 13.2501C7.25 13.1201 7.28 12.9901 7.33 12.8701C7.38 12.7501 7.45 12.6401 7.54 12.5401C7.64 12.4501 7.74 12.3801 7.87 12.3301C8.23 12.1801 8.68 12.2601 8.96 12.5401C9.14 12.7301 9.25 12.9901 9.25 13.2501C9.25 13.3101 9.24 13.3801 9.23 13.4501C9.22 13.5101 9.2 13.5701 9.17 13.6301C9.15 13.6901 9.12 13.7501 9.08 13.8101C9.06 13.8434 9.03112 13.8767 9.00223 13.91C8.98779 13.9267 8.97334 13.9434 8.96 13.9601C8.77 14.1401 8.51 14.2501 8.25 14.2501Z"
                        fill={fillColor}
                    />
                    <path
                        d="M11.37 14.1699C11.49 14.2199 11.62 14.2499 11.75 14.2499C12.01 14.2499 12.27 14.1399 12.46 13.9599C12.4733 13.9432 12.4878 13.9265 12.5022 13.9099C12.5311 13.8765 12.56 13.8432 12.58 13.8099C12.62 13.7499 12.65 13.6899 12.67 13.6299C12.7 13.5699 12.72 13.5099 12.73 13.4499C12.74 13.3799 12.75 13.3099 12.75 13.2499C12.75 12.9899 12.64 12.7299 12.46 12.5399C12.18 12.2599 11.73 12.1699 11.37 12.3299C11.24 12.3799 11.14 12.4499 11.04 12.5399C10.95 12.6399 10.88 12.7499 10.83 12.8699C10.78 12.9899 10.75 13.1199 10.75 13.2499C10.75 13.5199 10.86 13.7699 11.04 13.9599C11.14 14.0499 11.24 14.1199 11.37 14.1699Z"
                        fill={fillColor}
                    />
                    <path
                        d="M15.25 14.2499C15.12 14.2499 14.99 14.2199 14.87 14.1699C14.74 14.1199 14.64 14.0499 14.54 13.9599L14.42 13.8099C14.38 13.7499 14.35 13.6899 14.33 13.6299C14.3 13.5699 14.28 13.5099 14.27 13.4499C14.26 13.3799 14.25 13.3099 14.25 13.2499C14.25 12.9899 14.36 12.7299 14.54 12.5399C14.64 12.4499 14.74 12.3799 14.87 12.3299C15.24 12.1699 15.68 12.2599 15.96 12.5399C16.14 12.7299 16.25 12.9899 16.25 13.2499C16.25 13.3099 16.24 13.3799 16.23 13.4499C16.22 13.5099 16.2 13.5699 16.17 13.6299C16.15 13.6899 16.12 13.7499 16.08 13.8099C16.06 13.8432 16.0311 13.8765 16.0023 13.9098C15.9878 13.9265 15.9733 13.9432 15.96 13.9599C15.77 14.1399 15.51 14.2499 15.25 14.2499Z"
                        fill={fillColor}
                    />
                    <path
                        d="M7.87 17.67C7.99 17.72 8.12 17.7499 8.25 17.7499C8.51 17.7499 8.77 17.6399 8.96 17.4599C9.14 17.2699 9.25 17.0099 9.25 16.7499C9.25 16.4899 9.14 16.23 8.96 16.04C8.59 15.67 7.91 15.67 7.54 16.04C7.45 16.13 7.38 16.2399 7.33 16.3699C7.28 16.4899 7.25 16.6199 7.25 16.7499C7.25 17.0099 7.36 17.2699 7.54 17.4599C7.64 17.5499 7.75 17.62 7.87 17.67Z"
                        fill={fillColor}
                    />
                    <path
                        d="M11.75 17.7499C11.49 17.7499 11.23 17.6399 11.04 17.4599C10.86 17.2699 10.75 17.0099 10.75 16.7499C10.75 16.6199 10.78 16.4899 10.83 16.3699C10.88 16.2399 10.95 16.13 11.04 16.04C11.41 15.67 12.09 15.67 12.46 16.04C12.55 16.13 12.62 16.2399 12.67 16.3699C12.72 16.4899 12.75 16.6199 12.75 16.7499C12.75 17.0099 12.64 17.2699 12.46 17.4599C12.27 17.6399 12.01 17.7499 11.75 17.7499Z"
                        fill={fillColor}
                    />
                    <path
                        d="M14.54 17.4599C14.73 17.6399 14.99 17.7499 15.25 17.7499C15.51 17.7499 15.77 17.6399 15.96 17.4599C16.14 17.2699 16.25 17.0099 16.25 16.7499C16.25 16.4899 16.14 16.2299 15.96 16.0399C15.9433 16.0266 15.9267 16.0121 15.91 15.9977C15.8767 15.9688 15.8433 15.9399 15.81 15.9199C15.75 15.8799 15.69 15.8499 15.63 15.8299C15.57 15.7999 15.51 15.7799 15.44 15.7699C15.12 15.6999 14.77 15.8099 14.54 16.0399C14.45 16.1299 14.38 16.2399 14.33 16.3699C14.28 16.4899 14.25 16.6199 14.25 16.7499C14.25 16.8799 14.28 17.0099 14.33 17.1299C14.38 17.2599 14.45 17.3699 14.54 17.4599Z"
                        fill={fillColor}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.5 2.5V1.75C8.5 1.34 8.16 1 7.75 1C7.34 1 7 1.34 7 1.75V2.53123C3.81383 2.80438 2 4.8578 2 8.25V16.75C2 20.4 4.1 22.5 7.75 22.5H15.75C19.4 22.5 21.5 20.4 21.5 16.75V8.25C21.5 4.8578 19.6862 2.80438 16.5 2.53123V1.75C16.5 1.34 16.16 1 15.75 1C15.34 1 15 1.34 15 1.75V2.5H8.5ZM7 4.75C7 5.16 7.34 5.5 7.75 5.5C8.16 5.5 8.5 5.16 8.5 4.75V4H15V4.75C15 5.16 15.34 5.5 15.75 5.5C16.16 5.5 16.5 5.16 16.5 4.75V4.03515C18.8045 4.26256 19.9519 5.59536 19.9985 8.08984H3.50148C3.54811 5.59536 4.69552 4.26256 7 4.03515V4.75ZM3.5 9.58984V16.75C3.5 19.61 4.89 21 7.75 21H15.75C18.61 21 20 19.61 20 16.75V9.58984H3.5Z"
                        fill={fillColor}
                    />
                </g>
            </g>
        </svg>
    );
};

export default DateRangeFilled;
