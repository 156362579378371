const styles = {
    container: {
        my: 8,
        textAlign: "center",
    },
    cardContent: {
        py: 5,
        px: 10,
    },
    HomeBtn: {
        mt: 1,
    },
    card: {
        borderRadius: 3,
    },
    btnArea: {
        display: "flex",
        gap: 2,
        justifyContent: "center",
        mt: 2,
    },
    textContent: {
        fontSize: "23px",
        my: "20px",
    },
    titleSection: {
        fontSize: "48px",
        color: "im_grey.900",
        my: "26px",
    },
};

export default styles;
