const styles = {
    clearIcon: {
        color: "im_dark_blue.500",
    },
    recordsMsg: { p: "16px" },
    container: {
        mb: "20px",
    },
};

export default styles;
