import dayjs from "dayjs";
import { multipleSort } from "./processMediaHelper";
import {
    dateFormats,
    dateTimeUnits,
    indefiniteDate,
    getIndefinitePhase,
    maxYear,
    minYear,
    getNotAtIronMountainPhase,
    serviceDateSortCriteria,
} from "src/constants/dateFormatConstants";
import { getCustomers, getPersonalTimeZoneOffset, getRegion } from "src/services/utils";

export const parseDate = (date, currentFormat, expectedFormat) => {
    return date ? dayjs(date, currentFormat).format(expectedFormat) : "";
};

export const formatDate = (date, dateFormat = dateFormats.shortDate, setUtcTime = false) => {
    let formattedTimeStamp = "";
    if (date !== "") {
        if (date === indefiniteDate || date === getNotAtIronMountainPhase()) {
            formattedTimeStamp = "";
        } else if (date === getIndefinitePhase()) {
            formattedTimeStamp = getIndefinitePhase();
        } else {
            if (setUtcTime) {
                formattedTimeStamp = dayjs.utc(date).format(dateFormat);
            } else {
                formattedTimeStamp = dayjs(date).format(dateFormat);
            }
        }
    }
    return formattedTimeStamp;
};

export const formatDateUsingCurrentDate = ({ date, currentFormat, convertFormat, utc = false }) => {
    return formatDate(dayjs(date, currentFormat), convertFormat, utc);
};

export const fileDate = () => {
    return dayjs().format(dateFormats.fileDate);
};

export const convertLocalDateTime = (date) => {
    const formattedDate = formatDate(date);
    const formattedTime = formatDate(date, dateFormats.timeFormat24, true);
    return `${formattedDate} ${formattedTime}`;
};

export const getSelectedCustomerLocaleTime = (format) => {
    const { selectedCustomer } = getCustomers();

    let dateFormat = `${getDisplayFormat()} ${dateFormats.timeFormat24}`;
    if (format) {
        dateFormat = format;
    }
    const offSetDate = dayjs().utcOffset(parseFloat(selectedCustomer.time_zone_offset));
    return formatDate(offSetDate, dateFormat);
};

export const getDisplayFormat = (format = dateFormats.shortDate) => {
    return dayjs().localeData().longDateFormat(format);
};
/**
 * This function return a service dates array relevant to SecureSyncDatePicker
 * @param {*} serviceDateArray
 * @returns
 */
export const serviceDateMapper = (serviceDateArray, dateFormat = dateFormats.timeStamp) => {
    const serviceDateMappedArray = serviceDateArray.map((item) => dayjs(item.service_date, dateFormat));
    const sortedArr = multipleSort(serviceDateMappedArray, serviceDateSortCriteria);
    return sortedArr;
};

export const getMinAndMaxDate = () => {
    const currentYear = dayjs().year();
    const minDate = dayjs(`01-01-${currentYear}`, dateFormats.formattedDateMMDDYYYY).subtract(minYear, "year");
    const maxDate = dayjs(`12-31-${currentYear}`, dateFormats.formattedDateMMDDYYYY).add(maxYear, "year");
    return {
        minDateForApi: minDate.format(dateFormats.requestServiceDateFormat),
        maxDateForApi: maxDate.format(dateFormats.requestServiceDateFormat),
        displayMinDate: formatDate(minDate),
        displayMaxDate: formatDate(maxDate),
    };
};

export const getServiceDateRange = () => {
    const fromDate = formatDate(dayjs().startOf("day"), dateFormats.timeStamp);
    const toDate = formatDate(
        dayjs().startOf("day").add(maxYear, dateTimeUnits.year).endOf(dateTimeUnits.year),
        dateFormats.timeStamp
    );
    return { fromDate, toDate };
};

export const timestampToHex = () => {
    const timestamp = Date.now(); // current timestamp in milliseconds

    // Convert the timestamp to seconds (optional, depending on your use case)
    const timestampInSeconds = Math.floor(timestamp / 1000);

    // Convert the timestamp to a hexadecimal string
    return timestampInSeconds.toString(16);
};

export const selectCurrentMonthFirstDate = () => {
    return formatDate(dayjs().startOf(dateTimeUnits.month));
};

export const formatDateTime = (dateTime, isUTC = false) => {
    const offset = getPersonalTimeZoneOffset();

    let dayjsFormatted = dayjs(dateTime);
    if (isUTC) {
        dayjsFormatted = dayjs(dateTime).utc();
    }

    if (offset === "0") {
        return dayjsFormatted;
    }

    const [hours, minutes] = offset.split(".").map(Number);
    const totalMinutes = hours * 60 + (minutes || 0) * Math.sign(hours);
    const adjustedDate = dayjsFormatted.add(totalMinutes, "minute");

    return adjustedDate;
};

export const getCurrentDateTime = () => {
    const isUTC = false;
    const dateTime = dayjs();
    const offset = getPersonalTimeZoneOffset(); // Assuming this function is defined elsewhere

    let formattedDateTime = isUTC ? dateTime.utc() : dateTime;

    if (offset !== "0") {
        const [hours, minutes] = offset.split(".").map(Number);
        const totalMinutes = hours * 60 + (minutes || 0) * Math.sign(hours);
        const userTimezone = dayjs.tz.guess();
        const userTime = dayjs().tz(userTimezone);
        const gmtOffsetMinutes = userTime.utcOffset();
        const totalOffsetMinutes = offset < 0 ? totalMinutes - gmtOffsetMinutes : totalMinutes - gmtOffsetMinutes;
        formattedDateTime = formattedDateTime.add(totalOffsetMinutes, "minute");
    }
    return formattedDateTime;
};

export const getCurrentDateTimeInLocalizedFormat = (dateTime, format = dateFormats.localized24HFormat) => {
    const dateTimeFormat = formatDateTime(dateTime, true);
    return formatDate(dateTimeFormat, format);
};

export const getTomorrowDate = () => dayjs().add(1, dateTimeUnits.day);

export const getAUDate = (date) => {
    const region = getRegion();
    if (region !== "imau" && region !== "imna") {
        return formatDate(date, dateFormats.sortDate);
    }
    return formatDate(date);
};

export const getdateObject = (date, dateFormat = getDisplayFormat()) => {
    return dayjs(date, dateFormat);
};

export const startOfTheDate = (dateString, dateFormat = getDisplayFormat()) => {
    const dayjsDate = dayjs(dateString, dateFormat);
    return dayjsDate.format(dateFormats.timeStamp);
};

export const endOfTheDate = (dateString, dateFormat = getDisplayFormat()) => {
    const dayjsDate = dayjs(dateString, dateFormat);
    return dayjsDate.endOf(dateTimeUnits.day).format(dateFormats.timeStamp);
};

/**
 *
 * @param {string} firstDate
 * @param {string} secondDate
 * @param {*} dateFormat
 * @description this method can use to compare 2 dates are same. if date format is not display date need to pass current date format.
 * @returns
 */
export const datesSameComparator = (firstDate, secondDate, dateFormat = getDisplayFormat()) => {
    return dayjs(firstDate, dateFormat).isSame(dayjs(secondDate, dateFormat));
};

export const openMediaOtherExceptionsDetectedInDateTime = (date) => {
    const region = getRegion();
    if (region === "imau") {
        return formatDate(date, dateFormats.dateTimeLongFormatImau, true);
    } else if (region === "ime") {
        return formatDate(date, dateFormats.dateTimeLongFormatIme, true);
    }
    return formatDate(date, dateFormats.dateTimeLongFormat, true);
};
