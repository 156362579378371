import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ListEmployees from "../ListEmployees/ListEmployees";
import styles from "./SearchEmployees.styles";
import { getDistrictLists, getTitleList, getAllEmployeeInformation } from "src/services/disasterService";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { yesFlag } from "src/constants/serviceConstants";
import { searchEmployees } from "src/constants/routeConstants/operationsRouterFullPaths";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initValue = {
    firstName: "",
    lastName: "",
    district: "",
    title: "",
};
const validationSchema = () =>
    Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        district: Yup.string(),
        title: Yup.string(),
    });

const SearchEmployees = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const [districtList, setDistrictList] = useState([]);
    const [jobTitle, setJobTitle] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearched, setSearched] = useState(false);
    const [districtName, setDistrictName] = useState("");
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleReset() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    const { values, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, setFieldValue } = useFormik({
        initialValues: initValue,
        enableReinitialize: true,
        validationSchema: validationSchema(),
        onSubmit: () => onFindClick(values, true),
    });
    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }
        return newNewPageState;
    };
    const onFindClick = async (values, isButtonClick) => {
        const pageState = getPageState({ ...values }, !isButtonClick);
        navigate(searchEmployees, {
            state: pageState,
            replace: true,
        });

        setSearched(true);
        const res = await getAllEmployeeInformation(values, yesFlag);
        if (!res.failure) {
            setEmployeeList(res?.rs0.sort((a, b) => a.name.localeCompare(b.name)));
        }
    };

    const handleDistrictChange = (e) => {
        const districtName =
            e.target.value === "0" ? "" : districtList.find((item) => item.value === e.target.value).label;
        setDistrictName(districtName);
        setFieldValue("district", e.target.value);
    };

    const setIntValues = (reset = false) => {
        const districtId =
            districtList.length > 0 ? districtList.find((i) => i.id === selectedCustomer.district_id).id : "0";
        setValues({
            ...values,
            firstName: reset ? " " : locationState.firstName,
            lastName: reset ? " " : locationState.lastName,
            district: reset ? districtId : locationState?.district || districtId,
            title: reset ? " " : locationState.title,
        });
        const locationStateName = districtList.find((i) => i.id === selectedCustomer.district_id).district_name;
        setDistrictName(
            locationStateName ||
                (districtList.length > 0 ? districtList.find((i) => i.id === selectedCustomer.district_id).label : "0")
        );
    };
    const getDistrict = async () => {
        setIsLoading(true);
        const districtLists = await getDistrictLists(values);
        if (!districtLists.failure) {
            const res = districtLists.rs0
                .map((item) => ({
                    id: item.district_id,
                    value: item.district_id,
                    label: item.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const firstOption = { id: "1", value: "0", label: t("common.allWithBracket") };
            setDistrictList([firstOption, ...res]);
        }
        setIsLoading(false);
    };
    const getTitle = async () => {
        setIsLoading(true);
        const titleLists = await getTitleList();
        if (!titleLists.failure) {
            const res = titleLists.rs0
                .map((item) => ({
                    id: item.job_title_id,
                    value: item.job_title_id,
                    label: item.title,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const firstOption = { id: "1", value: "", label: t("common.allWithBracket") };
            setJobTitle([firstOption, ...res]);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        getDistrict();
        getTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState) {
                const newFormValues = {
                    firstName: locationState?.firstName || " ",
                    lastName: locationState?.lastName || " ",
                    district:
                        locationState?.district ||
                        (districtList.length > 0
                            ? districtList.find((i) => i.id === selectedCustomer.district_id).id
                            : "0"),
                    title: locationState?.title || " ",
                };
                await setValues(newFormValues);
                onFindClick(newFormValues);
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (districtList.length > 0) {
            setIntValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtList]);

    const handleReset = () => {
        setIntValues(true);
        setEmployeeList([]);
        setSearched(false);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading || isSubmitting} />
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <PageTitle>{t("titles.searchEmployees")}</PageTitle>
                    <Typography variant="body1" sx={styles.userDetailBody1}>
                        <Trans
                            i18nKey={"searchEmployees.enterEmployeeSearchCriteria"}
                            values={{ buttonLabel: t("buttonLabels.find") }}
                        />
                    </Typography>

                    <Box sx={styles.formFields}>
                        <TextField
                            label={t("formFields.firstName")}
                            name="firstName"
                            variant="outlined"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                        />

                        <TextField
                            label={t("formFields.lastName")}
                            name="lastName"
                            variant="outlined"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                        />

                        <SecureSyncSelect
                            label={t("formFields.district")}
                            options={districtList}
                            name="district"
                            value={values.district}
                            onChange={handleDistrictChange}
                            onBlur={handleBlur}
                        />

                        <SecureSyncSelect
                            label={t("formFields.jobTitle")}
                            defaultOption={t("common.allWithBracket")}
                            options={jobTitle}
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Box>
                    <Box sx={styles.buttonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                handleReset();
                            }}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            {t("buttonLabels.clear")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            title={getKeyBoardLabel(shortCutKeys.search)}
                        >
                            {t("buttonLabels.find")}
                        </Button>
                    </Box>
                    <Box sx={styles.tableContainer}>
                        <ListEmployees
                            employeeList={employeeList}
                            isSearched={isSearched}
                            searchCriteria={{ ...values, districtName }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SearchEmployees;
