/* eslint-disable max-lines */
import ClearIcon from "@mui/icons-material/Clear";
import { Alert, Box, Button, Container, Divider, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import styles from "./OpenMediaDistribution.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getUserDetails } from "src/redux/userDetailsSlice";
import { listRetentionProfiles } from "src/services/adminService";
import { convertToLocaleUpperCase, filterData, getKeyBoardLabel } from "src/utils/commonHelper";
import { getSelectedCustomerId } from "src/services/utils";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import {
    calculateReturnDate,
    dateSort,
    generateXML,
    getOpenMeidaGlobalAttribute,
    scrollTop,
} from "src/utils/processMediaHelper";
import AddMediaModal from "src/components/ProcessMedia/OmDistribution/AddMediaModal/AddMediaModal";
import EditMediaModal from "src/components/ProcessMedia/OmDistribution/EditMediaModal/EditMediaModal";
import {
    createOMDistributionList,
    getLogicalVaultCount,
    getOpenMediaStatus,
    insertLoggedEvent,
} from "src/services/processMediaService";
import OMDistributionDataTable from "src/components/ProcessMedia/OmDistribution/DataTable/OMDistributionDataTable";
import {
    formatDate,
    formatDateUsingCurrentDate,
    getCurrentDateTime,
    getDisplayFormat,
    getMinAndMaxDate,
    getServiceDateRange,
    serviceDateMapper,
} from "src/utils/dateHelper";
import { getServiceDateForCustomerService } from "src/services/requestServices";
import { noFlag, systemId, yesFlag } from "src/constants/serviceConstants";
import Pencil from "src/components/Icons/Pencil/Pencil";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getOmDistributionListPayload } from "src/services/printServices";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

export const OpenMediaDistribution = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            if (!disableForm) {
                handleSubmit();
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            if (disableForm) {
                printReport();
            }
        },
    });
    const { t } = useTranslation();
    const theme = useTheme();
    const userDetails = useSelector(getUserDetails);
    const [isLoading, setIsLoading] = useState(false);
    const [retentionProfileList, setRetentionProfileList] = useState([]);
    const [retentionProfDropdown, setRetentionProfDropdown] = useState([]);
    const [selectedRetentionProfile, setSelectedRetentionProfile] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({ id: null, row: null });
    const [isEditMediaModalOpen, setIsEditMediaModalOpen] = useState(false);
    const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false);
    const [mediaDataArr, setMediaDataArr] = useState([]);
    const [logicalVaultCount, setLogicalVaultCount] = useState("0");
    const [alertParams, setAlertParams] = useState({});
    const [disableForm, setDisableForm] = useState(false);
    const [addedMediaNos, setAddedMediaNos] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [serviceDates, setServiceDates] = useState([]);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const globalAttributes = useGetGlobalAttrs(systemId.id60);
    const { displayMaxDate: maxDate } = getMinAndMaxDate();
    const minDate = getCurrentDateTime();
    const { volserMaxLength } = useMemo(() => {
        return getOpenMeidaGlobalAttribute(globalAttributes?.data?.rs0);
    }, [globalAttributes]);

    const columns = [
        {
            field: "action",
            headerName: "",
            width: "58px",
            sortable: false,
            renderCell: (params) => (
                <Box display={"flex"} gap={2} alignItems={"center"}>
                    <IconButton
                        disabled={disableForm}
                        onClick={() => {
                            setSelectedRecord({ id: params.id, row: params });
                            openEditMediaModalHandler();
                        }}
                    >
                        <Pencil fill={disableForm ? theme.palette.im_grey["200"] : theme.palette.im_mid_blue["500"]} />
                    </IconButton>
                    <IconButton
                        disabled={disableForm}
                        onClick={() => {
                            deleteSingleMediaType(params.id);
                        }}
                    >
                        <ClearIcon sx={styles.ClearIcon} />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "seq",
            headerName: convertToLocaleUpperCase(t("omDistribution.enteredSequence")),
            sortable: true,
        },
        {
            field: "mediaNo",
            headerName: convertToLocaleUpperCase(t("omDistribution.mediaNumber")),
        },
        {
            field: "returnDate",
            headerName: convertToLocaleUpperCase(t("omDistribution.returnDate")),
        },
        { field: "drpCode", headerName: convertToLocaleUpperCase(t("omDistribution.drpCode")) },
        {
            field: "openMediaStatus",
            headerName: convertToLocaleUpperCase(t("omDistribution.openMediaStatus")),
        },
        {
            field: "description",
            headerName: convertToLocaleUpperCase(t("omDistribution.description")),
        },
    ];

    const getRowId = (row) => mediaDataArr.indexOf(row);
    const { fromDate, toDate } = getServiceDateRange();

    const handleCloseSnackbar = () => {
        setAlertParams({
            messageCode: "",
            showAlert: false,
            error: false,
        });
    };

    const deleteSingleMediaType = (id) => {
        const filteredDataArr = mediaDataArr.filter((value) => value.id !== id);
        setMediaDataArr(filteredDataArr);
        setAddedMediaNos(filteredDataArr.map((item) => item.mediaNo));
    };

    useEffect(() => {
        getLogicalVaultCountSer();
        getRetentionProfiles();
        setFieldValue("volserMaxLength", volserMaxLength);
        const getServiceDateList = async () => {
            setIsLoading(true);
            try {
                const response = await getServiceDateForCustomerService({
                    fromDate: fromDate,
                    toDate: toDate,
                    rescheduleFlag: noFlag,
                    addonCutoffFlag: "",
                });
                if (!response.failure) {
                    if (response?.resultSet0?.length > 0) {
                        setServiceDates(serviceDateMapper(response?.resultSet0));
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getServiceDateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volserMaxLength]);

    const openEditMediaModalHandler = () => {
        setIsEditMediaModalOpen(true);
    };

    const closeEditMediaModalHandler = () => {
        setIsEditMediaModalOpen(false);
    };

    const submitEditMediaModalHandler = (updatedData) => {
        setIsLoading(true);
        const newMediaData = [...mediaDataArr];
        const indexOfMedia = mediaDataArr.findIndex((item) => item.id === selectedRecord.id);
        if (indexOfMedia !== -1) {
            newMediaData[indexOfMedia] = updatedData;
        }
        setMediaDataArr(newMediaData);
        closeEditMediaModalHandler();
        setIsLoading(false);
    };

    const openAddMediaModalHandler = () => {
        setIsAddMediaModalOpen(true);
    };

    const closeAddMediaModalHandler = () => {
        setIsAddMediaModalOpen(false);
    };

    const submitAddMediaModalHandler = async (mediaArr) => {
        setIsLoading(true);
        const openMediaStatusRes = await getOpenMediaStatus(generateXML(mediaArr));

        const newMediaArr = mediaArr.map((value) => {
            let mediaStatus = "";
            if (!openMediaStatusRes.failure) {
                mediaStatus =
                    filterData(openMediaStatusRes?.rs0, "open_media_number", value.mediaList)[0]?.open_media_status ||
                    "";
            }
            return {
                id: value.id,
                mediaNo: value.mediaList,
                returnDate: values.returnDate,
                drpCode: values.drpCode,
                openMediaStatus: mediaStatus,
                description: values.description,
            };
        });

        const allElementArray = [...mediaDataArr, ...newMediaArr];
        const sortedArray = dateSort(allElementArray, "returnDate", t("omDistribution.indefinite"));
        setAddedMediaNos(sortedArray.map((item) => item.mediaNo));
        setMediaDataArr(sortedArray);
        closeAddMediaModalHandler();
        setIsLoading(false);
    };

    const { values, handleChange, handleBlur, handleSubmit, setFieldValue, initialValues, setValues } = useFormik({
        initialValues: {
            serviceDate: "",
            retentionProfile: "",
            isIndefiniteRetention: false,
            returnDate: "",
            drpCode: "",
            description: "",
            volserMaxLength: "20",
            customerFileSectionId: "",
        },
        onSubmit: async (values) => {
            if (values.serviceDate && mediaDataArr.length > 0) {
                setIsLoading(true);
                const volserListString = mediaDataArr?.map((value) => value.mediaNo).join("\t");
                const returnDateListString = mediaDataArr
                    .map((value) => {
                        if (value.returnDate === t("omDistribution.indefinite") || value.returnDate === "") {
                            return t("omDistribution.indefinite");
                        } else {
                            return formatDateUsingCurrentDate({
                                date: value.returnDate,
                                currentFormat: getDisplayFormat(),
                                convertFormat: dateFormats.displayDate,
                            });
                        }
                    })
                    .join("\t");
                const descrListString = mediaDataArr.map((value) => value.description).join("\t");
                const drpCodeListString = mediaDataArr.map((value) => value.drpCode).join("\t");
                const indefiniteString = t("omDistribution.indefinite");
                try {
                    const distributionRes = await createOMDistributionList(
                        getSelectedCustomerId(),
                        userDetails.preferred_username,
                        volserListString,
                        returnDateListString,
                        descrListString,
                        drpCodeListString,
                        indefiniteString,
                        formatDateUsingCurrentDate({
                            date: values.serviceDate,
                            currentFormat: getDisplayFormat(),
                            convertFormat: dateFormats.displayDate,
                        })
                    );

                    if (distributionRes.failure) {
                        setAlertParams({
                            messageCode: distributionRes.errorCode,
                            showAlert: true,
                            error: true,
                        });
                        scrollTop();
                    } else {
                        setAlertParams({
                            messageCode: 63033,
                            showAlert: true,
                            error: false,
                        });
                        scrollTop();
                        setDisableForm(true);
                        setShowErrorAlert(false);
                        setFieldValue("customerFileSectionId", distributionRes.customerFileSectionId);
                        await createLog(volserListString.length);
                    }
                } catch (error) {
                    console.error("OM Distribution OnSubmit - catch error: ", error);
                }
                setIsLoading(false);
            } else {
                scrollTop();
                setShowErrorAlert(true);
            }
        },
        enableReinitialize: true,
    });

    const getLogicalVaultCountSer = async () => {
        setIsLoading(true);
        try {
            const res = await getLogicalVaultCount();
            setLogicalVaultCount(res.rs0[0].count);
        } catch (error) {
            console.error("get Logical vault count - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getRetentionProfiles = async () => {
        setIsLoading(true);
        try {
            const getRetentionProfilesRes = await listRetentionProfiles(
                t("omDistribution.indefinite"),
                t("localizedServiceDetails.zeroDays"),
                "5"
            );
            const retentionDropArr = mapRetentionProfiles(getRetentionProfilesRes?.rs0);
            setRetentionProfDropdown(retentionDropArr);
            setRetentionProfileList(getRetentionProfilesRes?.rs0);
            setFieldValue("retentionProfile", "");
            const defaultRetentionProfile = filterData(getRetentionProfilesRes?.rs0, "default_om_dist_list_flag", "Y");
            if (defaultRetentionProfile.length > 0) {
                selectRetentionProfile(getRetentionProfilesRes?.rs0, defaultRetentionProfile[0]?.retention_profile_id);
            }
        } catch (error) {
            console.error("get Retention Profiles - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const mapRetentionProfiles = (retArr) => {
        if (retArr.length > 0) {
            return retArr.map((value) => {
                return { id: value.retention_profile_id, label: value.profile_name, value: value.retention_profile_id };
            });
        } else {
            return [{ id: "1", value: "x", label: t("common.defaultDropdownOption") }];
        }
    };

    const isRetentionProfileDefault =
        retentionProfDropdown.length === 1 && retentionProfDropdown[0].label === t("common.defaultDropdownOption");

    const selectRetentionProfile = (retArr, selectedRetentionProfId) => {
        const defaultRetentionProf = filterData(retArr, "retention_profile_id", selectedRetentionProfId);
        if (defaultRetentionProf.length > 0) {
            const isIndefiniteRetentionProfile = defaultRetentionProf[0].indefinite_retention_flag === yesFlag;
            setSelectedRetentionProfile(defaultRetentionProf[0]);
            setFieldValue("retentionProfile", defaultRetentionProf[0].retention_profile_id);
            setFieldValue("isIndefiniteRetention", isIndefiniteRetentionProfile);
            setFieldValue("drpCode", defaultRetentionProf[0].drp_code.toLocaleUpperCase());
            setFieldValue("description", defaultRetentionProf[0].media_descr);
            setFieldValue(
                "returnDate",
                isIndefiniteRetentionProfile
                    ? t("omDistribution.indefinite")
                    : calculateReturnDate(values.serviceDate, defaultRetentionProf[0], t("omDistribution.indefinite"))
            );
        } else {
            const savedServiceDate = values.serviceDate;
            setValues({ ...initialValues, serviceDate: savedServiceDate });
            setSelectedRetentionProfile(null);
        }
    };

    const serviceDateChange = (date) => {
        setFieldValue("serviceDate", date);
        setFieldValue(
            "returnDate",
            calculateReturnDate(date, selectedRetentionProfile, t("omDistribution.indefinite"))
        );
    };

    const returnDateChange = (date) => {
        setFieldValue("returnDate", date);
    };

    const retentionProfChangeHandler = (event) => {
        setFieldValue("retentionProfile", event.target.value);
    };

    useEffect(() => {
        selectRetentionProfile(retentionProfileList, values.retentionProfile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.retentionProfile]);

    const createLog = async (importedItemCount) => {
        setIsLoading(true);
        try {
            const description = `OM Distribution List - Submit ${importedItemCount} items. Customer_Id = ${getSelectedCustomerId()}`;
            const comment = `Statistics ignore deletes`;
            await insertLoggedEvent(description, comment);
        } catch (error) {
            console.error("createLog - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        setMediaDataArr([]);
        setSelectedRecord({ id: null, row: null });
    };

    const isReturnDateIndefinite = values.returnDate === t("omDistribution.indefinite");

    const returnDateIndefinitePhase = () => {
        if (isReturnDateIndefinite && values.isIndefiniteRetention) {
            return t("omDistribution.indefinite");
        }

        return "";
    };

    const printReport = () => {
        const mediaList = [];
        mediaDataArr.forEach((e) => {
            mediaList.push(e.mediaNo);
        });
        const drpCode = mediaDataArr[0] && mediaDataArr[0].drpCode ? mediaDataArr[0].drpCode : "";
        const returnDate =
            mediaDataArr[0] && mediaDataArr[0].returnDate
                ? formatDateUsingCurrentDate({
                      date: mediaDataArr[0].returnDate,
                      currentFormat: getDisplayFormat(),
                      convertFormat: dateFormats.isoDate,
                  })
                : "";
        const description = mediaDataArr[0] && mediaDataArr[0].description ? mediaDataArr[0].description : "";
        setPrintPayload(
            getOmDistributionListPayload({
                customerFileSectionId: values.customerFileSectionId,
                omListId: mediaList.toString(),
                serviceDate: formatDateUsingCurrentDate({
                    date: values.serviceDate,
                    currentFormat: getDisplayFormat(),
                    convertFormat: dateFormats.isoDate,
                }),
                returnDate,
                drpCode,
                description,
            })
        );
        printModalOpen(true);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <form onSubmit={handleSubmit}>
                    <Box sx={styles.container}>
                        <Box sx={styles.innerBox}>
                            <PageTitle>{t("omDistribution.openMediaDistributionList")}</PageTitle>
                            <SecureSyncSnackBar
                                openSnackBar={alertParams.showAlert}
                                message={
                                    <Typography fontSize={12} maxWidth={440}>
                                        <Trans
                                            i18nKey={`apiErrorMessages.${alertParams?.messageCode}`}
                                            values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                                        />
                                    </Typography>
                                }
                                severity={alertParams?.error ? "error" : "success"}
                                onClose={handleCloseSnackbar}
                            />
                            {showErrorAlert && (!values.serviceDate || mediaDataArr.length === 0) && (
                                <Box sx={styles.marginBottomBreak}>
                                    <SecureSyncAlert
                                        message={
                                            <Typography sx={styles.fontWeight500}>
                                                {t("omDistribution.followingError")}
                                                <br />
                                                {!values.serviceDate && (
                                                    <Box sx={styles.errorMsgs}>
                                                        {t("omDistribution.serviceDateMandatory")}
                                                    </Box>
                                                )}
                                                {mediaDataArr.length === 0 && (
                                                    <Box sx={styles.errorMsgs}>{t("omDistribution.noOMList")}</Box>
                                                )}
                                                {!isReturnDateIndefinite &&
                                                    !dayjs(values.returnDate, getDisplayFormat()).isValid() && (
                                                        <Box sx={styles.errorMsgs}>
                                                            {t("messages.incompleteAutoCompleteReturnDate", {
                                                                format: getDisplayFormat(),
                                                                fromDate: formatDate(minDate),
                                                                toDate: maxDate,
                                                            })}
                                                        </Box>
                                                    )}
                                            </Typography>
                                        }
                                        severity="error"
                                        onClose={() => {
                                            setShowErrorAlert(false);
                                        }}
                                    />
                                </Box>
                            )}
                            {logicalVaultCount === "0" ? (
                                <Alert severity="info">{t("omDistribution.thisCustomerIsNotConfigured")}</Alert>
                            ) : (
                                <>
                                    {!showErrorAlert && (
                                        <>
                                            <Typography sx={styles.fontWeight500}>
                                                {t("omDistribution.selectAServiceData")}
                                            </Typography>
                                            <Typography sx={styles.fontWeight500}>
                                                <Trans i18nKey={"omDistribution.clickSubmit"} />
                                            </Typography>
                                        </>
                                    )}
                                    <br />
                                    <Divider />
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("omDistribution.serviceDate")}
                                    </Typography>
                                    <Box component="div" width={"25%"} paddingRight={1}>
                                        <SecureSyncDatePicker
                                            name="serviceDate"
                                            label={t("omDistribution.serviceDate") + "*"}
                                            value={values.serviceDate}
                                            disabled={disableForm}
                                            onChange={serviceDateChange}
                                            onBlur={handleBlur}
                                            disablePast
                                            getDateProp={(dateProp) => {
                                                const isServiceDate = serviceDates.find((serviceDate) =>
                                                    serviceDate.isSame(dateProp, dateTimeUnits.day)
                                                );
                                                return { highlight: isServiceDate };
                                            }}
                                        />
                                    </Box>
                                    <Typography sx={styles.switchDescription}>
                                        {t("omDistribution.selectTheDateOM")}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("omDistribution.retentionProfile")}
                                    </Typography>
                                    <Typography sx={styles.instructionText}>
                                        {t("omDistribution.selectARetentionProfile")}
                                    </Typography>
                                    <Box component="div" sx={styles.marginBottomBreak} width={"50%"}>
                                        <SecureSyncSelect
                                            name="retentionProfile"
                                            type="outlined"
                                            label={t("omDistribution.retentionProfile")}
                                            options={retentionProfDropdown}
                                            showBlankOption={!isRetentionProfileDefault}
                                            value={values.retentionProfile}
                                            onChange={retentionProfChangeHandler}
                                            defaultOption={
                                                isRetentionProfileDefault ? t("common.defaultDropdownOption") : ""
                                            }
                                            disabled={disableForm}
                                        />
                                    </Box>
                                    <Divider />
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("omDistribution.autoCompleteValues")}
                                    </Typography>
                                    <Typography sx={styles.instructionText}>
                                        {t("omDistribution.enteringAutoCompleteValues")}
                                    </Typography>
                                    <Box component="div" sx={styles.flexRow50}>
                                        <Box component="div" style={{ flex: 1 }}>
                                            <SecureSyncDatePicker
                                                name="returnDate"
                                                label={t("omDistribution.returnDate")}
                                                value={isReturnDateIndefinite ? "" : values.returnDate}
                                                onChange={returnDateChange}
                                                onBlur={handleBlur}
                                                disabled={disableForm}
                                                disablePast
                                                getDateProp={(dateProp) => {
                                                    const isServiceDate = serviceDates.find((serviceDate) =>
                                                        serviceDate.isSame(dateProp, dateTimeUnits.day)
                                                    );
                                                    return { highlight: isServiceDate };
                                                }}
                                                customDateValue={returnDateIndefinitePhase()}
                                            />
                                        </Box>
                                        <Box component="div" style={{ flex: 1 }}>
                                            <TextField
                                                name="drpCode"
                                                label={t("omDistribution.drpCode")}
                                                inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                                                fullWidth
                                                onChange={(event) => {
                                                    setFieldValue("drpCode", event.target.value.toLocaleUpperCase());
                                                }}
                                                value={values.drpCode}
                                                disabled={disableForm}
                                            />
                                        </Box>
                                    </Box>
                                    <Box component="div" sx={styles.descriptionField}>
                                        <TextField
                                            name="description"
                                            label={t("omDistribution.description")}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={handleChange}
                                            value={values.description}
                                            disabled={disableForm}
                                        />
                                    </Box>
                                    <Divider />
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("omDistribution.openMediaDistributionList")}
                                    </Typography>
                                    <Typography sx={styles.instructionText}>
                                        <Trans i18nKey={"omDistribution.clickAddMedia"} />
                                    </Typography>
                                    <Box sx={styles.marginBottomBreak}>
                                        <OMDistributionDataTable
                                            columns={columns}
                                            mediaData={mediaDataArr}
                                            openAddMediaModalHandler={openAddMediaModalHandler}
                                            getRowId={getRowId}
                                            disableForm={disableForm}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={styles.buttonContainer}>
                                        <Button variant="outlined" onClick={handleReset} disabled={disableForm}>
                                            {t("buttonLabels.deleteAll")}
                                        </Button>
                                        <Button
                                            title={getKeyBoardLabel(shortCutKeys.submit)}
                                            variant="contained"
                                            type="submit"
                                            disabled={disableForm}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                        {disableForm && (
                                            <Button
                                                title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                                onClick={printReport}
                                                variant="contained"
                                            >
                                                {t("buttonLabels.prepareReport")}
                                            </Button>
                                        )}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </form>
            </Container>
            {isAddMediaModalOpen && (
                <AddMediaModal
                    addedMediaNos={addedMediaNos}
                    onClose={closeAddMediaModalHandler}
                    onSubmit={submitAddMediaModalHandler}
                    volserMaxLength={values.volserMaxLength}
                />
            )}
            {isEditMediaModalOpen && (
                <EditMediaModal
                    recordData={selectedRecord}
                    addedMediaNos={addedMediaNos.filter((item) => item !== selectedRecord.row.mediaNo)}
                    onClose={closeEditMediaModalHandler}
                    onSubmit={submitEditMediaModalHandler}
                    volserMaxLength={values.volserMaxLength}
                    serviceDates={serviceDates}
                />
            )}
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.omDistributionListReport}
            />
        </>
    );
};
