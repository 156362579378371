const styles = {
    Snackbar: {
        marginTop: "140px",
    },
    alert: {
        width: "100%",
    },
};

export default styles;
