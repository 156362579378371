import { Box, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./UserListSection.styles";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";

const UserListSection = ({ values, handleBlur, handleChange, touched, errors, userList }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                <FormControl sx={styles.formControlStyle}>
                    <SecureSyncSelect
                        id="selectedUser"
                        label={t("formFields.labelUser")}
                        options={userList}
                        value={values.selectedUser}
                        name="selectedUser"
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        error={touched.selectedUser && Boolean(errors.selectedUser)}
                        helperText={touched.selectedUser && errors.selectedUser}
                        required={true}
                    />
                </FormControl>
            </Box>
        </>
    );
};

export default UserListSection;
