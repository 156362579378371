import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./RequestedTransport.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const RequestedTransport = ({ records, onRemoveContainer, isEmptyTransport }) => {
    const { t } = useTranslation();
    const columns = useMemo(
        () => {
            const newColumns = [
                {
                    field: "id",
                    headerName: "",
                    width: 60,
                    sortable: false,
                    renderCell: (params) => (
                        <IconButton onClick={() => onRemoveContainer(params.id)}>
                            <Clear sx={styles.clearIcon} />
                        </IconButton>
                    ),
                },
                !isEmptyTransport
                    ? {
                          field: "vaultLabel",
                          width: 100,
                          headerName: convertToLocaleUpperCase(t("tableHeader.vaultID")),
                      }
                    : null,
                {
                    field: "transportTypeLabel",
                    headerName: convertToLocaleUpperCase(t("tableHeader.transportType")),
                },
                {
                    field: "insertConfig",
                    headerName: convertToLocaleUpperCase(t("tableHeader.insertConfiguration")),
                    renderCell: (params) => {
                        return (
                            <Stack>
                                {params.insertTypeList.length === 0 && (
                                    <Box>
                                        <Trans i18nKey={"common.notApplicable"} />
                                    </Box>
                                )}
                                {params.insertTypeList.map((insertType) => {
                                    const countLabel =
                                        insertType.count > 1 ? (
                                            <>
                                                <Box>{insertType.count}</Box>
                                                <Box> - </Box>
                                            </>
                                        ) : (
                                            <></>
                                        );
                                    return (
                                        <Box
                                            sx={styles.insertConfigSection}
                                            key={`${params.id}-${insertType.insertTypeId}`}
                                        >
                                            {countLabel}
                                            <Box>{insertType.label}</Box>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        );
                    },
                },
                {
                    field: "transportQuantity",
                    headerName: convertToLocaleUpperCase(t("tableHeader.transportQuantity")),
                    width: 200,
                },
                !isEmptyTransport
                    ? {
                          field: "isLocks",
                          headerName: `${convertToLocaleUpperCase(t("tableHeader.locks"))}?`,
                          width: 90,
                          renderCell: (params) => {
                              return <Box>{params.isLocks ? t("common.yes") : t("common.no")}</Box>;
                          },
                      }
                    : null,
            ];

            return newColumns.filter((activeColumn) => activeColumn);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [records, isEmptyTransport]
    );

    return (
        <Box>
            <PageSubTitle>{t("titles.requestedTransports")}</PageSubTitle>
            <SecureSyncTableCard>
                <Box sx={styles.tableRecordCountSection}>
                    {records.length > 0 && (
                        <Typography variant="body1">{t("messages.recordsFound", { count: records.length })}</Typography>
                    )}
                </Box>
                <SecureSyncTable
                    columns={columns}
                    rows={records}
                    noDataMessage={t("messages.noAvailableRecords")}
                ></SecureSyncTable>
            </SecureSyncTableCard>
        </Box>
    );
};

export default RequestedTransport;
