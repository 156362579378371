const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    innerContainer: {
        width: "100%",
        textAlign: "left",
    },
    contactFieldContainer: {
        padding: "10px 6px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    userDetailBody1: {
        mb: "20px",
    },
    tableContainer: {
        marginBottom: "20px",
    },
    formFields: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
    },
};

export default styles;
