import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClearIcon } from "@mui/x-date-pickers";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./ScanVerificationResultDataTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { resolved, verifyIssueTypeId } from "src/constants/processMediaConstants";
import Pencil from "src/components/Icons/Pencil/Pencil";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const ScanVerificationResultDataTable = ({
    dataArray = [],
    getRowId,
    openEditVerificationIssueModalHandler,
    isEditVerificationIssueModalOpen,
    setSelectedRecord,
    updatedRowList,
    onDelete,
    selectedRecord,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const columns = [
        {
            field: "action",
            headerName: "",
            sortable: true,
            renderCell: (params) => {
                const disableEdit = params.c7 && params.c4 === resolved.resolvedYes;
                const viewDelete = !params.c0 && params.c1 === verifyIssueTypeId.receiveOpenMediaFromIronMountain;
                return (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                        <IconButton
                            disabled={Boolean(disableEdit)}
                            onClick={() => {
                                setSelectedRecord(params);
                                openEditVerificationIssueModalHandler();
                            }}
                        >
                            {isEditVerificationIssueModalOpen && selectedRecord && selectedRecord.c3 === params.c3 ? (
                                <DoneIcon sx={styles.doneIcon} />
                            ) : updatedRowList.has(params.c3) ? (
                                <Pencil fill={theme.palette.im_orange["500"]} />
                            ) : (
                                <Pencil
                                    fill={disableEdit ? theme.palette.im_grey["200"] : theme.palette.im_mid_blue["500"]}
                                />
                            )}
                        </IconButton>
                        {viewDelete && (
                            <IconButton
                                onClick={() => {
                                    onDelete(params.c3);
                                }}
                            >
                                <ClearIcon sx={styles.clearIcon} />
                            </IconButton>
                        )}
                    </Box>
                );
            },
        },
        {
            field: "c2",
            headerName: convertToLocaleUpperCase(t("scanVerification.issue")),
            sortable: true,
        },
        {
            field: "c3",
            headerName: convertToLocaleUpperCase(t("scanVerification.mediaNumber")),
            sortable: true,
        },
        {
            field: "c4",
            headerName: convertToLocaleUpperCase(t("scanVerification.resolved")),
            sortable: true,

            renderCell: (param) => {
                return param.c4 === resolved.resolvedYes ? t("common.yes") : t("common.no");
            },
        },
        {
            field: "c5",
            headerName: `${convertToLocaleUpperCase(t("scanVerification.comments"))} *`,
            width: "130px",
            sortable: true,
        },
        {
            field: "c6",
            headerName: convertToLocaleUpperCase(t("scanVerification.originalUserDateTime")),
            sortable: true,
        },
        {
            field: "c7",
            headerName: convertToLocaleUpperCase(t("scanVerification.resolvedUserDateTime")),
            sortable: true,
        },
    ];

    return (
        <SecureSyncTableCard sx={styles.noMarginTop}>
            <Box sx={styles.recordCount}>
                {dataArray.length > 0 && (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: dataArray.length })}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={dataArray}
                    columns={columns}
                    getRowId={getRowId}
                    noDataMessage={t("scanVerification.noRecordsFound")}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default ScanVerificationResultDataTable;
