const styles = {
    textContent: {
        fontWeight: "bold",
    },
    cardHederText: {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "20px" /* 100% */,
        letterSpacing: "0.14px",
        color: "text.primary",
        paddingBottom: "20px",
    },
    fourColumnLayout: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
    },

    pageDesc: {
        fontWeight: 500,
        mb: "20px",
        "& strong": {
            fontWeight: 700,
        },
    },

    extraInserts: {
        mb: "20px",
    },
    extraLocksWrapper: { gridColumn: "span 3" },
    extraLocksFieldsWrapper: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" },
    footerButtons: { display: "flex", gap: "20px", py: "20px" },

    bottomPadding: { paddingBottom: "20px" },
    autoComplete: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "1.5",
        letterSpacing: " 0.15px",
    },
    desc: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "1.5" /* 24px */,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    comments: {
        mb: "20px",
    },
    tooltipSection: { display: "flex", alignItems: "center" },
    overFlowAuto: {
        overflow: "auto",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        paddingBottom: "20px",
    },
    bottomRight: { paddingRight: "20px", paddingBottom: "20px" },
    buttonContainer: {
        paddingTop: "20px",
        display: "flex",
        gap: 2.5,
        paddingBottom: "32px",
    },
};

export default styles;
