import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ScanVerificationHistory.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import { getListVerifiedResult } from "src/services/researchService";
import ScanVerificationHistoryTable from "src/components/Operations/Research/ScanVerificationHistory/ScanVerificationHistoryTable";
import { multipleSort } from "src/utils/processMediaHelper";
import { sortCriteriaResearch } from "src/constants/researchConstants";
import { formatDate, getDisplayFormat, getMinAndMaxDate, selectCurrentMonthFirstDate } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";
import { scanVerificationHistory } from "src/constants/routeConstants/operationsRouterFullPaths";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t }) => {
    const { minDateForApi: minDate, displayMinDate } = getMinAndMaxDate();
    const maxDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const displayMaxDate = formatDate(dayjs());
    return Yup.object().shape({
        startDate: Yup.string().test("validateStartDate", (value, { createError, path, parent }) => {
            const dateFormat = getDisplayFormat();
            const isValidDate = dayjs(value, dateFormat).isValid();
            const isToDateValid = dayjs(parent.endDate, dateFormat).isValid();
            const isBeforeDate = dayjs(value, dateFormat).isSameOrBefore(dayjs(parent.endDate, dateFormat));

            const isOutSideMinDate = dayjs(value).isBefore(minDate);

            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: parent.endDate,
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && !parent.endDate) {
                message = t("messages.fromDateRequired");
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        endDate: Yup.string().test("validateEndDate", (value, { createError, path, parent }) => {
            const dateFormat = getDisplayFormat();
            const isValidDate = dayjs(value, dateFormat).isValid();
            const isOutSideMaxDate = dayjs(value, dateFormat).isAfter(maxDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        to: t("common.to").toLowerCase(),
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && !parent.startDate) {
                message = t("messages.toDateRequired");
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const initialValues = {
    startDate: "",
    endDate: "",
};

const ScanVerificationHistory = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleResetForm() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    useEffect(() => {
        const restoreSearchResults = async () => {
            let newFormValues = {
                startDate: selectCurrentMonthFirstDate(),
                endDate: formatDate(dayjs()),
            };
            if (locationState) {
                newFormValues = {
                    startDate: locationState?.startDate || initialValues.startDate,
                    endDate: locationState?.endDate || initialValues.endDate,
                };
                fetchScanVerificationHistory(newFormValues);
            }
            await setValues(newFormValues);
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchScanVerificationHistory = async (values) => {
        setIsLoading(true);

        let startDate = values.startDate;
        let endDate = values.endDate;
        if (startDate && !endDate) {
            setFieldValue("endDate", startDate);
            endDate = startDate;
        } else if (!startDate && endDate) {
            setFieldValue("startDate", endDate);
            startDate = endDate;
        }

        const result = await getListVerifiedResult({
            startDate: getSpDateFormat(startDate),
            endDate: getSpDateFormat(endDate),
        });
        if (!result.failure) {
            const sortedList = multipleSort(result.verifiedResultList, sortCriteriaResearch.scanVerification);
            setHistoryData(sortedList);
        }

        const pageState = getPageState({ ...values, startDate: startDate, endDate: endDate }, true);
        navigate(scanVerificationHistory, {
            state: pageState,
            replace: true,
        });

        setIsLoading(false);
    };

    const getSpDateFormat = (date) => {
        const dateFormat = getDisplayFormat();
        const dayJsDate = dayjs(date, dateFormat);
        const spDate = formatDate(dayJsDate, dateFormats.displayDate);
        return spDate;
    };

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }
        return newNewPageState;
    };

    const { values, errors, touched, setValues, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
        initialValues,
        validationSchema: validationSchema({ t }),
        onSubmit: () => fetchScanVerificationHistory(values),
    });

    const { displayMinDate } = getMinAndMaxDate();

    const handleResetForm = () => {
        const newFormValues = {
            startDate: selectCurrentMonthFirstDate(),
            endDate: formatDate(dayjs()),
        };
        setValues(newFormValues);
        setHistoryData([]);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <PageTitle>
                <Trans i18nKey={"titles.scanVerificationHistory"} />
            </PageTitle>
            <Typography sx={styles.pageDesc}>
                <Trans
                    i18nKey={"scanVerificationHistory.instructionText"}
                    values={{ buttonLabel: t("buttonLabels.find") }}
                />
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
                <Box sx={styles.searchFields}>
                    <SecureSyncDatePicker
                        name="startDate"
                        value={values.startDate}
                        required
                        disableFuture
                        label={t("formFields.processingDateFrom")}
                        helperText={touched.startDate && errors.startDate}
                        error={Boolean(touched.startDate && errors.startDate)}
                        onChange={async (value) => {
                            if (dayjs(value, getDisplayFormat()).isValid()) {
                                await setFieldValue("startDate", value);
                                setFieldTouched("startDate", true, true);
                            } else {
                                await setFieldValue("startDate", "");
                            }
                        }}
                        minDate={dayjs(displayMinDate)}
                        maxDate={dayjs()}
                    />
                    <SecureSyncDatePicker
                        name="endDate"
                        value={values.endDate}
                        disableFuture
                        required
                        label={t("formFields.processingDateTo")}
                        helperText={touched.endDate && errors.endDate}
                        error={Boolean(touched.endDate && errors.endDate)}
                        onChange={async (value) => {
                            if (dayjs(value, getDisplayFormat()).isValid()) {
                                await setFieldValue("endDate", value);
                                setFieldTouched("endDate", true, true);
                            } else {
                                await setFieldValue("endDate", "");
                            }
                        }}
                        minDate={dayjs(displayMinDate)}
                        maxDate={dayjs()}
                    />
                </Box>
                <Box sx={styles.footerButtons}>
                    <Button variant="outlined" onClick={handleResetForm} title={getKeyBoardLabel(shortCutKeys.reset)}>
                        <Trans i18nKey={"buttonLabels.reset"} />
                    </Button>
                    <Button variant="contained" type="submit" title={getKeyBoardLabel(shortCutKeys.search)}>
                        <Trans i18nKey={"buttonLabels.find"} />
                    </Button>
                </Box>
            </form>
            <Box sx={styles.tableSection}>
                <ScanVerificationHistoryTable resultData={historyData} />
            </Box>
        </>
    );
};

export default ScanVerificationHistory;
