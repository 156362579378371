import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};

const dbId = process.env.REACT_APP_FB_DB_ID;
export const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp, dbId);

export const fbAuth = getAuth(firebaseApp);

// firestore collection constants starts ..... //
export const branches_collection = collection(db, "branches");
export const message_collection = collection(db, "messages");
export const message_by_code_collection = collection(db, "message");
export const templates_collection = collection(db, "templates");
export const destruct_request_type_collection = collection(db, "destruct_request_type");
export const destruct_request_status_collection = collection(db, "destruct_request_status");
export const request_type_collection = collection(db, "request_type");
export const request_status_collection = collection(db, "request_status");
export const personnel_cust_pers_collection = collection(db, "personnel_customer");
export const personnel_details_collection = collection(db, "personnel");
export const get_country_collection = collection(db, "country");
export const get_local_collection = collection(db, "locale");
export const unit_of_measure_collection = collection(db, "unit_of_measure");
export const customers_collection = collection(db, "customer");
export const service_location_collection = collection(db, "service_location");
export const holidaysCollection = collection(db, "branch_holidays");
export const holidayYearsCollection = collection(db, "branch_holiday_years");
export const app_setting_collection = collection(db, "appSettings");

export const employeeCollection = collection(db, "employee");
export const program_security_collection = collection(db, "program_security");
export const destruct_Request_Handling_Type_collection = collection(db, "destruct_request_handling_type");
export const destruct_request_box_type_collection = collection(db, "destruct_request_box_type");
export const personnel_customer_authorization_collection = collection(db, "personnel_customer_authorization");
export const authorization_code_collection = collection(db, "authorization_code");
export const media_type_collection = collection(db, "media_type");
export const global_attribute_collection = collection(db, "global_attribute");
export const dropdown_list_collection = collection(db, "dropdown_list");
export const locale_collection = collection(db, "locale");

/************* Billing Module *********************/
export const billing_classification_collection = collection(db, "billing_classification");
export const billing_cycle_collection = collection(db, "billing_cycle");
export const commission_type_collection = collection(db, "commission_type");
export const capacity_billing_suffix_collection = collection(db, "capacity_billing_suffix");
export const billing_error_action_collection = collection(db, "billing_error_action");
//**** firestore collection definition ends ....*****//
export const apiResultSet0Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_0");
export const apiResultSet1Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_1");
export const apiResultSet2Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_2");
export const apiResultSet3Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_3");
export const apiResultSet4Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_4");
export const apiResultSet5Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_5");
export const apiResultSet6Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_6");
export const apiResultSet7Collection = (documentId) => collection(db, "api_results_ss", documentId, "resultSet_7");
export const docRef = (documentId) => doc(db, "api_results_ss", documentId);
export const apiResultSet = (documentId, resultSet) => collection(db, "api_results_ss", documentId, resultSet);
