import { Box, IconButton, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RequestedInsertsTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const RequestedInsertsTable = ({ records, onClickDelete }) => {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                field: "id",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <IconButton onClick={() => onClickDelete(params.id)}>
                        <ClearIcon sx={styles.clearIcon} />
                    </IconButton>
                ),
            },
            {
                field: "insertTypeLabel",
                headerName: convertToLocaleUpperCase(t("tableHeader.insertType")),
            },
            {
                field: "insertQuantity",
                headerName: convertToLocaleUpperCase(t("tableHeader.quantity")),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <Box sx={styles.container}>
            <SecureSyncTableCard>
                <Box sx={styles.recordsMsg}>
                    {records.length > 0 && (
                        <Typography variant="body1">{t("messages.recordsFound", { count: records.length })}</Typography>
                    )}
                </Box>
                <SecureSyncTable columns={columns} rows={records} noDataMessage={t("messages.noAvailableRecords")} />
            </SecureSyncTableCard>
        </Box>
    );
};

export default RequestedInsertsTable;
