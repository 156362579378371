const styles = {
    card: {
        my: "16px",
    },
    clearIcon: {
        color: "im_mid_blue.500",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "70px",
        p: 2,
    },
    noMarginTop: {
        mt: 0,
    },
    doneIcon: {
        color: "im_mid_blue.500",
    },
};

export default styles;
