import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CustomMediaIdentifierModal from "../CustomMediaIdentifierModal/CustomMediaIdentifierModal";
import styles from "./RequestedContainer.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const RequestedContainer = ({ records, onRemoveContainer, onCMIListChange, savedCMILabels, showCMIColumn }) => {
    const { t, i18n } = useTranslation();
    const [selectedCMIListValues, setSelectedCMIListValues] = useState({
        cmiList: [],
        rowIndex: null,
    });
    const theme = useTheme();
    const nonSelectedContainerCMILabels = useMemo(() => {
        const nonSelectedRecords = records.filter((_, index) => index !== selectedCMIListValues.rowIndex);
        return nonSelectedRecords.reduce((prevList, container) => {
            const validCMIItems = container.cmiList.filter((item) => item);
            return [...prevList, ...validCMIItems];
        }, []);
    }, [records, selectedCMIListValues.rowIndex]);

    const columns = useMemo(
        () => {
            const newColumns = [
                {
                    field: "id",
                    headerName: "",
                    width: 60,
                    sortable: false,
                    renderCell: (params) => (
                        <IconButton onClick={() => onRemoveContainer(params.id)}>
                            <Clear sx={styles.clearIcon} />
                        </IconButton>
                    ),
                },
                {
                    field: "vaultLabel",
                    width: 100,
                    headerName: convertToLocaleUpperCase(t("tableHeader.vaultID")),
                },
                {
                    field: "containerTypeLabel",
                    headerName: convertToLocaleUpperCase(t("tableHeader.containerType")),
                },
                {
                    field: "insertConfig",
                    headerName: convertToLocaleUpperCase(t("tableHeader.insertConfiguration")),
                    renderCell: (params) => {
                        return (
                            <Stack>
                                {params.insertTypeList.length === 0 && <Trans i18nKey={"common.notApplicable"}></Trans>}
                                {params.insertTypeList.map((insertType) => {
                                    const countLabel =
                                        insertType.count > 1 ? (
                                            <>
                                                <Box>{insertType.count}</Box>
                                                <Box> - </Box>
                                            </>
                                        ) : (
                                            <></>
                                        );
                                    return (
                                        <Box
                                            sx={styles.insertConfigCell}
                                            key={`${params.id}-${insertType.insertTypeId}`}
                                        >
                                            {countLabel}
                                            <Box>{insertType.label}</Box>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        );
                    },
                },
                showCMIColumn
                    ? {
                          field: "Request_Status",
                          headerName: t("titles.customMediaIdentifier").toLocaleUpperCase(i18n.language),
                          width: 140,
                          renderCell: (params) => {
                              const onIconClick = () => {
                                  setSelectedCMIListValues({ rowIndex: params.rowIndex, cmiList: params.cmiList });
                              };

                              const isDuplicateLabel = params.cmiList.find((cmiLabel) => {
                                  return savedCMILabels.includes(cmiLabel);
                              });

                              const allUndefinedOrEmpty = params.cmiList.every(
                                  (value) => value === undefined || value === ""
                              );

                              return (
                                  <>
                                      {params.cmiList.length > 0 && (
                                          <Box sx={styles.cmiCell}>
                                              {allUndefinedOrEmpty ? (
                                                  <Typography>
                                                      <Trans i18nKey={"messages.none"} />
                                                  </Typography>
                                              ) : (
                                                  <Typography color={isDuplicateLabel ? "im_red.500" : "im_grey.900"}>
                                                      <Trans i18nKey={"common.values"} />
                                                  </Typography>
                                              )}
                                              <IconButton onClick={onIconClick}>
                                                  <RemoveRedEyeFilled fill={theme.palette.primary.main} />
                                              </IconButton>
                                          </Box>
                                      )}
                                  </>
                              );
                          },
                      }
                    : null,
                {
                    field: "containerQuantity",
                    headerName: convertToLocaleUpperCase(t("tableHeader.containerQuantity")),
                    width: 100,
                },
                {
                    field: "isLocks",
                    headerName: `${t("formFields.locks").toLocaleUpperCase(i18n.language)}?`,
                    width: 90,
                    renderCell: (params) => {
                        return <Box>{params.isLocks ? t("common.yes") : t("common.no")}</Box>;
                    },
                },
                {
                    field: "lockTypeLabel",
                    headerName: convertToLocaleUpperCase(t("tableHeader.lockType")),
                    width: 100,
                },
            ];

            return newColumns.filter((activeColumn) => activeColumn);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [records, savedCMILabels]
    );

    const handleClose = () => {
        setSelectedCMIListValues({ show: false, rowIndex: null, cmiList: [] });
    };

    const handleCMIListSave = (cmiList) => {
        onCMIListChange(cmiList, selectedCMIListValues.rowIndex);
        handleClose();
    };

    return (
        <Box sx={styles.container}>
            <PageSubTitle>
                <Trans i18nKey={"titles.requestedContainers"} />
            </PageSubTitle>
            <SecureSyncTableCard sx={styles.tableCard}>
                <Box sx={styles.noRecords}>
                    {records.length > 0 && (
                        <Typography variant="body1">{t("messages.recordsFound", { count: records.length })}</Typography>
                    )}
                </Box>
                <SecureSyncTable columns={columns} rows={records} noDataMessage={t("messages.noAvailableRecords")} />
            </SecureSyncTableCard>
            <CustomMediaIdentifierModal
                existingCMILabels={[...nonSelectedContainerCMILabels, ...savedCMILabels]}
                records={selectedCMIListValues.cmiList}
                show={selectedCMIListValues.rowIndex !== null}
                onClose={handleClose}
                onSave={handleCMIListSave}
            />
        </Box>
    );
};

export default RequestedContainer;
