const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "20px",
    },
    requestId: {
        fontSize: "26px",
        fontWeight: 400,
        letterSpacing: "0.13px",
        color: "im_dark_blue.500",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "32px",
        paddingBottom: "20px",
    },
};

export default styles;
