import { sha256 } from "js-sha256";
import { getCustomers, getLoggedUserDetails, getPersonalId } from "./utils";
import { displayAnswer } from "src/constants/autherizationConstants/challengeQuestionConstants";
import { getDrLevel, getIntAuth } from "src/utils/commonHelper";

export const createUpdateCustomerPayload = (request) => {
    const defaultChallengeQuestionValue = "11";
    const custMetaData = getCustomers();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    let custListString = "";
    let customerCount = 0;
    if (custMetaData.custList.length === 1) {
        customerCount = 1;
        custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : [];
    } else {
        const custList = request.selectedCustomers;
        customerCount = custList.length;
        custList.forEach((e) => {
            const customerId = e;
            if (customerId) {
                const customer = customerId.padStart(10, " ");
                custListString = `${custListString}${customer}`;
            }
        });
    }
    const challengeAnswer =
        request.challengeResponse !== "" && request.challengeResponse !== displayAnswer
            ? request.challengeResponse
            : "";
    return {
        payload: {
            main_district_id: custMetaData?.selectedCustomer?.district_id,
            first_name: request.firstName,
            last_name: request.lastName,
            middle_name: request.middleName,
            cellular_phone: transferPhoneFormat(request.cellular),
            pager_phone: request.pager,
            pager_pin_number: request.pagerPin,
            home_email_addr: request.homeEmail,
            business_email_addr: request.businessEmail,
            last_mod_user: lastModifiedUser,
            sha1_encrypted_pw_flag: "Y",
            home_phone: transferPhoneFormat(request.homePhone),
            is_secure_sync_user: request.enableSecureSync ? "1" : "0",
            challenge_question_id:
                request.challengeQuestion && request.challengeQuestion !== defaultChallengeQuestionValue
                    ? request.challengeQuestion
                    : "",
            customer_id: "",
            hashed_challenge_answer: challengeAnswer !== "" ? sha256(challengeAnswer.toUpperCase()).toUpperCase() : "",
            personnel_id: request.personalId,
            personnel_locale_id: request.contactInfoCountryCode,
            personnel_update_flag: "Y",
        },
        customerCount,
    };
};

export const createRemoveAccountPayload = (request, contextCustomerId, personId) => {
    const custMetaData = getCustomers();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    let custListString = "";
    let customerCount = 0;
    if (custMetaData.custList.length === 1) {
        customerCount = 1;
        custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : [];
    } else {
        const custList = request.selectedCustomers;
        customerCount = custList.length;
        // convert array into string with padding value
        custList.forEach((e) => {
            const customerId = e;
            if (customerId) {
                const customer = customerId.padStart(10, " ");
                custListString = `${custListString}${customer}`;
            }
        });
    }
    return {
        payload: {
            main_district_id: custMetaData?.selectedCustomer?.district_id,
            context_customer_id: contextCustomerId,
            customer_id_list: custListString,
            business_locale_id: "0",
            cust_pers_update_flag: "N",
            business_email_addr: "",
            business_fax: "",
            business_phone: "",
            business_phone_ext: "",
            employee_number: "",
            dr_auth_code: "",
            int_auth_code: "",
            last_mod_user: lastModifiedUser,
            int_auth_action: "D",
            dr_auth_action: "D",
            personnel_id: personId,
            requesting_personnel_id: getPersonalId(),
        },
        customerCount,
    };
};

export const createAddCutomerPayload = (request) => {
    const custMetaData = getCustomers();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    let custListString = "";
    let customerCount = 0;
    if (custMetaData.custList.length === 1) {
        customerCount = 1;
        custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : [];
    } else {
        const custList = request.selectedCustomers;
        customerCount = custList.length;
        // convert array into string with padding value
        custList.forEach((e) => {
            const customerId = e;
            if (customerId) {
                const customer = customerId.padStart(10, " ");
                custListString = `${custListString}${customer}`;
            }
        });

        // TODO ? handle incomming customer list
    }
    const drAuth = getDrAuthCode(request.authorizationLevel);
    const inCode = getIntAuthCode(request.authorizationLevel);
    return {
        payload: {
            main_district_id: custMetaData?.selectedCustomer?.district_id,
            first_name: request.firstName,
            last_name: request.lastName,
            middle_name: request.middleName,
            cellular_phone: transferPhoneFormat(request.cellular),
            pager_phone: request.pager,
            pager_pin_number: request.pagerPin,
            home_email_addr: request.homeEmail,
            customer_id_list: custListString,
            business_locale_id: request.accountInfoCountryCode,
            locale_id: request.contactInfoCountryCode,
            business_email_addr: request.businessEmail,
            business_fax: transferPhoneFormat(request.businessFax),
            business_phone: transferPhoneFormat(request.businessPhone),
            business_phone_ext: request.extension,
            employee_number: request.customerEmployeeNo,
            dr_auth_code: drAuth,
            int_auth_code: inCode,
            last_mod_user: lastModifiedUser,
            sha1_encrypted_pw_flag: "Y",
            sha1_encrypted_pw: "",
            auth_card_email_body:
                "User {0} created a new personnel entry for: {1} ({2}) {3}Please generate an authorization card and send it to the customer.Authorization #{4} -- Customer: {5}",
            auth_card_email_subject: "DB Auth Card Creation Notification",
            home_phone: transferPhoneFormat(request.homePhone),
            is_secure_sync_user: request.enableSecureSync ? "1" : "0",
        },
        customerCount,
    };
};

const checkCustPersChanged = (changedObj, currentObj) => {
    const isChanged =
        changedObj?.accountInfoCountryCode !== currentObj?.accountInfoCountryCode ||
        changedObj?.businessFax !== currentObj?.businessFax ||
        changedObj?.businessPhone !== currentObj?.businessPhone ||
        changedObj?.customerEmployeeNo !== currentObj?.customerEmployeeNo ||
        changedObj?.extension !== currentObj?.extension;
    return isChanged;
};

export const createUpdateAccountPayload = (
    values,
    contextCustomerId,
    personId,
    intAuthActionType,
    drAuthActionType,
    currentValues
) => {
    const isChangeCustomerPers = checkCustPersChanged(values, currentValues) ? "Y" : "N";
    const custMetaData = getCustomers();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    let custListString = "";
    let customerCount = 0;
    if (custMetaData.custList.length === 1) {
        customerCount = 1;
        custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : [];
    } else {
        const custList = values.selectedCustomers;
        customerCount = custList.length;
        // convert array into string with padding value
        custList.forEach((e) => {
            const customerId = e;
            if (customerId) {
                const customer = customerId.padStart(10, " ");
                custListString = `${custListString}${customer}`;
            }
        });
    }
    const drAuth = getDrAuthCode(values.authorizationLevel);
    const inCode = getIntAuthCode(values.authorizationLevel);
    return {
        payload: {
            main_district_id: custMetaData?.selectedCustomer?.district_id,
            context_customer_id: contextCustomerId,
            customer_id_list: custListString,
            business_locale_id: values.accountInfoCountryCode,
            cust_pers_update_flag: isChangeCustomerPers,
            business_email_addr: "",
            business_fax: transferPhoneFormat(values.businessFax),
            business_phone: transferPhoneFormat(values.businessPhone),
            business_phone_ext: values.extension,
            employee_number: values.customerEmployeeNo,
            dr_auth_code: drAuth,
            int_auth_code: inCode,
            last_mod_user: lastModifiedUser,
            int_auth_action: intAuthActionType,
            dr_auth_action: drAuthActionType,
            personnel_id: personId,
            requesting_personnel_id: getPersonalId(),
        },
        customerCount,
    };
};

const getDrAuthCode = (authLevel) => {
    const drLevel = getDrLevel();
    let authString = ``;
    drLevel.forEach((e) => {
        const authValue = authLevel[e.value];
        if (authValue) {
            authString = `${authString}${e.level}`;
        }
    });
    return authString;
};

const getIntAuthCode = (authLevel) => {
    const intAuth = getIntAuth();
    let authString = ``;
    intAuth.forEach((e) => {
        const authValue = authLevel[e.value];
        if (authValue) {
            authString = `${authString}${e.level}`;
        }
    });
    return authString;
};

const transferPhoneFormat = (numbers) => {
    return numbers;
};
