import { getDocs, query } from "firebase/firestore";
import { app_setting_collection } from "./firebase";
import { constructFbHeaders, invokeApi } from "./utils";
import { traceWrapper } from "./firebasePerformance";
import {
    genarateAuthMailBody,
    AUTH_MAIL_SUBJECT,
    genarateNewAccountCreateMailBody,
    NEW_ACCOUNT_CREATE_TARGET_USER_SUBJECT,
    genarateAccountAuthUpdateMail,
    AUTH_UPDATE_SUBJECT,
    genarateAuthChangeMailBody,
    AUTH_CHANGED_MAIL_SUBJECT,
    AUTH_PIN_CHANGED_MAIL_SUBJECT,
    genarateAuthPinChangeMailBody,
} from "src/utils/mailHelper";

export const sendAuthMail = async (authPin, authNumber, modifiedUser, to) => {
    const setting = await getMailSetting();
    const senderName = setting["CUSTOM_NAME"][0] ? setting["CUSTOM_NAME"][0] : "";
    const senderMail = setting["SENDER_MAIL_LIST"][0] ? setting["SENDER_MAIL_LIST"][0] : "";
    const body = genarateAuthMailBody(authNumber, authPin, modifiedUser);
    const cc = [];
    const subject = AUTH_MAIL_SUBJECT;
    const authMail = createMailContent(senderName, senderMail, body, cc, subject, to);
    const res = await sendMail(authMail);
    return res;
};

export const sendAuthResetMail = async (accountNumbers, name, modified, to, mail) => {
    const setting = await getMailSetting();
    const appData = await getAppData();
    const ssMobile = appData?.secureSyncMobile ? appData.secureSyncMobile : "";
    const ssWebAppUrl = appData?.secureSyncUrl ? appData.secureSyncUrl : "";
    const senderName = setting["CUSTOM_NAME"][0] ? setting["CUSTOM_NAME"][0] : "";
    const senderMail = setting["SENDER_MAIL_LIST"][0] ? setting["SENDER_MAIL_LIST"][0] : "";
    const body = genarateAuthChangeMailBody(accountNumbers, name, mail, modified, ssMobile, ssWebAppUrl);
    const cc = [];
    const subject = AUTH_CHANGED_MAIL_SUBJECT;
    const authMail = createMailContent(senderName, senderMail, body, cc, subject, to);
    const res = await sendMail(authMail);
    return res;
};

export const sendPinChangeMail = async (accountNumbers, name, modified, to, mail) => {
    const setting = await getMailSetting();
    const appData = await getAppData();
    const ssMobile = appData?.secureSyncMobile ? appData.secureSyncMobile : "";
    const ssWebAppUrl = appData?.secureSyncUrl ? appData.secureSyncUrl : "";
    const senderName = setting["CUSTOM_NAME"][0] ? setting["CUSTOM_NAME"][0] : "";
    const senderMail = setting["SENDER_MAIL_LIST"][0] ? setting["SENDER_MAIL_LIST"][0] : "";
    const body = genarateAuthPinChangeMailBody(accountNumbers, name, mail, modified, ssMobile, ssWebAppUrl);
    const cc = [];
    const subject = AUTH_PIN_CHANGED_MAIL_SUBJECT;
    const authMail = createMailContent(senderName, senderMail, body, cc, subject, to);
    const res = await sendMail(authMail);
    return res;
};

export const sendUserCreateMailCreated = async (accountNumber, name, modified, to, email = null) => {
    const setting = await getMailSetting();
    const senderName = setting["CUSTOM_NAME"][0] ? setting["CUSTOM_NAME"][0] : "";
    const senderMail = setting["SENDER_MAIL_LIST"][0] ? setting["SENDER_MAIL_LIST"][0] : "";
    const body = genarateNewAccountCreateMailBody(accountNumber, name, modified, email);
    const cc = [];
    const subject = NEW_ACCOUNT_CREATE_TARGET_USER_SUBJECT;
    const authMail = createMailContent(senderName, senderMail, body, cc, subject, to);
    const res = await sendMail(authMail);
    return res;
};

export const sendAuthUpdateMail = async (
    accountNumber,
    name,
    modified,
    email = null,
    intCode = "none",
    drCode = "none",
    to
) => {
    const setting = await getMailSetting();
    const appData = await getAppData();
    const ssMobile = appData?.secureSyncMobile ? appData.secureSyncMobile : "";
    const ssWebAppUrl = appData?.secureSyncUrl ? appData.secureSyncUrl : "";
    const senderName = setting["CUSTOM_NAME"][0] ? setting["CUSTOM_NAME"][0] : "";
    const senderMail = setting["SENDER_MAIL_LIST"][0] ? setting["SENDER_MAIL_LIST"][0] : "";
    const body = genarateAccountAuthUpdateMail(
        accountNumber,
        name,
        modified,
        (email = null),
        (intCode = "none"),
        (drCode = "none"),
        ssWebAppUrl,
        ssMobile
    );
    const cc = [];
    const subject = AUTH_UPDATE_SUBJECT;
    const authMail = createMailContent(senderName, senderMail, body, cc, subject, to);
    const res = await sendMail(authMail);
    return res;
};

const getMailSetting = async () => {
    const q = query(app_setting_collection);

    const snapshot = await getDocs(q);
    let mailSetting;
    snapshot.forEach((e) => {
        if (e.id === "email_configs") {
            const tempData = e.data();
            mailSetting = tempData;
        }
    });
    return mailSetting;
};

const getAppData = async () => {
    const q = query(app_setting_collection);
    const snapshot = await getDocs(q);
    let appData;
    snapshot.forEach((e) => {
        if (e.id === "appData") {
            const tempData = e.data();
            appData = tempData;
        }
    });
    return appData;
};

const createMailContent = (senderName, senderMail, body, cc, subject, to) => {
    const mailContent = {
        body: body,
        cc: cc,
        from: {
            emailAddress: senderMail,
            name: senderName,
        },
        languageCode: "en",
        replyTo: {
            emailAddress: senderMail,
            name: senderName,
        },
        subject: subject,
        to: to,
    };
    return mailContent;
};

const sendMail = async (content) => {
    const { traceStart, traceStop } = traceWrapper("/sendmail/send");
    traceStart();
    const headers = constructFbHeaders();
    const mailPath = process.env.REACT_APP_CF_MDS_MAIL + "/sendmail/send";
    try {
        const mail = await invokeApi("POST", mailPath, content, {}, headers);
        return mail;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
