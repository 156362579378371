export const forbiddenExtensions = new Set([
    "ade",
    "adp",
    "bas",
    "bat",
    "chm",
    "cmd",
    "com",
    "cpl",
    "crt",
    "dll",
    "exe",
    "hlp",
    "hta",
    "inf",
    "ins",
    "isp",
    "js",
    "jse",
    "lnk",
    "mdb",
    "mde",
    "msc",
    "msi",
    "msp",
    "mst",
    "ocx",
    "olb",
    "pcd",
    "pif",
    "reg",
    "scr",
    "sct",
    "shb",
    "shs",
    "tlb",
    "url",
    "vb",
    "vba",
    "vbs",
    "vbx",
    "wsc",
    "wsf",
    "wsh",
    "xls",
    "xlm",
]);

export const acceptedFileFormats = [
    ".txt",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".pdf",
    ".ppt",
    ".pptx",
    ".xml",
    ".bmp",
    ".jpg",
    ".png",
    ".jpeg",
    ".gif",
    ".rtf",
    ".zip",
    ".gzip",
    ".gz",
    ".rar",
    ".tar",
    ".tgz",
    ".taz",
    ".7z",
];
