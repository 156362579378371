import { Box, Button, Container, Divider, SvgIcon, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./UserVerification.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getHistoryInfo, getStalePersonnels } from "src/services/authorizationService";
import { ReactComponent as Edit } from "src/assets/images/RemoveRedEyeFilled.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getSSUserDetail } from "src/redux/userDetailsSlice";
import { getUserAccountPath } from "src/constants/routeConstants/administrationRouterFullPaths";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { sortHelper } from "src/utils/sortHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getUserVerificationPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";
import { getSelectedCustomer } from "src/services/utils";

export const UserVerification = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleVerify(selectedItems);
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.clear,
        callback: () => {
            handleClearSelection();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            printReport();
        },
    });
    const userDetails = useSelector(getSSUserDetail);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const clearSelectionRef = useRef(null);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const [searchParams] = useSearchParams();
    const currentPath = useMemo(() => {
        return location.pathname + location.search;
    }, [location.pathname, location.search]);

    const isCurrentCustomerOnly = searchParams.get("currentCustomerOnly") === "true";
    const limitedCustomers = isCurrentCustomerOnly ? selectedCustomer.customer_id : 0;

    const handleClearSelection = () => {
        if (clearSelectionRef.current) {
            clearSelectionRef.current.onClearSelection();
        }
    };

    const handleSelectionChange = (newSelectedItems) => {
        setSelectedItems(newSelectedItems);
    };

    useEffect(() => {
        userVerificationDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitedCustomers]);

    const printReport = () => {
        const mediaList = [];
        userData.forEach((e) => {
            mediaList.push(e.mediaNo);
        });

        setPrintPayload(getUserVerificationPayload({ limitedCustomerId: limitedCustomers }));
        printModalOpen(true);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "edit",
                headerName: "",
                width: 60,
                tooltip: t("messages.viewUserDetailsForAccount"),
                renderCell: (params) => (
                    <SvgIcon
                        component={Edit}
                        onClick={() => {
                            handleEditClick(params);
                        }}
                    />
                ),
            },
            {
                field: "customerBranch",
                headerName: convertToLocaleUpperCase(t("tableHeader.customerBranch")),
                flex: 1,
                sortable: true,
                renderCell: (row) => (
                    <div>
                        {row.customerBranch.split(" ")[0]}
                        <br />
                        {row.customerBranch.split(" ")[1]}
                    </div>
                ),
                headerClassName: "header-cell",
            },
            {
                field: "userName",
                headerName: convertToLocaleUpperCase(t("tableHeader.userName")),
                flex: 1,
                sortable: true,
                headerClassName: "header-cell",
            },
            {
                field: "interactionAuth",
                headerName: convertToLocaleUpperCase(t("tableHeader.interactionAuthority")),
                flex: 1,
                headerClassName: "header-cell",
            },
            {
                field: "interactionAuthLastUpdate",
                headerName: convertToLocaleUpperCase(t("tableHeader.interactionAuthLastUpdate")),
                flex: 1,
                headerClassName: "header-cell",
            },
            {
                field: "drAuth",
                headerName: convertToLocaleUpperCase(t("tableHeader.drAuthority")),
                flex: 1,
                headerClassName: "header-cell",
            },
            {
                field: "drAuthLastUpdate",
                headerName: convertToLocaleUpperCase(t("tableHeader.drAuthLastUpdate")),
                flex: 1,
                headerClassName: "header-cell",
            },
            {
                field: "lastVerificationWithoutUpdate",
                headerName: convertToLocaleUpperCase(t("tableHeader.lastVerificationWithoutUpdate")),
                flex: 1,
                headerClassName: "header-cell",
            },
        ];

        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userVerificationDetails = async () => {
        setLoading(true);
        try {
            const res = await getStalePersonnels(
                userDetails.personnel_id,
                false,
                userDetails.locale_id,
                limitedCustomers
            );
            const userDetail = res.rs0;
            const rowData = userDetail.map((userDetail) => ({
                personnelId: userDetail.personnel_id,
                customerId: userDetail.customer_id,
                customerBranch: `${userDetail.customer_number.trim()} (${userDetail.branch.trim()})`,
                userName: `${userDetail.last_name}, ${userDetail.first_name}`,
                interactionAuth: userDetail.authorization_code,
                interactionAuthLastUpdate: userDetail.authorization_last_update,
                drAuth: userDetail.dr_authority,
                drAuthLastUpdate: userDetail.dr_authorization_last_update,
                lastVerificationWithoutUpdate: userDetail.last_verification,
            }));
            const sortedRow = sortHelper({
                data: rowData,
                primaryProperty: "customerBranch",
                secondaryProperty: "userName",
                sortOrder: "asc",
            });
            setUserData(sortedRow);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const handleEditClick = (row) => {
        // Navigate to the edit page and pass the row data
        navigate(getUserAccountPath(row.personnelId, row.customerId), { state: { rowData: row } });
    };

    const getRowId = (row) => {
        const customerID = row.customerId.length === 10 ? row.customerId : row.customerId.padStart(10, " ");
        const personnalID =
            row.personnelId.toString().length === 10
                ? row.personnelId.toString()
                : row.personnelId.toString().padStart(10, " ");
        return `${customerID}${personnalID}`;
    };
    const handleVerify = async (selectedItems) => {
        const personalString = selectedItems.join("");
        try {
            setLoading(true);
            await getHistoryInfo(personalString);
            userVerificationDetails();
        } catch (error) {
            console.error(error);
        }
    };

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(currentPath, { state: { ...locationState, ...attributes }, replace: true });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.textBox}>
                        <PageTitle> {t("userVerification.userVerification")} </PageTitle>
                        <Typography variant="body1" sx={styles.bodyText}>
                            {userData.length > 0
                                ? t("userVerification.updateSecurityPrivileges")
                                : t("userVerification.accountsAreUpToDate")}
                        </Typography>
                    </Box>
                </Box>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        {userData.length > 0 && (
                            <>
                                <Box sx={styles.recordStart}>
                                    <Typography variant="body1" sx={styles.cardText}>
                                        {t("messages.recordsFound", { count: userData.length })}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={userData}
                            columns={columns}
                            noDataMessage={t("messages.noRecordsFound")}
                            initialPage={location.state?.pageNumber}
                            initialRowsPerPage={location.state?.rowsPerPage}
                            getRowId={(row) => getRowId(row)}
                            enableSelection={true}
                            tableRef={clearSelectionRef}
                            onSelectionChange={handleSelectionChange}
                            onTableAttributesChange={onTableStateChange}
                            renderSelectAllHeaderCell={() => (
                                <Typography width={"auto"} align={"left"} sx={styles.columnHeader}>
                                    {t("tableHeader.verify")}
                                </Typography>
                            )}
                        />
                    </Box>
                </SecureSyncTableCard>
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.clear)}
                        id="cancel-button"
                        variant="outlined"
                        color="primary"
                        onClick={handleClearSelection}
                        sx={styles.button}
                    >
                        {t("buttonLabels.clear")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        id="prepare-report-button"
                        onClick={printReport}
                        variant="outlined"
                        color="primary"
                        sx={styles.button}
                    >
                        {t("buttonLabels.prepareReport")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        id="submit-button"
                        variant="contained"
                        onClick={() => {
                            handleVerify(selectedItems);
                        }}
                        color="primary"
                        sx={styles.button}
                    >
                        {t("common.submit")}
                    </Button>
                </Box>
            </Container>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.userVerificationReport}
            />
        </>
    );
};
