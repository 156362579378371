import { t } from "i18next";
import { fileDate, startOfTheDate } from "./dateHelper";
import { multipleSort } from "./processMediaHelper";
import {
    allOption,
    detectedInTranslation,
    discrepancyTranslation,
    getAllCaps,
    getAllValue,
    mediaValues,
    sortCriteriaResearch,
} from "src/constants/researchConstants";
import { globalAttributeTypeId, noFlag, yesFlag } from "src/constants/serviceConstants";

export const getInboundOutBoundGlobalAttrs = (globalAttributes) => {
    const globalAttribute96 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id96
    );
    return {
        allowCustomerMediaIdentifiers: globalAttribute96?.global_attribute_value === noFlag,
    };
};

export const getSearchContainersGlobalAttrs = (globalAttributes) => {
    const globalAttribute96 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id96
    );
    return {
        allowCustomerMediaIdentifiers: globalAttribute96?.global_attribute_value === yesFlag,
    };
};

export const getSearchOpenMediaGlobalAttrs = (globalAttributes) => {
    const globalAttribute94 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id94
    );
    return {
        mediaNumberMaxLength: globalAttribute94?.global_attribute_value,
    };
};

export const getIdList = (inputList, prefix) => {
    if (inputList !== null && inputList.length > 0) {
        const uniqueIds = new Set();
        inputList
            .filter((item) => item.uniqueid && item.uniqueid.startsWith(prefix))
            .forEach((item) => uniqueIds.add(item.uniqueid.replace(prefix, "")));
        return uniqueIds.size > 0 ? [...uniqueIds] : [-1];
    }
    return null;
};
export const getDiscrepancyList = (discrepancyTypeList, idContext) => {
    if (discrepancyTypeList !== null && discrepancyTypeList.length > 0) {
        const uniqueAlternateIds = new Set();
        discrepancyTypeList
            .filter((item) => item.idcontext === idContext)
            .forEach((item) => uniqueAlternateIds.add(item.alternate_id));

        return uniqueAlternateIds.size > 0 ? [...uniqueAlternateIds] : [-1];
    }
    return null;
};

export const processIdLists = (detectedInIds, discrepancyTypeIds) => {
    if (detectedInIds.length > 0 || discrepancyTypeIds.length > 0) {
        if (detectedInIds.length === 0 || discrepancyTypeIds.length === 0) {
            return [...new Set([...detectedInIds, ...discrepancyTypeIds])]; // Union
        } else {
            return detectedInIds.filter((id) => discrepancyTypeIds.includes(id)); // Intersection
        }
    }
    return [];
};

export const getCommonIdList = (mediaType, detectedInList, discrepancyTypeList, prefix, idContext, type = "") => {
    if (type === "system") {
        if (mediaType !== getAllValue && mediaType !== mediaValues.container) {
            return null;
        }
    } else {
        if (mediaType !== getAllValue && mediaType !== mediaValues.openMedia) {
            return null;
        }
    }

    const detectedInIds = getIdList(detectedInList, prefix);
    const discrepancyTypeIds = getDiscrepancyList(discrepancyTypeList, idContext);
    if (detectedInIds !== null && discrepancyTypeIds !== null) {
        const filterIds = processIdLists(detectedInIds, discrepancyTypeIds);
        return filterIds.length === 0 ? "-1" : filterIds.join(",");
    }
    return null;
};

export const getFileSectionTypeIdList = (mediaType, detectedInList, discrepancyTypeList) => {
    return getCommonIdList(
        mediaType,
        detectedInList,
        discrepancyTypeList,
        "FileSectionType_",
        "Discrepancy_Type_File_Section_Type"
    );
};

export const getSystemFunctionIdList = (mediaType, detectedInList, discrepancyTypeList) => {
    return getCommonIdList(
        mediaType,
        detectedInList,
        discrepancyTypeList,
        "SystemFunction_",
        "Discrepancy_Type_System_Function",
        "system"
    );
};

export const extractNumbers = (str) => {
    const regex = /\d+/g;
    const matches = str.match(regex);
    return matches ? matches[0] : "";
};

export const getDistinctValues = (array, attributeName) => {
    const uniqueIds = [...new Set(array.map((item) => item[attributeName]))];
    return uniqueIds.join(",");
};

export const generateCSV = (data, headers, filename = "data") => {
    const headerRow = headers.join(",");
    const csvContent = [headerRow, ...data.map((row) => Object.values(row ? row : " ").join(","))].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    link.click();
};

export const generateOtherMediaExceptionFileName = () => {
    return `OpenMediaExceptionDetail_${fileDate()}`;
};

function getDetectedInKeyAndType(str) {
    const [type, id] = str.split("_");
    return {
        type: type.charAt(0).toLowerCase() + type.slice(1),
        id: id,
    };
}

export const getDetectedInTranslatedArray = (array) => {
    return array.map((value) => {
        const { type, id } = getDetectedInKeyAndType(value.uniqueid);
        const translatedName = getDetectedInTranslation(id, type);
        return { ...value, id: parseInt(id), translatedName };
    });
};

export const getDiscrepancyTypeTranslatedArray = (array, type) => {
    return array.map((value) => {
        const translatedName = getDiscrepancyTranslation(
            type,
            value.alternate_id,
            value.uniqueid.trim().replace(/\s+/g, "")
        );
        return { ...value, translatedName };
    });
};

export const getDiscrepancyXml = (discrepancyArray, selectedValue, discrepancyTypeValues) => {
    let discrepancyTypes = [...discrepancyArray];
    const selectedDiscrepancy = discrepancyTypeValues.find((discrepancy) => discrepancy.value === selectedValue);
    if (selectedValue !== getAllValue) {
        discrepancyTypes = discrepancyArray.filter((type) => type.formattedname === selectedDiscrepancy.formattedName);
    }
    const xmlString = `${discrepancyTypes
        .map((discrepancyType) => {
            return `<DiscrepancyType UniqueId='${discrepancyType.uniqueid.trim()}' AlternateId='${discrepancyType.alternate_id.trim()}' IDContext='${discrepancyType.idcontext.trim()}' />`;
        })
        .join("")}`;

    return `<ROOT>${xmlString}</ROOT>`;
};

export const getDiscrepancyData = (discrepancyArray, selectedValue, discrepancyTypeValues = []) => {
    let discrepancyTypes = [...discrepancyArray];

    const selectedDiscrepancy = discrepancyTypeValues.find((discrepancy) => discrepancy.value === selectedValue);
    if (selectedValue !== getAllValue) {
        discrepancyTypes = discrepancyArray.filter((type) => type.formattedname === selectedDiscrepancy.formattedName);
    }

    const uniqueIds = discrepancyTypes.map((discrepancyType) => discrepancyType.uniqueid.trim()).join(",");
    const alternateId = discrepancyTypes.map((discrepancyType) => discrepancyType.alternate_id.trim()).join(",");
    const idContext = discrepancyTypes.map((discrepancyType) => discrepancyType.idcontext.trim()).join(",");

    return { uniqueIds, alternateId, idContext };
};

export const getDetectedInXml = (detectedInArray, selectedDetectedIns) => {
    const filteredDetectedInObjects = detectedInArray.filter((detectedIn) => {
        return selectedDetectedIns.includes(detectedIn.translatedName);
    });

    const xmlString = `${filteredDetectedInObjects
        .map((detectedIn) => {
            return `<DetectedIn Detected_In_Id='${detectedIn.id}' Detected_In_Descr='${detectedIn.formattedname.trim()}' Detected_In_Context='${detectedIn.idcontext.trim()}' />`;
        })
        .join("")}`;

    return `<ROOT>${xmlString}</ROOT>`;
};

export const getSelectedDetectedInData = (detectedInArray, selectedDetectedIns) => {
    const filteredDetectedInObjects = detectedInArray.filter((detectedIn) => {
        return selectedDetectedIns.includes(detectedIn.translatedName);
    });

    const detectedInIds = filteredDetectedInObjects.map((detectedIn) => detectedIn.id).join(",");
    const detectedInDecs = filteredDetectedInObjects.map((detectedIn) => detectedIn.formattedname.trim()).join(",");
    const detectedInContexts = filteredDetectedInObjects.map((detectedIn) => detectedIn.idcontext.trim()).join(",");

    return { detectedInIds, detectedInDecs, detectedInContexts };
};

export const getPayload = (params, allCustomers, discrepancyArray, detectedInArray, discrepancyTypeValues = []) => {
    let payload = {};
    if (params) {
        payload = {
            customerIdList: params.customer === getAllValue ? allCustomers : params.customer,
            discrepancyStatus:
                params.discrepancyStatus === getAllValue ? getAllValue.toUpperCase() : params.discrepancyStatus,
            logicalVault: params.logicalVault === getAllValue ? "" : params.logicalVault,
            detectedDateFrom: params.detectedDateFrom ? startOfTheDate(params.detectedDateFrom) : null,
            detectedDateTo: params.detectedDateTo ? startOfTheDate(params.detectedDateTo) : null,
            resolvedDateFrom: params.resolvedDateFrom ? startOfTheDate(params.resolvedDateFrom) : null,
            resolvedDateTo: params.resolvedDateTo ? startOfTheDate(params.resolvedDateTo) : null,
            mediaNumber: params.mediaNumber,
            mediaProgramType: params.media === getAllValue ? getAllCaps : params.media,
            discrepancyTypeXML:
                params.discrepancyType === getAllValue
                    ? ""
                    : getDiscrepancyXml(discrepancyArray, params.discrepancyType, discrepancyTypeValues),
            detectedInXML: getDetectedInXml(detectedInArray, params.detectedIn),
        };
    }
    return payload;
};

export const getTranslatedSuggestions = (input) => {
    const suggestionCode = input.slice(0, 2).toLowerCase() + input.slice(2).replace(/-/g, "");

    return t(`suggestionTypes.${suggestionCode}`);
};

export const getDiscrepancyTranslation = (type, id, key) => {
    const filterData = discrepancyTranslation().filter(
        (data) => data.type === type && data.id === id && data.key === key
    );

    return filterData.length > 0 ? filterData[0].label : "";
};

export const getDetectedInTranslation = (id, type) => {
    const filteredArray = detectedInTranslation().filter((data) => data.id === id && data.type === type);
    return filteredArray.length > 0 ? filteredArray[0].label : " ";
};

export const sortOpenMediaGrid = (data) => {
    const sortedData = multipleSort(
        data.map((val) => {
            // eslint-disable-next-line camelcase
            return { ...val, sequence_id: parseInt(val.sequence_id) };
        }),
        [...sortCriteriaResearch.vaultStatusReportCustomerNo, ...sortCriteriaResearch.sortBySequenceId]
    );

    // Then order by the logic provided
    sortedData.sort((a, b) => {
        // If both discrepancy type codes are "missing"
        if (
            a.discrepancy_type_code.trim().toLowerCase() === "missing" &&
            b.discrepancy_type_code.trim().toLowerCase() === "missing"
        ) {
            // If a's file section type ID is 2, a comes before b
            if (a.file_section_type_id === "2") {
                return 1;
            }
            // If b's file section type ID is 2, b comes before a
            if (b.file_section_type_id === "2") {
                return -1;
            }
            // Otherwise, a and b are equal
            return 0;
        }
        // If a's discrepancy type code is "missing" and b's is not
        else if (a.discrepancy_type_code.trim().toLowerCase() === "missing") {
            return -1;
        }
        // If b's discrepancy type code is "missing" and a's is not
        else if (b.discrepancy_type_code.trim().toLowerCase() === "missing") {
            return 1;
        }
        // If both discrepancy type codes are "unexpected"
        else if (
            a.discrepancy_type_code.trim().toLowerCase() === "unexpected" &&
            b.discrepancy_type_code.trim().toLowerCase() === "unexpected"
        ) {
            // They are equal
            return 0;
        }
        // If a's discrepancy type code is "unexpected" and b's is not
        else if (a.discrepancy_type_code.trim().toLowerCase() === "unexpected") {
            return -1;
        }
        // If b's discrepancy type code is "unexpected" and a's is not
        else if (b.discrepancy_type_code.trim().toLowerCase() === "unexpected") {
            return 1;
        }
        // For all other cases, a comes before b if its discrepancy type code is alphabetically smaller
        else {
            return a.discrepancy_type_code
                .trim()
                .toLowerCase()
                .localeCompare(b.discrepancy_type_code.trim().toLowerCase());
        }
    });

    return sortedData;
};

export const sortContainerGrid = (data = [], sortKey, sortDirection) => {
    if (data.length === 0 || "severity" in data[0]) {
        return [];
    }
    const sortedData = multipleSort(
        data.map((val) => {
            // eslint-disable-next-line camelcase
            return { ...val, sequence_id: parseInt(val.sequence_id) };
        }),
        sortCriteriaResearch.sortBySequenceId
    );

    sortedData.sort((a, b) => {
        if (a[sortKey] === "missing") {
            if (b[sortKey] === "missing") {
                return 0;
            } else {
                return 1;
            }
        } else if (a[sortKey] === "unexpected") {
            if (b[sortKey] === "expected") {
                return 1;
            } else {
                return -1;
            }
        } else if (a[sortKey] === "expected") {
            if (b[sortKey] === "expected") {
                return 0;
            } else {
                return -1;
            }
        }

        return 6;
    });

    return sortedData;
};

export const detectedInDiscrepancySort = (a, b) => (a.uniqueid > b.uniqueid ? 1 : b.uniqueid > a.uniqueid ? -1 : 0);

export const selectDistinctDistricts = (dataArray) => {
    const seenDistricts = new Set();
    const uniqueDistricts = dataArray
        .filter((item) => {
            const isDuplicate = seenDistricts.has(item.district_id);
            seenDistricts.add(item.district_id);
            return !isDuplicate;
        })
        .map((item) => ({
            id: item.district_id,
            label: item.districtName,
            value: item.district_id,
        }));

    return [allOption(), ...uniqueDistricts];
};

export const capitalize1stLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
