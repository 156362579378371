export const styles = {
    typography: {
        color: "#14477D",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "123.5%" /* 41.99px */,
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "30px",
    },
    accordion: { borderRadius: "8px", marginBottom: "20px" },
    buttonTODO: {
        mr: 4,
        marginRight: "15px",
        borderRadius: "30px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "131%",
        letterSpacing: "0.28px",
        backgroundColor: "#d32f2f", // MUI's error color
        color: "#fff",
        borderColor: "#d32f2f",
        "&:hover": {
            borderRadius: "30px", // Maintain border radius on hover
            backgroundColor: "#b71c1c",
            borderColor: "#b71c1c",
        },
    },
    buttonReports: {
        mr: 4,
        borderRadius: "30px",
        marginRight: "15px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "131%",
        letterSpacing: "0.28px",
        backgroundColor: "transparent", // MUI's error color
        color: "#58595B",
        borderColor: "#d32f2f",
        "&:hover": {
            borderRadius: "30px", // Maintain border radius on hover
            backgroundColor: "rgba(211, 47, 47, 0.04)",
            borderColor: "#b71c1c",
        },
    },
    button: {
        mr: 4,
        marginRight: "15px",
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "1.3",
        letterSpacing: "0.28px",
        textTransform: "none",
        "&:hover": {
            borderRadius: "30px",
        },
    },
};
