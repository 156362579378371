import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    List,
    ListItem,
    SvgIcon,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { styles } from "./InventoryCaptureResults.styles";
import { ReactComponent as Edit } from "src/assets/images/RemoveRedEyeFilled.svg";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getSearchMediaDiscrepancy } from "src/constants/routeConstants/operationsRouterFullPaths";
import { discrepancyStatusValues } from "src/constants/discrepancyConstants";
import { yesFlag } from "src/constants/serviceConstants";

const InventoryCaptureResults = (props) => {
    const [icrCount, setIcrCount] = useState(0);
    const [rows, setRow] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const list = props?.inventoryCompareList ? props.inventoryCompareList : [];
        setIcrCount(list.length.toString());
        setRow(list);
    }, [props?.inventoryCompareList]);

    const editButtonClick = (params) => {
        const data = params ? params : null;
        if (data) {
            const path = getSearchMediaDiscrepancy({
                customerId: data.customerId,
                detectedDateFrom: data.completeData,
                detectedDateTo: data.completeData,
                detectedInFormattedName: t("detectedIn.inventoryCompare"),
                discrepancyStatus: discrepancyStatusValues.resolved,
                districtId: data.districtId,
                fromHomePage: yesFlag,
            });
            navigate(path);
        }
    };
    const columns = [
        {
            field: "edit",
            headerName: "",
            width: 60,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton onClick={() => editButtonClick(params)}>
                        <SvgIcon component={Edit} />
                    </IconButton>
                </Box>
            ),
        },
        { field: "completeData", headerName: t("homeModule.completeDate"), flex: 1, sortable: true },
        { field: "customer", headerName: t("homeModule.Customer"), flex: 1, sortable: true },
        { field: "logicalVault", headerName: t("homeModule.logicalVault"), flex: 1, sortable: true },
        { field: "name", headerName: t("homeModule.Name"), flex: 1, sortable: true },
        { field: "branch", headerName: t("homeModule.Branch"), flex: 1, sortable: true },
        { field: "notInIronMountain", headerName: t("homeModule.notInIronMountain"), flex: 1, sortable: true },
        { field: "notOnInventory", headerName: t("homeModule.notOnInventory"), flex: 1, sortable: true },
    ];
    return (
        <>
            <Accordion sx={styles.accord}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="inventory-capture-results-content"
                    id="inventory-capture-results"
                >
                    <Typography variant="body1" sx={styles.summaryTypography}>
                        {t("homeModule.InventoryCompareResults")} ({icrCount})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Typography variant="body1" sx={styles.detailsTypography}>
                            {t("homeModule.ClickOnTheComparisonDateBelowToViewTheLatestInventoryCompareResult")}
                        </Typography>
                        <List sx={styles.detailsList}>
                            <ListItem sx={styles.detailsListItem}>
                                {t(
                                    "homeModule.NotAtIronMountainMediaThatWasOnTheCustomersInventoryButWasNotInTheIronMountainsInventory"
                                )}
                            </ListItem>
                            <ListItem sx={styles.detailsListItem}>
                                {t(
                                    "homeModule.NotOnInventoryFileMediaThatWasInIronMountainsInventoryButWasNotOnTheCustomersInventoryFile"
                                )}
                            </ListItem>
                        </List>
                        <Box style={styles.tableBox}>
                            <SecureSyncTable
                                propStyles={styles.dataGrid}
                                rows={rows}
                                columns={columns}
                                noDataMessage={t("messages.noRecordsFound")}
                                getRowId={(row) => row.id}
                                initialOrder="decs"
                            />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

InventoryCaptureResults.propTypes = {
    count: PropTypes.number,
};

export default InventoryCaptureResults;
