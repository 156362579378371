import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OpenMediaDetails.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { sortHelper } from "src/utils/sortHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const OpenMediaDetails = ({ openMediaList }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "volser", headerName: convertToLocaleUpperCase(t("tableHeader.openMediaNumber")) },
            { field: "descr", headerName: convertToLocaleUpperCase(t("tableHeader.openMediaStatus")) },
            { field: "open_media_status", headerName: convertToLocaleUpperCase(t("tableHeader.currentStatus")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedOpenMediaList = sortHelper({
        data: openMediaList,
        primaryProperty: "volser",
        sortOrder: "asc",
    });

    return (
        <>
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    <Typography variant="h6" sx={styles.heading}>
                        {t("requestDetails.openMediaDetails")}
                    </Typography>
                    {openMediaList?.length > 0 && (
                        <>
                            <Box sx={styles.recordStart}>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: openMediaList?.length })}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable
                        rows={sortedOpenMediaList}
                        columns={columns}
                        noDataMessage={t("messages.noOpenMediaRequest")}
                        getRowId={(row) => row.id}
                    />
                </Box>
            </SecureSyncTableCard>
        </>
    );
};

export default OpenMediaDetails;
