const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    msgBox: {
        width: "100%",
        textAlign: "left",
    },
    statisticWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
        mb: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        mt: "40px",
    },
    fontWeight500: {
        fontWeight: 500,
    },
    marginBottom: {
        mb: "16px",
    },
};

export default styles;
