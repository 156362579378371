import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams, useLocation } from "react-router-dom";
import useFetchUserPreferences from "./useFetchUserPreferences";
import useLoadLanguages from "./useLoadLanguages";
import useLoadCountries from "./useLoadCountries";
import useDateLocalize from "./useDateLocalize";
import useValidateOktaSession from "./useValidateOktaSession";
import useAuthState from "./useAuthState";
import { localStorageKeys } from "src/constants/localStorageConstants";
import { dashboard, login } from "src/constants/routeConstants/rootRouterFullPaths";
import {
    getBasicUserInfo,
    getCustomerDetails,
    isUserCollectionLoaded,
    saveFbToken,
    saveSSUserDetails,
    saveTokens,
    saveUserCollections,
    saveUserDetails,
    setIsUserPreferredLanguageSet,
    setUserDetailsErrorMessage,
    updateSelectedCustomer,
} from "src/redux/userDetailsSlice";
import { fbLogin, fbSignOut, getFBToken } from "src/services/authService";
import { getLoggedInUserDetails, getSSUser } from "src/services/userServices";
import { setAuthorizationToken } from "src/services/utils";
import { postUserLoginCheck } from "src/utils/loginHelper";
import { initializePerformanceMonitoring } from "src/services/firebasePerformance";

const useInitialLoad = () => {
    useValidateOktaSession();
    const { isAuthenticated } = useAuthState();
    const { isLoading: isLanguagesLoading } = useLoadLanguages();
    const { isLoading: isUserPreferencesLoading } = useFetchUserPreferences();
    const location = useLocation();
    const { isLoading: isLoadingCountry } = useLoadCountries();
    const { isLoading: isLoadingDateLocalize } = useDateLocalize();
    const isUserDataLoaded = useSelector(isUserCollectionLoaded);
    const navigate = useNavigate();
    const { authState, oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const basicInfo = useSelector(getBasicUserInfo);
    const { defaultCustomer, activeCustomerList } = useSelector(getCustomerDetails);
    const isLanguageSet = localStorage.getItem(localStorageKeys.isUserPreferredLanguageSet);
    const redirectUrl = searchParams.get("redirectUrl");

    useEffect(() => {
        dispatch(setIsUserPreferredLanguageSet(isLanguageSet === "true"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && authState.isAuthenticated) {
            setLoading(true);
            const fetchSSUserDetails = async (username) => {
                const ssUser = await getSSUser(username);
                dispatch(saveSSUserDetails(ssUser));
            };

            const fetchUserDetails = async () => {
                try {
                    const userInfo = await oktaAuth.getUser();
                    setAuthorizationToken(authState?.idToken?.idToken);
                    dispatch(saveUserDetails(userInfo));
                    dispatch(saveTokens(authState));
                    let user;
                    const fbToken = await getFBToken();
                    if (fbToken) {
                        user = await fbLogin(fbToken);
                        initializePerformanceMonitoring();
                        dispatch(saveFbToken(user.accessToken));
                    }
                    fetchSSUserDetails(userInfo.preferred_username);
                    const loginUserDetails = await getLoggedInUserDetails(userInfo.preferred_username, userInfo.sub);
                    if (loginUserDetails.failed) {
                        setLoading(false);
                        dispatch(setUserDetailsErrorMessage("User is not configured. Contact admin."));
                        navigate(login);
                    } else {
                        dispatch(saveUserCollections(loginUserDetails));
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    console.error(err);
                }
            };

            fetchUserDetails();
        } else {
            setLoading(false);
            fbSignOut();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, isAuthenticated]);

    useEffect(() => {
        //Post login check
        if (isAuthenticated && isUserDataLoaded) {
            const postLoginCheck = postUserLoginCheck(basicInfo);
            const selectedCustomerIdFromStorage = localStorage.getItem(localStorageKeys.selectedCustomerId) || "";
            const localSelectedCustomer = activeCustomerList.find(
                (customer) => customer.customer_id === selectedCustomerIdFromStorage
            );
            let selectedCustomer;
            if (localSelectedCustomer) {
                selectedCustomer = localSelectedCustomer;
            } else if (defaultCustomer) {
                selectedCustomer = defaultCustomer;
            } else if (activeCustomerList.length === 1) {
                selectedCustomer = activeCustomerList[0];
            }

            if (selectedCustomer) {
                dispatch(
                    updateSelectedCustomer({
                        selectedCustomer: selectedCustomer,
                    })
                );
            } else {
                localStorage.setItem(localStorageKeys.selectedCustomerId, "");
            }

            if (
                !isLanguageSet ||
                isLanguageSet === "false" ||
                (postLoginCheck && !localSelectedCustomer) ||
                !selectedCustomer
            ) {
                let postLoginUrl = login;
                if (redirectUrl) {
                    postLoginUrl = postLoginUrl.concat(`?redirectUrl=${redirectUrl}`);
                }
                navigate(postLoginUrl);
            } else {
                if (!location.pathname || location.pathname === "/") {
                    navigate(dashboard);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, isAuthenticated, isUserDataLoaded, activeCustomerList, basicInfo, defaultCustomer]);

    return {
        isLoading:
            isLoading || isUserPreferencesLoading || isLanguagesLoading || isLoadingCountry || isLoadingDateLocalize,
    };
};

export default useInitialLoad;
