import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { localStorageKeys } from "src/constants/localStorageConstants";
import { getSecurityQuestionsDropdown } from "src/services/commonServices";
import { getUserPreferenceDetails } from "src/services/userServices";
import { getAccessRightsObject } from "src/utils/applicationHelper";
import { clearValuesOnSignIn } from "src/utils/loginHelper";
import { getCustomerListByStatus } from "src/utils/userDetailsHelper";

const initialState = {
    oktaUser: {},
    authToken: "",
    idToken: "",
    fbToken: "",
    ssUserDetails: {},
    userDetailsFetchStatus: fetchStatus.notStarted,
    customer: {
        activeCustomerList: [],
        onHoldCustomerList: [],
        defaultCustomer: null,
        selectedCustomer: null,
    },
    challengeQuestions: {
        status: fetchStatus.notStarted,
        data: [],
    },
    preferences: {
        status: fetchStatus.notStarted,
        data: {},
    },
    basicUserInfo: {},
    userDetailsErrorMessage: "",
    sessionGuid: "",
    programIds: [],
    accessRights: {
        status: fetchStatus.notStarted,
        data: {},
    },
    isUserPreferredLanguageSet: false,
};

export const slice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        saveTokens: (state, action) => {
            const { idToken, accessToken } = action.payload;
            state.idToken = idToken?.idToken;
            state.authToken = accessToken?.accessToken;
        },
        saveFbToken: (state, action) => {
            state.fbToken = action.payload;
        },
        saveUserDetails: (state, action) => {
            state.oktaUser = action.payload;
        },
        clearUserDetails: (state, action) => {
            clearValuesOnSignIn();
            return initialState;
        },
        saveSSUserDetails: (state, action) => {
            state.ssUserDetails = action.payload;
            // state.customerLists = JSON.parse(action.payload.customer_list);
        },
        setUserDetailsErrorMessage: (state, action) => {
            state.userDetailsErrorMessage = action.payload;
        },
        saveUserCollections: (state, action) => {
            state.userDetailsFetchStatus = fetchStatus.completed;
            const { allowedCustomers, defaultCustomer, onHoldCustomers } = getCustomerListByStatus(
                action.payload.customers
            );
            state.customer.activeCustomerList = allowedCustomers;
            state.customer.defaultCustomer = defaultCustomer;
            state.customer.onHoldCustomerList = onHoldCustomers;
            state.basicUserInfo = action.payload.postLoginCheck;
        },
        updateSelectedCustomer: (state, action) => {
            state.customer.selectedCustomer = action.payload.selectedCustomer;
            state.preferences = {
                status: fetchStatus.notStarted,
                data: {},
            };
            state.sessionGuid = "";
            state.programIds = [];
            state.accessRights = {
                status: fetchStatus.notStarted,
                data: {},
            };
            localStorage.setItem(localStorageKeys.selectedCustomerId, action.payload.selectedCustomer.customer_id);
        },
        saveSessionGuid: (state, action) => {
            state.sessionGuid = action.payload.preferences.sessionGuid;
        },
        setIsUserPreferredLanguageSet: (state, action) => {
            state.isUserPreferredLanguageSet = action.payload;
            localStorage.setItem(localStorageKeys.isUserPreferredLanguageSet, action.payload);
        },
    },
    extraReducers: (builder) => {
        //Fetch User Preferences
        builder.addCase(fetchUserPreferences.pending, (state) => {
            state.preferences.status = fetchStatus.loading;
        });
        builder.addCase(fetchUserPreferences.fulfilled, (state, action) => {
            const preferences = action.payload;
            state.preferences = { status: fetchStatus.completed, data: preferences };
            state.sessionGuid = preferences.sessionGuid;
            state.programIds = preferences.programIds;
            state.accessRights = {
                status: fetchStatus.completed,
                data: getAccessRightsObject(preferences.programIds),
            };
        });
        //Fetch Security Questions
        builder.addCase(fetchSecurityQuestions.pending, (state) => {
            state.challengeQuestions.status = fetchStatus.loading;
        });
        builder.addCase(fetchSecurityQuestions.fulfilled, (state, action) => {
            state.challengeQuestions = { status: fetchStatus.completed, data: action.payload };
        });
    },
    selectors: {
        getAccessToken: (state) => state?.authToken,
        getIdToken: (state) => state?.idToken,
        getFbToken: (state) => state?.fbToken,
        getUserDetails: (state) => state?.oktaUser,
        getAllowedCustomers: (state) => state.customer?.activeCustomerList,
        getOnHoldCustomers: (state) => state.customer?.onHoldCustomerList,
        getCustomerDetails: (state) => state.customer,
        getBasicUserInfo: (state) => state.basicUserInfo,
        isUserCollectionLoaded: (state) => state.userDetailsFetchStatus === fetchStatus.completed,
        getUserDetailsErrorMessage: (state) => state.userDetailsErrorMessage,
        getChallengeQuestions: (state) => state.challengeQuestions,
        getSelectedCustomer: (state) => state?.customer?.selectedCustomer,
        getSessionGuid: (state) => state.sessionGuid,
        getProgramIdsOfCurrentCustomer: (state) => state.programIds,
        getAccessRights: (state) => state.accessRights,
        getSSUserDetail: (state) => state.ssUserDetails,
        getSelectedLanguageLocaleId: (state) => state?.selectedLanguageDetails?.locale_id,
        getUserPreferences: (state) => state?.preferences,
        isUserPreferredLanguageSet: (state) => state.isUserPreferredLanguageSet,
    },
});

export const fetchUserPreferences = createAsyncThunk(
    "userDetails/fetchUserPreferences",
    async ({ userName, userId, customerNumber, customerId, districtId }) => {
        const userPreferences = await getUserPreferenceDetails(
            userName,
            userId,
            customerNumber,
            customerId,
            districtId
        );
        return userPreferences;
    }
);

export const fetchSecurityQuestions = createAsyncThunk(
    "userDetails/fetchSecurityQuestions",
    async () => {
        const securityQuestions = await getSecurityQuestionsDropdown();
        return securityQuestions;
    },
    {
        condition: (_args, { getState }) => {
            const state = getState();
            return state.userDetails.challengeQuestions.status === fetchStatus.notStarted;
        },
    }
);

export const {
    saveUserDetails,
    clearUserDetails,
    saveTokens,
    saveSSUserDetails,
    saveFbToken,
    saveUserCollections,
    setUserDetailsErrorMessage,
    updateSelectedCustomer,
    saveSelectedLanguageDetails,
    setIsUserPreferredLanguageSet,
} = slice.actions;

export const {
    getAccessToken,
    getIdToken,
    getFbToken,
    getUserDetails,
    isInternalUser,
    isAuthenticated,
    getAllowedCustomers,
    getOnHoldCustomers,
    getBasicUserInfo,
    isUserCollectionLoaded,
    getUserDetailsErrorMessage,
    getChallengeQuestions,
    getSelectedCustomer,
    getSessionGuid,
    getSSUserDetail,
    getProgramIdsOfCurrentCustomer,
    getAccessRights,
    getUserPreferences,
    getCustomerDetails,
    isUserPreferredLanguageSet,
} = slice.selectors;

export default slice.reducer;
