const styles = {
    typographyBody: {
        color: "im_grey.900",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    alertBox: {
        width: "100%",
    },
    successAlert: { mb: 2 },
};

export default styles;
