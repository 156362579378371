import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import CancelRequest from "src/components/Request/SearchDetails/CancelRequest/CancelRequest";
import ProtectedRoute from "src/components/AppComponents/ProtectedRoute/ProtectedRoute";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import { NotFound } from "src/pages/NotFound/NotFound";
import DeliverMedia from "src/pages/Request/DeliverMedia/DeliverMedia";
import MiscServices from "src/pages/Request/MiscServices/MiscServices";
import NewContainerLockInsert from "src/pages/Request/NewContainerLockInsert/NewContainerLockInsert";
import NewOrEmptyTransport from "src/pages/Request/NewOrEmptyTransport/NewOrEmptyTransport";
import Other from "src/pages/Request/Other/Other";
import RequestDetails from "src/pages/Request/RequestDetails/RequestDetails";
import RescheduleService from "src/pages/Request/RescheduleService/RescheduleService";
import SearchMediaDestructionRequests from "src/pages/Request/SearchMediaDestructionRequests/SearchMediaDestructionRequests";
import SearchRequests from "src/pages/Request/SearchRequests/SearchRequests";
import { getAccessRights } from "src/redux/userDetailsSlice";
import MediaDestructionRequestDetail from "src/pages/Request/MediaDestructionRequestDetail/MediaDestructionRequestDetail";

const RequestRouter = () => {
    const { request } = routePaths;
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    return (
        <Routes>
            <Route
                path={request.requestDetail}
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.searchRequestsDetail} component={RequestDetails} />
                }
            />
            <Route
                path={request.cancelRequest}
                element={<ProtectedRoute hasAccessToView={pageAccess.searchRequestsDetail} component={CancelRequest} />}
            />
            <Route
                index
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.searchRequestsDetail} component={SearchRequests} />
                }
            />
            <Route
                path={request.searchMediaDestructionRequestDetail}
                element={
                    <ProtectedRoute
                        hasAccessToView={pageAccess.searchMediaDestructionRequests}
                        component={MediaDestructionRequestDetail}
                    />
                }
            />
            <Route
                path={request.searchMediaDestructionRequest}
                element={
                    <ProtectedRoute
                        hasAccessToView={pageAccess.searchMediaDestructionRequests}
                        component={SearchMediaDestructionRequests}
                    />
                }
            />
            <Route
                path={request.deliverMedia}
                element={<ProtectedRoute hasAccessToView={pageAccess.deliverMedia} component={DeliverMedia} />}
            />
            <Route
                path={request.newContainer}
                element={
                    <ProtectedRoute
                        hasAccessToView={pageAccess.newContainerLockInsert}
                        component={NewContainerLockInsert}
                    />
                }
            />
            <Route
                path={request.newTransport}
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.newOrEmptyTransport} component={NewOrEmptyTransport} />
                }
            />
            <Route
                path={request.other}
                element={<ProtectedRoute hasAccessToView={pageAccess.other} component={Other} />}
            />
            <Route
                path={request.rescheduleService}
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.requestReschedule} component={RescheduleService} />
                }
            />
            <Route
                path={request.micsServices}
                element={<ProtectedRoute hasAccessToView={pageAccess.miscServices} component={MiscServices} />}
            />
            <Route path={"*"} element={<NotFound />} />
        </Routes>
    );
};

export default RequestRouter;
