import { TableHead, TableSortLabel, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import SecureSyncCheckBox from "../SecureSyncCheckBox/SecureSyncCheckBox";
import { styles } from "./SecureSyncTable.styles";
import TablePaginationActions from "./TablePaginationActions/TablePaginationActions";
import ArrowDown from "src/components/Icons/ArrowDown/ArrowDown";

const defaultGetRowId = (row) => row.id;
export default function SecureSyncTable({
    rows,
    columns,
    tableMinWidth = "",
    getRowId = defaultGetRowId,
    noDataMessage,
    enableSelection = false,
    tableRef,
    onSelectionChange,
    disablePagination = false,
    initialValues = [],
    renderSelectAllHeaderCell,
    selectedRecordId = "",
    headerHeight = "56px",
    rowHeight = "52px",
    initialOrderBy = "",
    initialOrder = "asc",
    initialPage = 0,
    initialRowsPerPage = 10,
    onTableAttributesChange = () => {},
    propStyles = {},
    selectAllKeepInitialValues = false,
    setPaginationOutSideTable = false,
}) {
    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [order, setOrder] = useState(initialOrder);
    const [orderBy, setOrderBy] = useState(initialOrderBy);
    const columnLength = enableSelection ? columns.length + 1 : columns.length;
    const { t } = useTranslation();
    useImperativeHandle(
        tableRef,
        () => {
            return {
                onClearSelection: () => {
                    setSelectedIDs(initialValues);
                    onSelectionChange(initialValues);
                },
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onSelectionChange]
    );

    useEffect(() => {
        if (initialValues.length !== 0) {
            setSelectedIDs(initialValues);
            onSelectionChange(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    useEffect(() => {
        setRowsPerPage(initialRowsPerPage);
    }, [initialRowsPerPage]);

    useEffect(() => {
        if (initialOrderBy !== orderBy) {
            setOrderBy(initialOrderBy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialOrderBy]);

    useEffect(() => {
        onTableAttributesChange({ rowsPerPage, pageNumber: page, order, orderBy });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page, order, orderBy]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    useEffect(() => {
        setPage(initialPage);
    }, [initialPage, rows]);

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const handleJumpToPageChange = (event, newPage) => {
        handlePageChange(newPage);
    };

    const handleSelectAllClick = (event) => {
        let selectedItemID = [];
        if (event.target.checked) {
            selectedItemID = rows
                .filter((e) => !e.isDisable)
                .map((n) => {
                    return getRowId(n);
                });
        }
        if (selectAllKeepInitialValues) {
            initialValues.forEach((element) => {
                if (!selectedItemID.find((e) => e === element)) {
                    selectedItemID.push(element);
                }
            });
        }
        setSelectedIDs(selectedItemID);
        onSelectionChange(selectedItemID);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedIDs.indexOf(id);
        let selectedItemID = [];
        if (selectedIndex === -1) {
            selectedItemID = selectedItemID.concat(selectedIDs, id);
        } else if (selectedIndex === 0) {
            selectedItemID = selectedItemID.concat(selectedIDs.slice(1));
        } else if (selectedIndex === selectedIDs.length - 1) {
            selectedItemID = selectedItemID.concat(selectedIDs.slice(0, -1));
        } else if (selectedIndex > 0) {
            selectedItemID = selectedItemID.concat(
                selectedIDs.slice(0, selectedIndex),
                selectedIDs.slice(selectedIndex + 1)
            );
        }
        setSelectedIDs(selectedItemID);
        onSelectionChange(selectedItemID);
    };

    const isSelected = (id) => selectedIDs.indexOf(id) !== -1;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortOrder = isAsc ? "desc" : "asc";
        setOrder(sortOrder);
        setOrderBy(property);
        setPage(0);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        const column = columns.find((col) => col.field === orderBy);
        if (column && column.customSort) {
            return (a, b) => column.customSort(a, b, order);
        }

        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy, column)
            : (a, b) => descendingComparator(a, b, orderBy, column) * -1;
    };

    const descendingComparator = (a, b, orderBy, column) => {
        let valueA = a[orderBy],
            valueB = b[orderBy];
        if (column.type === "string") {
            valueA = a[orderBy].toLowerCase();
            valueB = b[orderBy].toLowerCase();
        } else if (column.type === "number") {
            valueA = parseInt(a[orderBy]);
            valueB = parseInt(b[orderBy]);
        }
        if (column && column.type === "date") {
            return dayjs(valueA).isAfter(dayjs(valueB)) ? -1 : 1;
        } else {
            if (valueB < valueA) {
                return -1;
            }
            if (valueB > valueA) {
                return 1;
            }
            return 0;
        }
    };

    const sortedRows = useMemo(() => {
        if (!disablePagination) {
            if (orderBy) {
                return stableSort(rows, getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                );
            }
            return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else {
            if (orderBy) {
                return stableSort(rows, getComparator(order, orderBy));
            }
            return rows;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, order, orderBy, rows]);

    const allSelected = useMemo(() => {
        const enabledRows = rows.filter((e) => !e.isDisable);
        const enabledSelectedIds = [];
        selectedIDs.forEach((element) => {
            if (enabledRows.find((e) => getRowId(e) === element)) {
                enabledSelectedIds.push(element);
            }
        });
        return enabledRows.length > 0 && enabledSelectedIds.length === enabledRows.length;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIDs, rows]);

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    ...propStyles,
                    ...styles.tableContainer,
                }}
            >
                <Table
                    sx={tableMinWidth ? { minWidth: tableMinWidth } : styles.tableMinWidth}
                    aria-label="custom pagination table"
                >
                    <TableHead sx={styles.tableHeader}>
                        <TableRow>
                            {enableSelection && (
                                <TableCell padding="checkbox">
                                    {renderSelectAllHeaderCell ? (
                                        renderSelectAllHeaderCell()
                                    ) : (
                                        <SecureSyncCheckBox
                                            indeterminate={selectedIDs.length > 0 && selectedIDs.length < rows.length}
                                            checked={allSelected}
                                            onChange={handleSelectAllClick}
                                        />
                                    )}
                                </TableCell>
                            )}
                            {columns.map((column) => (
                                <TableCell
                                    key={column.field}
                                    width={column.width || "auto"}
                                    align={column.align || "left"}
                                    sortDirection={orderBy === column.field ? order : false}
                                    sx={styles.columnHeader(headerHeight)}
                                >
                                    {column?.sortable ? (
                                        <TableSortLabel
                                            active={orderBy === column.field}
                                            direction={orderBy === column.field ? order : "asc"}
                                            onClick={(event) => handleRequestSort(event, column.field)}
                                        >
                                            {column.headerName}
                                            {orderBy === column.field ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        column.headerName
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.length > 0 ? (
                            sortedRows.map((row, rowIndex) => {
                                const isItemSelected = isSelected(getRowId(row));
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            enableSelection && !row.isDisable && handleClick(event, getRowId(row))
                                        }
                                        role={enableSelection ? "checkbox" : undefined}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={getRowId(row)}
                                        selected={isItemSelected || getRowId(row) === selectedRecordId}
                                    >
                                        {enableSelection && (
                                            <TableCell padding="checkbox" sx={styles.selectAllHeader}>
                                                <SecureSyncCheckBox
                                                    disabled={row.isDisable}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": `enhanced-table-checkbox-${getRowId(row)}`,
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        {columns.map((column) => {
                                            const cellValue = column.renderCell
                                                ? column.renderCell({ ...row, rowIndex })
                                                : row[column.field];
                                            return column.field === "seq" ? (
                                                <TableCell
                                                    key={column.field}
                                                    align={column.align || "left"}
                                                    sx={styles.rowCell(rowHeight)}
                                                >
                                                    {getRowId(row) + 1}
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    key={column.field}
                                                    align={column.align || "left"}
                                                    sx={styles.rowCell(rowHeight)}
                                                >
                                                    {column.tooltip ? (
                                                        <Tooltip title={column.tooltip || ""} arrow>
                                                            {cellValue}
                                                        </Tooltip>
                                                    ) : (
                                                        cellValue
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columnLength}>
                                    <Box sx={styles.noDataMsgCell}>
                                        <Typography variant="body1" sx={styles.noDataMsg}>
                                            {noDataMessage}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    {rows.length > 0 && rows.length > 10 && !disablePagination && !setPaginationOutSideTable && (
                        <TableFooter sx={styles.tableFooter}>
                            <TableRow>
                                <TablePagination
                                    sx={styles.tablePagination}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    colSpan={columnLength}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                            inputProps: {
                                                "aria-label": t("tableHeader.rowsPerPage"),
                                                IconComponent: (props) => (
                                                    <ArrowDown sx={styles.arrowIcon} fillOpacity={0.66} {...props} />
                                                ),
                                            },
                                        },
                                    }}
                                    onPageChange={(_, newPage) => handlePageChange(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={(subProps) => (
                                        <TablePaginationActions
                                            {...subProps}
                                            onJumpToPageChange={handleJumpToPageChange}
                                        />
                                    )}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
            {rows.length > 0 && rows.length > 10 && !disablePagination && setPaginationOutSideTable && (
                <TablePagination
                    sx={{
                        ...styles.tablePagination,
                        ...styles.tablePaginationOutSideTable,
                    }}
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={columnLength}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                        select: {
                            inputProps: {
                                "aria-label": t("tableHeader.rowsPerPage"),
                                IconComponent: (props) => (
                                    <ArrowDown sx={styles.arrowIcon} fillOpacity={0.66} {...props} />
                                ),
                            },
                        },
                    }}
                    onPageChange={(_, newPage) => handlePageChange(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={(subProps) => (
                        <TablePaginationActions {...subProps} onJumpToPageChange={handleJumpToPageChange} />
                    )}
                />
            )}
        </>
    );
}

SecureSyncTable.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string.isRequired,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            renderCell: PropTypes.func,
        })
    ).isRequired,
    tableMinWidth: PropTypes.string,
    noDataMessage: PropTypes.string,
    getRowId: PropTypes.func,
    enableSelection: PropTypes.bool,
    disablePagination: PropTypes.bool,
    initialOrder: PropTypes.string,
    initialPage: PropTypes.number,
    initialRowsPerPage: PropTypes.number,
};
