const styles = {
    bannerContainer: {
        px: { xs: 3, sm: 5 },
        paddingBottom: "20px",
        marginBottom: "20px",
    },
    notificationWrapper: {
        maxHeight: "400px",
        overflow: "auto",
        "& p": {
            display: "block",
            margin: "16px 0px",
        },
    },
    accordionContainer: {
        paddingX: "70px",
        paddingBottom: "30px",
    },
};

export default styles;
