import { Box, Container } from "@mui/material";

import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./CompleteLibraryAudits.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getCompletedLibraryAudit } from "src/services/researchService";
import { maxCommentLength, sortCriteriaResearch } from "src/constants/researchConstants";
import CompleteLibraryAuditsTable from "src/components/Operations/Research/CompleteLibraryAudits/CompleteLibraryAuditsTable";
import { multipleSort } from "src/utils/processMediaHelper";
import { completedLibraryAudit, getAuditResultPath } from "src/constants/routeConstants/operationsRouterFullPaths";
import { formatDate, getDisplayFormat } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";

const CompleteLibraryAudits = () => {
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [libraryAuditRecords, setLibraryAuditRecords] = useState([]);
    useEffect(() => {
        const fetchCompletedLibraryRecords = async () => {
            setIsLoading(true);
            const auditResponse = await getCompletedLibraryAudit(
                maxCommentLength,
                t("completedLibraryAudits.irmPhrase")
            );

            if (!auditResponse?.failure) {
                const sortedData = multipleSort(
                    auditResponse?.auditResults,
                    sortCriteriaResearch.completeLibraryAudits
                );
                setLibraryAuditRecords(sortedData);
                const pageState = getPageState(true);
                navigate(completedLibraryAudit, {
                    state: pageState,
                    replace: true,
                });
            }
            setIsLoading(false);
        };
        fetchCompletedLibraryRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSelectedAuditRecord = (param) => {
        navigate(
            getAuditResultPath(
                param.Audit_Id,
                formatDate(dayjs(param.Completion_Date, getDisplayFormat()), dateFormats.isoDate),
                param.Audit_Type_Code
            )
        );
    };

    const getPageState = (keepPaginationInfo) => {
        const newNewPageState = {};
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }
        return newNewPageState;
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container sx={styles.containerSection}>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("completedLibraryAudits.completedLibraryAudits")}</PageTitle>
                        {libraryAuditRecords.length > 0 && (
                            <Trans
                                i18nKey={"completedLibraryAudits.clickEyeIcon"}
                                values={{ buttonLabel: t("buttonLabels.find") }}
                            />
                        )}
                    </Box>
                    <CompleteLibraryAuditsTable
                        auditData={libraryAuditRecords}
                        setSelectedAudit={setSelectedAuditRecord}
                    />
                </Box>
            </Container>
        </>
    );
};

export default CompleteLibraryAudits;
