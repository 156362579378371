/* eslint-disable max-lines */
import { Box, Button, Divider, FormControl, FormGroup, FormLabel, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import CustomerList from "../AddUser/CustomerList/CustomerList";
import ModifyHistory from "../EditUser/ModifyHistory/ModifyHistory";
import styles from "./EditAccountForm.styles";
import RemoveUserSuccessModal from "./RemoveUserSuccessModal/RemoveUserSuccessModal";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getUserPath, searchUsers } from "src/constants/routeConstants/administrationRouterFullPaths";
import {
    getAuthInfo,
    getHistoryInfo,
    getPersonalAuthService,
    removeCustomerService,
    validatePhoneFormat,
} from "src/services/authorizationService";
import { authActionType, authLevelCode } from "src/constants/autherizationConstants/authConstants";
import CountriesList from "src/components/common/CountriesList/CountriesList";
import { getDuplicateUsers, updatePersonAccount } from "src/services/userPersonalServices";
import { getBasicUserInfo, getSelectedCustomer } from "src/redux/userDetailsSlice";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import SecureSyncSupText from "src/components/common/SecureSyncSupText/SecureSyncSupText";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { checkAuthLevelValidity, downloadFileFromFB } from "src/services/utils";
import { staticFile } from "src/constants/fileConstants";
import { useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import { getDrLevel, getIntAuth, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        authorizationLevel: Yup.object()
            .shape({
                AReleaseMedia: Yup.boolean(),
                BReceiveRequestMedia: Yup.boolean(),
                CAccessIronMountainFacilities: Yup.boolean(),
                DPlaceEmergencyRequests: Yup.boolean(),
                EModifyAuthorizations: Yup.boolean(),
                RReceiveOnlyCannotRequestMedia: Yup.boolean(),
                ConfirmMediaToIncludeInDR: Yup.boolean(),
                ManageDRDocuments: Yup.boolean(),
                DeclareVerifyADR: Yup.boolean(),
                ReviseDRAuthorization: Yup.boolean(),
            })
            .test(t("authorization.authValidation"), (value) => Object.values(value).some((val) => val === true)),
        accountInfoCountryCode: Yup.string().required(t("authorization.accountCodeValidation")),
        businessPhone: Yup.string().required(t("authorization.phoneValidation")),
        selectedCustomers: Yup.array().min(1, t("authorization.customerValidation")),
    });

const initialValues = {
    authorizationLevel: {
        AReleaseMedia: false,
        BReceiveRequestMedia: false,
        CAccessIronMountainFacilities: false,
        DPlaceEmergencyRequests: false,
        EModifyAuthorizations: false,
        RReceiveOnlyCannotRequestMedia: false,
        ConfirmMediaToIncludeInDR: false,
        ManageDRDocuments: false,
        DeclareVerifyADR: false,
        ReviseDRAuthorization: false,
    },
    accountInfoCountryCode: "",
    extension: "",
    businessPhone: "",
    businessFax: "",
    customerEmployeeNo: "",
    selectedCustomers: [],
    businessEmail: "",
};

//extension businessPhone businessFax customerEmployeeNo
const EditAccountFrom = (props) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            resetForm();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.verify,
        callback: () => {
            verifyAuthHistory();
        },
    });
    const businessPhoneRef = useRef(null);
    const faxRef = useRef(null);
    const authorizationLevelRef = useRef(null);

    const clearSelectionRef = useRef(null);
    const initAccountDataRef = useRef(null);
    const personalDataRef = useRef(null);
    const [modUserOpen, setModUserOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [action, setAction] = useState("insert");
    const drLevel = getDrLevel();
    const intAuth = getIntAuth();
    const [hasSelectActiveCustomers, setActiveCustomerSelect] = useState(true);
    const [confirmMultipleAccountModal, setMultipleAccountSelect] = useState(false);
    const [removeCustomerList, setRemoveCustomerList] = useState("");
    const [openRemoveSuccessModal, setRemoveSuccessModal] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [initialCustomerSelections, setInitialCustomerSelections] = useState([]);
    const [permission, setPermission] = useState({
        canEditInt: false,
        hasPermission: true,
        canEditDrAuth: false,
        canDeleteIntUser: false,
        canDeleteNoIntUser: false,
        canEditOtherAccount: false,
    });
    const [customValidations, setCustomValidations] = useState({
        businessPhone: { validate: true, message: "" },
        fax: { validate: true, message: "" },
        authLevelNotMatched: { validate: true, message: "" },
    });
    const [activeCustomerList, setActiveCustomerList] = useState([]);

    const selectedPerson = useSelector(getBasicUserInfo);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const { t } = useTranslation();
    const params = useParams();
    const { navigate, goBack } = useSecureSyncNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchAuthInfo();
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        if (props.personalData) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.personalData]);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, submitCount } =
        useFormik({
            initialValues: initialValues,
            validationSchema: validationSchema(t),
            onSubmit: async (values) => {
                formSubmitPostCheck();
            },
        });

    useEffect(() => {
        if (submitCount > 0) {
            const { authorizationLevel, businessPhone } = errors;
            if (authorizationLevel) {
                const firstCheckbox = authorizationLevelRef.current.querySelector('input[type="checkbox"]');
                if (firstCheckbox) {
                    firstCheckbox.focus();
                }
            } else if (businessPhone) {
                businessPhoneRef.current?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    const formSubmitPostCheck = async () => {
        props.cleanMsg();
        setAction("insert");
        if (values.selectedCustomers.length > 1) {
            setMultipleAccountSelect(true);
        } else {
            triggerSubmit();
        }
    };

    const confirmClick = () => {
        if (action === "insert") {
            triggerSubmit();
        } else {
            removeThisUser();
        }
    };

    const triggerSubmit = async () => {
        setLoading(true);
        await formSubmit();
        setLoading(false);
    };

    const formSubmit = async () => {
        try {
            const [validateBusinessPhone, validateFaxPhone, validateAuthLevel] = await Promise.all([
                validatePhoneNumber("fax", values.businessFax, t("authorization.businessFax")),
                validatePhoneNumber("businessPhone", values.businessPhone, t("authorization.businessPhone")),
                checkAuthLevelValidity(values),
            ]);
            setCustomValidations((prevState) => ({
                ...prevState,
                authLevelNotMatched: validateAuthLevel,
            }));
            if (!validateBusinessPhone || !validateFaxPhone || !validateAuthLevel.validate) {
                if (!validateBusinessPhone) {
                    businessPhoneRef.current?.focus();
                } else if (!validateFaxPhone) {
                    faxRef.current?.focus();
                }
                if (!validateAuthLevel.validate) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
                return;
            }

            const intAuthAction =
                personalDataRef?.current?.interaction_code === authLevelCode.none
                    ? authActionType.insert
                    : authActionType.update;
            const drAuthAction =
                personalDataRef?.current?.dr_code === authLevelCode.none
                    ? authActionType.insert
                    : authActionType.update;
            const res = await updatePersonAccount(
                values,
                accountId,
                params.userId,
                activeCustomerList,
                props.personalData,
                intAuthAction,
                drAuthAction,
                permission,
                initAccountDataRef.current
            );
            if (res) {
                props.openSuccessMsg(res);
            } else {
                props.openSuccessMsg(res);
            }
            await fetchAuthInfo();
        } catch (error) {
            setLoading(false);
        }
    };

    const onAuthorizationFormDownload = async () => {
        setLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const file = generateFilePathForCurrentLocale(bucketPath, staticFile["authLevel"]);
        await downloadFileFromFB({
            fbFilePath: file,
            fileName: "AuthorizationLevels.pdf",
            onComplete: () => setLoading(false),
        });
    };

    const handleClearSelection = () => {
        if (clearSelectionRef.current) {
            clearSelectionRef.current.onClearSelection();
        }
    };

    const resetForm = async () => {
        setLoading(true);
        handleClearSelection();
        if (initAccountDataRef.current) {
            setValues(initAccountDataRef.current);
        } else {
            await fetchAuthInfo();
        }
        setLoading(false);
    };

    const validateCustomerSelection = (selectedCustomers) => {
        let isValid = true;
        let msg = "";
        if (activeCustomerList && activeCustomerList.length > 1 && selectedCustomers.length === 0) {
            isValid = false;
            msg = t("authorization.customerValidation");
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            isCustomerSelected: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const validatePhoneNumber = async (targetField, phoneNumber, label) => {
        let isValid = true;
        let msg = "";
        if (phoneNumber !== "") {
            const res = await validatePhoneFormat(
                { [targetField]: phoneNumber },
                { personnel: values.contactInfoCountryCode, business: values.accountInfoCountryCode }
            );
            if (res.failure && res.errorCode === "1") {
                isValid = false;
                msg = t("authorization.phoneServiceValidation", { label: label, format: res.data.bad_format_message });
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            [targetField]: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const fetchAuthInfo = async () => {
        const res = await getAuthInfo(params.userId);
        const tempIsLoggedInUser = selectedPerson.sys_user_id === params.userId;
        let accountId = params.accountId;
        const tempRows = [];
        const intSelection = [];
        let isSelectedUserDuplicate = false;
        const notDuplicatedUsers = [];
        let tempIsActiveUser = true;
        let personalAuth = await getPersonalAuthService({
            activeOnlyFlag: "N",
            PersonnelId: params.userId,
            customerId: accountId,
        });
        if (!personalAuth.personalData) {
            tempIsActiveUser = false;
            accountId = selectedCustomer.customer_id;
            const personalAuthSelectedCustomer = await getPersonalAuthService({
                activeOnlyFlag: "N",
                PersonnelId: params.userId,
                customerId: accountId,
            });
            personalAuth.personalData = personalAuthSelectedCustomer.personalData;
        }

        let selectedActiveCustomer = null;

        if (res.authList && res.authList.length !== 0) {
            const promises = res.authList.map(async (cust, index) => {
                if (cust.edit_permission_flag === "Y" || tempIsLoggedInUser) {
                    let isDuplicate = false;
                    isDuplicate = await getDuplicateUsers(props.personalData, cust.customer_id, params.userId);
                    if (cust.customer_id === accountId) {
                        selectedActiveCustomer = cust;
                        intSelection.push(index + 1);
                        isSelectedUserDuplicate = isDuplicate;
                    }
                    const rowObj = {
                        id: index + 1,
                        account: cust.customer_id,
                        customerNumber: isDuplicate ? (
                            <SecureSyncSupText name={cust.customer_number} sup={"2"} />
                        ) : (
                            cust.customer_number
                        ),
                        name: cust.customer_name,
                        branch: cust.branch_name,
                        interactionAuth: cust.interaction_code,
                        drAuth: cust.dr_code,
                        isDisable: isDuplicate,
                        details: cust,
                    };
                    if (
                        !isDuplicate &&
                        (cust.dr_code !== authLevelCode.none || cust.interaction_code !== authLevelCode.none)
                    ) {
                        notDuplicatedUsers.push(cust);
                    }
                    tempRows.push(rowObj);
                }
            });
            await Promise.all(promises);
        }
        if (isSelectedUserDuplicate && notDuplicatedUsers.length > 0 && !tempIsActiveUser) {
            const nonDuplicateUser = notDuplicatedUsers[0];
            personalAuth = await getPersonalAuthService({
                activeOnlyFlag: "N",
                PersonnelId: params.userId,
                customerId: nonDuplicateUser.customer_id,
            });
            selectedActiveCustomer = nonDuplicateUser;
        }

        setInitValues(selectedActiveCustomer, personalAuth, [accountId]);
        props.setSelectedAccount(selectedActiveCustomer);
        setAccountId(accountId);
        props.setActiveUser(tempIsActiveUser);
        setInitialCustomerSelections(intSelection);
        setActiveCustomerList(tempRows);
    };

    const postRemoveConditions = () => {
        if (values.selectedCustomers.length > 1) {
            setMultipleAccountSelect(true);
            setAction("delete");
        } else {
            removeThisUser();
        }
    };

    const removeThisUser = async () => {
        setLoading(true);
        const remove = await removeCustomerService(values, accountId, params.userId, activeCustomerList);
        const removeCustList = [];
        if (remove.successCustomerList && remove.successCustomerList.length > 0) {
            remove.successCustomerList.forEach((e) => {
                removeCustList.push(e?.number);
            });
        }
        removeCustList.sort();
        setRemoveCustomerList(removeCustList.toString());
        setRemoveSuccessModal(true);
        setLoading(false);
        //
    };

    const navigateToUserDetails = () => {
        navigate(searchUsers);
    };

    const updatePermission = (element, personalAuth) => {
        const permissions = {
            canEditInt: false,
            hasPermission: true,
            canEditDrAuth: false,
            canDeleteIntUser: false,
            canDeleteNoIntUser: false,
            canEditOtherAccount: false,
        };
        if (element.edit_int_flag === "Y" || element.edit_permission_flag === "Y") {
            if (personalAuth?.authData?.edit_int_auth_flag === "Y") {
                permissions.canEditInt = true;
            }
            if (personalAuth?.authData?.delete_int_user_flag === "Y") {
                permissions.canDeleteIntUser = true;
            }
            if (personalAuth?.authData?.delete_no_int_user_flag === "Y") {
                permissions.canDeleteNoIntUser = true;
            }
            if (personalAuth?.authData?.edit_dr_auth_flag === "Y") {
                permissions.canEditDrAuth = true;
            }
            if (personalAuth?.authData?.edit_other_account_flag === "Y") {
                permissions.canEditOtherAccount = true;
            }
        } else {
            permissions.hasPermission = false;
        }
        setPermission(permissions);
    };

    const setInitValues = (element, personalAuth, customerList) => {
        const personalData = personalAuth.personalData;
        updatePermission(element, personalAuth);

        const tempInitValues = {
            ...values,
            authorizationLevel: {
                AReleaseMedia: element.interaction_code.includes(authLevelCode.A),
                BReceiveRequestMedia: element.interaction_code.includes(authLevelCode.B),
                CAccessIronMountainFacilities: element.interaction_code.includes(authLevelCode.C),
                DPlaceEmergencyRequests: element.interaction_code.includes(authLevelCode.D),
                EModifyAuthorizations: element.interaction_code.includes(authLevelCode.E),
                RReceiveOnlyCannotRequestMedia: element.interaction_code.includes(authLevelCode.R),
                ConfirmMediaToIncludeInDR: element.dr_code.includes(authLevelCode.confirmDr),
                ManageDRDocuments: element.dr_code.includes(authLevelCode.manageDr),
                DeclareVerifyADR: element.dr_code.includes(authLevelCode.declareDr),
                ReviseDRAuthorization: element.dr_code.includes(authLevelCode.reviseDr),
            },
            accountInfoCountryCode: personalData.business_locale_id ? personalData.business_locale_id : "",
            extension: personalData.business_phone_ext ? personalData.business_phone_ext : "",
            businessPhone: personalData.business_phone ? personalData.business_phone : "",
            businessFax: personalData.business_fax ? personalData.business_fax : "",
            customerEmployeeNo: personalData.employee_number ? personalData.employee_number : "",
            businessEmail: personalData.business_email_addr ? personalData.business_email_addr : "",
            selectedCustomers: customerList,
        };
        personalDataRef.current = element;
        initAccountDataRef.current = tempInitValues;
        setValues(tempInitValues);
    };

    const onClickBack = () => {
        const path = getUserPath(params.userId);
        goBack(path);
    };

    const onClickRowSelect = (event) => {
        const selectedCustomers = [];
        let selectActiveCustomer = false;
        event.forEach((el) => {
            const selectedRow = activeCustomerList.find((e) => e.id === el);
            if (selectedRow.drAuth !== authLevelCode.none || selectedRow.interactionAuth !== authLevelCode.none) {
                selectActiveCustomer = true;
            }
            selectedCustomers.push(selectedRow.account);
        });
        setActiveCustomerSelect(selectActiveCustomer);
        setValues({
            ...values,
            selectedCustomers: selectedCustomers,
        });
        validateCustomerSelection(selectedCustomers);
    };

    const getCustomerPersonalAuthString = (customerId) => {
        const customerID = customerId.length === 10 ? customerId : customerId.padStart(10, " ");
        const personnalID =
            params.userId.toString().length === 10
                ? params.userId.toString()
                : params.userId.toString().padStart(10, " ");
        return `${customerID}${personnalID}`;
    };
    const verifyAuthHistory = async () => {
        let personalString = "";
        if (values.selectedCustomers.length > 0) {
            values.selectedCustomers.forEach((customer) => {
                const customerPersonalString = getCustomerPersonalAuthString(customer);
                personalString = personalString + customerPersonalString;
            });
            setLoading(true);
            await getHistoryInfo(personalString);
            setLoading(false);
        }
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading || isSubmitting} />
            <form noValidate onSubmit={handleSubmit}>
                <Box display="flex" gap={2}>
                    <Typography variant="h5" sx={styles.sectionTitle}>
                        {t("authorization.authLevel")}
                    </Typography>
                    <SecureSyncIconTooltip
                        onClick={onAuthorizationFormDownload}
                        title={t("authorization.authHelperToolTip")}
                        placement="right"
                    />
                </Box>
                {touched.authorizationLevel && errors.authorizationLevel && (
                    <Typography color="error" sx={styles.authLevelText}>
                        {t("authorization.authValidation")}
                    </Typography>
                )}
                {customValidations.authLevelNotMatched.message && (
                    <Typography color="error" sx={styles.authLevelText}>
                        {t("authorization.authLevelNotMatched")}
                    </Typography>
                )}
                <Box sx={styles.intAuthBox}>
                    <FormControl component="fieldset" variant="standard" sx={styles.formControl}>
                        <FormLabel required>{t("authorization.intAuth")}</FormLabel>
                        <FormGroup sx={styles.boxPadding9}>
                            {intAuth.map((e) => (
                                <>
                                    <SecureSyncCheckBox
                                        disabled={!permission.canEditInt}
                                        label={e.label}
                                        name={`authorizationLevel.${e.value}`}
                                        checked={values.authorizationLevel[e.value]}
                                        onChange={handleChange}
                                    />
                                </>
                            ))}
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard" sx={{ flex: 1 }}>
                        <FormLabel required>{t("authorization.drAuth")}</FormLabel>
                        <FormGroup ref={authorizationLevelRef} sx={styles.boxPadding9}>
                            {drLevel.map((e) => (
                                <>
                                    <SecureSyncCheckBox
                                        disabled={!permission.canEditDrAuth}
                                        label={e.label}
                                        name={`authorizationLevel.${e.value}`}
                                        checked={values.authorizationLevel[e.value]}
                                        onChange={handleChange}
                                    />
                                </>
                            ))}
                        </FormGroup>
                        {touched.DRA && errors.DRA && <Typography color="error">{errors.DRA}</Typography>}
                    </FormControl>
                </Box>
                <Divider />
                <Typography variant="h5" sx={styles.sectionTitle}>
                    {t("authorization.accountInfo")}
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={2} sx={styles.dataGridContainer}>
                    <FormControl sx={styles.muiNonePadding}>
                        <CountriesList
                            id="country-code-select"
                            label={t("authorization.countryPhoneCode")}
                            name="accountInfoCountryCode"
                            value={values.accountInfoCountryCode}
                            onChangeCallBack={(item) => {
                                values.accountInfoCountryCode = item;
                            }}
                            onBlur={handleBlur}
                            error={touched.accountInfoCountryCode && Boolean(errors.accountInfoCountryCode)}
                            helperText={touched.accountInfoCountryCode && errors.accountInfoCountryCode}
                        />
                    </FormControl>
                    <TextField
                        label={t("authorization.businessPhone")}
                        name="businessPhone"
                        variant="outlined"
                        required
                        value={values.businessPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.businessPhone &&
                            (Boolean(errors.businessPhone) || !customValidations.businessPhone.validate)
                        }
                        helperText={
                            touched.businessPhone && (errors.businessPhone || customValidations.businessPhone.message)
                        }
                        fullWidth
                        inputRef={businessPhoneRef}
                    />
                    <TextField
                        label={t("formFields.extension")}
                        name="extension"
                        variant="outlined"
                        value={values.extension}
                        inputProps={{ maxLength: 6 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />

                    <TextField
                        label={t("authorization.businessFax")}
                        name="businessFax"
                        variant="outlined"
                        value={values.businessFax}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.businessFax && (Boolean(errors.businessFax) || !customValidations.fax.validate)}
                        helperText={touched.businessFax && (errors.businessFax || customValidations.fax.message)}
                        fullWidth
                        inputRef={faxRef}
                    />
                </Box>
                <Box display="flex" flexWrap="wrap" gap={2} sx={styles.dataGridContainer}>
                    <TextField
                        label={t("authorization.empNo")}
                        name="customerEmployeeNo"
                        variant="outlined"
                        inputProps={{ maxLength: 15 }}
                        value={values.customerEmployeeNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Box>
                <Divider />

                {activeCustomerList && activeCustomerList.length > 1 && (
                    <CustomerList
                        touched={touched.selectedCustomers}
                        error={errors.selectedCustomers}
                        onClickRowSelect={onClickRowSelect}
                        rows={activeCustomerList}
                        clearSelectionRef={clearSelectionRef}
                        initialValues={initialCustomerSelections}
                    />
                )}
                <ModifyHistory
                    modUserOpen={modUserOpen}
                    personalData={props.personalData}
                    closePopup={() => setModUserOpen(false)}
                />
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button variant="outlined" color="primary" onClick={onClickBack} startIcon={<IconLeft />}>
                        {t("buttonLabels.back")}
                    </Button>
                    <Button variant="outlined" onClick={() => setModUserOpen(true)} color="primary">
                        {t("buttonLabels.lastModifyData")}
                    </Button>
                    {permission.canDeleteIntUser && props?.isActiveUser && !props?.isLoggedInUser && (
                        <Button
                            disabled={!hasSelectActiveCustomers}
                            onClick={postRemoveConditions}
                            variant="outlined"
                            color="warning"
                        >
                            {t("buttonLabels.removeUser")}
                        </Button>
                    )}
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.verify)}
                        variant="outlined"
                        onClick={verifyAuthHistory}
                        color="primary"
                    >
                        {t("buttonLabels.verify")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.reset)}
                        variant="outlined"
                        onClick={resetForm}
                        color="primary"
                    >
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        variant="contained"
                        type="submit"
                        color="primary"
                        sx={styles.buttonSubmit}
                    >
                        {t("buttonLabels.submit")}
                    </Button>
                </Box>
            </form>
            <ConfirmationModal
                count={values?.selectedCustomers?.length}
                handleConfirm={() => {
                    setMultipleAccountSelect();
                    confirmClick();
                }}
                onClose={() => {
                    setMultipleAccountSelect(false);
                }}
                open={confirmMultipleAccountModal}
            />

            <RemoveUserSuccessModal
                customerList={removeCustomerList}
                customerName={props.displayName}
                onClose={() => {
                    navigateToUserDetails();
                }}
                open={openRemoveSuccessModal}
            />
        </>
    );
};

export default EditAccountFrom;
