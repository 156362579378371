import { Box, Button, SvgIcon, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./GlobalMediaLocatorTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import {
    getContainerDetailPath,
    getOpenMediaDetailPath,
    globalMediaLocator,
} from "src/constants/routeConstants/operationsRouterFullPaths";
import { getCurrentStatus, getTruncatedDescription } from "src/utils/globalMediaLocatorHelper";
import { mediaLocatorView } from "src/constants/researchConstants";
import {
    getKeyBoardLabel,
    padStartStringWithSelectedCharacters,
    convertToLocaleUpperCase,
} from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { getAllowedCustomers, getBasicUserInfo } from "src/redux/userDetailsSlice";
import { getGlobalMediaLocatorResultsReport } from "src/services/printServices";
import { targetUrl } from "src/constants/printConstants";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const GlobalMediaLocatorTable = ({ records = [], isSearched, mediaNumber }) => {
    const shortCutKeys = getShortCutKeys();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const hasRequests = records.length > 0;
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const customerList = useSelector(getAllowedCustomers);
    const userDetails = useSelector(getBasicUserInfo);
    const columns = useMemo(
        () => [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <SecureSyncIconTooltip
                        title={t("globalMediaLocator.viewMediaDetails")}
                        onClick={() => handleEditClick(params)}
                        icon={<SvgIcon fill={theme.palette.primary.main} component={RemoveRedEyeFilled} />}
                    ></SecureSyncIconTooltip>
                ),
            },
            {
                field: "Media_Number",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")),
                sortable: true,
            },
            {
                field: "Media_Type",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaType")),
                sortable: true,
                renderCell: (params) => {
                    let label = "";
                    if (params.Media_Type === "Open Media") {
                        label = t("common.openMedia");
                    } else if (params.Media_Type === "Container") {
                        label = t("common.containers");
                    }
                    return <span>{label}</span>;
                },
            },
            {
                field: "Branch",
                headerName: convertToLocaleUpperCase(t("tableHeader.branch")),
                sortable: true,
            },
            {
                field: "Customer_Number",
                headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
                sortable: true,
            },
            {
                field: "Return_Date",
                headerName: convertToLocaleUpperCase(t("tableHeader.returnDateOrCurrentStatus")),
                type: "date",
                renderCell: (params) => {
                    return getCurrentStatus(params);
                },
            },
            {
                field: "Description",
                headerName: convertToLocaleUpperCase(t("tableHeader.description")),
                sortable: true,
                renderCell: (params) => {
                    return getTruncatedDescription(params.Description);
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const handleEditClick = (params) => {
        const url =
            params.Media_Locator_View === mediaLocatorView.openMedia
                ? getOpenMediaDetailPath({
                      mediaId: params.Media_Id,
                      districtId: params.District_Id,
                      customerNumber: params.Customer_Number,
                  })
                : getContainerDetailPath({ mediaId: params.Media_Id, districtId: params.District_Id });
        navigate(url);
    };

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(globalMediaLocator, { state: { ...locationState, ...attributes }, replace: true });
    };

    const printReport = () => {
        let customerIdList = "";
        customerList?.forEach((e) => {
            customerIdList += padStartStringWithSelectedCharacters(e?.customer_id, 10, "0");
        });
        const userName = userDetails.username;
        setPrintPayload(
            getGlobalMediaLocatorResultsReport({
                mediaNumber: mediaNumber,
                userName: userName,
                customerIdList: customerIdList,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <Box sx={styles.container}>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        {hasRequests && (
                            <>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: records.length })}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={printReport}
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                >
                                    {t("buttonLabels.prepareReport")}
                                </Button>
                            </>
                        )}
                    </Box>
                    <SecureSyncTable
                        rows={records}
                        columns={columns}
                        initialPage={location.state?.pageNumber}
                        initialRowsPerPage={location.state?.rowsPerPage}
                        initialOrderBy={location.state?.orderBy}
                        initialOrder={location.state?.order}
                        noDataMessage={
                            isSearched ? t("messages.noMatchingRecords") : t("messages.pleaseProvideTheInput")
                        }
                        getRowId={(row) => row.Media_Id}
                        onTableAttributesChange={onTableStateChange}
                    />
                </SecureSyncTableCard>
                {hasRequests && (
                    <Typography sx={styles.footerCaption}>
                        <Trans i18nKey={"common.tableFooterCaption"}></Trans>
                    </Typography>
                )}
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.globalMediaLocatorResultsReport}
            />
        </>
    );
};

export default GlobalMediaLocatorTable;
