import { useState, useEffect } from "react";
import { Box, Button, Grid, Divider } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./DetailEmployees.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { getEmployeeInformationById, getDistrictInfoById } from "src/services/disasterService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getEmployeeContactInformationPayload } from "src/services/printServices";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const DetailEmployees = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const [employeeInfo, setEmployeeInfo] = useState([]);
    const [districtInfo, setDistrictInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const navigate = useNavigate();
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const getEmployeeDetails = async () => {
        setIsLoading(true);
        const employeeInfo = await getEmployeeInformationById(params.employeeId);
        setEmployeeInfo(employeeInfo?.rs0[0] ? employeeInfo?.rs0[0] : []);
        const districtInfo = await getDistrictInfoById(employeeInfo?.rs0[0].district_id);
        setDistrictInfo(districtInfo?.rs0[0] ? districtInfo?.rs0[0] : []);
        setIsLoading(false);
    };

    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });

    useEffect(() => {
        if (params.employeeId !== "") {
            getEmployeeDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const printReport = () => {
        setPrintPayload(
            getEmployeeContactInformationPayload({
                employeeId: params.employeeId,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <PageTitle>{t("titles.employeeDetails")}</PageTitle>

                    <Grid container justifyContent="left" sx={styles.gridContainer}>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.first_name}
                                label={t("formFields.firstName")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.last_name}
                                label={t("formFields.lastName")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.title}
                                label={t("formFields.jobTitle")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}></Grid>

                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={`${employeeInfo.business_phone}${employeeInfo.business_phone_ext !== "" ? ", X" + employeeInfo.business_phone_ext : ""}`}
                                label={t("formFields.businessPhone")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.pager_phone}
                                label={t("formFields.pager")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.cellular_phone}
                                label={t("formFields.cellular")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={districtInfo.fax_phone}
                                label={t("formFields.fax")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={districtInfo.toll_free_phone}
                                label={t("formFields.tollFreeNumber")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.home_phone}
                                label={t("formFields.homePhone")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={6} sx={styles.gridItem}></Grid>
                        <Grid item md={9} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={employeeInfo.external_email_addr}
                                label={t("formFields.emailAddress")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box sx={styles.buttonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<IconLeft />}
                            onClick={() => navigate(-1)}
                        >
                            {t("buttonLabels.back")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => printReport()}
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        >
                            {t("buttonLabels.prepareReport")}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.employeeContactInformation}
            />
        </>
    );
};

export default DetailEmployees;
