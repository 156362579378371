import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { AccountStatusSection } from "../../components/Dashboard/AccountStatusSection/AccountStatusSection";
import { IronMountainServiceSection } from "../../components/Dashboard/IronMountainServicesSection/IronMountainServiceSection";
import { NotificationSection } from "../../components/Dashboard/NotificationSection/NotificationSection";
import { WelcomeSection } from "../../components/Dashboard/WelcomeSection/WelcomeSection";
import { getUrlList } from "src/services/homeService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { BannerSection } from "src/components/Dashboard/BannerSection/BannerSection";

export const Dashboard = () => {
    const [links, setLinks] = useState({});
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const links = await getUrlList();
            setLinks(links);
            setLoading(false);
        };
        fetchData();
    }, []);
    return (
        <Box>
            <CircularLoaderFullPage show={isLoading} />
            <WelcomeSection />
            <BannerSection />
            <NotificationSection />
            <AccountStatusSection />
            <IronMountainServiceSection urls={links} />
        </Box>
    );
};
