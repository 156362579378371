const styles = {
    textContent: {
        fontWeight: "bold",
    },
    pageDesc: {
        fontWeight: 500,
        mb: "20px",
        "& strong": {
            fontWeight: 700,
        },
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "20px",
        mb: "20px",
    },
    footerButtons: { display: "flex", gap: "20px", mb: "20px" },
    tableSection: { mb: "20px" },
};

export default styles;
