import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ExtraInsertsPurchase.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const ExtraInsertsPurchase = ({ extraInsertPurchaseData }) => {
    const { t } = useTranslation();
    const noDataMsg = t("messages.noExtraInserts");
    const tableHeading =
        extraInsertPurchaseData.length > 0
            ? extraInsertPurchaseData[0].bill_for_inserts_flag === "Y"
                ? t("requestDetails.extraInsertsPurchase")
                : t("requestDetails.extraInsertsExchange")
            : t("requestDetails.extraInserts");
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "type", headerName: convertToLocaleUpperCase(t("tableHeader.insertType")) },
            { field: "quantity", headerName: convertToLocaleUpperCase(t("tableHeader.quantity")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={styles.container}>
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    <Typography variant="h6" sx={styles.heading}>
                        {tableHeading}
                    </Typography>
                    {extraInsertPurchaseData.length > 0 && (
                        <Box>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: extraInsertPurchaseData.length })}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable rows={extraInsertPurchaseData} columns={columns} noDataMessage={noDataMsg} />
                </Box>
            </SecureSyncTableCard>
        </Box>
    );
};

export default ExtraInsertsPurchase;
