import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./CommentSection.styles";

const CommentSection = ({ values, handleBlur, handleChange, touched, errors }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.boxPaddingBottom}>
                <TextField
                    id="comments"
                    name="comments"
                    label={t("headerMenu.comments")}
                    variant="outlined"
                    multiline
                    fullWidth
                    inputProps={{ maxLength: 2048 }}
                    value={values.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                    InputProps={{
                        sx: {
                            overflow: "auto",
                        },
                    }}
                    rows={4}
                />
            </Box>
        </>
    );
};
export default CommentSection;
