const styles = {
    bodyText: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        color: "im_grey.900",
    },
    textField: {
        paddingBottom: "20px",
        "& .MuiInputBase-root": {
            height: "150px",
            alignItems: "flex-start",
        },
        "& .MuiOutlinedInput-input": {
            height: "100%",
            padding: "10px",
        },
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    title: {
        fontWeight: "600",
    },
};

export default styles;
