import { Box, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./MediaDestructionSuccessModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const MediaDestructionSuccessModal = ({ open, onClose, values, printReport }) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            printReport();
        },
    });
    return (
        <>
            <SecureSyncModal
                open={open}
                title={`${t("common.success")}!`}
                footer={
                    <Box sx={styles.confirmationModalFooter}>
                        <Button variant="outlined" onClick={onClose}>
                            <Trans i18nKey={"buttonLabels.close"} />
                        </Button>
                        <Button
                            autoFocus
                            variant="contained"
                            onClick={printReport}
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        >
                            <Trans i18nKey={"buttonLabels.prepareReport"} />
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.successModalDesc}>
                    <Trans
                        i18nKey={"messages.printMessage"}
                        values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                    />
                </Box>
                <Box>
                    <Trans i18nKey={values} />
                </Box>
            </SecureSyncModal>
        </>
    );
};

export default MediaDestructionSuccessModal;
