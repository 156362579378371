const styles = {
    typographyHeader: {
        color: "im_grey.900",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.334",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    typographyError: {
        fontSize: "14px",
        paddingBottom: "20px",
    },
    formControlStyle: {
        "& .MuiFormControl-root": {
            paddingRight: "0px",
        },
    },
    primaryCheckBox: {
        color: "primary",
        "&.Mui-checked": {
            color: "primary",
        },
    },
    leftPaddingGroup: { paddingLeft: "9px" },
    flexBoxPaddingBottom: { display: "flex", paddingBottom: "20px" },
    boxPaddingBottom: {
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(4, 285px)",
    },

    toggleTypography: {
        fontSize: "12px",
        paddingLeft: "50px",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.4px",
    },
    authLevelHeaderTypography: { fontSize: "14px", paddingBottom: "20px" },
    authHelperIcon: {
        "& .MuiTooltip-tooltip": {
            boxShadow: "none",
        },
    },
    flexFormControl: { flex: 1 },
    actionSection: { paddingBottom: "20px", paddingTop: "20PX" },
    toggleButton: {
        paddingBottom: "20px",
    },
};

export default styles;
