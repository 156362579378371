import { Box, Button, Grid, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import styles from "./MediaDetails.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { formatDate } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";
import { mediaOptionsDCValue } from "src/constants/discrepancyConstants";
import {
    getDetectedInTranslation,
    getDiscrepancyTranslation,
    getTranslatedSuggestions,
} from "src/utils/researchHelper";
import IconRight from "src/components/Icons/IconRight/IconRight";
import { getContainerDiscrepancyDetails, getOmDiscrepancyDetails } from "src/services/researchService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getDiscrepancyDetailReportPayload } from "src/services/printServices";

const MediaDetails = ({ selectedRecord, onClose = () => {}, otherMediaDetails = [], type }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState({ ...selectedRecord });
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const [fetchRecords, setFetchRecords] = useState({
        generalDetails: [],
        discrepancyDetails: [],
        customerComment: [],
        additional: [],
        uploadErrors: [],
    });

    useEffect(() => {
        const fetchSelectedData = async () => {
            setLoading(true);
            let response = {};
            if (type === mediaOptionsDCValue.openMedia) {
                response = await getOmDiscrepancyDetails({
                    omDiscrepancyId: record?.open_media_discrepancy_id,
                    districtId: record?.district_id,
                });
            } else {
                response = await getContainerDiscrepancyDetails({
                    discrepancyId: record?.discrepancy_id,
                    districtId: record?.district_id,
                });
            }

            if (!response?.failure) {
                setFetchRecords(response);
            }

            setLoading(false);
        };
        fetchSelectedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    const { selectedRecordIndex, nextBtnDisable, prevBtnDisable } = useMemo(() => {
        let nextBtnDisable = false,
            prevBtnDisable = false,
            selectedRecordIndex = null;
        if (type === mediaOptionsDCValue.openMedia) {
            selectedRecordIndex = otherMediaDetails.findIndex(
                (obj) => obj.open_media_discrepancy_id === record.open_media_discrepancy_id
            );
        } else {
            selectedRecordIndex = otherMediaDetails.findIndex((obj) => obj.discrepancy_id === record.discrepancy_id);
        }

        if (selectedRecordIndex === 0) {
            prevBtnDisable = true;
        }

        if (selectedRecordIndex === otherMediaDetails.length - 1) {
            nextBtnDisable = true;
        }

        return { selectedRecordIndex, nextBtnDisable, prevBtnDisable };
    }, [otherMediaDetails, record.discrepancy_id, record.open_media_discrepancy_id, type]);

    const selectPrevious = () => {
        const selectedObject = otherMediaDetails[selectedRecordIndex - 1];
        setRecord(selectedObject);
    };

    const selectNext = () => {
        const selectedObject = otherMediaDetails[selectedRecordIndex + 1];
        setRecord(selectedObject);
    };

    const { discrepancyType, detectedInVal, suggestionType, mediaType, mediaNo } = useMemo(() => {
        const suggestionType = record?.suggestion_type_code
            ? getTranslatedSuggestions(record?.suggestion_type_code?.trim())
            : " ";

        const mediaType = record?.media_type_id ? t(`mediaTypes.${record?.media_type_id}`) : " ";
        let discrepancyType = " ";
        let mediaNo = " ";
        let detectedInVal = " ";
        if (type === mediaOptionsDCValue.openMedia) {
            if (record?.discrepancy_type_code && record?.file_section_type_id) {
                discrepancyType = getDiscrepancyTranslation(
                    "openMedia",
                    record?.file_section_type_id,
                    record?.discrepancy_type_code.trim().replace(/\s+/g, "")
                );
            }
            if (record?.file_section_type_id) {
                detectedInVal = getDetectedInTranslation(record?.file_section_type_id, "fileSectionType");
            }
            mediaNo = record?.volser_number ? record?.volser_number : " ";
        } else {
            if (record?.discrepancy_type_code && record?.system_function_id) {
                discrepancyType = getDiscrepancyTranslation(
                    "container",
                    record?.system_function_id,
                    record?.discrepancy_type_code.trim().replace(/\s+/g, "")
                );
            }

            if (record?.system_function_id) {
                detectedInVal = getDetectedInTranslation(record?.system_function_id, "systemFunction");
            }
            mediaNo = record?.container_number ? record?.container_number.trim() + " (OM)" : " ";
        }

        return { discrepancyType, detectedInVal, suggestionType, mediaType, mediaNo };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        record?.discrepancy_type_code,
        record?.file_section_type_id,
        record?.media_type_id,
        record?.suggestion_type_code,
        record?.volser_number,
        record?.container_number,
        type,
    ]);

    const printReport = () => {
        setPrintPayload(
            getDiscrepancyDetailReportPayload({
                omDiscrepancyId: record.open_media_discrepancy_id,
                serviceDate: record?.service_date ? formatDate(record?.service_date) : " ",
                run: "",
                runStatus: "",
                pendingReturnDate: record?.pending_return_date ? formatDate(record?.pending_return_date) : " ",
                resolution: suggestionType,
                location: fetchRecords?.generalDetails[0]?.location ? fetchRecords?.generalDetails[0]?.location : " ",
                detectedIn: detectedInVal,
                cstomerMediaIdentifier: "",
                mediaType: mediaType,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <CircularLoaderFullPage show={loading} />
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <PageTitle>{t("titles.discrepancyDetail")}</PageTitle>
                    <Grid container justifyContent="left" sx={styles.gridContainer}>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={record?.customer_number ? record?.customer_number : " "}
                                label={t("formFields.customer")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={
                                    fetchRecords?.generalDetails[0]?.customer_name
                                        ? fetchRecords?.generalDetails[0]?.customer_name
                                        : record?.assigned_to_customer_name
                                }
                                label={t("formFields.name")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={mediaNo}
                                label={t("formFields.mediaNo")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={mediaType}
                                label={t("formFields.mediaType")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>

                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={record?.car_id ? record?.car_id : " "}
                                label={t("formFields.carId")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={discrepancyType}
                                label={t("formFields.discrepancyType")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={
                                    record?.detected_datetime
                                        ? formatDate(
                                              record?.detected_datetime,
                                              dateFormats.localized24HAndSecFormat,
                                              true
                                          )
                                        : " "
                                }
                                label={t("formFields.detectedDate")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={detectedInVal}
                                label={t("formFields.detectedIn")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        {type === mediaOptionsDCValue.openMedia && (
                            <>
                                <Grid item md={3} sx={styles.gridItem}>
                                    <SecureSyncReadOnlyTextField
                                        value={record?.logical_vault_code ? record?.logical_vault_code : " "}
                                        label={t("formFields.logicalVault")}
                                        isMultiline={true}
                                        shrink={true}
                                    />
                                </Grid>
                                <Grid item md={3} sx={styles.gridItem}>
                                    <SecureSyncReadOnlyTextField
                                        value={record?.request_id ? record?.request_id : " "}
                                        label={t("formFields.requestID")}
                                        isMultiline={true}
                                        shrink={true}
                                    />
                                </Grid>
                            </>
                        )}
                        {type === mediaOptionsDCValue.container && (
                            <>
                                <Grid item md={3} sx={styles.gridItem}>
                                    <SecureSyncReadOnlyTextField
                                        value={record?.service_date ? formatDate(record?.service_date) : " "}
                                        label={t("formFields.serviceDate")}
                                    />
                                </Grid>
                                <Grid item md={3} sx={styles.gridItem}>
                                    <SecureSyncReadOnlyTextField
                                        value={
                                            record?.pending_return_date ? formatDate(record?.pending_return_date) : " "
                                        }
                                        label={t("formFields.pendingReturnDate")}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Divider />
                    <Grid container justifyContent="left" sx={styles.gridContainer}>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={record?.resolved_datetime ? formatDate(record.resolved_datetime) : " "}
                                label={t("formFields.resolvedDate")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={record?.resolved_by_name ? record?.resolved_by_name : " "}
                                label={t("formFields.resolvedBy")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={
                                    fetchRecords?.generalDetails[0]?.authorized_by_name
                                        ? fetchRecords?.generalDetails[0]?.authorized_by_name
                                        : " "
                                }
                                label={t("formFields.authorizedBy")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item md={3} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={suggestionType}
                                label={t("formFields.resolution")}
                                isMultiline={true}
                                shrink={true}
                            />
                        </Grid>
                        {type === mediaOptionsDCValue.openMedia && (
                            <Grid item md={3} sx={styles.gridItem}>
                                <SecureSyncReadOnlyTextField
                                    value={
                                        fetchRecords?.generalDetails[0]?.location
                                            ? fetchRecords?.generalDetails[0]?.location
                                            : " "
                                    }
                                    label={t("formFields.location")}
                                    isMultiline={true}
                                    shrink={true}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Divider />
                    <Grid container justifyContent="left" sx={styles.gridContainer}>
                        <Grid item md={12} sx={styles.gridItem}>
                            <SecureSyncReadOnlyTextField
                                value={
                                    fetchRecords?.customerComment.length > 0
                                        ? fetchRecords?.customerComment.map((value) => value).join(", ")
                                        : " "
                                }
                                label={t("formFields.customerComments")}
                                isMultiline
                                variant="outlined"
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={styles.buttonContainer}>
                        <Button variant="outlined" color="primary" startIcon={<IconLeft />} onClick={onClose}>
                            {t("buttonLabels.back")}
                        </Button>
                        <Button variant="contained" onClick={printReport}>
                            {t("buttonLabels.print")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<IconLeft />}
                            disabled={prevBtnDisable}
                            onClick={selectPrevious}
                        >
                            {t("buttonLabels.previous")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<IconRight />}
                            disabled={nextBtnDisable}
                            onClick={selectNext}
                        >
                            {t("buttonLabels.next")}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.discrepancyDetailReport}
            />
        </>
    );
};

export default MediaDetails;
