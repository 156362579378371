import commonStyles from "../NewContainerLockInsert.styles";

const styles = {
    ...commonStyles,
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    extraInsertsWrapper: {
        ...commonStyles.fourColumnLayout,
        mb: "32px",
    },
    extraInsertsForm: {
        mb: "20px",
    },
    insertType: { gridColumn: "span 2" },
    buttonsWrapper: { display: "flex", columnGap: "20px" },
    toggleHelpText: { color: "im_red.500" },
};

export default styles;
