/* eslint-disable camelcase */
/**
 * 1. Check color available in Secure Sync
 * https://www.figma.com/design/slWuCLCv4DiSM8ShYRaL8j/SecureSync-UX-Design?node-id=37-4513&m=dev
 * 2. If it is not available check MUI color - https://mui.com/material-ui/customization/color/
 * 3. If it is not available in both at under `custom`
 */
const colorPalette = {
    primary: {
        main: "#1b75bc",
        main1: "#277FD8",
    },
    im_mid_blue: {
        500: "#1b75bc", // use primary.main
    },
    im_dark_blue: {
        500: "#14477d",
    },
    im_light_blue: {
        100: "#D2F0FC",
        500: "#6dcff6",
    },
    im_grey: {
        50: "#eeeeef",
        100: "#cbcccc",
        200: "#b2b3b4",
        300: "#8F9091",
        400: "#797a7c",
        500: "#58595b",
        900: "#252526",
    },
    background: {
        default: "#ffffff",
        custom: "#F0FAFE",
    },
    text: {
        primary: "#252526", //grey.900
        light: "#000000A8",
    },
    info: {
        main: "#1462AD",
    },
    im_red: {
        500: "#ED1C24",
        700: "#D32F2F",
    },
    im_orange: {
        500: "#F7931E",
    },
    im_custom: {},
    im_green: {
        500: "#8dc13f",
    },
};

export default colorPalette;
