export const documentOptions = [
    {
        label: "ssrsPrintService.pdfFormat",
        disable: false,
        value: "PDF",
    },
    {
        label: "ssrsPrintService.wordFormat",
        disable: true,
        value: "DOCS",
    },
    {
        label: "ssrsPrintService.excelFormat",
        disable: true,
        value: "EXCEL",
    },
    {
        label: "ssrsPrintService.csvFormat",
        disable: true,
        value: "CSV",
    },
];

export const PDF = "PDF";
export const DOCS = "DOCS";
export const EXCEL = "EXCEL";
export const CSV = "CSV";

export const targetUrl = {
    securityVerificationReport: "/ssprocessmedia/securityverificationreport",
    searchUserReport: "/ssauthorization/searchuserresult",
    omDistributionListReport: "/ssprocessmedia/getomdistributionlist",
    userVerificationReport: "/ssauthorization/userverificationreport",
    containerPickupManifestReport: "/ssprocessmedia/getcontainerpickupmanifest",
    scanVerificationFullReport: "/ssprocessmedia/getfullscanverificationreport",
    scanVerificationReport: "/ssprocessmedia/getscanverificationreport",
    closeContainer: "/ssprocessmedia/getclosedcontainercontents",
    customerAuth: "/ssauthorization/customerautherization",
    searchMediaDestructionRequest: "/ssrequestreport/searchdestructionrequests",
    searchRequestReport: "/ssrequestreport/searchrequestsresultsreport",
    newOrEmptyTransport: "/ssrequestreport/requestnewemptytransportreport",
    otherServicePrint: "/ssrequestreport/requestotherreport",
    deliverMedia: "/ssrequestreport/requestdeliverymediareport",
    searchEmployeeReport: "/ssrequestreport/employeesearchresultsreport",
    newLockContainer: "/ssrequestreport/requestnewcontainersreport",
    mediaDestructionRequest: "/ssrequestreport/requestdestructreport",
    deletecontainersreport: "/ssrequestreport/deletecontainersreport",
    carSearchRequest: "/ssrequestreport/correctiveactionrequestsearchreport",
    OpenMediaExceptionDetail: "/ssrequestreport/ommediaexceptiondetailsreport",
    employeeContactInformation: "/ssauthorization/employecontactinforeport",
    scanVerificationHistoryResult: "/ssrequestreport/scanverificationresultreport",
    inboundOutboundSummaryReport: "/ssrequestreport/inboundoutbountsummaryreport",
    openMediaSearchResults: "/ssrequestreport/openmediasearchresults",
    discrepancyDetailReport: "/ssrequestreport/discrepancydetailreport",
    carSearchResults: "/ssrequestreport/correctiveactionrequestdetail",
    vaultStatusReport: "/ssrequestreport/vaultstatusreport",
    libraryAuditResults: "/ssrequestreport/libraryauditresults",
    inboundOutboundDetailReport: "/ssrequestreport/inboundoutboundinventorydetailreport",
    searchContainerResultReport: "/ssrequestreport/containersearchresults",
    inboundOutboundSummaryImeReport: "/ssrequestreport/inboundoutboundinventorysummaryreport",
    globalMediaLocatorResultsReport: "/ssrequestreport/globalmedialocatorresultsreport",
    inboundOutboundDetailImeReport: "/ssrequestreport/inboundoutboundinventorydetailsreport",
    discrepancySearchReport: "/ssrequestreport/discrepancysearchreport",
};
