import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthState from "./useAuthState";
import { clearUserDetails } from "src/redux/userDetailsSlice";

const useAuthRedirect = () => {
    const { isLoaded, isAuthenticated } = useAuthState();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoaded && isAuthenticated === false) {
            dispatch(clearUserDetails());
            navigate("/");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoaded]);

    useEffect(() => {
        if (isLoaded && !isAuthenticated) {
            const currentUrl = encodeURI(location.pathname);
            return navigate(`/?redirectUrl=${currentUrl}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoaded, location.pathname]);
    return isAuthenticated;
};

export default useAuthRedirect;
