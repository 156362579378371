import commonStyles from "../NewContainerLockInsert.styles";

const styles = {
    ...commonStyles,
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    pageDesc: {
        fontWeight: 500,
        mb: "20px",
    },
    containerTypeSelect: { gridColumn: "span 2" },
    cmiWrapper: { display: "flex", alignItems: "center", mb: "20px" },
    insertConfigLabelWrapper: { display: "flex", alignItems: "center", mb: "20px" },
    containerNotListed: { fontWeight: 500, mb: "32px" },
    buttonWrapper: { display: "flex", columnGap: "20px", mb: "20px" },
    locksFieldWrapper: { display: "grid", gridTemplateColumns: "auto 1fr" },
    locksLabel: { display: "flex", alignItems: "center" },
    locksCaption: { gridColumnStart: 2 },
    containerForm: {
        ...commonStyles.fourColumnLayout,
        mb: "20px",
    },
    cmiField: {
        mb: "20px",
    },
    cmiTooltipDesc: { fontSize: "10px", fontWeight: 500 },
    cmiTooltipIcon: { py: "0px" },
    insertConfigWrapper: { mb: "20px" },
    tooltipTitle: { fontSize: "12px", fontWeight: 500 },
    tooltipDesc: { fontSize: "10px", fontWeight: 500 },
};

export default styles;
