import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useAuthState from "./useAuthState";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { fetchLanguageDetails, getSupportedLanguageDetails, setSelectedLanguageDetails } from "src/redux/languageSlice";
import { getFbToken } from "src/redux/userDetailsSlice";

const useLoadLanguages = () => {
    const { isAuthenticated } = useAuthState();
    const dispatch = useDispatch();
    const fbTokenSelector = useSelector(getFbToken);
    const { status } = useSelector(getSupportedLanguageDetails);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (isAuthenticated && fbTokenSelector && fbTokenSelector !== "") {
            dispatch(fetchLanguageDetails());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, fbTokenSelector]);

    useEffect(() => {
        if (status === fetchStatus.completed) {
            dispatch(setSelectedLanguageDetails());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, status]);

    return { isLoading: status === fetchStatus.loading };
};

export default useLoadLanguages;
