export const errorCodes = {
    Code0: "0",
    Code1: "1",
    Code63643: "63643",
    Code63645: "63645",
    Code10001: "10001",
    Code63009: "63009",
    Code63008: "63008",
    Code61014: "61014",
    Code25097: "25097",
    Code62527: "62527",
    Code10163: "10163",
    Code63857: "63857",
    Code63540: "63540",
    Code64030: "64030",
    Code63639: "63639",
    Code63752: "63752",
    Code63546: "63546",
    Code63551: "63551",
    Code63024: "63024",
    Code63046: "63046",
    Code63037: "63037",
};
