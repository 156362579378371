/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ListItemIcon, MenuItem, MenuList, Popper } from "@mui/material";
// import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProtectedMenu from "../../ProtectedMenu/ProtectedMenu";
import styles from "./AdministrationMenu.styles";
import { ReactComponent as ArrowDropDown } from "src/assets/images/ArrowDropDownFilled.svg";
import { ReactComponent as ArrowDown } from "src/assets/images/arrow-down.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import {
    addUser,
    comments,
    customerPreference,
    editMyPreference,
    getEditMyInfoPath,
    retentionProfile,
    searchUsers,
    userVerification,
} from "src/constants/routeConstants/administrationRouterFullPaths";
import { useAnchorAndNavigation } from "src/customHooks";
import { downloadFileFromFB } from "src/services/utils";
import { getAccessRights, getBasicUserInfo } from "src/redux/userDetailsSlice";
import { staticFile } from "src/constants/fileConstants";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { getCustomerAuthReportPayload, getSecurityVerificationReportPayload } from "src/services/printServices";
import { targetUrl } from "src/constants/printConstants";

function AdministrationMenu() {
    const [downloading, setDownLoading] = useState();
    const [printPayload, setPrintPayload] = useState(null);
    const [printUrl, setPrintUrl] = useState(null);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const { t } = useTranslation();
    const { data: accessRights } = useSelector(getAccessRights);
    // eslint-disable-next-line camelcase
    const { sys_user_id } = useSelector(getBasicUserInfo);
    const menuAccess = accessRights.menu;
    const pageAccess = accessRights.page;
    const {
        anchorEl: administrationMenu,
        isOpen: isAdministrationMenuOpen,
        menuRef: administrationMenuRef,
        openAnchor: openAdministrationMenu,
        closeAnchor: closeAdministrationMenu,
        handleBoxMouseLeave: handleAdministrationBoxMouseLeave,
        // eslint-disable-next-line no-unused-vars
        handleMenuMouseLeave: handleAdministrationMenuMouseLeave,
        // eslint-disable-next-line no-unused-vars
        handleMenuItemClick: handleAdministrationMenuItemClick,
    } = useAnchorAndNavigation();
    const {
        anchorEl: authorizationMenu,
        isOpen: isAuthorizationMenuOpen,
        menuRef: authorizationMenuRef,
        openAnchor: openAuthorizationMenu,
        closeAnchor: closeAuthorizationMenu,
        handleBoxMouseLeave: handleAuthorizationBoxMouseLeave,
        // eslint-disable-next-line no-unused-vars
        handleMenuMouseLeave: handleAuthorizationMenuMouseLeave,
        handleMenuItemClick: handleAuthorizationMenuItemClick,
    } = useAnchorAndNavigation();
    const {
        anchorEl: adminMenu,
        isOpen: isAdminMenuOpen,
        menuRef: adminMenuRef,
        openAnchor: openAdminMenu,
        closeAnchor: closeAdminMenu,
        handleBoxMouseLeave: handleAdminBoxMouseLeave,
        // eslint-disable-next-line no-unused-vars
        handleMenuMouseLeave: handleAdminMenuMouseLeave,
        handleMenuItemClick: handleAdminMenuItemClick,
    } = useAnchorAndNavigation();

    const onAuthorizationFormDownload = async () => {
        setDownLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const file = generateFilePathForCurrentLocale(bucketPath, staticFile["authorizationForms"]);
        await downloadFileFromFB({
            fbFilePath: file,
            fileName: `${t("authorization.customerAuthorizationForm")}.pdf`,
            onComplete: () => setDownLoading(false),
        });
    };

    const printSecurityVerificationReport = async () => {
        setPrintUrl(targetUrl.securityVerificationReport);
        setPrintPayload(getSecurityVerificationReportPayload());
        printModalOpen(true);
    };

    const printCustomerAuthReport = async () => {
        setPrintUrl(targetUrl.customerAuth);
        setPrintPayload(getCustomerAuthReportPayload());
        printModalOpen(true);
    };

    return (
        <div>
            <CircularLoaderFullPage show={downloading} />
            <Box
                onMouseEnter={openAdministrationMenu}
                onMouseLeave={handleAdministrationBoxMouseLeave}
                sx={styles.relativeDisplay}
            >
                <Button endIcon={<ArrowDown />} sx={styles.button.headerButton}>
                    {t("headerMenu.administration")}
                </Button>
                <Popper
                    open={isAdministrationMenuOpen}
                    anchorEl={administrationMenu}
                    placement="bottom-start"
                    className="menu-wrapper"
                    disablePortal={false}
                >
                    <MenuList
                        ref={administrationMenuRef}
                        onMouseLeave={closeAdministrationMenu}
                        style={styles.menuList(isAdministrationMenuOpen)}
                    >
                        <Box
                            onMouseEnter={openAuthorizationMenu}
                            onMouseLeave={handleAuthorizationBoxMouseLeave}
                            sx={{ position: "relative" }}
                        >
                            <ProtectedMenu hasAccess={menuAccess.authorization}>
                                <MenuItem sx={styles.arrowDropDownIcon}>
                                    <span style={styles.headerText}>{t("headerMenu.authorization")}</span>
                                    <ListItemIcon>
                                        <ArrowDropDown />
                                    </ListItemIcon>
                                </MenuItem>
                                <Popper
                                    sx={{ zIndex: "appBar" }}
                                    open={isAuthorizationMenuOpen}
                                    anchorEl={authorizationMenu}
                                    placement="right-start"
                                    className="menu-wrapper"
                                    disablePortal={false}
                                >
                                    <MenuList
                                        ref={authorizationMenuRef}
                                        onMouseLeave={closeAuthorizationMenu}
                                        style={styles.menuList(isAdministrationMenuOpen)}
                                    >
                                        <ProtectedMenu hasAccess={pageAccess.searchUsers}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAuthorizationMenuItemClick(searchUsers);
                                                }}
                                            >
                                                {t("headerMenu.searchUsers")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.addUsers}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAuthorizationMenuItemClick(addUser);
                                                }}
                                            >
                                                {t("headerMenu.addUsers")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.comments}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAuthorizationMenuItemClick(comments);
                                                }}
                                            >
                                                {t("headerMenu.comments")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.authorizationReport}>
                                            <MenuItem
                                                onClick={async () => {
                                                    await printCustomerAuthReport();
                                                    closeAdministrationMenu();
                                                }}
                                            >
                                                {t("headerMenu.authorizationReport")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.authorizationForm}>
                                            <MenuItem
                                                onClick={async () => {
                                                    await onAuthorizationFormDownload();
                                                    closeAdministrationMenu();
                                                }}
                                            >
                                                {t("headerMenu.authorizationForm")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.userVerification}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAuthorizationMenuItemClick(userVerification);
                                                }}
                                            >
                                                {t("headerMenu.userVerification")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.securityVerificationReport}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    printSecurityVerificationReport();
                                                }}
                                            >
                                                {t("headerMenu.securityVerificationReport")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                    </MenuList>
                                </Popper>
                            </ProtectedMenu>
                        </Box>
                        <Box
                            onMouseEnter={openAdminMenu}
                            onMouseLeave={handleAdminBoxMouseLeave}
                            sx={{ position: "relative" }}
                        >
                            <ProtectedMenu hasAccess={menuAccess.admin}>
                                <MenuItem sx={styles.arrowDropDownIcon}>
                                    <span style={styles.headerText}>{t("headerMenu.admin")}</span>
                                    <ListItemIcon>
                                        <ArrowDropDown />
                                    </ListItemIcon>
                                </MenuItem>
                                <Popper
                                    sx={{ zIndex: "appBar" }}
                                    open={isAdminMenuOpen}
                                    anchorEl={adminMenu}
                                    placement="right-start"
                                    className="menu-wrapper"
                                    disablePortal={false}
                                >
                                    <MenuList
                                        ref={adminMenuRef}
                                        onMouseLeave={closeAdminMenu}
                                        style={styles.menuList(isAdministrationMenuOpen)}
                                    >
                                        <ProtectedMenu hasAccess={pageAccess.editMyInfoUserDetail}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAdminMenuItemClick(getEditMyInfoPath(sys_user_id));
                                                }}
                                            >
                                                {t("headerMenu.editMyInfo")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.editMyPreference}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAdminMenuItemClick(editMyPreference);
                                                }}
                                            >
                                                {t("headerMenu.editMyPreferences")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.customerPreference}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAdminMenuItemClick(customerPreference);
                                                }}
                                            >
                                                {t("headerMenu.customerPreferences")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.retentionProfiles}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeAdministrationMenu();
                                                    handleAdminMenuItemClick(retentionProfile);
                                                }}
                                            >
                                                {t("headerMenu.retentionProfiles")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                    </MenuList>
                                </Popper>
                            </ProtectedMenu>
                        </Box>
                    </MenuList>
                </Popper>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={printUrl}
            />
        </div>
    );
}

export default AdministrationMenu;
