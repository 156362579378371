const styles = {
    textContent: {
        fontWeight: "bold",
    },
    editPreferenceTitle: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%",
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    editPreferenceBody1: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "14px",
    },
    dividerSpacing: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    switchDescription: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    contactFieldContainer: {
        paddingBottom: "20px",
    },
    contactFieldContainerTop: {
        paddingTop: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    formControl: {
        width: "640px",
    },
    paddingLeft: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        marginLeft: "47px",
    },
    singleColumnContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "20px",
    },
    noteDescription: {
        fontSize: "12px",
        color: "#797A7C",
    },
    link: {
        paddingTop: "17px",
        paddingLeft: "56px",
        marginLeft: "20px",
        color: "primary.main",
        textDecorationColor: "info.main",
        textDecorationLine: "underline",
    },
    link2: {
        marginTop: "-30px",
        textAlign: "center",
        color: "primary.main",
        textDecorationColor: "info.main",
        textDecorationLine: "underline",
        cursor: "pointer",
    },
    verificationField: {
        flex: "1",
        marginBottom: "20px",
        width: "22%",
    },
    customerDisplay: {
        marginLeft: "-25px",
    },
    customerNameDisplayField: {
        width: "89%",
    },
    labelContainer: {
        paddingTop: "5px",
    },
    radioContainer: {
        backgroundColor: "background.custom",
        paddingLeft: "20px",
        marginBottom: "20px",
    },
    buttonContainer2: {
        display: "flex",
        gap: "10px",
        paddingBottom: "8px",
        paddingTop: "8px",
        width: "100%",
        justifyContent: "left",
        marginLeft: "10px",
    },
    textDescription: {
        height: "auto",
        maxHeight: "150px",
        overflow: "auto",
        marginBottom: "20px",
    },
    mediaRequestHeader: {
        fontSize: "12px",
        fontWeight: 400,
        letterSpacing: "0.4px",
        marginBottom: "7px",
        opacity: "0.6",
    },
};

export default styles;
