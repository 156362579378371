import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuthState from "./useAuthState";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { fetchSecurityQuestions, getChallengeQuestions } from "src/redux/userDetailsSlice";

const useFetchSecurityQuestions = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuthState();
    const { status, data } = useSelector(getChallengeQuestions);
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchSecurityQuestions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    return { isLoading: status === fetchStatus.loading, data };
};

export default useFetchSecurityQuestions;
