import { t } from "i18next";
import { staticFile } from "src/constants/fileConstants";

export const getPrimaryLinks = () => {
    return [
        {
            name: t("resources.secureSyncUserGuide"),
            fbFileName: staticFile.dataProtectionCustomerHandbook,
        },
        {
            name: t("homeModule.WhatIsNewInSecuresync"),
            fbFileName: staticFile.whatIsNewSecureSyncFile,
        },
        {
            name: t("resources.glossary"),
            fbFileName: staticFile.secureSyncGlossary,
        },
    ];
};
