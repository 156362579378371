const styles = {
    typographyBody: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20.02px",
        letterSpacing: "0.17px",
        whiteSpace: "pre-wrap",
    },
    typographyHeader: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "16px",
    },
    boxPaddingBottom: {
        paddingBottom: "10px",
        display: "grid",
        gridTemplateColumns: "640px 211px 79px 310px",
    },
    formControlStyle: {
        "& .MuiFormControl-root": {
            paddingRight: "0px",
        },
    },
};

export default styles;
