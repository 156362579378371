import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Divider } from "@mui/material";
import SuccessModal from "../SuccessModal/SuccessModal";
import OtherServicesActionSection from "../OtherServicesActionSection/OtherServicesActionSection";
import DeliveryInformationSection from "../DeliveryInformationSection/DeliveryInformationSection";
import DescriptionSection from "../DescriptionSection/DescriptionSection";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { otherServicesWrapper } from "src/services/requestServices";
import { formatDate, getDisplayFormat, getMinAndMaxDate } from "src/utils/dateHelper";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import { calculateAddOnCutOffFlag } from "src/utils/newContainerLockInsertHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { getSelectedCustomerId } from "src/services/utils";
import { getAddOnCutOff } from "src/utils/requestHelper";
import { targetUrl } from "src/constants/printConstants";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { getOtherServicePayload } from "src/services/printServices";

const validationSchema = (t) => {
    const { maxDateForApi: maxDate, displayMaxDate } = getMinAndMaxDate();
    const toDay = dayjs().subtract(1, dateTimeUnits.days).format(dateFormats.requestServiceDateFormat);
    return Yup.object().shape({
        serviceLocation: Yup.string().required(t("otherRequest.serviceTypeValidation")),
        description: Yup.string().required(t("otherRequest.validationDescriptionRequired")),
        serviceDate: Yup.object()
            .required(t("messages.serviceDateRequired"))
            .test("serviceDate", (value, { createError, path }) => {
                const isValidDate = dayjs(value).isValid();
                const formattedValues = value ? formatDate(value, dateFormats.requestServiceDateFormat) : "";
                const isOutSideMaxDate = dayjs(formattedValues).isAfter(maxDate);
                let message = "";
                if (!isValidDate) {
                    message = t("otherRequest.validationServiceDateRequired");
                } else if (isOutSideMaxDate) {
                    message = t("otherRequest.validationDateLimit", {
                        min: formatDate(toDay, getDisplayFormat()),
                        max: displayMaxDate,
                    });
                }
                if (message) {
                    return createError({
                        path,
                        message,
                    });
                } else {
                    return true;
                }
            }),
    });
};

const formIntValues = {
    serviceLocation: "",
    description: "",
    serviceDate: "",
};

const OtherServicesForm = ({ initValues, serviceFrequencyValues }) => {
    const printUrl = targetUrl.otherServicePrint;
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const [open, setOpen] = useState(false);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [requestData, setRequestData] = useState({
        description: "",
        requestId: "",
        serviceDate: "",
        statusCode: "",
    });
    const [isTodayServiceDate, setIsTodayServiceDate] = useState(false);
    const serviceDateRef = useRef();
    const { t } = useTranslation();
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: formIntValues,
        validationSchema: validationSchema(t),
        onSubmit: async () => {
            await submitForm();
        },
    });

    const serviceScheduleData = useMemo(async () => {
        setLoading(true);
        const { noOfServices, addOnCutoffFlag, previousDayFlag, noOfServicesTom, maxServiceDate } =
            await getAddOnCutOff();
        const cutOffFlag = calculateAddOnCutOffFlag({
            noOfServices,
            addOnCutoffFlag,
            maxServiceDate,
            noOfServicesTom,
            previousDayFlag,
        });
        setLoading(false);
        return {
            noOfServices,
            cutOffFlag,
            previousDayFlag,
            noOfServicesTom,
            maxServiceDate,
        };
    }, []);

    const serviceDateHandle = async (value) => {
        if (!isTodayServiceDate && dayjs(value).isSame(dayjs(), dateTimeUnits.day)) {
            setErrorOpen(true);
            setErrorMsg(t("messages.requestCantMakeToday"));
            setFieldValue("serviceDate", "");
        } else {
            if (dayjs(value).isValid()) {
                setFieldValue("serviceDate", value);
            }
        }
    };

    useEffect(() => {
        setFieldTouched("serviceDate", true, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.serviceDate]);

    const submitForm = async () => {
        try {
            setErrorOpen(false);
            setErrorMsg("");
            const res = await otherServicesWrapper(
                values.serviceLocation,
                values.serviceDate,
                values.description,
                serviceScheduleData
            );
            if (!res.success) {
                setErrorOpen(true);
                setErrorMsg(res.message);
            } else {
                const data = res.data;
                setRequestData({
                    description: data.descr,
                    requestId: data.request_id,
                    serviceDate: data.service_date, // do not need to localize here already localize in sp's
                    statusCode: data.status_code,
                });
                setOpen(true);
            }
        } catch (error) {
            setErrorOpen(true);
            setErrorMsg(error.message);
        }
    };

    const getServiceDates = async () => {
        if (serviceDateRef.current) {
            serviceDateRef.current.refreshServiceDates();
        }
    };

    useEffect(() => {
        if (initValues && initValues.length > 0) {
            setInitialValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValues, serviceFrequencyValues]);

    const setInitialValues = () => {
        let location = initValues[0] ? initValues[0].value : "";
        const selectedCustomer = getSelectedCustomerId();
        const selectedCustomerLocation = initValues.find((loc) => loc.value === selectedCustomer);
        if (selectedCustomerLocation && !serviceFrequencyValues.customerHasSchedule) {
            location = selectedCustomerLocation.value;
        }
        setFieldValue("description", "");
        setFieldValue("serviceLocation", location);
        getServiceDates();
    };

    const closeSuccessModal = async () => {
        setOpen(false);
        setRequestData({
            description: "",
            requestId: "",
            serviceDate: "",
            statusCode: "",
        });
        await resetForm();
        setInitialValues();
    };

    const printReport = () => {
        setPrintPayload(getOtherServicePayload(requestData.requestId));
        printModalOpen(true);
    };

    const handleReset = () => {
        resetForm();
        setInitialValues();
    };

    return (
        <>
            <CircularLoaderFullPage show={isSubmitting || isLoading} />
            {errorOpen && (
                <SecureSyncAlert
                    message={errorMsg}
                    severity="error"
                    onClose={() => {
                        setErrorOpen(false);
                        setErrorMsg("");
                    }}
                />
            )}
            <form noValidate onSubmit={handleSubmit}>
                <Divider />
                <DeliveryInformationSection
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    serviceDateRef={serviceDateRef}
                    touched={touched}
                    errors={errors}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    serviceLocations={initValues}
                    serviceFrequencyValues={serviceFrequencyValues}
                    serviceDateHandle={serviceDateHandle}
                    getTodayServiceDate={(data) => setIsTodayServiceDate(data)}
                />
                <DescriptionSection
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                />
                <Divider />
                <OtherServicesActionSection resetForm={handleReset} handleSubmit={handleSubmit} />
                <SuccessModal
                    printReport={printReport}
                    open={open}
                    onClose={closeSuccessModal}
                    description={requestData.description}
                    requestId={requestData.requestId}
                    serviceDate={requestData.serviceDate}
                    statusCode={requestData.statusCode}
                />
                <SecureSyncPrintModal
                    open={isPrintModalOpen}
                    payload={printPayload}
                    onClose={() => printModalOpen(false)}
                    targetReport={printUrl}
                />
            </form>
        </>
    );
};

export default OtherServicesForm;
