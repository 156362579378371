const styles = {
    toggleTypography: {
        fontSize: "12px",
        paddingLeft: "50px",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.4px",
        color: "im_grey.900",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "32px",
        paddingBottom: "20px",
    },
    bottomPadding: {
        paddingBottom: "20px",
    },
    gridDisplay: {
        display: "grid",
        gridTemplateColumns: "repeat(4,340px)",
        gap: "20px",
        mb: "20px",
    },
};

export default styles;
