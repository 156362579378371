import { Box, Button, Typography, SvgIcon } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddRetentionProfiles from "../AddRetentionProfiles/AddRetentionProfiles";
import styles from "./RetentionProfiles.styles";
import { programIds } from "src/constants/programIdsConstants";
import { listRetentionProfiles } from "src/services/adminService";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import Pencil from "src/components/Icons/Pencil/Pencil";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { useSecureSyncHotKeys } from "src/customHooks";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const RetentionProfiles = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.add,
        callback: () => {
            if (hasWriteAccess) {
                setModalOpen(true);
            }
        },
    });
    const { t } = useTranslation();
    const userLevelID = useSelector(getProgramIdsOfCurrentCustomer);
    const hasWriteAccess = isUserProgramLevelAvailable(userLevelID, programIds.programId_270) ? true : false;
    const message = hasWriteAccess ? (
        <Typography>
            {t("retentionProfile.retentionProfilesNotDefined")}{" "}
            <Trans i18nKey={"retentionProfile.clickToCreateProfile"} />
        </Typography>
    ) : (
        <Typography>{t("retentionProfile.retentionProfilesNotDefined")}</Typography>
    );
    const [retentionProfile, setRetentionProfile] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRetentionProfile, setSelectedRetentionProfile] = useState({});

    const [response, setResponse] = useState({ responseMessage: "" });
    const [snackbarOpen, setSnackbarOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ssAlert, setSsAlert] = useState(true);

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const onClose = () => {
        setSelectedRetentionProfile({});
        setModalOpen(false);
    };
    const getProfiles = async () => {
        setIsLoading(true);
        const retentionProfileList = await listRetentionProfiles(
            t("OMDestribution.Indefinite"),
            t("OMDestribution.0Days"),
            ""
        );
        const sortedProfileList = retentionProfileList?.rs0.sort((a, b) =>
            a.profile_name.localeCompare(b.profile_name)
        );
        setRetentionProfile(sortedProfileList || []);
        setSsAlert(!retentionProfileList?.rs0?.length);
        setIsLoading(false);
    };
    useEffect(() => {
        getProfiles();
        handleOpenSnackbar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);
    const columns = useMemo(() => {
        const baseColumns = [
            hasWriteAccess && {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <SvgIcon
                        component={Pencil}
                        sx={styles.iconColor}
                        onClick={() => {
                            setSelectedRetentionProfile(params);
                            setModalOpen(true);
                        }}
                    />
                ),
            },
            { field: "profile_name", headerName: t("retentionProfile.profileName"), sortable: true },
            {
                field: "media_program_type",
                headerName: convertToLocaleUpperCase(t("retentionProfile.programType")),
                sortable: true,
            },
            {
                field: "default_om_dist_list_flag",
                headerName: t("retentionProfile.default"),
                renderCell: (params) => <>{params.default_om_dist_list_flag === "Y" ? `Yes` : `No`}</>,
                sortable: true,
            },
            { field: "descr", headerName: t("retentionProfile.profileDescription"), sortable: true },
            {
                field: "retention_days",
                headerName: convertToLocaleUpperCase(t("retentionProfile.retention")),
                renderCell: (params) => (
                    <>
                        {params.retention_days === ""
                            ? `${t("retentionProfile.indefinite")}`
                            : `${params.retention_days} ${t("retentionProfile.days")}`}
                    </>
                ),
                sortable: true,
            },
        ].filter(Boolean);
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasWriteAccess]);
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                        <PageTitle>{t("retentionProfile.retentionProfiles")}</PageTitle>
                        {response.responseMessage !== "" && (
                            <SecureSyncSnackBar
                                openSnackBar={snackbarOpen}
                                message={response.responseMessage}
                                severity={"success"}
                                onClose={handleCloseSnackbar}
                            />
                        )}
                    </Box>
                    <Box sx={styles.mbBr}>
                        {retentionProfile.length === 0 && ssAlert && (
                            <SecureSyncAlert message={message} severity="info" onClose={() => setSsAlert(false)} />
                        )}
                    </Box>
                    <SecureSyncTableCard>
                        <Box sx={styles.recordCount}>
                            {hasWriteAccess && (
                                <>
                                    <Box sx={styles.recordStart}>
                                        <Typography variant="body1">{`${retentionProfile.length} ${t("retentionProfile.recordFound")}`}</Typography>
                                    </Box>
                                    <Box sx={styles.recordEnd}>
                                        <Button
                                            title={getKeyBoardLabel(shortCutKeys.add)}
                                            variant="outlined"
                                            onClick={() => setModalOpen(true)}
                                        >
                                            {t("retentionProfile.addNewProfile")}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box sx={styles.cardContent}>
                            <SecureSyncTable
                                rows={retentionProfile}
                                columns={columns}
                                getRowId={(row) => Number(row.retention_profile_id)}
                                noDataMessage={message}
                            />
                        </Box>
                    </SecureSyncTableCard>
                </Box>
            </Box>
            <AddRetentionProfiles
                open={modalOpen}
                onClose={onClose}
                params={selectedRetentionProfile}
                setResponse={setResponse}
            />
        </>
    );
};

export default RetentionProfiles;
