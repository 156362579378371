const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    formControlSection: {
        mb: 2,
        width: "100%",
    },
    checkBox: {
        color: "primary",
        "&.Mui-checked": {
            color: "primary",
        },
    },
};

export default styles;
