import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./PostFormSubmit.styles";
import { serviceType } from "src/constants/miscConstants";

const serviceHeaderMapping = {
    [serviceType.libraryAudit]: "miscService.libraryAuditSuccess",
    [serviceType.newCustomer]: "miscService.newCustomerNote",
    [serviceType.authCardReplacement]: "miscService.authReplaceNote",
    [serviceType.bidService]: "miscService.bidSuccess",
};

const staticLabels = (values, t) => [
    `${t("miscService.customerLabel")}: ${values.customerId}`,
    `${t("requestDetails.requestedBy")}: ${values.modifiedUserName}`,
    `${t("formFields.requestorEmailLabel")}: ${values.requestorEmail}`,
    `${t("miscService.requestorPhoneNumber")}: ${values.requestorPhoneNumber}  ${t("miscService.labelExtension")}: ${values.ext}`,
    `${t("formFields.requestedDateTime")}: ${values.now}`,
];

const dynamicLabels = (values, t) => [
    {
        item: `${t("containerPickup.description")}: ${values.description}`,
        services: [serviceType.bidService],
    },
    {
        item: `${t("formFields.newCustomerName")}: ${values.newCustomerName}`,
        services: [serviceType.newCustomer],
    },

    {
        item: `${t("formFields.labelSubCustomer")}: ${values.newCustomerIsSubAccount}`,
        services: [serviceType.newCustomer],
    },
    {
        item: `${t("formFields.parentAccountNumber")}: ${values.parentAccountNumber}`,
        services: [serviceType.newCustomer],
    },
    {
        item: `${t("formFields.requestedCompletionDate")}: ${values.requestedCompletionDate}`,
        services: [serviceType.libraryAudit, serviceType.newCustomer],
    },
    {
        item: `${t("formFields.dataCenterContactName")}: ${values.dataCenterContactName}`,
        services: [serviceType.newCustomer],
    },
    {
        item: `${t("formFields.dataCenterContactPhone")}: ${values.dataCenterContactPhone}  ${t("miscService.labelExtension")}: ${values.dataCenterExt}`,
        services: [serviceType.newCustomer],
    },
    {
        item: `${t("headerMenu.comments")}: ${values.comments}`,
        services: [serviceType.libraryAudit, serviceType.newCustomer],
    },
];

const PostFormSubmit = ({ values }) => {
    const { t } = useTranslation();
    return (
        <>
            <Typography variant="body1" sx={styles.typographyHeader}>
                <Trans i18nKey="userDetails.userDeletePopupTitle" /> <br />
                <Trans i18nKey={serviceHeaderMapping[values.serviceLocation]} />
            </Typography>
            {values.serviceLocation !== serviceType.authCardReplacement && (
                <>
                    {staticLabels(values, t).map((item) => (
                        <>
                            <Typography variant="body1" sx={styles.typographyBody}>
                                {item}
                            </Typography>
                        </>
                    ))}
                </>
            )}
            {dynamicLabels(values, t)
                .filter((e) => e.services.includes(values.serviceLocation))
                .map((item) => (
                    <>
                        <Typography variant="body1" sx={styles.typographyBody}>
                            {item.item}
                        </Typography>
                    </>
                ))}
        </>
    );
};
export default PostFormSubmit;
