import { Box, ListItemIcon, MenuItem, MenuList, Popper } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProtectedMenu from "../../ProtectedMenu/ProtectedMenu";
import styles from "./OperationsMenu.styles";
import { ReactComponent as ArrowDropDown } from "src/assets/images/ArrowDropDownFilled.svg";
import { ReactComponent as ArrowDown } from "src/assets/images/arrow-down.svg";
import {
    completedLibraryAudit,
    correctiveActionRequest,
    discrepancyManagement,
    drDocument,
    globalMediaLocator,
    inboundOutboundReports,
    scanVerificationHistory,
    searchContainer,
    searchEmployees,
    searchOpenMedia,
    vaultStatusReport,
} from "src/constants/routeConstants/operationsRouterFullPaths";
import { useAnchorAndNavigation } from "src/customHooks";
import { getAccessRights } from "src/redux/userDetailsSlice";

function OperationsMenu() {
    const { t } = useTranslation();
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    const menuAccess = accessRights.menu;
    const {
        anchorEl: operationMenu,
        isOpen: isOperationMenuOpen,
        menuRef: operationMenuRef,
        openAnchor: openOperationMenu,
        closeAnchor: closeOperationMenu,
        handleBoxMouseLeave: handleOperationBoxMouseLeave,
    } = useAnchorAndNavigation();
    const {
        anchorEl: disasterMenu,
        isOpen: isDisasterMenuOpen,
        menuRef: disasterMenuRef,
        openAnchor: openDisasterMenu,
        closeAnchor: closeDisasterMenu,
        handleBoxMouseLeave: handleDisasterBoxMouseLeave,
        handleMenuItemClick: handleDisasterMenuItemClick,
    } = useAnchorAndNavigation();
    const {
        anchorEl: researchMenu,
        isOpen: isResearchMenuOpen,
        menuRef: researchMenuRef,
        openAnchor: openResearchMenu,
        closeAnchor: closeResearchMenu,
        handleBoxMouseLeave: handleResearchBoxMouseLeave,
        handleMenuItemClick: handleResearchMenuItemClick,
    } = useAnchorAndNavigation();
    return (
        <div>
            <Box onMouseEnter={openOperationMenu} onMouseLeave={handleOperationBoxMouseLeave}>
                <Button color="inherit" endIcon={<ArrowDown />} sx={styles.button.headerButton}>
                    {t("headerMenu.operations")}
                </Button>
                <Popper
                    open={isOperationMenuOpen}
                    anchorEl={operationMenu}
                    placement="bottom-start"
                    className="menu-wrapper"
                    disablePortal={false}
                >
                    <MenuList
                        ref={operationMenuRef}
                        onMouseLeave={closeOperationMenu}
                        style={styles.menuList(isOperationMenuOpen)}
                    >
                        <Box
                            onMouseEnter={openResearchMenu}
                            onMouseLeave={handleResearchBoxMouseLeave}
                            sx={styles.relativeDisplay}
                        >
                            <ProtectedMenu hasAccess={menuAccess.research}>
                                <MenuItem sx={styles.arrowDropDownIcon}>
                                    <span style={styles.headerText}>{t("headerMenu.research")}</span>
                                    <ListItemIcon>
                                        <ArrowDropDown />
                                    </ListItemIcon>
                                </MenuItem>
                                <Popper
                                    sx={{ zIndex: "appBar" }}
                                    open={isResearchMenuOpen}
                                    anchorEl={researchMenu}
                                    placement="right-start"
                                    className="menu-wrapper"
                                    disablePortal={false}
                                >
                                    <MenuList
                                        ref={researchMenuRef}
                                        onMouseLeave={closeResearchMenu}
                                        style={styles.menuList(isOperationMenuOpen)}
                                    >
                                        <ProtectedMenu hasAccess={pageAccess.completedLibraryAudits}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(completedLibraryAudit);
                                                }}
                                            >
                                                {t("headerMenu.completedLibraryAudits")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.correctiveActionRequest}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(correctiveActionRequest);
                                                }}
                                            >
                                                {t("headerMenu.correctiveActionRequest")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.searchContainer}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(searchContainer);
                                                }}
                                            >
                                                {t("headerMenu.searchContainers")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.searchOpenMedia}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(searchOpenMedia);
                                                }}
                                            >
                                                {t("headerMenu.searchOpenMedia")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.discrepancyManagementSearch}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(discrepancyManagement);
                                                }}
                                            >
                                                {t("headerMenu.discrepancyManagement")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.valutStatusReports}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(vaultStatusReport);
                                                }}
                                            >
                                                {t("headerMenu.vaultStatusReports")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.globalMediaLocator}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(globalMediaLocator);
                                                }}
                                            >
                                                {t("headerMenu.globalMediaLocator")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.scanVerificationHistory}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(scanVerificationHistory);
                                                }}
                                            >
                                                {t("headerMenu.scanVerificationHistory")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.inboundOutboundReports}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeResearchMenu();
                                                    handleResearchMenuItemClick(inboundOutboundReports);
                                                }}
                                            >
                                                {t("headerMenu.inboundOutboundReport")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                    </MenuList>
                                </Popper>
                            </ProtectedMenu>
                        </Box>
                        <Box
                            onMouseEnter={openDisasterMenu}
                            onMouseLeave={handleDisasterBoxMouseLeave}
                            sx={styles.relativeDisplay}
                        >
                            <ProtectedMenu hasAccess={menuAccess.disasterRecovery}>
                                <MenuItem sx={styles.arrowDropDownIcon}>
                                    <span style={styles.headerText}>{t("headerMenu.disasterRecovery")}</span>
                                    <ListItemIcon>
                                        <ArrowDropDown />
                                    </ListItemIcon>
                                </MenuItem>
                                <Popper
                                    sx={{ zIndex: "appBar" }}
                                    open={isDisasterMenuOpen}
                                    anchorEl={disasterMenu}
                                    placement="right-start"
                                    className="menu-wrapper"
                                    disablePortal={false}
                                >
                                    <MenuList
                                        ref={disasterMenuRef}
                                        onMouseLeave={closeDisasterMenu}
                                        style={styles.menuList(isOperationMenuOpen)}
                                    >
                                        <ProtectedMenu hasAccess={pageAccess.searchEmployees}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeDisasterMenu();
                                                    handleDisasterMenuItemClick(searchEmployees);
                                                }}
                                            >
                                                {t("headerMenu.searchEmployees")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                        <ProtectedMenu hasAccess={pageAccess.dRDocuments}>
                                            <MenuItem
                                                onClick={() => {
                                                    closeOperationMenu();
                                                    closeDisasterMenu();
                                                    handleDisasterMenuItemClick(drDocument);
                                                }}
                                            >
                                                {t("headerMenu.dRDocuments")}
                                            </MenuItem>
                                        </ProtectedMenu>
                                    </MenuList>
                                </Popper>
                            </ProtectedMenu>
                        </Box>
                    </MenuList>
                </Popper>
            </Box>
        </div>
    );
}

export default OperationsMenu;
