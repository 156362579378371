import { useLocation, useNavigate } from "react-router-dom";
const useSecureSyncNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const doesAnyHistoryEntryExist = location.key !== "default";
    const goBack = (fallbackUrl) => {
        if (doesAnyHistoryEntryExist && !location.state?.navigatedViaFallback) {
            navigate(-1);
        } else {
            navigate(fallbackUrl, {
                state: {
                    navigatedViaFallback: true,
                },
            });
        }
    };
    return { navigate, goBack, isHistoryExist: doesAnyHistoryEntryExist };
};

export default useSecureSyncNavigate;
