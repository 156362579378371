import { Box, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./SuccessModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const SuccessModal = ({ open, onClose, description, requestId, serviceDate, statusCode, printReport }) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    return (
        <SecureSyncModal
            open={open}
            title={`${t("common.success")}!`}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        {t("buttonLabels.close")}
                    </Button>
                    <Button
                        autoFocus
                        variant="contained"
                        color="primary"
                        onClick={printReport}
                        title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                    >
                        {t("buttonLabels.prepareReport")}
                    </Button>
                </Box>
            }
        >
            <Box sx={styles.confirmationModalFooter}>
                <Typography>
                    <Trans
                        i18nKey={"otherRequest.printMessage"}
                        values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                    />
                </Typography>
            </Box>
            <Box sx={styles.confirmationModalFooter}>
                <Typography>
                    <Trans
                        i18nKey={"otherRequest.msgSuccess"}
                        values={{
                            description: description ? description : "",
                            requestId: requestId ? requestId : "",
                            serviceDate: serviceDate ? serviceDate : "",
                            statusCode: statusCode ? statusCode : "",
                        }}
                    />
                </Typography>
            </Box>
        </SecureSyncModal>
    );
};

export default SuccessModal;
