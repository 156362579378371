import { Box, Button, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SearchContainerFilters.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getAll } from "src/constants/researchConstants";
import { searchContainer } from "src/services/processMediaService";
import { getSearchContainerDropDown } from "src/services/researchService";
import { formatDate, getDisplayFormat, getMinAndMaxDate } from "src/utils/dateHelper";
import { dateTimeUnits } from "src/constants/dateFormatConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { searchContainer as searchContainerPath } from "src/constants/routeConstants/operationsRouterFullPaths";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t }) => {
    const { maxDateForApi: maxDate } = getMinAndMaxDate();
    const format = getDisplayFormat();
    return Yup.object().shape({
        returnDateFrom: Yup.string().test("returnDateFrom", (value, { createError, path, parent }) => {
            const toDate = parent.returnDateTo ? dayjs(parent.returnDateTo) : "";
            const fromDate = value ? dayjs(value) : "";
            const today = dayjs();
            let message = "";
            if (value) {
                const isOutSideMaxDate = dayjs(fromDate).isAfter(maxDate, dateTimeUnits.day);
                if (fromDate.isBefore(today, dateTimeUnits.day) || isOutSideMaxDate) {
                    message = t("messages.invalidDateRange", {
                        title: t("messages.fromReturn"),
                        minDate: formatDate(today, getDisplayFormat()),
                        maxDate: formatDate(maxDate, getDisplayFormat()),
                    });
                    return createError({
                        path,
                        message,
                    });
                }
            }
            if (toDate && fromDate) {
                const isValidDate = toDate.isValid() && fromDate.isValid();
                const isOutSideMaxDate = toDate.isBefore(fromDate);
                if (isValidDate && isOutSideMaxDate) {
                    message = t("containerSearch.dateValidationMsg", {
                        toDate: formatDate(parent.returnDateTo, format),
                    });
                    return createError({
                        path,
                        message,
                    });
                }
            }
            return true;
        }),
        returnDateTo: Yup.string().test("returnDateTo", (value, { createError, path, parent }) => {
            const toDate = value ? dayjs(value) : "";
            const today = dayjs();
            let message = "";
            if (value) {
                const isOutSideMaxDate = dayjs(toDate).isAfter(maxDate, dateTimeUnits.day);
                if (toDate.isBefore(today, dateTimeUnits.day) || isOutSideMaxDate) {
                    message = t("messages.invalidDateRange", {
                        title: t("messages.toReturn"),
                        minDate: formatDate(today, getDisplayFormat()),
                        maxDate: formatDate(maxDate, getDisplayFormat()),
                    });
                    return createError({
                        path,
                        message,
                    });
                }
            }
            return true;
        }),
    });
};

const initialValues = {
    containerNumber: "",
    customerMediaIdentifier: "",
    currentStatus: "",
    mediaType: "",
    description: "",
    returnDateFrom: "",
    returnDateTo: "",
};

const SearchContainerFilters = ({ onSearch, allowCustomerMediaIdentifiers, clearTableState, setSearchValues }, ref) => {
    const shortCutKeys = getShortCutKeys();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({
        show: false,
        msg: "",
    });
    const [mediaTypeOptions, setMediaTypeOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const { t } = useTranslation();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.clear, callback: () => resetFormValue() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.searchContainersFind,
        callback: () => handleSubmit(),
    });
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getDropDownValues();
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        callResetFunction: (keepState) => onFindClick(keepState, false, values),
    }));

    useEffect(() => {
        if (mediaTypeOptions.length > 0) {
            setFieldValue("mediaType", mediaTypeOptions[0].value ? mediaTypeOptions[0].value : "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaTypeOptions]);

    useEffect(() => {
        if (statusOptions.length > 0) {
            setFieldValue("currentStatus", statusOptions[1].value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusOptions]);

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState) {
                const newFormValues = {
                    containerNumber: locationState?.containerNumber || "",
                    customerMediaIdentifier: locationState?.customerMediaIdentifier || "",
                    currentStatus: locationState?.currentStatus || "",
                    mediaType: locationState?.mediaType || "",
                    description: locationState?.description || "",
                    returnDateFrom: locationState?.returnDateFrom || "",
                    returnDateTo: locationState?.returnDateTo || "",
                };
                await setValues(newFormValues);
                onFindClick(false, false, newFormValues);
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }

        return newNewPageState;
    };

    const onFindClick = async (keepState, isButtonClick, values) => {
        //To get all request we need to pass empty value.
        setLoading(true);
        if (!keepState) {
            clearTableState();
        }
        let payload = {
            ...values,
        };
        if (!values.returnDateFrom || !values.returnDateTo) {
            const bothDate = values.returnDateFrom || values.returnDateTo;
            payload = {
                ...payload,
                returnDateFrom: bothDate,
                returnDateTo: bothDate,
            };
        }
        setValues(payload);
        const { containers, failure } = await searchContainer(payload);
        const pageState = getPageState({ ...values }, !isButtonClick);
        navigate(searchContainerPath, {
            state: pageState,
            replace: true,
        });
        containers.sort((a, b) => (a.Number > b.Number ? 1 : b.Number > a.Number ? -1 : 0));
        onSearch({
            error: failure,
            containers: containers,
        });
        setLoading(false);
    };

    const resetFormValue = () => {
        resetForm();
        clearTableState();
        onSearch({ error: true, containers: [], clearState: true });
        if (mediaTypeOptions.length > 0) {
            setFieldValue("mediaType", mediaTypeOptions[0].value ? mediaTypeOptions[0].value : "");
        }
        if (statusOptions.length > 0) {
            setFieldValue("currentStatus", statusOptions[1].value);
        }
    };

    const getDropDownValues = async () => {
        setError({
            show: false,
            msg: "",
        });
        const res = await getSearchContainerDropDown();
        if (res.failure) {
            setError({
                show: true,
                msg: t(`apiErrorMessages.${res.error}`),
            });
        } else {
            setError({
                show: false,
                msg: "",
            });
            const { mediaTypes, statusList } = res;
            if (mediaTypes.length > 0) {
                const mediaTypesDropDownList = mediaTypes.map((e) => {
                    return {
                        id: parseInt(e.Order_Column),
                        label: e.Short_Descr ? e.Short_Descr : "",
                        value: e.Media_Type_Id ? e.Media_Type_Id : getAll,
                    };
                });
                setMediaTypeOptions(sortDropDownValues(mediaTypesDropDownList));
            }

            if (statusList.length > 0) {
                const statusDropDownList = statusList.map((e) => {
                    return {
                        id: parseInt(e.Order_Column),
                        label: e.Summary_Type ? e.Summary_Type : "",
                        value: e.Summary_Type,
                    };
                });
                setStatusOptions(sortDropDownValues(statusDropDownList));
            }
        }
    };

    const sortDropDownValues = (array) => {
        array.sort((a, b) => {
            const aOrder = a.id;
            const bOrder = b.id;
            const aLabel = a.label;
            const bLabel = b.label;
            if (aOrder === bOrder) {
                if (aLabel < bLabel) {
                    return -1;
                } else {
                    if (aLabel > bLabel) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            } else {
                if (aOrder < bOrder) {
                    return -1;
                } else {
                    return 1;
                }
            }
        });
        return array;
    };
    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
        setValues,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema({ t }),
        onSubmit: () => onFindClick(false, true, values),
    });

    useEffect(() => {
        setSearchValues(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <>
            {error.show && (
                <SecureSyncAlert
                    message={error.msg}
                    severity="error"
                    onClose={() => {
                        setError({
                            show: false,
                            msg: "",
                        });
                    }}
                />
            )}
            <CircularLoaderFullPage show={isSubmitting || isLoading} />
            <form onSubmit={handleSubmit}>
                <Box sx={styles.searchContainer}>
                    <Typography sx={styles.filterDesc}>
                        <Trans i18nKey={"containerSearch.formTitle"} values={{ buttonLabel: t("buttonLabels.find") }} />
                    </Typography>
                    <Box sx={styles.searchFields}>
                        <TextField
                            label={t("containerPickup.containerNumber")}
                            name="containerNumber"
                            value={values.containerNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.containerNumber && touched.containerNumber)}
                            helperText={touched.containerNumber && errors.containerNumber}
                        />
                        {allowCustomerMediaIdentifiers && (
                            <TextField
                                label={t("formFields.customerMediaIdentifier")}
                                name="customerMediaIdentifier"
                                value={values.customerMediaIdentifier}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.customerMediaIdentifier && touched.customerMediaIdentifier)}
                                helperText={touched.customerMediaIdentifier && errors.customerMediaIdentifier}
                            />
                        )}
                        <SecureSyncSelect
                            label={t("formFields.currentStatus")}
                            options={statusOptions}
                            name="currentStatus"
                            value={values.currentStatus}
                            onChange={handleChange}
                        />
                        <SecureSyncSelect
                            label={t("formFields.mediaType")}
                            options={mediaTypeOptions}
                            name="mediaType"
                            value={values.mediaType}
                            onChange={handleChange}
                        />

                        <Box sx={styles.description}>
                            <TextField
                                id="description"
                                name="description"
                                label={t("downloadInventory.descriptionPhrase")}
                                variant="outlined"
                                multiline
                                fullWidth
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 50 }}
                                error={touched.description && Boolean(errors.description)}
                                helperText={touched.description && errors.description}
                                InputProps={{
                                    sx: {
                                        overflow: "auto",
                                    },
                                }}
                                rows={1}
                            />
                        </Box>
                        <div />

                        <SecureSyncDatePicker
                            disablePast
                            value={values.returnDateFrom}
                            label={t("formFields.returnDateFrom")}
                            helperText={touched.returnDateFrom && errors.returnDateFrom}
                            error={Boolean(touched.returnDateFrom && errors.returnDateFrom)}
                            onChangeRaw={async (value) => {
                                const isValid = dayjs(value).isValid();
                                await setFieldValue("returnDateFrom", isValid ? value : "");
                                setFieldTouched("returnDateFrom", true, true);
                            }}
                        />
                        <SecureSyncDatePicker
                            disablePast
                            value={values.returnDateTo}
                            label={t("formFields.returnDateTo")}
                            helperText={touched.returnDateTo && errors.returnDateTo}
                            error={Boolean(touched.returnDateTo && errors.returnDateTo)}
                            onChangeRaw={async (value) => {
                                const isValid = dayjs(value).isValid();
                                await setFieldValue("returnDateTo", isValid ? value : "");
                                setFieldTouched("returnDateTo", true, true);
                            }}
                        />
                    </Box>

                    <Box sx={styles.searchButtons}>
                        <Button
                            variant="outlined"
                            type="reset"
                            onClick={resetFormValue}
                            title={getKeyBoardLabel(shortCutKeys.clear)}
                        >
                            {t("buttonLabels.clear")}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            title={getKeyBoardLabel(shortCutKeys.searchContainersFind)}
                        >
                            {t("buttonLabels.find")}
                        </Button>
                    </Box>
                </Box>
            </form>
        </>
    );
};
export default forwardRef(SearchContainerFilters);
