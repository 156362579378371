import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import useAuthState from "./useAuthState";
import { login } from "src/constants/routeConstants/rootRouterFullPaths";
import { getSelectedCustomer, getUserDetails, isUserPreferredLanguageSet } from "src/redux/userDetailsSlice";

const useShowMenuOptions = () => {
    const { isAuthenticated } = useAuthState();
    const user = useSelector(getUserDetails);
    const selectedUser = useSelector(getSelectedCustomer);
    const isLanguageSet = useSelector(isUserPreferredLanguageSet);
    const isPostLoginPage = useMatch(login);

    return isAuthenticated && user?.email && selectedUser && isLanguageSet && !isPostLoginPage;
};

export default useShowMenuOptions;
