import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultISOLocaleId } from "src/constants/dateFormatConstants";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { getLanguageDetails } from "src/services/commonServices";
import { getSelectedLanguage } from "src/utils/i18nHelper";

const initialState = {
    supportedLanguages: {
        status: fetchStatus.notStarted,
        data: [],
    },
    selectedLanguageDetails: {},
    dayJsLocaleCode: defaultISOLocaleId,
};

export const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setSelectedLanguageDetails: (state) => {
            const selectedLanguage = getSelectedLanguage();
            if (selectedLanguage) {
                const selectedLanguageDetails =
                    state.supportedLanguages.data.find((language) => language.iso_locale_code === selectedLanguage) ||
                    {};
                state.selectedLanguageDetails = selectedLanguageDetails;
            }
        },
        setDayJsLocaleIsoCode: (state, action) => {
            state.dayJsLocaleCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLanguageDetails.pending, (state) => {
            state.supportedLanguages.status = fetchStatus.loading;
        });
        builder.addCase(fetchLanguageDetails.fulfilled, (state, action) => {
            state.supportedLanguages.status = fetchStatus.completed;
            state.supportedLanguages.data = action.payload;
        });
    },
    selectors: {
        getSelectedLanguageDetails: (state) => state.selectedLanguageDetails,
        getSelectedLanguageLocaleId: (state) => state.selectedLanguageDetails.locale_id,
        getSupportedLanguageDetails: (state) => state.supportedLanguages,
        getIsoLocaleCode: (state) => state.selectedLanguageDetails.iso_locale_code,
        getDayJsLocaleCode: (state) => state.dayJsLocaleCode,
    },
});

export const fetchLanguageDetails = createAsyncThunk(
    "language/fetchLanguageDetails",
    async () => {
        const languageDetails = await getLanguageDetails();
        return languageDetails;
    },
    {
        condition: (_args, { getState }) => {
            const state = getState();
            return state.language.supportedLanguages.status === fetchStatus.notStarted;
        },
    }
);

export const {
    getSelectedLanguageDetails,
    getSupportedLanguageDetails,
    getSelectedLanguageLocaleId,
    getDayJsLocaleCode,
    getIsoLocaleCode,
} = languageSlice.selectors;

export const { setSelectedLanguageDetails, setDayJsLocaleIsoCode } = languageSlice.actions;

export default languageSlice.reducer;
