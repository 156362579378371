import { Box } from "@mui/material";
import OktaSignIn from "@okta/okta-signin-widget";
import { Fragment, useEffect, useRef } from "react";
// eslint-disable-next-line import/no-unresolved
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import styles from "./OktaSignInWidget.styles";
import { clearValuesOnSignIn } from "src/utils/loginHelper";

export const OktaSignInWidget = ({ config, onSuccess, onError }) => {
    const widgetRef = useRef();
    useEffect(() => {
        //This is to clear the storage if user closes tab without logout
        clearValuesOnSignIn();
    }, []);

    useEffect(() => {
        if (!widgetRef.current) {
            return false;
        }
        const widget = new OktaSignIn(config);
        widget
            .showSignInToGetTokens({
                el: widgetRef.current,
            })
            .then(onSuccess)
            .catch(onError);

        return () => widget.remove();
    }, [config, onSuccess, onError]);

    return (
        <Box sx={styles.oktaContainer} className="oktaContainer">
            <Fragment>
                <div ref={widgetRef} />
            </Fragment>
        </Box>
    );
};
