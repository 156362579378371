import { Box, Button, Typography, Divider, FormHelperText } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import styles from "./DRDocuments.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import { preferenceLevelTypeId } from "src/constants/preferenceServiceConstants";
import { getPreferences } from "src/services/adminService";
import {
    drDocumentEncryptService,
    getListCustomerDRFiles,
    getListDRInstructions,
    insertAndUpdateDRInstructions,
    insertDrInstructionDesignatedCustomer,
} from "src/services/disasterService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import DRFileTable from "src/components/Operations/DisasterRecovery/DRFileTable/DRFileTable";
import DRInstructionTable from "src/components/Operations/DisasterRecovery/DRInstructionTable/DRInstructionTable";
import { multipleSort } from "src/utils/processMediaHelper";
import { drSortConstants, maxInstructionFileSize, mbToBytesConversionFactor } from "src/constants/drConstants";
import { programIds } from "src/constants/programIdsConstants";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { filterData } from "src/utils/commonHelper";
import OverwriteFileConfirmation from "src/components/Operations/DisasterRecovery/ConfirmationModals/OverwriteFileConfirmation/OverwriteFileConfirmation";
import { isRTF, readFileAsByteArray, readFileAsString } from "src/utils/drHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { errorCodes } from "src/constants/errorCodes";
import { getMessageDetails } from "src/services/commonServices";
import { insertLoggedEvent } from "src/services/processMediaService";
import {
    downloadFileFromFB,
    getSelectedCustomer,
    uploadDrFilesToGcs,
    uploadDrInstructionFilesToGcs,
} from "src/services/utils";
import { getCurrentDateTime } from "src/utils/dateHelper";
import { acceptedFileFormats } from "src/constants/fileFormatConstants";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { drInstructionTemplateFileName, staticFile } from "src/constants/fileConstants";
import { bytesToKBWithPrecision, bytesToMBWithPrecision } from "src/utils/fileHelper";

const drInstructionTotalSizeLimitMB = process.env.REACT_APP_DR_INSTRUCTION_FILE_SIZE;
const maxRequestLength = process.env.REACT_APP_MAX_REQUEST_LENGTH_KB;
const initialConfirmationModal = {
    show: false,
    fileName: "",
    event: null,
    existingFile: [],
    isDRFile: false,
};
const initialError = {
    show: false,
    message: "",
};
const initialFileError = {
    show: false,
    messageKey: "",
    fileName: "",
};

const DRDocuments = () => {
    const { t, i18n } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [instructionsSizeLimit, setInstructionsSizeLimit] = useState(maxInstructionFileSize);
    const [cusPreference, setPreferences] = useState([]);
    const [cusDRFilesList, setCusDRFilesList] = useState([]);
    const [drInstructions, setDRInstructionList] = useState([]);
    const [, setMaxStorageLimit] = useState(0);
    const [overwriteFileConfirmationObj, setOverwriteFileConfirmationObj] = useState(initialConfirmationModal);
    const [drInstructionUploadError, setDrInstructionUploadError] = useState(initialFileError);
    const [drFileUploadError, setDrFileUploadError] = useState(initialFileError);
    const [errorStatus, setErrorStatus] = useState(initialError);
    const [resetter, setResetter] = useState(0);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);

    useEffect(() => {
        //fetch preference data
        const fetchPreferencesAndTotalSize = async () => {
            setLoading(true);
            //set Instruction size limit
            if (drInstructionTotalSizeLimitMB) {
                setInstructionsSizeLimit(parseInt(drInstructionTotalSizeLimitMB) * mbToBytesConversionFactor);
            }
            const preferences = await getPreferences(preferenceLevelTypeId, "", 1);
            if (!preferences.failure) {
                setPreferences(preferences?.rs0);
            }
            setLoading(false);
        };

        fetchPreferencesAndTotalSize();
    }, []);

    useEffect(() => {
        //fetch Tables data
        const fetchTablesData = async () => {
            setLoading(true);
            const [cusDRFiles, drInstructions] = await Promise.all([
                getListCustomerDRFiles({}),
                getListDRInstructions({ instructionTypeId: "1" }),
            ]);

            if (!cusDRFiles.failure) {
                setCusDRFilesList(multipleSort(cusDRFiles?.drFileList, drSortConstants.drFileSort));
                const maxStorage = cusDRFiles?.maxStorageLimit[0]?.max_storage_limit;
                if (maxStorage) {
                    setMaxStorageLimit(parseInt(maxStorage));
                }
            }

            if (!drInstructions.failure) {
                setDRInstructionList(multipleSort(drInstructions?.drInstructions, drSortConstants.drInstructionsSort));
            }
            setLoading(false);
        };

        fetchTablesData();
    }, [resetter]);

    const userHasPermission = useMemo(() => {
        return {
            downloadPermission: isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_228),
            editPermission: isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_275),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const drInstructionsSize = useMemo(() => {
        return drInstructions.reduce((acc, obj) => acc + parseInt(obj.size_in_bytes), 0);
    }, [drInstructions]);

    const drFilesSize = useMemo(() => {
        const size = cusDRFilesList.reduce((acc, obj) => acc + parseInt(obj.size), 0);
        return bytesToKBWithPrecision(size);
    }, [cusDRFilesList]);

    const drFilesMaxStorage = useMemo(() => {
        let size = 0;
        const selectedPref = filterData(cusPreference, "preference_type_id", "8");
        if (selectedPref.length > 0) {
            size = parseInt(selectedPref[0].preference_value);
        }
        return bytesToMBWithPrecision(size);
    }, [cusPreference]);

    const maxRequestLengthMB = useMemo(() => {
        let length = 0;
        if (maxRequestLength) {
            length = bytesToKBWithPrecision(parseInt(maxRequestLength));
        }
        return length;
    }, []);

    const storeDRDocs = (event, isDRFile = false) => {
        const file = event.target.files[0];
        let sameFile = [];
        if (isDRFile) {
            sameFile = filterData(cusDRFilesList, "name", file.name);
        } else {
            sameFile = filterData(drInstructions, "dr_instruction_name", file.name);
        }
        if (sameFile.length > 0) {
            setOverwriteFileConfirmationObj({
                show: true,
                fileName: file.name,
                event: event,
                existingFile: sameFile,
                isDRFile: isDRFile,
            });
        } else {
            if (isDRFile) {
                insertDRFiles(event);
            } else {
                insertDRInstruction(event);
            }
        }
    };

    const initialStates = () => {
        setErrorStatus(initialError);
        setOverwriteFileConfirmationObj(initialConfirmationModal);
    };

    const insertDRInstruction = async (event, existingFile = []) => {
        setLoading(true);
        const sameFile = [...existingFile];
        initialStates();
        setDrInstructionUploadError(initialFileError);
        let drInstructionId,
            lastModified,
            sizeInDb = "";
        if (sameFile.length > 0) {
            drInstructionId = sameFile[0].dr_instruction_id;
            lastModified = sameFile[0].last_mod_date;
            sizeInDb = sameFile[0].size_in_bytes;
        }
        const file = event.target.files[0];
        const isRTFFile = await isRTF(file, file.name);

        try {
            const fileContentString = await readFileAsString(file);
            if (file && isRTFFile) {
                if (drInstructionsSize - parseInt(sizeInDb) + file.size > instructionsSizeLimit) {
                    setDrInstructionUploadError({
                        show: true,
                        messageKey: "apiErrorMessages.63430",
                        fileName: file.name,
                    });
                } else {
                    const response = await insertAndUpdateDRInstructions({
                        instructionId: drInstructionId,
                        drInstructionName: file.name,
                        lastModDate: lastModified,
                    });

                    if (!response.failure) {
                        const fileNameInGcs = `${response.fileId[0].dr_instruction_id}_${file.name}`;
                        await uploadDrInstructionFilesToGcs(
                            fileContentString,
                            selectedCustomer.customer_id,
                            fileNameInGcs
                        );

                        const source = "DRFiles.StoreDRInstruction";
                        const description = `Disaster Recovery - Upload Instruction File: ${file.name}  Customer Id: ${selectedCustomer.customer_id}`;
                        await insertLoggedEvent("", description, source);
                    } else {
                        if (
                            response.errorCode === errorCodes.Code63546 ||
                            response.errorCode === errorCodes.Code63551
                        ) {
                            const message = t(`apiErrorMessages.${response.error}`);
                            setErrorStatus({
                                show: true,
                                message,
                            });
                        } else {
                            const errorDetails = await getMessageDetails(response.errorCode);
                            setErrorStatus({
                                show: true,
                                message: errorDetails[0]?.descr,
                            });
                        }
                    }
                }
            } else {
                setDrInstructionUploadError({
                    show: true,
                    messageKey: "apiErrorMessages.63770",
                    fileName: file.name,
                });
            }
        } catch (error) {
            setErrorStatus({
                show: true,
                message: `Error reading file: ${error}`,
            });
        }
        event.target.value = "";
        setResetter(resetter + 1);
        setLoading(false);
    };

    const insertDRFiles = async (event, existingFile = []) => {
        let isOmitFileSize = false;
        let drFileLastModified = getCurrentDateTime();
        let drFileId,
            existingFileSizeKB = "";
        let fileSizeCompare = 0;

        setLoading(true);
        const sameFile = [...existingFile];
        initialStates();
        setDrFileUploadError(initialFileError);
        if (sameFile.length > 0) {
            drFileId = sameFile[0].dr_file_id;
            drFileLastModified = sameFile[0].last_mod_date;
            existingFileSizeKB = sameFile[0].size;
            isOmitFileSize = true;
        }
        const file = event.target.files[0];

        if (isOmitFileSize) {
            fileSizeCompare = drFilesSize - existingFileSizeKB / 1024;
        } else {
            fileSizeCompare = drFilesSize;
        }

        const fileExtension = file.name.split(".").pop();
        if (!acceptedFileFormats.some((format) => format.toLowerCase() === `.${fileExtension.toLowerCase()}`)) {
            setDrFileUploadError({
                messageKey: "apiErrorMessages.63550",
                fileName: file.name,
                show: true,
            });
            event.target.value = null;
        } else {
            // Convert file size from bytes to kilobytes (KB)
            const fileSize = parseInt(file.size / 1024);
            const maxStorageInKilobytes = drFilesMaxStorage * 1024;
            if (fileSizeCompare + fileSize >= maxStorageInKilobytes) {
                setDrFileUploadError({
                    messageKey: "apiErrorMessages.63430",
                    fileName: file.name,
                    show: true,
                });
            } else {
                const response = await insertDrInstructionDesignatedCustomer({
                    fileSize: fileSize.toString(),
                    fileName: file.name,
                    fileBlob: "",
                    fileId: drFileId,
                    lastModDate: drFileLastModified,
                });
                if (!response.failure) {
                    const fileId = response.fileId[0].dr_file_id;
                    const encryptPayload = {
                        drId: fileId,
                        fileName: file.name,
                    };
                    const fileByteArray = await readFileAsByteArray(file);
                    await uploadDrFilesToGcs(fileByteArray, fileId, file.name);
                    await drDocumentEncryptService(encryptPayload);
                    const source = "DRFiles.StoreDRFile";
                    const description = `Disaster Recovery - Upload File: ${file.name}  Customer Id: ${selectedCustomer.customer_id}`;
                    await insertLoggedEvent("", description, source);
                } else {
                    if (response.errorCode === errorCodes.Code63546 || response.errorCode === errorCodes.Code63551) {
                        const message = t(`apiErrorMessages.${response.error}`);
                        setErrorStatus({
                            show: true,
                            message,
                        });
                    } else {
                        const errorDetails = await getMessageDetails(response.errorCode);
                        setErrorStatus({
                            show: true,
                            message: errorDetails[0]?.descr,
                        });
                    }
                }
            }
        }
        setResetter(resetter + 1);
        setLoading(false);
    };

    const onDRInstructionTemplateDownload = async () => {
        setLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const file = generateFilePathForCurrentLocale(bucketPath, staticFile["drInstructionTemplate"]);
        const parts = drInstructionTemplateFileName.split(".language.");
        const fileName = parts[0] + "." + i18n.language + "." + parts[1];
        await downloadFileFromFB({
            fbFilePath: file,
            fileName: fileName,
            onComplete: () => setLoading(false),
        });
    };

    const FileUpload = ({ state, onChange, showHelper = true, ...fileUploadProps }) => {
        return (
            <>
                <SecureSyncFileUpload
                    startIcon={<UploadIcon />}
                    variant="contained"
                    onChange={onChange}
                    name={t("common.uploadFile")}
                    {...fileUploadProps}
                />
                {state.show ? (
                    <Box sx={styles.drDocumentsInstructionsLabel}>
                        <FormHelperText sx={styles.drDocumentsInstructions2}>
                            {t("messages.fileErrorMessage", { fileName: state.fileName })}
                        </FormHelperText>
                        <FormHelperText sx={styles.drDocumentsInstructions2}>
                            <Trans i18nKey={state.messageKey} />
                        </FormHelperText>
                    </Box>
                ) : showHelper ? (
                    <Box sx={styles.drDocumentsInstructionsLabel}>
                        <FormHelperText>
                            <Trans i18nKey={"drDocuments.drDocumentsUploadBtnHelper"} />
                        </FormHelperText>
                    </Box>
                ) : (
                    <Box sx={styles.drDocumentsInstructionsLabel} />
                )}
            </>
        );
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <PageTitle>{t("titles.drDocuments")}</PageTitle>
                    <Box>
                        {errorStatus.show && (
                            <SecureSyncAlert
                                severity="error"
                                message={errorStatus.message}
                                onClose={() => setErrorStatus(initialError)}
                            />
                        )}
                    </Box>
                    <Divider />
                    <PageSubTitle sx={styles.fontBold}>{t("drDocuments.drInstructionsSubTitle")}</PageSubTitle>
                    <Typography variant="body1" sx={styles.drDocumentsBody1}>
                        {t("drDocuments.drInstructionsDescription")}
                    </Typography>
                    {drInstructions.length === 0 ? (
                        <Typography variant="body1" sx={styles.drDocumentsBody2}>
                            {t("drDocuments.noDisasterInstructions")}
                        </Typography>
                    ) : (
                        <Box sx={styles.defaultMarginBottom}>
                            <DRInstructionTable
                                resultData={drInstructions}
                                editPermission={userHasPermission.editPermission}
                                downloadPermission={userHasPermission.downloadPermission}
                                selectedCustomerId={selectedCustomer.customer_id}
                                setResetter={setResetter}
                            />
                        </Box>
                    )}
                    <Box sx={styles.contactFieldContainer}>
                        {userHasPermission.editPermission && (
                            <Box>
                                <Typography variant="body1" sx={styles.drDocumentsInstructions}>
                                    {t("drDocuments.addDisasterRecoveryHelperText")}
                                </Typography>
                                <FileUpload state={drInstructionUploadError} onChange={storeDRDocs} />
                            </Box>
                        )}
                        <Box
                            sx={userHasPermission.editPermission ? styles.buttonContainer : styles.defaultBtnContainer}
                        >
                            <Button variant="outlined" color="primary" onClick={onDRInstructionTemplateDownload}>
                                {t("buttonLabels.retrieveDRInstructionTemplate")}
                            </Button>
                        </Box>
                    </Box>

                    <Divider />
                    <PageSubTitle sx={styles.fontBold}>
                        <>
                            {t("drDocuments.drFileSubTitle")}
                            <FormHelperText>{t("drDocuments.drFileNote")}</FormHelperText>
                        </>
                    </PageSubTitle>
                    <Typography variant="body1" sx={styles.drDocumentsBody1}>
                        <Trans
                            i18nKey={"drDocuments.drFileDescription"}
                            values={{
                                limit: drFilesMaxStorage,
                                storedValue: drFilesSize,
                                maxRequestSize: maxRequestLengthMB,
                            }}
                            components={{ sp: <Box component={"span"} sx={styles.highlightTxt} /> }}
                        />
                    </Typography>
                    {cusDRFilesList.length === 0 ? (
                        <Typography variant="body1" sx={styles.drDocumentsBody2}>
                            {t("drDocuments.noDisasterDocuments")}
                        </Typography>
                    ) : (
                        <Box sx={styles.defaultMarginBottom}>
                            <DRFileTable
                                resultData={cusDRFilesList}
                                editPermission={userHasPermission.editPermission}
                                downloadPermission={userHasPermission.downloadPermission}
                                selectedCustomerId={selectedCustomer.customer_id}
                                setResetter={setResetter}
                            />
                        </Box>
                    )}
                    <Box sx={styles.contactFieldContainer}>
                        {userHasPermission.editPermission && (
                            <Box>
                                <Typography variant="body1" sx={styles.drDocumentsInstructions}>
                                    {t("drDocuments.addDRFiles")}
                                </Typography>
                                <FileUpload
                                    state={drFileUploadError}
                                    onChange={(event) => {
                                        storeDRDocs(event, true);
                                    }}
                                    accept={acceptedFileFormats.join(",")}
                                    showHelper={false}
                                />
                            </Box>
                        )}
                    </Box>
                    <FormHelperText sx={styles.footerHelper}>{t("drDocuments.footerNote")}</FormHelperText>
                </Box>
            </Box>
            <OverwriteFileConfirmation
                fileName={overwriteFileConfirmationObj.fileName}
                handleConfirm={() => {
                    if (overwriteFileConfirmationObj.isDRFile) {
                        insertDRFiles(overwriteFileConfirmationObj.event, overwriteFileConfirmationObj.existingFile);
                    } else {
                        insertDRInstruction(
                            overwriteFileConfirmationObj.event,
                            overwriteFileConfirmationObj.existingFile
                        );
                    }
                }}
                onClose={() => setOverwriteFileConfirmationObj(initialConfirmationModal)}
                open={overwriteFileConfirmationObj.show}
            />
        </>
    );
};
export default DRDocuments;
