const styles = {
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    boxName: {
        padding: "10px 0px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 640px)",
    },
    boxParentAccount: {
        padding: "10px 0px",
        display: "grid",
        gridTemplateColumns: "repeat(1, 310px)",
    },
    toggleButton: {
        paddingLeft: "10px",
    },
    toggleTypo: {
        paddingLeft: "47px",
    },
    formControlStyle: {
        "& .MuiFormControl-root": {
            paddingRight: "0px",
        },
    },
    boxDataCenter: {
        padding: "10px 0px",
        display: "grid",
        gridTemplateColumns: "640px 200px 90px 310px",
    },
};

export default styles;
