import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./OtherServicesActionSection.styles";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const OtherServicesActionSection = (props) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate(dashboard);
    };
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            props.handleSubmit();
        },
    });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => props.resetForm() });
    return (
        <Box display="flex" gap={2.5} sx={styles.actionSection}>
            <Button id="clear-button" variant="outlined" onClick={navigateToHome} color="primary">
                {t("buttonLabels.cancel")}
            </Button>
            <Button
                id="clear-button"
                variant="outlined"
                onClick={() => props.resetForm()}
                color="primary"
                title={getKeyBoardLabel(shortCutKeys.reset)}
            >
                {t("buttonLabels.reset")}
            </Button>
            <Button
                id="submit-button"
                variant="contained"
                type="submit"
                color="primary"
                title={getKeyBoardLabel(shortCutKeys.submit)}
            >
                {t("common.submit")}
            </Button>
        </Box>
    );
};

export default OtherServicesActionSection;
