import { Box, Divider, Paper, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import styles from "./CustomerList.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import {
    editUser,
    getMyAccountPath,
    getUserAccountPath,
} from "src/constants/routeConstants/administrationRouterFullPaths";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import Pencil from "src/components/Icons/Pencil/Pencil";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const CustomerList = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const fromUserSearch = useMatch(editUser);
    const params = useParams();
    const handleEditClick = (el) => {
        const account = el.account;
        const accountEditPath = fromUserSearch
            ? getUserAccountPath(params.userId, account)
            : getMyAccountPath(params.userId, account);
        navigate(accountEditPath);
    };

    const columns = useMemo(() => {
        let baseColumns = [
            {
                field: "customerNumber",
                headerName: convertToLocaleUpperCase(t("tableHeader.account")),
                flex: 1,
                sortable: true,
            },
            { field: "name", headerName: convertToLocaleUpperCase(t("tableHeader.name")), flex: 1, sortable: true },
            { field: "branch", headerName: convertToLocaleUpperCase(t("tableHeader.branch")), flex: 1, sortable: true },
            {
                field: "interactionAuth",
                headerName: convertToLocaleUpperCase(t("tableHeader.interactionAuthority")),
                flex: 1,
                sortable: true,
            },
            {
                field: "drAuth",
                headerName: convertToLocaleUpperCase(t("tableHeader.drAuthority")),
                flex: 1,
                sortable: true,
            },
        ];

        if (props.enabledEdit) {
            baseColumns = [
                {
                    field: "edit",
                    headerName: "",
                    width: 60,
                    sortable: false,
                    renderCell: (params) => (
                        <>
                            {params && params.editPermission && (
                                <SvgIcon
                                    sx={styles.iconColor}
                                    component={Pencil}
                                    onClick={() => {
                                        handleEditClick(params);
                                    }}
                                />
                            )}
                        </>
                    ),
                },
                ...baseColumns,
            ];
        }

        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledEdit]);

    const customerList = useMemo(() => {
        if (props.rows) {
            props.rows.sort((a, b) => {
                const customerA = a.id;
                const customerB = b.id;

                if (customerA < customerB) {
                    return -1;
                }
                if (customerA > customerB) {
                    return 1;
                }
                return 0;
            });
            return props.rows;
        }
        return [];
    }, [props.rows]);
    return (
        <>
            {!props.enabledEdit && (
                <>
                    <PageSubTitle>{t("authorization.customerAccountAssignment")}</PageSubTitle>
                    <Typography variant="body2" sx={styles.typographyBody}>
                        {t("authorization.customerHelperTxt")}
                    </Typography>
                    {props.touched && props.error && (
                        <Typography color="error" sx={styles.authLevelHeaderTypography}>
                            {props.error}
                        </Typography>
                    )}
                </>
            )}
            <Box sx={styles.boxPadding}>
                <Paper>
                    <SecureSyncTable
                        initialOrder="desc"
                        onSelectionChange={props.onClickRowSelect}
                        rows={customerList}
                        columns={columns}
                        noDataMessage={t("messages.noRecordsFound")}
                        getRowId={(row) => row.id}
                        enableSelection={!props.enabledEdit}
                        tableRef={props.clearSelectionRef}
                        initialValues={props.initialValues}
                        selectAllKeepInitialValues={true}
                    />
                </Paper>
            </Box>
            <Divider />
        </>
    );
};

export default CustomerList;
