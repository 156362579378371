const styles = {
    heading: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: 1.34,
        color: "im_grey.900",
    },
    container: {
        paddingBottom: "40px",
    },
    multiTable: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
    },
};

export default styles;
