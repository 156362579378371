const styles = {
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "32px",
        paddingBottom: "20px",
    },
};

export default styles;
