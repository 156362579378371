/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Container, Divider, FormHelperText } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./ScanVerificationResult.styles";
import ScanVerificationResultDataTable from "src/components/ProcessMedia/ScanVerification/ScanVerificationResultTable/ScanVerificationResultDataTable";
import {
    addVerifySummary,
    getVerifyResultSummaryAndIssues,
    getVerifyResultSummaryIssues,
    insertLoggedEvent,
    verifyAndUpdateIssueItem,
} from "src/services/processMediaService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { convertLocalDateTime, formatDate } from "src/utils/dateHelper";
import { yesFlag } from "src/constants/serviceConstants";
import {
    jsonToXmlScanVerification,
    listDescDisplayFormatHelper,
    listDescFormatHelper,
    multipleSort,
} from "src/utils/processMediaHelper";
import EditVerificationIssueModal from "src/components/ProcessMedia/ScanVerification/EditVerificationIssueModal/EditVerificationIssueModal";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { resolved, sortingOrderType, verifyIssueTypeId } from "src/constants/processMediaConstants";
import { getKeyBoardLabel, padEndStringWithSelectedCharacters } from "src/utils/commonHelper";
import { scanVerificationHistoryResult, scanVerificationReportPayload } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

export const ScanVerificationResult = ({
    scanVerificationObj,
    verifyResultRecord,
    scanMore,
    isScanVerificationHistory = false,
    refreshPage = () => {},
}) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.scanMore,
        callback: () => {
            if (verifyResultSet0[0]?.verify_editable_flag === yesFlag) {
                scanMoreHandler();
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            if (buttonState?.submitButton) {
                handleSubmit();
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            if (buttonState?.prepareReportButton && !(buttonState?.disableButton || updatedRows.size > 0)) {
                printReport(false);
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareFullReport,
        callback: () => {
            if (buttonState?.prepareFullReportButton && !(buttonState?.disableButton || updatedRows.size > 0)) {
                printReport(true);
            }
        },
    });
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [summaryDetailsData, setSummaryDetailsData] = useState([]);
    const [defaultSummaryDetailsData, setDefaultSummaryDetailsData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEditVerificationIssueModalOpen, setIsEditVerificationIssueModalOpen] = useState(false);
    const [scanVerifyResults, setScanVerifyResults] = useState({});
    const [updatedRows, setUpdatedRows] = useState(new Set());
    const [mediaList, setMediaList] = useState([]);
    const [verifyResultId, setVerifyResultId] = useState("0");
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalProps, setPrintModalProps] = useState({ show: false, isFullReport: false });

    useEffect(() => {
        verifyResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyResults = async () => {
        setIsLoading(true);
        if (scanVerificationObj.verifyResultId) {
            setVerifyResultId(scanVerificationObj.verifyResultId);
        }
        const response = await getVerifyResultSummaryIssues({
            ...scanVerificationObj,
            mediaList: createVolserList(scanVerificationObj?.scanMediaObj?.mediaList),
        });
        setMediaList(scanVerificationObj?.scanMediaObj?.mediaList);
        if (!response.failure) {
            setScanVerifyResults(response);
            const summaryDetailsList = response?.allSummaryDetails ? response?.allSummaryDetails : [];
            setSummaryDetailsData(sortIssues(summaryDetailsList));
            setDefaultSummaryDetailsData(summaryDetailsList);
        }
        setIsLoading(false);
    };

    const scanResult = useMemo(() => {
        const metaData =
            scanVerifyResults?.metaData && scanVerifyResults?.metaData?.length > 0 ? scanVerifyResults?.metaData : [];
        const lastScanCount = mediaList.length;
        const scanMatchCount = parseInt(metaData[0]?.scan_match_count) || 0;
        const scanCount = parseInt(metaData[0]?.scan_count) || 0;
        const newScanCount = lastScanCount - scanMatchCount;
        const totalScanCount =
            verifyResultRecord[0]?.verify_result_id === "0" || verifyResultId === "0" || verifyResultRecord.length === 0
                ? scanCount + lastScanCount
                : scanCount + lastScanCount - scanMatchCount;
        const unresolvedOnListNotScanCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.sendOpenMediaToIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const unresolvedNotOnListCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.receiveOpenMediaFromIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        let createdDateTime = "";
        if (!verifyResultRecord[0]?.verify_result_id) {
            if (scanVerificationObj?.filterDate) {
                if (scanVerificationObj.actionId === verifyIssueTypeId.sendOpenMediaToIronMountain) {
                    createdDateTime = convertLocalDateTime(scanVerificationObj?.filterDate);
                } else {
                    createdDateTime = formatDate(scanVerificationObj?.filterDate);
                }
            }
        } else if (metaData[0]?.verify_type_id === verifyIssueTypeId.sendOpenMediaToIronMountain) {
            createdDateTime = convertLocalDateTime(metaData[0]?.list_creation_date_ltz);
        } else {
            createdDateTime = metaData[0]?.service_date;
        }
        let logicalVault = "";
        if (
            verifyResultRecord[0]?.verify_result_id &&
            (parseInt(verifyResultRecord[0]?.verify_result_id) > 0 || parseInt(verifyResultId) > 0)
        ) {
            logicalVault = metaData[0]?.logical_vault_descr;
        } else {
            logicalVault = scanVerificationObj?.logicalVaultDesc;
        }
        return {
            lastScannedMediaList: scanVerificationObj?.mediaList,
            lastScanCount: lastScanCount,
            totalScanCount: totalScanCount,
            unresolvedOnListNotInScanCount: unresolvedOnListNotScanCount,
            unresolvedNotOnListCount: unresolvedNotOnListCount,
            verifiedItemCount: parseInt(metaData[0]?.list_count) - unresolvedOnListNotScanCount,
            createdDateTime: createdDateTime,
            logicalVault: logicalVault,
            newScanCount: newScanCount,
        };
    }, [scanVerificationObj, mediaList, summaryDetailsData, verifyResultRecord, scanVerifyResults, verifyResultId]);

    const buttonState = useMemo(() => {
        const metaData =
            scanVerifyResults?.metaData && scanVerifyResults?.metaData?.length > 0 ? scanVerifyResults?.metaData : [];

        const unresolvedOnListNotScanCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.sendOpenMediaToIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const unresolvedNotOnListCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.receiveOpenMediaFromIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const lastScanCount = mediaList.length;
        const newScanCount = lastScanCount - parseInt(metaData[0]?.scan_match_count);
        let submitBtnView = false;
        if (verifyResultRecord[0]?.verify_result_id === "0" || verifyResultId === "0") {
            submitBtnView = true;
        } else {
            var totalCurrentUnresolvedCount = unresolvedOnListNotScanCount + unresolvedNotOnListCount;
            if (totalCurrentUnresolvedCount > 0 || newScanCount > 0 || updatedRows.size > 0) {
                submitBtnView = true;
            } else {
                submitBtnView = false;
            }
        }
        let prepareFullReportBtnView = false;
        if (
            metaData[0]?.verify_type_id === verifyIssueTypeId.receiveClosedContainerContents &&
            !isScanVerificationHistory
        ) {
            prepareFullReportBtnView = true;
        } else if (
            (parseInt(verifyResultRecord[0]?.verify_result_id) > 0 || parseInt(verifyResultId) > 0) &&
            metaData[0]?.verify_editable_flag === yesFlag
        ) {
            prepareFullReportBtnView = true;
        }
        return {
            submitButton: submitBtnView,
            prepareFullReportButton: prepareFullReportBtnView,
            prepareReportButton: parseInt(verifyResultRecord[0]?.verify_result_id) > 0 || parseInt(verifyResultId) > 0,
            disableButton: newScanCount !== 0,
        };
    }, [
        mediaList,
        summaryDetailsData,
        verifyResultRecord,
        scanVerifyResults,
        updatedRows,
        verifyResultId,
        isScanVerificationHistory,
    ]);

    const verifyResultSet0 = scanVerifyResults?.metaData ? scanVerifyResults?.metaData : [];

    const sortIssues = (dataArr) => {
        const sortCriteria = [
            { key: "c1", order: sortingOrderType.descending },
            { key: "c3", order: sortingOrderType.ascending },
        ];

        return multipleSort(dataArr, sortCriteria);
    };
    const openEditVerificationIssueModalHandler = () => {
        setIsEditVerificationIssueModalOpen(true);
    };

    const closeEditVerificationIssueModalHandler = () => {
        setIsEditVerificationIssueModalOpen(false);
    };

    const submitEditVerificationIssueModalHandler = (updatedData) => {
        closeEditVerificationIssueModalHandler();
        const newUpdatedRows = new Set(updatedRows);
        const updatedObj = summaryDetailsData.map((obj) => {
            if (obj.c3 === selectedRecord.c3) {
                const getInitialRecord = defaultSummaryDetailsData.filter((item) => item.c3 === selectedRecord.c3);
                if (updatedData.c5 === getInitialRecord[0].c5 && updatedData.c4 === getInitialRecord[0].c4) {
                    newUpdatedRows.delete(selectedRecord.c3);
                } else {
                    newUpdatedRows.add(selectedRecord.c3);
                }
                obj.c4 = updatedData.c4;
                obj.c5 = updatedData.c5;
                return obj;
            }
            return obj;
        });
        setUpdatedRows(newUpdatedRows);
        setSummaryDetailsData(updatedObj);
        setSelectedRecord(null);
    };
    const handleSubmit = async () => {
        setIsLoading(true);
        var insertVerifySummary = null;
        const hasVerifyResultId = Boolean(scanVerificationObj.verifyResultId);
        if (!hasVerifyResultId && verifyResultId === "0") {
            insertVerifySummary = await addVerifySummary({
                verifyResultId: "0",
                customerFileSectionId: scanVerificationObj.customerFileSectionId,
                requestId: scanVerificationObj.requestId,
                containerId: scanVerificationObj.containerId,
                verifyTypeId: scanVerificationObj.actionId,
                count: verifyResultSet0[0]?.list_count,
                descr: listDescFormatHelper(scanVerificationObj.listDes),
                listCreationDate: scanVerificationObj.filterDate,
                logicalVaultDescr: scanVerificationObj?.logicalVaultDesc,
            });
            setVerifyResultId(insertVerifySummary?.responseData0[0]?.verify_result_id);
        }

        const tempVerifyId =
            !hasVerifyResultId && verifyResultId === "0"
                ? insertVerifySummary?.responseData0[0]?.verify_result_id
                : verifyResultId !== "0"
                  ? verifyResultId
                  : scanVerificationObj.verifyResultId;
        await verifyAndUpdateIssueItem(
            tempVerifyId,
            jsonToXmlScanVerification(
                scanVerifyResults,
                {
                    customerFileSectionId: scanVerificationObj.customerFileSectionId,
                    requestId: scanVerificationObj.requestId,
                    containerId: scanVerificationObj.containerId,
                    verifyTypeId: scanVerificationObj.actionId,
                    count: verifyResultSet0[0]?.list_count,
                    descr: listDescFormatHelper(scanVerificationObj.listDes),
                    listCreationDate: scanVerificationObj.filterDate,
                    resolvedCount: scanResult?.verifiedItemCount,
                },
                summaryDetailsData
            ),
            mediaList.length
        );
        const responseVerifiedItems = await getVerifyResultSummaryAndIssues(
            tempVerifyId,
            t("scanVerification.scannedPhrase")
        );
        if (!responseVerifiedItems.failure) {
            setScanVerifyResults(responseVerifiedItems);
            const summaryDetailsList = responseVerifiedItems?.allSummaryDetails
                ? responseVerifiedItems?.allSummaryDetails
                : [];
            setMediaList([]);
            setSummaryDetailsData(sortIssues(summaryDetailsList));
            setDefaultSummaryDetailsData(summaryDetailsList);
            const description = "Scan Verification Results: Successful Save of Scan Verification Results";
            let comment = "";
            if (hasVerifyResultId) {
                comment = `Verify_Result_Id: ${scanVerificationObj.verifyResultId}`;
            } else {
                const verifyResultId = insertVerifySummary?.responseData0[0]?.verify_result_id;
                const personnelName = responseVerifiedItems?.metaData[0]?.Last_Session_Personnel_Name;
                const lastSessionDate = responseVerifiedItems?.metaData[0]?.Last_Session_Date_Ltz;
                comment = `Verify_Result_Id: ${verifyResultId} Current Session: ${personnelName} ${lastSessionDate}`;
            }

            await insertLoggedEvent(comment, description);
        }
        await refreshPage();
        setUpdatedRows(new Set());
        setIsLoading(false);
    };

    const isVerificationResultHasLength = verifyResultSet0.length > 0;

    const onDeleteIssuesHandler = (id) => {
        const verificationIssueList = summaryDetailsData.filter((item) => item.c3 !== id);
        const modifiedMediaList = mediaList.filter((item) => item.mediaList !== id.trim());
        setMediaList(modifiedMediaList);
        const newUpdatedRows = new Set(updatedRows);
        newUpdatedRows.delete(id);
        setUpdatedRows(newUpdatedRows);
        setSummaryDetailsData(verificationIssueList);
    };

    const createVolserList = (mediaTypeList) => {
        if (mediaTypeList.length > 0) {
            return mediaTypeList.map((value) => padEndStringWithSelectedCharacters(value.mediaList, 20, " ")).join("");
        }
        return "";
    };

    const scanMoreHandler = () => {
        scanMore({
            lastScan: mediaList[mediaList.length - 1]?.mediaList || "-",
            totalRecords: mediaList.length,
            mediaList: mediaList,
        });
    };

    const printReport = (isFullReport = false) => {
        let payload;
        if (isScanVerificationHistory) {
            const params = {
                verifyResultId: verifyResultId,
                list: listDescDisplayFormatHelper(scanVerificationObj.listDes),
                createdDate: scanResult?.createdDateTime || "",
                verifiedItems: scanResult?.verifiedItemCount.toString() || "0",
                originalVerificationDate: verifyResultSet0[0]?.first_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                    : "",
                lastVerificationDate: verifyResultSet0[0]?.last_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                    : "",
            };
            payload = scanVerificationHistoryResult(params);
        } else {
            const params = {
                isFullReport: isFullReport,
                verifyResultId: verifyResultId,
                list: listDescDisplayFormatHelper(scanVerificationObj.listDes),
                createdDate: scanResult?.createdDateTime || "",
                verifiedItems: scanResult?.verifiedItemCount.toString() || "0",
                originalVerificationDate: verifyResultSet0[0]?.first_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                    : "",
                lastVerificationDate: verifyResultSet0[0]?.last_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                    : "",
            };
            payload = scanVerificationReportPayload(params);
        }
        setPrintPayload(payload);
        setPrintModalProps({ show: true, isFullReport: isFullReport });
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("titles.scanVerificationResults")}</PageTitle>
                        <Box sx={styles.infoMessagesSection}>
                            {buttonState?.submitButton && (
                                <Trans
                                    i18nKey={"apiErrorMessages.63577"}
                                    sx={styles.buttonSubmit}
                                    values={{ buttonLabel: t("buttonLabels.submit") }}
                                />
                            )}
                            <br />
                            {verifyResultSet0[0]?.verify_editable_flag === yesFlag && (
                                <Trans
                                    i18nKey={"apiErrorMessages.25133"}
                                    values={{ buttonLabel: t("buttonLabels.scanMore") }}
                                />
                            )}
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("scanVerification.comparisonSummary")}</PageSubTitle>
                        <Box sx={styles.mainWrapper}>
                            <SecureSyncReadOnlyTextField
                                value={scanVerificationObj.actionDes || " "}
                                label={t("scanVerification.action")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={
                                    listDescDisplayFormatHelper(
                                        scanVerificationObj.listDes,
                                        scanVerificationObj.actionId
                                    ) || " "
                                }
                                label={t("scanVerification.list")}
                            />
                            {scanResult?.createdDateTime && (
                                <SecureSyncReadOnlyTextField
                                    value={scanResult?.createdDateTime || " "}
                                    label={
                                        scanVerificationObj?.actionId ===
                                        verifyIssueTypeId.receiveOpenMediaFromIronMountain
                                            ? t("scanVerification.serviceDate")
                                            : t("scanVerification.createdDateTime")
                                    }
                                />
                            )}
                            {scanVerificationObj && scanVerificationObj.logicalVaultDesc && (
                                <SecureSyncReadOnlyTextField
                                    value={scanVerificationObj.logicalVaultDesc || " "}
                                    label={t("scanVerification.logicalVault")}
                                />
                            )}
                            <Box sx={styles.div4}>
                                {isVerificationResultHasLength && verifyResultSet0[0]?.first_session_personnel_name && (
                                    <SecureSyncReadOnlyTextField
                                        value={verifyResultSet0[0]?.first_session_personnel_name || " "}
                                        label={t("scanVerification.originalVerificationUser")}
                                    />
                                )}
                            </Box>
                            {isVerificationResultHasLength && verifyResultSet0[0]?.first_session_date_ltz && (
                                <SecureSyncReadOnlyTextField
                                    value={
                                        verifyResultSet0[0]?.first_session_date_ltz
                                            ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                                            : " "
                                    }
                                    label={t("scanVerification.originalVerificationDateTime")}
                                />
                            )}
                            {isVerificationResultHasLength && verifyResultSet0[0]?.last_session_personnel_name && (
                                <SecureSyncReadOnlyTextField
                                    value={verifyResultSet0[0]?.last_session_personnel_name || " "}
                                    label={t("scanVerification.lastVerificationUser")}
                                />
                            )}
                            {isVerificationResultHasLength && verifyResultSet0[0]?.last_session_date_ltz && (
                                <SecureSyncReadOnlyTextField
                                    value={
                                        verifyResultSet0[0]?.last_session_date_ltz
                                            ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                                            : " "
                                    }
                                    label={t("scanVerification.lastVerificationDateTime")}
                                />
                            )}
                        </Box>
                        <Box sx={styles.statisticWrapper}>
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.totalScanCount || "0"}
                                label={t("scanVerification.uniqueItemsScanned")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={verifyResultSet0[0]?.list_count || "0"}
                                label={t("scanVerification.totalItemsInList")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.verifiedItemCount || "0"}
                                label={t("scanVerification.verifiedItems")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.unresolvedOnListNotInScanCount || "0"}
                                label={t("scanVerification.unresolvedOnListNotInScan")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.unresolvedNotOnListCount || "0"}
                                label={t("scanVerification.unresolvedNotOnList")}
                            />
                        </Box>

                        <Divider />
                        <PageSubTitle>{t("titles.verificationIssues")}</PageSubTitle>
                        <ScanVerificationResultDataTable
                            dataArray={summaryDetailsData}
                            updatedRowList={updatedRows}
                            getRowId={(row) => row.c3}
                            openEditVerificationIssueModalHandler={openEditVerificationIssueModalHandler}
                            isEditVerificationIssueModalOpen={isEditVerificationIssueModalOpen}
                            setSelectedRecord={setSelectedRecord}
                            selectedRecord={selectedRecord}
                            onDelete={onDeleteIssuesHandler}
                        />
                        <FormHelperText sx={styles.formHelperText}>
                            {t("scanVerification.whenTheResolvedFlagIsCheckedComments")}
                        </FormHelperText>
                        <Divider />
                        <Box sx={styles.buttonContainer}>
                            {verifyResultSet0[0]?.verify_editable_flag === yesFlag && (
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.scanMore)}
                                    variant="outlined"
                                    onClick={scanMoreHandler}
                                >
                                    {t("buttonLabels.scanMore")}
                                </Button>
                            )}
                            {buttonState?.prepareReportButton && (
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                    variant="outlined"
                                    disabled={buttonState?.disableButton || updatedRows.size > 0}
                                    onClick={() => printReport(false)}
                                >
                                    {t("buttonLabels.prepareIssueReport")}
                                </Button>
                            )}
                            {buttonState?.prepareFullReportButton && (
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.prepareFullReport)}
                                    variant="outlined"
                                    disabled={buttonState?.disableButton || updatedRows.size > 0}
                                    onClick={() => printReport(true)}
                                >
                                    {t("buttonLabels.prepareFullReport")}
                                </Button>
                            )}
                            {buttonState?.submitButton && (
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.submit)}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={styles.buttonSubmit}
                                >
                                    {t("buttonLabels.submit")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
            {isEditVerificationIssueModalOpen && (
                <EditVerificationIssueModal
                    recordData={selectedRecord}
                    onClose={closeEditVerificationIssueModalHandler}
                    onSubmit={submitEditVerificationIssueModalHandler}
                />
            )}
            <SecureSyncPrintModal
                open={printModalProps.show}
                payload={printPayload}
                onClose={() => setPrintModalProps({ show: false, isFullReport: false })}
                targetReport={
                    printModalProps.isFullReport
                        ? targetUrl.scanVerificationFullReport
                        : isScanVerificationHistory
                          ? targetUrl.scanVerificationHistoryResult
                          : targetUrl.scanVerificationReport
                }
            />
        </>
    );
};
