const styles = {
    clearIcon: {
        color: "im_dark_blue.500",
    },
    tblCardContent: {
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
    },
    boxContainer: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        paddingBottom: "20px",
    },
    textFieldWidth: { width: "300px" },
    allPadding: { padding: "16px" },
    importText: { fontWeight: 400, fontSize: 12, marginBottom: 15 },
    bottomPadding: { marginTop: "20px", paddingBottom: "20px" },
    cardHederText: {
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "20px" /* 100% */,
        letterSpacing: "0.14px",
        color: "text.primary",
    },
    cardDesc: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        color: "text.primary",
        paddingBottom: "20px",
    },
    uploadText: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        color: "text.primary",
    },
};

export default styles;
