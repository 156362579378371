const WarningOutlined = ({ fill, width = "22px", height = "22px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" style={{ width, height }}>
            <path
                d="M10.9967 13.5207C10.6209 13.5207 10.3092 13.209 10.3092 12.8332V8.24986C10.3092 7.87403 10.6209 7.56236 10.9967 7.56236C11.3725 7.56236 11.6842 7.87403 11.6842 8.24986V12.8332C11.6842 13.209 11.3725 13.5207 10.9967 13.5207Z"
                fill={fill}
            />
            <path
                d="M10.9967 16.4999C10.9417 16.4999 10.8775 16.4907 10.8134 16.4815C10.7584 16.4724 10.7034 16.454 10.6484 16.4265C10.5934 16.4082 10.5384 16.3807 10.4834 16.344L10.3459 16.234C10.1809 16.0599 10.08 15.8215 10.08 15.5832C10.08 15.3449 10.1809 15.1065 10.3459 14.9324L10.4834 14.8224C10.5384 14.7857 10.5934 14.7582 10.6484 14.7399C10.7034 14.7124 10.7584 14.694 10.8134 14.6849C10.9325 14.6574 11.0609 14.6574 11.1709 14.6849C11.235 14.694 11.29 14.7124 11.345 14.7399C11.4 14.7582 11.455 14.7857 11.51 14.8224L11.6475 14.9324C11.8125 15.1065 11.9134 15.3449 11.9134 15.5832C11.9134 15.8215 11.8125 16.0599 11.6475 16.234L11.51 16.344C11.455 16.3807 11.4 16.4082 11.345 16.4265C11.29 16.454 11.235 16.4724 11.1709 16.4815C11.1159 16.4907 11.0517 16.4999 10.9967 16.4999Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.44168 20.3132H16.5517C18.3392 20.3132 19.705 19.6624 20.4017 18.4982C21.0984 17.3157 20.9975 15.8124 20.1267 14.2449L14.5717 4.24402C13.655 2.59402 12.39 1.68652 10.9967 1.68652C9.60335 1.68652 8.33835 2.59402 7.42168 4.24402L1.86668 14.2357C0.995849 15.8032 0.904182 17.3157 1.59168 18.489C2.28835 19.6624 3.65418 20.3132 5.44168 20.3132ZM8.62252 4.91319C9.29168 3.72152 10.135 3.06152 10.9967 3.06152C11.8583 3.06152 12.7017 3.72152 13.3709 4.91319L18.935 14.914C19.5583 16.0415 19.6683 17.059 19.2283 17.8015C18.7883 18.544 17.8442 18.9474 16.5608 18.9474H5.45085C4.16752 18.9474 3.21418 18.544 2.78335 17.8015C2.35252 17.059 2.45335 16.0324 3.07668 14.914L8.62252 4.91319Z"
                fill={fill}
            />
        </svg>
    );
};

export default WarningOutlined;
