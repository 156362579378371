export const isRTF = (inputStream, fileName) => {
    const charsToValidate = 1000;
    let fileContent = "";

    // First, make sure the name has .rtf as its extension
    if (fileName.split(".").pop().toUpperCase() !== "RTF") {
        return false;
    }

    // Create a reader to read the input stream
    const reader = new FileReader();

    return new Promise((resolve) => {
        reader.onload = (event) => {
            const result = event.target.result;
            const byteArray = new Uint8Array(result);

            // Read first 1000 bytes and check for non-ASCII characters (>128)
            for (let i = 0; i < Math.min(byteArray.length, charsToValidate); i++) {
                if (byteArray[i] > 128) {
                    resolve(false);
                    return;
                }
            }

            // Convert the stream to ASCII string
            fileContent = new TextDecoder("ascii").decode(result);

            // Check if the file contains standard RTF header information
            if (fileContent.substring(0, 7).toUpperCase() !== "{\\RTF1\\") {
                resolve(false);
                return;
            }

            // Check if the file contains any embedded objects (objdata)
            if (fileContent.toLowerCase().includes("objdata")) {
                resolve(false);
                return;
            }

            resolve(true);
        };

        reader.readAsArrayBuffer(inputStream);
    });
};

export function readFileAsString(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Resolve the promise when file is successfully read
        reader.onload = function (e) {
            resolve(e.target.result); // The file content as a string
        };

        // Reject the promise if an error occurs during reading
        reader.onerror = function () {
            reject(new Error("Error reading file"));
        };

        reader.readAsText(file, "ASCII"); // Read file as text
    });
}

export const readFileAsByteArray = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (e) {
            const arrayBuffer = e.target.result; // ArrayBuffer from the file
            const byteArray = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Uint8Array
            resolve(byteArray); // Resolve with byte array string
        };

        // Reject the promise if an error occurs during reading
        reader.onerror = function () {
            reject(new Error("Error reading file"));
        };

        reader.readAsArrayBuffer(file);
    });
};

export const downloadRTFFile = (fileName, content) => {
    // Create a Blob from the RTF content string
    const blob = new Blob([content], { type: "application/rtf" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with the desired file name
    link.download = fileName;

    // Create a URL for the blob and set it as the href of the link
    link.href = URL.createObjectURL(blob);

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
};

export const downloadFileFromByteArray = (byteArr, fileName) => {
    // Sample byte array (Uint8Array)

    const byteArray = new Uint8Array(byteArr);
    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName; // Specify the file name

    // Automatically trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM
    document.body.removeChild(link);
};

export const removeFileExtension = (filePath) => {
    return filePath.replace(/\.[^/.]+$/, "");
};
