export const en = "en";
export const enGB = "en-GB";
export const enUS = "en-US";

export const authActionType = {
    update: "U",
    insert: "I",
};

export const authLevelCode = {
    none: "None",
    A: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    R: "R",
    confirmDr: "1",
    manageDr: "2",
    declareDr: "3",
    reviseDr: "4",
};
