import { getDocs, query } from "firebase/firestore";
import { app_setting_collection } from "./firebase";
import { traceWrapper } from "./firebasePerformance";
import { constructFbHeaders, invokeApi } from "./utils";
import { CIAM_USER_EXIST, CIAM_USER_NOT_FOUND } from "src/constants/ciamConstants";

const apiUrls = {
    getUserById: "/user/getbyid",
    createUser: "/user/add",
    updateUser: "/user/update",
};

export const ssoHandleService = async (login, payload, isNewUser = false) => {
    const ciamPayload = await ssoPayloadCreation(login, payload);
    const createSSO = await ssoCreateUser(ciamPayload);
    try {
        if (createSSO) {
            return true;
        } else {
            // okta fail
            // check user already has app access
            const hasAppAccess = await ssoGetUser(login);
            if (isNewUser && hasAppAccess) {
                return false;
            }
            const userId = hasAppAccess.userId;
            const ciamUpdatePayload = await ssoPayloadCreation(userId, payload, userId);
            const ciamUpdate = await ssoUserUpdate(ciamUpdatePayload);
            if (ciamUpdate) {
                return ciamUpdate;
            } else {
                return false;
            }
        }
    } catch (error) {
        return error;
    }
};

const ssoPayloadCreation = async (login, payload, userId = false) => {
    const appData = await getSSOappAccess();
    let ciamPayload = {
        firstName: payload.firstName,
        middleName: payload.middleName,
        lastName: payload.lastName,
        email: payload.businessEmail,
        appAccess: {
            appsAdd: [appData],
            appsRemove: [],
        },
    };
    if (userId) {
        ciamPayload = {
            ...ciamPayload,
            userId: userId,
        };
    } else {
        ciamPayload = {
            ...ciamPayload,
            login: login,
        };
    }
    return ciamPayload;
};

const getSSOappAccess = async () => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-appSettings");
    traceStart();
    const q = query(app_setting_collection);

    const snapshot = await getDocs(q);
    let ciamSetting;
    snapshot.forEach((e) => {
        if (e.id === "CIAM") {
            const tempData = e.data();
            ciamSetting = tempData?.envMap;
        }
    });
    let settingString = "";
    if (process.env.REACT_APP_ENV !== "sandbox") {
        settingString = `sb-${process.env.REACT_APP_CF_REGION}`;
    } else {
        settingString = `sb-${process.env.REACT_APP_CF_ENV}-${process.env.REACT_APP_CF_REGION}`;
    }
    const ciamAppSetting = {
        tenantId: parseInt(ciamSetting[settingString].TenantId ? ciamSetting[settingString].TenantId : 0),
        tenantAppId: parseInt(ciamSetting[settingString].TenantAppId ? ciamSetting[settingString].TenantAppId : 0),
    };
    traceStop();
    return ciamAppSetting;
};

export const ssoGetUser = async (login, ciamPayload = null) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getUserById);
    traceStart();
    const headers = constructFbHeaders();
    const getUser = process.env.REACT_APP_CF_URL_CIAM + "/user/getbyid";
    try {
        const body = {
            userId: login,
        };
        const ciamUser = await invokeApi("POST", getUser, body, {}, headers);
        if (!ciamUser.isSuccess && ciamUser?.error?.errorCode === CIAM_USER_NOT_FOUND) {
            // this scenrior happened very realy!
            if (ciamPayload) {
                const createSSO = await ssoCreateUser(ciamPayload);
                return createSSO ? false : new Error("system error accured!");
            }
            return false;
        } else {
            const appData = await getSSOappAccess();
            if (ciamUser?.result?.appAccess) {
                const appList = ciamUser?.result?.appAccess?.apps ? ciamUser.result.appAccess.apps : [];

                const appHasAccess = appList.find(
                    (e) => e.tenantAppId === parseInt(appData.tenantAppId) && e.tenantId === parseInt(appData.tenantId)
                );
                if (appHasAccess) {
                    return {
                        hasAppAccess: true,
                        userId: ciamUser.result.id,
                    };
                } else {
                    return {
                        hasAppAccess: false,
                        userId: ciamUser.result.id,
                    };
                }
            }
        }
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

const ssoCreateUser = async (body) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createUser);
    traceStart();
    const headers = constructFbHeaders();
    const getUser = process.env.REACT_APP_CF_URL_CIAM + "/user/add";
    try {
        const ciamUser = await invokeApi("POST", getUser, body, {}, headers);
        if (!ciamUser.isSuccess && ciamUser?.error?.errorCode === CIAM_USER_EXIST) {
            return false;
        } else {
            return true;
        }
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

const ssoUserUpdate = async (body) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateUser);
    traceStart();
    const headers = constructFbHeaders();
    const getUser = process.env.REACT_APP_CF_URL_CIAM + "/user/update";
    try {
        const ciamUser = await invokeApi("POST", getUser, body, {}, headers);
        if (!ciamUser.isSuccess && ciamUser?.error?.errorCode === CIAM_USER_NOT_FOUND) {
            // unlikly happen
            return false;
        } else {
            return true;
        }
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
