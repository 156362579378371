export const styles = {
    typography: {
        color: "#000000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%" /* 41.99px */,
        letterSpacing: "0.15px",
    },
    accordion: { borderRadius: "8px", marginBottom: "20px" },
    headerTxt: {
        fontWeight: "400",
        fontSize: "24px",
        marginBottom: "5px",
    },
};
