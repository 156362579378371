const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        p: 3,
        width: "100%",
        textAlign: "left",
    },
    header: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%" /* 41.99px */,
        letterSpacing: "0.25px",
        mb: "16px",
    },
    sectionTitle: {
        my: 2,
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    textFieldContainer: {
        display: "flex",
        gap: 2,
        my: 3,
    },
    footNotes: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    formControlLabel: {
        paddingBottom: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        my: 2,
    },
    card: {
        width: "100%",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    dataGrid: {
        height: "auto",
        width: "100%",
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "background.default",
            color: "black",
        },
    },
    noRowsOverlay: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        mb: 3,
        height: "35px",
    },
    recordStart: {
        justifyContent: "flex-start",
    },
    recordEnd: {
        justifyContent: "flex-end",
    },
    switchDescription: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    flexRow50: {
        display: "flex",
        gap: 2,
        width: "50%",
        alignItems: "center",
    },
    fullHeight: {
        minHeight: `calc(100vh - 310px)`,
    },
    statusRecord: {
        fontWeight: 500,
        fontSize: "14px",
    },
    footerBtnSection: {
        p: 2,
        display: "flex",
        justifyContent: "left",
    },
    marginBottomBreak: {
        mb: "16px",
    },
    errorMsgs: {
        fontWeight: 500,
        fontSize: 14,
    },
    infoSections: {
        fontWeight: 500,
        mb: "16px",
    },
    fontWeight500: {
        fontWeight: 500,
    },
};

export default styles;
