import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../VaultStatusReportAccordionSection";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getSearchMediaDiscrepancy, vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { getAllCaps, systemFunction } from "src/constants/researchConstants";
import { mediaOptionsDCValue } from "src/constants/discrepancyConstants";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const ContainerDiscrepancySummary = ({ rows = [], detectedInDataList = [], dateRange, viewPermission = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(rows.length > 0);
    }, [rows]);

    const columns = [
        {
            field: "action",
            headerName: "",
            sortable: true,
            renderCell: (params) => {
                return (
                    viewPermission && (
                        <Box display={"flex"} gap={2} alignItems={"center"}>
                            <IconButton onClick={() => redirectToDiscrepancySearch(params)}>
                                <RemoveRedEyeFilled fill={theme.palette.im_mid_blue["500"]} />
                            </IconButton>
                        </Box>
                    )
                );
            },
        },
        {
            field: "customer_number",
            headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
            sortable: true,
        },
        {
            field: "customer_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.name")),
            sortable: true,
            type: "string",
        },
        {
            field: "detectedIn",
            headerName: convertToLocaleUpperCase(t("tableHeader.detectedIn")),
            sortable: true,
            renderCell: (params) => {
                const detectedInValue = detectedInDataList.filter(
                    (data) => parseInt(params.detected_in_sf_id) === data.id && data.idcontext === systemFunction
                );
                return detectedInValue[0].translatedName;
            },
        },
        {
            field: "logical_vault_code",
            headerName: convertToLocaleUpperCase(t("tableHeader.logicalVault")),
            sortable: true,
        },
        {
            field: "unexpected_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.unexpected")),
            sortable: true,
        },
        {
            field: "missing_naim_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.notAtIRM")),
            sortable: true,
        },
        {
            field: "no_return_date_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noValidReturnDate")),
            sortable: true,
        },
        {
            field: "incomplete_stop_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.incompleteStop")),
            sortable: true,
        },
        {
            field: "unresolved_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfUnresolvedDiscrepancy")),
            sortable: true,
        },
    ];

    const redirectToDiscrepancySearch = (params) => {
        const detectedInValue = detectedInDataList.filter(
            (data) => parseInt(params.detected_in_sf_id) === data.id && data.idcontext === systemFunction
        );
        const url = getSearchMediaDiscrepancy({
            customerId: params.customer_id,
            fileSectionTypeId: params.detected_in_sf_id,
            districtId: params.district_id,
            detectedDateFrom: dateRange.fromDate,
            detectedDateTo: dateRange.toDate,
            logicalVaultCode: params.logical_vault_code.trim() ? params.logical_vault_code : "-1",
            detectedInFormattedName: detectedInValue[0]?.translatedName,
            mediaProgramTypeId: mediaOptionsDCValue.container,
            discrepancyStatus: getAllCaps,
        });

        navigate(url);
    };

    const onTableStateChange = ({ rowsPerPage, pageNumber, order, orderBy }) => {
        const locationState = location.state || {};
        navigate(vaultStatusReport, {
            state: {
                ...locationState,
                containerDiscrepancySummaryPageNumber: pageNumber,
                containerDiscrepancySummaryRowsPerPage: rowsPerPage,
                containerDiscrepancySummaryOrderBy: orderBy,
                containerDiscrepancySummaryOrder: order,
            },
            replace: true,
        });
    };

    return (
        <Accordion sx={styles.accord} expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="authorization-verification-content"
                id="authorization-verification"
                onClick={() => setExpanded(!expanded)}
            >
                <Typography variant="body1" sx={styles.headerTypography}>
                    <Trans i18nKey={"titles.containerDiscrepancySummary"} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <>
                    <Typography sx={styles.descriptionSection}>
                        <Trans i18nKey={"vaultStatusReport.discrepancySectionDescription"} />
                    </Typography>
                    <Box>
                        <Box style={styles.tableBox}>
                            <Paper>
                                <SecureSyncTable
                                    propStyles={styles.dataGrid}
                                    rows={rows}
                                    columns={columns}
                                    noDataMessage={t("messages.noRecordsFound")}
                                    getRowId={(row) => row.customer_id}
                                    sx={styles.dataGrid}
                                    initialPage={location.state?.containerDiscrepancySummaryPageNumber}
                                    initialRowsPerPage={location.state?.containerDiscrepancySummaryRowsPerPage}
                                    initialOrderBy={location.state?.containerDiscrepancySummaryOrderBy}
                                    initialOrder={location.state?.containerDiscrepancySummaryOrder}
                                    onTableAttributesChange={onTableStateChange}
                                />
                            </Paper>
                        </Box>
                    </Box>
                </>
            </AccordionDetails>
        </Accordion>
    );
};

export default ContainerDiscrepancySummary;
