import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./RescheduleService.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import {
    getEligibleCustomerSchedulesService,
    getServiceDateForCustomerService,
    isValidCustomerOnCallService,
    scheduleRequestsAndManageTransportsService,
} from "src/services/requestServices";
import { formatDate, getDisplayFormat, getServiceDateRange, serviceDateMapper } from "src/utils/dateHelper";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import MultipleCLSConfirmation from "src/components/Request/ConfirmationModals/MultipleCLSConfirmation/MultipleCLSConfirmation";
import { multipleSort } from "src/utils/processMediaHelper";
import { onCallConstants, requestInitialData, requestSortCriteria } from "src/constants/requestConstants";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { createCslList, getAddOnCutOff, getCslByServiceDate, getServiceFrequency } from "src/utils/requestHelper";
import { getBasicUserInfo } from "src/redux/userDetailsSlice";
import ServiceDateCalendarTooltip from "src/components/AppComponents/ServiceDateCalendarTooltip/ServiceDateCalendarTooltip";
import { useGetAttrsBasedOnAddCutOffValues, useSecureSyncHotKeys } from "src/customHooks";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t, serviceDates, minDate = formatDate(dayjs()) }) => {
    return Yup.object().shape({
        originalDate: Yup.string().test("validateOriginalDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value).isValid();
            const formattedDate = formatDate(value, dateFormats.timeStamp, false, false);
            const isServiceDate = serviceDates.find((serviceDate) =>
                serviceDate.isSame(formattedDate, dateTimeUnits.day)
            );
            let message = "";
            if (!isValidDate) {
                message = t("messages.invalidDate");
            } else if (!isServiceDate) {
                message = t("messages.originalDateShouldScheduleService");
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        newDate: Yup.string().test("validateNewDate", (value, { createError, path, parent }) => {
            const formattedValues = formatDate(dayjs(value));
            const isValidDate = dayjs(value).isValid();
            const isSameDate = dayjs(value).isSame(parent.originalDate);
            const isBeforeMinDate = dayjs(formattedValues).isBefore(minDate);
            let message = "";
            if (!isValidDate) {
                message = t("messages.invalidDate");
            } else if (isBeforeMinDate) {
                message = t("messages.beforeMinDate");
            } else if (isSameDate) {
                message = t("messages.newDateAndOriginalDateSame");
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const RescheduleService = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [serviceDates, setServiceDates] = useState([]);
    const [runDetails, setRunDetails] = useState(requestInitialData.initialRunDetails);
    const [servicePeriod, setServicePeriod] = useState(requestInitialData.initialServicePeriod);
    const [onCallStatus, setOnCallStatus] = useState(false);
    const [warningAlertState, setWarningAlertState] = useState(false);
    const [cslConfirmationModal, setCSLConfirmationModal] = useState(requestInitialData.initialCslConfirmation);
    const [snackbarProps, setSnackbarProps] = useState(requestInitialData.initialSnackbarProps);
    const [disableBtn, setDisableBtn] = useState(false);
    const [cslList, setCslList] = useState([]);
    const { fromDate, toDate } = getServiceDateRange();
    const { username } = useSelector(getBasicUserInfo);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => resetHandler() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    useEffect(() => {
        const fetchAddOnCutOff = async () => {
            const getAddOnCutOffResponse = await getAddOnCutOff();
            setRunDetails(getAddOnCutOffResponse);
        };
        const fetchValidCustomerOnCall = async () => {
            const customerOnCallResponse = await isValidCustomerOnCallService();
            if (!customerOnCallResponse?.result[0]?.hasError) {
                const onCall = customerOnCallResponse?.result[0]?.on_call;
                setOnCallStatus(onCall === onCallConstants.onCall);
            }
        };
        const fetchGetServiceDate = async () => {
            const response = await fetchServiceDate({ addOnCutoff: "", reschedule: noFlag });
            if (!response.failure) {
                if (response?.resultSet0?.length > 0) {
                    const cslList = createCslList(response?.resultSet0);
                    const serviceDateList = serviceDateMapper(response?.resultSet0);
                    setServiceDates(serviceDateList);
                    setCslList(cslList);
                    setFieldValue("originalDate", serviceDateList[0]);
                }
            }
        };
        const fetchCustomerServiceFrequency = async () => {
            const getServiceFrequencyResponse = await getServiceFrequency();
            setServicePeriod(getServiceFrequencyResponse);
        };

        const fetchData = async () => {
            if (!disableBtn) {
                setLoading(true);
                await Promise.all([
                    fetchAddOnCutOff(),
                    fetchValidCustomerOnCall(),
                    fetchGetServiceDate(),
                    fetchCustomerServiceFrequency(),
                ]);
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableBtn]);

    const { addDays, addOnCutOffToday, addOnCutOffTomorrow } = useGetAttrsBasedOnAddCutOffValues(
        runDetails,
        onCallStatus
    );

    const infoMessage = useMemo(() => {
        let message = "";
        if (!isLoading) {
            if (serviceDates.length === 0) {
                message = t("messages.noNextServiceDate");
            } else if (!servicePeriod.customerHasSchedule) {
                message = t("messages.noScheduleDefine");
            }
        }
        return message;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, serviceDates.length, servicePeriod.customerHasSchedule]);

    const checkMultipleCSLForServiceDate = () => {
        const cslArr = getCslByServiceDate(cslList, values.newDate);
        if (cslArr && cslArr.length > 1) {
            setCSLConfirmationModal({ show: true, csl: cslArr[0] });
        } else {
            onRescheduleService(values);
        }
    };

    const onRescheduleService = async (values) => {
        setLoading(true);
        setDisableBtn(true);
        const isNewDateIsToday = dayjs(values.newDate).isToday();
        const isNewDateIsAServiceDate = serviceDates.find((serviceDate) => serviceDate.isSame(values.newDate));
        if (isNewDateIsToday && !isNewDateIsAServiceDate) {
            setWarningAlertState(true);
        } else {
            //Reschedule Request - Get route customer schedule id
            let routeCustomerScheduleID = -1;
            const formattedNewDate = formatDate(values.newDate, dateFormats.timeStamp);
            const formattedOriginalDate = formatDate(values.originalDate, dateFormats.timeStamp);
            const getEligibleCustomerSchedule = await getEligibleCustomerSchedulesService({
                rescheduleDate: formattedNewDate,
            });

            if (!getEligibleCustomerSchedule.failure) {
                if (getEligibleCustomerSchedule.result.length > 0) {
                    routeCustomerScheduleID = getEligibleCustomerSchedule?.result[0]?.Route_Customer_Schedule_Id;
                }
            }

            //Get the request_id from the default request for the reschedule date
            let parentRequestID = -1;
            const getServiceDateList = await fetchServiceDate({
                newDate: formattedNewDate,
                addOnCutoff: noFlag,
                reschedule: "",
            });
            if (!getServiceDateList.failure) {
                if (getServiceDateList?.resultSet0?.length > 0) {
                    const sortedServiceDateList = multipleSort(
                        getServiceDateList.resultSet0,
                        requestSortCriteria.serviceDatesServiceDateSort
                    );
                    parentRequestID = sortedServiceDateList[0]?.request_id;
                }
            }

            //call reschedule service
            const requestRescheduleResponse = await scheduleRequestsAndManageTransportsService({
                oldServiceDate: formattedOriginalDate,
                newServiceDate: formattedNewDate,
                employeeId: "",
                holidayFlag: yesFlag,
                cslNewRcsId: routeCustomerScheduleID === -1 ? "" : routeCustomerScheduleID,
                cslNewRequestId: parentRequestID === -1 ? "" : parentRequestID,
                lastModUser: username,
                rescheduledToText: t("rescheduleService.rescheduledToText"),
                rescheduledFromText: t("rescheduleService.rescheduledFromText"),
            });

            //Handel FailureCode - snackbar
            if (requestRescheduleResponse?.error[0]?.hasError) {
                setSnackbarProps({
                    show: true,
                    severity: "error",
                    message: requestRescheduleResponse?.error[0]?.displayMessage,
                });
            } else {
                const messageKey = isNewDateIsAServiceDate ? "newDateIsServiceDate" : "other";
                setSnackbarProps({
                    show: true,
                    severity: "success",
                    message: (
                        <Trans
                            i18nKey={`messages.requestRescheduleSuccess.${messageKey}`}
                            components={{ br: <br /> }}
                            values={{
                                originalDate: formatDate(values.originalDate, getDisplayFormat()),
                                newDate: formatDate(values.newDate, getDisplayFormat()),
                            }}
                        />
                    ),
                });
                resetForm();
            }
        }
        setDisableBtn(false);
        setLoading(false);
    };

    const fetchServiceDate = async ({ newDate, reschedule, addOnCutoff }) => {
        const response = await getServiceDateForCustomerService({
            fromDate: newDate ? newDate : fromDate,
            toDate: newDate ? newDate : toDate,
            rescheduleFlag: reschedule,
            addonCutoffFlag: addOnCutoff,
        });
        return response;
    };
    const { values, errors, touched, handleSubmit, setFieldValue, resetForm, setFieldTouched } = useFormik({
        initialValues: {
            originalDate: null,
            newDate: null,
        },
        validationSchema: validationSchema({ t, serviceDates }),
        onSubmit: checkMultipleCSLForServiceDate,
    });

    const resetHandler = () => {
        resetForm();
        setFieldValue("originalDate", serviceDates[0]);
    };

    const handleCloseSnackbar = () => {
        setSnackbarProps(requestInitialData.initialSnackbarProps);
    };

    const onCancel = () => {
        navigate(dashboard);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("rescheduleService.requestReschedule")}</PageTitle>
                        <SecureSyncSnackBar
                            openSnackBar={snackbarProps.show}
                            message={snackbarProps.message}
                            severity={snackbarProps.severity}
                            onClose={handleCloseSnackbar}
                        />
                        {warningAlertState && (
                            <SecureSyncAlert
                                severity={"warning"}
                                message={
                                    <Box sx={styles.fontWeight500}>{t("messages.newDateIsTodayAndNotAServiceDay")}</Box>
                                }
                                onClose={() => setWarningAlertState(false)}
                            />
                        )}
                        {infoMessage ? (
                            <SecureSyncAlert message={<Box sx={styles.fontWeight500}>{infoMessage}</Box>} />
                        ) : (
                            <>
                                <Typography sx={styles.infoText}>
                                    <Trans
                                        i18nKey={"rescheduleService.selectServiceDates"}
                                        values={{ buttonLabel: t("buttonLabels.submit") }}
                                    />
                                </Typography>
                                <Divider />
                                <PageSubTitle>{t("rescheduleService.deliveryInformation")}</PageSubTitle>
                                <form onSubmit={handleSubmit} noValidate>
                                    <Box sx={styles.searchFields}>
                                        <SecureSyncDatePicker
                                            value={values.originalDate}
                                            required
                                            label={t("rescheduleService.originalDate")}
                                            disablePast
                                            getDateProp={(dateProp) => {
                                                const isServiceDate = serviceDates.find((serviceDate) =>
                                                    serviceDate.isSame(dateProp, dateTimeUnits.day)
                                                );
                                                return {
                                                    disabled: !isServiceDate,
                                                    highlight: Boolean(isServiceDate),
                                                };
                                            }}
                                            helperText={touched.originalDate && errors.originalDate}
                                            error={Boolean(touched.originalDate && errors.originalDate)}
                                            onChangeRaw={async (value) => {
                                                await setFieldValue("originalDate", value);
                                                setFieldTouched("originalDate", true, true);
                                            }}
                                        />
                                        <SecureSyncDatePicker
                                            value={values.newDate}
                                            required
                                            label={t("rescheduleService.newDate")}
                                            minDate={dayjs().add(addDays, dateTimeUnits.day)}
                                            getDateProp={(dateProp) => {
                                                let datePropObj = {};
                                                const resultArray = [addOnCutOffToday, addOnCutOffTomorrow].filter(
                                                    (value) => value !== null && value !== undefined
                                                );
                                                const isCutoffDate = resultArray.find((serviceDate) =>
                                                    serviceDate.isSame(dateProp, dateTimeUnits.day)
                                                );
                                                if (isCutoffDate) {
                                                    datePropObj = {
                                                        disabled: Boolean(isCutoffDate),
                                                        customStyle: styles.cutOffDates,
                                                    };
                                                } else {
                                                    const isServiceDate = serviceDates.find((serviceDate) =>
                                                        serviceDate.isSame(dateProp, dateTimeUnits.day)
                                                    );
                                                    datePropObj = { highlight: isServiceDate };
                                                }
                                                return datePropObj;
                                            }}
                                            helperText={touched.newDate && errors.newDate}
                                            error={Boolean(touched.newDate && errors.newDate)}
                                            onChangeRaw={async (value) => {
                                                await setFieldValue("newDate", value);
                                                setFieldTouched("newDate", true, true);
                                            }}
                                        />
                                        <Box sx={styles.tooltipSection}>
                                            <ServiceDateCalendarTooltip />
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box sx={styles.buttonSection}>
                                        <Button variant="outlined" disabled={disableBtn} onClick={onCancel}>
                                            {t("buttonLabels.cancel")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            type="reset"
                                            onClick={resetHandler}
                                            disabled={disableBtn}
                                            title={getKeyBoardLabel(shortCutKeys.reset)}
                                        >
                                            {t("buttonLabels.reset")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={disableBtn}
                                            title={getKeyBoardLabel(shortCutKeys.submit)}
                                        >
                                            {t("buttonLabels.submit")}
                                        </Button>
                                    </Box>
                                </form>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
            <MultipleCLSConfirmation
                open={cslConfirmationModal.show}
                onClose={() => setCSLConfirmationModal(requestInitialData.initialCslConfirmation)}
                customerLocation={cslConfirmationModal.csl}
                handleConfirm={() => onRescheduleService(values)}
            />
        </>
    );
};

export default RescheduleService;
