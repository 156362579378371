import { Box, Button, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useEffect } from "react";
import styles from "./EditModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { yesFlag } from "src/constants/serviceConstants";

const initialValues = {
    retentionProfile: "",
    description: "",
};

const EditModal = ({ open, onClose, updateRecord, record, retentionProfiles, noProfiles }) => {
    const { t } = useTranslation();
    const { errors, handleBlur, handleChange, touched, values, setValues, setFieldValue } = useFormik({
        initialValues: initialValues,
    });

    useEffect(() => {
        setValues({
            retentionProfile: record.Retention_Profile_Id === "" ? "no profile" : record.Retention_Profile_Id,
            description: record.Descr,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, retentionProfiles]);

    const changeRetentionProfile = (e) => {
        const retentionProfile = e.target.value;
        const selectedRetentionProfile = retentionProfiles.find((item) => item?.id === parseInt(retentionProfile));
        if (retentionProfile !== "1" && selectedRetentionProfile?.overwriteFlag === yesFlag) {
            setFieldValue("description", selectedRetentionProfile?.mediaDescr);
        }
        handleChange(e);
    };

    const handleSubmit = () => {
        const payload = {
            ...values,
            retentionProfile: noProfiles ? "" : values.retentionProfile,
        };
        updateRecord(record, payload);
    };
    return (
        <SecureSyncModal
            open={open}
            title={`${t("containerSearch.editContainerModal")}`}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        {t("buttonLabels.cancel")}
                    </Button>
                    <Button autoFocus variant="contained" onClick={handleSubmit} color="primary">
                        {t("buttonLabels.update")}
                    </Button>
                </Box>
            }
        >
            <Box sx={styles.formContainer}>
                <form>
                    <Box sx={styles.formItem}>
                        <SecureSyncSelect
                            showBlankOption={!noProfiles}
                            disabled={!record.isProfileEditable}
                            label={t("formFields.retentionProfile")}
                            options={retentionProfiles}
                            name="retentionProfile"
                            value={values.retentionProfile}
                            onChange={changeRetentionProfile}
                        />
                    </Box>
                    <Box sx={styles.formItem}>
                        <TextField
                            id="description"
                            name="description"
                            label={t("downloadInventory.descriptionPhrase")}
                            variant="outlined"
                            multiline
                            fullWidth
                            disabled={!record.isDescriptionEditable}
                            value={values.description ? values.description : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 1024 }}
                            error={touched.description && Boolean(errors.description)}
                            helperText={touched.description && errors.description}
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            rows={8}
                        />
                    </Box>
                    <Typography sx={styles.helpText}>
                        <Trans i18nKey={"containerSearch.descrHelp"}></Trans>
                    </Typography>
                </form>
            </Box>
        </SecureSyncModal>
    );
};

export default EditModal;
