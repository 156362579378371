import { Divider, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import dayjs from "dayjs";
import UserListSection from "../UserListSection/UserListSection";
import MiscServicesActionSection from "../MiscServicesActionSection/MiscServicesActionSection";
import ContactSection from "../ContactSection/ContactSection";
import ServiceSection from "../ServiceSection/ServiceSection";
import NewCustomerSection from "../NewCustomerSection/NewCustomerSection";
import CommentSection from "../CommentSection/CommentSection";
import DescriptionSection from "../DescriptionSection/DescriptionSection";
import styles from "./MiscServicesForm.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getUserPreferences } from "src/redux/userDetailsSlice";
import { handleMiscSubmit, validatePhoneFormat } from "src/services/requestServices";
import { formatDate, getDisplayFormat, getMinAndMaxDate } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";
import { getCountryList } from "src/redux/applicationSlice";
import { serviceType } from "src/constants/miscConstants";
import { getPersonalName } from "src/services/utils";

const validationSchema = (t) => {
    const { maxDateForApi: maxDate, displayMaxDate } = getMinAndMaxDate();
    const toDay = dayjs().subtract(1, "days").format(dateFormats.requestServiceDateFormat);
    return Yup.object().shape({
        serviceLocation: Yup.string().required(t("miscService.serviceTypeValidation")),
        requestorPhoneNumber: Yup.string().required(t("miscService.requestorPhoneValidation")),
        requestorEmail: Yup.string()
            .required(t("miscService.emailRequired"))
            .matches(/^\S+@\S+\.\S+$/, t("miscService.emailNotValid")),
        ext: Yup.string().matches(/^[0-9]*$/, t("miscService.extInvalid")),
        dataCenterExt: Yup.string().matches(/^[0-9]*$/, t("miscService.dataCenterExtNotValid")),
        requestedCompletionDate: Yup.string().when("serviceLocation", {
            is: (serviceLocation) =>
                serviceLocation && [serviceType.newCustomer, serviceType.libraryAudit].includes(serviceLocation),
            then: () =>
                Yup.string().test("requestedCompletionDate", (value, { createError, path, parent }) => {
                    const isValidDate = dayjs(value).isValid();
                    const isOutSideMaxDate = dayjs(value).isAfter(maxDate);
                    const isOutSideMinDate = dayjs(value).isBefore(toDay);
                    let message = "";
                    if (value) {
                        if (!isValidDate) {
                            message = t("miscService.completeDateRequired");
                        } else if (isOutSideMaxDate || isOutSideMinDate) {
                            message = t("miscService.invalidRequestedDate", {
                                minDate: dayjs(toDay).format(dateFormats.sortDate),
                                maxDate: displayMaxDate,
                            });
                        }
                    } else {
                        message = t("miscService.completeDateRequired");
                    }
                    if (message) {
                        return createError({
                            path,
                            message,
                        });
                    } else {
                        return true;
                    }
                }),
            otherwise: () => Yup.string().notRequired(),
        }),
        newCustomerName: Yup.string().when("serviceLocation", {
            is: (serviceLocation) => serviceLocation && [serviceType.newCustomer].includes(serviceLocation),
            then: () => Yup.string().required(t("miscService.requestorCustomerValidation")),
            otherwise: () => Yup.string().notRequired(),
        }),
        selectedUser: Yup.string().when("serviceLocation", {
            is: (serviceLocation) => serviceLocation && [serviceType.authCardReplacement].includes(serviceLocation),
            then: () => Yup.string().required(t("miscService.userIsRequired")),
            otherwise: () => Yup.string().notRequired(),
        }),
        description: Yup.string().when("serviceLocation", {
            is: (serviceLocation) => serviceLocation && [serviceType.bidService].includes(serviceLocation),
            then: () => Yup.string().required(t("miscService.descrIsRequired")),
            otherwise: () => Yup.string().notRequired(),
        }),
    });
};

const formIntValues = {
    serviceLocation: serviceType.default,
    requestorEmail: "",
    requestorPhoneNumber: "",
    ext: "",
    requestedCompletionDate: "",
    newCustomerName: "",
    newCustomerIsSubAccount: false,
    parentAccountNumber: "",
    dataCenterContactName: "",
    dataCenterContactPhone: "",
    dataCenterExt: "",
    comments: "",
    description: "",
    selectedUser: "",
};

const MiscServicesForm = (props) => {
    const customerPreferences = useSelector(getUserPreferences);
    const countryList = useSelector(getCountryList);

    const [customValidations, setCustomValidations] = useState({
        requestorPhone: { validate: true, message: "" },
        dataCenterPhone: { validate: true, message: "" },
    });

    const { t } = useTranslation();
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: formIntValues,
        validationSchema: validationSchema(t),
        onSubmit: async () => {
            await submitForm();
        },
    });

    const submitForm = async () => {
        const [validateRequestorPhone, validateDataCenterPhone] = await Promise.all([
            validatePhoneNumber("requestorPhone", values.requestorPhoneNumber, "miscService.requestorPhoneInvalid"),
            validatePhoneNumber("dataCenterPhone", values.dataCenterContactPhone, "miscService.dataCenterPhoneInvalid"),
        ]);
        if (!validateRequestorPhone || !validateDataCenterPhone) {
            return;
        }
        const dateFormat = getDisplayFormat();
        const res = await handleMiscSubmit(
            {
                ...values,
                requestedCompletionDate: values.requestedCompletionDate
                    ? formatDate(values.requestedCompletionDate, dateFormat)
                    : "",
                requestedCompletionDateMail: values.requestedCompletionDate
                    ? formatDate(values.requestedCompletionDate, dateFormats.mistServiceDateOnly)
                    : "",
            },
            props?.initValues?.personalInfo,
            getPersonalName(),
            userList
        );
        props.setFormSubmit();
        props.setValues(res);
        setInitValues();
    };

    useEffect(() => {
        if (!values.newCustomerIsSubAccount) {
            setValues({
                ...values,
                parentAccountNumber: "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.newCustomerIsSubAccount]);

    useEffect(() => {
        setInitValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.initValues?.personalInfo]);

    const validatePhoneNumber = async (targetField, phoneNumber, label) => {
        const personalLocaleId = customerPreferences?.data?.personnel[0]?.Customer_Personnel_Locale_Id;
        let selectedCountry;
        if (countryList && countryList.length > 0) {
            selectedCountry = countryList.find((e) => e.locale_id === personalLocaleId);
        }
        let isValid = true;
        let msg = "";
        if (phoneNumber !== "") {
            const res = await validatePhoneFormat(phoneNumber, selectedCountry);
            if (!res.isValid) {
                isValid = false;
                msg = t(label, {
                    msg: res?.formatExample?.format_example,
                });
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            [targetField]: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const setInitValues = async () => {
        await resetForm();

        // eslint-disable-next-line camelcase
        const { business_email, business_phone, business_phone_ext } = props?.initValues?.personalInfo || {};
        if (props?.initValues?.personalInfo) {
            setValues({
                requestedCompletionDate: "",
                newCustomerName: "",
                newCustomerIsSubAccount: false,
                parentAccountNumber: "",
                dataCenterContactName: "",
                dataCenterContactPhone: "",
                dataCenterExt: "",
                comments: "",
                description: "",
                selectedUser: "",
                serviceLocation: serviceType.default,
                // eslint-disable-next-line camelcase
                requestorEmail: business_email
                    ? // eslint-disable-next-line camelcase
                      business_email
                    : "",
                // eslint-disable-next-line camelcase
                requestorPhoneNumber: business_phone
                    ? // eslint-disable-next-line camelcase
                      business_phone
                    : "",
                // eslint-disable-next-line camelcase
                ext: business_phone_ext
                    ? // eslint-disable-next-line camelcase
                      business_phone_ext
                    : "",
            });
        }
    };

    const serviceLocations = useMemo(() => {
        return props?.initValues?.dropDownValues ? props.initValues.dropDownValues : [];
    }, [props?.initValues?.dropDownValues]);

    const userList = useMemo(() => {
        return props?.initValues?.userList ? props.initValues.userList : [];
    }, [props?.initValues?.userList]);

    const isPageValid = useMemo(() => {
        // eslint-disable-next-line camelcase
        const { branch_mgr_email, acct_mgr_email, personnel_created_email } = props?.initValues?.personalInfo || {};
        switch (values.serviceLocation) {
            case serviceType.libraryAudit:
                // eslint-disable-next-line camelcase
                return branch_mgr_email;
            case serviceType.newCustomer:
                // eslint-disable-next-line camelcase
                return acct_mgr_email;
            case serviceType.authCardReplacement:
                // eslint-disable-next-line camelcase
                return personnel_created_email;
            case serviceType.bidService:
                // eslint-disable-next-line camelcase
                return acct_mgr_email;
            default:
                break;
        }
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.serviceLocation]);
    return (
        <>
            <CircularLoaderFullPage show={isSubmitting} />
            <form noValidate onSubmit={handleSubmit}>
                <ServiceSection
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    serviceLocations={serviceLocations}
                />
                {isPageValid ? (
                    [
                        serviceType.newCustomer,
                        serviceType.libraryAudit,
                        serviceType.authCardReplacement,
                        serviceType.bidService,
                    ].includes(values.serviceLocation) && (
                        <>
                            <Divider />
                            <ContactSection
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                customValidations={customValidations}
                            />
                            {serviceType.authCardReplacement === values.serviceLocation && (
                                <UserListSection
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    userList={userList}
                                />
                            )}
                            {serviceType.newCustomer === values.serviceLocation && (
                                <NewCustomerSection
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    customValidations={customValidations}
                                />
                            )}
                            {[
                                serviceType.newCustomer,
                                serviceType.libraryAudit,
                                serviceType.authCardReplacement,
                            ].includes(values.serviceLocation) && (
                                <CommentSection
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                />
                            )}

                            {serviceType.bidService === values.serviceLocation && (
                                <DescriptionSection
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                />
                            )}
                            <Divider />
                            <MiscServicesActionSection resetForm={setInitValues} handleSubmit={handleSubmit} />
                        </>
                    )
                ) : (
                    <Typography variant="body1" sx={styles.typographyBody}>
                        {!isPageValid && <Trans i18nKey={"miscService.pageInvalid"} />}
                    </Typography>
                )}
            </form>
        </>
    );
};

export default MiscServicesForm;
