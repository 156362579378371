import { programIds, menuProgramIdsGroup } from "src/constants/programIdsConstants";

export const getAccessRightsObject = (userProgramLevels = []) => {
    const isProgramLevelAvailable = (programLevels, programLevel) => {
        return programLevels.includes(programLevel);
    };

    const isPageHasAccess = (pageProgramIDs) => {
        if (Array.isArray(pageProgramIDs)) {
            const foundProgramLevel = userProgramLevels?.find((programLevel) => {
                return isProgramLevelAvailable(pageProgramIDs, programLevel);
            });

            return Boolean(foundProgramLevel);
        }
        return isProgramLevelAvailable(userProgramLevels, pageProgramIDs);
    };

    const isMenuHasAccess = (menuProgramLevels) => {
        const foundProgramLevel = userProgramLevels?.find((programLevel) => {
            return isProgramLevelAvailable(menuProgramLevels, programLevel);
        });

        return Boolean(foundProgramLevel);
    };

    return {
        menu: {
            processMedia: isMenuHasAccess(menuProgramIdsGroup.processMedia),
            authorization: isMenuHasAccess(menuProgramIdsGroup.authorization),
            admin: isMenuHasAccess(menuProgramIdsGroup.admin),
            request: isMenuHasAccess(menuProgramIdsGroup.request),
            research: isMenuHasAccess(menuProgramIdsGroup.research),
            disasterRecovery: isMenuHasAccess(menuProgramIdsGroup.disasterRecovery),
        },
        page: {
            downloadInventory: isPageHasAccess(programIds.programId_211),
            containerPickup: isPageHasAccess(programIds.programId_214),
            oMDistributionList: isPageHasAccess(programIds.programId_213),
            scanVerification: isPageHasAccess(programIds.programId_215),
            sendListFile: isPageHasAccess(programIds.programId_230),
            searchUsers: isPageHasAccess([programIds.programId_100, programIds.programId_101]),
            addUsers: isPageHasAccess(programIds.programId_108),
            comments: isPageHasAccess([programIds.programId_105, programIds.programId_106]),
            accountStatus: isPageHasAccess(programIds.programId_107),
            authorizationReport: isPageHasAccess(programIds.programId_117),
            authorizationForm: isPageHasAccess(programIds.programId_128),
            userVerification: isPageHasAccess(programIds.programId_219),
            securityVerificationReport: isPageHasAccess(programIds.programId_220),
            searchRequestsDetail: isPageHasAccess(programIds.programId_147),
            searchMediaDestructionRequests: isPageHasAccess(programIds.programId_292),
            deliverMedia: isPageHasAccess(programIds.programId_149),
            other: isPageHasAccess(programIds.programId_146),
            newContainerLockInsert: isPageHasAccess(programIds.programId_150),
            newOrEmptyTransport: isPageHasAccess(programIds.programId_151),
            requestReschedule: isPageHasAccess(programIds.programId_145),
            miscServices: isPageHasAccess(programIds.programId_207),
            completedLibraryAudits: isPageHasAccess(programIds.programId_208),
            correctiveActionRequest: isPageHasAccess(programIds.programId_251),
            searchContainer: isPageHasAccess([programIds.programId_116, programIds.programId_154]),
            searchOpenMedia: isPageHasAccess(programIds.programId_209),
            discrepancyManagementSearch: isPageHasAccess([programIds.programId_329, programIds.programId_330]),
            valutStatusReports: isPageHasAccess(programIds.programId_331),
            globalMediaLocator: isPageHasAccess(programIds.programId_224),
            scanVerificationHistory: isPageHasAccess(programIds.programId_232),
            inboundOutboundReports: isPageHasAccess(programIds.programId_233),
            searchEmployees: isPageHasAccess(programIds.programId_133),
            dRDocuments: isPageHasAccess(programIds.programId_228),
            editMyInfoUserDetail: isPageHasAccess(programIds.programId_234),
            editMyPreference: isPageHasAccess(programIds.programId_235),
            customerPreference: isPageHasAccess(programIds.programId_236),
            addRetentionProfile: isPageHasAccess(programIds.programId_270),
            retentionProfiles: isPageHasAccess(programIds.programId_269),
            suggestions: isPageHasAccess(programIds.programId_116),
        },
    };
};

export const isUserProgramLevelAvailable = (userLevel, programLevel) => {
    return userLevel.includes(programLevel);
};
