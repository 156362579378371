import { Alert, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styles from "./SecureSyncSnackBar.styles";

const SecureSyncSnackBar = ({ message, severity, autoHideDuration, openSnackBar, onClose }) => {
    const [open, setOpen] = useState(openSnackBar);

    useEffect(() => {
        setOpen(openSnackBar);
    }, [openSnackBar]);

    const handleClose = useCallback(
        (event, reason) => {
            if (reason === "clickaway") {
                return;
            }
            setOpen(false);
            if (onClose) {
                onClose(event, reason);
            }
        },
        [onClose]
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={styles.Snackbar}
        >
            <Alert onClose={handleClose} severity={severity || "success"} variant="filled" sx={styles.alert}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SecureSyncSnackBar;
