const styles = {
    textContent: {
        fontWeight: "bold",
    },
    textField: {
        margin: "20px 0",
    },
    retentionProfileTitle: {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "150%",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        color: "#000000",
    },
    boxPaddingBottom: {
        height: "auto",
    },
    contactFieldContainer: {
        width: "100%",
    },
    formControl: {
        width: "100%",
        paddingRight: 2,
        paddingBottom: "20px",
    },
    paddingLeft: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        marginLeft: "47px",
    },
    buttonContainer: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    switchContainer: {
        paddingTop: "20px",
    },
    userDetailBody1: {
        paddingBottom: "15px",
    },
    mediaStatus: {
        width: "25%",
        marginLeft: "-12px",
        marginTop: "-6px",
        marginRight: "60px",
    },
    confirmMessage: {
        fontSize: "14px",
    },
    confirmationModalFooter: {
        margin: "8px",
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "8px",
    },
};

export default styles;
