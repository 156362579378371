import { useEffect, useMemo, useState } from "react";
import { Box, Button, Container, Divider, FormHelperText } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./ScanVerificationHistoryResult.styles";
import ScanVerificationResultDataTable from "src/components/ProcessMedia/ScanVerification/ScanVerificationResultTable/ScanVerificationResultDataTable";
import {
    getVerifyResultSummaryAndIssues,
    insertLoggedEvent,
    verifyAndUpdateIssueItem,
} from "src/services/processMediaService";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { convertLocalDateTime } from "src/utils/dateHelper";
import { yesFlag } from "src/constants/serviceConstants";
import { jsonToXmlScanVerification, multipleSort } from "src/utils/processMediaHelper";
import EditVerificationIssueModal from "src/components/ProcessMedia/ScanVerification/EditVerificationIssueModal/EditVerificationIssueModal";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { resolved, sortingOrderType, verifyIssueTypeId } from "src/constants/processMediaConstants";
import { scanVerificationHistoryResult, scanVerificationReportPayload } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import { scanVerificationHistory } from "src/constants/routeConstants/operationsRouterFullPaths";
import { scanVerification } from "src/constants/routeConstants/processMediaRouterFullPaths";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const ScanVerificationHistoryResult = () => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const { navigate, goBack } = useSecureSyncNavigate();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [summaryDetailsData, setSummaryDetailsData] = useState([]);
    const [defaultSummaryDetailsData, setDefaultSummaryDetailsData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEditVerificationIssueModalOpen, setIsEditVerificationIssueModalOpen] = useState(false);
    const [scanVerifyResults, setScanVerifyResults] = useState({});
    const [updatedRows, setUpdatedRows] = useState(new Set());
    const [mediaList, setMediaList] = useState([]);
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalProps, setPrintModalProps] = useState({ show: false, isFullReport: false });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.scanMore, callback: () => scanMoreHandler() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport(false) });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareFullReport, callback: () => printReport(true) });
    useEffect(() => {
        const verifyResults = async () => {
            setIsLoading(true);
            const responseVerifiedItems = await getVerifyResultSummaryAndIssues(
                params.verificationId,
                t("scanVerification.scannedPhrase")
            );

            if (!responseVerifiedItems.failure) {
                setScanVerifyResults(responseVerifiedItems);
                const summaryDetailsList = responseVerifiedItems?.allSummaryDetails
                    ? responseVerifiedItems?.allSummaryDetails
                    : [];
                setMediaList([]);
                setSummaryDetailsData(sortIssues(summaryDetailsList));
                setDefaultSummaryDetailsData(summaryDetailsList);
            }
            setIsLoading(false);
        };
        verifyResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const metaData = useMemo(() => {
        const metaData =
            scanVerifyResults?.metaData && scanVerifyResults?.metaData?.length > 0 ? scanVerifyResults?.metaData : [];

        return {
            verifyType: metaData[0]?.verify_type_descr || "",
            verifyTypeId: metaData[0]?.verify_type_id || "",
            list: metaData[0]?.list_descr || "",
            listDesc: metaData[0]?.list_descr_ltz || "",
            logicalVaultDesc: metaData[0]?.logical_vault_descr || "",
        };
    }, [scanVerifyResults?.metaData]);

    const scanResult = useMemo(() => {
        const metaData =
            scanVerifyResults?.metaData && scanVerifyResults?.metaData?.length > 0 ? scanVerifyResults?.metaData : [];
        const lastScanCount = mediaList.length;
        const scanMatchCount = parseInt(metaData[0]?.scan_match_count) || 0;
        const scanCount = parseInt(metaData[0]?.scan_count) || 0;
        const newScanCount = lastScanCount - scanMatchCount;
        const totalScanCount =
            params.verificationId === "0" ? scanCount + lastScanCount : scanCount + lastScanCount - scanMatchCount;
        const unresolvedOnListNotScanCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.sendOpenMediaToIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const unresolvedNotOnListCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.receiveOpenMediaFromIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        let createdDateTime = "";
        if (metaData[0]?.verify_type_id === verifyIssueTypeId.sendOpenMediaToIronMountain) {
            createdDateTime = convertLocalDateTime(metaData[0]?.list_creation_date_ltz);
        } else {
            createdDateTime = metaData[0]?.service_date;
        }
        let logicalVault = "";
        if (params.verificationId && parseInt(params.verificationId) > 0) {
            logicalVault = metaData[0]?.logical_vault_descr;
        }
        return {
            lastScanCount: lastScanCount,
            totalScanCount: totalScanCount,
            unresolvedOnListNotInScanCount: unresolvedOnListNotScanCount,
            unresolvedNotOnListCount: unresolvedNotOnListCount,
            verifiedItemCount: parseInt(metaData[0]?.list_count) - unresolvedOnListNotScanCount,
            createdDateTime: createdDateTime,
            logicalVault: logicalVault,
            newScanCount: newScanCount,
        };
    }, [scanVerifyResults?.metaData, mediaList.length, params.verificationId, summaryDetailsData]);

    const buttonState = useMemo(() => {
        const metaData =
            scanVerifyResults?.metaData && scanVerifyResults?.metaData?.length > 0 ? scanVerifyResults?.metaData : [];

        const unresolvedOnListNotScanCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.sendOpenMediaToIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const unresolvedNotOnListCount = summaryDetailsData.filter(
            (obj) => obj.c1 === verifyIssueTypeId.receiveOpenMediaFromIronMountain && obj.c4 === resolved.resolvedNo
        ).length;
        const lastScanCount = mediaList.length;
        const newScanCount = lastScanCount - parseInt(metaData[0]?.scan_match_count);
        let submitBtnView = false;
        if (params.verificationId === "0") {
            submitBtnView = true;
        } else {
            var totalCurrentUnresolvedCount = unresolvedOnListNotScanCount + unresolvedNotOnListCount;
            if (totalCurrentUnresolvedCount > 0 || newScanCount > 0 || updatedRows.size > 0) {
                submitBtnView = true;
            } else {
                submitBtnView = false;
            }
        }
        let prepareFullReportBtnView = false;
        if (parseInt(params.verificationId) > 0 && metaData[0]?.verify_editable_flag === yesFlag) {
            prepareFullReportBtnView = true;
        }
        return {
            submitButton: submitBtnView,
            prepareFullReportButton: prepareFullReportBtnView,
            prepareReportButton: parseInt(params.verificationId) > 0,
            disableButton: newScanCount !== 0,
        };
    }, [scanVerifyResults?.metaData, summaryDetailsData, mediaList.length, params.verificationId, updatedRows.size]);

    const verifyResultSet0 = scanVerifyResults?.metaData ? scanVerifyResults?.metaData : [];

    const sortIssues = (dataArr) => {
        const sortCriteria = [
            { key: "c1", order: sortingOrderType.descending },
            { key: "c3", order: sortingOrderType.ascending },
        ];

        return multipleSort(dataArr, sortCriteria);
    };
    const openEditVerificationIssueModalHandler = () => {
        setIsEditVerificationIssueModalOpen(true);
    };

    const closeEditVerificationIssueModalHandler = () => {
        setIsEditVerificationIssueModalOpen(false);
    };

    const submitEditVerificationIssueModalHandler = (updatedData) => {
        closeEditVerificationIssueModalHandler();
        const newUpdatedRows = new Set(updatedRows);
        const updatedObj = summaryDetailsData.map((obj) => {
            if (obj.c3 === selectedRecord.c3) {
                const getInitialRecord = defaultSummaryDetailsData.filter((item) => item.c3 === selectedRecord.c3);
                if (updatedData.c5 === getInitialRecord[0].c5 && updatedData.c4 === getInitialRecord[0].c4) {
                    newUpdatedRows.delete(selectedRecord.c3);
                } else {
                    newUpdatedRows.add(selectedRecord.c3);
                }
                obj.c4 = updatedData.c4;
                obj.c5 = updatedData.c5;
                return obj;
            }
            return obj;
        });
        setUpdatedRows(newUpdatedRows);
        setSummaryDetailsData(updatedObj);
        setSelectedRecord(null);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        await verifyAndUpdateIssueItem(
            params.verificationId,
            jsonToXmlScanVerification(scanVerifyResults, {
                verifyTypeId: metaData.verifyTypeId,
                descr: metaData.list,
                resolvedCount: scanResult?.verifiedItemCount,
            }),
            mediaList.length
        );
        const responseVerifiedItems = await getVerifyResultSummaryAndIssues(
            params.verificationId,
            t("scanVerification.scannedPhrase")
        );

        if (!responseVerifiedItems.failure) {
            setScanVerifyResults(responseVerifiedItems);
            const summaryDetailsList = responseVerifiedItems?.allSummaryDetails
                ? responseVerifiedItems?.allSummaryDetails
                : [];
            setMediaList([]);
            setSummaryDetailsData(sortIssues(summaryDetailsList));
            setDefaultSummaryDetailsData(summaryDetailsList);
            const description = "Scan Verification Results: Successful Save of Scan Verification Results";
            let comment = "";
            comment = `Verify_Result_Id: ${params.verificationId}`;
            await insertLoggedEvent(comment, description);
        }
        setUpdatedRows(new Set());
        setIsLoading(false);
    };

    const isVerificationResultHasLength = verifyResultSet0.length > 0;

    const onDeleteIssuesHandler = (id) => {
        const verificationIssueList = summaryDetailsData.filter((item) => item.c3 !== id);
        const modifiedMediaList = mediaList.filter((item) => item.mediaList !== id.trim());
        setMediaList(modifiedMediaList);
        const newUpdatedRows = new Set(updatedRows);
        newUpdatedRows.delete(id);
        setUpdatedRows(newUpdatedRows);
        setSummaryDetailsData(verificationIssueList);
    };

    const printReport = (isFullReport = false) => {
        let payload;
        if (isFullReport) {
            const paramsObj = {
                isFullReport: isFullReport,
                verifyResultId: params.verificationId,
                list: metaData.list,
                createdDate: scanResult?.createdDateTime || "",
                verifiedItems: scanResult?.verifiedItemCount.toString() || "0",
                originalVerificationDate: verifyResultSet0[0]?.first_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                    : "",
                lastVerificationDate: verifyResultSet0[0]?.last_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                    : "",
            };
            payload = scanVerificationReportPayload(paramsObj);
        } else {
            const paramsObj = {
                verifyResultId: params.verificationId,
                list: metaData.list,
                createdDate: scanResult?.createdDateTime || "",
                verifiedItems: scanResult?.verifiedItemCount.toString() || "0",
                originalVerificationDate: verifyResultSet0[0]?.first_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                    : "",
                lastVerificationDate: verifyResultSet0[0]?.last_session_date_ltz
                    ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                    : "",
            };
            payload = scanVerificationHistoryResult(paramsObj);
        }
        setPrintPayload(payload);
        setPrintModalProps({ show: true, isFullReport: isFullReport });
    };

    const scanMoreHandler = () => {
        const scanMore = {
            lastScan: mediaList[mediaList.length - 1]?.mediaList || "-",
            totalRecords: mediaList.length,
            mediaList: mediaList,
            list: metaData.listDesc,
            action: metaData.verifyTypeId,
            logicalVaultDesc: metaData.logicalVaultDesc,
        };

        navigate(scanVerification, { state: scanMore });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("titles.scanVerificationResults")}</PageTitle>
                        <Box sx={styles.infoMessagesSection}>
                            {buttonState?.submitButton && (
                                <Trans
                                    i18nKey={"apiErrorMessages.63577"}
                                    values={{ buttonLabel: t("buttonLabels.submit") }}
                                />
                            )}
                            <br />
                            {verifyResultSet0[0]?.verify_editable_flag === yesFlag && (
                                <Trans
                                    i18nKey={"apiErrorMessages.25133"}
                                    values={{ buttonLabel: t("buttonLabels.scanMore") }}
                                />
                            )}
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("scanVerification.comparisonSummary")}</PageSubTitle>
                        <Box sx={styles.mainWrapper}>
                            <SecureSyncReadOnlyTextField
                                value={metaData.verifyType || " "}
                                label={t("scanVerification.action")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={metaData.list || " "}
                                label={t("scanVerification.list")}
                            />
                            {scanResult?.createdDateTime && (
                                <SecureSyncReadOnlyTextField
                                    value={scanResult?.createdDateTime || " "}
                                    label={t("scanVerification.createdDateTime")}
                                />
                            )}
                            {metaData?.logicalVaultDesc && (
                                <SecureSyncReadOnlyTextField
                                    value={metaData?.logicalVaultDesc || " "}
                                    label={t("scanVerification.logicalVault")}
                                />
                            )}
                            <Box sx={styles.div4}>
                                {isVerificationResultHasLength && verifyResultSet0[0]?.first_session_personnel_name && (
                                    <SecureSyncReadOnlyTextField
                                        value={verifyResultSet0[0]?.first_session_personnel_name || " "}
                                        label={t("scanVerification.originalVerificationUser")}
                                    />
                                )}
                            </Box>
                            {isVerificationResultHasLength && verifyResultSet0[0]?.first_session_date_ltz && (
                                <SecureSyncReadOnlyTextField
                                    value={
                                        verifyResultSet0[0]?.first_session_date_ltz
                                            ? convertLocalDateTime(verifyResultSet0[0]?.first_session_date_ltz)
                                            : " "
                                    }
                                    label={t("scanVerification.originalVerificationDateTime")}
                                />
                            )}
                            {isVerificationResultHasLength && verifyResultSet0[0]?.last_session_personnel_name && (
                                <SecureSyncReadOnlyTextField
                                    value={verifyResultSet0[0]?.last_session_personnel_name || " "}
                                    label={t("scanVerification.lastVerificationUser")}
                                />
                            )}
                            {isVerificationResultHasLength && verifyResultSet0[0]?.last_session_date_ltz && (
                                <SecureSyncReadOnlyTextField
                                    value={
                                        verifyResultSet0[0]?.last_session_date_ltz
                                            ? convertLocalDateTime(verifyResultSet0[0]?.last_session_date_ltz)
                                            : " "
                                    }
                                    label={t("scanVerification.lastVerificationDateTime")}
                                />
                            )}
                        </Box>
                        <Box sx={styles.statisticWrapper}>
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.totalScanCount || "0"}
                                label={t("scanVerification.uniqueItemsScanned")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={verifyResultSet0[0]?.list_count || "0"}
                                label={t("scanVerification.totalItemsInList")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.verifiedItemCount || "0"}
                                label={t("scanVerification.verifiedItems")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.unresolvedOnListNotInScanCount || "0"}
                                label={t("scanVerification.unresolvedOnListNotInScan")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={scanResult?.unresolvedNotOnListCount || "0"}
                                label={t("scanVerification.unresolvedNotOnList")}
                            />
                        </Box>

                        <Divider />
                        <PageSubTitle>{t("titles.verificationIssues")}</PageSubTitle>
                        <ScanVerificationResultDataTable
                            dataArray={summaryDetailsData}
                            updatedRowList={updatedRows}
                            getRowId={(row) => row.c3}
                            openEditVerificationIssueModalHandler={openEditVerificationIssueModalHandler}
                            isEditVerificationIssueModalOpen={isEditVerificationIssueModalOpen}
                            setSelectedRecord={setSelectedRecord}
                            selectedRecord={selectedRecord}
                            onDelete={onDeleteIssuesHandler}
                        />
                        <FormHelperText sx={styles.formHelperText}>
                            {t("scanVerification.whenTheResolvedFlagIsCheckedComments")}
                        </FormHelperText>
                        <Divider />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                variant="outlined"
                                disabled={buttonState?.disableButton}
                                onClick={() => goBack(scanVerificationHistory)}
                                startIcon={<IconLeft />}
                            >
                                {t("buttonLabels.back")}
                            </Button>
                            {verifyResultSet0[0]?.verify_editable_flag === yesFlag && (
                                <Button
                                    variant="outlined"
                                    onClick={scanMoreHandler}
                                    title={getKeyBoardLabel(shortCutKeys.scanMore)}
                                >
                                    {t("buttonLabels.scanMore")}
                                </Button>
                            )}
                            {buttonState?.prepareReportButton && (
                                <Button
                                    variant="outlined"
                                    disabled={buttonState?.disableButton || updatedRows.size > 0}
                                    onClick={() => printReport(false)}
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                >
                                    {t("buttonLabels.prepareIssueReport")}
                                </Button>
                            )}
                            {buttonState?.prepareFullReportButton && (
                                <Button
                                    variant="outlined"
                                    disabled={buttonState?.disableButton || updatedRows.size > 0}
                                    onClick={() => printReport(true)}
                                    title={getKeyBoardLabel(shortCutKeys.prepareFullReport)}
                                >
                                    {t("buttonLabels.prepareFullReport")}
                                </Button>
                            )}
                            {buttonState?.submitButton && (
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    title={getKeyBoardLabel(shortCutKeys.submit)}
                                >
                                    {t("buttonLabels.submit")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
            {isEditVerificationIssueModalOpen && (
                <EditVerificationIssueModal
                    recordData={selectedRecord}
                    onClose={closeEditVerificationIssueModalHandler}
                    onSubmit={submitEditVerificationIssueModalHandler}
                />
            )}
            <SecureSyncPrintModal
                open={printModalProps.show}
                payload={printPayload}
                onClose={() => setPrintModalProps({ show: false, isFullReport: false })}
                targetReport={
                    printModalProps.isFullReport
                        ? targetUrl.scanVerificationFullReport
                        : targetUrl.scanVerificationHistoryResult
                }
            />
        </>
    );
};

export default ScanVerificationHistoryResult;
