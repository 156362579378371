const InfoCircle = ({ fill, width = "22px", height = "22px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" style={{ width, height }}>
            <path
                d="M11 9.5C11.41 9.5 11.75 9.84 11.75 10.25L11.75 15.25C11.75 15.66 11.41 16 11 16C10.59 16 10.25 15.66 10.25 15.25L10.25 10.25C10.25 9.84 10.59 9.5 11 9.5Z"
                fill={fill}
            />
            <path
                d="M11.38 6.33C11.26 6.27999 11.13 6.25 11 6.25C10.87 6.25 10.74 6.27999 10.62 6.33C10.5 6.37999 10.39 6.45 10.29 6.54C10.2 6.64 10.13 6.74 10.08 6.87C10.03 6.99 10 7.12 10 7.25C10 7.38 10.03 7.51 10.08 7.63C10.13 7.75 10.2 7.86 10.29 7.96C10.39 8.05 10.5 8.12 10.62 8.17C10.86 8.27 11.14 8.26999 11.38 8.17C11.5 8.12 11.61 8.04999 11.71 7.96C11.8 7.85999 11.87 7.75 11.92 7.63C11.97 7.50999 12 7.37999 12 7.25C12 7.12 11.97 6.99 11.92 6.87C11.87 6.74 11.8 6.64 11.71 6.54C11.61 6.45 11.5 6.37999 11.38 6.33Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 21.75C5.07 21.75 0.25 16.93 0.25 11C0.25 5.07 5.07 0.25 11 0.25C16.93 0.25 21.75 5.07 21.75 11C21.75 16.93 16.93 21.75 11 21.75ZM11 1.75C5.9 1.75 1.75 5.9 1.75 11C1.75 16.1 5.9 20.25 11 20.25C16.1 20.25 20.25 16.1 20.25 11C20.25 5.9 16.1 1.75 11 1.75Z"
                fill={fill}
            />
        </svg>
    );
};

export default InfoCircle;
