import { sortingOrderType } from "./processMediaConstants";
import i18n from "src/i18n";
export const dateFormats = {
    displayDate: "MM/DD/YYYY",
    sortDate: "DD/MM/YYYY",
    displayTime: "hh:mm A",
    displayDateTime: "MM/DD/YYYY hh:mm A",
    emailDateTime: "MM/DD/YYYY hh:mm:ss A",
    isoDate: "YYYY-MM-DD",
    isoTime: "HH:mm:ss",
    isoDateTime: "YYYY-MM-DDTHH:mm:ssZ",
    isoDateTimeZ: "YYYY-MM-DDTHH:mm:ss[Z]",
    readableDate: "dddd, MMMM Do YYYY",
    fileDate: "YYYYMMDD",
    requestServiceDateFormat: "YYYY-MM-DD HH: mm: ss",
    timeWithGmtStamp: " HH:mm [GMT]",
    timeStamp: "YYYY-MM-DD HH:mm:ss",
    timeStampWithMilliSeconds: "YYYY-MM-DD HH:mm:ss.SSS",
    formattedDateMMDDYYYY: "MM-DD-YYYY",
    shortDate: "L",
    mistService: "DD-MMM-YYYY HH:mm",
    mistServiceDateOnly: "DD-MMM-YYYY",
    timeFormat24: "HH:mm",
    localized24HFormat: "L HH:mm",
    localized24HAndSecFormat: "L HH:mm:ss",
    yearFormat: "YYYY",
    weekCode: "ddd",
    dateTimeLongFormat: "dddd, MMMM D, YYYY h:mm:ss A",
    dateTimeLongFormatImau: "MMMM D, YYYY h:mm:ss A",
    dateTimeLongFormatIme: "DD MMMM YYYY HH:mm:ss",
};

export const dateTimeUnits = {
    year: "year",
    month: "month",
    day: "day",
    days: "days",
};

export const indefiniteDate = "9999-12-31T00:00:00Z";
export const maxYear = 50;
export const minYear = 7;

export const getIndefinitePhase = () => i18n.t("containerPickup.indefinite");
export const getNotAtIronMountainPhase = () => i18n.t("localizedServiceDetails.notAtIronMountain");

export const serviceDateSortCriteria = [{ key: "$d", order: sortingOrderType.ascending }];
export const defaultISOLocaleId = "en-US";

export const avoidIsoLocaleCodes = {
    frCa: "fr-CA",
    enCa: "en-CA",
};
