import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RequestContainerDeletion.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { sortHelper } from "src/utils/sortHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const RequestContainerDeletion = ({ requestDeleteContainer }) => {
    const { t } = useTranslation();
    const noDataMsg = t("messages.noClosedContainers");
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "number", headerName: convertToLocaleUpperCase(t("tableHeader.containerNumber")) },
            { field: "descr", headerName: convertToLocaleUpperCase(t("tableHeader.containerType")) },
            { field: "exchange_status", headerName: convertToLocaleUpperCase(t("tableHeader.containerStatus")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedRequestDeleteContainer = sortHelper({
        data: requestDeleteContainer,
        primaryProperty: "number",
        secondaryProperty: "exchange_status",
        sortOrder: "asc",
    });

    return (
        <>
            <Box sx={styles.container}>
                <PageSubTitle>{t("requestDetails.requestContainerDeletion")}</PageSubTitle>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        <Typography variant="h6" sx={styles.heading}>
                            {t("requestDetails.deleteContainerDetails")}
                        </Typography>
                        {requestDeleteContainer.length > 0 && (
                            <Box>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: requestDeleteContainer.length })}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={sortedRequestDeleteContainer}
                            columns={columns}
                            noDataMessage={noDataMsg}
                        />
                    </Box>
                </SecureSyncTableCard>
            </Box>
            <Divider />
        </>
    );
};

export default RequestContainerDeletion;
