const styles = {
    serviceLink: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    linkIcon: {
        width: 22,
        height: 22,
        marginRight: 8,
    },
    textStyle: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        color: "info.main",
        mb: 1.5,
    },
    label: {
        mb: "20px",
    },
    listWrapper: {
        display: "inline-flex",
        flexDirection: "column",
        ml: "30px",
    },
    otherResourceItem: {
        display: "inline-flex",
        flexDirection: "column",
    },
    otherResourceItemWrapper: {
        display: "inline-flex",
        flexDirection: "column",
        ml: "30px",
        gap: "20px",
    },
    link2: {
        my: "40px",
        color: "info.main",
        textDecorationLine: "underline",
    },
    subtitle: {
        fontWeight: "600",
    },
};

export default styles;
