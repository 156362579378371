const styles = {
    card: {
        width: "100%",
        mt: "20px",
        "& .MuiCardContent-root": {
            padding: "0px",
        },
        "& .MuiCardContent-root:last-child": {
            padding: "0px",
        },
    },
};
export default styles;
