const styles = {
    box: {
        px: { xs: 3, sm: 5 },
        bgcolor: "#F7F7FE",
        paddingBottom: "20px",
        marginBottom: "20px",
    },
    typographyTitle: {
        color: "#14477D",
        fontSize: "26px",
        fontWeight: 400,
        lineHeight: "123.5%",
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    serviceLink: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    typographyText: {
        mt: 3,
        mb: 1.5,
        color: "#252526",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
};

export default styles;
