import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const useAnchorAndNavigation = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef();
    const navigate = useNavigate();

    const openAnchor = useCallback((event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    }, []);

    const closeAnchor = useCallback(() => {
        setIsMenuOpen(false);
        setAnchorEl(null);
    }, []);

    const handleBoxMouseLeave = useCallback(
        (event) => {
            const target = event.relatedTarget;
            if (menuRef.current && (!(target instanceof Node) || !menuRef.current.contains(target))) {
                closeAnchor();
            }
        },
        [closeAnchor]
    );

    const handleMenuMouseLeave = useCallback(
        (event) => {
            const target = event.relatedTarget;
            if (anchorEl && (!(target instanceof Node) || !anchorEl.contains(target))) {
                closeAnchor();
            }
        },
        [anchorEl, closeAnchor]
    );

    const handleMenuItemClick = useCallback(
        (path) => {
            closeAnchor();
            navigate(path);
        },
        [closeAnchor, navigate]
    );

    return {
        anchorEl,
        isOpen,
        menuRef,
        openAnchor,
        closeAnchor,
        handleBoxMouseLeave,
        handleMenuMouseLeave,
        handleMenuItemClick,
    };
};

export default useAnchorAndNavigation;
