import { getDocs } from "firebase/firestore";
import { t } from "i18next";
import { apiResultSet0Collection, apiResultSet1Collection } from "./firebase";
import {
    constructFbHeaders,
    getCustomers,
    getLanguageLocaleId,
    getLoggedUserDetails,
    getPersonalId,
    getPersonalLocalId,
    getResultSets,
    getSelectedCustomerDb,
    getSelectedCustomerId,
    getSelectedCustomerTimeZoneOffset,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import { timestampToHex } from "src/utils/dateHelper";

const apiUrls = {
    getDropdownListInfo: "/inboundoutboundreport/getdropdownlistinfo",
    listCompletedLogicalVault: "/sscompletelibraryaudits/listcompletedlogicalvault",
    getExceptions: "/inboundoutboundreport/getexceptions",
    listContainerResearchCriteria: "/sssearchcontainers/listcontainerresearchcriteria",
    updateItem: "/sspreparedatasource/updateitem",
    listVerifiedResultsSummary: "/scanverificationhistory/listverifiedresultsummary",
    searchGlobalMediaLocator: "/globalmedialocator/searchglobalmedialocator",
    listOpenMediaSearchResults: "/sssearchopenmedia/listopenmediasearchresults",
    listOpenMediaSearchResultsTwo: "/sssearchopenmedia/listopenmediasearchresultstwo",
    getSpecifiedOmDetails: "/sssearchopenmedia/getspecifiedomdetails",
    setUserUpdatesForResearch: "/sssearchopenmedia/setuserupdatesforresearch",
    listCAR: "/sscorrectiveactionrequest/listcorrectiveactionrequests",
    getBasicCar: "/sssearchopenmedia/getbasiccar",
    insertRequestComment: "/sssearchopenmedia/insertrequestcomment",
    getOperationsServiceReference: "/sssearchopenmedia/getoperationservicereference",
    vsrAccountSummary: "/sssearchopenmedia/vsraccountsummary",
    getDataForContainer: "/sssearchopenmedia/getdataforcontainer",
    omOtherException: "/sssearchopenmedia/omotherexception",
    exceptionSelectedExportMethod: "/sssearchopenmedia/exceptionselectedexportmethod",
    otherVaultReportExceptions: "/sssearchopenmedia/othervaultreportexceptions",
    updateContainer: "/sssearchopenmedia/updatecontainer",
    listActiveLogicalVaults: "/ssdiscrepancymanagement/listactivelogicalvaults",
    getOperationServiceReference: "/sssearchopenmedia/getoperationservicereference",
    drManagementSearch: "/sssearchopenmedia/drmanagemtnsearch",
    loadOmDiscrepancies: "/sssearchopenmedia/loadomdiscrepancies",
    loadContainerDiscrepancies: "/sssearchopenmedia/loadcontainerdiscrepancies",
};
/**
 * Get Dripdown List info
 * @param {*} dropdownListTypeId
 * @param {*} languageLocaleId
 * @returns
 */
export const getDropdownListInfo = async (dropdownListTypeId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDropdownListInfo);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/inboundoutboundreport/getdropdownlistinfo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        system_id: "50",
        dropdown_list_type_id: "",
        customer_id: getSelectedCustomerId(),
        personnel_id: getPersonalId(),
        language_locale_id: getLanguageLocaleId(),
    };

    try {
        if (dropdownListTypeId) {
            body.dropdown_list_type_id = dropdownListTypeId;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const dropdownList = {
            failure: false,
            rs0: rs0Data,
            rs1: rs1Data,
        };
        return dropdownList;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Get Completed Library audit List info
 * @param {*} maxCommentLength
 * @param {*} ironMountainPhrase
 * @returns
 */
export const getCompletedLibraryAudit = async (maxCommentLength, ironMountainPhrase) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listCompletedLogicalVault);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sscompletelibraryaudits/listcompletedlogicalvault";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        locale_id: getPersonalLocalId(),
        max_comment_length: maxCommentLength || "0",
        iron_mountain: ironMountainPhrase || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const dropdownList = {
            failure: false,
            auditResults: rs0Data,
            error: rs1Data,
        };
        return dropdownList;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

/**
 * Get Audit Issue List info
`* @param {obj} request
 * @returns
 */
export const getAuditExceptionList = async ({
    auditId,
    orderBy,
    descendingFlag,
    ssieFlag,
    missingPhrase,
    unexpectedPhrase,
    auditTypeCode,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getExceptions);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/inboundoutboundreport/getexceptions";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        audit_id: auditId || "",
        order_by: orderBy || "",
        descending_flag: descendingFlag || "",
        ssie_flag: ssieFlag || "",
        missing_phrase: missingPhrase || "",
        unexpected_phrase: unexpectedPhrase || "",
        audit_type_code: auditTypeCode || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const dropdownList = {
            failure: false,
            auditExceptionResults: rs0Data,
            error: rs1Data,
        };
        return dropdownList;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

/**
 * @returns
 */
export const getSearchContainerDropDown = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listContainerResearchCriteria);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchcontainers/listcontainerresearchcriteria";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId() || "1",
        all_phrase: t("localizedServiceDetails.allPhrase"),
        all_excluding_deleted_phrase: t("localizedServiceDetails.allExcludingDeletedPhrase"),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            mediaTypes: response?.results[0] ? response?.results[0] : [],
            statusList: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const updateContainerItem = async (containerItemObject) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateItem);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/updateitem";
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        new_return_date: containerItemObject.newReturnDate || "",
        container_id: containerItemObject.containerId || "",
        new_descr: containerItemObject.newDescription || "",
        personnel_id: containerItemObject.personnelId || getPersonalId(),
        prepare_for_pickup_flag: containerItemObject.prepareForPickup || "N",
        return_date_change_flag: containerItemObject.returnDateChange || "N",
        login: containerItemObject.username || lastModifiedUser,
        container_timestamp: containerItemObject.containerTimestamp || timestampToHex(),
        retention_profile_id: containerItemObject.retentionProfileId || "",
        deleted_phrase: containerItemObject.deletedPhrase || "Deleted -- {0}",
        sent_to_phrase: containerItemObject.sentToPhrase || "Sent to {0}",
        not_at_iron_mountain_phrase: containerItemObject.notAtIronmountainPhrase || "Not at Iron Mountain",
        indefinite_phrase: containerItemObject.indefinitePhrase || "Indefinite",
        all_phrase: containerItemObject.allPhrase || "<ALL>",
        all_excluding_deleted_phrase: containerItemObject.allExcludingDeletedPhrase || "<ALL> Excluding Deleted",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const result = await getResultSets(resDoc, 2);
        return result;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Audit Issue List info
`* @param {obj} request
 * @returns
 */
export const getListVerifiedResult = async ({ startDate, endDate }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listVerifiedResultsSummary);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/scanverificationhistory/listverifiedresultsummary";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        start_date_ltz: startDate || "",
        end_date_ltz: endDate || "",
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const dropdownList = {
            failure: false,
            verifiedResultList: rs0Data,
            error: rs1Data,
        };
        return dropdownList;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getGlobalMediaLocator = async ({ mediaNumber }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.searchGlobalMediaLocator);
    traceStart();
    const headers = constructFbHeaders();
    const userDetails = getLoggedUserDetails();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";

    const lastModifiedUser = userDetails.basicUserInfo.username;
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_RESEARCH}/globalmedialocator/searchglobalmedialocator`;
    const body = {
        main_district_id: "50",
        customer_id: selectedCustomer.customer_id,
        media_number: mediaNumber,
        login: lastModifiedUser,
        customer_id_list: custListString,
        vt_available_flag: noFlag,
        language_locale_id: getLanguageLocaleId(),
        sent_to_phrase: "Sent to {0}",
        container_phrase: "Container",
        open_media_phrase: "Open Media",
        media_in_container_phrase: "Media in Container",
        in_phrase: "IN {0}",
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const response = await getResultSets(res.docId, 3);
        if (response.failure) {
            return response;
        }

        return {
            resultSet0: response?.results[0],
            resultSet1: response?.results[1],
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const getOpenMediaSearch = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listOpenMediaSearchResults);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/listopenmediasearchresults";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        all_phrase: "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 4);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            rs0: response?.results[0] ? response?.results[0] : [],
            rs1: response?.results[1] ? response?.results[1] : [],
            rs2: response?.results[2] ? response?.results[2] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getOpenMediaList = async (fieldData) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listOpenMediaSearchResultsTwo);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/listopenmediasearchresultstwo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        report_flag: "N",
        volser: fieldData.mediaNumber,
        descr: fieldData.mediaDescription,
        drp_code: fieldData.drpCode,
        from_return_date: fieldData.returnDateFrom,
        to_return_date: fieldData.returnDateTo,
        summary_type: fieldData.currentStatus,
        logical_vault_id: fieldData.logicalVault,
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        sent_to_phrase: "Sent to {0}",
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("containerPickup.indefinite"),
        destroyed_phrase: t("searchOpenMedia.destroyed"),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const dropdownList = {
            failure: false,
            verifiedResultList: rs0Data,
            error: rs1Data,
        };
        return dropdownList;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

/**
 * @returns
 */
export const getMediaData = async (omMediaId, districtId = null) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getSpecifiedOmDetails);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/getspecifiedomdetails";
    const body = {
        main_district_id: districtId ? districtId : getSelectedCustomerDb(),
        open_media_id: omMediaId,
        locale_id: getPersonalLocalId() || "1",
        language_locale_id: getLanguageLocaleId() || "1",
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("containerPickup.indefinite"),
        destroyed_phrase: t("openMediaDetails.destroy"),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            mediaDetails: response?.results[0][0] ? response?.results[0][0] : "",
            requestData: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const omUpdate = async ({ omId, drpCode, description, ts, returnDate }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.setUserUpdatesForResearch);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/setuserupdatesforresearch";
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        open_media_id: omId,
        new_return_date: returnDate,
        new_drp_code: drpCode,
        new_descr: description,
        open_media_ts: ts,
        last_mod_user: lastModifiedUser,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            mediaDetails: response?.results[0][0] ? response?.results[0][0] : "",
            requestData: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getCorrectiveActionRequests = async ({ fromDate, toDate }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listCAR);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sscorrectiveactionrequest/listcorrectiveactionrequests";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        from_date: fromDate,
        to_date: toDate,
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        msg_om_missing_pick_descr: t("localizedServiceDetails.msgOmMissingPick"),
        msg_cont_miss_prep_descr: t("localizedServiceDetails.msgContMissPrep"),
        msg_cont_miss_arrival_desc: t("localizedServiceDetails.msgContMissArrival"),
        msg_incomplete_stop_descr: t("localizedServiceDetails.msgIncompleteStop"),
        carDescription: t("localizedServiceDetails.carDescription"),
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            verifiedResultList: response?.results[0] ? response?.results[0] : [],
            error: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getCorrectiveActionDetails = async ({ carId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getBasicCar);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/getbasiccar";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        car_id: carId,
        open_media_list_phrase: t("localizedServiceDetails.openMediaListPhrase"),
        containers_phrase: t("localizedServiceDetails.containersPhrase"),
        carDescription: t("localizedServiceDetails.carDescription"),
        msg_om_missing_pick: t("localizedServiceDetails.msgOmMissingPick"),
        msg_cont_miss_prep: t("localizedServiceDetails.msgContMissPrep"),
        msg_cont_miss_arrival: t("localizedServiceDetails.msgContMissArrival"),
        msg_incomplete_stop: t("localizedServiceDetails.msgIncompleteStop"),
        msg_employee: t("localizedServiceDetails.msgEmployee"),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            correctiveResults1: response?.results[0] ? response?.results[0] : [],
            correctiveResults2: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getCorrectiveActionUpdateComments = async ({ carId, comments }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertRequestComment);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/insertrequestcomment";
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        car_id: carId,
        login: lastModifiedUser,
        personnel_id: getPersonalId(),
        comment: comments,
        open_media_list_phrase: t("localizedServiceDetails.openMediaListPhrase"),
        containers_phrase: t("localizedServiceDetails.containersPhrase"),
        carDescription: t("localizedServiceDetails.carDescription"),
        msg_om_missing_pick: t("localizedServiceDetails.msgOmMissingPick"),
        msg_cont_miss_prep: t("localizedServiceDetails.msgContMissPrep"),
        msg_cont_miss_arrival: t("localizedServiceDetails.msgContMissArrival"),
        msg_incomplete_stop: t("localizedServiceDetails.msgIncompleteStop"),
        msg_employee: t("localizedServiceDetails.msgEmployee"),
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            correctiveResults1: response?.results[0] ? response?.results[0] : [],
            correctiveResults2: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getOperationServiceReference = async ({ modeId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getOperationsServiceReference);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/getoperationservicereference";
    const body = {
        main_district_id: "50",
        operational_mode_id: modeId,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 11);

        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            containerDetected: response?.results[1] ? response?.results[1] : [],
            openMediaDetected: response?.results[2] ? response?.results[2] : [],
            containerDiscrepancyType: response?.results[3] ? response?.results[3] : [],
            openMediaDiscrepancyType: response?.results[4] ? response?.results[4] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getReportsDropDown = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDropdownListInfo);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/inboundoutboundreport/getdropdownlistinfo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        dropdown_list_type_id: "2",
        system_id: "50",
        language_locale_id: getLanguageLocaleId(),
        customer_id: getSelectedCustomerId(),
        personnel_id: getPersonalId(),
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            reportDropDown: response?.results[0] ? response?.results[0] : [],
            error: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getVaultStatusReportData = async ({
    customerIdList,
    startDate,
    endDate,
    customerFacingFlag = yesFlag,
    targetMediaType,
    fileSectionTypeIdList,
    systemFunctionIdList,
    discrepancyTypeCode,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.vsrAccountSummary);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/vsraccountsummary";
    const body = {
        main_district_id: "50",
        customer_id_list: customerIdList,
        start_date: startDate,
        end_date: endDate,
        customer_facing_flag: customerFacingFlag,
        target_media_type: targetMediaType,
        file_section_type_id_list: fileSectionTypeIdList,
        system_function_id_list: systemFunctionIdList,
        discrepancy_type_code: discrepancyTypeCode,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 4);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            openMediaDiscrepancySummary: response?.results[0] ? response?.results[0] : [],
            containerDiscrepancySummary: response?.results[1] ? response?.results[1] : [],
            accountSummary: response?.results[2] ? response?.results[2] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getDataForContainer = async ({ containerId, districtId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDataForContainer);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/getdataforcontainer";
    const body = {
        main_district_id: districtId ? districtId : getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        container_id: containerId,
        district_id: "NULL",
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        deleted_phrase: t("localizedServiceDetails.deleted0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("localizedServiceDetails.indefinite"),
        days_phrase: t("localizedServiceDetails.zeroDays"),
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 4);

        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            containerData: response?.results[0] ? response?.results[0] : [],
            transactionHistory: response?.results[1] ? response?.results[1] : [],
            closedContainerContents: response?.results[2] ? response?.results[2] : [],
            retentionProfile: response?.results[3] ? response?.results[3] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getOtherOpenMediaExceptionData = async ({
    customerIdList,
    startDate,
    endDate,
    customerFacingFlag = yesFlag,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.omOtherException);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/omotherexception";
    const body = {
        main_district_id: "50",
        customer_id_list: customerIdList,
        start_date: startDate,
        end_date: endDate,
        customer_facing_flag: customerFacingFlag,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 1);

        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            otherOpenMediaExceptions: response?.results[0] ? response?.results[0] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getExportSelectedExceptions = async ({
    customerIdList,
    fileSectionTypeIdList,
    exceptionTypeList,
    fromDate,
    toDate,
    maxSearchResults,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.exceptionSelectedExportMethod);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/exceptionselectedexportmethod";
    const body = {
        main_district_id: "50",
        customer_id_list: customerIdList,
        file_section_type_id_list: fileSectionTypeIdList,
        exception_type_list: exceptionTypeList,
        from_date: fromDate,
        to_date: toDate,
        ga_max_num_search_results: maxSearchResults,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);

        if (response.failure) {
            return {
                failure: true,
                error: response[0].error,
            };
        }
        return {
            failure: false,
            otherOpenMediaExceptions: response?.results[0] ? response?.results[0] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getOtherVaultExceptions = async ({
    customerId,
    districtId,
    fileSectionTypeIdList,
    exceptionType,
    fromDate,
    toDate,
    operationalModeId,
    logicalVaultCode,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.otherVaultReportExceptions);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/othervaultreportexceptions";
    const body = {
        main_district_id: districtId || getSelectedCustomerDb(),
        customer_id: customerId,
        file_section_type_id: fileSectionTypeIdList,
        exception_type: exceptionType,
        detected_date_from: fromDate,
        detected_date_to: toDate,
        operational_mode_id: operationalModeId,
        logical_vault_code: logicalVaultCode,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 1);

        if (response.failure) {
            return {
                failure: true,
                error: response[0].error,
            };
        }
        return {
            failure: false,
            openMediaExceptions: response?.results[0] ? response?.results[0] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};
export const updateContainerDetails = async ({
    containerId,
    newReturnDate,
    description,
    timestamp,
    retentionProfileId,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateContainer);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/updatecontainer";
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        container_id: containerId,
        new_return_date_2: newReturnDate,
        new_descr: description,
        personnel_id: getPersonalId(),
        prepare_for_pickup_flag: noFlag,
        return_date_change_flag: noFlag,
        login: lastModifiedUser,
        container_timestamp: timestamp,
        retention_profile_id: retentionProfileId,
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        deleted_phrase: t("localizedServiceDetails.deleted0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("localizedServiceDetails.indefinite"),
        all_phrase: t("localizedServiceDetails.allPhrase"),
        all_excluding_deleted_phrase: t("localizedServiceDetails.allExcludingDeletedPhrase"),
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 4);
        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            containerData: response?.results[0] ? response?.results[0] : [],
            error: response?.results[1] ? response?.results[1] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getLogicalVaultService = async (customerId, districtId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.listActiveLogicalVaults);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/ssdiscrepancymanagement/listactivelogicalvaults";
    const body = {
        main_district_id: districtId || getSelectedCustomerDb(),
        customer_id: customerId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const resultSets = await getResultSets(resDoc, 2);
        return resultSets;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getDMdropdownService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getOperationServiceReference);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/getoperationservicereference";
    const body = {
        main_district_id: "50",
        operational_mode_id: "3",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const resultSets = await getResultSets(resDoc, 6);
        return resultSets;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getDrManagementSearch = async (payload) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.drManagementSearch);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/drmanagemtnsearch";

    const body = {
        main_district_id: "50",
        operational_mode_id: "3",
        customer_id_list: payload.customerIdList,
        district_id: null,
        discrepancy_status: payload.discrepancyStatus,
        discrepancy_type_descr_xml: payload.discrepancyTypeXML || "",
        media_program_type: payload.mediaProgramType || "",
        logical_vault_id: payload.logicalVault || null,
        detected_in_xml: payload.detectedInXML || "",
        from_detected_date: payload.detectedDateFrom || null,
        to_detected_date: payload.detectedDateTo || null,
        from_resolved_date: payload.resolvedDateFrom || null,
        to_resolved_date: payload.resolvedDateTo || null,
        volser_number: payload.mediaNumber || null,
        service_date: null,
        run_id: null,
        exclude_unresolved_no_action_flag: "Y",
        exclude_auto_resolve_flag: "N",
        om_cont_audit_id: null,
        om_cont_discrepancy_id: null,
        car_id: null,
        ga_max_num_search_results: "1000",
        ga_courtesy_followup_days: "3",
        customer_media_descr: null,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers, true);
        const resDoc = res?.docId;
        const resultSets = await getResultSets(resDoc, 2);
        return resultSets;
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getOmDiscrepancyDetails = async ({ omDiscrepancyId, districtId = getSelectedCustomerDb() }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.loadOmDiscrepancies);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/loadomdiscrepancies";

    const body = {
        main_district_id: districtId,
        om_discrepancy_id: omDiscrepancyId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const resultSets = await getResultSets(resDoc, 4);

        return {
            failure: false,
            generalDetails: resultSets.results[0],
            discrepancyDetails: resultSets.results[1],
            customerComment: resultSets.results[2],
            additional: resultSets.results[3],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const getContainerDiscrepancyDetails = async ({ discrepancyId, districtId = getSelectedCustomerDb() }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.loadContainerDiscrepancies);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_RESEARCH + "/sssearchopenmedia/loadcontainerdiscrepancies";

    const body = {
        main_district_id: districtId,
        discrepancy_id: discrepancyId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const resultSets = await getResultSets(resDoc, 4);

        return {
            failure: false,
            generalDetails: resultSets.results[0],
            customerComment: resultSets.results[1],
            uploadErrors: resultSets.results[2],
            additional: resultSets.results[3],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};
