import { Box, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./OpenMediaDetailsContainer.styles";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";

const OpenMediaDetailsContainer = ({ data, disable, changeDrpCode, value, headerMsg, error, closeError }) => {
    const { t } = useTranslation();
    return (
        <Box sx={styles.searchContainer}>
            {headerMsg && (
                <Typography sx={styles.filterDesc}>
                    <Trans i18nKey={headerMsg}></Trans>
                </Typography>
            )}
            {error.show && <SecureSyncAlert message={error.msg} severity="error" onClose={closeError} />}
            <Box sx={styles.searchFields}>
                <SecureSyncReadOnlyTextField
                    label={t("formFields.mediaNumber")}
                    name="mediaNumber"
                    value={data.mediaNumber ? data.mediaNumber : "-"}
                />
                <SecureSyncReadOnlyTextField
                    label={t("formFields.mediaType")}
                    name="mediaType"
                    value={data.mediaType ? data.mediaType : "-"}
                />
                <SecureSyncReadOnlyTextField
                    label={t("formFields.currentStatus")}
                    name="status"
                    value={data.status ? data.status : "-"}
                />
                <SecureSyncReadOnlyTextField
                    label={t("formFields.logicalVault1")}
                    name="logicalVault"
                    value={data.logicalVault ? data.logicalVault : "-"}
                />
                <SecureSyncReadOnlyTextField
                    label={t("formFields.returnDate")}
                    name="returnDate"
                    value={data.dateDisplay ? data.dateDisplay : "-"}
                />
                <Box>
                    {disable ? (
                        <SecureSyncReadOnlyTextField
                            label={t("formFields.drpCode")}
                            name="drpCode"
                            value={data.drpCode ? data.drpCode : "-"}
                        />
                    ) : (
                        <>
                            <TextField
                                label={t("formFields.drpCode")}
                                name="drpCode"
                                value={value}
                                onChange={changeDrpCode}
                                inputProps={{ maxLength: 10 }}
                            />
                            <Typography sx={styles.footerCaption}>
                                <Trans i18nKey={"containerSearch.drpWarning"}></Trans>
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default OpenMediaDetailsContainer;
