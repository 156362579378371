import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SecureSyncAlert from "../SecureSyncAlert/SecureSyncAlert";
import CircularLoaderFullPage from "../CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncSelect from "../SecureSyncSelect/SecureSyncSelect";
import styles from "./SecureSyncPrintModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { CSV, DOCS, documentOptions, EXCEL, PDF } from "src/constants/printConstants";
import { getUserDetails } from "src/redux/userDetailsSlice";
import { populateDownloadReport } from "src/services/printServices";
import { getReportConfig } from "src/services/utils";

const SecureSyncPrintModal = ({ open, onClose, payload, targetReport }) => {
    const userDetails = useSelector(getUserDetails);
    const { t } = useTranslation();
    const [reportFormat, setReportFormat] = useState("PDF");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [reportConfig, setReportConfig] = useState(null);

    const downloadReport = async () => {
        setLoading(true);
        let userName = "";
        if (userDetails) {
            // eslint-disable-next-line camelcase
            const { given_name = "", middle_name = "", family_name = "" } = userDetails;
            // eslint-disable-next-line camelcase
            const dotMiddleName = middle_name ? `${middle_name}.` : "";
            // eslint-disable-next-line camelcase
            userName = `${family_name}, ${given_name} ${dotMiddleName} `;
        }
        try {
            await populateDownloadReport(payload, userName, reportFormat, targetReport);
            setLoading(false);
            onClose();
            setErrorMessage("");
        } catch (error) {
            setErrorMessage(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        const getConfig = async () => {
            setLoading(true);
            if (targetReport) {
                const res = await getReportConfig(targetReport);
                setReportConfig(res);
            }
            setLoading(false);
        };
        getConfig();
    }, [targetReport]);

    const downloadOptions = useMemo(
        () => {
            const options = [];
            documentOptions.forEach((item) => {
                const itemObj = {
                    id: item.value,
                    label: t(item.label),
                    value: item.value,
                };
                if (reportConfig) {
                    if (item.value === PDF && reportConfig.pdf) {
                        options.push(itemObj);
                    }
                    if (item.value === DOCS && reportConfig.docs) {
                        options.push(itemObj);
                    }
                    if (item.value === EXCEL && reportConfig.excel) {
                        options.push(itemObj);
                    }
                    if (item.value === CSV && reportConfig.csv) {
                        options.push(itemObj);
                    }
                } else {
                    if (!item.disable) {
                        options.push(itemObj);
                    }
                }
            });
            return options;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [documentOptions, reportConfig]
    );

    const handleClose = () => {
        onClose();
        setErrorMessage("");
    };

    return (
        <>
            <CircularLoaderFullPage show={loading} />

            <SecureSyncModal
                open={open}
                title={t("ssrsPrintService.modalTitle")}
                onClose={handleClose}
                footer={
                    <Box sx={styles.confirmationModalFooter}>
                        <Button autoFocus variant="outlined" onClick={handleClose}>
                            {t("buttonLabels.close")}
                        </Button>
                        <Button
                            disabled={!payload || !targetReport}
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={downloadReport}
                        >
                            {t("buttonLabels.download")}
                        </Button>
                    </Box>
                }
            >
                <Box>
                    {errorMessage && (
                        <Box sx={styles.alertBody}>
                            <SecureSyncAlert
                                message={errorMessage}
                                severity="error"
                                onClose={() => {
                                    setErrorMessage("");
                                }}
                            />
                        </Box>
                    )}
                    <SecureSyncSelect
                        width="500px"
                        id="download-format"
                        label={t("ssrsPrintService.dropDownTitle")}
                        defaultOption={downloadOptions.length > 0 ? reportFormat : ""}
                        options={downloadOptions}
                        value={downloadOptions.length > 0 ? reportFormat : ""}
                        onChange={(e) => {
                            setReportFormat(e.target.value);
                        }}
                        required={true}
                    />
                </Box>
            </SecureSyncModal>
        </>
    );
};

export default SecureSyncPrintModal;
