import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getAllValue } from "src/constants/researchConstants";
import { getAllowedCustomers, getSelectedCustomer } from "src/redux/userDetailsSlice";

const CustomerListDropdown = ({
    required = false,
    type = "standard",
    id,
    label,
    defaultOption,
    showDefaultOption = false,
    width,
    value,
    name,
    onChange,
    onBlur,
    error,
    helperText,
    disabled = false,
    onlySelectedBranch = false,
    enableAll = false,
}) => {
    const { t } = useTranslation();
    const customerLists = useSelector(getAllowedCustomers);
    const selectedCustomer = useSelector(getSelectedCustomer);

    const customerOptions = useMemo(() => {
        const filteredCustomerList =
            onlySelectedBranch && selectedCustomer
                ? customerLists.filter((customer) => customer.district_id === selectedCustomer.district_id)
                : customerLists;
        const firstOption = {
            id: t("common.all"),
            value: getAllValue,
            label: t("common.all"),
        };
        const customers = filteredCustomerList.map((customer) => ({
            id: customer.customer_id.toString(),
            value: customer.customer_id.toString(),
            label: `${customer.customer_number}, ${customer.customer_name}, (${customer.district_name})`,
        }));
        if (enableAll) {
            return [firstOption, ...customers];
        }
        return customers;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlySelectedBranch, selectedCustomer, customerLists, enableAll]);

    return (
        <SecureSyncSelect
            showDefaultOption={showDefaultOption}
            width={width}
            type={type}
            id={id}
            label={label}
            defaultOption={defaultOption}
            required={required}
            options={customerOptions}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            disabled={disabled}
        />
    );
};

CustomerListDropdown.propTypes = {
    type: PropTypes.oneOf(["standard", "outlined", "filled"]),
    required: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    defaultOption: PropTypes.string,
    width: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
};

export default CustomerListDropdown;
