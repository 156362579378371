import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import styles from "./SecureSyncSelect.styles";
import { ReactComponent as ArrowDown } from "src/assets/images/arrow-down.svg";

const defaultValue = "not-value";

const SecureSyncSelect = ({
    required,
    type = "outlined",
    id,
    label,
    defaultOption,
    showDefaultOption,
    options,
    width,
    value,
    name,
    onChange,
    onBlur,
    error,
    helperText,
    sx = null,
    showBlankOption = false, // This the show empty item
    disabled = false,
}) => {
    const [selectValue, setSelectValue] = useState(value || (defaultOption ? defaultValue : ""));
    useEffect(() => {
        if (value) {
            setSelectValue(value);
        } else if (defaultOption) {
            setSelectValue(defaultValue);
        } else {
            setSelectValue("");
        }
    }, [value, defaultOption]);

    const handleChange = (event) => {
        const { value } = event.target;
        setSelectValue(value);
        if (value === defaultValue) {
            onChange({ target: { name, value: "" } });
        } else {
            onChange(event);
        }
    };

    const handleBlur = (event) => {
        setSelectValue(event.target.value); // Update the select value before calling onBlur
        onBlur && onBlur(event);
    };

    return (
        <FormControl required={required} variant={type} sx={styles.formControlWidth(width)} error={error}>
            {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
            <Select
                labelId={`${id}-label`}
                id={id}
                name={name}
                value={selectValue}
                onChange={handleChange}
                onBlur={handleBlur} // Handle blur event directly on Select component
                label={label}
                sx={sx ? sx : styles.selectField}
                IconComponent={(props) => <ArrowDown {...props} style={styles.iconComponent} />}
                disabled={disabled}
            >
                {defaultOption && (
                    <MenuItem
                        sx={showDefaultOption ? styles.displayBlock : styles.displayNone}
                        key={"empty"}
                        value={defaultValue}
                    >
                        {defaultOption}
                    </MenuItem>
                )}
                {showBlankOption && <MenuItem sx={styles.emptyOption} key={"empty"} value={defaultValue} />}
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.value} disabled={option.isDisabled}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

SecureSyncSelect.propTypes = {
    type: PropTypes.oneOf(["standard", "outlined", "filled"]),
    required: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    defaultOption: PropTypes.string,
    width: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
};

export default SecureSyncSelect;
