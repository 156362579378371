import { signInWithCustomToken } from "firebase/auth";
import { fbAuth } from "./firebase";
import { constructOktaHeaders, invokeApi } from "./utils";
import { traceWrapper } from "./firebasePerformance";

/**
 * This function call cloudfunction to generate fb token so that this user can be authenticated
 */
export const getFBToken = async () => {
    const { traceStart, traceStop } = traceWrapper("/ssauth/auth/login");
    traceStart();
    const headers = constructOktaHeaders();

    if (headers) {
        const url = process.env.REACT_APP_CF_URL_AUTH;
        try {
            const res = await invokeApi("POST", url, {}, {}, headers);
            return res?.result?.fbToken;
        } catch (err) {
            console.log(err);
            throw new Error("System error occured. Please contact IRM Support");
        } finally {
            traceStop();
        }
    } else {
        traceStop();
        throw new Error("Unable to get access token");
    }
};

/**
 * This method does signin using custom token provided by FBAdmin app
 * @param {*} token
 */
export const fbLogin = async (token) => {
    const { traceStart, traceStop } = traceWrapper("Fn:SignInWithCustomToken");
    traceStart();
    if (!fbAuth?.currentUser?.getIdToken) {
        try {
            const signedInUser = await signInWithCustomToken(fbAuth, token);
            return signedInUser.user;
        } catch (error) {
            const errorMessage = error.message;
            throw new Error(errorMessage);
        } finally {
            traceStop();
        }
    }
};

/**
 * This should be called when user is not authenticated in okta as a precaution
 */
export const fbSignOut = () => {
    const { traceStart, traceStop } = traceWrapper("Fn:FireBase SignOut");
    traceStart();
    fbAuth
        .signOut()
        .then(() => {})
        .catch(function (error) {
            console.error("Error on signout === ", error);
        })
        .finally(() => {
            traceStop();
        });
};
