/* eslint-disable max-lines */
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Radio,
    Typography,
    TextField,
    RadioGroup,
    DialogContent,
    DialogContentText,
    OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import ArrowDown from "../../../../assets/images/arrow-down.svg";
import styles from "./AddRetentionProfiles.styles";
import {
    getMediaProgramTypes,
    addRetentionProfile,
    updateRetentionProfile,
    deleteRetentionProfile,
} from "src/services/adminService";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { getLanguageLocaleId, getSelectedCustomerId } from "src/services/utils";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { programIds } from "src/constants/programIdsConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";
const initValue = {
    profileName: "",
    programType: "5",
    isDefaultProfileCheck: false,
    profileDescription: "",
    mediaStatus: "N",
    mediaStatusDays: "",
    drpCode: "",
    mediaDescription: "",
    overwriteMediaDescription: false,
};
const validationSchema = (t) =>
    Yup.object().shape({
        profileName: Yup.string().required(t("addRetentionProfile.profileNameRequired")),
        programType: Yup.string().required(t("addRetentionProfile.profileNameRequired")),
        isDefaultProfileCheck: Yup.boolean(),
        mediaStatusDays: Yup.string().when("mediaStatus", ([mediaStatus], schema) => {
            if (mediaStatus === "N") {
                return Yup.string().required(t("addRetentionProfile.required"));
            }
            return schema;
        }),
    });
const AddRetentionProfiles = ({ open, onClose, params, setResponse }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            if (hasSubmitAccess) {
                handleSubmit();
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            setIntValues();
        },
    });

    const { t } = useTranslation();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, setFieldValue, resetForm } =
        useFormik({
            initialValues: initValue,
            enableReinitialize: true,
            validationSchema: validationSchema(t),
            onSubmit: async (values) => {
                await formSubmit();
            },
        });

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formSubmit = async () => {
        setIsLoading(true);
        const payload = {
            name: values.profileName,
            description: values.profileDescription,
            defaultOmDistListFlag: values.isDefaultProfileCheck ? "Y" : "N",
            mediaProgramTypeId: values.programType,
            retentionDays: values.mediaStatus !== "yes" ? values.mediaStatusDays.toString() : null,
            indefiniteRetention: values.mediaStatus,
            drpCode: values.drpCode,
            mediaDescr: values.mediaDescription,
            containerFlag: values.overwriteMediaDescription ? "Y" : "N",
        };
        const payLoadForUpdate = { ...payload, timeStamp: params.timestamp };
        setIsLoading(true);
        if (Object.keys(params).length > 0) {
            const res = await updateRetentionProfile(payLoadForUpdate, params.retention_profile_id);
            if (!res.failure) {
                setResponse({
                    responseMessage: t("addRetentionProfile.retentionProfileSuccessfullyUpdated"),
                });
            }
        } else {
            const res = await addRetentionProfile(payload, getSelectedCustomerId());
            if (!res.failure) {
                setResponse({
                    responseMessage: t("addRetentionProfile.retentionProfileSuccessfullyAdded"),
                });
            }
        }
        setAddModalOpen(false);
        onClose();
        resetForm();
        setIsLoading(false);
    };
    const userLevelID = useSelector(getProgramIdsOfCurrentCustomer);
    const hasSubmitAccess = isUserProgramLevelAvailable(userLevelID, programIds.programId_271);
    const hasDeleteAccess = isUserProgramLevelAvailable(userLevelID, programIds.programId_272);
    const hasAccessForDefaultProfileCheck = isUserProgramLevelAvailable(userLevelID, programIds.programId_273);
    const hasAccessForOverrideWithDesc = isUserProgramLevelAvailable(userLevelID, programIds.programId_274);

    const [mediaType, setMediaType] = useState([]);
    const getMediaType = async () => {
        const mediaType = await getMediaProgramTypes(getLanguageLocaleId());
        setMediaType(mediaType?.rs0 ? mediaType.rs0 : []);
    };
    const getMediaAndRetentionProfile = async () => {
        await getMediaType();
        setIsLoading(false);
    };
    useEffect(() => {
        setIsLoading(true);
        getMediaAndRetentionProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setIntValues = () => {
        Object.keys(params).length > 0
            ? setValues({
                  ...values,
                  profileName: params.profile_name,
                  programType: params.media_program_type_id,
                  isDefaultProfileCheck: params.default_om_dist_list_flag !== "N",
                  profileDescription: params.descr,
                  drpCode: params.drp_code,
                  mediaStatus: params.indefinite_retention_flag,
                  mediaDescription: params.media_descr,
                  overwriteMediaDescription: params.overwrite_container_descr_flag !== "N",
                  mediaStatusDays: params.retention_days,
              })
            : resetForm();
    };

    useEffect(() => {
        setIntValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={open}
                onClose={(e) => {
                    onClose();
                    resetForm();
                }}
                footer={
                    <Box sx={styles.buttonContainer}>
                        <Button variant="outlined" color="primary" onClick={onClose}>
                            {t("addRetentionProfile.cancel")}
                        </Button>
                        {hasDeleteAccess && Object.keys(params).length > 0 && (
                            <Button variant="outlined" color="primary" onClick={() => setAddModalOpen(true)}>
                                {t("addRetentionProfile.delete")}
                            </Button>
                        )}
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                            type="reset"
                            variant="outlined"
                            color="primary"
                            onClick={setIntValues}
                        >
                            {t("addRetentionProfile.reset")}
                        </Button>
                        {hasSubmitAccess && (
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {t("addRetentionProfile.submit")}
                            </Button>
                        )}
                    </Box>
                }
                title={`${Object.keys(params).length > 0 ? t("addRetentionProfile.updateRetentionProfile") : t("addRetentionProfile.addRetentionProfile")} ${t("addRetentionProfile.retentionProfile")}`}
            >
                <Typography variant="h6" sx={styles.retentionProfileTitle}>
                    {t("addRetentionProfile.retentionProfile")}
                </Typography>
                <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                    <Box sx={styles.contactFieldContainer}>
                        <TextField
                            label={t("addRetentionProfile.profileName")}
                            name="profileName"
                            variant="outlined"
                            onChange={handleChange}
                            value={values.profileName}
                            onBlur={handleBlur}
                            error={touched.profileName && Boolean(errors.profileName)}
                            helperText={touched.profileName && errors.profileName}
                            inputProps={{
                                maxLength: 40,
                            }}
                            required
                            fullWidth
                        />
                    </Box>
                    <Box sx={styles.contactFieldContainer}>
                        <FormControl required sx={styles.formControl}>
                            <InputLabel id="countrycode-select-label">
                                {t("addRetentionProfile.programType")}
                            </InputLabel>
                            <Select
                                labelId="countrycode-select-label"
                                name="programType"
                                id="select-lang"
                                onChange={handleChange}
                                value={values.programType}
                                onBlur={handleBlur}
                                error={touched.programType && Boolean(errors.programType)}
                                helperText={touched.programType && errors.programType}
                                label={t("addRetentionProfile.programType")}
                                input={<OutlinedInput label={t("addRetentionProfile.programType")} />}
                                disabled={Object.keys(params).length > 0}
                                IconComponent={() => (
                                    <img src={ArrowDown} alt="Arrow Down" style={{ paddingRight: "15px" }} />
                                )}
                                style={{ flex: 1 }}
                            >
                                {mediaType.map((option) => (
                                    <MenuItem key={option.media_program_type_id} value={option.media_program_type_id}>
                                        {option.descr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {values.programType !== "3" && (
                    <Box sx={styles.singleColumnContainer}>
                        <Box sx={styles.switchLabelContainer}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="isDefaultProfileCheck"
                                        checked={values.isDefaultProfileCheck}
                                        onChange={handleChange}
                                    />
                                }
                                label={t("addRetentionProfile.defaultProfileForOMDList")}
                                disabled={!hasAccessForDefaultProfileCheck}
                            />
                        </Box>
                        {hasAccessForDefaultProfileCheck && (
                            <Box>
                                <Typography variant="body1" color="#252526" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                <Box sx={styles.textField}>
                    <TextField
                        id="authorization-comments"
                        name="profileDescription"
                        label={t("addRetentionProfile.profileDescription")}
                        variant="outlined"
                        onChange={handleChange}
                        value={values.profileDescription}
                        onBlur={handleBlur}
                        multiline
                        fullWidth
                        rows={4}
                        maxRows={10}
                    />
                </Box>
                <Typography variant="h6" sx={styles.retentionProfileTitle}>
                    {t("addRetentionProfile.retentionProfileMediaDefaults")}
                </Typography>
                <Box gap={2} sx={styles.boxPaddingBottom}>
                    <Typography variant="body1" sx={styles.userDetailBody1}>
                        {t("addRetentionProfile.retention")}
                    </Typography>
                    <RadioGroup name="row-radio-buttons-group">
                        <Box sx={styles.contactFieldContainer}>
                            <FormControlLabel
                                name="mediaStatus"
                                value="N"
                                onChange={handleChange}
                                control={<Radio />}
                                label=""
                                checked={values.mediaStatus === "N"}
                            />
                            <TextField
                                label={t("addRetentionProfile.days")}
                                name="mediaStatusDays"
                                variant="outlined"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mediaStatusDays}
                                error={touched.mediaStatusDays && Boolean(errors.mediaStatusDays)}
                                helperText={touched.mediaStatusDays && errors.mediaStatusDays}
                                disabled={values.mediaStatus === "Y"}
                                sx={styles.mediaStatus}
                            />
                            <FormControlLabel
                                name="mediaStatus"
                                value="Y"
                                onChange={(e) => {
                                    handleChange("mediaStatus")(e);
                                    setFieldValue("mediaStatusDays", "");
                                }}
                                control={<Radio />}
                                label={t("addRetentionProfile.indefinite")}
                                checked={values.mediaStatus === "Y"}
                            />
                        </Box>
                    </RadioGroup>
                </Box>
                <Box sx={styles.textField}>
                    {values.programType !== "3" && (
                        <TextField
                            label={t("addRetentionProfile.drpCode")}
                            name="drpCode"
                            variant="outlined"
                            value={values.drpCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{
                                maxLength: 10,
                            }}
                        />
                    )}
                </Box>
                <Box sx={styles.textField}>
                    <TextField
                        id="authorization-comments"
                        name="mediaDescription"
                        label={t("addRetentionProfile.mediaDescription")}
                        value={values.mediaDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={4}
                        maxRows={10}
                    />
                    {values.programType === "3" && (
                        <Box sx={styles.switchContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="overwriteMediaDescription"
                                            checked={values.overwriteMediaDescription}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={t("addRetentionProfile.overwriteWithMediaDescription")}
                                    disabled={!hasAccessForOverrideWithDesc}
                                />
                            </Box>
                            {hasAccessForOverrideWithDesc && (
                                <Box>
                                    <Typography variant="body1" color="#252526" component="div" sx={styles.paddingLeft}>
                                        <Trans i18nKey={"common.toggleOnOff"} />
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </SecureSyncModal>
            <SecureSyncModal
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                footer={
                    <Box sx={styles.buttonContainer}>
                        <Button onClick={() => setConfirmDialog(false)}>{t("addRetentionProfile.cancel")}</Button>
                        <Button>{t("addRetentionProfile.ok")}</Button>
                    </Box>
                }
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("addRetentionProfile.deleteConfirmTitle")}
                    </DialogContentText>
                </DialogContent>
            </SecureSyncModal>
            <SecureSyncModal
                open={addModalOpen}
                title={t("addRetentionProfile.confirm")}
                footer={
                    <Box sx={styles.confirmationModalFooter}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={async () => {
                                await deleteRetentionProfile(params.retention_profile_id);
                                setAddModalOpen(false);
                                onClose();
                                resetForm();
                                setResponse({
                                    responseMessage: t("addRetentionProfile.retentionProfileSuccessfullyDeleted"),
                                });
                            }}
                        >
                            {t("common.yes")}
                        </Button>
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={() => {
                                setAddModalOpen(false);
                            }}
                        >
                            {t("common.no")}
                        </Button>
                    </Box>
                }
            >
                <Typography sx={styles.confirmMessage}>{t("addRetentionProfile.deleteConfirmMessage")}</Typography>
            </SecureSyncModal>
        </>
    );
};

export default AddRetentionProfiles;
