/* eslint-disable max-lines */
import { useState, useEffect, useMemo, useRef } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Switch,
    Radio,
    RadioGroup,
    Typography,
    TextField,
    Grid,
    useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styles from "./CustomerPreferences.styles";
import ResolveDiscrepanciesModal from "./ResolveDiscrepanciesModal";
import MediaDestructionModal from "./MediaDestructionModal";
import ApplyPreferencesModal from "./ApplyPreferencesModal";
import InfoCircle from "src/components/Icons/InfoCircle/InfoCircle";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import {
    getPreferencesService,
    getNonPreferenceDataService,
    updateCustPreferencesService,
} from "src/services/adminService";
import { getDropdownList } from "src/services/commonServices";
import { allowedPrefList, dropDownListTypeId } from "src/constants/adminConstants";
import { preferenceTypeId } from "src/constants/preferenceServiceConstants";
import { holidayLists } from "src/constants/routeConstants/administrationRouterFullPaths";
import { getProgramIdsOfCurrentCustomer, getSelectedCustomer } from "src/redux/userDetailsSlice";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { programIds } from "src/constants/programIdsConstants";
import { destructionAuthorizationWaiverFileName, staticFile } from "src/constants/fileConstants";
import { getLanguageLocaleId } from "src/services/utils";
import { downloadInstructionGuide } from "src/utils/editMyPreferencesHelper";
import { yesFlag, noFlag } from "src/constants/serviceConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        userVerificationDays: Yup.number()
            .max(365, t("customerPreferences.userVerificationErrorMessage", { minValue: 1, maxValue: 365 }))
            .required(t("customerPreferences.userVerificationRequired")),
        commentRequired: Yup.boolean(),
        allowUserToScanVerify: Yup.boolean(),
        mediaDestructionRequest: Yup.boolean(),
        callResolveDiscrepancies: Yup.boolean(),
        allowUserToSetDefaultCustomer: Yup.boolean(),
        retainOpenMediaDescription: Yup.boolean(),
        deleteContainerDescription: Yup.boolean(),
        customerNameDisplay: Yup.string().required(t("customerPreferences.customerDisplayRequired")),
        bypassDestructionRequest: Yup.boolean(),
        preferenceApplied: Yup.string(),
        personnelEmail: Yup.string().when(
            "customerDeliveryPickupNotification",
            ([customerDeliveryPickupNotification], schema) => {
                if (customerDeliveryPickupNotification) {
                    return Yup.string().required(t("customerPreferences.personnelToReceiveEmail"));
                }
                return schema;
            }
        ),
    });

const initValues = {
    userVerificationDays: "",
    retainOpenMediaDescription: false,
    deleteContainerDescription: false,
    commentRequired: false,
    allowUserToSetDefaultCustomer: false,
    allowUserToScanVerify: false,
    holidayService: "",
    preferenceApplied: "",
    customerNameDisplay: "",
    callResolveDiscrepancies: false,
    customerDeliveryPickupNotification: "",
    personnelEmail: "",
    mediaDestructionRequest: false,
    bypassDestructionRequest: false,
};
const CustomerPreferences = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            setIntValues();
        },
    });
    const { t } = useTranslation();
    const userVerificationDaysRef = useRef(null);
    const customerNameDisplayRef = useRef(null);
    const personnelEmailRef = useRef(null);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);
    const hasUpdateAccess = isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_237);
    const hasOpenMediaAccess = isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_229);
    const hasHolidayServiceUpdateAccess = isUserProgramLevelAvailable(
        programIdsForCurrentCustomer,
        programIds.programId_352
    );
    const theme = useTheme();
    const { values, handleBlur, handleChange, handleSubmit, setValues, setFieldValue, touched, errors, submitCount } =
        useFormik({
            initialValues: initValues,
            validationSchema: validationSchema(t),
            onSubmit: async (values) => {
                await formSubmit();
            },
        });

    const showWaiverFile = async (fileForms, fileName) => {
        const waiverLink = await downloadInstructionGuide(fileForms, fileName);
        window.open(waiverLink, "_blank");
    };

    const formSubmit = async () => {
        setIsLoading(true);
        const updatedUserPreferences = userPreferences.map((pref) => {
            switch (pref.preference_type_id) {
                case preferenceTypeId.commentRequired:
                    return {
                        ...pref,
                        preferenceValue: values.commentRequired ? yesFlag : noFlag,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                case preferenceTypeId.userVerificationDays:
                    return {
                        ...pref,
                        preferenceValue: values.userVerificationDays,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };

                case preferenceTypeId.customerNameDisplay:
                    return {
                        ...pref,
                        preferenceValue: values.customerNameDisplay,
                        applyToAllCustomersBit: "0",
                    };
                case preferenceTypeId.retainOpenMediaDescription:
                    return {
                        ...pref,
                        preferenceValue: values.retainOpenMediaDescription ? yesFlag : noFlag,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                case preferenceTypeId.deleteContainerDescription:
                    return {
                        ...pref,
                        preferenceValue: values.deleteContainerDescription ? yesFlag : noFlag,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                case preferenceTypeId.allowUsertoSetDefaultCustomer:
                    return {
                        ...pref,
                        preferenceValue: values.allowUserToSetDefaultCustomer ? yesFlag : noFlag,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                case preferenceTypeId.allowUserToScanVerify:
                    return {
                        ...pref,
                        preferenceValue: values.allowUserToScanVerify ? yesFlag : noFlag,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                case preferenceTypeId.callResolveDiscrepancies:
                    return {
                        ...pref,
                        preferenceValue: values.callResolveDiscrepancies ? yesFlag : noFlag,
                        applyToAllCustomersBit: "0",
                    };
                case preferenceTypeId.customerDeliveryPickupNotification:
                    return {
                        ...pref,
                        preferenceValue: values.customerDeliveryPickupNotification ? yesFlag : noFlag,
                        applyToAllCustomersBit: "0",
                    };
                case preferenceTypeId.mediaDestructionRequest:
                    return {
                        ...pref,
                        preferenceValue: values.mediaDestructionRequest ? yesFlag : noFlag,
                        applyToAllCustomersBit: "0",
                    };
                case preferenceTypeId.bypassDestructionRequest:
                    return {
                        ...pref,
                        preferenceValue: values.bypassDestructionRequest ? yesFlag : noFlag,
                        applyToAllCustomersBit: "0",
                    };
                case preferenceTypeId.holidayService:
                    return {
                        ...pref,
                        preferenceValue: values.holidayService,
                        applyToAllCustomersBit: values.preferenceApplied === yesFlag ? "1" : "0",
                    };
                default:
                    return pref;
            }
        });
        const res = await updateCustPreferencesService(
            values.preferenceApplied,
            values.emailVaultStatusFlag,
            allCustomerIdForElevel,
            values.personnelEmail,
            updatedUserPreferences
        );
        if (!res.failure) {
            setIsLoading(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
            let successMsgCustomersNumber = "";
            if (values.preferenceApplied === "Y") {
                successMsgCustomersNumber = res.customerList;
            } else {
                successMsgCustomersNumber = selectedCustomer.customer_number;
            }
            getPreference();
            handleOpenSnackbar({
                severity: "success",
                message: t("customerPreferences.preferencesAppliedToCustomers", {
                    customerNumber: successMsgCustomersNumber,
                }),
            });
        } else {
            setIsLoading(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
            handleOpenSnackbar({ severity: "error", message: res.errorCode });
        }
    };

    const [userPreferences, setUserPreferences] = useState([]);
    const [personnelData, setPersonnelData] = useState([]);
    const [vaultStatus, setVaultStatus] = useState("");
    const [personnelEmail, setPersonnelEmail] = useState([]);
    const [customerNonPreferences, setCustomerNonPreferences] = useState([]);
    const [holidayList, setHolidayList] = useState([]);
    const [eLevelCustomerCount, setELevelCustomerCount] = useState(0);
    const [snackbarState, setSnackbarState] = useState({ show: false, message: "", severity: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [resolveDiscrepanciesModalShow, setResolveDiscrepanciesModalShow] = useState(false);
    const [mediaDestructionModalShow, setMediaDestructionModalShow] = useState(false);
    const [applyPreferencesModalShow, setApplyPreferencesModalShow] = useState(false);
    const handleOpenSnackbar = (msg) => {
        setSnackbarState({
            show: true,
            severity: msg.severity,
            message: msg.message,
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarState({ show: false, message: "", severity: "" });
    };

    const personalDropdown = useMemo(() => {
        if (personnelData?.length > 0) {
            return personnelData
                .sort((a, b) => a.personnel_name.toLowerCase().localeCompare(b.personnel_name.toLowerCase()))
                ?.map((p) => ({
                    id: p.personnel_id?.toString(),
                    value: p.personnel_id?.toString(),
                    label: p.personnel_name?.toString(),
                }));
        } else {
            return [];
        }
    }, [personnelData]);

    const allCustomersForElevel = useMemo(() => {
        if (customerNonPreferences.length > 0) {
            return customerNonPreferences
                ?.filter((i) => i.is_e_level_flag === yesFlag)
                ?.map((i) => i.customer_number)
                ?.sort((a, b) => a.localeCompare(b))
                .join(",");
        }
    }, [customerNonPreferences]);

    const allCustomerIdForElevel = useMemo(() => {
        if (customerNonPreferences.length > 0) {
            return customerNonPreferences?.map((i) => i.customer_id).join(",");
        }
    }, [customerNonPreferences]);

    const selectedCustomerForPreferenceDisplay = useMemo(() => {
        if (customerNonPreferences.length > 0 && selectedCustomer.customer_number !== "") {
            return customerNonPreferences?.find(
                (customer) => customer?.customer_number?.trim() === selectedCustomer?.customer_number?.trim()
            ).customer_name;
        }
    }, [customerNonPreferences, selectedCustomer.customer_number]);

    const handleResolveDiscrepancies = () => {
        if (!values.callResolveDiscrepancies && vaultStatus === noFlag) {
            setResolveDiscrepanciesModalShow(true);
            return;
        }
        setFieldValue("callResolveDiscrepancies", !values.callResolveDiscrepancies);
    };

    const handleMediaDestruction = () => {
        if (!values.mediaDestructionRequest) {
            setMediaDestructionModalShow(true);
            return;
        }
        setFieldValue("mediaDestructionRequest", false);
    };

    const handleVerificationDays = (e) => {
        if (String(e.target.value).length > 3) {
            return;
        }
        setFieldValue("userVerificationDays", e.target.value);
    };

    const handlePreferenceChange = (e) => {
        if (e.target.value === yesFlag) {
            setApplyPreferencesModalShow(true);
        } else {
            setFieldValue("preferenceApplied", e.target.value);
        }
    };

    const setIntValues = () => {
        const obj = userPreferences.reduce((prevObject, pref) => {
            switch (pref.preference_type_id) {
                case preferenceTypeId.userVerificationDays:
                    return { ...prevObject, userVerificationDays: pref.preference_value };
                case preferenceTypeId.commentRequired:
                    return { ...prevObject, commentRequired: pref.preference_value === yesFlag };
                case preferenceTypeId.allowUserToScanVerify:
                    return { ...prevObject, allowUserToScanVerify: pref.preference_value === yesFlag };
                case preferenceTypeId.mediaDestructionRequest:
                    return { ...prevObject, mediaDestructionRequest: pref.preference_value === yesFlag };
                case preferenceTypeId.callResolveDiscrepancies:
                    return { ...prevObject, callResolveDiscrepancies: pref.preference_value === yesFlag };
                case preferenceTypeId.allowUsertoSetDefaultCustomer:
                    return { ...prevObject, allowUserToSetDefaultCustomer: pref.preference_value === yesFlag };
                case preferenceTypeId.retainOpenMediaDescription:
                    return { ...prevObject, retainOpenMediaDescription: pref.preference_value === yesFlag };
                case preferenceTypeId.deleteContainerDescription:
                    return { ...prevObject, deleteContainerDescription: pref.preference_value === yesFlag };
                case preferenceTypeId.customerNameDisplay:
                    return {
                        ...prevObject,
                        customerNameDisplay: pref.preference_value || selectedCustomerForPreferenceDisplay.trim(),
                    };
                case preferenceTypeId.customerDeliveryPickupNotification:
                    return { ...prevObject, customerDeliveryPickupNotification: pref.preference_value === yesFlag };
                case preferenceTypeId.bypassDestructionRequest:
                    return { ...prevObject, bypassDestructionRequest: pref.preference_value === yesFlag };
                case preferenceTypeId.holidayService:
                    return { ...prevObject, holidayService: pref.preference_value };
                default:
                    return prevObject;
            }
        }, {});
        setValues({
            ...values,
            personnelEmail: personnelEmail[0]?.email_receipt_personnel_id || "",
            ...obj,
            preferenceApplied: "N",
        });
        setIsLoading(false);
    };

    useEffect(() => {
        if (submitCount > 0) {
            const { userVerificationDays, customerNameDisplay, personnelEmail } = errors;
            if (userVerificationDays) {
                userVerificationDaysRef.current?.focus();
            } else if (customerNameDisplay) {
                customerNameDisplayRef.current?.focus();
            } else if (personnelEmail) {
                personnelEmailRef.current?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    useEffect(() => {
        if (holidayList.length > 0) {
            setIntValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holidayList]);

    useEffect(() => {
        const eLevelCount = customerNonPreferences?.filter((customer) => customer.is_e_level_flag === yesFlag);
        setELevelCustomerCount(eLevelCount.length);
    }, [customerNonPreferences]);

    const getPreference = async () => {
        setIsLoading(true);
        const [prefList, nonprefListCust, dropdownList] = await Promise.all([
            getPreferencesService(preferenceTypeId.customer, allowedPrefList.customer),
            getNonPreferenceDataService(),
            getDropdownList(dropDownListTypeId.holidayRule),
        ]);
        setUserPreferences(prefList?.preferences ? prefList.preferences : []);
        setPersonnelData(prefList?.personal ? prefList.personal : []);
        setPersonnelEmail(prefList?.email ? prefList.email : []);
        setVaultStatus(
            prefList?.vaultExist[0]?.vault_status_report_recipient_exist
                ? prefList?.vaultExist[0]?.vault_status_report_recipient_exist
                : ""
        );
        setCustomerNonPreferences(nonprefListCust.data);
        const holidayRule = dropdownList.rs0
            .filter((item) => item.language_id === getLanguageLocaleId() && item.descr !== "")
            .sort((a, b) => a.display_order.localeCompare(b.display_order))
            .map((item) => ({
                id: item.dropdown_list_id,
                value: item.dropdown_list_id,
                label: item.descr,
                display: item.display_order,
            }));
        setHolidayList(holidayRule);
        setIsLoading(false);
    };

    useEffect(() => {
        getPreference();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.mainContainer}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle>{t("customerPreferences.customerPreferences")}</PageTitle>
                        <SecureSyncSnackBar
                            openSnackBar={snackbarState.show}
                            message={snackbarState.message}
                            severity={snackbarState.severity}
                            onClose={handleCloseSnackbar}
                        />
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            {t("customerPreferences.onlyAuthorizedUser")}
                        </Typography>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.userVerificationDays")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            {t("customerPreferences.enterTheAmount")}
                        </Typography>
                        <TextField
                            label={t("customerPreferences.userVerificationDays")}
                            name="userVerificationDays"
                            type="number"
                            variant="outlined"
                            value={values.userVerificationDays}
                            onChange={handleVerificationDays}
                            onBlur={handleBlur}
                            disabled={!hasUpdateAccess}
                            sx={styles.verificationField}
                            error={touched.userVerificationDays && Boolean(errors.userVerificationDays)}
                            helperText={touched.userVerificationDays && errors.userVerificationDays}
                            required
                            inputRef={userVerificationDaysRef}
                        />
                        {hasOpenMediaAccess && customerNonPreferences[0]?.has_om_logical_vault_flag === yesFlag && (
                            <>
                                <Divider />
                                <PageSubTitle>{t("customerPreferences.retainOpenMedia")}</PageSubTitle>
                                <Typography variant="body1" sx={styles.editPreferenceBody1}>
                                    <Trans i18nKey={"customerPreferences.toggleRetainExistingDescription"} />
                                </Typography>
                                <Box sx={styles.singleColumnContainer}>
                                    <Box sx={styles.switchLabelContainer}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="retainOpenMediaDescription"
                                                    checked={Boolean(values.retainOpenMediaDescription)}
                                                    onChange={handleChange}
                                                    disabled={!hasUpdateAccess}
                                                />
                                            }
                                            label={t("customerPreferences.retainOpenMediaDesc")}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                            <Trans i18nKey={"common.toggleOnOff"} />
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        )}
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.deleteContainerDescription")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.toggleDeleteContainerDescription"} />
                        </Typography>
                        <Box sx={styles.singleColumnContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="deleteContainerDescription"
                                            checked={Boolean(values?.deleteContainerDescription)}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.deleteContainerDescriptionDelivery")}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.commentRequired")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectToRequireAll"} />
                        </Typography>
                        <Box sx={styles.singleColumnContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="commentRequired"
                                            checked={Boolean(values.commentRequired)}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.commentRequiredDesc")}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.allowUserstoSet")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectToAllowUsers"} />
                        </Typography>
                        <Box sx={styles.singleColumnContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="allowUserToSetDefaultCustomer"
                                            checked={Boolean(values.allowUserToSetDefaultCustomer)}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.allowUserstoSetLogin")}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    {t("customerPreferences.noSelected")}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.allowUsersToScanVerify")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectYesToAllowToScanVerify"} />
                        </Typography>
                        <Box sx={styles.singleColumnContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="allowUserToScanVerify"
                                            checked={Boolean(values.allowUserToScanVerify)}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.allowUsersToScanVerifyMountain")}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.holidayService")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            {t("customerPreferences.selectValueFromDropdown")}
                        </Typography>
                        <Grid sx={styles.contactFieldContainer} container spacing={2}>
                            <Grid item xs={6}>
                                <Box>
                                    <FormControl sx={styles.formControl}>
                                        <SecureSyncSelect
                                            name="holidayService"
                                            type="outlined"
                                            label={t("customerPreferences.language")}
                                            options={holidayList}
                                            value={values.holidayService}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to={holidayLists}>
                                    <Typography sx={styles.link}>
                                        {t("customerPreferences.ironMountainHolidays")}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        {eLevelCustomerCount > 1 && hasUpdateAccess && (
                            <>
                                <Box sx={styles.radioContainer}>
                                    <PageSubTitle>{t("customerPreferences.determinePreferenceApplied")}</PageSubTitle>
                                    <Typography variant="body1" sx={styles.editPreferenceBody1}>
                                        {t("customerPreferences.optionToApply")}
                                    </Typography>
                                    <Box sx={styles.contactFieldContainer}>
                                        <RadioGroup name="row-radio-buttons-group">
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            name="preferenceApplied"
                                                            value="N"
                                                            checked={values.preferenceApplied === noFlag}
                                                            onChange={handlePreferenceChange}
                                                            disabled={!hasUpdateAccess}
                                                        />
                                                    }
                                                    label={`${t("customerPreferences.applyPreferenceToCustomer")} ${selectedCustomer.customer_number}`}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            name="preferenceApplied"
                                                            value="Y"
                                                            checked={values.preferenceApplied === yesFlag}
                                                            onChange={handlePreferenceChange}
                                                            disabled={!hasUpdateAccess}
                                                        />
                                                    }
                                                    label={t("customerPreferences.applyPreferenceToAll")}
                                                />
                                                <SecureSyncIconTooltip
                                                    title={
                                                        <>
                                                            <Typography>
                                                                <Box component="span">
                                                                    {t("customerPreferences.allCustomers")}
                                                                </Box>
                                                                <br />
                                                                <Box component="span" sx={styles.footNotes}>
                                                                    {allCustomersForElevel}
                                                                </Box>
                                                            </Typography>
                                                        </>
                                                    }
                                                    placement="right"
                                                    icon={<InfoCircle fill={theme.palette.im_mid_blue["500"]} />}
                                                />
                                            </Box>
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            </>
                        )}
                        <PageTitle>
                            {t("customerPreferences.preferencesSpecificToCustomer")} {selectedCustomer.customer_number}
                        </PageTitle>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.securesyncCustomerName")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            {t("customerPreferences.enterFriendlyName")}
                        </Typography>
                        <Grid sx={styles.contactFieldContainer} container spacing={2}>
                            <Grid item xs={3}>
                                <Box sx={styles.labelContainer}>
                                    <TextField
                                        label={t("customerPreferences.securesyncCustomerNameDisplay")}
                                        name="customerNameDisplay"
                                        inputProps={{ maxLength: 35 }}
                                        variant="outlined"
                                        value={values.customerNameDisplay}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!hasUpdateAccess}
                                        error={touched.customerNameDisplay && Boolean(errors.customerNameDisplay)}
                                        helperText={touched.customerNameDisplay && errors.customerNameDisplay}
                                        required
                                        inputRef={customerNameDisplayRef}
                                        sx={styles.customerNameDisplayField}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={9} sx={styles.customerDisplay}>
                                <Box>
                                    <Typography variant="body1" component="div" sx={styles.noteDescription}>
                                        {t("customerPreferences.customerNameOnInvoice")}
                                    </Typography>
                                    <Typography>{selectedCustomer?.customer_name?.trim()}</Typography>
                                    <Typography variant="body1" component="div" sx={styles.noteDescription}>
                                        {t("customerPreferences.noteModifications")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {hasOpenMediaAccess && customerNonPreferences[0]?.has_om_logical_vault_flag === yesFlag && (
                            <>
                                <Divider />
                                <PageSubTitle>{t("customerPreferences.doNotCallToResolveDependencies")}</PageSubTitle>
                                <Typography variant="body1" sx={styles.editPreferenceBody1}>
                                    <Trans i18nKey={"customerPreferences.selectYesToCall"} />
                                </Typography>
                                <Box sx={styles.singleColumnContainer}>
                                    <Box sx={styles.switchLabelContainer}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="callResolveDiscrepancies"
                                                    checked={values.callResolveDiscrepancies}
                                                    onChange={handleResolveDiscrepancies}
                                                    disabled={!hasUpdateAccess}
                                                />
                                            }
                                            label={t("customerPreferences.doNotCallToResolveDependencies2")}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                            <Trans i18nKey={"common.toggleOnOff"} />
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        )}
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.customerDeliveryPickup")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectYesToReceiveEmail"} />
                        </Typography>
                        <Grid sx={styles.contactFieldContainer} container spacing={2}>
                            <Grid item xs={5}>
                                <Box sx={styles.switchLabelContainer}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="customerDeliveryPickupNotification"
                                                checked={Boolean(values.customerDeliveryPickupNotification)}
                                                onChange={handleChange}
                                                disabled={!personalDropdown.length || !hasUpdateAccess}
                                                inputRef={personnelEmailRef}
                                            />
                                        }
                                        label={t("customerPreferences.customerDeliveryPickup2")}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                        <Trans i18nKey={"common.toggleOnOff"} />
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <SecureSyncSelect
                                    name="personnelEmail"
                                    label={t("customerPreferences.selectPersonnelToReceiveEmail")}
                                    options={personalDropdown || []}
                                    value={values.personnelEmail}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={!hasUpdateAccess || !values.customerDeliveryPickupNotification}
                                    showBlankOption={true}
                                    error={touched.personnelEmail && Boolean(errors.personnelEmail)}
                                    helperText={touched.personnelEmail && errors.personnelEmail}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.placeHoldOnMedia")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectYesToPreventCreationOfMedia"} />
                        </Typography>
                        <Box sx={styles.contactFieldContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="mediaDestructionRequest"
                                            checked={Boolean(values.mediaDestructionRequest)}
                                            onChange={handleMediaDestruction}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.placeHoldOnMediaRequests")}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                    <Trans i18nKey={"common.toggleOnOff"} />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <PageSubTitle>{t("customerPreferences.bypassAuthorization")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            <Trans i18nKey={"customerPreferences.selectyesForParameter"} />
                        </Typography>
                        <Box sx={styles.contactFieldContainer}>
                            <Box sx={styles.switchLabelContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="bypassDestructionRequest"
                                            checked={Boolean(values.bypassDestructionRequest)}
                                            onChange={handleChange}
                                            disabled={!hasUpdateAccess}
                                        />
                                    }
                                    label={t("customerPreferences.placeHoldOnMediaRequests?")}
                                />
                            </Box>
                            <Grid sx={styles.contactFieldContainer} container spacing={2}>
                                <Grid item xs={8}>
                                    <Box>
                                        <Typography variant="body1" component="div" sx={styles.paddingLeft}>
                                            <Trans i18nKey={"common.toggleOnOff"} />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        sx={styles.link2}
                                        onClick={() =>
                                            showWaiverFile(
                                                staticFile["destructionAuthorizationWaiver"],
                                                destructionAuthorizationWaiverFileName
                                            )
                                        }
                                    >
                                        {t("customerPreferences.destructionAuthorizationWaiver")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {(hasUpdateAccess || hasHolidayServiceUpdateAccess) && (
                            <>
                                <Divider />
                                <Divider />
                                <Box sx={styles.buttonContainer}>
                                    <Button
                                        title={getKeyBoardLabel(shortCutKeys.reset)}
                                        variant="outlined"
                                        color="primary"
                                        onClick={setIntValues}
                                    >
                                        {t("common.reset")}
                                    </Button>
                                    <Button
                                        title={getKeyBoardLabel(shortCutKeys.submit)}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        {t("common.submit")}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
            <ApplyPreferencesModal
                handleConfirm={() => {
                    setApplyPreferencesModalShow(false);
                    setFieldValue("preferenceApplied", yesFlag);
                }}
                onClose={() => {
                    setApplyPreferencesModalShow(false);
                }}
                open={applyPreferencesModalShow}
            />
            <ResolveDiscrepanciesModal
                handleConfirm={() => {
                    setResolveDiscrepanciesModalShow(false);
                }}
                onClose={() => {
                    setResolveDiscrepanciesModalShow(false);
                }}
                open={resolveDiscrepanciesModalShow}
            />
            <MediaDestructionModal
                handleConfirm={() => {
                    setMediaDestructionModalShow(false);
                    setFieldValue("mediaDestructionRequest", true);
                }}
                onClose={() => {
                    setMediaDestructionModalShow(false);
                }}
                open={mediaDestructionModalShow}
            />
        </>
    );
};

export default CustomerPreferences;
