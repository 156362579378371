import { Alert, AlertTitle, Box, Collapse, Container, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./UserAccountDetails.styles";
import { getPersonalInformation } from "src/services/authorizationService";
import EditAccountFrom from "src/components/Administration/Authorization/EditAccountForm/EditAccountFrom";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { useLoadCountries } from "src/customHooks";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getBasicUserInfo } from "src/redux/userDetailsSlice";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";

const ShowAlertTitle = ({ message }) => {
    return message && <AlertTitle>{message}</AlertTitle>;
};

const UserAccountDetails = () => {
    const [msgParams, setMsgParams] = useState({
        error: false,
        message: "",
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [personalData, setPersonalData] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [msgOpen, setMsgOpen] = useState(false);
    const [isActiveUser, setActiveUser] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [sucessPopup, setSuccessPopup] = useState({
        sucMessage: "",
        dRCancelledMessage: "",
        interactionCancelledMessage: "",
        cancelledMessage: "",
        mailSendMessage: "",
    });

    // eslint-disable-next-line no-unused-vars
    const { isLoading: isCountriesLoading } = useLoadCountries();
    const selectedPerson = useSelector(getBasicUserInfo);
    const params = useParams();

    const cleanMsg = () => {
        setSuccessPopup({
            sucMessage: "",
            dRCancelledMessage: "",
            interactionCancelledMessage: "",
            cancelledMessage: "",
            mailSendMessage: "",
        });
        setMsgOpen(false);
        setMsgParams({
            error: false,
            message: "",
        });
    };
    useEffect(() => {
        const getAuthLevel = async () => {
            setLoading(true);
            const personalDataTemp = await getPersonalInformation(params.userId);
            setPersonalData(personalDataTemp.personalData);
            setLoading(false);
        };
        if (params.accountId) {
            getAuthLevel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const openSuccessMsgProd = (res) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (res.error) {
            setMsgParams(res);
        } else {
            handleOpenSnackbar();
            setSuccessPopup({
                sucMessage: res.sucMessage,
                dRCancelledMessage: res.dRCancelledMessage,
                interactionCancelledMessage: res.interactionCancelledMessage,
                cancelledMessage: res.cancelledMessage,
                mailSendMessage: res.mailSendMessage,
            });
            if (
                res.dRCancelledMessage ||
                res.interactionCancelledMessage ||
                res.cancelledMessage ||
                res.mailSendMessage
            ) {
                setMsgOpen(true);
            }
        }
    };

    const isLoggedInUser = useMemo(() => {
        return selectedPerson?.sys_user_id === params.userId;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPerson]);

    const displayName = useMemo(() => {
        if (personalData) {
            // eslint-disable-next-line camelcase
            const { first_name = "", middle_name = "", last_name = "" } = personalData;
            // eslint-disable-next-line camelcase
            const dotMiddleName = middle_name ? `${middle_name}.` : "";
            // eslint-disable-next-line camelcase
            return `${first_name} ${dotMiddleName} ${last_name}`;
        } else {
            return "";
        }
    }, [personalData]);

    return (
        <Container>
            <CircularLoaderFullPage show={isLoading} />
            <Collapse in={msgOpen}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setMsgOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={msgParams?.error ? "error" : "info"}
                    sx={styles.successAlert}
                >
                    {msgParams?.error ? (
                        <ShowAlertTitle message={sucessPopup.message} />
                    ) : (
                        <>
                            <ShowAlertTitle message={sucessPopup.interactionCancelledMessage} />
                            <ShowAlertTitle message={sucessPopup.dRCancelledMessage} />
                            <ShowAlertTitle message={sucessPopup.cancelledMessage} />
                            <ShowAlertTitle message={sucessPopup.mailSendMessage} />
                        </>
                    )}
                </Alert>
            </Collapse>
            <Box sx={styles.mainContainer}>
                <Box sx={styles.innerContainer}>
                    <SecureSyncSnackBar
                        openSnackBar={snackbarOpen}
                        message={sucessPopup.sucMessage}
                        severity={msgParams?.error ? "error" : "success"}
                        onClose={handleCloseSnackbar}
                    />
                    <PageTitle>
                        <Trans i18nKey="userDetails.userAccountHeader" />
                    </PageTitle>
                    {isLoggedInUser && (
                        <Typography variant="body1" sx={styles.body1}>
                            <Trans
                                i18nKey={"userDetails.reviceData"}
                                sx={styles.buttonSubmit}
                                values={{ buttonLabel: t("buttonLabels.submit") }}
                            />
                        </Typography>
                    )}

                    {!isActiveUser && (
                        <Typography variant="body1" sx={styles.body1}>
                            <Trans i18nKey="userDetails.noInfoForTheAccount" />
                        </Typography>
                    )}

                    {isActiveUser && !isLoggedInUser && (
                        <Typography variant="body1" sx={styles.body1}>
                            <Trans i18nKey="userDetails.removeAccountMsg" />
                        </Typography>
                    )}

                    <Typography variant="body1" sx={styles.body1}>
                        <Trans
                            i18nKey="userDetails.customerAccount"
                            values={{
                                account: selectedAccount?.customer_number || "",
                                name: displayName || "",
                            }}
                        />
                        <br />
                        {!isActiveUser && (
                            <Trans
                                i18nKey="userDetails.mostRecentDataDisplay"
                                values={{ buttonLabel: t("buttonLabels.submit") }}
                            />
                        )}
                    </Typography>
                    <Divider />
                </Box>
            </Box>
            <EditAccountFrom
                openSuccessMsg={openSuccessMsgProd}
                personalData={personalData}
                setSelectedAccount={setSelectedAccount}
                setActiveUser={setActiveUser}
                isActiveUser={isActiveUser}
                isLoggedInUser={isLoggedInUser}
                displayName={displayName}
                cleanMsg={cleanMsg}
            />
        </Container>
    );
};

export default UserAccountDetails;
