import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { getCountryListService } from "src/services/commonServices";
import { getGlobalAttributeValues } from "src/services/processMediaService";

const initialState = {
    supportedCountries: {
        status: fetchStatus.notStarted,
        data: [],
    },
    globalAttributes: {
        50: {
            status: fetchStatus.notStarted,
            mainDistrictId: "",
            data: {},
        },
        60: {
            status: fetchStatus.notStarted,
            mainDistrictId: "",
            data: {},
        },
    },
};

export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCountryList.pending, (state) => {
            state.supportedCountries.status = fetchStatus.loading;
        });
        builder.addCase(fetchCountryList.fulfilled, (state, action) => {
            state.supportedCountries.status = fetchStatus.completed;
            state.supportedCountries.data = action.payload;
        });
        builder.addCase(fetchGlobalAttr.pending, (state, action) => {
            const { systemId } = action.meta.arg;
            state.globalAttributes[systemId].status = fetchStatus.loading;
        });
        builder.addCase(fetchGlobalAttr.fulfilled, (state, action) => {
            const { systemId, mainDistrictId } = action.meta.arg;
            state.globalAttributes[systemId].status = fetchStatus.completed;
            state.globalAttributes[systemId].mainDistrictId = mainDistrictId;
            state.globalAttributes[systemId].data = action.payload;
        });
    },
    selectors: {
        getCountryList: (state) => state.supportedCountries.data,
        getSupportedCountries: (state) => state.supportedCountries,
        getGlobalAttributes: (state) => state.globalAttributes,
    },
});

export const fetchCountryList = createAsyncThunk(
    "application/fetchCountryList",
    async () => {
        const countryList = await getCountryListService();
        return countryList;
    },
    {
        condition: (_args, { getState }) => {
            const state = getState();
            return state.application.supportedCountries.status === fetchStatus.notStarted;
        },
    }
);

export const fetchGlobalAttr = createAsyncThunk("application/fetchGlobalAttr", async ({ systemId, mainDistrictId }) => {
    const globalAttr = await getGlobalAttributeValues({ systemId, mainDistrictId });
    return globalAttr;
});

export const { getCountryList, getSupportedCountries, getGlobalAttributes } = applicationSlice.selectors;

export default applicationSlice.reducer;
