/* eslint-disable camelcase */
import dayjs from "dayjs";
import { formatDate, getDisplayFormat } from "./dateHelper";
import { filterData } from "./commonHelper";
import generateFilePathForCurrentLocale from "./generateFilePathForCurrentLocale";
import { globalAttributeTypeId, yesFlag } from "src/constants/serviceConstants";
import { sortingOrderType, verifyIssueTypeId } from "src/constants/processMediaConstants";
import { downloadFileFromFB } from "src/services/utils";

export const generateXML = (mediaNumbers) => {
    const openMediaElements = mediaNumbers.map((item) => `  <Open_Media>${item.mediaList}</Open_Media>`).join("");
    let xml = "<Open_Media_List>\n";
    xml += openMediaElements;
    xml += "</Open_Media_List>";
    return xml;
};

export const dateSort = (data, param, indefinitePhase) => {
    return data.sort((a, b) => {
        if (a[param] === indefinitePhase) {
            return 1;
        } // Place "Indefinite" dates at the end
        if (b[param] === indefinitePhase) {
            return -1;
        } // Place "Indefinite" dates at the end

        // Parse dates in MM/DD/YYYY format
        const [monthA, dayA, yearA] = a[param].split("/").map(Number);
        const [monthB, dayB, yearB] = b[param].split("/").map(Number);

        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);

        return dateA - dateB; // Compare dates
    });
};

export const calculateReturnDate = (serviceDate, retentionProfile, indefinitePhase) => {
    if (retentionProfile) {
        if (!serviceDate) {
            return ""; // Return empty string if serviceDate is empty
        }
        if (retentionProfile.indefinite_retention_flag === "Y") {
            return indefinitePhase;
        }
        const retentionDays = retentionProfile.retention_days;
        const serviceDateObj = dayjs(serviceDate, getDisplayFormat());
        const returnDateObj = serviceDateObj.add(retentionDays, "day");
        const formattedReturnDate = formatDate(returnDateObj);
        return formattedReturnDate;
    } else {
        return "";
    }
};

export const processMediaErrors = (errorCode) => {
    let error = "";
    switch (errorCode) {
        case "10001":
            error = "Concurrency check failed";
            break;
        case "63009":
            error = "Invalid Return Date";
            break;
        case "63008":
            error = "No Longer Updatable";
            break;
        case "61014":
            error = "Invalid Customer_Id";
            break;
        case "25097":
            error = "Invalid Logical Vault";
            break;
        case "62527":
            error = "Invalid Parameter";
            break;
        case "10163":
            error = "Unspecified error";
            break;
        case "1":
            error = "Failure - see @error_msg";
            break;
        case "63643":
            error = "A file with this name already exists";
            break;
        case "63645":
            error = "BCP encountered an error - see error_msg";
            break;
        default:
            error = "";
            break;
    }

    return error;
};

export const matchesSample = (fileName, sampleName) => {
    // Convert both file names to lowercase for case-insensitive comparison
    const lowerFileName = fileName.toLowerCase();
    const lowerSampleName = sampleName.fileName.toLowerCase();

    // Remove the file extensions for comparison
    const baseFileName = lowerFileName.split(".").slice(0, -1).join(".");
    const baseSampleName = lowerSampleName.split(".").slice(0, -1).join(".");

    // Escape special regex characters except for the wildcard '%'
    const escapedSampleName = baseSampleName.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Replace '%' with '.*' to match any sequence of characters
    const regexPattern = escapedSampleName.replace(/%/g, ".*");

    // Create a regular expression from the pattern
    const regex = new RegExp(`^${regexPattern}$`);

    // Test if the base filename matches the pattern
    return regex.test(baseFileName);
};

export const getFileContent = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e.target.error);
        reader.readAsText(file); // You can also use readAsDataURL(file), readAsArrayBuffer(file), etc.
    });
}; // You can also use readAsDataURL(file), readAsArrayBuffer(file), etc.

export const jsonToXmlScanVerification = (json, metaObject, summerySet = []) => {
    let xmlString = `<?xml version=''1.0'' encoding=''ISO-8859-1''?><NewDataSet>`;

    // Convert metaData
    if (json.metaData && json.metaData.length > 0) {
        json.metaData.forEach((item) => {
            const verifyTypeId = item.verify_type_id ? item.verify_type_id : metaObject.verifyResultId;
            const verifyTypeDes = item.verify_type_descr ? item.verify_type_descr : metaObject.descr;
            xmlString += "<Table>";
            xmlString += item.verify_result_id ? `<Verify_Result_Id>${item.verify_result_id}</Verify_Result_Id>` : "";
            xmlString += verifyTypeId ? `<Verify_Type_Id>${verifyTypeId}</Verify_Type_Id>` : "";
            xmlString += verifyTypeDes ? `<Verify_Type_Descr>${verifyTypeDes}</Verify_Type_Descr>` : "";
            xmlString += item.list_descr_ltz ? `<List_Descr_Ltz>${item.list_descr_ltz}</List_Descr_Ltz>` : "";
            xmlString += `<List_Count>${item.list_count ? item.list_count : 0}</List_Count>`;
            xmlString += item.list_creation_date_ltz
                ? `<List_Creation_Date_Ltz>${item.list_creation_date_ltz}</List_Creation_Date_Ltz>`
                : "";
            xmlString += `<Unresolved_Count>${parseInt(item.list_count) - parseInt(metaObject.resolvedCount)}</Unresolved_Count>`;
            xmlString += `<Resolved_Count>${metaObject.resolvedCount ? metaObject.resolvedCount : 0}</Resolved_Count>`;
            xmlString += item.verify_editable_flag
                ? `<Verify_Editable_Flag>${item.verify_editable_flag}</Verify_Editable_Flag>`
                : "";
            xmlString += item.first_session_date_ltz
                ? `<First_Session_Date_Ltz>${item.first_session_date_ltz}</First_Session_Date_Ltz>`
                : "";
            xmlString += item.first_session_personnel_name
                ? `<First_Session_Personnel_Name>${item.first_session_personnel_name}</First_Session_Personnel_Name>`
                : "";
            xmlString += item.first_session_personnel_name
                ? `<Last_Session_Date_Ltz>${item.last_session_date_ltz}</Last_Session_Date_Ltz>`
                : "";
            xmlString += item.last_session_personnel_name
                ? `<Last_Session_Personnel_Name>${item.last_session_personnel_name}</Last_Session_Personnel_Name>`
                : "";
            xmlString += item.logical_vault_descr
                ? `<Logical_Vault_Descr>${item.logical_vault_descr}</Logical_Vault_Descr>`
                : "";
            xmlString += `<Scan_Count>${item.scan_count ? item.scan_count : 0}</Scan_Count>`;
            xmlString += `<Scan_Match_Count>${item.scan_match_count ? item.scan_match_count : 0}</Scan_Match_Count>`;
            xmlString += item.first_session_date_local_ltz
                ? `<First_Session_Date_Local_Ltz>${item.first_session_date_local_ltz}</First_Session_Date_Local_Ltz>`
                : "";
            xmlString += item.list_descr ? `<List_Descr>${item.list_descr}</List_Descr>` : "";
            xmlString += "</Table>";
        });
    }

    // Convert allSummaryDetails to Table1
    if (json.allSummaryDetails && json.allSummaryDetails.length > 0) {
        json.allSummaryDetails.forEach((item) => {
            if (summerySet.length !== 0) {
                const isSummeryModifyFound = summerySet.find((e) => e.c3 === item.c3);
                if (isSummeryModifyFound) {
                    xmlString += "<Table1>";
                    xmlString += item.c0 ? `<C0>${item.c0}</C0>` : "";
                    xmlString += item.c1 ? `<C1>${item.c1}</C1>` : "";
                    xmlString += item.c2 ? `<C2>${item.c2}</C2>` : "";
                    xmlString += item.c3 ? `<C3>${item.c3}</C3>` : "";
                    xmlString += item.c4 ? `<C4>${item.c4}</C4>` : "";
                    xmlString += item.c5 ? `<C5>${item.c5}</C5>` : "";
                    xmlString += item.c6 ? `<C6>${item.c6}</C6>` : "";
                    xmlString += item.c8 ? `<C8>${item.c8}</C8>` : "";
                    xmlString += item.c9 ? `<C9>${item.c9}</C9>` : "";
                    xmlString += "</Table1>";
                }
            } else {
                xmlString += "<Table1>";
                xmlString += item.c0 ? `<C0>${item.c0}</C0>` : "";
                xmlString += item.c1 ? `<C1>${item.c1}</C1>` : "";
                xmlString += item.c2 ? `<C2>${item.c2}</C2>` : "";
                xmlString += item.c3 ? `<C3>${item.c3}</C3>` : "";
                xmlString += item.c4 ? `<C4>${item.c4}</C4>` : "";
                xmlString += item.c5 ? `<C5>${item.c5}</C5>` : "";
                xmlString += item.c6 ? `<C6>${item.c6}</C6>` : "";
                xmlString += item.c8 ? `<C8>${item.c8}</C8>` : "";
                xmlString += item.c9 ? `<C9>${item.c9}</C9>` : "";
                xmlString += "</Table1>";
            }
        });
    }

    xmlString += "</NewDataSet>";
    return xmlString;
};
export const mapUpdatedContainerDataToContainerData = (updatedObj, prevObj) => {
    const previousObj = { ...prevObj };

    if (updatedObj?.isReturnDateEditable || !updatedObj?.hasAccessToEditReturnDate) {
        previousObj.Pending_Return_Date = updatedObj.newReturnDate || "";
    } else if (updatedObj?.isContainer) {
        if (updatedObj?.isDeleted) {
            previousObj.Display_Return_Date = updatedObj.newReturnDate || "";
        } else {
            previousObj.Summary_Type = updatedObj.newReturnDate || "";
        }
    }

    previousObj.includeOnManifestFlag = updatedObj.prepareForPickup || 0;
    previousObj.Retention_Profile_Id = updatedObj.retentionProfileId || "";
    previousObj.Profile_Name = updatedObj.profileName || "";
    previousObj.Descr = updatedObj.newDescription || "";

    return previousObj;
};

export const mapValidContainers = (selectedContainerData, retentionProfiles, indefinitePhase) => {
    const selectedContainer = { ...selectedContainerData };

    const selectedRetentionProfile =
        filterData(retentionProfiles, "retention_profile_id", selectedContainerData.Retention_Profile_Id) || [];
    const nextScheduleServiceDate = selectedRetentionProfile[0]?.next_sched_service_date || indefinitePhase;

    if (selectedContainer?.isReturnDateEditable || !selectedContainer?.hasAccessToEditReturnDate) {
        selectedContainer.Pending_Return_Date = nextScheduleServiceDate;
    } else if (selectedContainer?.isContainer) {
        if (selectedContainer?.isDeleted) {
            selectedContainer.Display_Return_Date = nextScheduleServiceDate;
        } else {
            selectedContainer.Summary_Type = nextScheduleServiceDate;
        }
    }

    selectedContainer.includeOnManifestFlag = 1;
    if (selectedRetentionProfile[0]?.overwrite_container_descr_flag === yesFlag) {
        selectedContainer.Descr = selectedRetentionProfile[0].media_descr;
    }

    return selectedContainer;
};

export const sortContainerPickup = (array) => {
    if (array.length > 0) {
        return array.sort((a, b) => {
            if (b.isActive !== a.isActive) {
                return b.includeOnManifestFlag - a.includeOnManifestFlag;
            } else if (b.Container_Number !== a.Container_Number) {
                return a.Container_Number.localeCompare(b.Container_Number);
            } else {
                return a.Number.localeCompare(b.Number);
            }
        });
    } else {
        return [];
    }
};

export const removeDuplicates = (combinedArray, attribure) => {
    const seObj = new Set();
    const uniqueArray = combinedArray.filter((item) => {
        const duplicate = seObj.has(item[attribure]);
        seObj.add(item[attribure]);
        return !duplicate;
    });

    return uniqueArray;
};

export const handleFileRead = (event, processFile = processCSV) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
        return Promise.resolve({ csvData: [], fileName: "", hasError: true });
    }

    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const parsedData = await processFile(text);
            if (parsedData.error) {
                resolve({ csvData: [], fileName: file.name, hasError: true });
            } else {
                resolve({ csvData: parsedData.data, fileName: file.name, hasError: false });
            }
        };
        reader.readAsText(file);
        event.target.value = "";
    });
};

export const processCSV = async (text) => {
    const rows = text
        .trim()
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line !== "");

    if (rows.length === 0) {
        return { error: true };
    }

    const lengthMoreThan20 = rows.find((row) => row.length > 20);
    if (lengthMoreThan20) {
        return { error: true };
    }

    const mappedData = rows.map((row) => {
        return row.replace(/[^a-zA-Z0-9-]/g, "");
    });

    return { error: false, data: mappedData };
};

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export const listDescFormatHelper = (str) => {
    return str.replace(/^\d{4}\/\d{2}\/\d{2}\s*|\s*\(\d+\)/g, "").trim();
};
export const listDescDisplayFormatHelper = (str, actionId) => {
    if (actionId === verifyIssueTypeId.receiveClosedContainerContents) {
        return str;
    }
    return str.substring(11).trim();
};

export const secureSyncSelectOptionsMapper = (idVariable, labelVariable, valueVariable, arr, isSortEnable = true) => {
    if (arr.length > 0) {
        if (isSortEnable) {
            return arr
                .map((data) => {
                    return { id: data[idVariable], label: data[labelVariable], value: data[valueVariable] };
                })
                .sort((a, b) => {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                });
        }
        return arr.map((data) => {
            return { id: data[idVariable], label: data[labelVariable], value: data[valueVariable] };
        });
    }

    return [];
};

export const minValObject = (array, param) => {
    if (!Array.isArray(array) || array.length === 0) {
        return null; // Return null if array is undefined or empty
    }
    // Use reduce to find the minimum val while handling null values
    const minValObject = array.reduce((min, obj) => {
        if (obj[param] === null) {
            return min;
        }
        return parseInt(obj[param]) < parseInt(min[param]) ? obj : min;
    }, array[0]);

    return minValObject;
};

export const multipleSort = (array, criteria) => {
    if (array.length > 0 && !("severity" in array[0])) {
        return array.sort((a, b) => {
            for (const { key, order } of criteria) {
                const valueA = typeof a[key] === "string" ? a[key]?.toLowerCase() : a[key];
                const valueB = typeof b[key] === "string" ? b[key]?.toLowerCase() : b[key];
                if (valueA < valueB) {
                    return order === sortingOrderType.ascending ? -1 : 1;
                }
                if (valueA > valueB) {
                    return order === sortingOrderType.ascending ? 1 : -1;
                }
            }
            return 0;
        });
    }
    return [];
};

export const downloadInstructionGuide = async (fileForms, fileName, onComplete = () => {}) => {
    const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
    const file = generateFilePathForCurrentLocale(bucketPath, fileForms);
    await downloadFileFromFB({
        fbFilePath: file,
        fileName: fileName,
        onComplete: onComplete,
    });
};

export const getOpenMeidaGlobalAttribute = (globalAttributes) => {
    const globalAttribute94 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id94
    );
    return {
        volserMaxLength: globalAttribute94?.global_attribute_value,
    };
};
