import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../VaultStatusReportAccordionSection";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getOpenMediaExceptionData } from "src/constants/routeConstants/operationsRouterFullPaths";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { systemSectionType } from "src/constants/researchConstants";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const OpenMediaOtherExceptions = ({
    rows = [],
    detectedInDataList = [],
    dateRange,
    setErrorAlert,
    searchMaxDisplay,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(rows.length > 0);
    }, [rows]);

    const columns = [
        {
            field: "customer_number",
            headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
            sortable: true,
        },
        {
            field: "customer_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.name")),
            sortable: true,
        },
        {
            field: "detectedIn",
            headerName: convertToLocaleUpperCase(t("tableHeader.detectedIn")),
            sortable: true,
            renderCell: (params) => {
                const detectedInValue = detectedInDataList.filter(
                    (data) => parseInt(params.detected_in_fst_id) === data.id && data.idcontext === systemSectionType
                );
                return detectedInValue[0]?.translatedName || "";
            },
        },
        {
            field: "logical_vault_code",
            headerName: convertToLocaleUpperCase(t("tableHeader.logicalVault")),
            sortable: true,
        },
        {
            field: "exception_type",
            headerName: convertToLocaleUpperCase(t("tableHeader.exceptionType")),
            sortable: true,
        },
        {
            field: "action",
            headerName: convertToLocaleUpperCase(t("tableHeader.exceptionCount")),
            renderCell: (params) => {
                return (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Box width={"75px"}>{params.exception_count}</Box>
                        <IconButton
                            onClick={() => {
                                if (parseInt(params.exception_count) > searchMaxDisplay) {
                                    setErrorAlert(
                                        t("apiErrorMessages.80279", {
                                            searchMaxDisplay: searchMaxDisplay,
                                        })
                                    );
                                } else {
                                    setErrorAlert("");
                                    onViewOpenMediaExceptions(params);
                                }
                            }}
                        >
                            <RemoveRedEyeFilled fill={theme.palette.im_mid_blue["500"]} />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    const onViewOpenMediaExceptions = (params) => {
        const url = getOpenMediaExceptionData({
            customerID: params.customer_id.trim(),
            exceptionTypes: params.exception_type.trim(),
            fileSectionTypeId: params.detected_in_fst_id.trim(),
            logicalVaultCode: params.logical_vault_code,
            detectedDateFrom: dateRange.fromDate,
            detectedDateTo: dateRange.toDate,
            districtId: params.district_id,
        });

        navigate(url);
    };

    const onTableStateChange = ({ rowsPerPage, pageNumber, order, orderBy }) => {
        const locationState = location.state || {};
        navigate(vaultStatusReport, {
            state: {
                ...locationState,
                openMediaOtherExceptionsPageNumber: pageNumber,
                openMediaOtherExceptionsRowsPerPage: rowsPerPage,
                openMediaOtherExceptionsOrderBy: orderBy,
                openMediaOtherExceptionsOrder: order,
            },
            replace: true,
        });
    };

    return (
        <Accordion sx={styles.accord} expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="authorization-verification-content"
                id="authorization-verification"
                onClick={() => setExpanded(!expanded)}
            >
                <Typography variant="body1" sx={styles.headerTypography}>
                    <Trans i18nKey={"titles.openMediaOtherExceptions"} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box style={styles.tableBox}>
                        <Paper>
                            <SecureSyncTable
                                propStyles={styles.dataGrid}
                                rows={rows}
                                columns={columns}
                                noDataMessage={t("messages.noRecordsFound")}
                                sx={styles.dataGrid}
                                initialPage={location.state?.openMediaOtherExceptionsPageNumber}
                                initialRowsPerPage={location.state?.openMediaOtherExceptionsRowsPerPage}
                                initialOrderBy={location.state?.openMediaOtherExceptionsOrderBy}
                                initialOrder={location.state?.openMediaOtherExceptionsOrder}
                                onTableAttributesChange={onTableStateChange}
                            />
                        </Paper>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default OpenMediaOtherExceptions;
