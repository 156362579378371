const styles = {
    card: {
        width: 1,
    },
    cardContent: {
        width: 1,
        height: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "70px",
        p: 2,
    },
    recordStart: {
        display: "flex",
        justifyContent: "flex-start",
        height: "100%",
        alignItems: "center",
    },
    recordEnd: {
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
    },
};

export default styles;
