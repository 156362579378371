import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./ServiceDateCalendarTooltip.styles";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";

const ServiceDateCalendarTooltip = () => {
    const { t } = useTranslation();
    return (
        <SecureSyncIconTooltip
            title={
                <Typography component={"div"}>
                    {t("rescheduleService.calendarDates")}
                    <Box fontSize={12}>
                        <Trans i18nKey={"rescheduleService.tooltipMsg"} />
                    </Box>
                </Typography>
            }
            buttonSx={styles.tooltipButtonStyle}
        />
    );
};

export default ServiceDateCalendarTooltip;
