import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import styles from "./ContainerContents.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";

export default function ContainerContents({ records }) {
    const { t } = useTranslation();
    return (
        <Box>
            <PageSubTitle>{t("titles.containerContents")}</PageSubTitle>
            <Box sx={styles.detailsContainer}>
                <Box sx={styles.containerDataGrid}>
                    {records?.map((value, index) => (
                        <Box key={index} sx={styles.barcodeData}>
                            {value?.item_barcode}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
