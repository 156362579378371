import { t } from "i18next";
import { en, enGB, enUS } from "src/constants/autherizationConstants/authConstants";
import i18n from "src/i18n";

export const padStartStringWithSelectedCharacters = (str, length, character) => {
    return str.padStart(length, character.toString());
};

export const padEndStringWithSelectedCharacters = (str, length, character) => {
    return str.padEnd(length, character.toString());
};

export const filterData = (dataArr, param, paramValue) => {
    if (dataArr.length > 0) {
        return dataArr.filter((element) => element[param] === paramValue);
    }
    return [];
};

export const getVersion = () => {
    return process.env.REACT_APP_SS_APP_VERSION || " ";
};

export const handleDownloadPdf = async (pdfUrl, fileName) => {
    try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading the file:", error);
    }
};

export const getFilenameFromPathFolderPath = (path) => {
    // Split the path by backslashes
    const parts = path.split("\\");
    // The last part is the filename
    const fileName = parts.pop();
    // Join the remaining parts back together with backslashes
    const folderPath = parts.join("\\") + "\\";
    // Return an object with both the folder path and the file name
    return { folderPath, fileName };
};

export const convertToLocaleUpperCase = (str) => str.toLocaleUpperCase(i18n.language);

export const getKeyBoardLabel = (shortCutStr) => {
    return `${t("common.keyboard")}: ${shortCutStr}`;
};

// This method replaces all single quotes to double single quotes.
// This is added to fix the SQL syntax problem when we pass the json data with single quote, SP is failing with syntax error.
export const sanitizeApiData = (data) => {
    const newBody = {};

    Object.keys(data).forEach((key) => {
        if (typeof data[key] === "string") {
            newBody[key] = data[key].replaceAll("'", "''");
        } else if (data[key] === "object") {
            const newNestedObject = sanitizeApiData(data[key]);
            newBody[key] = newNestedObject;
        } else {
            newBody[key] = data[key];
        }
    });
    return newBody;
};

const concatStrings = (num, text) => {
    const connecter = i18n.language === en || i18n.language === enGB || i18n.language === enUS ? "-" : ":";

    return `${num}${connecter}${text}`;
};

export const getDrLevel = () => [
    {
        label: concatStrings("1", t("drAuthority.1confirmMedia")),
        value: "ConfirmMediaToIncludeInDR",
        level: "1",
    },
    {
        label: concatStrings("2", t("drAuthority.2manageDRDocuments")),
        value: "ManageDRDocuments",
        level: "2",
    },
    {
        label: concatStrings("3", t("drAuthority.3declareVerifyDR")),
        value: "DeclareVerifyADR",
        level: "3",
    },
    {
        label: concatStrings("4", t("drAuthority.4reviseDRAuthorization")),
        value: "ReviseDRAuthorization",
        level: "4",
    },
];

export const getIntAuth = () => [
    {
        label: concatStrings("A", t("interactionAuthority.aReleaseMedia")),
        value: "AReleaseMedia",
        level: "A",
    },
    {
        label: concatStrings("B", t("interactionAuthority.bReceiveRequestMedia")),
        value: "BReceiveRequestMedia",
        level: "B",
    },
    {
        label: concatStrings("C", t("interactionAuthority.cAccessIronMountainFacilities")),
        value: "CAccessIronMountainFacilities",
        level: "C",
    },
    {
        label: concatStrings("D", t("interactionAuthority.dPlaceEmergencyRequests")),
        value: "DPlaceEmergencyRequests",
        level: "D",
    },
    {
        label: concatStrings("E", t("interactionAuthority.eModifyAuthorizations")),
        value: "EModifyAuthorizations",
        level: "E",
    },
    {
        label: concatStrings("R", t("interactionAuthority.rReceiveOnly")),
        value: "RReceiveOnlyCannotRequestMedia",
        level: "R",
    },
];
