import { Card, CardContent, CardHeader } from "@mui/material";
import styles from "./SecureSyncTableCard.styles";

const SecureSyncTableCard = ({ sx = {}, children, title = "" }) => {
    return (
        <Card sx={{ ...styles.card, ...sx }}>
            {title && <CardHeader title={title} />}
            <CardContent>{children}</CardContent>
        </Card>
    );
};

export default SecureSyncTableCard;
