import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { OpenNewTabServiceLink } from "../ServiceLink/ServiceLink";
import styles from "./IronMountainServiceSection.styles";

const IronMountainServiceSection = (props) => {
    // {
    const [ImColocationDataServiceLink, setImColocationDataServiceLink] = useState(null);
    const [BilltrustLink, setBilltrustLink] = useState(null);
    const [ImDataStorageOnArchivalTapseLink, setImDataStorageOnArchivalTapseLink] = useState(null);
    const [ImDisposeOfRetiredAssetsLink, setImDisposeOfRetiredAssetsLink] = useState(null);
    const [ImInsidersLink, setImInsidersLink] = useState(null);
    const [ImMediaDestructionLink, setImMediaDestructionLink] = useState(null);
    const [ImRestorationAndMigrationLink, setImRestorationAndMigrationLink] = useState(null);
    const [SitadLink, setSitadLink] = useState(null);

    useEffect(() => {
        const urls = props?.urls ? props.urls : null;
        if (urls) {
            setImColocationDataServiceLink(urls.ImColocationDataServiceLink ? urls.ImColocationDataServiceLink : null);
            setBilltrustLink(urls.BilltrustLink ? urls.BilltrustLink : null);
            setImDataStorageOnArchivalTapseLink(
                urls.ImDataStorageOnArchivalTapseLink ? urls.ImDataStorageOnArchivalTapseLink : null
            );
            setImDisposeOfRetiredAssetsLink(
                urls.ImDisposeOfRetiredAssetsLink ? urls.ImDisposeOfRetiredAssetsLink : null
            );
            setImInsidersLink(urls.ImInsidersLink ? urls.ImInsidersLink : null);
            setImMediaDestructionLink(urls.ImMediaDestructionLink ? urls.ImMediaDestructionLink : null);
            setImRestorationAndMigrationLink(
                urls.ImRestorationAndMigrationLink ? urls.ImRestorationAndMigrationLink : null
            );
            setSitadLink(urls.SitadLink ? urls.SitadLink : null);
        }
    }, [props]);
    // }
    return (
        <Box sx={styles.box}>
            <Typography variant="h4" align="center" sx={styles.typographyTitle}>
                {t("homeModule.IronMountainServices")}
            </Typography>
            {BilltrustLink ? (
                <OpenNewTabServiceLink href={BilltrustLink} sx={styles.serviceLink}>
                    {t("homeModule.PayInvoice")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {SitadLink ? (
                <OpenNewTabServiceLink href={SitadLink} sx={styles.serviceLink}>
                    {t("homeModule.LoginToSecureItAssetDispositionProgram")}{" "}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {ImInsidersLink ? (
                <OpenNewTabServiceLink href={ImInsidersLink} sx={styles.serviceLink}>
                    {t("homeModule.GoToIronMountainInsiders")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            <Typography sx={styles.typographyText}>
                {t("homeModule.ClickOnEachOfTheFollowingLinksForMoreInformationRegardingIronMountainServices")}
            </Typography>
            {ImMediaDestructionLink ? (
                <OpenNewTabServiceLink href={ImMediaDestructionLink} sx={styles.serviceLink}>
                    {t("homeModule.LearnMoreAboutSecureMediaDestructionServices")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {ImRestorationAndMigrationLink ? (
                <OpenNewTabServiceLink href={ImRestorationAndMigrationLink} sx={styles.serviceLink}>
                    {t("homeModule.LearnMoreAboutRestorationAndMigrationServices")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {ImColocationDataServiceLink ? (
                <OpenNewTabServiceLink href={ImColocationDataServiceLink} sx={styles.serviceLink}>
                    {" "}
                    {t("homeModule.LearnMoreAboutSecureCompliantColocationDataCenterServices")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {ImDisposeOfRetiredAssetsLink ? (
                <OpenNewTabServiceLink href={ImDisposeOfRetiredAssetsLink} sx={styles.serviceLink}>
                    {t("homeModule.LearnHowToSecurelyTrackManageAndDisposeOfRetiredAssets")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
            {ImDataStorageOnArchivalTapseLink ? (
                <OpenNewTabServiceLink href={ImDataStorageOnArchivalTapseLink} sx={styles.serviceLink}>
                    {t("homeModule.LearnHowToGetInstantAccessToDataStoredOnArchivalTapes")}
                </OpenNewTabServiceLink>
            ) : (
                <></>
            )}
        </Box>
    );
};

IronMountainServiceSection.propTypes = {
    ImColocationDataServiceLink: PropTypes.string,
    BilltrustLink: PropTypes.string,
    ImDataStorageOnArchivalTapseLink: PropTypes.string,
    ImDisposeOfRetiredAssetsLink: PropTypes.string,
    ImInsidersLink: PropTypes.string,
    ImMediaDestructionLink: PropTypes.string,
    ImRestorationAndMigrationLink: PropTypes.string,
    SitadLink: PropTypes.string,
};

export { IronMountainServiceSection };
