import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuthState from "./useAuthState";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { fetchCountryList, getCountryList } from "src/redux/applicationSlice";
import { getFbToken } from "src/redux/userDetailsSlice";

const useLoadCountries = () => {
    const { isAuthenticated } = useAuthState();
    const dispatch = useDispatch();
    const fbTokenSelector = useSelector(getFbToken);
    const { status } = useSelector(getCountryList);
    useEffect(() => {
        if (isAuthenticated && fbTokenSelector && fbTokenSelector !== "") {
            dispatch(fetchCountryList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, fbTokenSelector]);

    return { isLoading: status === fetchStatus.loading };
};

export default useLoadCountries;
