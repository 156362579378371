const styles = {
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
        p: "16px",
    },
    tableContainer: {
        mb: "20px",
    },
};

export default styles;
