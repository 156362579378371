const styles = {
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loader: {
        position: "relative",
        textAlign: "center",
    },
    typography: {
        marginBottom: 3,
    },
};

export default styles;
