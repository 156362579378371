const styles = {
    header: {
        fontSize: "16px",
        lineHeight: "1.235", // 41.99px
        letterSpacing: "0.25px",
        paddingBottom: "20px",
    },
    divHeader: {
        color: "im_grey.900",
        fontSize: "12px",
        fontWeight: 400,
        paddingTop: "20px",
    },
    boxPaddingBottom: {
        display: "grid",
        gridTemplateColumns: "285px 285px",
        paddingBottom: "20px",
    },
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
};

export default styles;
