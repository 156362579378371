const styles = {
    readOnlyInput: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "im_grey.400", // Default border color
                borderWidth: "1px",
            },
            "&:hover fieldset": {
                borderColor: "im_grey.400", // Border color on hover
            },
            "&.Mui-focused fieldset": {
                borderColor: "im_grey.400", // Border color on focus
                borderWidth: "1px",
            },
        },
        "& .MuiInputBase-root": {
            cursor: "default",
        },
        "& .MuiInputBase-input": {
            cursor: "default",
        },
    },
};

export default styles;
