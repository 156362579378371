const styles = {
    fourColumnLayout: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
        mb: "20px",
    },
};

export default styles;
