const styles = {
    clearIcon: {
        color: "im_dark_blue.500",
    },
    insertConfigCell: { display: "flex", columnGap: "5px" },
    cmiCell: { display: "flex", alignItems: "center" },
    container: { mb: "20px" },
    noRecords: { p: "16px" },
    tableCard: { mt: "0px" },
};

export default styles;
