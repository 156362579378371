import { Outlet } from "react-router-dom";
import SessionTimeoutModal from "../SessionTimeoutModal/SessionTimeoutModal";
import useAuthRedirect from "src/customHooks/useAuthRedirect";

const AuthRoute = () => {
    const isAuthenticated = useAuthRedirect();
    return (
        <>
            {isAuthenticated && (
                <>
                    <Outlet />
                    <SessionTimeoutModal />
                </>
            )}
        </>
    );
};

export default AuthRoute;
