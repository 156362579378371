const styles = {
    highlightedDates: {
        backgroundColor: "im_green.500",
    },
    disabledDay: {
        backgroundColor: "im_grey.50",
        color: "im_grey.200",
    },
    selected: {
        "&.MuiPickersDay-root.Mui-selected": {
            backgroundColor: "im_dark_blue.500",
        },
    },
    hover: {
        "&:hover": {
            backgroundColor: "im_dark_blue.500",
            color: "primary.contrastText",
        },
    },
};

export default styles;
