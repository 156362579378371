import { useTheme } from "@mui/material";

const ChevronLeftFilled = () => {
    const theme = useTheme();
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ChevronLeftFilled">
                <path
                    id="Vector"
                    d="M15.845 20.3375C15.655 20.3375 15.465 20.2675 15.315 20.1175L8.795 13.5975C7.735 12.5375 7.735 10.7975 8.795 9.7375L15.315 3.2175C15.605 2.9275 16.085 2.9275 16.375 3.2175C16.665 3.5075 16.665 3.9875 16.375 4.2775L9.855 10.7975C9.375 11.2775 9.375 12.0575 9.855 12.5375L16.375 19.0575C16.665 19.3475 16.665 19.8275 16.375 20.1175C16.225 20.2575 16.035 20.3375 15.845 20.3375Z"
                    fill={theme.palette.common.black}
                    fillOpacity="0.66"
                />
            </g>
        </svg>
    );
};

export default ChevronLeftFilled;
