import { Box, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./NewCustomerSection.styles";

const NewCustomerSection = ({ values, handleBlur, handleChange, touched, errors, customValidations }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box display="flex" gap={2} sx={styles.boxName}>
                <TextField
                    label={t("formFields.newCustomerName")}
                    variant="outlined"
                    name="newCustomerName"
                    required
                    inputProps={{ maxLength: 50 }}
                    onBlur={handleBlur}
                    value={values.newCustomerName}
                    onChange={handleChange}
                    error={touched.newCustomerName && Boolean(errors.newCustomerName)}
                    helperText={touched.newCustomerName && errors.newCustomerName}
                    fullWidth
                />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={styles.toggleButton}
                >
                    <Box>
                        <FormControlLabel
                            control={<Switch />}
                            label={t("miscService.newCustomerIsSubAccount")}
                            checked={values.newCustomerIsSubAccount}
                            onChange={handleChange}
                            name="newCustomerIsSubAccount"
                        />
                        <Typography variant="caption" sx={styles.toggleTypo}>
                            <Trans i18nKey={"common.toggleOn"} />
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" gap={2} sx={styles.boxParentAccount}>
                <TextField
                    label={t("formFields.parentAccountNumber")}
                    variant="outlined"
                    name="parentAccountNumber"
                    inputProps={{ maxLength: 6 }}
                    onBlur={handleBlur}
                    disabled={!values.newCustomerIsSubAccount}
                    value={values.parentAccountNumber}
                    onChange={handleChange}
                    error={touched.parentAccountNumber && Boolean(errors.parentAccountNumber)}
                    helperText={touched.parentAccountNumber && errors.parentAccountNumber}
                    fullWidth
                />
            </Box>
            <Box display="flex" gap={2} sx={styles.boxDataCenter}>
                <TextField
                    label={t("formFields.dataCenterContactName")}
                    variant="outlined"
                    name="dataCenterContactName"
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 35 }}
                    value={values.dataCenterContactName}
                    onChange={handleChange}
                    error={touched.dataCenterContactName && Boolean(errors.dataCenterContactName)}
                    helperText={touched.dataCenterContactName && errors.dataCenterContactName}
                    fullWidth
                />
                <TextField
                    label={t("formFields.dataCenterContactPhone")}
                    variant="outlined"
                    name="dataCenterContactPhone"
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 25 }}
                    value={values.dataCenterContactPhone}
                    onChange={handleChange}
                    error={
                        touched.dataCenterContactPhone &&
                        (Boolean(errors.dataCenterContactPhone) || !customValidations.dataCenterPhone.validate)
                    }
                    helperText={
                        touched.dataCenterContactPhone &&
                        (errors.dataCenterContactPhone || customValidations.dataCenterPhone.message)
                    }
                    fullWidth
                />
                <TextField
                    label={t("formFields.ext")}
                    variant="outlined"
                    name="dataCenterExt"
                    inputProps={{ maxLength: 6 }}
                    onBlur={handleBlur}
                    value={values.dataCenterExt}
                    onChange={handleChange}
                    error={touched.dataCenterExt && Boolean(errors.dataCenterExt)}
                    helperText={touched.dataCenterExt && errors.dataCenterExt}
                    fullWidth
                />
            </Box>
        </>
    );
};
export default NewCustomerSection;
