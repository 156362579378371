import { Box, Button, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./LibraryAuditResults.styles";
import LibraryAuditResultsTable from "src/components/Operations/Research/LibraryAuditResults/LibraryAuditResultsTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import { getAuditExceptionList } from "src/services/researchService";
import { auditTypes, orderByL, sortCriteriaResearch } from "src/constants/researchConstants";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import { multipleSort } from "src/utils/processMediaHelper";
import { getAUDate } from "src/utils/dateHelper";
import { libraryAuditReport } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";

const LibraryAuditResults = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, completionDate, auditType } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [issueList, setIssueList] = useState([]);
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalShow, setPrintModalShow] = useState(false);
    useEffect(() => {
        const fetchGetAuditExceptionList = async () => {
            setIsLoading(true);
            const auditExceptionResponse = await getAuditExceptionList({
                auditId: id,
                orderBy: orderByL,
                descendingFlag: noFlag,
                ssieFlag: yesFlag,
                missingPhrase: t("libraryAuditResults.missingPhrase"),
                unexpectedPhrase: t("libraryAuditResults.unexpectedPhrase"),
                auditTypeCode: auditType,
            });

            if (!auditExceptionResponse?.failure) {
                const sortedData = multipleSort(
                    auditExceptionResponse?.auditExceptionResults,
                    sortCriteriaResearch.auditExceptions
                );
                setIssueList(sortedData);
            }
            setIsLoading(false);
        };
        fetchGetAuditExceptionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPrintReport = () => {
        const payload = libraryAuditReport({ auditId: id, auditTypeCode: auditType, ssieFlag: yesFlag });
        setPrintPayload(payload);
        setPrintModalShow(true);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("libraryAuditResults.libraryAuditResults")}</PageTitle>
                        <Box sx={styles.fontWeight500}>
                            {issueList.length > 0 ? (
                                <Trans
                                    i18nKey={"libraryAuditResults.clickPrepareReport"}
                                    values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                                />
                            ) : (
                                <Trans i18nKey={"libraryAuditResults.noExceptions"} />
                            )}
                        </Box>
                        <Box sx={styles.statisticWrapper}>
                            <SecureSyncReadOnlyTextField
                                value={getAUDate(completionDate) || " "}
                                label={t("libraryAuditResults.completionDate")}
                            />
                            {auditType === auditTypes.openMedia && (
                                <SecureSyncReadOnlyTextField
                                    value={id || " "}
                                    label={t("libraryAuditResults.auditId")}
                                />
                            )}
                        </Box>
                        <Box sx={styles.marginBottom}>
                            <LibraryAuditResultsTable resultData={issueList} onPrintReport={onPrintReport} />
                        </Box>
                        <Divider />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    navigate(-1);
                                }}
                                startIcon={<IconLeft />}
                            >
                                {t("buttonLabels.back")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <SecureSyncPrintModal
                open={printModalShow}
                payload={printPayload}
                onClose={() => setPrintModalShow(false)}
                targetReport={targetUrl.libraryAuditResults}
            />
        </>
    );
};

export default LibraryAuditResults;
