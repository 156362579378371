import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import styles from "./PopUpMenu.styles";

/**
 * @description Popup menu component with Icon.
 * @param {Object} props Input props.
 * @returns {Node} HTML Template.
 */
const defaultIcon = <MoreVertIcon fontSize="small" />;
const PopUpMenu = (props) => {
    const { icon = defaultIcon, options = [], selectedOption = {}, text, disabled, isDisabled } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * @description Open Menu Click handler.
     * @param {Object} event Event.
     * @returns {*} Open the popup menu.
     */
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * @description Close Menu Click handler.
     * @returns {*} Close the popup menu.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {text ? (
                <Button
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    disabled={disabled}
                    variant="text"
                >
                    <Typography>{text}</Typography>
                </Button>
            ) : (
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    disabled={isDisabled}
                >
                    {icon}
                </IconButton>
            )}

            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: styles.menuContainer,
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.label}
                        selected={option.label === selectedOption.label}
                        onClick={() => {
                            option.action();
                            handleClose();
                        }}
                        disabled={option?.isDisabled || false}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

PopUpMenu.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    text: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.func,
            label: PropTypes.string,
        })
    ).isRequired,
    selectedOption: PropTypes.string,
};

export default PopUpMenu;
