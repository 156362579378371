import { sortingOrderType } from "./processMediaConstants";

export const requestActionIds = {
    openMediaDetails: "1",
    closedContainerDetails: "4",
    temporaryTransportDetails: "5",
    deleteContainerDetails: "6",
    otherDetails: "8",
};

export const requestStatusCodes = {
    cancelled: "3",
    completed: "4",
};

export const requestTypeIds = {
    scheduledService: "1",
    addOn: "2",
    specialStandard: "4",
};

export const destructRequestTypeIds = {
    vaultItemized: "1",
    pickUp: "2",
};

export const destructRequestStatusIds = {
    scheduled: "4",
    pendingConfirmation: "1",
    completed: "7",
    inProgress: "6",
    cancelled: "5",
};
export const onCallConstants = {
    onCall: "1",
    notOnCall: "0",
};

export const requestInitialData = {
    initialRunDetails: {
        noOfServices: 0,
        addOnCutoffFlag: false,
        previousDayFlag: false,
        noOfServicesTom: 0,
        maxServiceDate: "",
    },
    initialSnackbarProps: { show: false, message: "", severity: "" },
    initialServicePeriod: {
        customerHasSchedule: false,
        scheduleStartDate: "",
        scheduleEndDate: "",
    },
    initialCslConfirmation: { show: false, csl: "" },
};
export const requestSortCriteria = {
    serviceDatesRequestIdSort: [{ key: "request_id", order: sortingOrderType.ascending }],
    serviceDatesServiceDateSort: [{ key: "service_date", order: sortingOrderType.ascending }],
    comboBoxSort: [{ key: "sequence_id", order: sortingOrderType.ascending }],
};

export const transportTypeConst = {
    tempTransport: "2",
    permanentTransport: "3",
};

export const authorizeReject = {
    authorize: "A",
    reject: "R",
};

export const destructItemStatusId = {
    notEligibleDelivered: "18",
};

export const destructCancelReasonIds = {
    noEligibleItem: "2",
    onHoldRequest: "5",
};

export const deliverMediaStatus = {
    openMedia: "Open Media",
    container: "Container",
};

export const followUpTypes = {
    possibleMatch: "P",
    duplicate: "D",
};

export const followTypeOrder = ["B", "P", "D", "L", ""];
