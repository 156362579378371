const styles = {
    iconContainer: {
        paddingLeft: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    helpIcon: {
        fontSize: "inherit",
    },
    menuList: (isOpen) => ({
        display: isOpen ? "block" : "none",
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        position: "absolute",
    }),
};

export default styles;
