export const styles = {
    headerTypography: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
    },
    bodyTypography: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    dataGrid: {
        maxHeight: 400,
    },
    accord: { borderRadius: "0px" },
    tableBox: { height: 400, width: "100%" },
    descriptionSection: {
        mb: "20px",
        fontSize: "12px",
    },
};
