import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import LibraryAuditResults from "src/pages/Operations/Research/LibraryAuditResults/LibraryAuditResults";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import { NotFound } from "src/pages/NotFound/NotFound";
import SearchEmployees from "src/pages/Operations/DisasterRecovery/SearchEmployees/SearchEmployees";
import CompleteLibraryAudits from "src/pages/Operations/Research/CompleteLibraryAudits/CompleteLibraryAudits";
import CorrectiveActionRequest from "src/pages/Operations/Research/CorrectiveActionRequest/CorrectiveActionRequest";
import DiscrepancyManagement from "src/pages/Operations/Research/DiscrepancyManagement/DiscrepancyManagement";
import GlobalMediaLocator from "src/pages/Operations/Research/GlobalMediaLocator/GlobalMediaLocator";
import ScanVerificationHistory from "src/pages/Operations/Research/ScanVerificationHistory/ScanVerificationHistory";
import SearchContainers from "src/pages/Operations/Research/SearchContainers/SearchContainers";
import SearchOpenMedia from "src/pages/Operations/Research/SearchOpenMedia/SearchOpenMedia";
import VaultStatusReports from "src/pages/Operations/Research/VaultStatusReports/VaultStatusReports";
import DetailEmployees from "src/pages/Operations/DisasterRecovery/SearchEmployees/DetailEmployees";
import ScanVerificationHistoryResult from "src/pages/Operations/Research/ScanVerificationHistoryResult/ScanVerificationHistoryResult";
import { getAccessRights } from "src/redux/userDetailsSlice";
import ProtectedRoute from "src/components/AppComponents/ProtectedRoute/ProtectedRoute";
import OpenMediaDetails from "src/pages/Operations/Research/OpenMediaDetails/OpenMediaDetails";
import ContainerDetails from "src/pages/Operations/Research/ContainerDetails/ContainerDetails";
import InboundOutBoundReports from "src/pages/Operations/Research/InboundOutBoundReports/InboundOutBoundReports";
import CorrectiveActionRequestDetail from "src/pages/Operations/Research/CorrectiveActionRequestDetail/CorrectiveActionRequestDetail";
import OpenMediaExceptionDetails from "src/pages/Operations/Research/OpenMediaExceptionDetails/OpenMediaExceptionDetails";
import DRDocuments from "src/pages/Operations/DisasterRecovery/DRDocuments/DRDocuments";

const OperationsRouter = () => {
    const {
        operations: { research, disasterRecovery },
    } = routePaths;
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    return (
        <Routes>
            <Route
                path={`${research.home}/*`}
                element={
                    <Routes>
                        <Route
                            path={research.completedLibraryAudit}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.completedLibraryAudits}
                                    component={CompleteLibraryAudits}
                                />
                            }
                        />
                        <Route
                            path={research.completedLibraryAuditResult}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.completedLibraryAudits}
                                    component={LibraryAuditResults}
                                />
                            }
                        />
                        <Route
                            path={research.correctiveActionRequest}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.correctiveActionRequest}
                                    component={CorrectiveActionRequest}
                                />
                            }
                        />
                        <Route
                            path={research.containerDetail}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchContainer}
                                    component={ContainerDetails}
                                />
                            }
                        />
                        <Route
                            path={research.searchContainer}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchContainer}
                                    component={SearchContainers}
                                />
                            }
                        />
                        <Route
                            path={research.searchOpenMedia}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchOpenMedia}
                                    component={SearchOpenMedia}
                                />
                            }
                        />
                        <Route
                            path={research.openMediaDetail}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchOpenMedia}
                                    component={OpenMediaDetails}
                                />
                            }
                        />
                        <Route
                            path={research.discrepancyManagement}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.discrepancyManagementSearch}
                                    component={DiscrepancyManagement}
                                />
                            }
                        />
                        <Route
                            path={research.vaultStatusReport}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.valutStatusReports}
                                    component={VaultStatusReports}
                                />
                            }
                        />
                        <Route
                            path={research.openMediaExceptionDetails}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.valutStatusReports}
                                    component={OpenMediaExceptionDetails}
                                />
                            }
                        />
                        <Route
                            path={research.globalMediaLocator}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.globalMediaLocator}
                                    component={GlobalMediaLocator}
                                />
                            }
                        />
                        <Route
                            path={research.scanVerificationHistory}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.scanVerificationHistory}
                                    component={ScanVerificationHistory}
                                />
                            }
                        />
                        <Route
                            path={research.scanVerificationResultHistory}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.scanVerificationHistory}
                                    component={ScanVerificationHistoryResult}
                                />
                            }
                        />
                        <Route
                            path={research.scanVerificationResultHistory}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.scanVerificationHistory}
                                    component={ScanVerificationHistoryResult}
                                />
                            }
                        />
                        <Route
                            path={research.correctiveActionsDetails}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.correctiveActionRequest}
                                    component={CorrectiveActionRequestDetail}
                                />
                            }
                        />
                        <Route
                            path={research.inboundOutboundReports}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.inboundOutboundReports}
                                    component={InboundOutBoundReports}
                                />
                            }
                        />
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                }
            />
            <Route
                path={`${disasterRecovery.home}/*`}
                element={
                    <Routes>
                        <Route
                            path={disasterRecovery.searchEmployees}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchEmployees}
                                    component={SearchEmployees}
                                />
                            }
                        />
                        <Route
                            path={disasterRecovery.detailEmployees}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.searchEmployees}
                                    component={DetailEmployees}
                                />
                            }
                        />
                        <Route
                            path={disasterRecovery.drDocument}
                            element={
                                <ProtectedRoute hasAccessToView={pageAccess.dRDocuments} component={DRDocuments} />
                            }
                        />
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                }
            />
        </Routes>
    );
};

export default OperationsRouter;
