import { Box, Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import styles from "./EditVerificationIssueModal.styles";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";

const EditVerificationIssueModal = ({ recordData, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const [data, setData] = useState({ c5: "", c4: "" });
    const [errorAlert, setErrorAlert] = useState({ show: false, error: "" });
    useEffect(() => {
        setData(recordData);
    }, [recordData]);
    const handleCheckBoxChange = (e) => {
        setData({ ...data, c4: e.target.checked ? yesFlag : noFlag });
    };

    const checkForError = () => {
        if (data.c4 === noFlag && data.c5) {
            setErrorAlert({ show: true, error: t("scanVerification.hasCommentAndNotResolved") });
            return true;
        } else if (!data.c5 && data.c4 === yesFlag) {
            setErrorAlert({ show: true, error: t("scanVerification.hasResolvedAndNotComment") });
            return true;
        }
        setErrorAlert({ show: false, error: "" });
        return false;
    };

    const handlerSubmit = () => {
        const hasError = checkForError();
        if (!hasError) {
            onSubmit(data);
        }
    };
    return (
        <>
            <SecureSyncModal
                open={true}
                title={t("scanVerification.editVerificationIssue")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={onClose}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button variant="contained" onClick={handlerSubmit}>
                            {t("buttonLabels.update")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        {errorAlert.show && (
                            <Box sx={styles.marginBottomBreak}>
                                <SecureSyncAlert
                                    message={errorAlert.error}
                                    severity="error"
                                    onClose={() => {
                                        setErrorAlert({ show: false, error: "" });
                                    }}
                                />
                            </Box>
                        )}
                        <Box sx={styles.formControlSection}>
                            <TextField
                                label={t("scanVerification.comments")}
                                aria-label="comments"
                                multiline
                                required
                                rows={3}
                                fullWidth
                                value={data?.c5}
                                onChange={(e) => setData({ c4: e.target.value ? yesFlag : noFlag, c5: e.target.value })}
                            />
                        </Box>
                        <Box sx={styles.formControlSection}>
                            <SecureSyncCheckBox
                                label={t("scanVerification.resolved")}
                                name="Resolved"
                                checked={data?.c4?.toUpperCase() === yesFlag ? true : false}
                                onChange={(e) => handleCheckBoxChange(e)}
                            />
                        </Box>
                    </Box>
                </Box>
            </SecureSyncModal>
        </>
    );
};

export default EditVerificationIssueModal;
