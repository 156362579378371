export const paramSetService = (message, values = []) => {
    let resultModified = message;
    if (values && values.length !== 0) {
        const trans = resultModified;
        if (trans.indexOf("|") > -1) {
            let index = 0;
            resultModified = trans.replace(/\|/g, () => values[index++]);
        }
    }
    return resultModified;
};
