import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import EditModal from "src/components/Operations/Research/ContainerSearch/EditModal/EditModal";
import SearchContainerFilters from "src/components/Operations/Research/ContainerSearch/SearchContainerFilters/SearchContainerFilters";
import SearchContainersTable from "src/components/Operations/Research/ContainerSearch/SearchContainersTable/SearchContainersTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { dateFormats } from "src/constants/dateFormatConstants";
import { targetUrl } from "src/constants/printConstants";
import { getAll } from "src/constants/researchConstants";
import { searchContainer } from "src/constants/routeConstants/operationsRouterFullPaths";
import { systemId } from "src/constants/serviceConstants";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { searchContainerReport } from "src/services/printServices";
import { getRetentionProfiles } from "src/services/processMediaService";
import { formatDate } from "src/utils/dateHelper";
import { getSearchContainersGlobalAttrs } from "src/utils/researchHelper";

const SearchContainers = () => {
    const filterRef = useRef();

    const { t } = useTranslation();
    const [searchResults, setSearchResults] = useState([]);
    const [retentionProfiles, setRetentionProfiles] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [noProfiles, setNoProfiles] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [record, selectRecord] = useState("");
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalShow, setPrintModalShow] = useState(false);
    const [searchValues, setSearchValues] = useState({});
    const location = useLocation();

    const navigate = useNavigate();

    const { allowCustomerMediaIdentifiers } = useMemo(() => {
        return getSearchContainersGlobalAttrs(globalAttributes?.data?.rs0);
    }, [globalAttributes]);

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(searchContainer, { state: { ...locationState, ...attributes }, replace: true });
    };
    const clearTableState = () => {
        setIsSearched(false);
        navigate(searchContainer, { state: {}, replace: true });
    };

    const handleSearchResults = ({ error, containers, clearState }) => {
        if (!error[0]?.hasError && !clearState) {
            setIsSearched(true);
            setSearchResults(containers);
        } else {
            setSearchResults([]);
            setIsSearched(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const { profiles } = await getRetentionProfiles("3");

            const profileSet = [];
            if (profiles.length > 0) {
                setNoProfiles(false);
                profiles.forEach((profile) => {
                    profileSet.push({
                        id: parseInt(profile.retention_profile_id),
                        label: profile.profile_name ? profile.profile_name : "",
                        value: profile.retention_profile_id,
                        mediaDescr: profile.media_descr,
                        overwriteFlag: profile.overwrite_container_descr_flag,
                    });
                });
            } else {
                setNoProfiles(true);
                profileSet.push({
                    id: 1,
                    label: t("containerSearch.noProfile"),
                    value: "no profile",
                });
            }
            setRetentionProfiles(profileSet);
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateRecord = (record, values) => {
        let profileName = "";
        if (values.retentionProfile) {
            const selectedProfile = retentionProfiles.find((pro) => pro.value === values.retentionProfile);
            profileName = selectedProfile.label;
        }
        const updatedArray = searchResults.map((item) =>
            item.Container_Id === record.Container_Id
                ? {
                      ...item,
                      Descr: values.description,
                      // eslint-disable-next-line camelcase
                      Retention_Profile_Id: values.retentionProfile,
                      // eslint-disable-next-line camelcase
                      Profile_Name: profileName,
                      // eslint-disable-next-line camelcase
                      Pending_Changes: "1",
                  }
                : item
        );
        setSearchResults(updatedArray);
        setOpenEdit(false);
        selectRecord("");
    };

    const resetTable = (keepState) => {
        if (filterRef.current) {
            filterRef.current.callResetFunction(keepState);
        }
    };

    const printReport = () => {
        const payload = searchContainerReport({
            containerId: "",
            containerNumberList: searchValues.containerNumber,
            customerMediaDescription: searchValues.customerMediaIdentifier,
            description: searchValues.description,
            fromDate: formatDate(searchValues.returnDateFrom, dateFormats.displayDate),
            toDate: formatDate(searchValues.returnDateTo, dateFormats.displayDate),
            mediaType: searchValues.mediaType && searchValues.mediaType !== getAll ? searchValues.mediaType : "",
            summaryType: searchValues.currentStatus,
            allowCustomerMediaIdentifiers,
        });

        setPrintPayload(payload);
        setPrintModalShow(true);
    };

    return (
        <Box>
            <CircularLoaderFullPage show={loading || globalAttributes.isLoading} />
            <PageTitle>{t("titles.containerSearch")}</PageTitle>
            <SearchContainerFilters
                onSearch={handleSearchResults}
                allowCustomerMediaIdentifiers={allowCustomerMediaIdentifiers}
                ref={filterRef}
                clearTableState={clearTableState}
                setSearchValues={setSearchValues}
            />
            <SearchContainersTable
                resetTable={(keepState) => resetTable(keepState)}
                isSearched={isSearched}
                records={searchResults}
                selectedRecord={record}
                allowCustomerMediaIdentifiers={allowCustomerMediaIdentifiers}
                selectRecord={(record) => selectRecord(record)}
                openEdit={() => setOpenEdit(true)}
                onTableStateChange={onTableStateChange}
                printReport={printReport}
            />
            <EditModal
                noProfiles={noProfiles}
                open={openEdit}
                onClose={() => {
                    setOpenEdit(false);
                    selectRecord("");
                }}
                record={record}
                retentionProfiles={retentionProfiles}
                updateRecord={(record, values) => updateRecord(record, values)}
            />
            <SecureSyncPrintModal
                open={printModalShow}
                payload={printPayload}
                onClose={() => setPrintModalShow(false)}
                targetReport={targetUrl.searchContainerResultReport}
            />
        </Box>
    );
};

export default SearchContainers;
