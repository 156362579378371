import { useMemo } from "react";
import { useSelector } from "react-redux";
import { languages } from "src/constants/i18nConstants";
import { getSupportedLanguageDetails } from "src/redux/languageSlice";
import { sortHelper } from "src/utils/sortHelper";

const useGetLanguagesByEurope = () => {
    const { data: supportLanguageDetails } = useSelector(getSupportedLanguageDetails);

    const languagesByEurope = useMemo(() => {
        const supportLanguagesObjByIsoCode = {};
        const sortedLanguages = sortHelper({
            data: [...supportLanguageDetails],
            primaryProperty: "language_display_name",
            sortOrder: "asc",
        });
        sortedLanguages.forEach((languageObj) => {
            supportLanguagesObjByIsoCode[languageObj.iso_locale_code] = languageObj;
        });
        const europeLanguages = [];
        const nonEuropeLanguages = [];
        Object.keys(supportLanguagesObjByIsoCode).forEach((isoCode) => {
            if ([languages.en_gb, languages.nl, languages.fr_FR, languages.de, languages.es].includes(isoCode)) {
                europeLanguages.push(supportLanguagesObjByIsoCode[isoCode]);
            } else if ([languages.en_us, languages.fr_CA, languages.es_419, languages.pt_BR].includes(isoCode)) {
                nonEuropeLanguages.push(supportLanguagesObjByIsoCode[isoCode]);
            }
        });
        return { europeLanguages, nonEuropeLanguages };
    }, [supportLanguageDetails]);
    return languagesByEurope;
};

export default useGetLanguagesByEurope;
