import { Box, SvgIcon, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useEffect, useMemo, useState } from "react";
import styles from "./SearchMediaTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncAccordion from "src/components/common/SecureSyncAccordion/SecureSyncAccordion";
import { formatDate, getDisplayFormat } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";
import {
    getDetectedInTranslation,
    getDiscrepancyTranslation,
    getTranslatedSuggestions,
    selectDistinctDistricts,
} from "src/utils/researchHelper";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getAllValue } from "src/constants/researchConstants";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const SearchMediaTable = ({ records = [], isSearched, navigateToDetails, type }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [expand, setExpand] = useState(false);
    const [district, setDistrict] = useState(getAllValue);

    useEffect(() => {
        setExpand(records.length > 0);
    }, [records.length]);

    const mediaColumns = useMemo(
        () => [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <Box>
                        <SecureSyncIconTooltip
                            title={convertToLocaleUpperCase(t("tableHeader.viewDetails"))}
                            icon={<SvgIcon fill={theme.palette.primary.main} component={RemoveRedEyeFilled} />}
                            onClick={() => navigateToDetails(params)}
                        ></SecureSyncIconTooltip>
                    </Box>
                ),
            },
            {
                field: "customer_number",
                headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
                sortable: true,
            },
            {
                field: "customer_name",
                headerName: convertToLocaleUpperCase(t("tableHeader.name")),
                sortable: true,
            },
            {
                field: "view",
                headerName: convertToLocaleUpperCase(t("tableHeader.branch")),
                sortable: true,
                renderCell: (params) => <Typography>{params.districtName}</Typography>,
            },
            {
                field: "volser_number",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaDR")),
                minWidth: 150,
                sortable: true,
            },
            {
                field: "logical_vault_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.logicalVault")),
                sortable: true,
            },
            {
                field: "media_type_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaType")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>{params?.media_type_id ? t(`mediaTypes.${params?.media_type_id}`) : ""}</Typography>
                ),
            },
            {
                field: "discrepancy_type_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.discrepancyType")),
                sortable: true,
                renderCell: (params) => {
                    let discrepancyType = "";
                    const discrepancyTypeCode = params?.discrepancy_type_code.trim().replace(/\s+/g, "");
                    if (params?.discrepancy_type_code && params?.file_section_type_id) {
                        discrepancyType = getDiscrepancyTranslation(
                            "openMedia",
                            params?.file_section_type_id,
                            discrepancyTypeCode
                        );
                    }

                    return (
                        <Typography
                            sx={
                                discrepancyTypeCode === "Missing" ||
                                discrepancyTypeCode === "NotReceived" ||
                                discrepancyTypeCode === "NotAtIronMountain" ||
                                getTranslatedSuggestions(params?.suggestion_type_code) ===
                                    t("suggestionTypes.pendingRes")
                                    ? styles.redColorTxt
                                    : {}
                            }
                        >
                            {discrepancyType}
                        </Typography>
                    );
                },
            },
            {
                field: "detected_datetime",
                headerName: convertToLocaleUpperCase(t("tableHeader.detectedDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.detected_datetime
                            ? formatDate(params?.detected_datetime, dateFormats.localized24HAndSecFormat, true)
                            : ""}
                    </Typography>
                ),
            },
            {
                field: "file_section_type_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.detectedIn")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.file_section_type_id
                            ? getDetectedInTranslation(params?.file_section_type_id, "fileSectionType")
                            : " "}
                    </Typography>
                ),
            },
            {
                field: "request_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.requestID")),
                sortable: true,
            },
            {
                field: "car_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.carId")),
                sortable: true,
            },
            {
                field: "resolved_datetime",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolvedDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>{params?.resolved_datetime ? formatDate(params?.resolved_datetime) : ""}</Typography>
                ),
            },
            {
                field: "resolved_by_name",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolvedBy")),
                sortable: true,
            },
            {
                field: "suggestion_type_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolution")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.suggestion_type_code
                            ? getTranslatedSuggestions(params?.suggestion_type_code?.trim())
                            : " "}
                    </Typography>
                ),
            },
            {
                field: "last_known_location",
                headerName: convertToLocaleUpperCase(t("tableHeader.lastKnownLocation")),
                sortable: true,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const containerColumns = useMemo(
        () => [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <Box>
                        <SecureSyncIconTooltip
                            title="View Details"
                            icon={<SvgIcon fill={theme.palette.primary.main} component={RemoveRedEyeFilled} />}
                            onClick={() => navigateToDetails(params)}
                        ></SecureSyncIconTooltip>
                    </Box>
                ),
            },
            {
                field: "customer_number",
                headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
                sortable: true,
            },
            {
                field: "assigned_to_customer_name",
                headerName: convertToLocaleUpperCase(t("tableHeader.name")),
                sortable: true,
            },
            {
                field: "view",
                headerName: convertToLocaleUpperCase(t("tableHeader.branch")),
                sortable: true,
                renderCell: (params) => <Typography>{params.districtName}</Typography>,
            },
            {
                field: "container",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaDR")),
                minWidth: 150,
                sortable: true,
                renderCell: (params) => <Typography>{params.container_number} (OM)</Typography>,
            },
            {
                field: "media_type_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaType")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>{params?.media_type_id ? t(`mediaTypes.${params?.media_type_id}`) : ""}</Typography>
                ),
            },
            {
                field: "service_date",
                headerName: convertToLocaleUpperCase(t("tableHeader.serviceDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.service_date ? formatDate(params?.service_date, getDisplayFormat(), true) : ""}
                    </Typography>
                ),
            },
            {
                field: "discrepancy_type_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.discrepancyType")),
                sortable: true,
                renderCell: (params) => {
                    let discrepancyType = "";
                    if (params?.discrepancy_type_code && params?.system_function_id) {
                        discrepancyType = getDiscrepancyTranslation(
                            "container",
                            params?.system_function_id,
                            params?.discrepancy_type_code.trim().replace(/\s+/g, "")
                        );
                    }
                    return <Typography>{discrepancyType}</Typography>;
                },
            },
            {
                field: "detected_datetime",
                headerName: convertToLocaleUpperCase(t("tableHeader.detectedDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.detected_datetime
                            ? formatDate(params?.detected_datetime, dateFormats.localized24HAndSecFormat, true)
                            : ""}
                    </Typography>
                ),
            },
            {
                field: "file_section_type_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.detectedIn")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.system_function_id
                            ? getDetectedInTranslation(params?.system_function_id, "systemFunction")
                            : " "}
                    </Typography>
                ),
            },
            {
                field: "pending_return_date",
                headerName: convertToLocaleUpperCase(t("tableHeader.pendingReturnDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.pending_return_date ? formatDate(params?.pending_return_date) : ""}
                    </Typography>
                ),
            },
            {
                field: "car_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.carId")),
                sortable: true,
            },
            {
                field: "resolved_datetime",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolvedDate")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>{params?.resolved_datetime ? formatDate(params?.resolved_datetime) : ""}</Typography>
                ),
            },
            {
                field: "resolved_by_name",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolvedBy")),
                sortable: true,
            },
            {
                field: "suggestion_type_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.resolution")),
                sortable: true,
                renderCell: (params) => (
                    <Typography>
                        {params?.suggestion_type_code
                            ? getTranslatedSuggestions(params?.suggestion_type_code?.trim())
                            : " "}
                    </Typography>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getDistrictOptions = useMemo(() => {
        return selectDistinctDistricts(records);
    }, [records]);

    const filteredData = useMemo(() => {
        if (district !== getAllValue) {
            return records.filter((record) => record.district_id === district);
        }
        return records;
    }, [district, records]);

    return (
        <SecureSyncAccordion
            boxSx={expand ? styles.accordionWrapper : {}}
            accordionSX={type === "media" ? styles.mediaAccordion : styles.containerAccordion}
            square
            id="om"
            expanded={expand}
            summary={
                <Typography>
                    {type === "media" ? t("discrepanctManagement.openMedia") : t("discrepanctManagement.container")} (
                    {records.length})
                </Typography>
            }
            onClick={() => {
                setExpand(!expand);
            }}
        >
            <Box>
                {getDistrictOptions.length > 2 && (
                    <Box sx={styles.fieldContainer}>
                        <SecureSyncSelect
                            value={district}
                            label={t("formFields.district")}
                            options={getDistrictOptions}
                            name="district"
                            onChange={(e) => {
                                setDistrict(e.target.value);
                            }}
                            defaultOption={t("common.allSmallCase")}
                        />
                    </Box>
                )}
                <SecureSyncTable
                    rows={filteredData}
                    columns={type === "media" ? mediaColumns : containerColumns}
                    noDataMessage={isSearched ? t("containerSearch.noContainers") : t("messages.pleaseProvideTheInput")}
                    getRowId={(row) => row.Request_Id}
                    setPaginationOutSideTable
                />
            </Box>
        </SecureSyncAccordion>
    );
};

export default SearchMediaTable;
