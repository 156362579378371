import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import styles from "./AddOpenMedia.styles";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import { enterKeyCode } from "src/constants/processMediaConstants";
import { handleFileRead, insertBulkData } from "src/utils/requestHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const maxCount = 400;

const AddOpenMedia = ({
    setOpenMediaList,
    setErrorMessage,
    gridCount = 0,
    openMediaTypeList,
    setOpenMediaTypeList,
    downloadInstructionFile,
    maxContainer,
    sequenceNumber,
    incrementSequenceNumber,
    initialErrorState,
    hasValidatedData,
}) => {
    const { t } = useTranslation();
    const noDataMessage = t("messages.pleaseAddOpenMedia");
    const columns = [
        {
            field: "delete",
            width: "10px",
            headerName: "",
            sortable: false,
            renderCell: (params) => (
                <SecureSyncIconTooltip
                    title={t("deliverMedia.enteredMediaRemoveButton")}
                    icon={
                        <IconButton
                            onClick={() => {
                                deleteSingleMediaType(params.id);
                            }}
                        >
                            <ClearIcon sx={styles.clearIcon} />
                        </IconButton>
                    }
                ></SecureSyncIconTooltip>
            ),
        },
        { field: "mediaNumber", headerName: convertToLocaleUpperCase(t("tableHeader.openMedia")), flex: 1 },
    ];

    const [openMediaNumber, setOpenMediaNumber] = useState("");

    useEffect(() => {
        setOpenMediaList(openMediaTypeList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMediaTypeList]);

    const addOpenMediaHandler = async () => {
        if (openMediaNumber) {
            if (maxCount > gridCount + openMediaTypeList.length) {
                addMediaType(openMediaNumber);
                setOpenMediaNumber("");
                setErrorMessage(initialErrorState);
            } else {
                setErrorMessage({
                    show: true,
                    status: "error",
                    message: t("messages.maxOpenMedia", { maxCount: maxCount }),
                });
            }
        }
    };

    const enterToAddScan = (event) => {
        if (event.keyCode === enterKeyCode) {
            addOpenMediaHandler();
        }
    };

    const addMediaType = (value) => {
        const haveDuplicates = openMediaTypeList.some((item) => item.mediaNumber === convertToLocaleUpperCase(value));
        if (!haveDuplicates) {
            setOpenMediaTypeList((prevData) => [
                {
                    id: uuidv4(),
                    sequenceNumber,
                    mediaNumber: convertToLocaleUpperCase(value),
                },
                ...prevData,
            ]);
            incrementSequenceNumber(1);
        }
    };

    const deleteSingleMediaType = (id) => {
        const mediaArray = openMediaTypeList.filter((val) => val.id !== id);
        setOpenMediaTypeList(mediaArray);
    };

    const fileReadHandler = async (event) => {
        const {
            csvData,
            hasError,
            errorMsg = "",
        } = await handleFileRead(event, maxCount, maxContainer, gridCount + openMediaTypeList.length);
        const uniqueRows = [...new Set(csvData)];
        if (!hasError) {
            const openMediaData = insertBulkData(uniqueRows, [], sequenceNumber, hasValidatedData);
            setOpenMediaTypeList((prevData) => {
                return [...openMediaData.reverse(), ...prevData];
            });
            incrementSequenceNumber(openMediaData.length);
            setErrorMessage(initialErrorState);
        } else {
            setErrorMessage({
                show: true,
                status: "error",
                message: errorMsg,
            });
        }
    };

    return (
        <SecureSyncTableCard
            title={
                <Typography variant="h6" sx={styles.cardHederText}>
                    {t("deliverMedia.enterOpenMedia")}
                </Typography>
            }
        >
            <Box sx={styles.allPadding}>
                <Typography variant="h6" sx={styles.cardDesc}>
                    <Trans i18nKey={""} />
                    <Trans i18nKey={"deliverMedia.openMediaDesc"} values={{ buttonLabel: t("buttonLabels.add") }} />
                </Typography>
                <Box sx={styles.boxContainer}>
                    <TextField
                        label="Media Number"
                        name="mediaNumber"
                        variant="outlined"
                        size="small"
                        value={openMediaNumber}
                        onChange={(e) => {
                            setOpenMediaNumber(e.target.value);
                        }}
                        sx={styles.textFieldWidth}
                        onKeyDown={enterToAddScan}
                    />
                    <Button variant="outlined" onClick={addOpenMediaHandler}>
                        {t("buttonLabels.add")}
                    </Button>
                </Box>
                <Typography sx={styles.uploadText}>
                    <Trans i18nKey={""} />
                    <Trans
                        i18nKey={"deliverMedia.clickUploadButton"}
                        values={{ buttonLabel: t("common.uploadFile") }}
                    />
                </Typography>
                <Box sx={styles.bottomPadding}>
                    <Typography style={styles.importText}>{t("common.importFile")} *</Typography>
                    <Box display="flex" alignItems="center" justifyContent="left" gap={2}>
                        <SecureSyncFileUpload
                            startIcon={<UploadIcon />}
                            variant="contained"
                            onChange={fileReadHandler}
                            accept=".txt"
                            name={t("common.uploadFile")}
                        />
                        <SecureSyncIconTooltip
                            title={t("containerPickup.fileFormatHelp")}
                            placement="right"
                            onClick={downloadInstructionFile}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.tblCardContent}>
                <SecureSyncTable
                    rows={openMediaTypeList}
                    columns={columns}
                    tableMinWidth="100px"
                    noDataMessage={noDataMessage}
                    getRowId={(row) => row.id}
                    headerHeight="36px"
                    rowHeight="32px"
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default AddOpenMedia;
