export const sortHelper = ({
    data,
    primaryProperty,
    sortOrder,
    secondaryProperty,
    thirdProperty,
    customSortFunction,
}) => {
    return data.sort((a, b) => {
        if (customSortFunction) {
            return customSortFunction(a, b, sortOrder);
        }
        const primaryA = typeof a[primaryProperty] === "string" ? a[primaryProperty].toLowerCase() : a[primaryProperty];
        const primaryB = typeof b[primaryProperty] === "string" ? b[primaryProperty].toLowerCase() : b[primaryProperty];

        if (primaryA < primaryB) {
            return sortOrder === "asc" ? -1 : 1;
        }
        if (primaryA > primaryB) {
            return sortOrder === "asc" ? 1 : -1;
        }

        if (secondaryProperty) {
            const secondaryA =
                typeof a[secondaryProperty] === "string" ? a[secondaryProperty].toLowerCase() : a[secondaryProperty];
            const secondaryB =
                typeof b[secondaryProperty] === "string" ? b[secondaryProperty].toLowerCase() : b[secondaryProperty];

            if (secondaryA < secondaryB) {
                return sortOrder === "asc" ? -1 : 1;
            }
            if (secondaryA > secondaryB) {
                return sortOrder === "asc" ? 1 : -1;
            }
        }

        if (thirdProperty) {
            const thirdA = typeof a[thirdProperty] === "string" ? a[thirdProperty].toLowerCase() : a[thirdProperty];
            const thirdB = typeof b[thirdProperty] === "string" ? b[thirdProperty].toLowerCase() : b[thirdProperty];

            if (thirdA < thirdB) {
                return sortOrder === "asc" ? -1 : 1;
            }
            if (thirdA > thirdB) {
                return sortOrder === "asc" ? 1 : -1;
            }
        }

        return 0;
    });
};
