const styles = {
    cardContent: {
        width: "100%",
        height: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
        p: "16px",
    },
    reqDetailRow: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
    },
    rowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
        paddingBottom: "20px",
    },
    mainContainer: {
        paddingBottom: "20px",
    },
};

export default styles;
