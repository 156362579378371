import { Box, MenuItem, MenuList, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import styles from "./HelpMenu.styles";
import { ReactComponent as HelpIcon } from "src/assets/images/Help.svg";
import { useAnchorAndNavigation } from "src/customHooks";
import { getFBDownloadUrl } from "src/utils/generateFilePathForCurrentLocale";
import { staticFile } from "src/constants/fileConstants";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { dashboard, resourcesPath } from "src/constants/routeConstants/rootRouterFullPaths";
import { discrepancyManagement, vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";

const fbHelpLinkLocations = [
    {
        path: dashboard,
        fbFilename: staticFile.homepagePersonnel,
    },
    {
        path: discrepancyManagement,
        fbFilename: staticFile.discrepancyManagementPersonnel,
    },
    {
        path: vaultStatusReport,
        fbFilename: staticFile.vaultStatusReportPersonnel,
    },
];

const HelpMenu = ({ isPostLoginScreen }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const {
        anchorEl: helpMenu,
        isOpen: isHelpMenuOpen,
        menuRef,
        openAnchor: openHelpMenu,
        // eslint-disable-next-line no-unused-vars
        closeAnchor: closeHelpMenu,
        handleBoxMouseLeave,
        handleMenuMouseLeave,
        handleMenuItemClick: handleHelpMenuItemClick,
    } = useAnchorAndNavigation();

    const helpFbFileName = useMemo(() => {
        const fbHelpLinkObj = fbHelpLinkLocations.find((helpLink) => {
            const match = matchPath(helpLink.path, location.pathname);
            return Boolean(match);
        });

        return fbHelpLinkObj?.fbFilename || "";
    }, [location.pathname]);

    const openFBLink = async (fileName) => {
        setLoading(true);
        const docUrl = await getFBDownloadUrl(fileName);
        window.open(docUrl, "_blank");
        setLoading(false);
    };

    const onHelpClick = async () => {
        openFBLink(helpFbFileName);
    };

    const onContactUsClick = async () => {
        openFBLink(staticFile.contactUs);
    };

    const onResourcesClick = async () => {
        if (isPostLoginScreen) {
            openFBLink(staticFile.secureSyncHelpNotLoggedIn);
        } else {
            handleHelpMenuItemClick(resourcesPath);
        }
    };

    return (
        <div>
            <CircularLoaderFullPage show={isLoading} />
            <Box style={styles.iconContainer} onMouseEnter={openHelpMenu} onMouseLeave={handleBoxMouseLeave}>
                <HelpIcon style={styles.helpIcon} />
            </Box>
            <Popper
                open={isHelpMenuOpen}
                anchorEl={helpMenu}
                placement="bottom-start"
                className="menu-wrapper"
                disablePortal={false}
                sx={{ zIndex: "appBar" }}
            >
                <MenuList ref={menuRef} onMouseLeave={handleMenuMouseLeave} style={styles.menuList(isHelpMenuOpen)}>
                    {!isPostLoginScreen && helpFbFileName && (
                        <MenuItem onClick={onHelpClick}>{t("headerMenu.help")}</MenuItem>
                    )}
                    <MenuItem onClick={onResourcesClick}>{t("headerMenu.resources")}</MenuItem>
                    <MenuItem onClick={onContactUsClick}>{t("headerMenu.contactUs")}</MenuItem>
                </MenuList>
            </Popper>
        </div>
    );
};

export default HelpMenu;
