const styles = {
    filterDesc: {
        fontWeight: 500,
        lineHeight: 1.5,
        mb: "20px",
        "& strong": {
            fontWeight: 900,
            lineHeight: 1.5,
        },
    },
    textContent: {
        fontWeight: 700,
    },
    searchContainer: {
        display: "flex",
        flexDirection: "column",
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(4,310px)",
        gap: "20px",
        mb: "20px",
    },
    description: {
        display: "grid",
        gridTemplateColumns: "970px",
        gap: "20px",
        mb: "20px",
    },
    searchButtons: {
        display: "flex",
        gap: "20px",
    },
    footerCaption: {
        mt: "10px",
        mb: "20px",
        fontWeight: 400,
        lineHeight: "20px",
        fontSize: "12px",
        letterSpacing: "0.4px",
        color: "text.light",
    },
};

export default styles;
