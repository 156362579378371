const Pencil = ({ fill, ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="pencil">
                <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.76079 15.9115C0.485019 17.0217 0.697151 18.0258 1.34062 18.6692C1.98409 19.3127 2.98818 19.5248 4.09126 19.2278L5.63276 18.846C6.19844 18.7046 8.69949 16.5288 9.10961 16.1187L16.7382 8.49013C16.7709 8.46681 16.802 8.44055 16.8312 8.41134C16.8604 8.38214 16.8867 8.351 16.91 8.31831L18.2737 6.9546C19.7162 5.5121 19.7162 3.16451 18.2737 1.72201C16.8312 0.279512 14.4836 0.279512 13.0411 1.72201L2.11136 12.6518C1.70124 13.0619 1.28405 13.8043 1.14263 14.37L0.76079 15.9115ZM16.3008 6.8203L17.2201 5.90101C18.0757 5.04541 18.0757 3.64534 17.2201 2.78974C16.3645 1.93414 14.9645 1.93414 14.1089 2.78974L13.1896 3.70903L16.3008 6.8203ZM12.1289 4.76969L3.18617 13.7124C2.96696 13.9316 2.68412 14.4266 2.60634 14.7307L2.2245 16.2721C2.07601 16.859 2.14672 17.3399 2.40835 17.6015C2.66998 17.8631 3.15788 17.9268 3.73771 17.7854L5.2792 17.4035C5.58326 17.3257 6.07823 17.0429 6.29744 16.8237L15.2402 7.88096L12.1289 4.76969Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export default Pencil;
