import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "src/components/AppComponents/ProtectedRoute/ProtectedRoute";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import { NotFound } from "src/pages/NotFound/NotFound";
import { ContainerPickup } from "src/pages/ProcessMedia/ContainerPickup/ContainerPickup";
import { DownloadInventoryFile } from "src/pages/ProcessMedia/DownloadInventoryFile/DownloadInventoryFile";
import { OpenMediaDistribution } from "src/pages/ProcessMedia/OpenMediaDistribution/OpenMediaDistribution";
import { ScanVerification } from "src/pages/ProcessMedia/ScanVerification/ScanVerification";
import { SendListFiles } from "src/pages/ProcessMedia/SendListFiles/SendListFiles";
import { getAccessRights } from "src/redux/userDetailsSlice";

const ProcessMediaRouter = () => {
    const { processMedia } = routePaths;
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    return (
        <Routes>
            <Route
                path={processMedia.containerPickup}
                element={<ProtectedRoute hasAccessToView={pageAccess.containerPickup} component={ContainerPickup} />}
            />
            <Route
                path={processMedia.downloadInventory}
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.downloadInventory} component={DownloadInventoryFile} />
                }
            />
            <Route
                path={processMedia.omDistributionList}
                element={
                    <ProtectedRoute hasAccessToView={pageAccess.oMDistributionList} component={OpenMediaDistribution} />
                }
            />
            <Route
                path={processMedia.scanVerification}
                element={<ProtectedRoute hasAccessToView={pageAccess.scanVerification} component={ScanVerification} />}
            />
            <Route
                path={processMedia.sendListFile}
                element={<ProtectedRoute hasAccessToView={pageAccess.sendListFile} component={SendListFiles} />}
            />
            <Route path={"*"} element={<NotFound />} />
        </Routes>
    );
};

export default ProcessMediaRouter;
