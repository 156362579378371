import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";

import AuthorizationsVerificationSection from "./AuthorizationsVerificationSection/AuthorizationsVerificationSection";
import PMDRequestSection from "./PMDRequestSection/PMDRequestSection";
import InventoryCaptureResults from "./InventoryCaptureResults/InventoryCaptureResults";
import ServiceDetailsSection from "./ServiceDetailsSection/ServiceDetailsSection";
import HomeHeaderSection from "./HomeHeaderSection/HomeHeaderSection";
import ReportsHeaderSection from "./ReportsHeaderSection/ReportsHeaderSection";
import styles from "./NotificationSection.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import {
    getInventoryServiceReport,
    getRecentServiceReport,
    getSDListService,
    getSummeryCount,
} from "src/services/homeService";
import { notificationButtons } from "src/constants/dashboardConstants";

export const NotificationSection = () => {
    const [selectedButton, setSelectedButton] = useState(notificationButtons.todo);
    const [aRVCount, setARVCount] = useState(0);
    const [pMDCount, setPMDCount] = useState(0);
    const [rSRCount, setRSRCount] = useState(0);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [isRecentServiceReportLoading, setIsRecentServiceReportLoading] = useState(false);
    const [isSDListServiceLoading, setIsSDListServiceLoading] = useState(false);
    const [isInventoryServiceReportLoading, setIsInventoryServiceReportLoading] = useState(false);
    const [mostRecentVaultReport, setMostRecentVaultReport] = useState("");
    const [serviceDetailsList, setServiceDetailsList] = useState([]);
    const [inventoryCompareList, setInventoryCompareList] = useState([]);
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    useEffect(() => {
        fetchSummery();
        fetchRecentServiceReport();
        fetchSDListService();
        fetchInventoryServiceReport();
    }, [selectedButton]);

    const fetchSummery = async () => {
        setIsSummaryLoading(true);
        try {
            const res = await getSummeryCount();
            res.authorizationRequiringVerificationCount
                ? setARVCount(parseInt(res.authorizationRequiringVerificationCount))
                : setARVCount(0);
            res.pendingMediaDestructionCount ? setPMDCount(parseInt(res.pendingMediaDestructionCount)) : setPMDCount(0);
            setIsSummaryLoading(false);
        } catch (error) {
            setIsSummaryLoading(false);
        }
    };

    const fetchRecentServiceReport = async () => {
        setIsRecentServiceReportLoading(true);
        const res = await getRecentServiceReport();
        setIsRecentServiceReportLoading(false);
        if (res.recentServiceReportCount) {
            setRSRCount(parseInt(res.recentServiceReportCount));
        } else {
            setRSRCount(0);
        }
    };

    const fetchSDListService = async () => {
        setIsSDListServiceLoading(true);
        const icrList = await getSDListService();
        setIsSDListServiceLoading(false);
        setServiceDetailsList(icrList);
        const latestReport = icrList[0];
        setMostRecentVaultReport(latestReport?.lastServiceDate ? latestReport.lastServiceDate : "");
    };

    const fetchInventoryServiceReport = async () => {
        setIsInventoryServiceReportLoading(true);
        const resInv = await getInventoryServiceReport();
        setIsInventoryServiceReportLoading(false);
        setInventoryCompareList(resInv);
    };

    return (
        <Box sx={styles.notificationContainer} gap={2}>
            <CircularLoaderFullPage
                show={
                    isSummaryLoading ||
                    isRecentServiceReportLoading ||
                    isInventoryServiceReportLoading ||
                    isSDListServiceLoading
                }
            />

            <HomeHeaderSection
                todoCount={aRVCount + pMDCount}
                reportsCount={rSRCount}
                selectedButton={selectedButton}
                handleButtonClick={handleButtonClick}
            />
            {selectedButton === notificationButtons.todo ? (
                <Box sx={styles.boxPaddingX}>
                    <AuthorizationsVerificationSection count={aRVCount} />
                    <Divider />
                    <PMDRequestSection count={pMDCount} />
                </Box>
            ) : (
                <Box sx={styles.boxPaddingX}>
                    {rSRCount && rSRCount !== 0 ? (
                        <ReportsHeaderSection mostRecentVaultReport={mostRecentVaultReport} />
                    ) : null}
                    <InventoryCaptureResults count={0} inventoryCompareList={inventoryCompareList} />
                    <ServiceDetailsSection count={rSRCount} rows={serviceDetailsList} />
                </Box>
            )}
        </Box>
    );
};
