import { getDocs } from "firebase/firestore";
import { t } from "i18next";
import { getUserAddress, userAlreadyExist } from "./authorizationService";
import { ssoGetUser, ssoHandleService } from "./ciamServices";
import { apiResultSet, apiResultSet0Collection, apiResultSet1Collection } from "./firebase";
import { sendAuthUpdateMail } from "./mailService";
import { createUpdateAccountPayload, createUpdateCustomerPayload } from "./payloadService";
import { ssoRegister } from "./userServices";
import {
    constructFbHeaders,
    createArrayObj,
    getCustomers,
    getPersonId,
    getSelectedCustomerDb,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { authLevelCode } from "src/constants/autherizationConstants/authConstants";

const apiUrls = {
    applyCustomerPersonalSetting: "/ssautherization/applycustomerpersonalsetting",
    personnelUpdate: "/ssautherization/personnelupdate",
};
/**
 * Creates a securesync user with provided details
 * @param {*} personnel object which contains all personnel details
 * @returns
 */
export const updateAccountInfoService = async (request) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.applyCustomerPersonalSetting);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/applycustomerpersonalsetting";
    try {
        const custCount = request.customerCount;
        const res = await invokeApi("POST", loginUrl, request.payload, {}, headers);
        const resDoc = res?.docId;

        let startCollection = custCount;
        for (let index = 0; index < custCount; index++) {
            const snapShot = await getDocs(apiResultSet(resDoc, `resultSet_${index}`));
            const snapObj = await createArrayObj(snapShot);
            const notAnCustomerDetails = snapObj[0] && snapObj[0].number;
            if (notAnCustomerDetails) {
                startCollection = index;
                break;
            }
        }
        const updatedDataString = `resultSet_0`;
        const successCustomerListString = `resultSet_${startCollection}`;
        const interactionCancelledCustomerListString = `resultSet_${startCollection + 1}`;
        const dRCancelledListString = `resultSet_${startCollection + 2}`;
        const cancelListCode = `resultSet_${startCollection + 3}`;
        const ciamString = `resultSet_${startCollection + 4}`;
        const errorCoude = `resultSet_${startCollection + 5}`;

        const [rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot, rs5Snapshot, rs6Snapshot, rs7Snapshot] =
            await Promise.all([
                getDocs(apiResultSet(resDoc, updatedDataString)),
                getDocs(apiResultSet(resDoc, successCustomerListString)),
                getDocs(apiResultSet(resDoc, interactionCancelledCustomerListString)),
                getDocs(apiResultSet(resDoc, dRCancelledListString)),
                getDocs(apiResultSet(resDoc, cancelListCode)),
                getDocs(apiResultSet(resDoc, ciamString)),
                getDocs(apiResultSet(resDoc, errorCoude)),
            ]);
        const updateData = await createArrayObj(rs1Snapshot);
        const successCustomerList = await createArrayObj(rs2Snapshot);
        const interactionCancelledCustomerList = await createArrayObj(rs3Snapshot);
        const dRCancelledList = await createArrayObj(rs4Snapshot);
        const cancelledList = await createArrayObj(rs5Snapshot);
        const ciamData = await createArrayObj(rs6Snapshot);
        const errorCode = await createArrayObj(rs7Snapshot);
        return {
            updateData,
            successCustomerList,
            interactionCancelledCustomerList,
            dRCancelledList,
            cancelledList,
            ciamData: ciamData[0],
            errorCode: errorCode[0],
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

// eslint-disable-next-line max-lines-per-function
export const updatePersonAccount = async (
    values,
    contextCustomerId = null,
    personId,
    rows,
    personalData,
    intAuthActionType,
    drAuthActionType,
    permission,
    currentData
) => {
    const payload = createUpdateAccountPayload(
        values,
        contextCustomerId,
        personId,
        intAuthActionType,
        drAuthActionType,
        currentData
    );
    try {
        const drCode = payload.payload.dr_auth_code ? payload.payload.dr_auth_code.trim() : authLevelCode.none;
        const intCode = payload.payload.int_auth_code ? payload.payload.int_auth_code.trim() : authLevelCode.none;
        const businessEmail = values.businessEmail;
        const accountNumber = payload.payload.context_customer_id;
        const modifiedUser = payload.payload.last_mod_user;
        const targetName = personalData.first_name;
        const contextCustomer = rows.find((cus) => cus.account === payload.payload.context_customer_id);

        let changedDr;
        let changeInt;
        values.selectedCustomers.forEach((element) => {
            const customer = rows.find((cus) => cus.account === element);
            if (customer.drAuth.trim() !== drCode) {
                changedDr = true;
            }
            if (customer.interactionAuth.trim() !== intCode) {
                changeInt = true;
            }
        });

        if (contextCustomer.drAuth.trim() === drCode) {
            payload.payload.dr_auth_action = "N";
        }
        if (contextCustomer.interactionAuth.trim() === intCode) {
            payload.payload.int_auth_action = "N";
        }
        // const userDetails = getLoggedUserDetails();
        const res = await updateAccountInfoService(payload);
        if (res.error && res.error && res.error.hasError) {
            return {
                error: true,
                message: res.error.displayMessage, // res.errorCode[0]
            };
        }
        const sqlUserUpdate = res.successCustomerList && res.successCustomerList.length > 0;
        if (sqlUserUpdate) {
            let sucMessage = "";
            let dRCancelledMessage = "";
            let interactionCancelledMessage = "";
            let cancelledMessage = "";
            let mailSendMessage = "";
            if (res.successCustomerList && res.successCustomerList.length >= 1) {
                const tempListSuccess = [];
                res.successCustomerList.forEach((customer) => {
                    tempListSuccess.push(customer.number);
                });
                tempListSuccess.sort();
                sucMessage = t("userDetails.userUpdateSuccess", { value: tempListSuccess.toString() });
            }
            if (changeInt || changedDr) {
                const modifyTo = [
                    {
                        emailAddress: businessEmail,
                        name: targetName,
                    },
                ];
                const resMail = await sendAuthUpdateMail(
                    accountNumber,
                    targetName,
                    modifiedUser,
                    businessEmail,
                    intCode,
                    drCode,
                    modifyTo
                );
                if (!resMail.isSuccess) {
                    mailSendMessage = `${t(`messages.systemErrorMessage`)}`;
                } else {
                    mailSendMessage = `${t(`userDetails.emailConfirmationSend`)}`;
                }
            }
            if (res.dRCancelledList && res.dRCancelledList.length >= 1 && permission?.canEditDrAuth) {
                const tempList = [];
                res.dRCancelledList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                dRCancelledMessage = t("authorization.drCancelMsg", { value: tempList.toString() });
            }
            if (
                res.interactionCancelledCustomerList &&
                res.interactionCancelledCustomerList.length >= 1 &&
                permission?.canEditInt
            ) {
                const tempList = [];
                res.interactionCancelledCustomerList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                interactionCancelledMessage = t("authorization.interactionCancelMsg", { value: tempList.toString() });
            }
            if (res.cancelledList && res.cancelledList.length >= 1) {
                const tempList = [];
                res.cancelledList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                cancelledMessage = t("authorization.cancelListMsg", { value: tempList.toString() });
            }

            return {
                error: false,
                sucMessage,
                dRCancelledMessage,
                interactionCancelledMessage,
                cancelledMessage,
                mailSendMessage,
            };
        } else {
            return {
                error: true,
                message: t("authorization.authLevelNotMatched"), // res.errorCode[0]
            };
        }
    } catch (error) {
        return error;
    }
};

export const updatePersonService = async (request, ssLogin = null) => {
    const custMetaData = getCustomers();
    let targetCustomer = null;
    if (custMetaData.custList.length === 1) {
        targetCustomer = custMetaData.selectedCustomer.customer_id ? custMetaData.selectedCustomer.customer_id : false;
    } else {
        targetCustomer = request.selectedCustomers[0];
    }
    const payload = createUpdateCustomerPayload(request);
    try {
        const isSecureSyncUser = request.enableSecureSync;
        const res = await updateUser(payload.payload);
        let extraWarningMsg = "";
        let allowCiamUpdate = true;

        if (res.error && res.error && res.error && res.error.hasError) {
            if (res.error.severity === "E") {
                return {
                    error: true,
                    message: res.error.displayMessage, // res.errorCode[0]
                };
            } else {
                const errorCode = res.error.error.errorCode;
                allowCiamUpdate = errorCode !== "80482";
                extraWarningMsg = t(`apiErrorMessages.${res.error.error.errorCode}`);
            }
        }
        const sqlUserUpdate = res.updateData;
        if (sqlUserUpdate) {
            const userObj = {
                first_name: payload.payload.first_name,
                last_name: payload.payload.last_name,
                home_phone: "",
                business_phone: "",
                business_phone_ext: "",
                e_level_authorization: "",
                duplicate_check_flag: "Y",
                auth_number: "",
                middle_name: payload.payload.middle_name,
            };

            let user = await getUserAddress(userObj, targetCustomer);
            user = user.rs0[0] ? user.rs0[0] : null;
            if (isSecureSyncUser && allowCiamUpdate) {
                const ssLoginUserName = ssLogin ? ssLogin : request.businessEmail;
                const sSoRes = await ssoHandleService(ssLoginUserName, request);
                if (!sSoRes) {
                    return {
                        error: true,
                        message: "securesync user creation failed",
                    };
                }
                if (!ssLogin) {
                    const oktaUser = await ssoGetUser(request.businessEmail);
                    const ssoPayload = {
                        main_district_id: "50",
                        personnel_id: user.personnel_id,
                        ciam_uid: oktaUser.userId,
                        rc: "1",
                    };
                    await ssoRegister(ssoPayload);
                }
            }
            let sucMessage = "";

            if (res.successCustomerList && res.successCustomerList.length >= 1) {
                const tempListSuccess = [];
                res.successCustomerList.forEach((customer) => {
                    tempListSuccess.push(customer.number);
                });
                sucMessage = t("authorization.createSuccess", {
                    name: user.personnel_name,
                    value: tempListSuccess.toString(),
                });
            }

            return {
                error: false,
                sucMessage,
                extraWarningMsg,
            };
        } else {
            return {
                error: true,
                message: t("authorization.authLevelNotMatched"), // res.errorCode[0]
            };
        }
    } catch (error) {
        return error;
    }
};

/**
 * Updated personnel information
 * @param {*} personnel
 * @returns
 */
export const updateUser = async (payload) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.personnelUpdate);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/personnelupdate";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        ...payload,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);

        return {
            updateData: rs0Data && rs0Data[0] ? rs0Data[0] : null,
            error: rs1Data[0],
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getDuplicateUsers = async (personalD, customer, askingPerson = getPersonId()) => {
    const userObj = {
        firstName: personalD.first_name,
        lastName: personalD.last_name,
        middleName: personalD.middle_name,
    };
    const userValidate = await userAlreadyExist(userObj, customer, askingPerson);
    return userValidate?.isUserNameTaken;
};
