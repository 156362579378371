/* eslint-disable max-lines */
import { Box, Container, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import styles from "./Other.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getActiveCustomersSiblingsService } from "src/services/requestServices";
import OtherServicesForm from "src/components/Request/OtherServices/OtherServicesForm/OtherServicesForm";
import { getServiceFrequency } from "src/utils/requestHelper";

//extension businessPhone businessFax customerEmployeeNo
const Other = () => {
    const [initValues, setInitValues] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [serviceFrequencyValues, setServiceFrequencyValues] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await loadPageData();
            setLoading(false);
        };
        fetchData();
    }, []);

    const loadPageData = async () => {
        const values = await getActiveCustomersSiblingsService();
        const serviceLocationList = values.map((e) => {
            return {
                id: parseInt(e.customer_id),
                label: e.customer_number ? e.customer_number : "",
                value: e.customer_id,
            };
        });
        serviceLocationList.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
        const serviceFreqValues = await getServiceFrequency();
        setServiceFrequencyValues(serviceFreqValues);
        setInitValues(serviceLocationList);
    };

    const { t } = useTranslation();
    return (
        <Container disableGutters>
            <CircularLoaderFullPage show={isLoading} />
            <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" width="100%">
                <Box width="100%" textAlign="left">
                    <PageTitle> {t("titles.otherRequestTitle")}</PageTitle>
                    {initValues && initValues.length > 0 ? (
                        <>
                            <Typography variant="body1" sx={styles.typographyBody}>
                                <Trans i18nKey={""} />
                                <Trans
                                    i18nKey={"otherRequest.subTitle"}
                                    values={{ buttonLabel: t("buttonLabels.submit") }}
                                />
                            </Typography>
                            <OtherServicesForm
                                initValues={initValues}
                                serviceFrequencyValues={serviceFrequencyValues}
                            />
                        </>
                    ) : (
                        <Typography variant="body1" sx={styles.typographyBody}>
                            <Trans i18nKey={"otherRequest.msgNoScheduleEvent"} />
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default Other;
