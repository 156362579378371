/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { useHotkeysContext } from "react-hotkeys-hook";
import isEqual from "lodash.isequal";
import styles from "./ContainerPickpup.styles";
import { ContainerContentsSummary } from "src/components/ProcessMedia/ContainerPickup/ContainerContentsSummary/ContainerContentsSummary";
import ContainerPickupDataTable from "src/components/ProcessMedia/ContainerPickup/DataTable/ContainerPickupDataTable";
import { getProgramIdsOfCurrentCustomer, getUserDetails, getBasicUserInfo } from "src/redux/userDetailsSlice";
import {
    getContainers,
    getRetentionProfiles,
    updateContainerItem,
    validateContainers,
} from "src/services/processMediaService";
import {
    convertToLocaleUpperCase,
    filterData,
    getKeyBoardLabel,
    padEndStringWithSelectedCharacters,
    padStartStringWithSelectedCharacters,
} from "src/utils/commonHelper";
import { getSelectedCustomerId } from "src/services/utils";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import {
    mapUpdatedContainerDataToContainerData,
    mapValidContainers,
    multipleSort,
    processMediaErrors,
    sortContainerPickup,
} from "src/utils/processMediaHelper";
import { formatDate } from "src/utils/dateHelper";
import { programIds } from "src/constants/programIdsConstants";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { yesFlag } from "src/constants/serviceConstants";
import Pencil from "src/components/Icons/Pencil/Pencil";
import AddContainerContentModal from "src/components/ProcessMedia/ContainerPickup/AddContainerContentModal/AddContainerContentModal";
import PickupManifestModal from "src/components/ProcessMedia/ContainerPickup/PickupManifestModal/PickupManifestModal";
import EditContainerContentsModal from "src/components/ProcessMedia/ContainerPickup/EditContainerContentsModal/EditContainerContentsModal";
import { sortCriteriaEditContainer, sortCriteriaSubmitContainer } from "src/constants/processMediaConstants";
import { indefiniteDate } from "src/constants/dateFormatConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys, hotKeysScopes } from "src/constants/shortCutKeys";

export const ContainerPickup = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => onHandlerSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => onHandlerReset() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.pickupManifest,
        callback: () => {
            if (!disablePickupManifest) {
                openPickupManifestModalHandler();
            }
        },
        options: { scopes: hotKeysScopes.containerPickup },
    });

    const { t } = useTranslation();
    const theme = useTheme();
    const oktaUserDetails = useSelector(getUserDetails);
    const userFlags = useSelector(getBasicUserInfo);
    const programIdsList = useSelector(getProgramIdsOfCurrentCustomer);
    const [isLoading, setIsLoading] = useState(false);
    const [containerData, setContainerData] = useState([]);
    const [containerPickupData, setContainerPickupData] = useState([]);
    const [prePreparedContainerPickupData, setPrePreparedContainerPickupData] = useState([]);
    const [initialContainerPickupData, setInitialContainerPickupData] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isPreviousContainerPickup, setIsPreviousContainerPickup] = useState(false);
    const [isAddContainerModalOpen, setIsAddContainerModalOpen] = useState(false);
    const [isPickupManifestModalOpen, setIsPickupManifestModalOpen] = useState(false);
    const [isEditContainerModalOpen, setIsEditContainerModalOpen] = useState(false);
    const [showContainerContentSummary, setShowContainerContentSummary] = useState({ show: false, state: {} });
    const [updatedRows, setUpdatedRows] = useState(new Set());
    const [scanVerified, setScanVerified] = useState(new Set());
    const [editedContainers, setEditedContainers] = useState([]);
    const [containerString, setContainerString] = useState("");
    const [retentionProfiles, setRetentionProfiles] = useState({
        retentionProfileDropdownOption: [{ id: " ", label: t("common.defaultDropdownOption"), value: " " }],
        retentionProfileList: [],
    });
    const columns = [
        {
            field: "edit",
            headerName: "",
            width: 60,
            sortable: false,
            renderCell: (params) => {
                if (params.isReturnDateEditable || params.isCheckboxEditable) {
                    return (
                        <IconButton
                            onClick={() => {
                                openEditContainerModalHandler(params);
                            }}
                        >
                            {isEditContainerModalOpen && selectedRecord?.Container_Id === params?.Container_Id ? (
                                <DoneIcon sx={styles.doneIcon} />
                            ) : updatedRows.has(params?.Container_Id) || scanVerified.has(params?.Container_Id) ? (
                                <Pencil fill={theme.palette.im_orange["500"]} />
                            ) : (
                                <Pencil fill={theme.palette.im_mid_blue["500"]} />
                            )}
                        </IconButton>
                    );
                }
            },
        },
        {
            field: "Number",
            headerName: t("containerPickup.containerNumber").toLocaleUpperCase() + String.fromCharCode(185),
            flex: 1,
            sortable: true,
        },
        {
            field: "includeOnManifestFlag",
            headerName: t("containerPickup.pickup").toLocaleUpperCase(),
            flex: 1,
            sortable: true,
            renderCell: (params) =>
                params.includeOnManifestFlag === 1 ? <span>{t("common.yes")}</span> : <span>{t("common.no")}</span>,
        },
        {
            field: "Pending_Return_Date",
            headerName: t("containerPickup.returnDate").toLocaleUpperCase(),
            flex: 1,
            sortable: false,
            renderCell: (params) => getPendingReturnDate(params),
        },
        {
            field: "Descr",
            headerName: t("containerPickup.description").toLocaleUpperCase() + String.fromCharCode(178),
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip title={params.Descr} arrow>
                            <Box component={"span"} style={styles.renderCell}>
                                {params.Descr}
                            </Box>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            field: "Profile_Name",
            headerName: t("containerPickup.retentionProfile").toLocaleUpperCase(),
            flex: 1,
            sortable: false,
        },
    ];

    const checkUpdatedRecord = (updatedContainerObj) => {
        setUpdatedRows((prevUpdatedRows) => {
            const getInitialRecord = filterData(
                initialContainerPickupData,
                "Container_Id",
                updatedContainerObj.Container_Id
            );
            const newUpdatedRows = new Set(prevUpdatedRows);
            if (isEqual(updatedContainerObj, getInitialRecord[0])) {
                newUpdatedRows.delete(updatedContainerObj.Container_Id);
            } else {
                newUpdatedRows.add(updatedContainerObj.Container_Id);
            }
            return newUpdatedRows;
        });
    };

    const handleChangePreviousContainerPickup = (e) => {
        setIsPreviousContainerPickup(e.target.checked);
    };

    const { enableScope, disableScope } = useHotkeysContext();
    useEffect(() => {
        if (showContainerContentSummary.show) {
            disableScope(hotKeysScopes.containerPickup);
            enableScope(hotKeysScopes.containerContentsSummary);
        } else {
            disableScope(hotKeysScopes.containerContentsSummary);
            enableScope(hotKeysScopes.containerPickup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showContainerContentSummary.show]);

    useEffect(() => {
        const filterPreviousContainerPickup = () => {
            setIsLoading(true);
            if (isPreviousContainerPickup) {
                setContainerData([...prePreparedContainerPickupData, ...containerPickupData]);
            } else {
                setContainerData(containerPickupData);
            }
            setIsLoading(false);
        };

        filterPreviousContainerPickup();
    }, [isPreviousContainerPickup, prePreparedContainerPickupData, containerPickupData]);

    const getContainerDetails = async () => {
        setIsLoading(true);
        try {
            const havePermission = programIdsList.some((el) => el === programIds.programId_216);
            const containerListRes = await getContainers(getSelectedCustomerId(), yesFlag);

            const containerList = sortContainerPickup(
                await containerListRes?.rs0?.map((container) => {
                    const reportReturnDate =
                        container.At_Iron_Mtn_Flag === "N" && container.In_Transit_Flag === "N"
                            ? container.Display_Return_Date
                            : "";
                    const returnDateNull = container.Return_Date ? 1 : 0;
                    const pendingReturnDateNull = container.Pending_Return_Date_Local ? 1 : 0;
                    const failureCode = 0;
                    const pendingChanges = 0;
                    const includeOnManifestFlag = container.Pending_Return_Date_Local ? 1 : 0;
                    let IconState;
                    if (failureCode !== 0) {
                        IconState = 20;
                    } else {
                        if (container.In_Discrepancy_Flag === "Y" || container.To_Be_Issued_Flag === "Y") {
                            IconState = 50;
                        } else {
                            IconState = 99;
                        }
                    }
                    const includeOnPrintedManifestFlag = false;

                    const isContainer = container.Container_Transport_Flag === "C" ? true : false;
                    const isInDescrepancy = container.In_Discrepancy_Flag === "Y" ? true : false;
                    const isNotAtIronMtn = container.Not_At_Iron_Mtn_Flag === "Y" ? true : false;
                    const isInTransit = container.In_Transit_Flag === "Y" ? true : false;
                    const isToBeIssued = container.To_Be_Issued_Flag === "Y" ? true : false;
                    const isAtIronMtn = container.At_Iron_Mtn_Flag === "Y" ? true : false;
                    const isCheckboxEditable = !isInDescrepancy && (isNotAtIronMtn || isInTransit);
                    const isReturnDateEditable = isContainer && isCheckboxEditable;
                    const isDescriptionEditable = isReturnDateEditable;
                    const isProfileEditable = isReturnDateEditable;
                    const isOnManifest = includeOnManifestFlag === 1 ? true : false;

                    const hasAccessToEditReturnDate = havePermission;

                    const isDeleted = !isInTransit && !isToBeIssued && !isAtIronMtn && !isNotAtIronMtn;
                    return {
                        ...container,
                        // eslint-disable-next-line camelcase
                        Pending_Return_Date:
                            container.Pending_Return_Date === indefiniteDate ? "" : container.Pending_Return_Date,
                        reportReturnDate: reportReturnDate,
                        returnDateNull: returnDateNull,
                        pendingReturnDateNull: pendingReturnDateNull,
                        failureCode: failureCode,
                        pendingChanges: pendingChanges,
                        includeOnManifestFlag: includeOnManifestFlag,
                        IconState: IconState,
                        includeOnPrintedManifestFlag: includeOnPrintedManifestFlag,

                        isContainer: isContainer,
                        isInDescrepancy: isInDescrepancy,
                        isNotAtIronMtn: isNotAtIronMtn,
                        isInTransit: isInTransit,
                        isToBeIssued: isToBeIssued,
                        isAtIronMtn: isAtIronMtn,
                        isCheckboxEditable: isCheckboxEditable,
                        hasAccessToEditReturnDate: hasAccessToEditReturnDate,
                        isReturnDateEditable: isReturnDateEditable,
                        isDeleted: isDeleted,
                        isDescriptionEditable: isDescriptionEditable,
                        isProfileEditable: isProfileEditable,
                        isOnManifest: isOnManifest,
                    };
                })
            );

            await setContainerPickupData(filterData(containerList, "includeOnManifestFlag", 0) || []);
            await setPrePreparedContainerPickupData(filterData(containerList, "includeOnManifestFlag", 1) || []);
            await setInitialContainerPickupData(containerList || []);
        } catch (error) {
            console.error("Error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getRetentionProfilesList = async () => {
            const retentionProfRes = await getRetentionProfiles("3", t("containerPickup.indefinite"));
            if (retentionProfRes?.profiles?.length > 0) {
                let retentionOptions = [];
                retentionOptions = retentionProfRes?.profiles?.map((data) => {
                    return {
                        id: data.retention_profile_id,
                        label: data.profile_name,
                        value: data.retention_profile_id,
                    };
                });
                setRetentionProfiles({
                    retentionProfileDropdownOption: retentionOptions,
                    retentionProfileList: retentionProfRes?.profiles,
                });
            }
        };
        getRetentionProfilesList();
        getContainerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addContainerForValidate = async (data) => {
        setContainerString(data);
        setIsLoading(true);
        try {
            const validatedContainers = await validateContainers(
                getSelectedCustomerId(),
                data,
                t("containerPickup.invalidContainerNumber"),
                t("containerPickup.invalidCustomerSubmitted"),
                t("containerPickup.invalidContainerStatus")
            );
            return validatedContainers;
        } catch (error) {
            console.error("validated container error: ", error);
        } finally {
            setIsLoading(false);
        }
    };
    const openAddContainerModalHandler = () => {
        setIsAddContainerModalOpen(true);
    };

    const closeAddContainerModalHandler = () => {
        setIsAddContainerModalOpen(false);
    };

    const submitAddContainerModalHandler = async (containerArr) => {
        setIsLoading(true);
        try {
            const containerList = await containerArr
                .map((item) => {
                    const container = padStartStringWithSelectedCharacters(item.container, 6, "0");
                    if (item.mediaNo.length > 0) {
                        return item.mediaNo
                            .map((media) => {
                                const formattedMedia = padEndStringWithSelectedCharacters(media, 20, " ");
                                return `${container}${formattedMedia}`;
                            })
                            .join("");
                    } else {
                        return `${container}                    `;
                    }
                })
                .join("");

            const validatedContainersResponse = await addContainerForValidate(containerList);
            closeAddContainerModalHandler();
            setShowContainerContentSummary({ show: true, state: validatedContainersResponse });
        } catch (error) {
            console.error("Submit add container: ", error);
        } finally {
            setIsLoading(false);
        }
    };
    const openPickupManifestModalHandler = () => {
        setIsPickupManifestModalOpen(true);
    };

    const closePickupManifestModalHandler = () => {
        setIsPickupManifestModalOpen(false);
    };

    const openEditContainerModalHandler = (params) => {
        setSelectedRecord(params);
        setIsEditContainerModalOpen(true);
    };

    const closeEditContainerModalHandler = () => {
        setIsEditContainerModalOpen(false);
        setSelectedRecord({});
    };

    const submitEditContainerModalHandler = (value) => {
        const filteredEditContainers = editedContainers.filter((item) => item.containerId !== value.containerId);
        setEditedContainers([...filteredEditContainers, value]);

        const containerData = filterData(containerPickupData, "Container_Id", value.containerId);
        const preContainerData = filterData(prePreparedContainerPickupData, "Container_Id", value.containerId);
        const mappedObj = mapUpdatedContainerDataToContainerData(
            value,
            containerData.length > 0 ? containerData[0] : preContainerData[0]
        );
        const filteredContainer = containerPickupData.filter((item) => item.Container_Id !== mappedObj.Container_Id);
        const filteredPreContainer = prePreparedContainerPickupData.filter(
            (item) => item.Container_Id !== mappedObj.Container_Id
        );

        checkUpdatedRecord(mappedObj);

        if (preContainerData.length > 0) {
            setPrePreparedContainerPickupData(
                multipleSort([mappedObj, ...filteredPreContainer], sortCriteriaEditContainer)
            );
            setContainerPickupData(filteredContainer);
        } else {
            setPrePreparedContainerPickupData(filteredPreContainer);
            setContainerPickupData(multipleSort([mappedObj, ...filteredContainer], sortCriteriaEditContainer));
        }
        closeEditContainerModalHandler();
    };

    const onSubmitContainerContent = async (validContainers) => {
        setIsLoading(true);
        const scanVerifiedRecords = new Set(scanVerified);
        for (const validContainer of multipleSort(validContainers, sortCriteriaSubmitContainer)) {
            const containerData = filterData(containerPickupData, "Container_Id", validContainer.Container_Id);
            const preContainerData = filterData(
                prePreparedContainerPickupData,
                "Container_Id",
                validContainer.Container_Id
            );

            if (containerData.length > 0) {
                const container = mapValidContainers(
                    containerData[0],
                    retentionProfiles.retentionProfileList,
                    t("containerPickup.indefinite")
                );
                scanVerifiedRecords.add(container.Container_Id);
                updateContainerPickupState(container);
                updateContainerToInitialState(container);
            } else {
                const container = mapValidContainers(
                    preContainerData[0],
                    retentionProfiles.retentionProfileList,
                    t("containerPickup.indefinite")
                );
                scanVerifiedRecords.add(container.Container_Id);
                updateContainerPickupState(container);
                setPrePreparedContainerPickupData((prevData) => [
                    ...prevData.filter((item) => item.Container_Id !== validContainer.Container_Id),
                ]);
                updateContainerToInitialState(container);
            }
        }
        setIsLoading(false);
        hideContainerContent();
        setUpdatedRows(scanVerifiedRecords);
        setScanVerified(scanVerifiedRecords);
    };

    const updateContainerToInitialState = (container) => {
        setInitialContainerPickupData((prevItems) =>
            prevItems.map((item) => (item.Container_Id === container.Container_Id ? container : item))
        );
    };

    const updateContainerPickupState = (container) => {
        setContainerPickupData((prevData) => [
            container,
            ...prevData.filter((item) => item.Container_Id !== container.Container_Id),
        ]);
    };

    const hideContainerContent = () => {
        setShowContainerContentSummary({ show: false, state: {} });
    };

    const onHandlerReset = () => {
        setIsPreviousContainerPickup(false);
        if (updatedRows.size > 0) {
            setContainerPickupData(filterData(initialContainerPickupData, "includeOnManifestFlag", 0) || []);
            setPrePreparedContainerPickupData(filterData(initialContainerPickupData, "includeOnManifestFlag", 1) || []);
            setUpdatedRows(new Set());
        }
        hideContainerContent();
        setScanVerified(new Set());
    };

    const updateContainerItems = async (containerObj) => {
        setIsLoading(true);
        try {
            const res = await updateContainerItem(containerObj);
            if (res?.failure) {
                const error = processMediaErrors(res?.errorCode);
                console.error("update Container Item error: ", error);
            }
        } catch (error) {
            console.error("update Container - catch Error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onHandlerSubmit = async () => {
        const updatedRowsValue = new Set(updatedRows);
        editedContainers.forEach((item) => updatedRowsValue.delete(item.containerId));
        if (updatedRowsValue.size !== 0 || editedContainers.length > 0) {
            const allContainerArr = [...prePreparedContainerPickupData, ...containerPickupData];
            setInitialContainerPickupData(allContainerArr);
            const filterContainerArr = await allContainerArr.filter((container) =>
                updatedRowsValue.has(container.Container_Id)
            );

            const mappedUpdatedContainer = await filterContainerArr.map((container) => {
                return {
                    newReturnDate: container.Pending_Return_Date,
                    containerId: container.Container_Id,
                    newDescription: container.Descr,
                    personnelId: userFlags.sys_user_id || "",
                    prepareForPickup: container.includeOnManifestFlag,
                    returnDateChange: "Y",
                    username: oktaUserDetails.email || "",
                    containerTimestamp: "",
                    retentionProfileId: container.Retention_Profile_Id,
                    deletedPhrase: t("localizedServiceDetails.deleted0"),
                    sentToPhrase: t("localizedServiceDetails.sentTo0"),
                    notAtIronmountainPhrase: t("localizedServiceDetails.notAtIronMountain"),
                    indefinitePhrase: t("containerPickup.indefinite"),
                    allPhrase: `<${convertToLocaleUpperCase(t("common.all"))}>`,
                    allExcludingDeletedPhrase: t("containerPickup.allExcludingDeleted"),
                };
            });

            await [...mappedUpdatedContainer, ...editedContainers].forEach((containerObj) =>
                updateContainerItems(containerObj)
            );

            setEditedContainers([]);
            setUpdatedRows(new Set());
        }
        setScanVerified(new Set());
    };

    const getPendingReturnDate = (params) => {
        const isReadyForPickup = params?.includeOnManifestFlag === 1;
        if (params?.isReturnDateEditable || !params?.hasAccessToEditReturnDate) {
            const pendingReturnDate = formatDate(params?.Pending_Return_Date);
            if (isReadyForPickup) {
                if (pendingReturnDate) {
                    return <>{pendingReturnDate}</>;
                }
                return <>{t("containerPickup.indefinite")}</>;
            }
            return <>{pendingReturnDate}</>;
        } else if (params?.isContainer) {
            if (params?.isDeleted) {
                const displayReturnDate = formatDate(params?.Display_Return_Date);
                if (isReadyForPickup) {
                    return displayReturnDate ? displayReturnDate : t("containerPickup.indefinite");
                }
                return <>{displayReturnDate}</>;
            } else {
                return <>{params?.Summary_Type}</>;
            }
        }
    };

    const displayedPreparedContainers = filterData(containerData, "includeOnManifestFlag", 1);

    const disablePickupManifest =
        scanVerified.size === 0 &&
        !((displayedPreparedContainers.length > 0 && updatedRows.size === 0) || isPreviousContainerPickup);

    return !showContainerContentSummary.show ? (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("containerPickup.prepareContainerForPickup")}</PageTitle>
                        {initialContainerPickupData.length === 0 && (
                            <Alert severity="info">
                                {t("containerPickup.noContainersAvailable")} <br />
                                {t("containerPickup.click")}{" "}
                                <Box component={"span"} sx={styles.fontWeight700}>
                                    '{t("containerPickup.addContainerContents")}'
                                </Box>{" "}
                                {t("containerPickup.buttonToKeyImportOrScanTheContainerContents")}
                            </Alert>
                        )}
                        {initialContainerPickupData.length > 0 && (
                            <>
                                <Typography variant="body1" sx={styles.subheader}>
                                    <Trans
                                        i18nKey={
                                            "containerPickup.selectThePickupCheckBoxToIndicateContainersToBePickedUpOnTheNext"
                                        }
                                    />
                                </Typography>
                                <Typography variant="body1" sx={styles.subheader}>
                                    <Trans
                                        i18nKey={
                                            "containerPickup.toSelectTheScheduledItemsThatShouldBePrintedOnTheManifestForIronMountainPickup"
                                        }
                                    />
                                </Typography>
                            </>
                        )}
                        <Divider />
                        <br />
                        <Box display="flex" gap={2} sx={styles.marginBottomBreak}>
                            <Typography variant="h5" sx={styles.sectionTitle}>
                                {t("containerPickup.containerContents")}
                            </Typography>
                        </Box>
                        {prePreparedContainerPickupData.length > 0 && (
                            <Box sx={styles.singleColumnContainer}>
                                <Box sx={styles.switchLabelContainer}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isPreviousContainerPickup}
                                                onChange={handleChangePreviousContainerPickup}
                                            />
                                        }
                                        label={t("containerPickup.showPreviouslyPreparedContainers")}
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={styles.footNotes}>
                                        <Trans
                                            i18nKey={
                                                "containerPickup.useThisOptionToMakeUpdatesOrReprintThePickupManifest"
                                            }
                                        />
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <ContainerPickupDataTable
                        columns={columns}
                        containerData={containerData}
                        openAddContainerModalHandler={openAddContainerModalHandler}
                        selectedRecordId={selectedRecord?.Container_Id}
                    />
                    <Box sx={styles.innerBox}>
                        <Typography variant="body1" sx={styles.subheader}>
                            {t("containerPickup.click")}{" "}
                            <Box component={"span"} sx={styles.fontWeight700}>
                                '{t("containerPickup.addContainerContents")}'
                            </Box>{" "}
                            {t("containerPickup.buttonToKeyImportOrScanTheContainerContents")}
                        </Typography>
                        <Typography variant="body1" color="im_grey.900" component="div" sx={styles.footNotes}>
                            {t("containerPickup.containersBeginningWithTRAreTransports")}
                            <br />
                            {t("containerPickup.ironMountainBranchesCannotSeeOrChangeTheDescription")}
                        </Typography>
                        <Divider />
                        <br />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.pickupManifest)}
                                variant="outlined"
                                disabled={disablePickupManifest}
                                onClick={openPickupManifestModalHandler}
                            >
                                {t("containerPickup.pickupManifest")}
                            </Button>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.reset)}
                                variant="outlined"
                                onClick={onHandlerReset}
                            >
                                {t("common.reset")}
                            </Button>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                                variant="contained"
                                onClick={onHandlerSubmit}
                            >
                                {t("common.submit")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            {isAddContainerModalOpen && (
                <AddContainerContentModal
                    onClose={closeAddContainerModalHandler}
                    onSubmit={submitAddContainerModalHandler}
                />
            )}
            {isPickupManifestModalOpen && (
                <PickupManifestModal
                    containerData={displayedPreparedContainers}
                    onClose={closePickupManifestModalHandler}
                />
            )}
            {isEditContainerModalOpen && (
                <EditContainerContentsModal
                    retentionProfiles={retentionProfiles}
                    recordData={selectedRecord}
                    onClose={closeEditContainerModalHandler}
                    onSubmit={submitEditContainerModalHandler}
                    isPrePrepared={prePreparedContainerPickupData.some((item) => item.Number === selectedRecord.Number)}
                />
            )}
        </>
    ) : (
        <ContainerContentsSummary
            containerString={containerString}
            containerDetails={showContainerContentSummary.state}
            onClose={hideContainerContent}
            onSubmit={onSubmitContainerContent}
        />
    );
};
