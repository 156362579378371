const styles = {
    detailsContainer: { display: "grid", gridTemplateColumns: "repeat(2,1fr)" },
    containerDataGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        "--Grid-borderWidth": "1px",
        borderTop: "var(--Grid-borderWidth) solid",
        borderLeft: "var(--Grid-borderWidth) solid",
        borderColor: "divider",
        "& > div": {
            borderRight: "var(--Grid-borderWidth) solid",
            borderBottom: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
        },
    },
    barcodeData: { p: 2 },
};

export default styles;
