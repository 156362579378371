import { Box, Checkbox, FormLabel } from "@mui/material";
import styles from "./SecureSyncCheckBox.styles";

const SecureSyncCheckBox = ({ label, name, checked, onChange, disabled = false, indeterminate, inputProps }) => {
    return (
        <Box sx={styles.checkBoxContainer}>
            <Checkbox
                name={name}
                checked={checked}
                onChange={onChange}
                sx={styles.primaryCheckBox}
                disabled={disabled}
                indeterminate={indeterminate}
                inputProps={inputProps}
            />
            <FormLabel sx={styles.formLabelSx}>{label}</FormLabel>
        </Box>
    );
};

export default SecureSyncCheckBox;
