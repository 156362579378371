import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClosedContainer from "./ClosedContainer/ClosedContainer";
import ExtraLocksAndClips from "./ExtraLocksAndClips/ExtraLocksAndClips";
import ExtraInsertsPurchase from "./ExtraInsertsPurchase/ExtraInsertsPurchase";
import styles from "./IssueClosedContainer.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";

const IssueClosedContainer = ({ singleRequestData }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.container}>
                <PageSubTitle>{t("requestDetails.issueClosedContainerDetails")}</PageSubTitle>
                <ClosedContainer closedContainerData={singleRequestData?.closedContainerData} />
                <Box sx={styles.multiTable}>
                    <ExtraInsertsPurchase extraInsertPurchaseData={singleRequestData?.filteredExtraInsertPurchase} />
                    <ExtraLocksAndClips extraLocksAndClipsData={singleRequestData?.filteredExtraLocksAndClips} />
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export default IssueClosedContainer;
