import { useState, useEffect, useMemo } from "react";
import { Box, Container, Grid, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./HolidayLists.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getAllowedCustomers } from "src/redux/userDetailsSlice";
import { getHolidayLists, getHolidayYears } from "src/services/adminService";
import { getSelectedCustomerDb } from "src/services/utils";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = () =>
    Yup.object().shape({
        district: Yup.string(),
        year: Yup.string(),
    });
const initValue = {
    district: "",
    year: new Date().getFullYear().toString(),
};

const HolidayLists = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.search,
        callback: () => {
            handleSubmit();
        },
    });
    const { t } = useTranslation();
    const customerLists = useSelector(getAllowedCustomers);
    const [holidayYearsList, setHolidayYearsList] = useState([]);
    const [holidayList, setHolidayList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { values, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValue,
        validationSchema: validationSchema(),
        onSubmit: async (values) => {
            await formSubmit();
        },
    });
    const formSubmit = async () => {
        setIsLoading(true);
        const holidayLists = await getHolidayLists(values.year);
        const holidayListsSorted = holidayLists?.rs0.sort((a, b) => a.holiday_id - b.holiday_id);
        setHolidayList(holidayListsSorted);
        setIsLoading(false);
    };
    const districtOptions = useMemo(() => {
        const districtOptions =
            customerLists &&
            customerLists
                .filter(
                    (value, index) =>
                        value.On_Hold === "N" &&
                        index === customerLists.findIndex((item) => item.district_id === value.district_id)
                )
                .map((item) => ({
                    id: item.district_id,
                    value: item.district_id,
                    label: item.district_name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
        return districtOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerLists]);

    const districtChange = (e) => {
        setHolidayList([]);
        setFieldValue("district", e.target.value);
    };

    const yearChange = (e) => {
        setHolidayList([]);
        setFieldValue("year", e.target.value);
    };

    useEffect(() => {
        setFieldValue("district", districtOptions && districtOptions.find((i) => i.id === getSelectedCustomerDb())?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtOptions]);

    const getHolidayYear = async () => {
        const holidayYears = await getHolidayYears();
        const holidayYearList = holidayYears?.rs0.map((item) => ({
            id: item.year_district_id,
            value: item.year,
            label: item.year,
        }));
        setHolidayYearsList(holidayYearList);
    };
    useEffect(() => {
        formSubmit();
        getHolidayYear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "holiday_name",
                headerName: t("holidayLists.ironMountainHolidaysHeading"),
                sortable: true,
            },
            {
                field: "holiday_date",
                headerName: values.year,
                sortable: true,
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holidayList]);
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.mainContainer}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle>{t("holidayLists.ironMountainHolidays")}</PageTitle>
                        <Grid sx={styles.contactFieldContainer} container spacing={2}>
                            <Grid item xs={3}>
                                <SecureSyncSelect
                                    name="district"
                                    label={t("common.district")}
                                    options={districtOptions || []}
                                    value={values.district}
                                    onChange={districtChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SecureSyncSelect
                                    name="year"
                                    label={t("common.year")}
                                    defaultOption={new Date().getFullYear().toString()}
                                    options={holidayYearsList}
                                    value={values.year}
                                    onChange={yearChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.search)}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t("common.find")}
                        </Button>
                    </Box>
                    <Box sx={styles.cardContent}>
                        {holidayList.length > 0 && (
                            <SecureSyncTable
                                rows={holidayList}
                                columns={columns}
                                getRowId={(row) => Number(row.holiday_id)}
                                noDataMessage={t("messages.noRecordsFound")}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default HolidayLists;
