const styles = {
    boxPaddingBottom: {
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(4, 285px)",
    },
    flexFormControl: { flex: 1 },
    actionSection: { paddingBottom: "20px", paddingTop: "20px" },
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
};

export default styles;
