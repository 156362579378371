import { createSlice } from "@reduxjs/toolkit";
import { fetchStatus } from "src/constants/fetchStatusConstants";

const initialState = {
    authStatus: {
        status: fetchStatus.notStarted,
        data: {
            isAuthenticated: false,
        },
    },
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthStatus: (state, action) => {
            state.authStatus.status = fetchStatus.completed;
            state.authStatus.data = {
                isAuthenticated: action.payload.isAuthenticated,
            };
        },
    },
    selectors: {
        getAuthStatus: (state) => state.authStatus,
    },
});

export const { setAuthStatus } = authSlice.actions;
export const { getAuthStatus } = authSlice.selectors;

export default authSlice.reducer;
