const ErrorOutlined = ({ fill, ...props }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="warning-2">
                <g id="Union">
                    <path
                        d="M9.99642 11.6065C9.62059 11.6065 9.30892 11.2948 9.30892 10.919V6.10648C9.30892 5.73064 9.62059 5.41898 9.99642 5.41898C10.3723 5.41898 10.6839 5.73064 10.6839 6.10648V10.919C10.6839 11.2948 10.3723 11.6065 9.99642 11.6065Z"
                        fill={fill}
                    />
                    <path
                        d="M9.99642 14.8148C9.74892 14.8148 9.51973 14.7231 9.34557 14.549C9.26307 14.4573 9.19893 14.3565 9.14393 14.2465C9.09809 14.1365 9.07975 14.0173 9.07975 13.8981C9.07975 13.6598 9.18057 13.4214 9.34557 13.2473C9.68473 12.9081 10.3081 12.9081 10.6473 13.2473C10.8123 13.4214 10.9131 13.6598 10.9131 13.8981C10.9131 14.0173 10.8856 14.1365 10.8397 14.2465C10.7939 14.3565 10.7298 14.4573 10.6473 14.549C10.4731 14.7231 10.2439 14.8148 9.99642 14.8148Z"
                        fill={fill}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.20908 19.3798C8.75908 19.7006 9.38242 19.8565 9.99659 19.8565C10.6108 19.8565 11.2341 19.7006 11.7841 19.3798L17.2291 16.2356C18.3291 15.5939 19.0166 14.4114 19.0166 13.1373V6.8673C19.0166 5.59314 18.3291 4.41063 17.2291 3.76896L11.7841 0.624805C10.6749 -0.016862 9.30908 -0.016862 8.20908 0.624805L2.76407 3.76896C1.66407 4.41063 0.976562 5.59314 0.976562 6.8673V13.1373C0.976562 14.4114 1.66407 15.5939 2.76407 16.2356L8.20908 19.3798ZM8.89658 1.81646C9.23574 1.62396 9.62075 1.52312 9.99659 1.52312C10.3724 1.52312 10.7574 1.62396 11.0874 1.81646L16.5324 4.96062C17.2108 5.35479 17.6324 6.08814 17.6324 6.8673V13.1373C17.6324 13.9256 17.2108 14.6498 16.5324 15.044L11.0874 18.1881C10.4183 18.5823 9.57491 18.5823 8.89658 18.1881L3.45157 15.044C2.77324 14.6498 2.35156 13.9164 2.35156 13.1373V6.8673C2.35156 6.07897 2.77324 5.35479 3.45157 4.96062L8.89658 1.81646Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};

export default ErrorOutlined;
