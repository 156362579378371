const styles = {
    formControl: {
        width: "50%",
    },
    formControlCustomer: {
        width: "50%",
    },
    formControlDropdown: {
        width: "25%",
    },
    toggleContainer: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.4px",
        marginLeft: "47px",
    },
    editPreferenceBody1: {
        marginBottom: "10px",
        marginTop: "10px",
    },
    editPreferenceBody2: {
        marginBottom: "10px",
    },
    switchLabelContainer: {
        paddingTop: "15px",
        paddingBottom: "20px",
    },
    contactFieldContainer: {
        paddingBottom: "20px",
    },
    buttonContainer: {
        padding: "30px 0 20px 0",
        display: "flex",
        gap: 2,
    },
    businessEmailControl: {
        width: "50%",
        flex: "1",
        marginTop: "20px",
    },
    dividerSpacing: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    switchLabelContainer2: {
        paddingTop: "1px",
        paddingBottom: "15px",
    },
    contactFieldContainer2: {
        paddingBottom: "10px",
    },
};

export default styles;
