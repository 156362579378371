import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ClosedContainer.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { sortHelper } from "src/utils/sortHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const ClosedContainer = ({ closedContainerData }) => {
    const { t } = useTranslation();
    const noDataMsg = t("messages.noClosedContainers");
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "logical_vault_code", headerName: convertToLocaleUpperCase(t("tableHeader.vaultID")) },
            { field: "descr", headerName: convertToLocaleUpperCase(t("tableHeader.containerType")) },
            {
                field: "inserts",
                headerName: convertToLocaleUpperCase(t("tableHeader.insertConfiguration")),
                renderCell: (params) => {
                    const splitText = params?.inserts?.split(/\r|\f|\t/g);
                    return (
                        <div>
                            {splitText?.map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </div>
                    );
                },
            },
            { field: "quantity", headerName: convertToLocaleUpperCase(t("tableHeader.containerQuantity")) },
            {
                field: "locks",
                headerName: `${convertToLocaleUpperCase(t("tableHeader.locks"))}?`,
                renderCell: (params) => {
                    if (params?.lock_type_code === null) {
                        return <span></span>;
                    } else if (params?.lock_type_code === "") {
                        return <span>{t("common.no")}</span>;
                    } else {
                        return <span>{t("common.yes")}</span>;
                    }
                },
            },
            { field: "lock_type_code", headerName: convertToLocaleUpperCase(t("tableHeader.lockType")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    closedContainerData = closedContainerData.filter((data) => data.logical_vault_code);
    const sortedClosedContainerData = sortHelper({
        data: closedContainerData,
        primaryProperty: "logical_vault_code",
        secondaryProperty: "descr",
        sortOrder: "asc",
    });
    return (
        <>
            <Box>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        <Typography variant="h6" sx={styles.heading}>
                            {t("requestDetails.closedContainerDetails")}
                        </Typography>
                        {closedContainerData.length > 0 && (
                            <Box>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: closedContainerData.length })}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable rows={sortedClosedContainerData} columns={columns} noDataMessage={noDataMsg} />
                    </Box>
                </SecureSyncTableCard>
            </Box>
        </>
    );
};

export default ClosedContainer;
