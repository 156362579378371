const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    requestId: {
        fontSize: "26px",
        fontWeight: 400,
        letterSpacing: "0.13px",
        color: "im_dark_blue.500",
    },
    requestDetailsDesc: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    reqDetailsContainer: { paddingTop: "20px" },
    reqDetailRow: { display: "flex", flexDirection: "row", paddingBottom: "20px" },
    reqDetailColumn: { display: "flex", flexDirection: "column", flex: 1 },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "32px",
        paddingBottom: "20px",
    },
    requestDetailText: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "12px" /* 100% */,
        letterSpacing: "0.15px",
        paddingBottom: "7.5px",
    },
    requestDetailValue: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: "0.17px",
    },
    rowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
        gap: "20px", // Adjust the gap between fields
        paddingBottom: "20px",
    },
};

export default styles;
