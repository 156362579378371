import { t } from "i18next";
import { formatDate } from "./dateHelper";
import { mediaLocatorView, mediaStatusId } from "src/constants/researchConstants";

export const getTruncatedDescription = (fullDescription) => {
    let truncatedDesc = "";
    if (fullDescription.length <= 50) {
        truncatedDesc = fullDescription;
    } else {
        const tempArr = fullDescription.split(" ");
        if (tempArr.length === 1) {
            truncatedDesc = fullDescription;
        } else {
            for (const word of tempArr) {
                const newStr = truncatedDesc.concat(word).concat(" ");
                if (newStr.length > 50) {
                    break;
                }
                truncatedDesc = newStr;
            }
            truncatedDesc = truncatedDesc.trim().concat("...");
        }
    }

    return truncatedDesc;
};

export const getCurrentStatus = (params) => {
    if (params.Media_Locator_View === mediaLocatorView.openMedia) {
        if (params.Status_Id === mediaStatusId.statusId70) {
            return t("common.inTransit");
        } else if (params.Status_Id === mediaStatusId.statusId80 || params.Status_Id === mediaStatusId.statusId90) {
            return params.Summary_Type;
        } else if (!params.Return_Date) {
            return t("common.indefinite");
        } else {
            return formatDate(params.Return_Date, undefined, true);
        }
    } else {
        if (params.Status_Id === mediaStatusId.statusId15) {
            if (!params.Deleted_Date) {
                return params.Summary_Type;
            } else {
                return `${params.Summary_Type} -  ${formatDate(params.Deleted_Date, undefined, true)}`;
            }
        } else if (params.Status_Id === mediaStatusId.statusId9 || params.Status_Id === mediaStatusId.statusId11) {
            return t("common.inTransit");
        } else if (params.Status_Id === mediaStatusId.statusId10) {
            return params.Summary_Type;
        } else if (!params.Return_Date) {
            return t("common.indefinite");
        } else {
            return formatDate(params.Return_Date, undefined, true);
        }
    }
};
