import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMatch, useParams } from "react-router-dom";
import styles from "./MediaDestructionRequestDetail.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { getBasicUserInfo, getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { getMediaDestructionRequestDetailsService, updateDestructionRequestStatus } from "src/services/requestServices";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { programIds } from "src/constants/programIdsConstants";
import { formatDate } from "src/utils/dateHelper";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import MediaDestructionInformation from "src/components/Request/MediaDestructionDetails/MediaDestructionInformation/MediaDestructionInformation";
import MediaRequestedDestructionTable from "src/components/Request/MediaDestructionDetails/MediaRequestedDestructionTable/MediaRequestedDestructionTable";
import MediaRequestedDestructionDetails from "src/components/Request/MediaDestructionDetails/MediaRequestedDestructionDetails/MediaRequestedDestructionDetails";
import { getApiErrorMessage } from "src/services/utils";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { authorizeReject, destructItemStatusId, destructRequestStatusIds } from "src/constants/requestConstants";
import {
    searchMediaDestructionDetails,
    searchMediaDestructionRequest,
} from "src/constants/routeConstants/requestRouterFullPaths";
import { useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getMediaDestructionRequestPayload } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import MediaDestructionSuccessModal from "src/components/Request/MediaDestructionDetails/MediaDestructionSuccessModal/MediaDestructionSuccessModal";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const statusMessage = {
    show: false,
    status: "",
    message: " ",
};

const MediaDestructionRequestDetail = () => {
    const shortCutKeys = getShortCutKeys();
    const { destructId } = useParams();
    const { t } = useTranslation();
    const [responseMessage, setResponseMessage] = useState(statusMessage);
    const [destructionMediaData, setDestructionMediaData] = useState({
        mediaDestructionDetail1: [],
        mediaDestructionDetail2: [],
        mediaDestructionDetail3: [],
        error: [],
    });
    const [isLoading, setLoading] = useState(false);
    const userDetails = useSelector(getBasicUserInfo);
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);
    const [isAuthorizeRejectPossible, setIsAuthorizeRejectPossible] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const { navigate, goBack } = useSecureSyncNavigate();
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const [description, setDescription] = useState("");
    const [pageTitle, setPageTitle] = useState("");

    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setIsDataLoaded(false);
            const request = { destructRequestId: destructId, lastModUser: userDetails?.username };
            const { mediaDestructionDetail1, mediaDestructionDetail2, mediaDestructionDetail3, error } =
                await getMediaDestructionRequestDetailsService(request);
            if (error[0]?.hasError) {
                setResponseMessage({
                    show: true,
                    status: "error",
                    message: error[0].displayMessage,
                });
            } else {
                const isOwnUser = mediaDestructionDetail1[0]?.requested_personnel_id !== userDetails?.sys_user_id;
                const hasPermission = isUserProgramLevelAvailable(
                    programIdsForCurrentCustomer,
                    programIds.programId_293
                );
                const isConfirmationPending =
                    mediaDestructionDetail1[0]?.destruct_request_status_id ===
                    destructRequestStatusIds.pendingConfirmation;
                setIsAuthorizeRejectPossible(isOwnUser && hasPermission && isConfirmationPending);
                if (
                    mediaDestructionDetail1[0]?.destruct_request_type_id ===
                    destructRequestStatusIds.pendingConfirmation
                ) {
                    const updatedData = mediaDestructionDetail2?.map((data) => {
                        if (data?.destruct_item_status_id === destructItemStatusId.notEligibleDelivered) {
                            const localizedDate = formatDate(data?.delivered_datetime);
                            // eslint-disable-next-line camelcase
                            return { ...data, destruct_item_status_descr: localizedDate };
                        }
                        return data;
                    });
                    setDestructionMediaData({
                        mediaDestructionDetail1: mediaDestructionDetail1,
                        mediaDestructionDetail2: updatedData,
                        mediaDestructionDetail3: mediaDestructionDetail3,
                        error: error,
                    });
                } else {
                    setDestructionMediaData({
                        mediaDestructionDetail1: mediaDestructionDetail1,
                        mediaDestructionDetail2: mediaDestructionDetail2,
                        mediaDestructionDetail3: mediaDestructionDetail3,
                        error: error,
                    });
                }
            }
            setLoading(false);
            setIsDataLoaded(true);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAuthorizeReject = async (actionCode) => {
        const modalDescription = t("messages.destructRequestUpdatedSuccessfully", { destructReqID: destructId });
        if (actionCode === authorizeReject.authorize) {
            setLoading(true);
            const payload = {
                destructId: destructId,
                actionCode: actionCode,
                personnelId: userDetails?.sys_user_id,
                destructReqTimestamp: destructionMediaData?.mediaDestructionDetail1[0]?.destruct_req_timestamp,
                lastModUser: userDetails?.username,
            };
            const authorizeRequest = await updateDestructionRequestStatus(payload);
            if (authorizeRequest.requestUpdate[0]?.hasError) {
                setResponseMessage({
                    show: true,
                    status: "error",
                    message:
                        authorizeRequest.requestUpdate[0]?.error === "63094"
                            ? getApiErrorMessage("63094")
                            : getApiErrorMessage("63992"),
                });
                setLoading(false);
            } else {
                setSuccessModalObj({
                    show: true,
                    description: modalDescription,
                });
                setLoading(false);
            }
        } else {
            setLoading(true);
            const payload = {
                destructId: destructId,
                actionCode: actionCode,
                personnelId: userDetails?.sys_user_id,
                destructReqTimestamp: destructionMediaData?.mediaDestructionDetail1[0]?.destruct_req_timestamp,
                lastModUser: userDetails?.username,
            };
            const rejectRequest = await updateDestructionRequestStatus(payload);
            if (rejectRequest.requestUpdate[0]?.hasError) {
                setResponseMessage({
                    show: true,
                    status: "error",
                    message:
                        rejectRequest.requestUpdate[0]?.error === "63094"
                            ? getApiErrorMessage("63094")
                            : getApiErrorMessage("63992"),
                });
                setLoading(false);
            } else {
                setSuccessModalObj({
                    show: true,
                    description: modalDescription,
                });
                setLoading(false);
            }
        }
    };

    const fromMediaDestructionRequest = useMatch(searchMediaDestructionDetails);
    const onClickBack = () => {
        if (fromMediaDestructionRequest) {
            goBack(searchMediaDestructionRequest);
        } else {
            navigate(dashboard);
        }
    };

    const printReport = () => {
        setPrintPayload(
            getMediaDestructionRequestPayload({
                destructRequestId: destructId,
                pageTitle: pageTitle,
                description: t("mediaDestructionRequestDetail.mediaDestructionRequestInProcess"),
            })
        );
        setIsPrintModalOpen(true);
    };

    const [successModalObj, setSuccessModalObj] = useState({
        show: false,
        description: "",
    });

    const onSuccessModalClose = () => {
        setSuccessModalObj({ show: false, description: "" });
        onClickBack();
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            {isDataLoaded && (
                <>
                    <Box sx={styles.container}>
                        <PageTitle>{t("titles.mediaDestructionRequestDetail")}</PageTitle>
                        <Typography sx={styles.requestId}>
                            {t("mediaDestructionRequestDetail.destructionRequestID")} {destructId}
                        </Typography>
                    </Box>
                    {responseMessage.show && responseMessage.status === "error" && (
                        <SecureSyncAlert
                            severity={"error"}
                            message={responseMessage.message}
                            onClose={() => setResponseMessage(statusMessage)}
                        />
                    )}
                    <SecureSyncSnackBar
                        openSnackBar={responseMessage.show && responseMessage.status === "success"}
                        message={responseMessage.message}
                        severity="success"
                        onClose={() => setResponseMessage(statusMessage)}
                    />
                    <MediaDestructionInformation
                        mediaDestructionDetails={destructionMediaData.mediaDestructionDetail1}
                    />
                    <MediaRequestedDestructionTable
                        mediaDestructionDetails1={destructionMediaData.mediaDestructionDetail1}
                        mediaDestructionDetails2={destructionMediaData.mediaDestructionDetail2}
                    />
                    <MediaRequestedDestructionDetails
                        mediaDestructionDetails={destructionMediaData.mediaDestructionDetail1}
                        isAuthorizeRejectPossible={isAuthorizeRejectPossible}
                        pageTitle={pageTitle}
                        setPageTitle={setPageTitle}
                        description={description}
                        setDescription={setDescription}
                    />
                    <Box sx={styles.buttonContainer}>
                        <Button
                            id="back"
                            variant="outlined"
                            color="primary"
                            onClick={onClickBack}
                            startIcon={<IconLeft />}
                        >
                            {t("buttonLabels.back")}
                        </Button>
                        {isAuthorizeRejectPossible && (
                            <>
                                <Button
                                    id="authorize"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        handleAuthorizeReject(authorizeReject.authorize);
                                    }}
                                >
                                    {t("buttonLabels.authorize")}
                                </Button>
                                <Button
                                    id="reject"
                                    variant="outlined"
                                    onClick={() => {
                                        handleAuthorizeReject(authorizeReject.reject);
                                    }}
                                    color="primary"
                                >
                                    {t("buttonLabels.reject")}
                                </Button>
                            </>
                        )}
                        <Button
                            id="prepareReport"
                            variant="contained"
                            onClick={() => {
                                printReport();
                            }}
                            color="primary"
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        >
                            {t("buttonLabels.prepareReport")}
                        </Button>
                    </Box>
                </>
            )}
            <MediaDestructionSuccessModal
                values={successModalObj.description}
                open={successModalObj.show}
                onClose={onSuccessModalClose}
                printReport={printReport}
            />
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.mediaDestructionRequest}
            />
        </>
    );
};

export default MediaDestructionRequestDetail;
