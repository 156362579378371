/* eslint-disable react-hooks/exhaustive-deps */
import { Box, MenuItem, MenuList, Popper } from "@mui/material";
// import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProtectedMenu from "../../ProtectedMenu/ProtectedMenu";
import styles from "./RequestMenu.styles";
import { ReactComponent as ArrowDown } from "src/assets/images/arrow-down.svg";
import {
    deliverMedia,
    micsServices,
    newContainer,
    newTransport,
    other,
    requestHome,
    rescheduleService,
    searchMediaDestructionRequest,
} from "src/constants/routeConstants/requestRouterFullPaths";
import { useAnchorAndNavigation } from "src/customHooks";
import { getAccessRights } from "src/redux/userDetailsSlice";

function RequestMenu() {
    const { t } = useTranslation();
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    const {
        anchorEl: requestMenu,
        isOpen: isRequestMenuOpen,
        menuRef,
        openAnchor: openRequestMenu,
        // eslint-disable-next-line no-unused-vars
        closeAnchor: closeRequestMenu,
        handleBoxMouseLeave,
        handleMenuMouseLeave,
        handleMenuItemClick: handleRequestMenuClick,
    } = useAnchorAndNavigation();

    return (
        <div>
            <Box onMouseEnter={openRequestMenu} onMouseLeave={handleBoxMouseLeave} sx={styles.relativeDisplay}>
                <Button color="inherit" endIcon={<ArrowDown />} sx={styles.button.headerButton}>
                    {t("headerMenu.request")}
                </Button>
                <Popper
                    sx={{ zIndex: "appBar" }}
                    anchorEl={requestMenu}
                    open={isRequestMenuOpen}
                    placement="bottom-start"
                    className="menu-wrapper"
                    disablePortal={false}
                >
                    <MenuList
                        ref={menuRef}
                        onMouseLeave={handleMenuMouseLeave}
                        style={styles.menuList(isRequestMenuOpen)}
                    >
                        <ProtectedMenu hasAccess={pageAccess.searchRequestsDetail}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(requestHome);
                                }}
                            >
                                {t("headerMenu.searchRequests")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.searchMediaDestructionRequests}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(searchMediaDestructionRequest);
                                }}
                            >
                                {t("headerMenu.searchMediaDestructionRequests")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.deliverMedia}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(deliverMedia);
                                }}
                            >
                                {t("headerMenu.deliverMedia")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.newContainerLockInsert}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(newContainer);
                                }}
                            >
                                {t("headerMenu.newContainerLockInsert")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.newOrEmptyTransport}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(newTransport);
                                }}
                            >
                                {t("headerMenu.newOrEmptyTransport")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.other}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(other);
                                }}
                            >
                                {t("headerMenu.other")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.requestReschedule}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(rescheduleService);
                                }}
                            >
                                {t("headerMenu.rescheduleService")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.miscServices}>
                            <MenuItem
                                onClick={() => {
                                    handleRequestMenuClick(micsServices);
                                }}
                            >
                                {t("headerMenu.miscServices")}
                            </MenuItem>
                        </ProtectedMenu>
                    </MenuList>
                </Popper>
            </Box>
        </div>
    );
}

export default RequestMenu;
