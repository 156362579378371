import { Card, CardContent, Grid, Typography } from "@mui/material";

import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { muiTheme } from "../../styles/muiTheme";
import { fontrem } from "../../styles/mixins";

export const HealthCheck = () => {
    return (
        <Grid container justifyContent="center" sx={Styles.container}>
            <Grid item xs={6}>
                <Card elevation={0} sx={Styles.card} variant="outlined" color="primary">
                    <CardContent sx={Styles.cardContent}>
                        <SentimentSatisfiedAltIcon color="primary" fontSize="large" />
                        <br />
                        <Typography component="p" sx={Styles.textContent}>
                            SecureSync APP is up and running healthy!
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

const Styles = {
    container: {
        my: 8,
        [muiTheme.breakpoints.down("md")]: {
            my: 3,
        },
        textAlign: "center",
    },
    cardContent: {
        py: 5,
        px: 10,
        [muiTheme.breakpoints.down("md")]: {
            py: 2,
            px: 3,
        },
    },
    HomeBtn: {
        mt: 1,
    },
    card: {
        borderRadius: 3,
    },
    textContent: {
        ...fontrem(18),
        fontWeight: "bold",
    },
};
