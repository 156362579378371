const styles = {
    detailsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
    },
    confirmationModalFooter: {
        my: "20px",
        display: "flex",
        gap: "20px",
    },
    pageDesc: {
        mb: "20px",
    },
};

export default styles;
