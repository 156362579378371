/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Link, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { matchPath, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProtectedMenu from "../ProtectedMenu/ProtectedMenu";
import AdministrationMenu from "./AdministrationMenu/AdministrationMenu";
import CustomerListDropdown from "./CustomerListDropdown/CustomerListDropdown";
import styles from "./Header.styles";
import HelpMenu from "./HelpMenu/HelpMenu";
import OperationsMenu from "./OperationsMenu/OperationsMenu";
import ProcessMediaMenu from "./ProcessMediaMenu/ProcessMediaMenu";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import RequestMenu from "./RequestMenu/RequestMenu";
import {
    getAccessRights,
    getAllowedCustomers,
    getSelectedCustomer,
    getUserDetails,
    updateSelectedCustomer,
} from "src/redux/userDetailsSlice";
import { dashboard, resourcesPath } from "src/constants/routeConstants/rootRouterFullPaths";
import secureSync from "src/assets/images/secure_sync.png";
import { useAuthState, useShowMenuOptions } from "src/customHooks";

export const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthState();
    const user = useSelector(getUserDetails);
    const selectedUser = useSelector(getSelectedCustomer);
    const allowedCustomers = useSelector(getAllowedCustomers);
    const { data: accessRights } = useSelector(getAccessRights);
    const menuAccess = accessRights.menu || {};
    const dispatch = useDispatch();
    const showMenuOptions = useShowMenuOptions();
    const isResourcesPage = matchPath(resourcesPath, location.pathname);

    const changeCustomer = (event) => {
        const customerId = event?.target?.value ? event.target.value : null;
        dispatch(
            updateSelectedCustomer({
                selectedCustomer: allowedCustomers?.find((customer) => customer.customer_id === customerId),
            })
        );
        navigate(dashboard);
    };
    return (
        <>
            <AppBar position="static" sx={styles.appBar}>
                <Toolbar sx={styles.toolBar}>
                    <Box sx={styles.companyLogoContainer}>
                        <Link component={RouterLink} to={showMenuOptions ? dashboard : "#"}>
                            <img loading="lazy" src={secureSync} alt="Company Logo" style={styles.companyLogo} />
                        </Link>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {showMenuOptions && (
                            <>
                                <Button
                                    color="inherit"
                                    onClick={() => navigate(dashboard)}
                                    sx={styles.button.headerButton}
                                >
                                    {t("headerMenu.dashboard")}
                                </Button>
                                <ProtectedMenu hasAccess={menuAccess.processMedia} component={ProcessMediaMenu} />
                                <ProtectedMenu
                                    hasAccess={menuAccess.authorization || menuAccess.admin}
                                    component={AdministrationMenu}
                                />
                                <ProtectedMenu hasAccess={menuAccess.request} component={RequestMenu} />
                                <ProtectedMenu
                                    hasAccess={menuAccess.research || menuAccess.disasterRecovery}
                                    component={OperationsMenu}
                                />
                            </>
                        )}
                        {isAuthenticated && user?.email && (
                            <>
                                <HelpMenu isPostLoginScreen={!showMenuOptions} />
                                <ProfileMenu postLoginCheck={selectedUser} />
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            {showMenuOptions && !isResourcesPage && (
                <Box sx={styles.box.alignItemsCenter}>
                    <CustomerListDropdown
                        width="400px"
                        defaultOption={selectedUser?.customer_name || ""}
                        id="login-page"
                        name="account"
                        type="standard"
                        onChange={changeCustomer}
                        value={selectedUser?.customer_id || ""}
                    />
                </Box>
            )}
        </>
    );
};
