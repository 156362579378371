/* eslint-disable camelcase */
import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./IssuePermanentTransports.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const IssuePermanentTransports = ({ transportDetails }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "logical_vault_code", headerName: convertToLocaleUpperCase(t("tableHeader.vaultID")) },
            { field: "media_type_descr", headerName: convertToLocaleUpperCase(t("tableHeader.containerType")) },
            { field: "inserts", headerName: convertToLocaleUpperCase(t("tableHeader.insertConfiguration")) },
            { field: "quantity", headerName: convertToLocaleUpperCase(t("tableHeader.transportQuantity")) },
            {
                field: "locks",
                headerName: `${t("tableHeader.locks")}?`,
                renderCell: (params) => {
                    if (params?.lock_type_code === null) {
                        return <span></span>;
                    } else if (params?.lock_type_code === "") {
                        return <span>{t("common.no")}</span>;
                    } else {
                        return <span>{t("common.yes")}</span>;
                    }
                },
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box sx={styles.container}>
                <PageSubTitle>{t("requestDetails.issuePermanentTransports")}</PageSubTitle>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        <Typography variant="h6" sx={styles.heading}>
                            {t("requestDetails.newPermanentTransportInformation")}
                        </Typography>
                        {transportDetails.length > 0 && (
                            <Box>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: transportDetails.length })}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={transportDetails}
                            columns={columns}
                            noDataMessage={t("messages.noMatchingRecords")}
                        />
                    </Box>
                </SecureSyncTableCard>
            </Box>
            <Divider />
        </>
    );
};

export default IssuePermanentTransports;
