import { Box, Button, Card, CardContent, Container, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "src/pages/ProcessMedia/ContainerPickup/ContainerPickpup.styles";
import { filterData, getKeyBoardLabel, padEndStringWithSelectedCharacters } from "src/utils/commonHelper";
import { insertLoggedEvent, updateCloseContainers } from "src/services/processMediaService";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getSelectedCustomerId } from "src/services/utils";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { downloadInstructionGuide, sortContainerPickup } from "src/utils/processMediaHelper";
import { rejectReasonTroubleshoot, staticFile } from "src/constants/fileConstants";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getCloseContainerPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys, hotKeysScopes } from "src/constants/shortCutKeys";
export const ContainerContentsSummary = ({ containerDetails, onClose, onSubmit, containerString }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            if (validContainers.length !== 0) {
                onHandlerSubmit();
            }
        },
    });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.cancel, callback: () => onHandlerCancel() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.printFileDetails,
        callback: () => onHandlerPrintFile(),
        options: { scopes: hotKeysScopes.containerContentsSummary },
    });
    const { t } = useTranslation();
    const [containerList, setContainerList] = useState([]);
    const [containerMediaList, setContainerMediaList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);

    useEffect(() => {
        setContainerList(containerDetails?.containerListRes);
        setContainerMediaList(containerDetails?.mediaListRes);
    }, [containerDetails]);

    const rejectedTableColumns = [
        {
            field: "Container_Number",
            headerName: t("containerPickup.container").toUpperCase(),
            flex: 1,
            sortable: false,
        },
        { field: "Reject_Reason", headerName: t("containerPickup.reason").toUpperCase(), flex: 1, sortable: false },
    ];
    const validTableColumns = [
        {
            field: "reject",
            headerName: t("containerPickup.reject").toUpperCase(),
            width: 60,
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        rejectContainer(params);
                    }}
                >
                    <ClearIcon sx={styles.clearIcon} />
                </IconButton>
            ),
        },
        {
            field: "Container_Number",
            headerName: t("containerPickup.container").toUpperCase(),
            flex: 1,
            sortable: false,
        },
        { field: "Item_Count", headerName: t("containerPickup.items").toUpperCase(), flex: 1, sortable: false },
        { field: "First_Item", headerName: t("containerPickup.firstItem").toUpperCase(), flex: 1, sortable: false },
        { field: "Last_Item", headerName: t("containerPickup.lastItem").toUpperCase(), flex: 1, sortable: false },
    ];

    const validContainers = sortContainerPickup(filterData(containerList, "Reject_Flag", "N"));
    const rejectedContainer = sortContainerPickup(filterData(containerList, "Reject_Flag", "Y"));

    const rejectContainer = (value) => {
        const rejectContainer = { ...value };
        // eslint-disable-next-line camelcase
        rejectContainer.Reject_Flag = "Y";
        // eslint-disable-next-line camelcase
        rejectContainer.Reject_Reason = t("containerPickup.userRejected");
        const newData = containerList.filter((obj) => obj.Container_Number !== value.Container_Number);
        setContainerList([...newData, rejectContainer]);
    };

    const createCloseContainerContentList = () => {
        // Max lengths
        const maxContainerLength = 6;
        const maxVolserLength = 20;
        let containerListString = "";

        validContainers.forEach((container) => {
            const strCurrContainer = padEndStringWithSelectedCharacters(
                container.Container_Number,
                maxContainerLength,
                " "
            );
            const volser = containerMediaList.filter((v) => v.Container_Number === container.Container_Number);
            if (volser.length > 0) {
                volser.forEach((media) => {
                    const strCurrContainerItem = padEndStringWithSelectedCharacters(
                        media.Container_Item,
                        maxVolserLength,
                        " "
                    );

                    containerListString += strCurrContainer + strCurrContainerItem;
                });
            } else {
                containerListString += strCurrContainer + " ".repeat(maxVolserLength);
            }
        });

        return containerListString;
    };

    const onHandlerSubmit = async () => {
        setIsLoading(true);
        try {
            const containerListString = await createCloseContainerContentList();

            const closeContainerRes = await updateCloseContainers(getSelectedCustomerId(), containerListString);

            if (closeContainerRes?.failure) {
                console.error("Close Container - error code: ", closeContainerRes.errorCode);
            } else {
                const containerIds = validContainers.map((container) => container.Container_Number).join(",");
                const message = `Import Container Contents - Customer Id: ${getSelectedCustomerId()} Container Id: ${containerIds}`;
                await insertLoggedEvent(message, message);
                onSubmit(validContainers);
            }
        } catch (error) {
            console.error("Close Container - catch error: ", error);
        }
        setIsLoading(false);
    };

    const onHandlerCancel = () => {
        onClose();
    };

    const onHandlerPrintFile = () => {
        //TODO: need to get the api. Still developing
        setPrintPayload(
            getCloseContainerPayload({
                containerList: containerString,
            })
        );
        printModalOpen(true);
    };

    const rejectReasonDownloadFileHandler = () => {
        setIsLoading(true);
        downloadInstructionGuide(
            staticFile["troubleshootingGuideForContainerContents"],
            rejectReasonTroubleshoot,
            () => {
                setIsLoading(false);
            }
        );
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <Typography variant="h4" sx={styles.header}>
                            {t("containerPickup.containerContentsSummary")}
                        </Typography>
                        <br />
                        <Typography variant="body1" sx={styles.subheader}>
                            {t("containerPickup.click")}{" "}
                            <Box component={"span"} sx={styles.fontWaight700}>
                                '{t("containerPickup.printFileDetails")}'
                            </Box>{" "}
                            {t("containerPickup.toCreateAReportOfContainerContentsSummaryWithDetails")}{" "}
                            <Box component={"span"} sx={styles.fontWaight700}>
                                '{t("common.submit")}'
                            </Box>{" "}
                            {t("containerPickup.toSaveValidContainers")}
                        </Typography>
                        <Divider />
                        <br />
                        {rejectedContainer.length > 0 && (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="left"
                                    gap={2}
                                    sx={styles.marginBottomBreak}
                                >
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("containerPickup.rejectedContainers")}
                                    </Typography>
                                    <SecureSyncIconTooltip
                                        title={t("containerPickup.rejectedContainerTooltip")}
                                        placement="right"
                                        onClick={rejectReasonDownloadFileHandler}
                                    />
                                </Box>
                                <Card sx={styles.card}>
                                    <CardContent>
                                        <Box sx={styles.recordCount}>
                                            {rejectedContainer.length > 0 && (
                                                <Box sx={styles.statusRecord}>
                                                    <Typography variant="body1">
                                                        {t("messages.recordsFound", {
                                                            count: rejectedContainer.length,
                                                        })}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={styles.cardContent}>
                                            <SecureSyncTable
                                                rows={rejectedContainer}
                                                columns={rejectedTableColumns}
                                                getRowId={(row) => row.Container_Number}
                                                noDataMessage={t("messages.noAvailableRecords")}
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        {validContainers.length > 0 && (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="left"
                                    gap={2}
                                    sx={styles.marginBottomBreak}
                                >
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("containerPickup.validContainers")}
                                    </Typography>
                                </Box>
                                <Card sx={styles.card}>
                                    <CardContent>
                                        <Box sx={styles.recordCount}>
                                            {validContainers.length > 0 && (
                                                <Box sx={styles.statusRecord}>
                                                    <Typography variant="body1">
                                                        {t("messages.recordsFound", { count: validContainers.length })}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={styles.cardContent}>
                                            <SecureSyncTable
                                                rows={validContainers}
                                                columns={validTableColumns}
                                                getRowId={(row) => row.Container_Number}
                                                noDataMessage={t("messages.noAvailableRecords")}
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                        <Divider />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.printFileDetails)}
                                variant="outlined"
                                onClick={onHandlerPrintFile}
                            >
                                {t("buttonLabels.printFileDetails")}
                            </Button>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.cancel)}
                                variant="outlined"
                                onClick={onHandlerCancel}
                            >
                                {t("buttonLabels.cancel")}
                            </Button>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                                variant="contained"
                                disabled={validContainers.length === 0}
                                onClick={onHandlerSubmit}
                            >
                                {t("common.submit")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.closeContainer}
            />
        </>
    );
};
