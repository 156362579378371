import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styles from "./ContainerBasicDetails.styles";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { globalAttributeTypeId, noFlag, systemId, yesFlag } from "src/constants/serviceConstants";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { programIds } from "src/constants/programIdsConstants";
import { updateContainerDetails } from "src/services/researchService";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { sortHelper } from "src/utils/sortHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    retentionProfile: "",
    description: "",
};

const ContainerBasicDetails = ({ record, retentionProfile, setIsLoading, paramsContainerData, setErrorMessage }) => {
    const shortCutKeys = getShortCutKeys();
    const [params] = useSearchParams();
    const districtId = params.get("districtId");
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    const allowCustomerMediaIdentifiers = useMemo(() => {
        const foundGlobalAttribute = globalAttributes?.data?.rs0?.find(
            (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id96
        );
        return foundGlobalAttribute?.global_attribute_value === yesFlag;
    }, [globalAttributes]);

    const noProfiles = retentionProfile.length === 0;
    const { t } = useTranslation();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleReset() });
    const { handleBlur, handleChange, values, setValues, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: async (values) => {
            await onSubmit(values);
        },
    });

    const setInitialValues = useCallback(() => {
        setValues({
            retentionProfile: record?.Retention_Profile_Id === "" ? "no profile" : record?.Retention_Profile_Id,
            description: record?.Descr,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record?.Retention_Profile_Id, record?.Descr]);

    useEffect(() => {
        setInitialValues();
    }, [setInitialValues]);

    const retentionProfileOptions = useMemo(() => {
        const profileSet = [];
        if (retentionProfile.length > 0) {
            retentionProfile?.forEach((profile) => {
                profileSet.push({
                    id: parseInt(profile.retention_profile_id),
                    label: profile.profile_name ? profile.profile_name : "",
                    value: profile.retention_profile_id,
                });
            });
        } else {
            profileSet.push({
                id: 1,
                label: t("containerSearch.noProfile"),
                value: "no profile",
            });
        }
        return sortHelper({
            data: profileSet || [],
            primaryProperty: "id",
            sortOrder: "asc",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retentionProfile]);

    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);
    const havePermission = programIdsForCurrentCustomer?.some((el) => el === programIds.programId_216);
    const { currentStatusField, isEditable } = useMemo(() => {
        const isNotAtIronMtn = record?.Not_At_Iron_Mtn_Flag === yesFlag;
        const isDeleted = Boolean(record?.Deleted_Date);
        const currentStatusField = { label: t("formFields.returnDate"), value: "" };
        const isToBeIssued = record?.To_Be_Issued_Flag === yesFlag;
        const isAtIronMtn = record?.At_Iron_Mtn_Flag === yesFlag;
        const isContainer = record?.Container_Transport_Flag === "C";
        const isInDescrepancy = record?.In_Discrepancy_Flag === yesFlag;
        const isEditable =
            ((isAtIronMtn && !isToBeIssued) || isNotAtIronMtn) && havePermission && isContainer && !isInDescrepancy;
        let reportReturnDate = record?.Display_Return_Date;
        if (record?.At_Iron_Mtn_Flag === noFlag && record?.In_Transit_Flag === noFlag) {
            reportReturnDate = "";
        }
        if (isDeleted) {
            currentStatusField.label = t("formFields.deletedDate");
            currentStatusField.value = record?.Deleted_Date;
        } else {
            if (isNotAtIronMtn && record?.Pending_Return_Date_Local) {
                currentStatusField.label = t("formFields.deletedDate");
                currentStatusField.value = record?.Pending_Return_Date_Local;
            } else if (isNotAtIronMtn) {
                currentStatusField.value = "";
            } else if (reportReturnDate === t("formFields.indefinite")) {
                currentStatusField.value = t("formFields.indefinite");
            } else {
                currentStatusField.value = reportReturnDate;
            }
        }
        return {
            isDeleted,
            isNotAtIronMtn,
            currentStatusField,
            isEditable,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        havePermission,
        record?.At_Iron_Mtn_Flag,
        record?.Container_Transport_Flag,
        record?.Deleted_Date,
        record?.Display_Return_Date,
        record?.In_Discrepancy_Flag,
        record?.In_Transit_Flag,
        record?.Not_At_Iron_Mtn_Flag,
        record?.Pending_Return_Date_Local,
        record?.To_Be_Issued_Flag,
    ]);

    const onSubmit = async (values) => {
        setIsLoading(true);
        const updateResponse = await updateContainerDetails({
            containerId: record.Container_Id,
            newReturnDate: record.Return_Date,
            description: values?.description ? values?.description : "",
            timestamp: paramsContainerData?.timestamp,
            retentionProfileId: values.retentionProfile,
        });
        if (updateResponse.failure) {
            if (updateResponse.error === "10001" || updateResponse.error === "63008") {
                setErrorMessage({
                    severity: "error",
                    show: true,
                    msg:
                        record?.In_Transit_Flag === yesFlag
                            ? t("apiErrorMessages.63051", { buttonLabel: t("common.inTransit") })
                            : record?.To_Be_Issued_Flag === yesFlag
                              ? t("apiErrorMessages.63054")
                              : t(`apiErrorMessages.${updateResponse.error}`),
                });
            } else {
                setErrorMessage({
                    severity: "error",
                    show: true,
                    msg: t(`apiErrorMessages.${updateResponse.error}`),
                });
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (isEditable) {
            if (record?.part_of_dr_bit === "true") {
                setErrorMessage({
                    status: "error",
                    show: true,
                    message: t("messages.containerInvolvedInDisasterRecoveryEvent"),
                });
            }
        } else {
            let errorMsg = "";
            if (record?.part_of_dr_bit === "true") {
                errorMsg = t("apiErrorMessages.80101", { item: t("common.container") });
            }
            if (record?.In_Transit_Flag === yesFlag) {
                errorMsg = `${errorMsg} ${t("apiErrorMessages.63051")}`;
            } else if (record?.To_Be_Issued_Flag === yesFlag) {
                errorMsg = `${errorMsg} ${t("apiErrorMessages.63054")}`;
            }
            errorMsg !== ""
                ? setErrorMessage({
                      status: "error",
                      show: true,
                      message: errorMsg,
                  })
                : setErrorMessage({
                      status: "",
                      show: false,
                      message: "",
                  });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    useEffect(() => {
        const selectedRetentionProfile = retentionProfile.find(
            (item) => item?.retention_profile_id === values.retentionProfile
        );

        if (values?.retentionProfile <= 0) {
            setFieldValue("description", "");
        } else if (
            values?.retentionProfile > 0 &&
            selectedRetentionProfile?.overwrite_container_descr_flag === yesFlag
        ) {
            setFieldValue("description", selectedRetentionProfile?.media_descr);
        } else {
            setFieldValue("description", record?.Descr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.retentionProfile]);

    const handleReset = () => {
        resetForm();
        setInitialValues();
    };

    return (
        <Box>
            {isEditable && (
                <Typography sx={styles.pageDesc}>
                    <Trans i18nKey={"containerDetails.desc"} values={{ buttonLabel: t("buttonLabels.update") }} />
                </Typography>
            )}
            <form noValidate onSubmit={handleSubmit}>
                <Box sx={styles.detailsContainer}>
                    <SecureSyncReadOnlyTextField
                        label={`${t("common.containerNumber")} ${String.fromCharCode(185)}`}
                        value={record?.Number || " "}
                    />
                    <SecureSyncReadOnlyTextField
                        label={t("formFields.currentStatus")}
                        value={record?.Summary_Type || " "}
                    />
                    <SecureSyncReadOnlyTextField
                        label={t("formFields.mediaType")}
                        value={record?.Media_Type_Short_Descr || " "}
                    />
                    {allowCustomerMediaIdentifiers && (
                        <SecureSyncReadOnlyTextField
                            label={t("formFields.customerMediaIdentifier")}
                            value={record?.Customer_Media_Descr || " "}
                        />
                    )}
                    <SecureSyncReadOnlyTextField
                        label={currentStatusField.label}
                        value={currentStatusField.value || " "}
                    />
                    <SecureSyncReadOnlyTextField
                        label={t("formFields.issuedDate")}
                        value={record?.Issued_Date || " "}
                    />
                    <Box sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <SecureSyncSelect
                            showBlankOption={!noProfiles}
                            disabled={!isEditable}
                            label={t("formFields.retentionProfile")}
                            options={retentionProfileOptions}
                            name="retentionProfile"
                            value={values.retentionProfile}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ gridColumnStart: 1, gridColumnEnd: 5 }}>
                        <TextField
                            id="description"
                            name="description"
                            label={`${t("downloadInventory.descriptionPhrase")}  ${String.fromCharCode(178)}`}
                            variant="outlined"
                            multiline
                            fullWidth
                            disabled={!isEditable || districtId}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 1024 }}
                            helperText={
                                <Box sx={{ ml: "20px" }} component={"ol"}>
                                    <Box component={"li"}>{t("containerDetails.containersBeginningWithTR")}</Box>
                                    <Box component={"li"}>
                                        {t("containerDetails.IronMountainBranchesCannotSeeChangeTheDescription")}
                                    </Box>
                                </Box>
                            }
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            rows={4}
                        />
                        <Box />
                    </Box>
                </Box>
                <Box sx={styles.confirmationModalFooter}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleReset}
                        disabled={!isEditable || districtId}
                        title={getKeyBoardLabel(shortCutKeys.reset)}
                    >
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        disabled={!isEditable || districtId}
                    >
                        {t("buttonLabels.update")}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ContainerBasicDetails;
