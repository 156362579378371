import { Box, Container, Divider, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { changePinPath } from "../../../../constants/routeConstants/administrationRouterFullPaths";
import styles from "./UserDetails.styles";
import { getAuthInfoService, getPersonalInformationWrapper } from "src/services/authorizationService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import EditUserForm from "src/components/Administration/Authorization/EditUser/EditUserForm/EditUserForm";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { useLoadCountries } from "src/customHooks";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";

const UserDetails = () => {
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setLoading] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [personalData, setPersonalData] = useState(null);
    const [authList, setAuthList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgOpen, setMsgOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const urlParams = useParams();
    const [successPopup, setSuccessPopup] = useState({
        extraWarningMsg: "",
    });

    // eslint-disable-next-line no-unused-vars
    const { isLoading: isCountriesLoading } = useLoadCountries();

    useEffect(() => {
        getPersonInfo();
        getAuthLevel();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [urlParams]);

    const displayName = useMemo(() => {
        if (personalData) {
            // eslint-disable-next-line camelcase
            const { first_name = "", middle_name = "", last_name = "" } = personalData;
            // eslint-disable-next-line camelcase
            const dotMiddleName = middle_name ? `${middle_name}.` : "";
            // eslint-disable-next-line camelcase
            return `${last_name}, ${first_name} ${dotMiddleName} `;
        } else {
            return "";
        }
    }, [personalData]);

    const submitCallBack = (res) => {
        const msg = t("userDetails.editSuccess", { value: displayName ? displayName : "" });
        setSuccessPopup({
            extraWarningMsg: res.extraWarningMsg,
        });
        if (res.extraWarningMsg) {
            setMsgOpen(true);
        }
        // extraWarningMsg
        setMessage(msg);
        handleOpenSnackbar();
        getPersonInfo();
    };

    const authReset = (res) => {
        if (res.mailHasError) {
            setSuccessPopup({
                extraWarningMsg: t("messages.systemErrorMessage"),
            });
            setMsgOpen(true);
        } else {
            const msg = t("userDetails.authNumberResetMsg", { value: displayName ? displayName : "" });
            setMessage(msg);
            handleOpenSnackbar();
            getPersonInfo();
        }
    };

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const getPersonInfo = async () => {
        const personalId = urlParams.userId;
        setLoading(true);
        const res = await getPersonalInformationWrapper(personalId);
        setPersonalData({
            ...res?.personalData?.personalData,
            isSecureSyncUser: res?.personalData?.isSecureSyncUser === "Y" ? true : false,
        });
        setAuthList(res?.authInfo?.authList ? res.authInfo.authList : []);
        setLoading(false);
    };

    const getAuthLevel = async () => {
        const personalId = urlParams.userId;
        const authlevel = await getAuthInfoService(personalId);

        if (authlevel.hasAccess) {
            setHasPermission(true);
        } else {
            setHasPermission(false);
        }
    };

    const navigateToUserPin = () => {
        const personalId = urlParams.userId;
        const path = changePinPath(personalId);
        navigate(path);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />

            {hasPermission && (
                <Container>
                    {msgOpen && (
                        <SecureSyncAlert
                            message={successPopup.extraWarningMsg}
                            severity="warning"
                            onClose={() => {
                                setMsgOpen(false);
                            }}
                        />
                    )}
                    <Box sx={styles.mainContainer}>
                        <SecureSyncSnackBar
                            openSnackBar={snackbarOpen}
                            message={message}
                            severity={"success"}
                            onClose={handleCloseSnackbar}
                        />

                        <Box sx={styles.innerContainer}>
                            <Typography variant="h4" color="im_dark_blue.500" sx={styles.userDetailTitle}>
                                {t("userDetails.userDetails")}
                            </Typography>
                            <Typography variant="body1" color="im_grey.900" sx={styles.userDetailBody1}>
                                <Trans
                                    i18nKey={"userDetails.reviceData"}
                                    sx={styles.buttonSubmit}
                                    values={{ buttonLabel: t("buttonLabels.submit") }}
                                />
                            </Typography>
                            {personalData?.isSecureSyncUser && (
                                <Typography variant="body1" color="im_grey.900" sx={styles.userDetailBody1}>
                                    <Trans
                                        i18nKey={"userDetails.userIdenty"}
                                        values={{
                                            login: personalData?.login ? personalData.login : "",
                                        }}
                                    />
                                </Typography>
                            )}
                            <Divider />
                            <EditUserForm
                                authList={authList}
                                personalData={personalData}
                                openSuccessMsg={submitCallBack}
                                openSuccessMsgResetAuth={authReset}
                                navigateToUserPin={navigateToUserPin}
                                displayName={displayName}
                            />{" "}
                        </Box>
                    </Box>
                    <Divider />
                </Container>
            )}
        </>
    );
};

export default UserDetails;
