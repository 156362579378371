const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    textBox: {
        width: "100%",
        textAlign: "left",
    },
    title: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%", // 41.99px
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    bodyText: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        color: "im_grey.900",
    },
    dataGridContainer: {
        paddingBottom: "20px",
    },
    dataGrid: {
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            color: "black",
        },
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    button: {
        borderRadius: "8px",
        "&:hover": {
            borderRadius: "8px",
        },
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "72px",
    },
    recordStart: {
        justifyContent: "flex-start",
        padding: "16px",
    },
    columnHeader: {
        fontSize: "13px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.17px",
        color: "text.secondary",
        paddingX: "10px",
    },
    cardText: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
    },
};

export default styles;
