import { Box, Button, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styles } from "./HomeHeaderSection.styles";
import { notificationButtons } from "src/constants/dashboardConstants";

const NotificationButton = ({ onClick, children, selected, hasNotification }) => {
    const { palette } = useTheme();

    return (
        <Button
            startIcon={
                <NotificationsIcon sx={{ fill: hasNotification ? palette.im_orange[500] : palette.im_grey[100] }} />
            }
            variant={selected ? "contained" : "outlined"}
            onClick={onClick}
            sx={styles.button}
        >
            {children}
        </Button>
    );
};

const HomeHeaderSection = ({ selectedButton, handleButtonClick, todoCount, reportsCount }) => {
    const isTodoSelected = selectedButton === notificationButtons.todo;
    return (
        <>
            <Typography variant="h4" align="center" sx={styles.typography}>
                {t("homeModule.Notifications")}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: "30px", textTransform: "none" }}>
                <NotificationButton
                    onClick={() => handleButtonClick(notificationButtons.todo)}
                    selected={isTodoSelected}
                    hasNotification={todoCount !== 0}
                >
                    {t("homeModule.ToDo")}
                </NotificationButton>

                <NotificationButton
                    onClick={() => handleButtonClick(notificationButtons.reports)}
                    selected={!isTodoSelected}
                    hasNotification={reportsCount !== 0}
                >
                    {t("homeModule.RecentServiceReports")}
                </NotificationButton>
            </Box>
        </>
    );
};

HomeHeaderSection.propTypes = {
    handleButtonClick: PropTypes.func,
    selectedButton: PropTypes.string,
};

export default HomeHeaderSection;
