const styles = {
    tableContainer: {
        display: "grid",
        gridTemplateColumns: "175px auto",
        gap: "20px",
    },
    tableTitle: {
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "22px",
        textAlign: "left",
    },
    tableHeaderContainer: {
        padding: "10px",
    },
};

export default styles;
