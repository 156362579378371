const LockIcon = ({ fill, width = "20px", height = "20px" }) => {
    return (
        <svg style={{ width, height }} viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M118.997 72.3337H114.831V64.0003C114.831 52.5003 105.497 43.167 93.9974 43.167C82.4974 43.167 73.1641 52.5003 73.1641 64.0003V72.3337H68.9974C64.4141 72.3337 60.6641 76.0837 60.6641 80.667V122.334C60.6641 126.917 64.4141 130.667 68.9974 130.667H118.997C123.581 130.667 127.331 126.917 127.331 122.334V80.667C127.331 76.0837 123.581 72.3337 118.997 72.3337ZM93.9974 109.834C89.4141 109.834 85.6641 106.084 85.6641 101.5C85.6641 96.917 89.4141 93.167 93.9974 93.167C98.5807 93.167 102.331 96.917 102.331 101.5C102.331 106.084 98.5807 109.834 93.9974 109.834ZM106.914 72.3337H81.0807V64.0003C81.0807 56.8753 86.8724 51.0837 93.9974 51.0837C101.122 51.0837 106.914 56.8753 106.914 64.0003V72.3337Z"
                fill={fill}
            />
            <circle cx="94.5" cy="94.5" r="92" stroke={fill} strokeWidth="5" />
        </svg>
    );
};

export default LockIcon;
