import { generatePath } from "react-router-dom";
import { routePaths } from "./routeConstants";

const securePath = routePaths.root;

//Administration
export const administrationHome = `${securePath}/${routePaths.administration.home}`;
export const authorizationHome = `${administrationHome}/${routePaths.administration.authorization.home}`;

//Administration -> Authorization
export const searchUsers = authorizationHome;
export const addUser = `${authorizationHome}/${routePaths.administration.authorization.addUser}`;
export const editUser = `${authorizationHome}/${routePaths.administration.authorization.editUser}`;
export const editUserAccount = `${authorizationHome}/${routePaths.administration.authorization.editAccount}`;
export const comments = `${authorizationHome}/${routePaths.administration.authorization.comments}`;
export const userVerification = `${authorizationHome}/${routePaths.administration.authorization.userVerification}`;
export const adminPin = `${authorizationHome}/${routePaths.administration.authorization.editPin}`;

////Administration -> Admin
export const adminHome = `${administrationHome}/${routePaths.administration.admin.home}`;
export const adminMyInfo = `${adminHome}/${routePaths.administration.admin.myInfo}`;
export const editMyInfo = `${adminHome}/${routePaths.administration.admin.editInfo}`;
export const editMyAccountInfo = `${adminHome}/${routePaths.administration.admin.editMyAccountInfo}`;
export const editMyPreference = `${adminHome}/${routePaths.administration.admin.editPreference}`;
export const customerPreference = `${adminHome}/${routePaths.administration.admin.customerPreference}`;
export const holidayLists = `${adminHome}/${routePaths.administration.admin.holidayLists}`;
export const addRetentionProfile = `${adminHome}/${routePaths.administration.admin.addRetentionProfile}`;
export const retentionProfile = `${adminHome}/${routePaths.administration.admin.retentionProfile}`;

export const changePinPath = (userId) => {
    return generatePath(adminPin, { userId });
};

export const getUserPath = (userId) => {
    return generatePath(editUser, { userId });
};

export const getEditMyInfoPath = (userId) => {
    return generatePath(editMyInfo, { userId });
};

export const getUserAccountPath = (userId, accountId) => {
    return generatePath(editUserAccount, { userId, accountId });
};

export const getMyAccountPath = (userId, accountId) => {
    return generatePath(editMyAccountInfo, { userId, accountId });
};
