import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { avoidIsoLocaleCodes, defaultISOLocaleId } from "src/constants/dateFormatConstants";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { getSupportedCountries } from "src/redux/applicationSlice";
import { setDayJsLocaleIsoCode } from "src/redux/languageSlice";
import { getUserPreferences } from "src/redux/userDetailsSlice";

const useDateLocalize = () => {
    const dispatch = useDispatch();
    const { status: preferenceStatus, data: preferences } = useSelector(getUserPreferences);
    const { status: countryStatus, data: countryList } = useSelector(getSupportedCountries);

    useEffect(() => {
        const getIsoImport = async () => {
            const isoLocaleCode = getLocaleISOCode();
            dispatch(setDayJsLocaleIsoCode(isoLocaleCode));
            try {
                if (
                    getLocaleISOCode() === avoidIsoLocaleCodes.enCa ||
                    getLocaleISOCode() === avoidIsoLocaleCodes.frCa
                ) {
                    await import(`dayjs/locale/${defaultISOLocaleId.toLowerCase()}.js`);
                    dayjs.locale(isoLocaleCode);
                } else {
                    await import(`dayjs/locale/${isoLocaleCode.toLowerCase()}.js`);
                    dayjs.locale(isoLocaleCode);
                }
            } catch (error) {
                dispatch(setDayJsLocaleIsoCode("en"));
                dayjs.locale("en");
            }
        };
        getIsoImport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryList, preferences, preferenceStatus, countryStatus]);

    const getLocaleISOCode = () => {
        const userLocaleId =
            preferenceStatus === fetchStatus.completed ? preferences?.personnel[0]?.Personnel_Locale_Id : "";
        let isoLocaleCode = defaultISOLocaleId;
        if (preferenceStatus === fetchStatus.completed && countryStatus === fetchStatus.completed) {
            isoLocaleCode = countryList.find((localeData) => localeData.locale_id === userLocaleId).iso_locale_code;
        }
        return isoLocaleCode;
    };
    return { isLoading: preferenceStatus === fetchStatus.loading || countryStatus === fetchStatus.loading };
};

export default useDateLocalize;
