const styles = {
    confirmationModalFooter: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    formContainer: {
        width: "515px",
    },
    formItem: {
        padding: "14px 0px 14px 0px",
    },
    helpText: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0.4px",
        textAlign: "left",
    },
};

export default styles;
