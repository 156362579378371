import { Box, Button, Divider } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styles from "./OpenMediaExceptionDetails.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import OpenMediaExceptionDetailsTable from "src/components/Operations/Research/OpenMediaExceptionDetails/OpenMediaExceptionDetailsTable";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getOtherVaultExceptions } from "src/services/researchService";
import { startOfTheDate } from "src/utils/dateHelper";
import { useSecureSyncNavigate } from "src/customHooks";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { multipleSort } from "src/utils/processMediaHelper";
import { sortCriteriaResearch } from "src/constants/researchConstants";
import { getDetectedInTranslation } from "src/utils/researchHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";

import { getOpenMediaExceptionDetailReportPayload } from "src/services/printServices";
import { getAllowedCustomers } from "src/redux/userDetailsSlice";

const OpenMediaExceptionDetails = () => {
    const customerList = useSelector(getAllowedCustomers);

    const { t } = useTranslation();
    const [params] = useSearchParams();
    const { goBack } = useSecureSyncNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [exceptionList, setExceptionList] = useState([]);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const detectedInId = params.get("fileSectionTypeId");
    const detectedIn = useMemo(() => {
        let detectedIn = " ";
        if (params.get("fileSectionTypeId")) {
            detectedIn = getDetectedInTranslation(detectedInId, "fileSectionType");
        }
        return detectedIn;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detectedInId]);

    useEffect(() => {
        const fetchGetExceptionList = async () => {
            setIsLoading(true);
            const payload = {
                customerId: params.get("customerID"),
                districtId: params.get("districtId"),
                fileSectionTypeIdList: detectedInId,
                exceptionType: params.get("exceptionTypes"),
                fromDate: startOfTheDate(params.get("detectedDateFrom")),
                toDate: startOfTheDate(params.get("detectedDateTo")),
                operationalModeId: "3",
                logicalVaultCode: params.get("logicalVaultCode"),
            };
            const result = await getOtherVaultExceptions(payload);
            if (!result.failure) {
                const exceptions = result.openMediaExceptions.map((value) => {
                    return { ...value, sequenceId: parseInt(value.sequence_id) };
                });
                setExceptionList(multipleSort(exceptions, sortCriteriaResearch.openMediaExceptionDetails));
            }
            setIsLoading(false);
        };
        fetchGetExceptionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { customerName, customerNumber, customerId } = useMemo(() => {
        return {
            customerName: exceptionList.length > 0 ? exceptionList[0].customer_name : " ",
            customerNumber: exceptionList.length > 0 ? exceptionList[0].customer_number : " ",
            customerId: exceptionList.length > 0 ? exceptionList[0].customer_id : " ",
        };
    }, [exceptionList]);

    const printReport = () => {
        const selectedCustomer = customerList.find((customer) => customer.customer_id === customerId);
        setPrintPayload(
            getOpenMediaExceptionDetailReportPayload({
                districtName: selectedCustomer.district_name,
                customerNumber: customerNumber,
                districtId: selectedCustomer.district_id,
                customerName: customerName,
                customerId: customerId,
                detectedIn: detectedIn,
                fileSectionTypeId: detectedInId,
                exceptionType: params.get("exceptionTypes"),
                detectedDateFrom: startOfTheDate(params.get("detectedDateFrom")),
                detectedDateTo: startOfTheDate(params.get("detectedDateTo")),
                operationalModeId: "3",
                logicalVaultCode: params.get("logicalVaultCode") === null ? "" : params.get("logicalVaultCode"),
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.container}>
                <Box sx={styles.innerBox}>
                    <PageTitle>
                        <Trans i18nKey={"titles.openMediaExceptionDetail"} />
                    </PageTitle>
                    <Box sx={styles.statisticWrapper}>
                        <SecureSyncReadOnlyTextField value={customerNumber || " "} label={t("formFields.customer")} />
                        <SecureSyncReadOnlyTextField value={customerName || " "} label={t("formFields.name")} />
                        <SecureSyncReadOnlyTextField value={detectedIn || " "} label={t("formFields.detectedIn")} />
                        <SecureSyncReadOnlyTextField
                            value={params.get("exceptionTypes") || " "}
                            label={t("formFields.exceptionType")}
                        />
                    </Box>
                    <Box sx={styles.marginBottom}>
                        <OpenMediaExceptionDetailsTable resultData={exceptionList} detectedIn={detectedIn} />
                    </Box>
                    <Divider />
                    <Box sx={styles.buttonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<IconLeft />}
                            onClick={() => {
                                goBack(vaultStatusReport);
                            }}
                        >
                            {t("buttonLabels.back")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                printReport();
                            }}
                        >
                            {t("buttonLabels.print")}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.OpenMediaExceptionDetail}
            />
        </>
    );
};

export default OpenMediaExceptionDetails;
