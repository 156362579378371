import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./CancelConfirmation.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const CancelConfirmation = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title={t("cancelRequest.cancelRequest")}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {t("buttonLabels.confirm")}
                    </Button>
                    <Button autoFocus variant="outlined" onClick={onClose}>
                        {t("buttonLabels.cancel")}
                    </Button>
                </Box>
            }
        >
            {t("messages.requestDelete")}
        </SecureSyncModal>
    );
};

export default CancelConfirmation;
