/*
 * Custom theme for MUI
 */
import { createTheme } from "@mui/material";
import colorPalette from "./colorPalette";

export const muiTheme = createTheme({
    palette: colorPalette,
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === "contained" && {
                        height: "36px",
                        padding: "6px 16px 6px 16px",
                        backgroundColor: "var(--color-im-mid-blue)",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        letterSpacing: "0.4000000059604645px",
                        textAlign: "left",
                        "&:hover": {
                            borderRadius: "5px",
                            backgroundColor: "var(--color-im-mid-blue)",
                            boxShadow:
                                "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                        },
                        textTransform: "capitalize",
                    }),
                    ...(ownerState.variant === "outlined" && {
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        letterSpacing: "0.4000000059604645px",
                        textAlign: "left",
                        height: "36px",
                        padding: "6px 16px 6px 16px",
                        color: "var(--color-im-mid-blue)",
                        borderColor: "var(--color-im-dark-blue-200)",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "rgba(20, 71, 125, 0.04)",
                            borderRadius: "5px",
                            border: "1px solid var(--color-im-dark-blue-200)",
                        },
                        "&:disabled": {
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            letterSpacing: "0.4000000059604645px",
                            textAlign: "left",
                            borderColor: "var(--color-im-grey-50)",
                            color: "var(--color-im-grey-100)",
                        },
                        textTransform: "capitalize",
                    }),
                }),
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: "var(--color-im-mid-blue)",
                    },
                    "&:hover": {
                        backgroundColor: "var(--color-im-dark-blue-100)",
                    },
                    "&:focus": {
                        backgroundColor: "var(--color-im-mid-blue-100)",
                    },
                    "&.Mui-disabled": {
                        color: "var(--color-im-grey-100)",
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: "var(--color-im-mid-blue)",
                    },
                    "&:hover": {
                        backgroundColor: "var(--color-im-dark-blue-100)",
                    },
                    "&:focus": {
                        backgroundColor: "var(--color-im-mid-blue-100)",
                    },
                    "&.Mui-disabled": {
                        color: "var(--color-im-grey-100)",
                    },
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    fontSize: "13px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0.17000000178813934px",
                    textAlign: "left",
                    padding: "0 10px",
                    color: "rgba(0, 0, 0, 0.6) !important",
                    backgroundColor: "var(--color-white-100) !important",
                },
            },
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                icon: {
                    color: "var(--color-im-mid-blue)",
                    "&:hover": {
                        color: "var(--color-im-light-blue-100)",
                    },
                },
                root: {
                    color: "rgba(0, 0, 0, 0.6) !important",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "9px",
                    background: "var(--color-white)",
                    boxShadow:
                        "0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important",
                },
            },
        },
        MUIDataTableFilterList: {
            styleOverrides: {
                chip: {
                    margin: "8px 8px 8px 0px",
                },
            },
        },
        MUIDataTableFilter: {
            styleOverrides: {
                resetLink: {
                    fontSize: "14px",
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "var(--color-im-light-blue-100) !important",
                    },
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    padding: "5px 5px",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: "4px",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    color: "var(--color-im-grey-900)",
                    "&:hover": {
                        background: "var(--color-im-light-blue-100) !important",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderRadius: "8px",
                    borderColor: "var(--color-im-dark-blue-500)",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: "8px !important",
                    borderColor: "var(--color-im-dark-blue-500) ",
                },
                option: {
                    color: "var(--color-im-grey-900)",
                    "&:hover": {
                        backgroundColor: "var(--color-im-light-blue-100)",
                    },
                },
                noOptions: { color: "var(--color-im-grey-300)" },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "var(--color-im-light-blue-100)",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "var(--color-im-grey-400)",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    color: "rgba(0, 0, 0, 0.6) !important",
                },
            },
        },
        MuiTableFooter: {
            styleOverrides: {
                selectLabel: {
                    color: "rgba(0, 0, 0, 0.6) !important",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.severity === "error" && {
                        backgroundColor: "var(--color-im-error-fill)",
                        color: "var(--color-im-error-content)",
                        boxShadow: "none",
                    }),
                    ...(ownerState.severity === "warning" && {
                        backgroundColor: "var(--color-im-warning-fill)",
                        color: "var(--color-im-warning-content)",
                        boxShadow: "none",
                    }),
                    ...(ownerState.severity === "info" && {
                        backgroundColor: "var(--color-im-info-fill)",
                        color: "var(--color-im-info-content)",
                        boxShadow: "none",
                    }),
                    ...(ownerState.severity === "success" && {
                        backgroundColor: "var(--color-im-success-fill)",
                        color: "var(--color-im-success-content)",
                        boxShadow: "none",
                    }),
                }),
            },
        },
    },

    typography: {
        fontFamily: "Inter",
        h4: {
            fontSize: "34px",
            fontWeight: "400",
            lineHeight: "41.99px",
            letterSpacing: "0.25px",
            color: "var(--color-im-dark-blue-500)",
        },
        h5: {
            fontSize: "24px",
            fontWeight: "400",
            lineHeight: "32px",
            letterSpacing: "0px",
            textAlign: "left",
            color: "var(--color-black)",
        },
        h6: {
            color: "var(--color-im-dark-blue)",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "0.15px",
        },
        h7: {
            color: "var(--color-im-grey-900)",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "28px",
            letterSpacing: "0.15px",
        },
    },
});
