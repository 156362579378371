import { fontrem } from "src/styles/mixins";

const styles = {
    NavLinks: {
        display: "flex",
        alignItems: "center",
        "& a": {
            mr: 2,
            color: "text.primary",
        },
        "& .notificationIcon": {
            mr: 2,
        },
        "& .notificationIcon svg": {
            ...fontrem(32),
        },
    },
    avatarButtonStyle: {
        paddingLeft: "15px",
        color: "text.primary",
        display: "flex",
        alignItems: "center",
    },
    display: {
        xs: "none",
        sm: "block",
    },
    userPopup: {
        width: "100%",
    },
    popupEmail: {
        ...fontrem(14),
        color: "#bdc1c6",
    },
    popupUserName: {
        ...fontrem(16),
    },
    infoTooltip: {
        div: {},
        lineHeight: 1.2,
    },
    Avatar: {
        backgroundColor: "#9ADCFF",
    },
};

export default styles;
