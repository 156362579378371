/* eslint-disable max-lines */
import { Box, Button, Divider, Link, Switch, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useHotkeysContext } from "react-hotkeys-hook";
import styles from "./ContainerInformation.styles";
import CustomMediaIdentifierModal from "./CustomMediaIdentifierModal/CustomMediaIdentifierModal";
import RequestedContainer from "./RequestedContainers/RequestedContainer";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { groupByInsertTypeId } from "src/utils/newContainerLockInsertHelper";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { openFileFromFB } from "src/services/utils";
import { staticFile } from "src/constants/fileConstants";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { multipleSort } from "src/utils/processMediaHelper";
import { requestSortCriteria } from "src/constants/requestConstants";
import { updatedDropdownData } from "src/utils/requestHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { hotKeysScopes } from "src/constants/shortCutKeys";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    vaultId: "",
    containerTypeId: "",
    containerQuantity: "",
    isLocks: true,
    lockTypeId: "",
    insertTypeIds: [],
};

const containerQtyRange = {
    min: 1,
    max: 150,
};

const useShortCutKeys = (handleSubmit, handleFormReset) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.add,
        callback: handleSubmit,
        options: { scopes: hotKeysScopes.newContainerLockInsert },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.clear,
        callback: handleFormReset,
        options: { scopes: hotKeysScopes.newContainerLockInsert },
    });
    const { enableScope } = useHotkeysContext();
    useEffect(() => {
        enableScope(hotKeysScopes.newContainerLockInsert);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const validationSchema = (t) => {
    const containerQtyErrorMessage = t("messages.containerQtyRange", {
        min: containerQtyRange.min,
        max: containerQtyRange.max,
    });
    return Yup.object().shape({
        vaultId: Yup.string().required(t("messages.vaultIdRequired")),
        containerTypeId: Yup.string().required(t("messages.containerTypeRequired")),
        containerQuantity: Yup.number()
            .typeError(containerQtyErrorMessage)
            .required(t("messages.containerQtyRequired"))
            .integer(containerQtyErrorMessage)
            .min(containerQtyRange.min, containerQtyErrorMessage)
            .max(containerQtyRange.max, containerQtyErrorMessage),
        isLocks: Yup.boolean().required(),
        insertTypeIds: Yup.array().of(Yup.string().required(t("messages.insertConfigRequired"))),
    });
};
const ContainerInformation = ({
    lockTypes,
    insertTypes,
    vaultIds,
    containerTypes,
    globalAttr,
    setInfoMessage,
    requestedContainers,
    onFormChange,
    setRequestedContainers,
    savedCMILabels,
    defaultLock,
    onClear,
}) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const [showCMIModal, setShowCMIModal] = useState(false);
    const [cmiLabels, setCMILabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [configs, setConfigs] = useState([]);
    useShortCutKeys(
        () => handleSubmit(),
        () => {
            handleResetForm();
        }
    );
    const lockTypeOptions = useMemo(() => {
        if (!lockTypes) {
            return [];
        }
        const sortedLockType = multipleSort(updatedDropdownData(lockTypes), requestSortCriteria.comboBoxSort);
        return sortedLockType.map(({ lock_type_code: lockTypeCode }) => {
            return {
                id: lockTypeCode.trim(),
                label: lockTypeCode.trim(),
                value: lockTypeCode.trim(),
            };
        });
    }, [lockTypes]);

    const insertTypesOptions = useMemo(() => {
        if (!insertTypes) {
            return [];
        }
        const sortedInsertTypes = multipleSort(updatedDropdownData(insertTypes), requestSortCriteria.comboBoxSort);
        return sortedInsertTypes.map(({ insert_type_id: typeId, descr: desc }) => {
            return { id: typeId.toString(), value: typeId.toString(), label: desc };
        });
    }, [insertTypes]);

    const vaultOptions = useMemo(() => {
        if (!vaultIds) {
            return [];
        }
        const sortedVault = multipleSort(updatedDropdownData(vaultIds), requestSortCriteria.comboBoxSort);
        return sortedVault.map(({ code_value: vaultCode, id }) => {
            return { id: id, value: id, label: vaultCode };
        });
    }, [vaultIds]);

    const containerTypeOptions = useMemo(() => {
        if (!containerTypes) {
            return [];
        }
        const sortedContainerType = multipleSort(updatedDropdownData(containerTypes), requestSortCriteria.comboBoxSort);
        return sortedContainerType.map(({ code_value: containerType, id }) => {
            return { id: id.toString(), value: id.toString(), label: containerType };
        });
    }, [containerTypes]);

    const existingCMIList = useMemo(() => {
        return requestedContainers.reduce((prevList, container) => {
            const validCMIItems = container.cmiList.filter((item) => item);
            return [...prevList, ...validCMIItems];
        }, []);
    }, [requestedContainers]);

    const {
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        resetForm,
        setFieldTouched,
    } = useFormik({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            await formSubmit(values);
        },
    });

    const maxInsertsAllowed = useMemo(() => {
        const selectedContainerType = containerTypes?.find(
            (containerType) => containerType.id === values.containerTypeId
        );
        return selectedContainerType ? parseInt(selectedContainerType.inserts_required) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.containerTypeId]);

    const getInitialFormValues = useCallback(() => {
        return {
            ...initialValues,
            vaultId: vaultOptions[0]?.value,
            lockTypeId: defaultLock?.[0]?.id?.trim(),
        };
    }, [defaultLock, vaultOptions]);

    useEffect(() => {
        resetForm({ values: getInitialFormValues() });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInitialFormValues]);

    useEffect(() => {
        onFormChange(dirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    const formSubmit = async (values) => {
        let newCMIList = [...cmiLabels];
        const validCMIItems = newCMIList.filter((item) => item);
        if (values.containerQuantity < validCMIItems.length) {
            setFieldValue("containerQuantity", cmiLabels.length);
            setInfoMessage(t("newContainerLockInsert.cmiLabelsErrorMsg", { cmiLabelsLength: cmiLabels.length }));
            return;
        } else if (values.containerQuantity >= validCMIItems.length) {
            newCMIList = [...validCMIItems, ...Array(values.containerQuantity - validCMIItems.length)];
            setInfoMessage("");
        }

        const vaultLabel = vaultOptions.find((vaultOption) => vaultOption.value === values.vaultId).label;
        const containerTypeLabel = containerTypeOptions.find(
            (containerTypeOption) => containerTypeOption.value === values.containerTypeId
        ).label;

        let lockTypeLabel = "";
        if (values.isLocks) {
            lockTypeLabel = lockTypeOptions.find((lockTypeOption) => lockTypeOption.value === values.lockTypeId)?.label;
        }

        const insertTypeList = groupByInsertTypeId(values.insertTypeIds, insertTypesOptions);

        const newContainer = {
            ...values,
            vaultLabel,
            containerTypeLabel,
            lockTypeLabel,
            insertTypeList,
            cmiList: newCMIList,
            id: uuidv4(),
        };
        const newRequestedContainers = [...requestedContainers];
        newRequestedContainers.push(newContainer);
        setRequestedContainers(newRequestedContainers);
        handleResetForm();
        setConfigs([]);
    };

    const handleResetForm = () => {
        setCMILabels([]);
        resetForm({
            values: getInitialFormValues(),
        });
        setConfigs([]);
        onClear();
    };

    useEffect(() => {
        const setInsertValues = async () => {
            let updatedArray = [];
            if (configs.length < maxInsertsAllowed) {
                updatedArray = [...configs];
                while (updatedArray.length < maxInsertsAllowed) {
                    updatedArray.push(configs[0] || "");
                }
                setConfigs(updatedArray);
                await setFieldValue("insertTypeIds", updatedArray);
            } else {
                if (maxInsertsAllowed > 0) {
                    updatedArray = [...configs];
                    updatedArray = updatedArray.slice(0, maxInsertsAllowed);
                }
            }
            await setFieldValue("insertTypeIds", updatedArray);
            setFieldTouched("insertTypeIds", []);
        };

        setInsertValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxInsertsAllowed]);

    const onClickDelete = (id) => {
        const newRequestedContainers = requestedContainers.filter((container) => container.id !== id);
        setRequestedContainers(newRequestedContainers);
    };

    const onInsertConfigChange = (event, index) => {
        const isAnyOneHasValue = values.insertTypeIds.find((typeId) => typeId);
        if (!isAnyOneHasValue) {
            const insertTypeIds = [...Array(maxInsertsAllowed).keys()].map((key) => {
                return event.target.value;
            });

            setConfigs(insertTypeIds);
            setFieldValue("insertTypeIds", insertTypeIds);
        } else {
            const configArray = [...configs];
            configArray[index] = event.target.value;
            setConfigs(configArray);
            handleChange(event);
        }
    };

    const onCMIClick = () => {
        const validCMIItems = cmiLabels.filter((item) => item);
        if (values.containerQuantity < validCMIItems.length) {
            setInfoMessage(t("newContainerLockInsert.cmiLabelsErrorMsg", { cmiLabelsLength: cmiLabels.length }));
        } else {
            setShowCMIModal(true);
            setInfoMessage("");
        }
    };

    const handleCMIListChangeFromTable = (newCMIList, index) => {
        const selectedContainer = { ...requestedContainers[index], cmiList: newCMIList };
        const newRequestedContainers = [...requestedContainers];
        newRequestedContainers[index] = selectedContainer;
        setRequestedContainers(newRequestedContainers);
    };

    const onContainerQuantityChange = (event) => {
        const newContainerQuantity = parseInt(event.target.value);
        const validCMIItems = cmiLabels.filter((item) => item);
        if (newContainerQuantity && newContainerQuantity > 0) {
            if (cmiLabels.length === 0 || validCMIItems.length === 0) {
                setCMILabels([...Array(newContainerQuantity)]);
            } else if (newContainerQuantity >= validCMIItems.length) {
                const newCMIList = [...validCMIItems, ...Array(newContainerQuantity - validCMIItems.length)];
                setCMILabels(newCMIList);
            }
        }

        handleChange(event);
    };

    const onTooltipClick = async () => {
        setIsLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const filePath = generateFilePathForCurrentLocale(bucketPath, staticFile["containerInsertGuide"]);
        const url = await openFileFromFB(filePath);
        if (url) {
            if (url.failed) {
                window.open("#", "_blank");
            } else {
                window.open(url, "_blank");
            }
        } else {
            window.open("#", "_blank");
        }
        setIsLoading(false);
    };

    return (
        <Box>
            <CircularLoaderFullPage show={isLoading} />
            <PageSubTitle>
                <Trans i18nKey={"titles.containerInformation"} />
            </PageSubTitle>
            <Typography sx={styles.pageDesc}>
                <Trans i18nKey={"newContainerLockInsert.containerInfoDescription"} />
            </Typography>
            <Box sx={styles.containerForm}>
                <SecureSyncSelect
                    required
                    error={touched.vaultId && Boolean(errors.vaultId)}
                    helperText={touched.vaultId && errors.vaultId}
                    showBlankOption={vaultOptions.length > 1}
                    label={t("formFields.vaultID")}
                    options={vaultOptions}
                    name="vaultId"
                    value={values.vaultId}
                    onChange={handleChange}
                />
                <Box sx={styles.containerTypeSelect}>
                    <SecureSyncSelect
                        required
                        showBlankOption
                        error={touched.containerTypeId && Boolean(errors.containerTypeId)}
                        helperText={touched.containerTypeId && errors.containerTypeId}
                        value={values.containerTypeId}
                        label={t("formFields.containerType")}
                        options={containerTypeOptions}
                        name="containerTypeId"
                        onChange={handleChange}
                    />
                </Box>
                <TextField
                    inputProps={{ maxLength: 3 }}
                    required
                    error={touched.containerQuantity && Boolean(errors.containerQuantity)}
                    helperText={touched.containerQuantity && errors.containerQuantity}
                    value={values.containerQuantity}
                    label={t("formFields.containerQty")}
                    name="containerQuantity"
                    onChange={onContainerQuantityChange}
                />
                <Box sx={styles.locksFieldWrapper}>
                    <Switch checked={values.isLocks} name="isLocks" onChange={handleChange} />
                    <Box sx={styles.locksLabel}>
                        <Trans i18nKey={"formFields.locks"} />?
                    </Box>
                    <Typography sx={styles.locksCaption} variant="caption">
                        <Trans i18nKey={"newContainerLockInsert.locksCaption"} />
                    </Typography>
                </Box>
                <Box>
                    <SecureSyncSelect
                        error={touched.lockTypeId && Boolean(errors.lockTypeId)}
                        helperText={touched.lockTypeId && errors.lockTypeId}
                        value={values.lockTypeId}
                        label={t("formFields.lockType")}
                        options={lockTypeOptions}
                        name="lockTypeId"
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            {/* Customer Media Identifier */}
            {globalAttr.allowCustomerMediaIdentifiers && (
                <Box sx={styles.cmiField}>
                    <Box sx={styles.cmiWrapper}>
                        <Typography fontWeight={500}>
                            <Trans i18nKey={"titles.customMediaIdentifier"} />
                        </Typography>
                        <SecureSyncIconTooltip
                            buttonSx={{ py: "0px" }}
                            title={
                                <Box>
                                    <Typography sx={styles.tooltipTitle}>
                                        <Trans i18nKey={"newContainerLockInsert.mediaIdentifierTooltipTitle"} />
                                    </Typography>
                                    <Typography sx={styles.tooltipDesc}>
                                        <Trans
                                            i18nKey={"newContainerLockInsert.mediaIdentifierTooltipDesc"}
                                            values={{ buttonLabel: t("buttonLabels.enterValues") }}
                                        />
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                    <Box>
                        <Button
                            disabled={!(values.containerQuantity && parseInt(values.containerQuantity) > 0)}
                            variant="outlined"
                            onClick={onCMIClick}
                        >
                            <Trans i18nKey={"buttonLabels.enterValues"} />
                        </Button>
                    </Box>
                </Box>
            )}
            {/* Insert Configuration */}
            <Box>
                <Box sx={styles.insertConfigLabelWrapper}>
                    <Typography fontWeight={500}>
                        <Trans i18nKey={"formFields.insertConfiguration"} />
                    </Typography>
                    <SecureSyncIconTooltip
                        buttonSx={{ py: "0px" }}
                        onClick={onTooltipClick}
                        title={
                            <Box>
                                <Typography sx={styles.cmiTooltipDesc}>
                                    <Trans i18nKey={"newContainerLockInsert.insertConfigurationTooltip"} />
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
                <Box sx={styles.insertConfigWrapper}>
                    {values.containerTypeId && maxInsertsAllowed === 0 && (
                        <Trans i18nKey={"common.notApplicable"}></Trans>
                    )}
                    {maxInsertsAllowed > 0 && (
                        <Box sx={styles.fourColumnLayout} key={`insert-config-dropdowns-${maxInsertsAllowed}`}>
                            {[...Array(maxInsertsAllowed).keys()].map((index) => (
                                <SecureSyncSelect
                                    key={index}
                                    label={t("formFields.insertConfiguration")}
                                    error={touched.insertTypeIds?.[index] && Boolean(errors.insertTypeIds?.[index])}
                                    helperText={touched.insertTypeIds?.[index] && errors.insertTypeIds?.[index]}
                                    required
                                    showBlankOption
                                    value={values.insertTypeIds[index]}
                                    name={`insertTypeIds.${index}`}
                                    options={insertTypesOptions}
                                    onChange={(event) => onInsertConfigChange(event, index)}
                                    onBlur={handleBlur}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>

            <Typography sx={styles.containerNotListed}>
                <Trans
                    i18nKey="newContainerLockInsert.containerNotListed"
                    components={{
                        Link: <Link href={`mailto:${process.env.REACT_APP_HELP_DESK_EMAIL}`} />,
                    }}
                />
            </Typography>
            <Box sx={styles.buttonWrapper}>
                <Button
                    variant="outlined"
                    type="reset"
                    onClick={handleResetForm}
                    title={getKeyBoardLabel(shortCutKeys.clear)}
                >
                    <Trans i18nKey={"buttonLabels.clear"} />
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    title={getKeyBoardLabel(shortCutKeys.add)}
                >
                    <Trans i18nKey={"buttonLabels.add"} />
                </Button>
            </Box>
            <RequestedContainer
                showCMIColumn={globalAttr.allowCustomerMediaIdentifiers}
                savedCMILabels={savedCMILabels}
                records={requestedContainers}
                onRemoveContainer={onClickDelete}
                onCMIListChange={handleCMIListChangeFromTable}
            />
            <Divider />
            <CustomMediaIdentifierModal
                existingCMILabels={[...existingCMIList, ...savedCMILabels]}
                records={cmiLabels}
                show={showCMIModal}
                onClose={() => setShowCMIModal(false)}
                onSave={setCMILabels}
            />
        </Box>
    );
};

export default ContainerInformation;
