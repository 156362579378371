const styles = {
    typographyHeader: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.5" /* 24px */,
        letterSpacing: "0.15px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    typographyError: {
        fontSize: "14px",
        paddingBottom: "20px",
    },
    boxPadding: {
        paddingBottom: "20px",
    },
    iconColor: {
        color: "primary.main1",
    },
};

export default styles;
