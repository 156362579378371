import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import styles from "./NotFound.styles";
import ErrorPage from "src/components/AppComponents/ErrorPage/ErrorPage";

export const NotFound = () => {
    return (
        <ErrorPage showDashboardBtn title={<Trans i18nKey={"errorPage.pageNotFound"} />}>
            <Typography sx={styles.messageSection}>
                <Trans i18nKey={"errorPage.errorDescription"} />
            </Typography>
        </ErrorPage>
    );
};
