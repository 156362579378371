/* eslint-disable max-lines */
import { Alert, Box, Button, Container, Divider, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./ScanVerification.styles";
import { ScanVerificationResult } from "src/components/ProcessMedia/ScanVerification/ScanVerificationResult/ScanVerificationResult";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getPreferences } from "src/services/adminService";
import { populateOMScanVerifyControls } from "src/services/processMediaService";
import { filterData, getKeyBoardLabel } from "src/utils/commonHelper";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { yesFlag } from "src/constants/serviceConstants";
import DefineComparisonMediaModal from "src/components/ProcessMedia/ScanVerification/DefineComparisonMediaModal/DefineComparisonMediaModal";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import {
    initialVerifyDataObj,
    sortCriteriaAscScanVerification,
    sortCriteriaDescScanVerification,
    verifyIssueTypeId,
} from "src/constants/processMediaConstants";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import {
    downloadInstructionGuide,
    minValObject,
    multipleSort,
    secureSyncSelectOptionsMapper,
} from "src/utils/processMediaHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { logicalVaultFileName, staticFile } from "src/constants/fileConstants";
import ResetScanVerificationConfirmation from "src/components/ProcessMedia/ConfirmationModals/ResetScanVerificationConfirmation/ResetScanVerificationConfirmation";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

export const ScanVerification = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.verify,
        callback: () => {
            handleOnVerifyClick();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            onReset();
        },
    });
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [hasScanMedia, setHasScanMedia] = useState(false);
    const [isComparisonMediaModalOpen, setIsComparisonMediaModalOpen] = useState(false);
    const [scanMediaObj, setScanMediaObj] = useState({ lastScan: "", totalRecords: "", mediaList: [] });
    const [metaDataInfo, setMetaDataInfo] = useState({ booScanVerifyClosedContainerContents: false });
    const [actionsList, setActionsList] = useState([]);
    const [listArr, setListArr] = useState([]);
    const [logicalVaultData, setLogicalVaultData] = useState([]);
    const [logicalVaultVisibility, setLogicalVaultVisibility] = useState(false);
    const [populateData, setPopulateData] = useState([]);
    const [showScanMediaResults, setShowScanMediaResults] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showResetModalConfirmation, setShowResetModalConfirmation] = useState(false);
    const [selectedVerifyData, setSelectedVerifyData] = useState({
        verifyData: initialVerifyDataObj,
        resultValues: [],
        isScanVerificationHistory: false,
    });

    const { values, setFieldValue, handleBlur } = useFormik({
        initialValues: {
            action: "",
            list: "",
            logicalVault: "",
            logicalVaultHelp: "",
            logicalVaultDes: "",
        },
    });

    useEffect(() => {
        getUserPreferences();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserPreferences = async () => {
        setIsLoading(true);
        const response = await getPreferences("2", null);
        const responsePopulateData = await populateOMScanVerifyControls({
            sendOpenMediaPhrase: t("scanVerification.sendOpenMediaPhrase"),
            receiveOpenMediaPhrase: t("scanVerification.receiveOpenMediaPhrase"),
            receiveCloseContainerPhrase: t("scanVerification.receiveCloseContainerPhrase"),
            closedContainerPhrase: t("scanVerification.closedContainerPhrase"),
            secureSyncOmDistributionPhrase: t("scanVerification.secureSyncOmDistributionPhrase"),
        });
        if (!responsePopulateData.failure) {
            if (responsePopulateData.actionDropdown && responsePopulateData.actionDropdown.length > 0) {
                const actionsList = secureSyncSelectOptionsMapper(
                    "action_id",
                    "descr",
                    "action_id",
                    responsePopulateData.actionDropdown
                );
                setActionsList(actionsList);
                setHasScanMedia(true);
                setFieldValue("action", actionsList[0].value);
                setListArr(insertListData(responsePopulateData?.listDropdown, actionsList[0].value));
            } else {
                setHasScanMedia(false);
            }
            setPopulateData(responsePopulateData);
        }

        if (!response.failure) {
            const verifyCloseContainer = filterData(response.rs0, "preference_type_id", "15") || [];
            setMetaDataInfo({
                ...metaDataInfo,
                booScanVerifyClosedContainerContents:
                    verifyCloseContainer[0]?.preference_value === yesFlag ? true : false,
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const scanHandlerDataBind = () => {
            if (locationState) {
                const mediaList = {
                    lastScan: locationState?.lastScan,
                    totalRecords: locationState?.totalRecords,
                    mediaList: locationState?.mediaList,
                };
                setScanMediaObj(mediaList);
                setFieldValue("action", locationState?.verifyTypeId);
                setFieldValue("list", locationState?.list);
                setFieldValue("logicalVaultDes", locationState?.logicalVaultDesc);

                if (locationState?.logicalVaultDesc) {
                    const selectedLogicalVault = filterData(
                        populateData.logicalVaultDropdown,
                        "logical_vault",
                        locationState?.logicalVaultDesc
                    );
                    getLogicalVault(selectedLogicalVault[0]?.customer_file_section_id);
                }
            }
        };
        scanHandlerDataBind();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const resetHandler = () => {
        setScanMediaObj({ lastScan: "", totalRecords: "", mediaList: [] });
        setFieldValue("action", actionsList[0].value);
        setFieldValue("list", "");
        setFieldValue("logicalVault", "");
        setFieldValue("logicalVaultHelp", "");
        setFieldValue("logicalVaultDes", "");
        setShowError(false);
        setListArr(insertListData(populateData?.listDropdown, actionsList[0].value));
        setLogicalVaultVisibility(false);
    };

    const openComparisonMediaModalHandler = () => {
        setIsComparisonMediaModalOpen(true);
    };

    const closeComparisonMediaModalHandler = () => {
        setIsComparisonMediaModalOpen(false);
    };

    const submitComparisonMediaModalHandler = (dataObj) => {
        closeComparisonMediaModalHandler();
        setScanMediaObj(dataObj);
    };

    const insertListData = (listArr, actionId) => {
        const filteredData = filterData(listArr, "action_id", actionId);
        switch (actionId) {
            case verifyIssueTypeId.sendOpenMediaToIronMountain:
                return secureSyncSelectOptionsMapper(
                    "descr",
                    "descr",
                    "descr",
                    multipleSort(filteredData, sortCriteriaDescScanVerification),
                    false
                );
            case verifyIssueTypeId.receiveOpenMediaFromIronMountain:
                return secureSyncSelectOptionsMapper(
                    "request_id",
                    "descr",
                    "request_id",
                    multipleSort(filteredData, sortCriteriaDescScanVerification),
                    false
                );
            case verifyIssueTypeId.receiveClosedContainerContents:
                return secureSyncSelectOptionsMapper(
                    "container_id",
                    "descr",
                    "container_id",
                    multipleSort(filteredData, sortCriteriaAscScanVerification),
                    false
                );
            default:
                return [];
        }
    };

    const onChangeAction = (event) => {
        setFieldValue("action", event.target.value);
        setFieldValue("list", "");
        setFieldValue("logicalVault", "");
        setFieldValue("logicalVaultHelp", "");
        setFieldValue("logicalVaultDes", "");
        setLogicalVaultVisibility(false);
        setShowError(false);
        const filteredList = filterData(populateData.listDropdown, "action_id", event.target.value);
        setListArr(insertListData(filteredList, event.target.value));
        setScanMediaObj({ lastScan: "", totalRecords: "", mediaList: [] });
    };

    const onChangeList = (event) => {
        setFieldValue("list", event.target.value);
        setFieldValue("logicalVault", "");
        setFieldValue("logicalVaultHelp", "");
        setFieldValue("logicalVaultDes", "");
        setShowError(false);
        logicalVaultVisibilityAndData(values.action, event.target.value);
        if (values.action === verifyIssueTypeId.sendOpenMediaToIronMountain) {
            setHasScanMedia(true);
        }

        verifyResultTransfer("", event.target.value);
        setScanMediaObj({ lastScan: "", totalRecords: "", mediaList: [] });
    };

    const getCustomerFileSectionId = (selectedLogicalVaultId = "", selectedListValue = "") => {
        if (values.action === verifyIssueTypeId.sendOpenMediaToIronMountain) {
            if (selectedLogicalVaultId && populateData?.logicalVaultDropdown?.length > 0) {
                const selectedLogicalVault = filterData(
                    populateData?.logicalVaultDropdown,
                    "customer_file_section_id",
                    selectedLogicalVaultId
                );
                return selectedLogicalVault[0]?.customer_file_section_id || "";
            }
            return filterData(populateData?.listDropdown, "descr", selectedListValue)[0]?.customer_file_section_id;
        }
        return "";
    };

    const verifyHandler = (listValue, logicalVaultId = values.logicalVault) => {
        setShowScanMediaResults(true);

        let selectedLogicalVault = "";
        if (logicalVaultId) {
            selectedLogicalVault = filterData(logicalVaultData, "value", logicalVaultId);
        }

        var selectedListData = [];
        var selectedCustomerFileSectionId = "";
        var verifiedData;
        if (values.action === verifyIssueTypeId.receiveClosedContainerContents) {
            selectedListData = filterData(populateData?.listDropdown, "container_id", listValue);
            if (populateData?.verifyResultRecords?.length > 0 && selectedListData.length > 0) {
                const selectedVerifiedData = filterData(
                    populateData?.verifyResultRecords,
                    "container_id",
                    selectedListData[0].container_id
                );
                verifiedData = minValObject(selectedVerifiedData, "verify_result_id");
            }
        } else if (values.action === verifyIssueTypeId.receiveOpenMediaFromIronMountain) {
            selectedListData = filterData(populateData?.listDropdown, "request_id", listValue);
            if (populateData?.verifyResultRecords?.length > 0 && selectedListData.length > 0) {
                const selectedVerifiedData = filterData(
                    populateData?.verifyResultRecords,
                    "request_id",
                    selectedListData[0].request_id
                );
                verifiedData = minValObject(selectedVerifiedData, "verify_result_id");
            }
        } else {
            selectedListData = filterData(populateData?.listDropdown, "descr", listValue);
            const customerFileSection = getCustomerFileSectionId(logicalVaultId, listValue);
            selectedCustomerFileSectionId = customerFileSection
                ? customerFileSection
                : selectedListData[0]?.customer_file_section_id;
            if (populateData?.verifyResultRecords?.length > 0) {
                const selectedVerifiedData = filterData(
                    populateData?.verifyResultRecords,
                    "customer_file_section_id",
                    selectedCustomerFileSectionId
                );
                verifiedData = minValObject(selectedVerifiedData, "verify_result_id");
            }
        }
        if (selectedListData.length > 0) {
            setSelectedVerifyData({
                verifyData: {
                    containerId: selectedListData[0]?.container_id,
                    customerFileId: selectedListData[0]?.customer_file_id,
                    customerFileSectionId: logicalVaultId
                        ? selectedLogicalVault[0]?.value
                        : selectedCustomerFileSectionId,
                    requestId: selectedListData[0]?.request_id,
                    logicalVaultId: logicalVaultId,
                    scannedPhrase: t("scanVerification.scannedPhrase"),
                    verifyResultId: verifiedData?.verify_result_id || "",
                    mediaList: "",
                    filterDate: selectedListData[0]?.filter_date,
                    logicalVaultDesc:
                        selectedLogicalVault && selectedLogicalVault.length > 0
                            ? selectedLogicalVault[0]?.label
                            : values.logicalVaultDes,
                    actionId: values.action,
                    actionDes: filterData(actionsList, "value", values.action)[0].label || "",
                    listId: listValue,
                    listDes: filterData(listArr, "value", listValue)[0]?.label || "",
                    scanMediaObj: scanMediaObj,
                },
                resultValues: verifiedData ? [verifiedData] : [],
                isScanVerificationHistory: Boolean(locationState),
            });
        }
    };

    const logicalVaultVisibilityAndData = (actionId, listId) => {
        if (values.action === verifyIssueTypeId.sendOpenMediaToIronMountain && populateData.listDropdown.length > 0) {
            const filteredData = populateData.listDropdown.filter(
                (data) => data.action_id === actionId && data.descr === listId
            );
            if (filteredData.length > 0) {
                if (!filteredData[0].customer_file_id) {
                    const verifyData = {
                        ...selectedVerifyData.verifyData,
                        customerFileSectionId: filteredData[0].customer_file_section_id,
                    };
                    setSelectedVerifyData((data) => ({ ...data, verifyData: verifyData }));
                    setLogicalVaultVisibility(false);
                } else {
                    const filteredLogicalVault = filterData(
                        populateData.logicalVaultDropdown,
                        "customer_file_id",
                        filteredData[0].customer_file_id
                    );

                    setLogicalVaultData([
                        ...secureSyncSelectOptionsMapper(
                            "logical_vault_id",
                            "logical_vault",
                            "customer_file_section_id",
                            filteredLogicalVault
                        ),
                    ]);
                    setFieldValue("logicalVault", "");
                    setLogicalVaultVisibility(true);
                }
            }
        }
    };

    const onChangeLogicalVault = (event) => {
        getLogicalVault(event.target.value);
    };

    const getLogicalVault = (value) => {
        setShowError(false);
        setFieldValue("logicalVault", value);
        const selectedLogicalVault = filterData(populateData.logicalVaultDropdown, "customer_file_section_id", value);
        setFieldValue("logicalVaultDes", selectedLogicalVault[0]?.logical_vault);
        const mediaTypes = filterData(
            populateData.mediaTypes,
            "logical_vault_id",
            selectedLogicalVault[0]?.logical_vault_id
        );
        const lvHelpString = mediaTypes.map((item) => item.media_type_descr).join(" / ");

        setFieldValue("logicalVaultHelp", lvHelpString);
        verifyResultTransfer(value, values.list);
        setScanMediaObj({ lastScan: "", totalRecords: "", mediaList: [] });
    };

    const verifyResultTransfer = (logicalVId, listId) => {
        var verifiedResults = [];
        var getSelectedListData = [];
        if (populateData?.verifyResultRecords?.length > 0) {
            switch (values.action) {
                case verifyIssueTypeId.sendOpenMediaToIronMountain:
                    verifiedResults = filterData(
                        populateData?.verifyResultRecords,
                        "customer_file_section_id",
                        getCustomerFileSectionId(logicalVId, listId)
                    );
                    break;
                case verifyIssueTypeId.receiveOpenMediaFromIronMountain:
                    getSelectedListData = filterData(populateData?.listDropdown, "request_id", listId);
                    verifiedResults = filterData(
                        populateData?.verifyResultRecords,
                        "request_id",
                        getSelectedListData[0].request_id
                    );
                    break;
                case verifyIssueTypeId.receiveClosedContainerContents:
                    getSelectedListData = filterData(populateData?.listDropdown, "container_id", listId);
                    verifiedResults = filterData(
                        populateData?.verifyResultRecords,
                        "container_id",
                        getSelectedListData[0].container_id
                    );
                    break;
                default:
                    verifiedResults = [];
                    break;
            }
        }
        if (verifiedResults.length > 0) {
            verifyHandler(listId, logicalVId);
        }
    };
    const mandatoryFieldsError = !values.list || (!values.logicalVault && logicalVaultVisibility);
    const scanMoreHandler = (mediaList) => {
        setShowScanMediaResults(false);
        setScanMediaObj(mediaList);
    };

    const downloadInstructionFile = () => {
        setIsLoading(true);
        downloadInstructionGuide(staticFile["logicalVault"], logicalVaultFileName, () => {
            setIsLoading(false);
        });
    };

    const onReset = () => {
        if (scanMediaObj?.mediaList && scanMediaObj.mediaList.length > 0) {
            setShowResetModalConfirmation(true);
        } else {
            resetHandler();
        }
    };

    const handleOnVerifyClick = () => {
        if (mandatoryFieldsError) {
            setShowError(true);
        } else {
            verifyHandler(values.list);
        }
    };

    return (
        <>
            {!showScanMediaResults && (
                <>
                    <CircularLoaderFullPage show={isLoading} />
                    <Container>
                        <Box sx={styles.container}>
                            <Box sx={styles.innerBox}>
                                <PageTitle>{t("scanVerification.scanVerification")}</PageTitle>
                                {showError && mandatoryFieldsError && (
                                    <Box sx={styles.marginBottomBreak}>
                                        <SecureSyncAlert
                                            message={
                                                <Typography sx={styles.fontWeight500}>
                                                    {t("omDistribution.followingError")}
                                                    <br />
                                                    {!values.list && (
                                                        <Box sx={styles.errorMsgs}>
                                                            {t("scanVerification.listRequired")}
                                                        </Box>
                                                    )}
                                                    {!values.logicalVault && logicalVaultVisibility && (
                                                        <Box sx={styles.errorMsgs}>
                                                            {t("scanVerification.logicalVaultRequired")}
                                                        </Box>
                                                    )}
                                                </Typography>
                                            }
                                            severity="error"
                                            onClose={() => {
                                                setShowError(false);
                                            }}
                                        />
                                    </Box>
                                )}
                                {!hasScanMedia && (
                                    <Box sx={styles.fullHeight}>
                                        <Alert severity="info">
                                            {t("scanVerification.infoAlertMsg")}{" "}
                                            {metaDataInfo.booScanVerifyClosedContainerContents &&
                                                t("scanVerification.infoAlertMsg2")}
                                        </Alert>
                                    </Box>
                                )}
                                {hasScanMedia && (
                                    <>
                                        <Typography sx={styles.infoSections}>
                                            {t("scanVerification.selectTheListInformationToBeVerified")}
                                        </Typography>
                                        <Divider />
                                        <PageSubTitle>{t("scanVerification.listInformation")}</PageSubTitle>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            gap={2}
                                            sx={styles.marginBottomBreak}
                                        >
                                            <Box component="div" width={"50%"}>
                                                <SecureSyncSelect
                                                    required
                                                    type="outlined"
                                                    name="action"
                                                    label={t("scanVerification.action")}
                                                    options={actionsList}
                                                    value={values.action}
                                                    onBlur={handleBlur}
                                                    onChange={onChangeAction}
                                                />
                                            </Box>
                                            <Box component="div" width={"50%"}>
                                                <SecureSyncSelect
                                                    required
                                                    type="outlined"
                                                    label={t("scanVerification.list")}
                                                    emptyOption
                                                    name="list"
                                                    options={listArr}
                                                    value={values.list}
                                                    onBlur={handleBlur}
                                                    onChange={onChangeList}
                                                />
                                            </Box>
                                        </Box>
                                        {logicalVaultVisibility && (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                gap={2}
                                                sx={styles.marginBottomBreak}
                                            >
                                                <Box component="div" width={"25%"}>
                                                    <SecureSyncSelect
                                                        required
                                                        type="outlined"
                                                        label={t("scanVerification.logicalVault")}
                                                        showBlankOption
                                                        name="logicalVault"
                                                        options={logicalVaultData}
                                                        value={values.logicalVault}
                                                        onBlur={handleBlur}
                                                        onChange={onChangeLogicalVault}
                                                    />
                                                </Box>
                                                <Box component="div" width={"25%"}>
                                                    <SecureSyncIconTooltip
                                                        title={
                                                            values.logicalVaultHelp ? (
                                                                <Typography>
                                                                    {t("scanVerification.mediaTypes")}
                                                                    <Box fontSize={12}>{values.logicalVaultHelp}</Box>
                                                                </Typography>
                                                            ) : (
                                                                <>{t("scanVerification.logicalVaultHelp")}</>
                                                            )
                                                        }
                                                        placement="right"
                                                        onClick={downloadInstructionFile}
                                                    />
                                                </Box>
                                                <Box component="div" width={"50%"} />
                                            </Box>
                                        )}
                                        {Boolean(values.action) && Boolean(values.list) && (
                                            <>
                                                <Typography sx={styles.infoSections}>
                                                    <Trans i18nKey={"scanVerification.clickDefineComparisonMedia"} />
                                                </Typography>
                                            </>
                                        )}
                                        <Divider />
                                        <br />
                                        {Boolean(scanMediaObj.lastScan) && Boolean(scanMediaObj.totalRecords) && (
                                            <>
                                                <PageSubTitle>{t("scanVerification.statistics")}</PageSubTitle>
                                                <Box
                                                    display={"flex"}
                                                    justifyContent={"space-between"}
                                                    marginTop={2}
                                                    width="50%"
                                                    sx={styles.marginBottomBreak}
                                                >
                                                    <SecureSyncReadOnlyTextField
                                                        value={scanMediaObj?.lastScan || " "}
                                                        label={t("scanVerification.lastScan")}
                                                    />
                                                    <SecureSyncReadOnlyTextField
                                                        value={scanMediaObj?.totalRecords || " "}
                                                        label={t("scanVerification.totalScanned")}
                                                    />
                                                </Box>
                                                <Divider />
                                                <br />
                                            </>
                                        )}
                                        <Box sx={styles.buttonContainer}>
                                            {Boolean(values.action) && Boolean(values.list) && (
                                                <Button variant="outlined" onClick={openComparisonMediaModalHandler}>
                                                    {t("buttonLabels.defineComparisonMedia")}
                                                </Button>
                                            )}
                                            <Button
                                                title={getKeyBoardLabel(shortCutKeys.reset)}
                                                variant="outlined"
                                                onClick={onReset}
                                            >
                                                {t("buttonLabels.reset")}
                                            </Button>
                                            <Button
                                                title={getKeyBoardLabel(shortCutKeys.verify)}
                                                variant="contained"
                                                onClick={handleOnVerifyClick}
                                            >
                                                {t("buttonLabels.verify")}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </>
            )}
            {showScanMediaResults && (
                <ScanVerificationResult
                    scanVerificationObj={selectedVerifyData.verifyData}
                    verifyResultRecord={selectedVerifyData.resultValues}
                    isScanVerificationHistory={selectedVerifyData.isScanVerificationHistory}
                    scanMore={scanMoreHandler}
                    refreshPage={getUserPreferences}
                />
            )}
            {isComparisonMediaModalOpen && (
                <DefineComparisonMediaModal
                    scanMediaListObj={scanMediaObj}
                    onClose={closeComparisonMediaModalHandler}
                    onSubmit={submitComparisonMediaModalHandler}
                />
            )}
            <ResetScanVerificationConfirmation
                handleConfirm={() => {
                    resetHandler();
                    setShowResetModalConfirmation(false);
                }}
                onClose={() => {
                    setShowResetModalConfirmation(false);
                }}
                open={showResetModalConfirmation}
            />
        </>
    );
};
