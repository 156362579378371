import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import styles from "./BannerSection.styles";
import SecureSyncAccordion from "src/components/common/SecureSyncAccordion/SecureSyncAccordion";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getIsoLocaleCode } from "src/redux/languageSlice";
import { getBannerInfoService } from "src/services/homeService";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";

export const BannerSection = () => {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(true);
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const localeCode = useSelector(getIsoLocaleCode);
    useEffect(() => {
        getBannerInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeCode]);

    const getBannerInfo = async () => {
        setLoading(true);
        const data = await getBannerInfoService({ locale: localeCode });
        if (data.notifications) {
            const bannerNotifications = data.notifications.sort((a, b) => {
                const aId = parseInt(a.bulletin_id);
                const bId = parseInt(b.bulletin_id);
                return aId > bId ? 1 : bId > aId ? -1 : 0;
            });

            const sanitizeBanner = bannerNotifications.map((e) => {
                return {
                    id: e.bulletin_id,
                    message: DOMPurify.sanitize(e.message_text, { ADD_ATTR: ["target"] }),
                    title: DOMPurify.sanitize(e.message_title, { ADD_ATTR: ["target"] }),
                };
            });

            setBannerData(sanitizeBanner);
        }
        setLoading(false);
    };

    return (
        <Box sx={styles.bannerContainer} gap={2}>
            <CircularLoaderFullPage show={loading} />
            {bannerData.length !== 0 && (
                <SecureSyncAccordion
                    boxSx={expand ? styles.accordionWrapper : {}}
                    square
                    id="om"
                    expanded={expand}
                    summary={<Typography>{t("homeModule.Notifications")}</Typography>}
                    onClick={() => {
                        setExpand(!expand);
                    }}
                >
                    <Box sx={styles.notificationWrapper}>
                        {bannerData.map((e) => {
                            return (
                                <Box key={e.id}>
                                    <PageSubTitle>
                                        <Box dangerouslySetInnerHTML={{ __html: e.title }} />
                                    </PageSubTitle>
                                    <Box dangerouslySetInnerHTML={{ __html: e.message }} />
                                </Box>
                            );
                        })}
                    </Box>
                </SecureSyncAccordion>
            )}
        </Box>
    );
};
