import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TransactionHistoryTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const TransactionHistoryTable = ({ records }) => {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                field: "container_transaction_datetime",
                headerName: convertToLocaleUpperCase(t("tableHeader.transactionDate")),
            },
            {
                field: "activity_display",
                headerName: convertToLocaleUpperCase(t("tableHeader.activity")),
            },
            {
                field: "personnel_name",
                headerName: convertToLocaleUpperCase(t("tableHeader.personnel")),
            },
            {
                field: "request_type",
                headerName: convertToLocaleUpperCase(t("tableHeader.requestType")),
            },
            {
                field: "request_id",
                headerName: convertToLocaleUpperCase(t("tableHeader.requestID")),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const hasRequests = records?.length > 0;

    return (
        <Box sx={styles.tableContainer}>
            <PageSubTitle> {t("titles.transactionHistory")}</PageSubTitle>
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    {hasRequests && (
                        <>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: records?.length })}
                            </Typography>
                        </>
                    )}
                </Box>
                <SecureSyncTable
                    rows={hasRequests ? records : []}
                    columns={columns}
                    noDataMessage={t("messages.noRecordsFound")}
                />
            </SecureSyncTableCard>
        </Box>
    );
};

export default TransactionHistoryTable;
