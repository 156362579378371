function RemoveRedEyeFilled({ fill, ...props }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="RemoveRedEyeFilled">
                <g id="Vector">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.21484 12.0199C7.21484 14.4099 9.15484 16.3499 11.5448 16.3499C13.9348 16.3499 15.8748 14.4099 15.8748 12.0199C15.8748 9.62994 13.9348 7.68994 11.5448 7.68994C9.15484 7.68994 7.21484 9.62994 7.21484 12.0199ZM8.71484 12.0199C8.71484 10.4599 9.98484 9.18994 11.5448 9.18994C13.1048 9.18994 14.3748 10.4599 14.3748 12.0199C14.3748 13.5799 13.1048 14.8499 11.5448 14.8499C9.98484 14.8499 8.71484 13.5799 8.71484 12.0199Z"
                        fill={fill}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.795 15.02C4.235 18.84 7.785 21.04 11.545 21.04C15.295 21.04 18.845 18.84 21.285 15.02C22.345 13.36 22.345 10.67 21.285 9.02C18.845 5.2 15.295 3 11.545 3C7.795 3 4.245 5.2 1.795 9.02C0.735 10.68 0.735 13.37 1.795 15.02ZM3.065 9.83C5.225 6.44 8.315 4.5 11.545 4.5C14.775 4.5 17.865 6.44 20.025 9.83C20.775 11 20.775 13.04 20.025 14.21C17.865 17.6 14.775 19.54 11.545 19.54C8.315 19.54 5.225 17.6 3.065 14.21C2.315 13.04 2.315 11 3.065 9.83Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
}

export default RemoveRedEyeFilled;
