import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./PickupManifestModal.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { formatDate } from "src/utils/dateHelper";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getPickupManifestListPayload } from "src/services/printServices";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const PickupManifestModal = ({ containerData, onClose }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            printReport();
        },
    });
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useEffect(() => {
        setData(
            containerData.map((container) => {
                return { ...container, includeOnPrintedManifestFlag: true };
            })
        );
    }, [containerData]);

    const columns = [
        {
            field: "includeOnPrintedManifestFlag",
            headerName: t("containerPickup.onManifest").toUpperCase(),
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <SecureSyncCheckBox
                    checked={params.includeOnPrintedManifestFlag}
                    onChange={(event) => {
                        handlerOnChangePickupManifest(event, params);
                    }}
                />
            ),
        },
        { field: "Number", headerName: t("containerPickup.containerNumber").toUpperCase(), sortable: false, flex: 3 },
        {
            field: "Pending_Return_Date",
            headerName: t("containerPickup.returnDate").toUpperCase(),
            sortable: false,
            flex: 2,
            renderCell: (params) => getPendingReturnDate(params),
        },
    ];

    const handlerOnChangePickupManifest = (event, value) => {
        setData(
            data.map((obj) =>
                obj.Container_Id === value.Container_Id
                    ? {
                          ...obj,
                          includeOnPrintedManifestFlag: event.target.checked,
                      }
                    : obj
            )
        );
    };

    const getPendingReturnDate = (params) => {
        const isReadyForPickup = params?.includeOnManifestFlag === 1;
        if (params?.isReturnDateEditable || !params?.hasAccessToEditReturnDate) {
            const pendingReturnDate = formatDate(params?.Pending_Return_Date);
            if (isReadyForPickup) {
                if (pendingReturnDate) {
                    return <>{pendingReturnDate}</>;
                }
                return <>{t("containerPickup.indefinite")}</>;
            }
            return <>{pendingReturnDate}</>;
        } else if (params?.isContainer) {
            if (params?.isDeleted) {
                const displayReturnDate = formatDate(params?.Display_Return_Date);
                if (isReadyForPickup) {
                    return displayReturnDate ? displayReturnDate : t("containerPickup.indefinite");
                }
                return <>{displayReturnDate}</>;
            } else {
                return <>{params?.Summary_Type}</>;
            }
        }
    };
    const printReport = () => {
        const selectedContainerNumbers = data
            .map((container) => (container.includeOnPrintedManifestFlag ? container.Number : ""))
            .join(",");
        setPrintPayload(getPickupManifestListPayload(selectedContainerNumbers));
        printModalOpen(true);
    };
    return (
        <>
            <SecureSyncModal
                open={true}
                title={t("containerPickup.pickupManifest")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={onClose}>
                            {t("buttonLabels.close")}
                        </Button>
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                            variant="contained"
                            onClick={printReport}
                        >
                            {t("buttonLabels.preparePickupManifest")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <Typography sx={styles.statusRecord}>
                            {t("containerPickup.theSelectedItemsWillBeIncludedOnThePickupManifest")}
                        </Typography>
                        <br />
                        <SecureSyncTableCard>
                            <Box sx={styles.recordCount}>
                                {data.length > 0 && (
                                    <Box sx={styles.statusRecord}>
                                        <Typography variant="body1">
                                            {t("messages.recordsFound", { count: data.length })}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={styles.tblCardContent}>
                                <SecureSyncTable
                                    rows={data}
                                    tableMinWidth="100px"
                                    columns={columns}
                                    getRowId={(row) => row.Container_Id}
                                    noDataMessage={t("messages.noAvailableRecords")}
                                />
                            </Box>
                        </SecureSyncTableCard>
                    </Box>
                </Box>
            </SecureSyncModal>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.containerPickupManifestReport}
            />
        </>
    );
};

export default PickupManifestModal;
