import { routePaths } from "./routeConstants";

const securePath = routePaths.root;

//processMedia
export const processMediaHome = `${securePath}/${routePaths.processMedia.home}`;
export const containerPickup = `${processMediaHome}/${routePaths.processMedia.containerPickup}`;
export const containerContentsSummary = `${processMediaHome}/${routePaths.processMedia.containerContentsSummary}`;
export const downloadInventory = `${processMediaHome}/${routePaths.processMedia.downloadInventory}`;
export const omDistributionList = `${processMediaHome}/${routePaths.processMedia.omDistributionList}`;
export const scanVerification = `${processMediaHome}/${routePaths.processMedia.scanVerification}`;
export const sendListFile = `${processMediaHome}/${routePaths.processMedia.sendListFile}`;
