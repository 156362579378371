import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";

const SecureSyncAccordion = ({
    boxSx = {},
    summary,
    id,
    children,
    expanded = false,
    onClick = () => {},
    square = false,
    accordionSX = {},
}) => {
    return (
        <Box sx={boxSx}>
            <Accordion expanded={expanded} square={square} sx={accordionSX}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`accord-${id}`}
                    id={`accord-${id}`}
                    onClick={onClick}
                >
                    {summary}
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default SecureSyncAccordion;
