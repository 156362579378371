import { t } from "i18next";
import { sortingOrderType } from "./processMediaConstants";

export const maxCommentLength = "50";
export const orderByL = "L";
export const getAll = "<ALL>";
export const getAllValue = "all";
export const getAllCaps = "All";
export const getAllExcludingDelete = "<ALL> Excluding Deleted";
export const maxIntegerValue = "2147483647";
export const auditTypes = {
    openMedia: "OM",
};
export const systemFunction = "System_Function";
export const systemSectionType = "File_Section_Type";

export const sortCriteriaResearch = {
    completeLibraryAudits: [{ key: "Completion_Date_Sort", order: sortingOrderType.descending }],
    auditExceptions: [
        { key: "Code", order: sortingOrderType.ascending },
        { key: "Volser", order: sortingOrderType.ascending },
    ],
    scanVerification: [{ key: "List_Descr_Ltz", order: sortingOrderType.ascending }],
    vaultStatusReportCustomerNo: [{ key: "customer_number", order: sortingOrderType.ascending }],
    openMediaExceptionDetails: [{ key: "sequenceId", order: sortingOrderType.ascending }],
    formattedNameSort: [{ key: "formattedname", order: sortingOrderType.ascending }],
    sortBySequenceId: [{ key: "sequence_id", order: sortingOrderType.ascending }],
    containerDiscrepancySort: [
        { key: "customer_number", order: sortingOrderType.ascending },
        { key: "detected_in_fst_id", order: sortingOrderType.ascending },
    ],
    accountSummarySort: [
        { key: "customer_number", order: sortingOrderType.ascending },
        { key: "media_type_descr", order: sortingOrderType.ascending },
    ],
};

export const mediaStatusId = {
    statusId70: "70",
    statusId80: "80",
    statusId90: "90",
    statusId15: "15",
    statusId9: "9",
    statusId11: "11",
    statusId10: "10",
};

export const mediaLocatorView = {
    openMedia: "1",
};

export const toolTipTypes = {
    critical: "20",
    dRInformation: "40",
    information: "50",
    none: "99",
};

export const exportSearchAttributeNames = {
    searchMaxDisplay: "SearchMaxDisplay",
    exportMaxRecords: "ExportMaxRecords",
};

export const allOption = () => {
    return {
        id: t("common.all"),
        value: getAllValue,
        label: t("common.all"),
    };
};

export const mediaValues = {
    openMedia: "openmedia",
    container: "container",
};

export const mediaOptions = [
    allOption(),
    { id: "container", label: t("common.container"), value: mediaValues.container },
    { id: "media", label: t("common.openMedia"), value: mediaValues.openMedia },
];

export const otherExceptionHelperHeader = [
    "Customer:",
    "Name",
    "Detected In:",
    "Exception Type:",
    "Media #",
    "Detected Datetime",
    "Task",
    "Logical Vault",
    "Request Type",
    "Service Date",
    "Additional Information",
    "CAR ID",
];

export const discrepancyTranslation = () => [
    { type: "openMedia", key: "Missing", id: "1", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Missing", id: "2", label: t("discrepancyTypes.notReceived") },
    { type: "openMedia", key: "Missing", id: "4", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Missing", id: "9", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Missing", id: "10", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Missing", id: "14", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Missing", id: "16", label: t("discrepancyTypes.notAtIRM") },
    { type: "openMedia", key: "Unexpected", id: "2", label: t("discrepancyTypes.unexpected") },
    { type: "openMedia", key: "Unexpected", id: "3", label: t("discrepancyTypes.unexpected") },
    { type: "openMedia", key: "Unexpected", id: "4", label: t("discrepancyTypes.notOnCustomerInventory") },
    { type: "openMedia", key: "Unexpected", id: "16", label: t("discrepancyTypes.unexpected") },
    { type: "openMedia", key: "Unexpected", id: "17", label: t("discrepancyTypes.unexpectedWithoutList") },
    { type: "container", key: "IncompleteStop", id: "13", label: t("discrepancyTypes.incompleteStop") },
    { type: "container", key: "NoReturnDate", id: "13", label: t("discrepancyTypes.noValidReturnDate") },
    { type: "container", key: "Missing", id: "4", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "7", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "11", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "13", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "63", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "67", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Missing", id: "73", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "Unexpected", id: "7", label: t("discrepancyTypes.unexpected") },
    { type: "container", key: "Unexpected", id: "11", label: t("discrepancyTypes.unexpected") },
    { type: "container", key: "Unexpected", id: "13", label: t("discrepancyTypes.unexpected") },
    { type: "container", key: "Unexpected", id: "15", label: t("discrepancyTypes.unexpected") },
    { type: "container", key: "Unexpected", id: "73", label: t("discrepancyTypes.unexpected") },
    { type: "container", key: "IncompleteStop", id: "", label: t("discrepancyTypes.incompleteStop") },
    { type: "container", key: "Missing", id: "", label: t("discrepancyTypes.notAtIRM") },
    { type: "container", key: "NoReturnDate", id: "", label: t("discrepancyTypes.noValidReturnDate") },
    { type: "container", key: "Unexpected", id: "", label: t("discrepancyTypes.unexpected") },
];

export const detectedInTranslation = () => [
    { type: "systemFunction", id: "11", label: t("detectedIn.outbound") },
    { type: "systemFunction", id: "13", label: t("detectedIn.inbound") },
    { type: "systemFunction", id: "15", label: t("detectedIn.inbound") },
    { type: "systemFunction", id: "4", label: t("detectedIn.outbound") },
    { type: "systemFunction", id: "7", label: t("detectedIn.outbound") },
    { type: "systemFunction", id: "73", label: t("detectedIn.audit") },
    { type: "systemFunction", id: "67", label: t("detectedIn.outbound") },
    { type: "systemFunction", id: "63", label: t("detectedIn.inbound") },
    { type: "fileSectionType", id: "1", label: t("detectedIn.outbound") },
    { type: "fileSectionType", id: "14", label: t("detectedIn.destruction") },
    { type: "fileSectionType", id: "16", label: t("detectedIn.audit") },
    { type: "fileSectionType", id: "2", label: t("detectedIn.inbound") },
    { type: "fileSectionType", id: "3", label: t("detectedIn.intervaultMovement") },
    { type: "fileSectionType", id: "4", label: t("detectedIn.inventoryCompare") },
    { type: "fileSectionType", id: "9", label: t("detectedIn.intervaultMovement") },
];
