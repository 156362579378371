const styles = {
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    recordCount: {
        padding: "16px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
};
export default styles;
