import { Box, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./ExcessRecordModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const ExcessRecordModal = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title={t("buttonLabels.confirm")}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {t("buttonLabels.ok")}
                    </Button>
                </Box>
            }
        >
            <Typography sx={styles.textDescription}>
                <Trans
                    i18nKey={"searchOpenMedia.excessRecordsToDisplay"}
                    values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                />
            </Typography>
        </SecureSyncModal>
    );
};

export default ExcessRecordModal;
