const styles = {
    notificationContainer: {
        px: { xs: 3, sm: 5 },
        bgcolor: "#F7F7FE",
        paddingBottom: "20px",
        marginBottom: "20px",
    },
    title: {
        color: "#14477D",
        fontSize: "26px",
        fontWeight: 400,
        lineHeight: "123.5%",
        letterSpacing: "0.25px",
        paddingTop: "20px",
        paddingBottom: "30px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: "30px",
    },
    button: (selectedButton) => ({
        mr: 4,
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "131%",
        letterSpacing: "0.28px",
        backgroundColor: selectedButton === "todo" ? "#d32f2f" : "transparent",
        color: selectedButton === "todo" ? "#fff" : "#58595B",
        borderColor: "#d32f2f",
        "&:hover": {
            borderRadius: "30px",
            backgroundColor: selectedButton === "todo" ? "#b71c1c" : "rgba(211, 47, 47, 0.04)",
            borderColor: "#b71c1c",
        },
    }),
    buttonReports: (selectedButton) => ({
        mr: 4,
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "131%",
        letterSpacing: "0.28px",
        color: selectedButton === "reports" ? "#fff" : "#58595B",
        "&:hover": {
            borderRadius: "30px",
        },
    }),
    accordionContainer: {
        paddingX: "70px",
        paddingBottom: "30px",
    },
    accordionTop: {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    accordionBottom: {
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
    },
    accordionDetails: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    accordionTitle: {
        color: "#000000",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
    dataGrid: {
        height: 400,
        width: "100%",
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            color: "black",
        },
    },
    listItem: {
        display: "inline-block",
        width: "5px",
        height: "5px",
        borderRadius: "100%",
        backgroundColor: "black",
        marginRight: "5px",
    },
    list: {
        listStyle: "circle",
        color: "black",
        "& .MuiTypography-root": { fontSize: "inherit" },
    },
};

export default styles;
