import { Box, Button, FormHelperText, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Star } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { useHotkeysContext } from "react-hotkeys-hook";
import styles from "./CustomMediaIdentifierModal.styles";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import Pencil from "src/components/Icons/Pencil/Pencil";
import { enterKeyCode } from "src/constants/processMediaConstants";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { hotKeysScopes } from "src/constants/shortCutKeys";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const CustomMediaIdentifierBody = ({
    cmiLabels,
    cmiListObj,
    setCMIList,
    existingCMILabels,
    isDuplicateValuesThere,
    resetter,
}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [selectedCMIValue, setSelectedCMIValue] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const isEditMode = selectedRow !== null;
    const onClickUpdate = () => {
        const newCMIListObj = [...cmiListObj];

        newCMIListObj[selectedRow] = {
            id: `cmi-${selectedRow}`,
            cmiLabel: selectedCMIValue,
            isEdited: cmiLabels[selectedRow] !== selectedCMIValue,
            isDuplicate: false,
        };

        const labelList = [...existingCMILabels];
        const updatedCMIListObj = newCMIListObj.map((cMIItem) => {
            const isThere = labelList.includes(cMIItem.cmiLabel);
            if (cMIItem.cmiLabel && !isThere) {
                labelList.push(cMIItem.cmiLabel);
            }
            return { ...cMIItem, isDuplicate: isThere };
        });

        setCMIList(updatedCMIListObj);
        setSelectedRow(null);
        setSelectedCMIValue("");
    };

    const onClickEdit = ({ rowIndex, cmiLabel }) => {
        setSelectedRow(rowIndex);
        setSelectedCMIValue(cmiLabel);
    };

    useEffect(() => {
        setSelectedRow(null);
        setSelectedCMIValue("");
    }, [resetter]);

    const columns = useMemo(() => {
        return [
            {
                //This field not used
                field: "id",
                headerName: "",
                width: 60,
                renderCell: (params) => {
                    const isEditing = selectedRow === params.rowIndex;
                    const isEdited = params.isEdited;
                    return (
                        <>
                            {isEditing ? (
                                <DoneIcon sx={styles.iconColor} />
                            ) : (
                                <IconButton onClick={() => onClickEdit(params)}>
                                    <Pencil
                                        fill={
                                            isEdited ? theme.palette.im_orange["500"] : theme.palette.im_mid_blue["500"]
                                        }
                                    />
                                </IconButton>
                            )}
                        </>
                    );
                },
            },
            {
                //This field not used
                field: "isDuplicate",
                headerName: convertToLocaleUpperCase(t("tableHeader.duplicate")),
                renderCell: (params) => {
                    return <>{params.isDuplicate ? <Star sx={styles.starIcon} /> : <Box></Box>}</>;
                },
            },
            {
                field: "cmiLabel",
                headerName: t("titles.customMediaIdentifier").toLocaleUpperCase(i18n.language),
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, setSelectedRow]);

    const enterToAddScan = (event) => {
        if (event.keyCode === enterKeyCode) {
            onClickUpdate();
        }
    };

    return (
        <>
            {Boolean(isDuplicateValuesThere) && (
                <SecureSyncAlert message={t("messages.duplicateCMILabels")} severity="error" />
            )}
            <SecureSyncTableCard>
                <Box sx={styles.tableCaptionWrapper}>
                    {isEditMode && (
                        <Box sx={styles.editField}>
                            <Box>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label={t("titles.customMediaIdentifier")}
                                    value={selectedCMIValue}
                                    name="containerQuantity"
                                    inputProps={{ maxLength: 20 }}
                                    onChange={(e) => setSelectedCMIValue(e.target.value)}
                                    onKeyDown={enterToAddScan}
                                />
                                <Box sx={styles.marginLeft}>
                                    <FormHelperText>{t("common.updateOrEnter")}</FormHelperText>
                                </Box>
                            </Box>
                            <Button variant="outlined" size="medium" onClick={onClickUpdate}>
                                <Trans i18nKey={"buttonLabels.update"} />
                            </Button>
                        </Box>
                    )}
                    {cmiListObj.length > 0 && !isEditMode && (
                        <Typography variant="body1">
                            <Trans
                                i18nKey={"newContainerLockInsert.noOfContainers"}
                                values={{ containerLength: cmiListObj.length }}
                            />
                        </Typography>
                    )}
                </Box>
                <SecureSyncTable
                    selectedRecordId={`cmi-${selectedRow}`}
                    rows={cmiListObj}
                    columns={columns}
                    tableMinWidth="100px"
                    noDataMessage={t("messages.noRecordsFound")}
                    disablePagination={true}
                    headerHeight="36px"
                    rowHeight="32px"
                />
            </SecureSyncTableCard>
        </>
    );
};

const useShortCutKeys = (handleSave, onResetModal, show) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: handleSave,
        options: {
            scopes: hotKeysScopes.newContainerLockInsertCMIModal,
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: onResetModal,
        options: {
            scopes: hotKeysScopes.newContainerLockInsertCMIModal,
        },
    });
    const { enableScope, disableScope } = useHotkeysContext();
    useEffect(() => {
        if (show) {
            disableScope(hotKeysScopes.newContainerLockInsert);
            enableScope(hotKeysScopes.newContainerLockInsertCMIModal);
        } else {
            disableScope(hotKeysScopes.newContainerLockInsertCMIModal);
            enableScope(hotKeysScopes.newContainerLockInsert);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
};

const CustomMediaIdentifierModal = ({ show, onClose, records, onSave, existingCMILabels }) => {
    const shortCutKeys = getShortCutKeys();
    const [cmiListObj, setCMIListObj] = useState([]);
    const [resetter, setResetter] = useState(0);
    useShortCutKeys(
        () => handleSave(),
        () => onResetModal(),
        show
    );
    const { t } = useTranslation();
    const handleSave = () => {
        const cmiLabels = cmiListObj.map((item) => item.cmiLabel);
        onSave(cmiLabels);
        onClose();
    };

    const generateCMIListObject = useCallback(() => {
        const newCMIListObj = records.map((cmiLabel, index) => ({
            id: `cmi-${index}`,
            isDuplicate: cmiLabel && existingCMILabels.includes(cmiLabel),
            isEdited: false,
            cmiLabel: cmiLabel,
        }));
        setCMIListObj(newCMIListObj);
    }, [existingCMILabels, records]);

    useEffect(() => {
        generateCMIListObject();
    }, [generateCMIListObject]);

    const isDuplicateValuesThere = useMemo(() => cmiListObj.find((item) => item.isDuplicate), [cmiListObj]);

    const onResetModal = () => {
        setResetter(resetter + 1);
        generateCMIListObject();
    };
    return (
        <SecureSyncModal
            title={t("titles.customMediaIdentifier")}
            open={show}
            maxWidth={"sm"}
            fullWidth
            footer={
                <Box sx={styles.footerBtnSection}>
                    <Button variant="outlined" onClick={onClose}>
                        {t("buttonLabels.cancel")}
                    </Button>
                    <Button variant="outlined" onClick={onResetModal} title={getKeyBoardLabel(shortCutKeys.reset)}>
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        disabled={Boolean(isDuplicateValuesThere)}
                        variant="contained"
                        onClick={handleSave}
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        sx={styles.buttonSubmit}
                    >
                        {t("buttonLabels.submit")}
                    </Button>
                </Box>
            }
        >
            <CustomMediaIdentifierBody
                isDuplicateValuesThere={isDuplicateValuesThere}
                existingCMILabels={existingCMILabels}
                cmiLabels={records}
                cmiListObj={cmiListObj}
                setCMIList={setCMIListObj}
                resetter={resetter}
            />
        </SecureSyncModal>
    );
};

export default CustomMediaIdentifierModal;
