import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import styles from "./MediaDestructionInformation.styles";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { formatDate, getMinAndMaxDate } from "src/utils/dateHelper";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { destructRequestStatusIds, destructRequestTypeIds } from "src/constants/requestConstants";
import { dateFormats } from "src/constants/dateFormatConstants";

const MediaDestructionInformation = ({ mediaDestructionDetails }) => {
    const { t } = useTranslation();

    const destructRequestTypeId = mediaDestructionDetails[0]?.destruct_request_type_id;
    const destructRequestStatusId = mediaDestructionDetails[0]?.destruct_request_status_id;
    const certifiedLabelVisible =
        destructRequestStatusId === destructRequestStatusIds.completed &&
        (destructRequestTypeId === destructRequestTypeIds.pickUp ||
            destructRequestTypeId === destructRequestTypeIds.vaultItemized);
    const authorizeInfo =
        mediaDestructionDetails[0]?.authorized_datetime_ltz ||
        mediaDestructionDetails[0]?.rejected_datetime_ltz ||
        mediaDestructionDetails[0]?.cancelled_datetime_ltz;
    const { minDateForApi: minServiceDate } = getMinAndMaxDate();
    const certifiedDate = dayjs(mediaDestructionDetails[0]?.certified_datetime_ltz).isAfter(minServiceDate)
        ? formatDate(mediaDestructionDetails[0]?.certified_datetime_ltz)
        : " ";

    return (
        <>
            <Box sx={styles.container}>
                <Typography>{t("mediaDestructionRequestDetail.mediaDestructionRequestDetailDesc")}</Typography>
            </Box>
            <Box sx={styles.reqDetailsContainer}>
                <Box sx={styles.reqDetailRow}>
                    <SecureSyncReadOnlyTextField
                        value={mediaDestructionDetails[0]?.destruct_request_status_descr || " "}
                        label={t("common.status")}
                    />
                    {certifiedLabelVisible && (
                        <SecureSyncReadOnlyTextField value={certifiedDate} label={t("formFields.certifiedDate")} />
                    )}
                </Box>
            </Box>
            <Divider />
            <PageSubTitle>{t("titles.requestInformation")}</PageSubTitle>
            <Box sx={styles.rowGrid}>
                <SecureSyncReadOnlyTextField
                    value={mediaDestructionDetails[0]?.requested_personnel_name || " "}
                    label={t("common.requestedBy")}
                />
                <SecureSyncReadOnlyTextField
                    value={
                        formatDate(
                            mediaDestructionDetails[0]?.requested_datetime_ltz,
                            dateFormats.localized24HFormat
                        ) || " "
                    }
                    label={t("common.loggedDate")}
                />
                <Box sx={styles.twoGrid}>
                    <SecureSyncReadOnlyTextField
                        value={mediaDestructionDetails[0]?.requested_person_email_addr || " "}
                        label={t("formFields.notifyViaEmail")}
                    />
                </Box>
            </Box>
            {authorizeInfo && (
                <Box sx={styles.rowGrid}>
                    {mediaDestructionDetails[0]?.authorized_datetime_ltz && (
                        <>
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails[0]?.authorized_personnel_name || " "}
                                label={t("formFields.authorizedBy")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={
                                    formatDate(
                                        mediaDestructionDetails[0]?.authorized_datetime_ltz,
                                        dateFormats.localized24HFormat
                                    ) || " "
                                }
                                label={t("formFields.authorizedDate")}
                            />
                        </>
                    )}
                    {mediaDestructionDetails[0]?.rejected_datetime_ltz && (
                        <>
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails[0]?.rejected_personnel_name || " "}
                                label={t("formFields.rejectedBy")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={
                                    formatDate(
                                        mediaDestructionDetails[0]?.rejected_datetime_ltz,
                                        dateFormats.localized24HFormat
                                    ) || " "
                                }
                                label={t("formFields.rejectedDate")}
                            />
                        </>
                    )}
                    {mediaDestructionDetails[0]?.cancelled_datetime_ltz && (
                        <>
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails[0]?.cancelled_personnel_name || " "}
                                label={t("formFields.cancelledBy")}
                            />
                            <SecureSyncReadOnlyTextField
                                value={formatDate(mediaDestructionDetails[0]?.cancelled_datetime_ltz) || " "}
                                label={t("formFields.cancelledDate")}
                            />
                        </>
                    )}
                </Box>
            )}
            {mediaDestructionDetails[0]?.destruct_request_type_id === destructRequestTypeIds.vaultItemized &&
                mediaDestructionDetails[0]?.im_comment && (
                    <Box sx={styles.reqDetailRow}>
                        <SecureSyncReadOnlyTextField
                            value={mediaDestructionDetails[0]?.im_comment || " "}
                            label={t("formFields.ironMountainComments")}
                            isMultiline={true}
                        />
                    </Box>
                )}

            <Divider />
        </>
    );
};

export default MediaDestructionInformation;
