import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Paper,
    SvgIcon,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./AuthorizationsVerificationSection.styles";
import { getARVListService } from "src/services/homeService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getAllowedCustomers, getSelectedCustomer, updateSelectedCustomer } from "src/redux/userDetailsSlice";
import { userVerification } from "src/constants/routeConstants/administrationRouterFullPaths";

const AuthorizationsVerificationSection = (props) => {
    const navigate = useNavigate();
    const [aRVCount, setARVCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [rows, setRow] = useState([]);
    const dispatch = useDispatch();
    const theme = useTheme();
    const allowedCustomers = useSelector(getAllowedCustomers);
    const selectedCustomer = useSelector(getSelectedCustomer);

    useEffect(() => {
        setARVCount(props?.count ? props.count : 0);
        setRow([]);
    }, [props?.count]);

    const getARVList = async (panel, isExpanded) => {
        setLoading(true);
        try {
            if (isExpanded && aRVCount !== 0) {
                const arvList = await getARVListService();
                setRow(arvList);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const navigateToUserVerification = (el) => {
        if (selectedCustomer.customer_id !== el.customerId) {
            dispatch(
                updateSelectedCustomer({
                    selectedCustomer: allowedCustomers?.find((customer) => customer.customer_id === el.customerId),
                })
            );
        }
        const path = `${userVerification}?currentCustomerOnly=true`;
        navigate(path);
    };

    const columns = [
        {
            field: "edit",
            headerName: "",
            width: 60,
            sortable: false,
            renderCell: (param) => (
                <Box>
                    <IconButton onClick={() => navigateToUserVerification(param)}>
                        <SvgIcon fill={theme.palette.primary.main} component={RemoveRedEyeFilled} />
                    </IconButton>
                </Box>
            ),
        },
        { field: "customer", headerName: t("homeModule.Customer"), flex: 1, sortable: true },
        { field: "name", headerName: t("homeModule.Name"), flex: 1, sortable: true },
        { field: "branch", headerName: t("homeModule.Branch"), flex: 1, sortable: true },
        { field: "count", headerName: t("homeModule.Count"), flex: 1, sortable: true },
    ];

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Accordion onChange={getARVList} sx={styles.accord}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="authorization-verification-content"
                    id="authorization-verification"
                >
                    <Typography variant="body1" sx={styles.headerTypography}>
                        {t("homeModule.AuthorizationsRequiringVerification")} ({aRVCount})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Typography variant="body1" sx={styles.bodyTypography}>
                            {t("homeModule.TheFollowingAccountsHaveUsersThatRequireSecurityUpdatesOrVerification")}
                        </Typography>
                        <Box style={styles.tableBox}>
                            <Paper>
                                <SecureSyncTable
                                    propStyles={styles.dataGrid}
                                    rows={rows}
                                    columns={columns}
                                    noDataMessage={t("messages.noRecordsFound")}
                                    getRowId={(row) => row.customerId}
                                    sx={styles.dataGrid}
                                    initialOrder="decs"
                                />
                            </Paper>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

AuthorizationsVerificationSection.propTypes = {
    count: PropTypes.number,
};

export default AuthorizationsVerificationSection;
