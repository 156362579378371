import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./TransactionHistoryContainer.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";

import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const TransactionHistoryContainer = ({ requests, customerNumber }) => {
    const { t } = useTranslation();
    const columnsInBound = [
        {
            field: "dateIn",
            headerName: convertToLocaleUpperCase(t("tableHeader.dateIn")),
            width: 20,
        },
    ];
    const columnsOutBound = [
        {
            field: "deliveryDate",
            headerName: convertToLocaleUpperCase(t("tableHeader.deliveryDate")),
            width: 60,
        },
        {
            field: "transportContainer",
            headerName: convertToLocaleUpperCase(t("tableHeader.transportContainer")),
            width: 60,
        },
        {
            field: "requestType",
            headerName: convertToLocaleUpperCase(t("tableHeader.requestType")),
            width: 60,
        },
        {
            field: "requestId",
            headerName: convertToLocaleUpperCase(t("tableHeader.requestId")),
            width: 60,
        },
    ];
    return (
        <Box sx={styles.boxPadding}>
            <Box>
                <PageSubTitle>{t("titles.openMediaDetailsTableHeader")}</PageSubTitle>
            </Box>
            <Box sx={styles.tableContainer}>
                <SecureSyncTableCard>
                    <Box sx={styles.tableHeaderContainer}>
                        <Typography sx={styles.tableTitle}>
                            <Trans i18nKey="openMediaDetails.inBoundLabel"></Trans>
                        </Typography>
                    </Box>
                    <SecureSyncTable
                        rows={requests}
                        tableMinWidth="175px"
                        columns={columnsInBound}
                        getRowId={(row) => row.dateIn}
                    />
                </SecureSyncTableCard>

                <SecureSyncTableCard>
                    <Box sx={styles.tableHeaderContainer}>
                        <Typography sx={styles.tableTitle}>
                            <Trans
                                i18nKey="openMediaDetails.outBoundLabel"
                                values={{
                                    value: customerNumber,
                                }}
                            ></Trans>
                        </Typography>
                    </Box>
                    <SecureSyncTable rows={requests} columns={columnsOutBound} getRowId={(row) => row.Request_Id} />
                </SecureSyncTableCard>
            </Box>
        </Box>
    );
};
export default TransactionHistoryContainer;
