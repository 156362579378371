import { Box, CircularProgress, Fade, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./CircularLoaderFullPage.styles";

const CircularLoaderFullPage = (props) => {
    const { show, message } = props;
    return (
        <Modal open={Boolean(show)} disableRestoreFocus>
            <Box sx={styles.root}>
                <Fade in={true} unmountOnExit>
                    <Box sx={styles.loader}>
                        <Typography variant="h5" sx={styles.typography}>
                            {message}
                        </Typography>
                        <CircularProgress color="primary" />
                    </Box>
                </Fade>
            </Box>
        </Modal>
    );
};

CircularLoaderFullPage.propTypes = {
    show: PropTypes.bool,
};

export default CircularLoaderFullPage;
