const styles = {
    linkIcon: {
        width: 22,
        height: 22,
        marginRight: 8,
    },
    textStyle: {
        textDecoration: "underline",
    },
    link: {
        display: "flex",
        alignItems: "center",
        color: "info.main",
        mb: 1.5,
    },
};

export default styles;
