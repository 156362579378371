import { Box, Button, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styles from "./InboundOutBoundReports.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { dateFormats } from "src/constants/dateFormatConstants";
import {
    formatDate,
    getCurrentDateTime,
    getDisplayFormat,
    getMinAndMaxDate,
    startOfTheDate,
} from "src/utils/dateHelper";
import { sortHelper } from "src/utils/sortHelper";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { noFlag, systemId } from "src/constants/serviceConstants";
import { getInboundOutBoundGlobalAttrs } from "src/utils/researchHelper";
import { getReportsDropDown } from "src/services/researchService";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import {
    inboundOutboundDetailIMEReport,
    inboundOutboundDetailReport,
    inboundOutboundSummaryImeReport,
    inboundOutboundSummaryReport,
} from "src/services/printServices";
import { targetUrl } from "src/constants/printConstants";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t }) => {
    const { minDateForApi: displayMinDate } = getMinAndMaxDate();
    const maxDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const displayMaxDate = formatDate(dayjs());
    return Yup.object().shape({
        reportingPeriodFrom: Yup.string().test("validateServiceDateFrom", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isToDateValid = dayjs(parent.serviceDateTo, getDisplayFormat()).isValid();
            const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                dayjs(parent.serviceDateTo, getDisplayFormat())
            );
            const withInOneMonth = dayjs(value).isBefore(dayjs(parent.reportingPeriodTo).subtract(1, "month"));
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateValidationMsg", {
                        fromDate: formatDate(parent.serviceDateTo),
                    });
                } else if (withInOneMonth) {
                    message = t("messages.theReportingPeriodCannotBeGreaterThanOneMonth");
                }
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        reportingPeriodTo: Yup.string().test("validateServiceDateTo", (value, { createError, path }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(maxDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.inboundToDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const InboundOutBoundReports = () => {
    const shortCutKeys = getShortCutKeys();
    const [reportDropDown, setReportDropDown] = useState([]);
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalProps, setPrintModalProps] = useState({ show: false, report: "" });
    const globalAttributes = useGetGlobalAttrs(systemId.id50, "50");
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleReset() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    const { t } = useTranslation();
    const globalAttr = useMemo(() => {
        return getInboundOutBoundGlobalAttrs(globalAttributes?.data?.rs0);
    }, [globalAttributes]);

    const mediaOption = [
        { id: "all", value: "A", label: convertToLocaleUpperCase(t("common.all")) },
        { id: "container", value: "C", label: t("common.container") },
        {
            id: "openMedia",
            value: "M",
            label: t("common.openMedia"),
        },
    ];
    const [isLoading, setLoading] = useState(false);

    const getReportDropDownData = async () => {
        const { reportDropDown } = await getReportsDropDown();
        if (reportDropDown) {
            const sortedReportDropDown = sortHelper({
                data: reportDropDown,
                primaryProperty: "display_order",
                sortOrder: "asc",
            });
            const newReportDropDown = sortedReportDropDown.map((reportDropDown) => ({
                id: reportDropDown.dropdown_list_id,
                value: reportDropDown.dropdown_list_id,
                label: reportDropDown.descr,
            }));
            setReportDropDown(newReportDropDown);
            setFieldValue("reports", newReportDropDown[0]?.value);
        }
    };

    const setInitialValues = async () => {
        const todayDate = getCurrentDateTime();
        setValues({
            ...values,
            reports: reportDropDown[0]?.value,
            reportingPeriodFrom: formatDate(todayDate),
            reportingPeriodTo: formatDate(todayDate),
            media: mediaOption[0]?.value,
            includeMediaNumber: false,
        });
    };

    useEffect(() => {
        setLoading(true);
        getReportDropDownData();
        setInitialValues();
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values,
    } = useFormik({
        initialValues: {
            reports: "",
            media: "",
            reportingPeriodFrom: "",
            reportingPeriodTo: "",
            includeMediaNumber: false,
        },
        validationSchema: validationSchema({ t }),
        onSubmit: () => printReport(),
    });

    const handleReset = () => {
        resetForm();
        setInitialValues();
    };

    const printReport = () => {
        let payload = null,
            target = "";

        if (globalAttr?.allowCustomerMediaIdentifiers) {
            if (values.includeMediaNumber) {
                // rp_inbound_outbound_detail2.rpt
                payload = inboundOutboundDetailReport({
                    dateFrom: startOfTheDate(values.reportingPeriodFrom),
                    dateTo: startOfTheDate(values.reportingPeriodTo),
                    programType: values.media,
                    labelReportTitle: t("reports.inboundOutboundSummaryReport.labelReportTitle"),
                });
                target = targetUrl.inboundOutboundDetailReport;
            } else {
                //rp_inbound_outbound_summary2.rpt
                payload = inboundOutboundSummaryReport({
                    programType: values.media,
                    dateFrom: startOfTheDate(values.reportingPeriodFrom),
                    dateTo: startOfTheDate(values.reportingPeriodTo),
                    limitRows: noFlag,
                    detailFlag: noFlag,
                    labelReportTitle: t("reports.inboundOutboundSummaryReport.labelReportSummaryTitle"),
                });
                target = targetUrl.inboundOutboundSummaryReport;
            }
        } else {
            if (values.includeMediaNumber) {
                // rp_inbound_outbound_detail_IME.rpt
                payload = inboundOutboundDetailIMEReport({
                    dateFrom: startOfTheDate(values.reportingPeriodFrom),
                    dateTo: startOfTheDate(values.reportingPeriodTo),
                    programType: values.media,
                    labelReportTitle: t("reports.inboundOutboundSummaryReport.labelReportTitle"),
                });
                target = targetUrl.inboundOutboundDetailImeReport;
            } else {
                // rp_inbound_outbound_summary_IME.rpt
                payload = inboundOutboundSummaryImeReport({
                    programType: values.media,
                    dateFrom: startOfTheDate(values.reportingPeriodFrom),
                    dateTo: startOfTheDate(values.reportingPeriodTo),
                    limitRows: noFlag,
                    detailFlag: noFlag,
                    labelReportTitle: t("reports.inboundOutboundSummaryReport.labelReportSummaryTitle"),
                });
                target = targetUrl.inboundOutboundSummaryImeReport;
            }
        }

        setPrintModalProps({ show: true, report: target });
        setPrintPayload(payload);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading || globalAttributes.isLoading} />
            <form noValidate onSubmit={handleSubmit}>
                <Box sx={styles.bottomPadding}>
                    <PageTitle>{t("titles.inboundOutboundReport")}</PageTitle>
                    <Typography sx={styles.bottomPadding}>
                        <Trans
                            i18nKey={"inboundOutboundReports.description"}
                            values={{ buttonLabel: t("buttonLabels.prepareReport") }}
                        />
                    </Typography>
                    <Box sx={styles.gridDisplay}>
                        <SecureSyncSelect
                            label={t("formFields.media")}
                            options={reportDropDown}
                            name="reports"
                            value={values.reports}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box sx={styles.bottomPadding}>
                    <PageSubTitle>{t("inboundOutboundReports.reportCriteria")}</PageSubTitle>
                    <Box sx={styles.gridDisplay}>
                        <SecureSyncSelect
                            label={t("formFields.media")}
                            options={mediaOption}
                            name="media"
                            value={values.media}
                            onChange={handleChange}
                        />
                        <SecureSyncDatePicker
                            name="reportingPeriodFrom"
                            required
                            value={values.reportingPeriodFrom}
                            label={t("formFields.reportingPeriodFrom")}
                            helperText={touched.reportingPeriodFrom && errors.reportingPeriodFrom}
                            error={Boolean(touched.reportingPeriodFrom && errors.reportingPeriodFrom)}
                            onChange={async (value) => {
                                await setFieldValue("reportingPeriodFrom", value);
                                setFieldTouched("reportingPeriodFrom", true, true);
                            }}
                            disableFuture
                            onBlur={handleBlur}
                        />
                        <SecureSyncDatePicker
                            name="reportingPeriodTo"
                            value={values.reportingPeriodTo}
                            label={t("formFields.reportingPeriodTo")}
                            required
                            helperText={touched.reportingPeriodTo && errors.reportingPeriodTo}
                            error={Boolean(touched.reportingPeriodTo && errors.reportingPeriodTo)}
                            onChange={async (value) => {
                                await setFieldValue("reportingPeriodTo", value);
                                setFieldTouched("reportingPeriodTo", true, true);
                            }}
                            disableFuture
                            onBlur={handleBlur}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                        alignItems="flex-start"
                        sx={styles.toggleButton}
                    >
                        <Box>
                            <FormControlLabel
                                control={<Switch />}
                                label={
                                    <Trans
                                        i18nKey={
                                            globalAttr?.allowCustomerMediaIdentifiers
                                                ? t("inboundOutboundReports.includeMediaNumberDetails")
                                                : t(
                                                      "inboundOutboundReports.includeCustomerMediaIdentifierMediaNumberDetails"
                                                  )
                                        }
                                        components={{ sup: <sup /> }}
                                        values={{ number: 1 }}
                                    />
                                }
                                checked={values.includeMediaNumber}
                                onChange={handleChange}
                                name="includeMediaNumber"
                            />
                            <Typography variant="body1" component="div" sx={styles.toggleTypography}>
                                <Trans i18nKey={t("common.toggleOnOff")} />
                                <br />
                                <Trans
                                    i18nKey={t("inboundOutboundReports.reportGeneration")}
                                    components={{ sup: <sup /> }}
                                    values={{ number: 1 }}
                                />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button
                        id="reset"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            handleReset();
                        }}
                        title={getKeyBoardLabel(shortCutKeys.reset)}
                    >
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        id="find-all"
                        variant="contained"
                        color="primary"
                        type="submit"
                        title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                    >
                        {t("buttonLabels.prepareReport")}
                    </Button>
                </Box>
            </form>

            <SecureSyncPrintModal
                open={printModalProps.show}
                payload={printPayload}
                onClose={() => setPrintModalProps({ show: false, report: printModalProps.report })}
                targetReport={printModalProps.report}
            />
        </>
    );
};

export default InboundOutBoundReports;
