import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./OpenMediaExceptionDetailsTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { formatDate, getDisplayFormat, openMediaOtherExceptionsDetectedInDateTime } from "src/utils/dateHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const OpenMediaExceptionDetailsTable = ({ resultData = [], detectedIn }) => {
    const { t } = useTranslation();
    const columns = [
        {
            field: "volser",
            headerName: `${convertToLocaleUpperCase(t("tableHeader.media"))} #`,
            sortable: true,
        },
        {
            field: "detected DateTime",
            headerName: convertToLocaleUpperCase(t("tableHeader.detectedDatetime")),
            sortable: true,
            renderCell: (params) => {
                return <>{openMediaOtherExceptionsDetectedInDateTime(params.detected_datetime)}</>;
            },
        },
        {
            field: "task",
            headerName: convertToLocaleUpperCase(t("tableHeader.task")),
            sortable: true,
            renderCell: (params) => {
                return <>{`${detectedIn} ${formatDate(params.task_datetime)}`}</>;
            },
        },
        {
            field: "logical_vault_code",
            headerName: convertToLocaleUpperCase(t("tableHeader.logicalVault")),
            sortable: true,
        },
        {
            field: "requestType",
            headerName: convertToLocaleUpperCase(t("tableHeader.requestType")),
            sortable: true,
            renderCell: (params) => {
                return <>{params.request_type_id ? t(`requestTypes.${params.request_type_id}`) : ""}</>;
            },
        },
        {
            field: "serviceDate",
            headerName: convertToLocaleUpperCase(t("tableHeader.serviceData")),
            sortable: true,
            renderCell: (params) => {
                return <>{formatDate(params.service_date, getDisplayFormat(), true)}</>;
            },
        },
        {
            field: "additional_information",
            headerName: convertToLocaleUpperCase(t("tableHeader.additionalInformation")),
            sortable: true,
        },
        {
            field: "car_id",
            headerName: convertToLocaleUpperCase(t("tableHeader.carId")),
            sortable: true,
        },
    ];

    return (
        <SecureSyncTableCard>
            <Box sx={styles.recordCount}>
                {resultData.length > 0 ? (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: resultData.length })}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.recordStart} />
                )}
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={resultData}
                    columns={columns}
                    getRowId={(row) => row.id}
                    noDataMessage={<Trans i18nKey={"messages.noRecordsFound"} />}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default OpenMediaExceptionDetailsTable;
