import { useTheme } from "@mui/material";

const UploadIcon = ({ fill, ...props }) => {
    const theme = useTheme();
    const defaultColor = theme.palette.background.default;
    const iconFill = fill || defaultColor;
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.3496 5.3167C12.4746 5.4417 12.633 5.50003 12.7913 5.50003C12.9496 5.50003 13.1163 5.4417 13.233 5.3167C13.4746 5.07503 13.4746 4.67503 13.233 4.43337L10.4413 1.6417C10.1996 1.40003 9.79963 1.40003 9.55796 1.6417L6.76629 4.43337C6.52463 4.67503 6.52463 5.07503 6.76629 5.3167C7.00796 5.55837 7.40796 5.55837 7.64963 5.3167L9.37476 3.59157V12.5C9.37476 12.8416 9.65809 13.125 9.99976 13.125C10.3414 13.125 10.6248 12.8416 10.6248 12.5V3.59183L12.3496 5.3167Z"
                fill={iconFill}
            />
            <path
                d="M12.7164 18.5418H7.28307C3.19141 18.5418 1.44141 16.7918 1.44141 12.7001V12.5918C1.44141 8.89177 2.89974 7.10844 6.16641 6.8001C6.49974 6.7751 6.81641 7.0251 6.84974 7.36677C6.88307 7.70844 6.63307 8.01677 6.28307 8.0501C3.66641 8.29177 2.69141 9.5251 2.69141 12.6001V12.7084C2.69141 16.1001 3.89141 17.3001 7.28307 17.3001H12.7164C16.1081 17.3001 17.3081 16.1001 17.3081 12.7084V12.6001C17.3081 9.50844 16.3164 8.2751 13.6497 8.0501C13.3081 8.01677 13.0497 7.71677 13.0831 7.3751C13.1164 7.03344 13.4081 6.7751 13.7581 6.80844C17.0747 7.09177 18.5581 8.88344 18.5581 12.6084V12.7168C18.5581 16.7918 16.8081 18.5418 12.7164 18.5418Z"
                fill={iconFill}
            />
        </svg>
    );
};

export default UploadIcon;
