import { getPerformance, trace } from "firebase/performance";
import { firebaseApp } from "./firebase";
import { isPerformanceMonitoringEnabled } from "./utils";

// This is to To track the application performance in firebase SDK
const firebasePerf = getPerformance(firebaseApp);
// This is enabled based on the firebase collection value
firebasePerf.instrumentationEnabled = false;
firebasePerf.dataCollectionEnabled = false;

export const initializePerformanceMonitoring = async () => {
    const isEnabled = await isPerformanceMonitoringEnabled();
    if (isEnabled) {
        firebasePerf.instrumentationEnabled = true;
        firebasePerf.dataCollectionEnabled = true;
    }
};
export const traceWrapper = (traceId) => {
    const t = trace(firebasePerf, traceId);

    return { traceStart: () => t.start(), traceStop: () => t.stop(), trace: t };
};
