import {
    constructFbHeaders,
    getCustomers,
    getLoggedUserDetails,
    getPersonalName,
    getPersonId,
    getResultSets,
    getSelectedCustomerDb,
    invokeApi,
} from "./utils";
import { insertLoggedEvent } from "./processMediaService";
import { traceWrapper } from "./firebasePerformance";
import { defaultDistrictId, feedbackEmailGroupCode } from "src/constants/serviceConstants";

const apiUrls = {
    addFeedback: "/sscommon/addfeedback",
    getEmailGroupAddress: "/sscommon/getemailgroupaddress",
    masterXpSendMail: "/sscommon/masterxpsendmail",
};

export const submitFeedback = async (comment, feedbackTime) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.addFeedback);
    traceStart();
    const headers = constructFbHeaders();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/addfeedback";
    const body = {
        main_district_id: "50",
        feedback_type: "5",
        user_id: getPersonId(),
        feedback_datetime: feedbackTime,
        comment,
        program_id: "",
        Last_mod_user: lastModifiedUser,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resultSetResponse = await getResultSets(res.docId, 2);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const resultsObj = resultSetResponse.results.reduce((prevValue, rs, index) => {
            return { ...prevValue, [`rs${index}`]: rs };
        }, {});
        const feedbackResponse = {
            failure: false,
            ...resultsObj,
        };
        return feedbackResponse;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getRecipientAddress = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getEmailGroupAddress);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/getemailgroupaddress";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        email_group_code: feedbackEmailGroupCode,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resultSetResponse = await getResultSets(res.docId, 2);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const resultsObj = resultSetResponse.results.reduce((prevValue, rs, index) => {
            return { ...prevValue, [`rs${index}`]: rs };
        }, {});
        const response = {
            failure: false,
            ...resultsObj,
        };
        return response;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

const getEmailBody = (feedbackText, date) => {
    const { selectedCustomer = null } = getCustomers();
    const personalName = getPersonalName();

    return `
    ${feedbackText}
    -- ${personalName}
    Customer #: ${selectedCustomer?.customer_number}

    Customer ID: ${selectedCustomer?.customer_id}
    Name: ${personalName}
    Time of message: ${date}
    `;
};

export const sendEmail = async (feedbackText, recipients, emailSubject, submittedTime) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.masterXpSendMail);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/masterxpsendmail";

    const emailBody = getEmailBody(feedbackText, submittedTime);
    const body = {
        main_district_id: defaultDistrictId,
        recipients: recipients,
        message: emailBody,
        query: "",
        attachments: "",
        copy_recipients: "",
        blind_copy_recipients: "",
        subject: emailSubject,
        type: "",
        attach_results: false,
        no_output: false,
        no_header: false,
        width: "",
        separator: "",
        echo_error: false,
        set_user: "",
        dbuse: "",
    };

    try {
        insertLogForEmail(recipients);
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resultSetResponse = await getResultSets(res.docId, 1);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const resultsObj = resultSetResponse.results.reduce((prevValue, rs, index) => {
            return { ...prevValue, [`rs${index}`]: rs };
        }, {});
        const emailResponse = {
            failure: false,
            ...resultsObj,
        };
        return emailResponse;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

const insertLogForEmail = async (recipients) => {
    const subject = "Feedback";
    const comment = `Recipient=${recipients} Subject = ${subject}`;
    const res = await insertLoggedEvent(comment, "Sent a message", "", "2");
    return res;
};
