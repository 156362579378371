import { useState } from "react";
import { Box, Button, FormHelperText, IconButton, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import styles from "./DefineComparisonMediaModal.styles";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { downloadInstructionGuide, handleFileRead } from "src/utils/processMediaHelper";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import DeleteAllConfirmation from "src/components/Operations/DisasterRecovery/ConfirmationModals/DeleteConfirmation/DeleteConfirmation";
import CloseModalConfirmation from "src/components/ProcessMedia/ConfirmationModals/CloseModalConfirmation/CloseModalConfirmation";
import { enterKeyCode, sourceType, getSourceTypeOptions } from "src/constants/processMediaConstants";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { importMediaFileFileName, staticFile } from "src/constants/fileConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const DefineComparisonMediaModal = ({ scanMediaListObj, onClose, onSubmit }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.ok,
        callback: () => {
            handleSubmit();
        },
    });
    const { t } = useTranslation();
    const [mediaType, setMediaType] = useState(sourceType.keyboard);
    const [isLoading, setIsLoading] = useState(false);
    const [fileFormatError, setFileFormatError] = useState({
        fileName: "",
        hasError: false,
        lastRecordCount: "",
    });
    const [mediaTypeList, setMediaTypeList] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCloseModalConfirmation, setShowCloseModalConfirmation] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const columns = [
        {
            field: "delete",
            headerName: "",
            width: "15px",
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        deleteSingleMediaType(params.id);
                    }}
                >
                    <ClearIcon sx={styles.clearIcon} />
                </IconButton>
            ),
        },
        { field: "mediaList", headerName: t("scanVerification.mediaList").toLocaleUpperCase(), sortable: false },
    ];

    const isMediaTypeText = mediaType === sourceType.text;

    const handleMediaTypeChange = (e) => {
        setMediaType(e.target.value);
    };

    const clearFileError = () => {
        setFileFormatError({ fileName: "", hasError: false, lastRecordCount: "" });
    };

    const fileReadHandler = async (event) => {
        setIsLoading(true);
        const { csvData, fileName, hasError } = await handleFileRead(event);
        const uniqueRows = [...new Set(csvData)];
        if (!hasError) {
            uniqueRows.forEach((element) => {
                addMediaType(element);
            });
        }
        setFileFormatError({ fileName: fileName, hasError: hasError, lastRecordCount: uniqueRows.length });
        setIsLoading(false);
    };

    const addMediaType = (value) => {
        setShowErrorAlert(false);
        setMediaTypeList((prevData) => [...prevData, { id: uuidv4(), mediaList: value }]);
    };

    const deleteSingleMediaType = (id) => {
        setMediaTypeList((arr) => arr.filter((val) => val.id !== id));
    };

    const addScanTypeHandler = () => {
        if (Object.keys(errors).length === 0) {
            if (values.scanType) {
                addMediaType(values.scanType);
                setFieldValue("scanType", "");
            }
        }
    };

    const enterToAddScan = (event) => {
        if (event.keyCode === enterKeyCode) {
            addScanTypeHandler();
        }
    };

    const resetModal = () => {
        setMediaType(t("common.textFile"));
        clearFileError();
        setMediaTypeList([]);
        setShowErrorAlert(false);
    };

    const validationSchemaEditMedia = Yup.object().shape({
        scanType: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/, t("omDistribution.invalidCharacters"))
            .max(20, t("omDistribution.maxLength", { charLength: 20 }))
            .test(
                "is-forbidden",
                t("omDistribution.duplicateMediaNo"),
                (value) => ![...mediaTypeList.map((item) => item.mediaList)].includes(value)
            ),
    });

    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            scanType: "",
        },
        validationSchema: validationSchemaEditMedia,
        onSubmit: () => {
            const mediasList = [...scanMediaListObj.mediaList, ...mediaTypeList];
            const statistics = {
                lastScan: mediasList[mediasList.length - 1]?.mediaList || "-",
                totalRecords: mediasList.length,
                mediaList: mediasList,
            };
            onSubmit(statistics);
        },
    });

    const closeHandler = () => {
        if (mediaTypeList.length > 0) {
            setShowCloseModalConfirmation(true);
        } else {
            onClose();
        }
    };

    const closeDeleteAllConfirmation = () => setShowDeleteConfirmation(false);

    const getStatusValue = () => {
        if (isMediaTypeText) {
            if (fileFormatError.fileName && !fileFormatError.hasError) {
                return t("messages.fileSuccessfullyImported", { fileName: fileFormatError.fileName });
            } else {
                if (fileFormatError.hasError) {
                    return t("messages.fileErrorMessage", { fileName: fileFormatError.fileName });
                }
            }
        }
        return " ";
    };

    const getRecordCount = () => {
        const messageKey = fileFormatError.lastRecordCount ? "full" : "partial";
        return t(`messages.lastRecordCountFromTxtFile.${messageKey}`, {
            count: mediaTypeList.length,
            lastCount: fileFormatError.lastRecordCount,
        });
    };

    const downloadInstructionFile = () => {
        setIsLoading(true);
        downloadInstructionGuide(staticFile["acceptableFileFormatForImportMediaFile"], importMediaFileFileName, () => {
            setIsLoading(false);
        });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={true}
                title={t("scanVerification.defineComparisonMedia")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={closeHandler}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button title={getKeyBoardLabel(shortCutKeys.ok)} variant="contained" onClick={handleSubmit}>
                            {t("buttonLabels.ok")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        {showErrorAlert && (
                            <Box sx={styles.marginBottomBrake}>
                                <SecureSyncAlert
                                    message={t("messages.submitEmptyMediaList", {
                                        buttonLabel: t("buttonLabels.cancel"),
                                    })}
                                    severity="error"
                                    onClose={() => {
                                        setShowErrorAlert(false);
                                    }}
                                />
                            </Box>
                        )}
                        {isMediaTypeText ? (
                            <Typography variant="body1" sx={styles.subheader}>
                                <Trans
                                    i18nKey={"common.selectAnImportFile"}
                                    values={{ buttonLabel: t("buttonLabels.ok") }}
                                />{" "}
                            </Typography>
                        ) : (
                            <Typography variant="body1" sx={styles.subheader}>
                                <Trans i18nKey={"omDistribution.keyWedgeScanMediaNumbers"} />
                            </Typography>
                        )}
                        <br />
                        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
                            <SecureSyncSelect
                                required={true}
                                type="outlined"
                                label={t("scanVerification.mediaType")}
                                options={getSourceTypeOptions()}
                                value={mediaType}
                                onChange={handleMediaTypeChange}
                            />
                            <SecureSyncIconTooltip
                                title={
                                    <>
                                        <Typography>
                                            {t("scanVerification.mediaSourceOptions")}
                                            <Box sx={styles.importFileTooltip}>
                                                {t("scanVerification.importATextFile")}
                                            </Box>
                                        </Typography>
                                    </>
                                }
                                placement="right"
                            />
                        </Box>
                        {isMediaTypeText && (
                            <Box>
                                <br />
                                <Typography style={{ fontWeight: 400, fontSize: 12, marginBottom: 15 }}>
                                    {t("common.importFile")} *
                                </Typography>
                                <Box display="flex" alignItems="center" justifyContent="left" gap={2}>
                                    <SecureSyncFileUpload
                                        startIcon={<UploadIcon />}
                                        variant="contained"
                                        onChange={fileReadHandler}
                                        accept=".txt"
                                        name={t("common.uploadFile")}
                                    />
                                    <SecureSyncIconTooltip
                                        title={t("containerPickup.fileFormatHelp")}
                                        placement="right"
                                        onClick={downloadInstructionFile}
                                    />
                                </Box>
                                {fileFormatError.hasError !== true ? (
                                    <Typography sx={styles.footNoteText}>
                                        {t("scanVerification.browseFilesToUploadTxt")}
                                    </Typography>
                                ) : (
                                    <Typography sx={styles.errorText}>{t("messages.importFailedMessage")}</Typography>
                                )}
                            </Box>
                        )}
                        <SecureSyncTableCard>
                            <Box sx={styles.recordCount}>
                                {isMediaTypeText ? (
                                    mediaTypeList.length > 0 ? (
                                        <Box sx={styles.statusRecord}>
                                            <Typography variant="body1">
                                                {t("messages.recordsFound", { count: mediaTypeList.length })}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={styles.statusRecord}>
                                            <Typography variant="body1"></Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box sx={styles.addMediaSection}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label={t("scanVerification.scanTypeHere")}
                                                name="scanType"
                                                focused
                                                required
                                                size="small"
                                                value={values.scanType}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    const regex = /^[a-zA-Z0-9]*$/;
                                                    if (e.target.value.length <= 20 && regex.test(e.target.value)) {
                                                        setFieldValue("scanType", e.target.value);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                error={touched.scanType && Boolean(errors.scanType)}
                                                helperText={touched.scanType && errors.scanType}
                                                onKeyDown={enterToAddScan}
                                            />
                                            <Box sx={styles.marginLeft}>
                                                <FormHelperText>{t("common.addOrEnter")}</FormHelperText>
                                            </Box>
                                        </Box>
                                        <Box sx={styles.addBtnSection}>
                                            <Button variant={"outlined"} onClick={addScanTypeHandler}>
                                                {t("buttonLabels.add")}
                                            </Button>
                                            <SecureSyncIconTooltip
                                                title={
                                                    <Typography>
                                                        {t("omDistribution.howToEnterMedia")}
                                                        <Box fontSize={12}>
                                                            {t("omDistribution.howToEnterMediaDescription")}
                                                        </Box>
                                                    </Typography>
                                                }
                                                placement="right"
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={styles.recordEnd}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            if (mediaTypeList.length > 0) {
                                                setShowDeleteConfirmation(true);
                                            }
                                        }}
                                    >
                                        {t("buttonLabels.deleteAll")}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={styles.tblCardContent}>
                                <SecureSyncTable
                                    rows={mediaTypeList}
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    tableMinWidth="100px"
                                    noDataMessage={t("scanVerification.noMediaIsAvailable")}
                                    disablePagination={true}
                                    headerHeight="36px"
                                    rowHeight="32px"
                                />
                            </Box>
                        </SecureSyncTableCard>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={mediaTypeList[mediaTypeList.length - 1]?.mediaList || " "}
                                label={t("scanVerification.lastItem")}
                            />
                        </Box>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={getStatusValue()}
                                isError={fileFormatError.hasError}
                                label={t("common.status")}
                            />
                        </Box>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={getRecordCount()}
                                label={t("scanVerification.recordCount")}
                            />
                        </Box>
                    </Box>
                </Box>
            </SecureSyncModal>
            <DeleteAllConfirmation
                open={showDeleteConfirmation}
                onClose={closeDeleteAllConfirmation}
                handleConfirm={() => {
                    setMediaTypeList([]);
                    closeDeleteAllConfirmation();
                    clearFileError();
                }}
            />
            <CloseModalConfirmation
                handleConfirm={() => {
                    onClose();
                    resetModal();
                }}
                onClose={() => {
                    setShowCloseModalConfirmation(false);
                }}
                open={showCloseModalConfirmation}
            />
        </>
    );
};

export default DefineComparisonMediaModal;
