import { useMemo, useState } from "react";
import { Box, Button, Typography, SvgIcon, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import styles from "./ListOpenMedia.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { searchOpenMedia } from "src/constants/routeConstants/operationsRouterFullPaths";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getOpenMediaSearchResultsPayload } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getShortCutKeys } from "src/constants/shortCutKeys";
import { useSecureSyncHotKeys } from "src/customHooks";

const ListOpenMedia = ({ mediaList, isSearched, navigateToDetails, values, logicalVaultValue }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <SecureSyncIconTooltip
                        title={t("listEmployees.viewDetails")}
                        icon={
                            <SvgIcon
                                fill={theme.palette.primary.main}
                                component={RemoveRedEyeFilled}
                                onClick={() => navigateToDetails(params)}
                            />
                        }
                    ></SecureSyncIconTooltip>
                ),
            },
            {
                field: "info",
                headerName: convertToLocaleUpperCase(t("tableHeader.info")),
                sortable: false,
                renderCell: (params) => {
                    let iconForInfo = "";
                    let toolTipForInfo = "";
                    if (params.part_of_dr_bit === "true") {
                        iconForInfo = "error";
                        toolTipForInfo = `${t("listOpenMedia.toolTip1")} ${t("listOpenMedia.toolTip2")}`;
                    } else if (params.priority_req_flag === "Y") {
                        iconForInfo = "primary";
                        toolTipForInfo = t("listOpenMedia.toolTip2");
                    } else if (params.in_inventory_flag === "N" && params.delivered_flag === "N") {
                        iconForInfo = "primary";
                        toolTipForInfo = t("listOpenMedia.toolTip3");
                    }
                    if (iconForInfo !== "") {
                        return (
                            <SecureSyncIconTooltip
                                title={toolTipForInfo}
                                icon={<ErrorOutlineOutlinedIcon color={iconForInfo} fontSize="medium" />}
                            ></SecureSyncIconTooltip>
                        );
                    }
                },
            },
            { field: "volser", headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")), sortable: true },
            {
                field: "date_display",
                headerName: convertToLocaleUpperCase(t("tableHeader.returnDateOrCurrentStatus")),
                sortable: true,
            },
            {
                field: "open_media_descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.description")),
                sortable: true,
            },
            {
                field: "in_discrepancy",
                headerName: convertToLocaleUpperCase(t("tableHeader.inDiscrepancy")),
                sortable: true,
                renderCell: (param) => (
                    <Typography>
                        {param.in_discrepancy === "true" && param.display_discrepancy_to_customer_flag === "Y"
                            ? "Yes"
                            : ""}
                    </Typography>
                ),
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(searchOpenMedia, { state: { ...locationState, ...attributes }, replace: true });
    };

    const printReport = () => {
        setPrintPayload(
            getOpenMediaSearchResultsPayload({
                mediaNumber: values?.mediaNumber,
                mediaDescription: values?.mediaDescription,
                drpCode: values?.drpCode,
                returnDateFrom: values?.returnDateFrom,
                returnDateTo: values?.returnDateTo,
                currentStatus: values?.currentStatus,
                logicalVaultCriteria: logicalVaultValue?.label ? logicalVaultValue?.label : " ",
                logicalVault: values?.logicalVault,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            {mediaList.length > 0 && (
                <Typography sx={styles.filterDesc}>
                    <Trans i18nKey={"listOpenMedia.clickEditIconToViewDetails"} />
                </Typography>
            )}
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    <Box sx={styles.recordStart}>
                        <Typography variant="body1">
                            {mediaList.length > 0 && t("messages.recordsFound", { count: mediaList.length })}
                        </Typography>
                    </Box>
                    <Box sx={styles.recordEnd}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                printReport();
                            }}
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                        >
                            {t("buttonLabels.prepareReport")}
                        </Button>
                    </Box>
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable
                        rows={mediaList}
                        columns={columns}
                        initialPage={location.state?.pageNumber}
                        initialRowsPerPage={location.state?.rowsPerPage}
                        initialOrderBy={location.state?.orderBy}
                        initialOrder={location.state?.order}
                        getRowId={(row) => Number(row.employee_id)}
                        noDataMessage={
                            isSearched ? t("listOpenMedia.noOpenMediaFound") : t("listOpenMedia.provideOtherInputs")
                        }
                        onTableAttributesChange={onTableStateChange}
                    />
                </Box>
            </SecureSyncTableCard>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.openMediaSearchResults}
            />
        </>
    );
};

export default ListOpenMedia;
