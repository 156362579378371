import { Box, Button, Container, Divider, FormControl, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./DownloadInventoryFile.styles";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import InfoCircle from "src/components/Icons/InfoCircle/InfoCircle";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { fileDate } from "src/utils/dateHelper";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { getApiErrorMessage, getSelectedCustomerId } from "src/services/utils";
import { createInventory, insertLoggedEvent, searchData } from "src/services/processMediaService";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import { multipleSort } from "src/utils/processMediaHelper";
import { sortCriteriaDownloadInventory } from "src/constants/processMediaConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getDropdownListInfo } from "src/services/researchService";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

export const DownloadInventoryFile = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.download,
        callback: () => {
            if (!downloadDisabled) {
                handleSubmit();
            }
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            if (!buttonDisabled) {
                resetHandler();
            }
        },
    });
    const { t } = useTranslation();
    const theme = useTheme();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const [isLoading, setIsLoading] = useState(false);
    const [inventoryTypeList, setInventoryTypeList] = useState([]);
    const [fileFormatList, setFileFormatList] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState({ show: false, message: "" });

    const { values, resetForm, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            inventory: "",
            fileFormat: "",
            includeMedia: "N",
            includeHeader: "N",
            containerConstant: t("downloadInventory.containerPhrase"),
            openMediaConstant: t("downloadInventory.openMediaPhrase"),
            inventoryListPhrase: t("downloadInventory.inventoryListPhrase"),
            customerPhrase: t("downloadInventory.customerPhrase"),
            mediaPhrase: t("downloadInventory.mediaPhrase"),
            mediaIdentifierPhrase: t("downloadInventory.mediaIdentifierPhrase"),
            logicalVaultPhrase: t("downloadInventory.logicalVaultPhrase"),
            drpPhrase: t("downloadInventory.drpPhrase"),
            returnDatePhrase: t("downloadInventory.returnDatePhrase"),
            mediaTypePhrase: t("downloadInventory.mediaTypePhrase"),
            descriptionPhrase: t("downloadInventory.descriptionPhrase"),
            containerOmPhrase: t("downloadInventory.containerOmPhrase"),
            slotPhrase: t("downloadInventory.slotPhrase"),
            dateInPhrase: t("downloadInventory.dateInPhrase"),
            inEmployeePhrase: t("downloadInventory.inEmployeePhrase"),
            dateOutPhrase: t("downloadInventory.dateOutPhrase"),
            outEmployeePhrase: t("downloadInventory.outEmployeePhrase"),
            customerName: t("downloadInventory.customerNamePhrase"),
            createdDate: t("downloadInventory.createdDatePhrase"),
            currentStatusCode: t("downloadInventory.currentStatusCodePhrase"),
            generationPhrase: t("downloadInventory.generationPhrase"),
        },
        onSubmit: async (values) => {
            await createInventorySys(values);
        },
    });

    const getFileFormatData = async () => {
        setIsLoading(true);
        try {
            const fileFormatRes = await getDropdownListInfo("3");
            let fileFormatTypes = [];
            if (fileFormatRes?.rs0.length > 0) {
                var sortedFileFormat = fileFormatRes.rs0.sort((a, b) => b.Display_Order - a.Display_Order);
            }
            fileFormatTypes = sortedFileFormat.map((item) => {
                return { id: item.dropdown_list_id, value: item.list_value, label: item.descr };
            });
            setFileFormatList(fileFormatTypes);
            setFieldValue("fileFormat", fileFormatTypes ? fileFormatTypes[0]?.value : "");
        } catch (error) {
            console.error("Get File format data - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getSearch = async () => {
        setIsLoading(true);
        try {
            const searchDataRes = await searchData(
                getSelectedCustomerId(),
                t("common.openMedia"),
                t("common.container"),
                `<${convertToLocaleUpperCase(t("common.all"))}>`
            );
            let inventoryTypeFiles = [];
            if (searchDataRes?.rs0.length > 0) {
                var filteredTypeFiles = searchDataRes?.rs0.sort((a, b) => a.filter - b.filter);
                inventoryTypeFiles = filteredTypeFiles.map((item) => {
                    return { id: item.filter, value: item.filter, label: item.description };
                });
            } else {
                setShowErrorAlert({ show: true, message: t("apiErrorMessages.63056") });
            }
            setInventoryTypeList(inventoryTypeFiles);
            setFieldValue("inventory", inventoryTypeFiles ? inventoryTypeFiles[0]?.value : "");
        } catch (error) {
            console.error("Get search Data - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const createLog = async (fileFormat, inventoryData) => {
        setIsLoading(true);
        try {
            const description = `Download Inventory File - ${fileFormat} selected`;
            const comment = `Inventory Data= ${inventoryData} selected`;
            await insertLoggedEvent(description, comment);
        } catch (error) {
            console.error("createLog - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const createInventorySys = async (value) => {
        setIsLoading(true);
        try {
            const createInventoryRes = await createInventory(value);
            if (createInventoryRes?.failure && createInventoryRes?.errorCode) {
                setShowErrorAlert({ show: true, message: getApiErrorMessage(createInventoryRes?.errorCode) });
            } else {
                const inventoryValue = inventoryTypeList.filter((item) => item.value === values.inventory);
                const fileFormatValue = fileFormatList.filter((item) => item.value === values.fileFormat);
                const fileName = await generateFileName(fileFormatValue[0].id === "8" ? "csv" : "txt");
                await downloadTextFile(fileName, createInventoryRes.inventoryData);
                await createLog(fileFormatValue[0].label, inventoryValue[0].label);
            }
        } catch (error) {
            console.error("Create inventory - catch error: ", error);
        } finally {
            setIsLoading(false);
        }
    };
    const generateFileName = (fileType) => {
        return `${selectedCustomer.customer_number}-${fileDate()}.${fileType.toLowerCase()}`;
    };

    const downloadTextFile = (filename, contentArray) => {
        // Extract the content strings from the array of objects
        const initialSortedArray = multipleSort(contentArray, sortCriteriaDownloadInventory);
        const extractedContent = initialSortedArray.map((item) => item.line_out);

        const sortedArray = extractedContent.sort((a, b) => {
            const aStatus = a.includes("OPENMEDIA") ? 1 : a.includes("CONTAINER") ? 2 : 3;
            const bStatus = b.includes("OPENMEDIA") ? 1 : b.includes("CONTAINER") ? 2 : 3;

            return aStatus - bStatus;
        });

        let headerLine = "";
        let filteredContent = [];

        if (filename.endsWith(".csv")) {
            // Handle CSV files
            headerLine = sortedArray.find((line) => line.startsWith("Cust #"));
            filteredContent = sortedArray.filter((line) => !line.startsWith("Cust #"));
        } else {
            // Handle TXT files
            headerLine = sortedArray.find((line) => line.startsWith('"Cust #'));
            filteredContent = sortedArray.filter((line) => !line.startsWith('"Cust #'));
        }

        // If a header line is found, put it at the start
        if (headerLine) {
            filteredContent.unshift(headerLine);
        }

        // Convert the content array to a string
        let content = "";

        // Convert array of strings to plain text format
        content = filteredContent.join("");

        // Create a Blob with the content
        const blob = new Blob([content], { type: "text/plain" });

        // Create a link element
        const link = document.createElement("a");

        // Set the download attribute with the filename
        link.download = filename;

        // Create an object URL for the Blob
        link.href = window.URL.createObjectURL(blob);

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    useEffect(() => {
        getFileFormatData();
        getSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetHandler = () => {
        resetForm();
        setFieldValue("inventory", inventoryTypeList[0].value);
        setFieldValue("fileFormat", fileFormatList[0].value);
    };

    const handleCheckBoxChange = (e, name) => {
        setFieldValue(name, e.target.checked ? "Y" : "N");
    };
    const buttonDisabled = inventoryTypeList.length === 0 || fileFormatList.length === 0;
    const downloadDisabled = buttonDisabled && !(Boolean(values.inventory) || Boolean(values.fileFormat));
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <form onSubmit={handleSubmit} onReset={resetHandler}>
                    <Box sx={styles.container}>
                        <Box sx={styles.innerBox}>
                            <PageTitle>{t("downloadInventory.downloadInventoryFile")}</PageTitle>
                            {showErrorAlert.show && (
                                <Box sx={styles.marginBottomBreak}>
                                    <SecureSyncAlert
                                        message={
                                            <Typography sx={styles.txtWeight500}>
                                                {getApiErrorMessage("63025")}
                                                <Box sx={styles.errorMsgs}>{showErrorAlert.message}</Box>
                                            </Typography>
                                        }
                                        severity="error"
                                        onClose={() => {
                                            setShowErrorAlert({ show: false, message: "" });
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography variant="body1" sx={styles.subheader}>
                                {inventoryTypeList.length > 0 && (
                                    <Box sx={styles.instructionsArea}>
                                        <Trans i18nKey={"downloadInventory.enterFileCriteriaAndClickDownload"} />
                                    </Box>
                                )}
                                {values.inventory === "1" && (
                                    <Box sx={styles.instructionsArea}>{getApiErrorMessage("63027")}</Box>
                                )}
                                {values.inventory === "2" && (
                                    <Box sx={styles.instructionsArea}>{getApiErrorMessage("63028")}</Box>
                                )}
                                {values.inventory === "3" && (
                                    <Box
                                        sx={styles.instructionsArea}
                                    >{`${getApiErrorMessage("63027")} ${getApiErrorMessage("63029")}`}</Box>
                                )}
                                <Box sx={styles.instructionsArea}>
                                    {t("downloadInventory.theDownloadMayTakeSeveralMinutesDependingOnTheSizeOfTheFile")}
                                </Box>
                            </Typography>
                            <Box sx={styles.flexBox}>
                                <Box sx={styles.formControlW30}>
                                    <SecureSyncSelect
                                        name="inventory"
                                        type="outlined"
                                        label={t("downloadInventory.inventoryData")}
                                        options={inventoryTypeList}
                                        value={values.inventory}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box sx={styles.formControlW30}>
                                    <SecureSyncSelect
                                        name="fileFormat"
                                        type="outlined"
                                        label={t("downloadInventory.fileFormat")}
                                        options={fileFormatList}
                                        value={values.fileFormat}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Box>
                            <Box sx={styles.checkboxArea}>
                                <FormControl sx={styles.formControl}>
                                    <SecureSyncCheckBox
                                        label={
                                            <Box sx={styles.txtWeight500}>
                                                {t("downloadInventory.includeMediaInTransitAndNotAtIronMountain")}
                                            </Box>
                                        }
                                        name="includeMedia"
                                        checked={values.includeMedia === "Y" ? true : false}
                                        onChange={(e) => handleCheckBoxChange(e, "includeMedia")}
                                    />
                                </FormControl>
                                <SecureSyncIconTooltip
                                    title={
                                        <>
                                            <Typography>
                                                <Box component="span">
                                                    {t("downloadInventory.includeMediaInTransitAndNotAtIronMountain")}
                                                </Box>
                                                <br />
                                                <Box component="span" sx={styles.footNotes}>
                                                    {t("downloadInventory.selectingThisOptionWillIncludeAll")}
                                                </Box>
                                            </Typography>
                                        </>
                                    }
                                    placement="right"
                                    icon={<InfoCircle fill={theme.palette.im_mid_blue["500"]} />}
                                />
                                <FormControl sx={styles.formControl}>
                                    <SecureSyncCheckBox
                                        label={
                                            <Box sx={styles.txtWeight500}>
                                                {t("downloadInventory.includeColumnHeaders")}
                                            </Box>
                                        }
                                        name="includeHeader"
                                        checked={values.includeHeader === "Y" ? true : false}
                                        onChange={(e) => handleCheckBoxChange(e, "includeHeader")}
                                    />
                                </FormControl>
                            </Box>
                            <Divider />
                            <br />
                            <Box sx={styles.buttonContainer}>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.reset)}
                                    variant="outlined"
                                    type="reset"
                                    disabled={buttonDisabled}
                                >
                                    {t("common.reset")}
                                </Button>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.download)}
                                    variant="contained"
                                    type="submit"
                                    disabled={downloadDisabled}
                                >
                                    {t("buttonLabels.download")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Container>
        </>
    );
};
