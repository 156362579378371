const styles = {
    filterDesc: {
        fontWeight: 500,
        margin: "20px 0",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        paddingBottom: "30px",
        paddingTop: "20px",
    },
    tableContainer: {
        marginBottom: "20px",
    },
    contactFieldContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
    },
    logicalVault: {
        gridColumn: 1,
    },
    customerDropdown: {
        gridColumn: "span 2",
    },
};

export default styles;
