const styles = {
    boxPaddingBottom: {
        paddingBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
    },
    buttonContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2,auto)",
        gap: "20px",
    },
};

export default styles;
