import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthStatus } from "src/redux/authSlice";

const useValidateOktaSession = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [isSessionValidated, setIsSessionValidate] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        const validateSessionAndRedirect = async () => {
            try {
                const isSessionExists = await oktaAuth.session.exists();
                setIsSessionValidate(true);
                if (isSessionExists && authState && !authState.isAuthenticated) {
                    //Runs when we come from okta page instead of directly

                    const { tokens } = await oktaAuth.token.getWithoutPrompt();
                    if (tokens) {
                        oktaAuth.tokenManager.setTokens(tokens);
                        const isAuthenticated = await oktaAuth.isAuthenticated();
                        dispatch(setAuthStatus({ isAuthenticated: isAuthenticated }));
                    }
                } else if (!isSessionExists && authState && authState.isAuthenticated) {
                    //Runs when local storage is active and if Okta login logged out before expire
                    await oktaAuth.signOut();
                } else if (isSessionExists && authState.isAuthenticated) {
                    //Runs Okta session and local storage is active
                    dispatch(setAuthStatus({ isAuthenticated: true }));
                } else {
                    dispatch(setAuthStatus({ isAuthenticated: false }));
                }
            } catch (err) {
                dispatch(setAuthStatus({ isAuthenticated: false }));
            }
        };

        if (authState && !isSessionValidated) {
            validateSessionAndRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, oktaAuth]);
};

export default useValidateOktaSession;
