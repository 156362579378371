/* eslint-disable react-hooks/exhaustive-deps */
import { Box, MenuItem, MenuList, Popper } from "@mui/material";
// import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProtectedMenu from "../../ProtectedMenu/ProtectedMenu";
import styles from "./ProcessMediaMenu.styles";
import { ReactComponent as ArrowDown } from "src/assets/images/arrow-down.svg";
import {
    containerPickup,
    downloadInventory,
    omDistributionList,
    scanVerification,
    sendListFile,
} from "src/constants/routeConstants/processMediaRouterFullPaths";
import { useAnchorAndNavigation } from "src/customHooks";
import { getAccessRights } from "src/redux/userDetailsSlice";

function ProcessMediaMenu() {
    const { t } = useTranslation();
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    const {
        anchorEl: processMediaMenu,
        isOpen: isProcessMediaMenuOpen,
        menuRef,
        openAnchor: openProcessMediaMenu,
        // eslint-disable-next-line no-unused-vars
        closeAnchor: closeProcessMediaMenu,
        handleBoxMouseLeave,
        handleMenuMouseLeave,
        handleMenuItemClick: handleProcessMediaMenuClick,
    } = useAnchorAndNavigation();

    return (
        <div>
            <Box onMouseEnter={openProcessMediaMenu} onMouseLeave={handleBoxMouseLeave} sx={styles.relativeDisplay}>
                <Button color="inherit" endIcon={<ArrowDown />} sx={styles.button.headerButton}>
                    {t("headerMenu.processMedia")}
                </Button>
                <Popper
                    sx={{ zIndex: "appBar" }}
                    open={isProcessMediaMenuOpen}
                    anchorEl={processMediaMenu}
                    placement="bottom-start"
                    className="menu-wrapper"
                    disablePortal={false}
                >
                    <MenuList
                        ref={menuRef}
                        onMouseLeave={handleMenuMouseLeave}
                        style={styles.menuList(isProcessMediaMenuOpen)}
                    >
                        <ProtectedMenu hasAccess={pageAccess.containerPickup}>
                            <MenuItem
                                onClick={() => {
                                    handleProcessMediaMenuClick(containerPickup);
                                }}
                            >
                                {t("headerMenu.containerPickup")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.downloadInventory}>
                            <MenuItem
                                onClick={() => {
                                    handleProcessMediaMenuClick(downloadInventory);
                                }}
                            >
                                {t("headerMenu.downloadInventory")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.oMDistributionList}>
                            <MenuItem
                                onClick={() => {
                                    handleProcessMediaMenuClick(omDistributionList);
                                }}
                            >
                                {t("headerMenu.oMDistributionList")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.scanVerification}>
                            <MenuItem
                                onClick={() => {
                                    handleProcessMediaMenuClick(scanVerification);
                                }}
                            >
                                {t("headerMenu.scanVerification")}
                            </MenuItem>
                        </ProtectedMenu>
                        <ProtectedMenu hasAccess={pageAccess.sendListFile}>
                            <MenuItem
                                onClick={() => {
                                    handleProcessMediaMenuClick(sendListFile);
                                }}
                            >
                                {t("headerMenu.sendListFile")}
                            </MenuItem>
                        </ProtectedMenu>
                    </MenuList>
                </Popper>
            </Box>
        </div>
    );
}

export default ProcessMediaMenu;
