const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        p: 3,
        width: "100%",
        textAlign: "left",
    },
    header: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%" /* 41.99px */,
        letterSpacing: "0.25px",
    },
    sectionTitle: {
        my: 2,
    },
    sectionTitleError: {
        my: 2,
        color: "im_red.500",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    textFieldContainer: {
        display: "flex",
        gap: 2,
        my: 3,
    },
    footNotes: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    formControlLabel: {
        paddingBottom: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        mt: 4,
    },
    switchLabelContainer: {},
    switchDescription: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        mb: "16px",
    },
    flexRow50: {
        display: "flex",
        gap: 2,
        width: "50%",
        alignItems: "center",
    },
    marginBottomBreak: {
        mb: "16px",
    },
    errorMsgs: {
        fontWeight: 500,
        fontSize: 14,
    },
    clearIcon: {
        color: "im_dark_blue.500",
    },
    descriptionField: {
        my: "16px",
    },
    fontWeight500: {
        fontWeight: 500,
    },
    instructionText: {
        fontWeight: 500,
        mb: "16px",
    },
};

export default styles;
