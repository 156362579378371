import generateFilePathForCurrentLocale from "./generateFilePathForCurrentLocale";
import { openFileFromFB } from "src/services/utils";

export const getPreferenceValueByPreferenceType = (allPrefs, prefTypeId) => {
    const prefValue = allPrefs.find((pref) => pref.preference_type_id === prefTypeId);
    return prefValue?.preference_value;
};

export const downloadInstructionGuide = async (fileForms) => {
    const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
    const file = generateFilePathForCurrentLocale(bucketPath, fileForms);
    const url = await openFileFromFB(file);
    return url;
};
