import { useState, useMemo } from "react";
import { Box, Button, Typography, SvgIcon, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ListEmployees.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { getEmployeeDetailsPath } from "src/constants/routeConstants/operationsRouterFullPaths";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { searchEmployees } from "src/constants/routeConstants/operationsRouterFullPaths";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getSearchEmployeePayload } from "src/services/printServices";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const ListEmployees = ({ employeeList, isSearched, searchCriteria }) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [isPrintModalOpen, setPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const handleEditClick = (row) => {
        navigate(getEmployeeDetailsPath(row.employee_id));
    };
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <SecureSyncIconTooltip
                        title={t("listEmployees.viewDetails")}
                        icon={
                            <SvgIcon
                                fill={theme.palette.primary.main}
                                component={RemoveRedEyeFilled}
                                onClick={() => handleEditClick(params)}
                            />
                        }
                    ></SecureSyncIconTooltip>
                ),
            },
            { field: "name", headerName: convertToLocaleUpperCase(t("tableHeader.name")), width: 350, sortable: true },
            { field: "title", headerName: convertToLocaleUpperCase(t("tableHeader.jobTitle")), sortable: true },
            {
                field: "business_phone",
                headerName: convertToLocaleUpperCase(t("tableHeader.businessPhone")),
                sortable: false,
            },
            {
                field: "cellular_phone",
                headerName: convertToLocaleUpperCase(t("tableHeader.cellularPhone")),
                sortable: false,
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(searchEmployees, { state: { ...locationState, ...attributes }, replace: true });
    };

    const printReport = () => {
        setPrintPayload(
            getSearchEmployeePayload({
                firstName: searchCriteria.firstName,
                lastName: searchCriteria.lastName,
                districtId: searchCriteria.district,
                districtName: searchCriteria.districtName,
                title: searchCriteria.title,
            })
        );
        setPrintModalOpen(true);
    };

    return (
        <>
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    {employeeList.length > 0 && (
                        <>
                            <Box sx={styles.recordStart}>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: employeeList.length })}
                                </Typography>
                            </Box>
                            <Box sx={styles.recordEnd}>
                                <Button
                                    variant="outlined"
                                    onClick={printReport}
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                >
                                    {t("buttonLabels.prepareReport")}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable
                        rows={employeeList}
                        columns={columns}
                        initialPage={location.state?.pageNumber}
                        initialRowsPerPage={location.state?.rowsPerPage}
                        initialOrderBy={location.state?.orderBy}
                        initialOrder={location.state?.order}
                        getRowId={(row) => Number(row.employee_id)}
                        noDataMessage={
                            isSearched ? t("listEmployees.provideNoDataInputs") : t("listEmployees.provideOtherInputs")
                        }
                        onTableAttributesChange={onTableStateChange}
                    />
                </Box>
            </SecureSyncTableCard>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setPrintModalOpen(false)}
                targetReport={targetUrl.searchEmployeeReport}
            />
        </>
    );
};

export default ListEmployees;
