const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    innerContainer: {
        width: "100%",
        textAlign: "left",
    },
    userDetailTitle: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%", // 41.99px
        letterSpacing: "0.25px",
        paddingTop: "20px",
    },
    formHeader: {
        color: "im_grey.900",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "133.4%",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    userDetailBody1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%", // 24px
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    dividerSpacing: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    nameFieldContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
    },
    contactFieldContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    formControl: {
        width: 310,
        paddingRight: 2,
        paddingBottom: "20px",
    },
    flexWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    singleColumnContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "20px",
    },
    switchLabelContainer: {},
    switchDescription: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.4px",
    },
    securityGridContainer: {
        paddingBottom: "20px",
    },
    securityGridItem: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "12px",
        letterSpacing: "0.15px",
    },
    challengeQuestionContainer: {
        display: "grid",
        paddingBottom: "20px",
        gridTemplateColumns: "640px 286px 286px",
    },
    dataGridContainer: {
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(4, 285px)",
    },
    dataGrid: {
        "& .MuiFormControl-root": {
            paddingRight: "0px",
        },
    },
    buttonContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    challengeAnswer: {
        padding: "0px 10px",
    },
    challengeField: {
        width: "100%",
    },
    typographyError: {
        fontSize: "14px",
        paddingBottom: "20px",
    },
    buttonSubmit: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
};

export default styles;
