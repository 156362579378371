const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    formControl: {
        width: "100%",
        paddingRight: 2,
    },
    card: {
        width: "100%",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    tblCardContent: {
        width: "100%",
        maxHeight: "150px",
        overflowY: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        height: "60px",
        py: 1,
        px: 2,
    },
    recordStart: {
        justifyContent: "flex-start",
    },
    recordEnd: {
        justifyContent: "flex-end",
    },
    footNoteText: {
        fontWeight: 400,
        fontSize: "12px",
        marginTop: "15px",
    },
    instructionText: {
        fontWeight: 400,
        fontSize: "12px",
    },
    statusRecord: {
        fontWeight: 500,
        fontSize: "14px",
        alignContent: "center",
    },
    footerBtnSection: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    errorText: {
        fontWeight: 400,
        fontSize: "12px",
        color: "im_red.500",
    },
};

export default styles;
