const styles = {
    textContent: {
        fontWeight: "bold",
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
        mb: "20px",
    },
    description: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
    },
    recordStart: {
        justifyContent: "flex-start",
        padding: "16px",
    },
    recordEnd: {
        justifyContent: "flex-end",
        padding: "16px",
    },
    footerCaption: {
        mt: "10px",
        mb: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
    },
};

export default styles;
