const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        p: 2,
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    textFieldContainer: {
        display: "flex",
        gap: 2,
        my: 3,
    },
    footNotes: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    formControlLabel: {
        paddingBottom: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        my: 2,
    },
    flexBox: {
        display: "flex",
        gap: 2,
    },
    checkboxArea: {
        display: "flex",
    },
    mr0: {
        mr: 0,
    },
    txtWeight500: {
        fontWeight: 500,
    },
    instructionsArea: {
        mb: 2,
        fontWeight: 500,
    },
    formControl: {
        m: 2,
        component: "fieldset",
        variant: "standard",
        mr: 0,
    },
    formControlW30: {
        width: "30%",
    },
    checkBox: {
        color: "primary",
        "&.Mui-checked": {
            color: "primary",
        },
    },
    marginBottomBreak: {
        mb: "16px",
    },
    errorMsgs: {
        fontWeight: 500,
        fontSize: 14,
    },
};

export default styles;
