const styles = {
    textContent: {
        fontWeight: "bold",
    },

    fourColumnLayout: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
    },
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr) 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    marginBottom20: { mb: "20px" },
    tooltipSection: { display: "flex", alignItems: "center" },
    btnSection: { display: "flex", my: "20px", gap: "20px" },
};

export default styles;
