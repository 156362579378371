import dayjs from "dayjs";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import styles from "./ServiceDateCalendar.styles";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import { dateTimeUnits } from "src/constants/dateFormatConstants";
import { useGetAttrsBasedOnAddCutOffValues } from "src/customHooks";
import { getServiceDateForCustomerService, isValidCustomerOnCallService } from "src/services/requestServices";
import {
    getSelectedCustomerLocaleTime,
    getServiceDateRange,
    serviceDateMapper,
    getTomorrowDate,
} from "src/utils/dateHelper";
import { calculateAddOnCutOffFlag } from "src/utils/newContainerLockInsertHelper";
import { getAddOnCutOff } from "src/utils/requestHelper";

//onRunDetailsFetch - used to reuse run details data in parent component instead fetching multiple times
const ServiceDateCalendar = (
    {
        label,
        value,
        error,
        helperText,
        onChange,
        onBlur,
        onRunDetailsFetch,
        scheduleEndDate,
        setServiceDateLoading,
        getTodayServiceDate = false,
        scheduleEmptyAllowSelectDefault = false,
        setIsScheduleDate = () => {},
    },
    ref
) => {
    const [runDetails, setRunDetails] = useState();
    const [serviceDates, setServiceDates] = useState([]);
    const [onCallSupport, setOnCallSupport] = useState(false);

    const { addDays, addOnCutOffToday, addOnCutOffTomorrow } = useGetAttrsBasedOnAddCutOffValues(
        runDetails,
        onCallSupport
    );

    const activeServiceDates = useMemo(() => {
        const minSelectableDate = dayjs().add(addDays, dateTimeUnits.day);
        const filteredServiceDates = serviceDates.filter((date) =>
            date.isSameOrAfter(minSelectableDate, dateTimeUnits.day)
        );
        return filteredServiceDates;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addDays, serviceDates]);

    useEffect(() => {
        const fetchOnCallSupport = async () => {
            const onCallSupport = await isValidCustomerOnCallService();
            setOnCallSupport(onCallSupport.result[0]?.on_call === "true");
        };
        fetchOnCallSupport();
    }, []);

    const fetchRunDetails = async () => {
        const runDetails = await getAddOnCutOff();
        setRunDetails(runDetails);
        if (onRunDetailsFetch) {
            onRunDetailsFetch(runDetails);
        }
        return runDetails;
    };

    useEffect(() => {
        if (activeServiceDates.length > 0) {
            onChange(activeServiceDates[0]);
            setIsScheduleDate(true);
        } else {
            onChange(getTomorrowDate());
            setIsScheduleDate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeServiceDates]);

    const getServiceDateList = useCallback(async () => {
        setServiceDateLoading(true);
        const newRunDetails = await fetchRunDetails();
        const addOnCutOff = calculateAddOnCutOffFlag(newRunDetails);
        const { fromDate, toDate } = getServiceDateRange();

        const response = await getServiceDateForCustomerService({
            fromDate: fromDate,
            toDate: toDate,
            addonCutoffFlag: addOnCutOff,
        });

        if (scheduleEmptyAllowSelectDefault) {
            const today = dayjs(getSelectedCustomerLocaleTime());
            const minSelectableDate = today.add(1, dateTimeUnits.day);
            onChange(minSelectableDate);
        }

        // setIsServiceLocationLoading(false);
        if (!response.failure) {
            if (response?.resultSet0?.length > 0) {
                const serviceDates = serviceDateMapper(response?.resultSet0);
                setServiceDates(serviceDates);
                if (getTodayServiceDate) {
                    getTodayServiceDate(isTodayServiceDate(serviceDates));
                }
            }
        }
        setServiceDateLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getServiceDateList();
    }, [getServiceDateList]);

    useImperativeHandle(ref, () => {
        return {
            refreshServiceDates: getServiceDateList,
        };
    }, [getServiceDateList]);

    const getDateProp = useCallback(
        (date) => {
            let datePropObj = {};
            const resultArray = [addOnCutOffToday, addOnCutOffTomorrow].filter((value) => value);
            const isCutoffDate = resultArray.find((serviceDate) => serviceDate.isSame(date, dateTimeUnits.day));
            if (isCutoffDate) {
                datePropObj = {
                    disabled: Boolean(isCutoffDate),
                    customStyle: styles.cutOffDates,
                };
            } else {
                const isServiceDate = activeServiceDates.find((serviceDate) =>
                    serviceDate.isSame(date, dateTimeUnits.day)
                );
                datePropObj = { highlight: isServiceDate };
                const enable = onCallSupport || isServiceDate || date.isAfter(scheduleEndDate);
                if (!enable) {
                    datePropObj.disabled = true;
                }
            }
            return datePropObj;
        },
        [addOnCutOffToday, addOnCutOffTomorrow, activeServiceDates, onCallSupport, scheduleEndDate]
    );

    const isTodayServiceDate = (serviceDates) => {
        const today = dayjs();
        return serviceDates.some((date) => date.isSame(today, dateTimeUnits.day));
    };
    return (
        <SecureSyncDatePicker
            label={label}
            value={value}
            error={error}
            helperText={helperText}
            onChangeRaw={onChange}
            onBlur={onBlur}
            disablePast
            required
            getDateProp={getDateProp}
            minDate={dayjs().add(addDays, dateTimeUnits.day)}
        />
    );
};

export default forwardRef(ServiceDateCalendar);
