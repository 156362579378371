import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./OMDistributionDataTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";

const OMDistributionDataTable = ({
    mediaData = [],
    columns,
    openAddMediaModalHandler,
    getRowId,
    disableForm = false,
}) => {
    const { t } = useTranslation();
    return (
        <SecureSyncTableCard>
            <Box sx={styles.recordCount}>
                {mediaData.length > 0 ? (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: mediaData.length })}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.recordStart} />
                )}
                <Box sx={styles.recordEnd}>
                    <Button variant="outlined" onClick={openAddMediaModalHandler} disabled={disableForm}>
                        {t("omDistribution.addMedia")}
                    </Button>
                </Box>
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={mediaData}
                    columns={columns}
                    getRowId={getRowId}
                    noDataMessage={t("omDistribution.noRecordsFound")}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default OMDistributionDataTable;
