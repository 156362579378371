import { localStorageKeys } from "src/constants/localStorageConstants";

export const postUserLoginCheck = (postLoginCheck) => {
    if (
        postLoginCheck.allow_default_customer_at_login === "Y" ||
        postLoginCheck.required_fields_need_validation === "Y" ||
        postLoginCheck.show_on_hold_customers_flag === "Y" ||
        postLoginCheck.show_default_customer_at_login === "Y"
    ) {
        return true;
    } else {
        return false;
    }
};

export const clearValuesOnSignIn = () => {
    localStorage.removeItem(localStorageKeys.selectedCustomerId);
};
