import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18nResources, languages } from "./constants/i18nConstants";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false, // if enables, can check the i18n object to debug
        resources: i18nResources,
        fallbackLng: languages.en_us,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
