import { generatePath } from "react-router-dom";
import { routePaths } from "./routeConstants";

const securePath = routePaths.root;

export const requestHome = `${securePath}/${routePaths.request.home}`;
export const searchMediaDestructionRequest = `${requestHome}/${routePaths.request.searchMediaDestructionRequest}`;
export const deliverMedia = `${requestHome}/${routePaths.request.deliverMedia}`;
export const newContainer = `${requestHome}/${routePaths.request.newContainer}`;
export const newTransport = `${requestHome}/${routePaths.request.newTransport}`;
export const other = `${requestHome}/${routePaths.request.other}`;
export const rescheduleService = `${requestHome}/${routePaths.request.rescheduleService}`;
export const micsServices = `${requestHome}/${routePaths.request.micsServices}`;
export const searchMediaDestructionDetails = `${requestHome}/${routePaths.request.searchMediaDestructionRequestDetail}`;
export const cancelRequest = `${requestHome}/${routePaths.request.cancelRequest}`;
export const requestDetails = `${requestHome}/${routePaths.request.requestDetail}`;

export const getRequestDetailPath = (requestId) => {
    return generatePath(requestDetails, { requestId });
};

export const getCancelRequestPath = (requestId, requestDetail, cancelRequestData) => {
    return generatePath(cancelRequest, { requestId, requestDetail, cancelRequestData });
};

export const getRequestMediaDestructionDetailPath = (destructId) => {
    return generatePath(searchMediaDestructionDetails, { destructId });
};
