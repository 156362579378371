/* eslint-disable max-lines */
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useHotkeysContext } from "react-hotkeys-hook";
import styles from "./NewContainerLockInsert.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import ContainerInformation from "src/components/Request/NewContainerLockInsert/ContainerInformation/ContainerInformation";
import ExtraInserts from "src/components/Request/NewContainerLockInsert/ExtraInserts/ExtraInserts";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getPreferences } from "src/services/adminService";
import {
    createTransportRequestService,
    getActiveCustomersSiblingsService,
    getComboBoxDescriptionForNewContainer,
} from "src/services/requestServices";
import { getSelectedCustomer } from "src/services/utils";
import { formatDate, getMinAndMaxDate, getTomorrowDate } from "src/utils/dateHelper";
import { calculateAddOnCutOffFlag, generateRequestDetails } from "src/utils/newContainerLockInsertHelper";
import { preferenceLevelTypeId, preferenceTypeId } from "src/constants/preferenceServiceConstants";
import NewContainerLockInsertSuccessModal from "src/components/Request/NewContainerLockInsert/NewContainerLockInsertSuccessModal/NewContainerLockInsertSuccessModal";
import { errorCodes } from "src/constants/errorCodes";
import { getNewContainerGlobalAttrs, getServiceFrequency, updatedDropdownData } from "src/utils/requestHelper";
import ServiceDateCalendarTooltip from "src/components/AppComponents/ServiceDateCalendarTooltip/ServiceDateCalendarTooltip";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import ServiceDateCalendar from "src/components/AppComponents/ServiceDateCalendar/ServiceDateCalendar";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { systemId } from "src/constants/serviceConstants";
import { dateTimeUnits } from "src/constants/dateFormatConstants";
import { multipleSort } from "src/utils/processMediaHelper";
import { requestSortCriteria } from "src/constants/requestConstants";
import { getNewContainerLocksPayload } from "src/services/printServices";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { hotKeysScopes } from "src/constants/shortCutKeys";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    serviceLocationId: "",
    serviceDate: getTomorrowDate(),
    comments: "",
    extraLocksQuantity: "",
    extraLockType: "",
    clipQuantity: "",
    isPurchase: true,
};

const clipQuantity = {
    min: 1,
    max: 32767,
};

const extraLocksQuantityRange = {
    min: 1,
    max: 32767,
};

const commentsMaxLength = 255;

const validationSchema = (t) => {
    const { minDateForApi: minDate, maxDateForApi: maxDate, displayMaxDate, displayMinDate } = getMinAndMaxDate();
    const clipQtyErrorMessage = t("messages.clipQtyRange", { min: clipQuantity.min, max: clipQuantity.max });
    const extraLocksQtyErrorMessage = t("messages.extraLocksQtyRange", {
        min: extraLocksQuantityRange.min,
        max: extraLocksQuantityRange.max,
    });

    return Yup.object().shape({
        serviceLocationId: Yup.string(),
        serviceDate: Yup.string()
            .required(t("messages.serviceDateRequired"))
            .test("validateServiceDate", (value, { createError, path }) => {
                const isValidDate = dayjs(value).isValid();
                const isOutSideMinDate = dayjs(value).isBefore(minDate);
                const isOutSideMaxDate = dayjs(value).isAfter(maxDate);
                let message = "";
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate || isOutSideMinDate) {
                    message = (
                        <Trans
                            i18nKey={"messages.serviceDateRange"}
                            values={{
                                serviceDate: t("formFields.serviceDate"),
                                minDate: displayMinDate,
                                maxDate: displayMaxDate,
                            }}
                        />
                    );
                }

                if (message) {
                    return createError({
                        path,
                        message,
                    });
                } else {
                    return true;
                }
            }),
        comments: Yup.string().max(
            commentsMaxLength,
            t("messages.commentsMaxLength", { maxLength: commentsMaxLength })
        ),
        extraLocksQuantity: Yup.number()
            .typeError(extraLocksQtyErrorMessage)
            .when("$extraLockType", ([extraLockType], schema) => {
                return extraLockType ? schema.required(t("messages.extraLocksQtyRequired")) : schema;
            })
            .integer(extraLocksQtyErrorMessage)
            .min(extraLocksQuantityRange.min, extraLocksQtyErrorMessage)
            .max(extraLocksQuantityRange.max, extraLocksQtyErrorMessage),
        extraLockType: Yup.string().when("$extraLocksQuantity", ([extraLocksQuantity], schema) => {
            return extraLocksQuantity ? schema.required(t("messages.extraLockTypeRequired")) : schema;
        }),
        clipQuantity: Yup.number()
            .typeError(clipQtyErrorMessage)
            .integer(clipQtyErrorMessage)
            .min(clipQuantity.min, clipQtyErrorMessage)
            .max(clipQuantity.max, clipQtyErrorMessage),
        isPurchase: Yup.bool(),
    });
};

const NewContainerLockInsertHeader = ({ isLoading }) => {
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <PageTitle>
                <Trans i18nKey={"titles.newContainerLockInsert"} />
            </PageTitle>
        </>
    );
};

const initialAlertState = { show: false, message: "" };
const useShortCutKeys = (handleSubmit, handleFormReset) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: handleSubmit,
        options: { scopes: hotKeysScopes.newContainerLockInsert },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: handleFormReset,
        options: { scopes: hotKeysScopes.newContainerLockInsert },
    });
    const { enableScope } = useHotkeysContext();
    useEffect(() => {
        enableScope(hotKeysScopes.newContainerLockInsert);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const NewContainerLockInsert = () => {
    const shortCutKeys = getShortCutKeys();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isScheduleServiceLoading, setIsScheduleServiceLoading] = useState(false);
    const [isServiceDateLoading, setIsServiceDateLoading] = useState(false);
    const [serviceLocations, setServiceLocations] = useState([]);
    const [dropdownData, setDropDownData] = useState({});
    const [serviceFrequencyValues, setServiceFrequencyValues] = useState({});
    const [requestedContainers, setRequestedContainers] = useState([]);
    const [requestedInserts, setRequestedInserts] = useState([]);
    const [savedCMILabels, setSavedCMILabels] = useState([]);
    const [runDetails, setRunDetails] = useState();
    const serviceDateRef = useRef();
    const [childFormStatus, setChildFormStatus] = useState({
        containerInfo: false,
        extraInsert: false,
    });
    const [infoStatus, setInfoStatus] = useState(initialAlertState);
    const [errorStatus, setErrorStatus] = useState(initialAlertState);
    const [mediaRequestDeadLineStatus, setMediaRequestDeadLineStatus] = useState(initialAlertState);
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    const [isTodayServiceDate, setIsTodayServiceDate] = useState(false);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useShortCutKeys(
        () => handleSubmit(),
        () => {
            handleFormReset();
        }
    );
    const [successModalObj, setSuccessModalObj] = useState({
        show: false,
        values: {},
    });

    const globalAttr = useMemo(() => {
        if (globalAttributes?.data?.rs0) {
            return getNewContainerGlobalAttrs(globalAttributes?.data?.rs0);
        }
        return { allowCustomerMediaIdentifiers: false, maxExtraInsert: 1000 };
    }, [globalAttributes]);

    const [simpleFormResetter, setSimpleFormResetter] = useState(0);

    const lockTypeOptions = useMemo(() => {
        if (!dropdownData.lockTypes) {
            return [];
        }
        const sortedLockType = multipleSort(
            updatedDropdownData(dropdownData.lockTypes),
            requestSortCriteria.comboBoxSort
        );
        return sortedLockType.map(({ lock_type_code: lockTypeCode }) => {
            return {
                id: lockTypeCode,
                label: lockTypeCode,
                value: lockTypeCode,
            };
        });
    }, [dropdownData.lockTypes]);

    const vaultOptions = useMemo(() => {
        if (!dropdownData.vaultIds) {
            return [];
        }
        const sortedVault = multipleSort(updatedDropdownData(dropdownData.vaultIds), requestSortCriteria.comboBoxSort);
        return sortedVault.map(({ code_value: vaultCode, id }) => {
            return { id: id, value: id, label: vaultCode };
        });
    }, [dropdownData.vaultIds]);

    const serviceLocationOptions = useMemo(() => {
        return serviceLocations.map(({ customer_id: customerId, customer_number: customerNumber }) => ({
            id: customerId.toString(),
            value: customerId.toString(),
            label: customerNumber.toString(),
        }));
    }, [serviceLocations]);

    useEffect(() => {
        const fetchCustomerServiceFrequency = async () => {
            setIsScheduleServiceLoading(true);
            const serviceFreqValues = await getServiceFrequency();
            setServiceFrequencyValues(serviceFreqValues);
            setIsScheduleServiceLoading(false);
        };
        fetchCustomerServiceFrequency();
    }, []);

    useEffect(() => {
        if (serviceFrequencyValues.customerHasSchedule) {
            const fetchInitialData = async () => {
                setIsLoading(true);
                const [data, comboBox] = await Promise.all([
                    getActiveCustomersSiblingsService(),
                    getComboBoxDescriptionForNewContainer(),
                    fetchMediaRequestDeadLineStatus(),
                ]);
                setServiceLocations(data);
                setDropDownData(comboBox);
                setIsLoading(false);
            };
            fetchInitialData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceFrequencyValues.customerHasSchedule, globalAttributes.data]);

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        setFieldTouched,
        setFieldValue,
        setValues,
        handleSubmit,
        handleBlur,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            await handleFormSubmit(values);
        },
    });

    useEffect(() => {
        setValues({
            ...initialValues,
            serviceLocationId: selectedCustomer.customer_id,
            vaultId: vaultOptions[0]?.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer.customer_id, vaultOptions]);

    useEffect(() => {
        if (errorStatus.show || infoStatus.show) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [errorStatus.show, infoStatus.show]);

    const fetchMediaRequestDeadLineStatus = async () => {
        const preferences = await getPreferences(preferenceLevelTypeId, preferenceTypeId.mediaRequestDeadLine, 1);
        const preferenceValue = preferences[0]?.preference_value;
        setMediaRequestDeadLineStatus({
            show: Boolean(preferenceValue),
            value: preferenceValue,
        });
    };

    const getUnSavedErrorMessage = () => {
        let unsavedSection = "";
        if (childFormStatus.containerInfo) {
            unsavedSection = t("titles.containerInformation");
        } else if (childFormStatus.extraInsert) {
            unsavedSection = t("titles.extraInserts");
        }
        return t("messages.unSavedSection", { section: unsavedSection });
    };

    const checkIsFormValid = (formValues) => {
        let message = "";
        if (childFormStatus.containerInfo || childFormStatus.extraInsert) {
            message = getUnSavedErrorMessage();
        } else if (
            requestedContainers.length === 0 &&
            requestedInserts.length === 0 &&
            !formValues.extraLockType &&
            !formValues.clipQuantity
        ) {
            message = t("messages.newContainerLockInsertFormCriteria");
        }

        if (message) {
            setErrorStatus({
                show: true,
                message,
            });
        } else {
            setErrorStatus({
                show: false,
                message,
            });
        }
        return !message;
    };

    const handleFormSubmit = async (formValues) => {
        const isFormValid = checkIsFormValid(formValues);

        if (isFormValid) {
            const requestDetails = generateRequestDetails({
                containers: requestedContainers,
                inserts: requestedInserts,
                purchase: false,
                ...formValues,
            });

            const addOnCutOff = calculateAddOnCutOffFlag(runDetails, values.serviceDate);

            const response = await createTransportRequestService({
                serviceDate: values.serviceDate,
                comment: values.comments,
                detailData: requestDetails,
                cslCustomerId: values.serviceLocationId,
                addonCutoffFlag: addOnCutOff,
            });

            if (response.success) {
                setSuccessModalObj({
                    show: true,
                    values: {
                        ...response,
                        serviceDate: formValues.serviceDate,
                    },
                });
            } else if (response.error === errorCodes.Code63857) {
                getServiceDates();
                const newDuplicateCMILabels = response.duplicateCustomerMediaDescList.split("\t");

                const message = t("messages.existingDuplicateCMILabels", {
                    labelsStr: newDuplicateCMILabels.join(","),
                });
                setSavedCMILabels(newDuplicateCMILabels);
                setErrorStatus({
                    show: true,
                    message,
                });
            } else if (response.error === errorCodes.Code63752) {
                getServiceDates();
                const message = t("apiErrorMessages.63752");
                setErrorStatus({
                    show: true,
                    message,
                });
            } else {
                getServiceDates();
                setErrorStatus({
                    show: true,
                    message: response.displayMessage,
                });
            }
        }
    };

    const handleFormReset = () => {
        resetForm({
            values: {
                ...initialValues,
                serviceLocationId: selectedCustomer.customer_id,
                vaultId: vaultOptions[0]?.value,
            },
        });
        setRequestedContainers([]);
        setRequestedInserts([]);
        setSimpleFormResetter(simpleFormResetter + 1);
        getServiceDates();
    };

    const getServiceDates = async () => {
        if (serviceDateRef.current) {
            serviceDateRef.current.refreshServiceDates();
        }
    };

    const onSuccessModalClose = () => {
        handleFormReset();
        setSuccessModalObj({ show: false, values: {} });
    };

    const onCancel = () => {
        navigate(dashboard);
    };

    const onClear = () => {
        setErrorStatus(initialAlertState);
    };

    const isScheduleDataLoaded = "customerHasSchedule" in serviceFrequencyValues;

    const serviceDateHandler = async (value) => {
        if (!isTodayServiceDate && dayjs(value).isSame(dayjs(), dateTimeUnits.day)) {
            setErrorStatus({
                show: true,
                message: t("messages.requestCantMakeToday"),
            });
            setFieldValue("serviceDate", "");
        } else {
            const formattedDate = formatDate(value);
            await setFieldValue("serviceDate", formattedDate);
            setErrorStatus(initialAlertState);
        }
    };

    if (!isScheduleDataLoaded) {
        return <NewContainerLockInsertHeader isLoading={isLoading || isScheduleServiceLoading} />;
    } else if (!serviceFrequencyValues.customerHasSchedule) {
        return (
            <Box>
                <NewContainerLockInsertHeader isLoading={isLoading || isScheduleServiceLoading} />
                <SecureSyncAlert severity="info" message={t("messages.noScheduleDefine")} />
            </Box>
        );
    }

    const printReport = () => {
        setPrintPayload(
            getNewContainerLocksPayload({
                requestId: successModalObj.values.requestId,
            })
        );
        printModalOpen(true);
    };

    return (
        <>
            <Box>
                <NewContainerLockInsertHeader
                    isLoading={isLoading || isScheduleServiceLoading || isSubmitting || isServiceDateLoading}
                />

                {mediaRequestDeadLineStatus.show && (
                    <SecureSyncAlert
                        message={t("messages.mediaRequestDeadLineStatus", { status: mediaRequestDeadLineStatus.value })}
                    />
                )}
                <Box>
                    {infoStatus.show && (
                        <SecureSyncAlert
                            severity="info"
                            message={infoStatus.message}
                            onClose={() => setInfoStatus(initialAlertState)}
                        />
                    )}
                </Box>
                <Box>
                    {errorStatus.show && (
                        <SecureSyncAlert
                            severity="error"
                            message={errorStatus.message}
                            onClose={() => setErrorStatus(initialAlertState)}
                        />
                    )}
                </Box>
                <NewContainerLockInsertSuccessModal
                    values={successModalObj.values}
                    open={successModalObj.show}
                    onClose={onSuccessModalClose}
                    printReport={printReport}
                />

                <Typography sx={styles.pageDesc}>
                    <Trans i18nKey={"newContainerLockInsert.pageDescription"} />
                </Typography>
                <Divider />

                <form noValidate onSubmit={handleSubmit}>
                    <Box>
                        <PageSubTitle>
                            <Trans i18nKey={"titles.deliveryInformation"} />
                        </PageSubTitle>
                        <Box sx={styles.containerWrapper}>
                            <SecureSyncSelect
                                disabled={true}
                                value={serviceLocationOptions.length ? values.serviceLocationId : ""}
                                error={touched.serviceLocationId && Boolean(errors.serviceLocationId)}
                                helperText={touched.serviceLocationId && errors.serviceLocationId}
                                label={t("formFields.serviceLocation")}
                                options={serviceLocationOptions}
                                name="serviceLocationId"
                                onChange={handleChange}
                            />
                            <ServiceDateCalendar
                                runDetails={runDetails}
                                scheduleEndDate={serviceFrequencyValues.scheduleEndDate}
                                setServiceDateLoading={setIsServiceDateLoading}
                                ref={serviceDateRef}
                                label={t("formFields.serviceDate")}
                                value={values.serviceDate}
                                error={touched.serviceDate && Boolean(errors.serviceDate)}
                                helperText={touched.serviceDate && errors.serviceDate}
                                onChange={async (value) => {
                                    await serviceDateHandler(value);
                                }}
                                onBlur={handleBlur}
                                onRunDetailsFetch={(data) => setRunDetails(data)}
                                getTodayServiceDate={(data) => setIsTodayServiceDate(data)}
                            />

                            <Box sx={styles.calendarTooltip}>
                                <ServiceDateCalendarTooltip />
                            </Box>
                        </Box>
                        <TextField
                            sx={styles.comments}
                            id="new-container-comments"
                            name="comments"
                            label={t("headerMenu.comments")}
                            variant="outlined"
                            multiline
                            fullWidth
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            inputProps={{ maxLength: commentsMaxLength }}
                            rows={4}
                        />
                    </Box>
                    <Divider />
                    <ContainerInformation
                        key={`containerForm${simpleFormResetter}`}
                        lockTypes={dropdownData.lockTypes}
                        insertTypes={dropdownData.insertTypes}
                        vaultIds={dropdownData.vaultIds}
                        containerTypes={dropdownData.containerTypes}
                        defaultLock={dropdownData.defaultLock}
                        globalAttr={globalAttr}
                        requestedContainers={requestedContainers}
                        setRequestedContainers={setRequestedContainers}
                        savedCMILabels={savedCMILabels}
                        setInfoMessage={(message) =>
                            setInfoStatus({
                                show: Boolean(message),
                                message,
                            })
                        }
                        onFormChange={(status) => {
                            setChildFormStatus({
                                ...childFormStatus,
                                containerInfo: status,
                            });
                        }}
                        onClear={onClear}
                    />
                    <Box>
                        {/* Extra Locks */}
                        <Box sx={{ ...styles.fourColumnLayout, ...styles.extraInserts }}>
                            <Box sx={styles.extraLocksWrapper}>
                                <PageSubTitle>
                                    <Trans i18nKey={"titles.extraLocks"} />
                                </PageSubTitle>
                                <Box sx={styles.extraLocksFieldsWrapper}>
                                    <TextField
                                        label={t("formFields.lockQty")}
                                        inputProps={{ maxLength: 3 }}
                                        error={touched.extraLocksQuantity && Boolean(errors.extraLocksQuantity)}
                                        helperText={touched.extraLocksQuantity && errors.extraLocksQuantity}
                                        value={values.extraLocksQuantity}
                                        name="extraLocksQuantity"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <Box>
                                        <SecureSyncSelect
                                            showBlankOption
                                            label={t("formFields.lockType")}
                                            options={lockTypeOptions}
                                            name="extraLockType"
                                            error={touched.extraLockType && Boolean(errors.extraLockType)}
                                            helperText={touched.extraLockType && errors.extraLockType}
                                            value={values.extraLockType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <PageSubTitle>
                                    <Trans i18nKey={"titles.clips"} />
                                </PageSubTitle>
                                <TextField
                                    inputProps={{ maxLength: 5 }}
                                    error={touched.clipQuantity && Boolean(errors.clipQuantity)}
                                    helperText={touched.clipQuantity && errors.clipQuantity}
                                    value={values.clipQuantity}
                                    label={t("formFields.clipsQty")}
                                    name="clipQuantity"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <ExtraInserts
                            key={`extraInsertsForm${simpleFormResetter}`}
                            isPurchase={values.isPurchase}
                            maxExtraInsert={globalAttr.maxExtraInsert}
                            extraInsertTypes={updatedDropdownData(dropdownData.extraInsertTypes)}
                            requestedInserts={requestedInserts}
                            setRequestedInserts={setRequestedInserts}
                            onPurchaseChange={(isPurchase) => {
                                setFieldValue("isPurchase", isPurchase);
                                setFieldTouched("isPurchase", true);
                            }}
                            setInfoMessage={(message) =>
                                setInfoStatus({
                                    show: Boolean(message),
                                    message,
                                })
                            }
                            onFormChange={(status) => {
                                setChildFormStatus({
                                    ...childFormStatus,
                                    extraInsert: status,
                                });
                            }}
                            onClear={onClear}
                        />
                    </Box>
                    <Divider />
                    <Box sx={styles.footerButtons}>
                        <Button variant="outlined" onClick={onCancel}>
                            <Trans i18nKey={"buttonLabels.cancel"} />
                        </Button>
                        <Button
                            variant="outlined"
                            type="reset"
                            onClick={handleFormReset}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            <Trans i18nKey={"buttonLabels.reset"} />
                        </Button>
                        <Button variant="contained" type="submit" title={getKeyBoardLabel(shortCutKeys.submit)}>
                            <Trans i18nKey={"buttonLabels.submit"} />
                        </Button>
                    </Box>
                </form>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.newLockContainer}
            />
        </>
    );
};

export default NewContainerLockInsert;
