import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { oktaAuthConfig } from "src/config/oktaConfig";

export const OktaSecurityConfig = ({ children }) => {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || "/", window?.location?.origin), { replace: true });
    };

    const oktaAuth = useMemo(() => {
        return new OktaAuth(oktaAuthConfig);
    }, []);

    const customAuthHandler = () => {
        navigate("/");
    };

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
};
