import { sanitizeApiData } from "src/utils/commonHelper";

const defaultErrorMsg = "System error occurred. Please contact system support or try again later;";

const traceHttp = false;

/**
 * @name restArgs Builds the fetch request for a rest operation.
 * @param {string} method Method such as POST, GET.
 * @param {object} body Body for the request.
 * @param {object} headers request header info
 * @returns {object} The fetch request object.
 */
const restArgs = (method, body, headers, ignoreSanitization = false) => {
    let newBody = body;
    if (method === "POST" && typeof body === "object" && !ignoreSanitization) {
        newBody = sanitizeApiData(body);
    }
    if (method === "GET") {
        return {
            method,
            headers: headers,
        };
    }
    return {
        method,
        body: JSON.stringify(newBody),
        headers: headers,
    };
};

/**
 *
 * @param {object} response  fetch response info
 * @param {string} url url info for error login
 * @param {number} localHttpId logId
 * @param {number} startTime method start time
 * @returns {object} response object
 */
const handleResponse = async (response, startTime) => {
    if (traceHttp && response.ok) {
        // console.log(`restOperation complete url=${response.url}, httpId=${response.localHttpId}, ${new Date().getTime() - startTime} ms.`)
    } else {
        if (traceHttp) {
            // console.log("error in handleResponse =", response)
        }
    }
    let jsonResponse;
    try {
        jsonResponse = await response.json();
        if (traceHttp) {
            // console.log(" json response = ", jsonResponse);
            // console.log(" json response errors = ", jsonResponse?.errorCode);
        }
    } catch (ex) {
        // const message = `handleResponse json parse error', status=${response.status}, statusText=${response.statusText || ''}, url=${response.url}`
        if (traceHttp) {
            // console.log(message)
        }
        throw new Error(ex);
    }

    if (jsonResponse?.errorCode) {
        const msg = jsonResponse;
        // console.log(" errors = ", errors)
        // console.log(" msg = ", msg);
        // console.log(" message = ", msg.message);
        // throw new Error(msg? msg.message: defaultErrorMsg);
        return new Promise((resolve, reject) => {
            reject({ code: msg.errorCode, reason: msg ? msg.message : defaultErrorMsg });
        });
    }
    return jsonResponse;
};

// let httpId = 0

/**
 *
 * @name restOperation
 * @param {string} url complete URL
 * @param {string} method  GET/POST method type
 * @param {object} body  body content
 * @param {object} headers request header info
 * @returns {object} return json response
 */
export const restOperation = (url, method, body, headers, ignoreSanitization) => {
    // console.log('restOperation')
    // const localHttpId = httpId++
    const startTime = new Date().getTime();
    if (traceHttp) {
        // console.log(`restOperation started url=${url}, httpId=${localHttpId}.`)
        // console.log('restArgs')
        // console.log(restArgs(method, body, headers))
    }
    const args = restArgs(method, body, headers, ignoreSanitization);
    // console.log(args)
    return fetch(url, args).then((response) => handleResponse(response, startTime));
};

/**
 * @name get Performs a rest get operation.
 * @param {string|string[]|Promise<any>} url endpoint for the request if string.
 * Array of endpoints for the request if array of strings. Finally, if Promise<any>
 * then a promise that is a service call.
 * @param {object} reqHeaders request header info
 * @returns {Promise<any>} The fetch result promise.
 */
export const get = async (url, reqHeaders) => {
    let result;
    if (typeof url === "string") {
        result = await restOperation(url, "GET", {}, reqHeaders);
    } else if (Array.isArray(url)) {
        const calls = [];
        for (let i = 0; i < url.length; ++i) {
            calls.push(restOperation(url[i], "GET", {}, reqHeaders));
            result = await Promise.all(calls);
        }
    } else {
        result = await url();
    }
    return result;
};

/**
 * @name post Performs a rest post operation.
 * @param {string} url endpoint for the request.
 * @param {any} postObj Body for the request.
 * @returns {Promise<any>} The fetch result promise.
 */

/**
 * @name post
 * @param {string} url complete URL
 * @param {object} postObj body content
 * @param {headers} headers header info
 * @returns {object} response
 */
export const post = async (url, postObj, headers, ignoreSanitization) => {
    const result = await restOperation(url, "POST", postObj, headers, ignoreSanitization);
    return result;
};

/**
 * @name del Performs a rest del operation.
 * @param {string} url endpoint for the request.
 * @returns {Promise<any>} The fetch result promise.
 */
export const del = (url) => restOperation(url, "DELETE");
