import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Trans, useTranslation } from "react-i18next";
import RequestedInsertsTable from "../RequestedInsertsTable/RequestedInsertsTable";
import styles from "./ExtraInserts.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { requestSortCriteria } from "src/constants/requestConstants";
import { multipleSort } from "src/utils/processMediaHelper";

const insertQty = {
    min: 1,
    max: 999,
};

const insertPurchaseType = {
    purchase: "purchase",
    exchange: "exchange",
};
const validationSchema = (t) => {
    const insertQtyErrorMessage = t("messages.insertQtyRange", { min: insertQty.min, max: insertQty.max });
    return Yup.object().shape({
        insertTypeId: Yup.string().required(t("messages.insertTypeRequired")),
        insertQuantity: Yup.number()
            .typeError(insertQtyErrorMessage)
            .required(t("messages.insertQtyRequired"))
            .integer(insertQtyErrorMessage)
            .min(insertQty.min, insertQtyErrorMessage)
            .max(insertQty.max, insertQtyErrorMessage),
    });
};

const initialValues = {
    insertTypeId: "",
    insertQuantity: "",
};
const ExtraInserts = ({
    extraInsertTypes,
    maxExtraInsert,
    setInfoMessage,
    onFormChange,
    requestedInserts,
    setRequestedInserts,
    onPurchaseChange,
    isPurchase,
    onClear,
}) => {
    const { t } = useTranslation();
    const extraInsertTypesOptions = useMemo(() => {
        if (!extraInsertTypes) {
            return [];
        }
        const sortedInsertTypes = multipleSort(extraInsertTypes, requestSortCriteria.comboBoxSort);
        return sortedInsertTypes.map(({ insert_type_id: typeId, descr: desc }) => {
            return { id: typeId.toString(), value: typeId.toString(), label: desc };
        });
    }, [extraInsertTypes]);
    const formSubmit = (values) => {
        const newRequestedInserts = [...requestedInserts];
        const existingItemsInsertQuantity = requestedInserts.reduce((prevValue, insert) => {
            return prevValue + parseInt(insert.insertQuantity);
        }, 0);
        const totalInsertQuantity = parseInt(existingItemsInsertQuantity) + parseInt(values.insertQuantity);
        if (totalInsertQuantity > maxExtraInsert) {
            setInfoMessage(t("messages.maxExtraInserts", { maxExtraInsert }));
            return;
        } else {
            setInfoMessage("");
            const insertTypeLabel = extraInsertTypesOptions.find(
                (extraInsertType) => extraInsertType.value === values.insertTypeId
            ).label;
            newRequestedInserts.push({
                ...values,
                insertTypeLabel,
                id: uuidv4(),
            });
            setRequestedInserts(newRequestedInserts);
            resetForm({
                values: initialValues,
            });
        }
    };

    const { values, touched, errors, dirty, handleChange, handleSubmit, resetForm, handleReset } = useFormik({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: formSubmit,
    });

    useEffect(() => {
        onFormChange(dirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    const onClickDelete = (id) => {
        const newRequestedInserts = requestedInserts.filter((container) => container.id !== id);
        setRequestedInserts(newRequestedInserts);
    };

    return (
        <Box>
            <Box sx={styles.extraInsertsForm}>
                <PageSubTitle>
                    <Trans i18nKey={"titles.extraInserts"} />
                </PageSubTitle>
                <Box sx={styles.extraInsertsWrapper}>
                    <Box sx={styles.insertType}>
                        <SecureSyncSelect
                            label={t("formFields.insertType")}
                            required
                            showBlankOption
                            options={extraInsertTypesOptions}
                            error={touched.insertTypeId && Boolean(errors.insertTypeId)}
                            helperText={touched.insertTypeId && errors.insertTypeId}
                            value={values.insertTypeId}
                            name="insertTypeId"
                            onChange={handleChange}
                        />
                    </Box>
                    <TextField
                        inputProps={{ maxLength: 3 }}
                        required
                        label={t("formFields.insertQty")}
                        name="insertQuantity"
                        error={touched.insertQuantity && Boolean(errors.insertQuantity)}
                        helperText={touched.insertQuantity && errors.insertQuantity}
                        value={values.insertQuantity}
                        onChange={handleChange}
                    />
                </Box>
                <Box sx={styles.buttonsWrapper}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            handleReset();
                            onClear();
                        }}
                    >
                        <Trans i18nKey={"buttonLabels.clear"} />
                    </Button>
                    <Button variant="contained" type="submit" onClick={handleSubmit}>
                        <Trans i18nKey={"buttonLabels.add"} />
                    </Button>
                </Box>
            </Box>
            {/* Requested Inserts */}
            <Box>
                <PageSubTitle>
                    <Trans i18nKey={"titles.requestedInserts"} />
                </PageSubTitle>
                <FormControl>
                    <RadioGroup
                        row
                        name="insertPurchaseType"
                        value={isPurchase ? insertPurchaseType.purchase : insertPurchaseType.exchange}
                        onChange={(event) => {
                            onPurchaseChange(event.target.value === insertPurchaseType.purchase);
                        }}
                    >
                        <FormControlLabel
                            value={insertPurchaseType.purchase}
                            control={<Radio />}
                            label={t("common.purchase")}
                        />
                        <FormControlLabel
                            value={insertPurchaseType.exchange}
                            control={<Radio />}
                            label={t("newContainerLockInsert.exchangeForExisting")}
                        />
                    </RadioGroup>
                    {!isPurchase && (
                        <FormHelperText sx={styles.toggleHelpText}>
                            <Trans i18nKey={"newContainerLockInsert.insertExchange"} />
                        </FormHelperText>
                    )}
                </FormControl>
                <RequestedInsertsTable records={requestedInserts} onClickDelete={onClickDelete} />
            </Box>
        </Box>
    );
};

export default ExtraInserts;
