import { getDocs } from "firebase/firestore";
import { t } from "i18next";
import {
    constructFbHeaders,
    getLanguageLocaleId,
    getPersonalId,
    getPersonalLocalId,
    getPreferences,
    getSelectedCustomerDb,
    getSelectedCustomerId,
    getSelectedCustomerTimeZoneOffset,
    invokeApi,
    getEmail,
    createArrayObj,
    getProgramIds,
} from "./utils";
import {
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    apiResultSet3Collection,
    apiResultSet4Collection,
    apiResultSet5Collection,
    apiResultSet6Collection,
} from "./firebase";
import { traceWrapper } from "./firebasePerformance";
import { formatDate, timestampToHex } from "src/utils/dateHelper";
import { noFlag, yesFlag } from "src/constants/serviceConstants";
import { dateFormats } from "src/constants/dateFormatConstants";
import { getAll, toolTipTypes } from "src/constants/researchConstants";
import { programIds } from "src/constants/programIdsConstants";

const apiUrls = {
    insertDistributionList: "/ssomdistributionlist/insertdistributionlist",
    getContainers: "/sspreparedatasource/getcontainers",
    getRetentionProfiles: "/sspreparedatasource/getretentionprofiles",
    updateItem: "/sspreparedatasource/updateitem",
    validateContainers: "/sspreparedatasource/validatecontainers",
    updateCloseContainers: "/closecontainerdata/updateclosecontainers",
    getSearchData: "/ssdownloadinventory/getsearchdata",
    insertLoggedEvent: "/sspreparedatasource/insertloggedevent",
    storeFile: "/sssendlistfile/storefile",
    storeFileBinary: "/sssendlistfile/storefilebinary",
    createNewInventory: "/createInventry/createNewInventory",
    getOpenMediaStatus: "/distributionlistdata/getopenmediastatus",
    getLogicalVaultCount: "/distributionlistdata/getlogicalvaultcount",
    getGlobalAttribute: "/globalattribute/getglobalattribute",
    getCustomerFileDefinitions: "/sssendlistfile/getcustomerfiledefinitions",
    populateOMScanVerifyControls: "/sssendlistfile/populateomscanverifycontrols",
    getVerifyResultSummaryAndIssues: "/sssendlistfile/getverifyresultsummaryandissues",
    getVerifyResultSummaryIssues: "/sssendlistfile/getverifyresultsummaryissues",
    addVerifyResult: "/sssendlistfile/addverifyresult",
    verifySession: "/sssendlistfile/verifysession",
};
export const createOMDistributionList = async (
    customerId,
    username,
    volserList = "",
    returnDateList = "",
    descrList = "",
    drpCodeList = "",
    indefinitePhrase,
    serviceDate
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertDistributionList);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/ssomdistributionlist/insertdistributionlist";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId,
        system_id: "50",
        system_function_id: "51",
        last_mod_user: username,
        volser_list: volserList,
        return_date_list: returnDateList,
        descr_list: descrList,
        drp_code_list: drpCodeList,
        indefinite_phrase: indefinitePhrase,
        service_date: serviceDate,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0]?.error && rs0Data[0]?.error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        const distributionList = {
            failure: false,
            customerFileSectionId:
                rs0Data[0]?.customer_file_section_id && rs0Data[0]?.customer_file_section_id
                    ? rs0Data[0]?.customer_file_section_id
                    : "",
        };
        return distributionList;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Returns list of containers for a customer
 * @param {*} customerId
 * @returns
 */
export const getContainers = async (customerId, pickUpFlag) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getContainers);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/getcontainers";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId,
        prepare_for_pickup_flag: pickUpFlag,
        container_number: "",
        descr: "",
        from_return_date: "",
        to_return_date: "",
        summary_type: "",
        media_type_id: "",
        container_id: "",
        locale_id: getPersonalLocalId(),
        customer_media_descr: "",
        language_locale_id: getLanguageLocaleId(),
        sent_to_phrase: "Sent to {0}",
        deleted_phrase: "Deleted -- {0}",
        not_at_iron_mountain_phrase: "Not at Iron Mountain",
        indefinite_phrase: "Indefinite",
        all_phrase: "<ALL>",
        all_excluding_deleted_phrase: "<ALL> Excluding Deleted",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const containers = {
            failure: false,
            rs0: rs0Data,
        };
        return containers;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Returns list of retention profiles for the customer
 * @param {*} customerId  - customer
 * @param {*} mediaProgramTypeId  media program type id
 * @param {*} indefinitePhrase  - indefinite phrase
 * @returns - array of object that contains retention profiles
 */
export const getRetentionProfiles = async (mediaProgramTypeId, indefinitePhrase = "", districtId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRetentionProfiles);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/getretentionprofiles";
    const body = {
        main_district_id: districtId ? districtId : getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        locale_id: getPersonalLocalId(),
        indefinite_phrase: indefinitePhrase ? indefinitePhrase : t("containerPickup.indefinite"),
    };

    try {
        if (mediaProgramTypeId) {
            body.media_program_type_id = mediaProgramTypeId;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const retentionProfiles = {
            failure: false,
            profiles: rs0Data,
        };
        return retentionProfiles;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Updates container item
 * @param {*} containerItemObject - container object
 * @returns an object with updated container
 */
export const updateContainerItem = async (containerItemObject) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateItem);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/updateitem";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        new_return_date: containerItemObject.newReturnDate || "",
        container_id: containerItemObject.containerId || "",
        new_descr: containerItemObject.newDescription || "",
        personnel_id: containerItemObject.personnelId || "",
        prepare_for_pickup_flag: containerItemObject.prepareForPickup || "",
        return_date_change_flag: containerItemObject.returnDateChange || "",
        login: containerItemObject.username || "",
        container_timestamp: containerItemObject.containerTimestamp || timestampToHex(),
        retention_profile_id: containerItemObject.retentionProfileId || "",
        deleted_phrase: containerItemObject.deletedPhrase || "",
        sent_to_phrase: containerItemObject.sentToPhrase || "",
        not_at_ironmountain_phrase: containerItemObject.notAtIronmountainPhrase || "",
        indefinite_phrase: containerItemObject.indefinitePhrase || "",
        all_phrase: containerItemObject.allPhrase || "",
        all_excluding_deleted_phrase: containerItemObject.allExcludingDeletedPhrase || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const updatedContainer = {
            failure: false,
            rs0: rs0Data,
        };
        return updatedContainer;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Validates container status against backend
 * @param {*} customerId  customer - selected customer
 * @param {*} containerList - container list
 * @param {*} invalidContainerNumber  - invalid container number phrase
 * @param {*} invalidContainerSubmitted  invalid container submitted phrase
 * @param {*} invalidContainerStatus invalid container status phrase
 * @returns list of object array
 */
export const validateContainers = async (
    customerId,
    containerList,
    invalidContainerNumber,
    invalidContainerSubmitted,
    invalidContainerStatus
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateContainers);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/validatecontainers";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        function: "ss_fs_validate_containers_sp",
        customer_id: customerId,
    };

    try {
        if (containerList) {
            body.container_list = containerList;
        }
        if (invalidContainerNumber) {
            body.invalid_container_number_phrase = invalidContainerNumber;
        }
        if (invalidContainerSubmitted) {
            body.invalid_container_submitted_phrase = invalidContainerSubmitted;
        }
        if (invalidContainerStatus) {
            body.invalid_container_status_phrase = invalidContainerStatus;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const containers = {
            failure: false,
            containerListRes: rs0Data,
            mediaListRes: rs1Data,
        };
        return containers;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Update Close Container
 * @param {*} customerId  customer - selected customer
 * @param {*} containerList - container list
 * @returns list of object array
 */
export const updateCloseContainers = async (customerId, containerList) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateCloseContainers);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/closecontainerdata/updateclosecontainers";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        function: "ss_fs_validate_containers_sp",
        customer_id: customerId,
        filename: "",
    };

    try {
        if (containerList) {
            body.container_list = containerList;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        const containers = {
            failure: false,
            rs0: rs0Data,
            rs1: rs1Data,
            rs2: rs2Data,
        };
        return containers;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Retrieves data to be downloaded
 * @param {*} customerId
 * @param {*} omPhrase
 * @param {*} containerPhrase
 * @param {*} allPhrase
 * @returns
 */
export const searchData = async (customerId, omPhrase, containerPhrase, allPhrase) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getSearchData);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/ssdownloadinventory/getsearchdata";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId,
    };

    try {
        if (omPhrase) {
            body.open_media_phrase = omPhrase;
        }
        if (containerPhrase) {
            body.container_phrase = containerPhrase;
        }
        if (allPhrase) {
            body.all_phrase = allPhrase;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        return { failure: false, rs0: rs0Data };
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Inserts logged in event
 * @param {*} comment
 * @param {*} description
 * @returns
 */
export const insertLoggedEvent = async (
    comment,
    description,
    source = "Process media - firebase",
    operationTypeId = "5"
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertLoggedEvent);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/insertloggedevent";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        login: getEmail(),
        event_type_id: "1",
        system_id: "50",
        app_version: process.env.REACT_APP_SS_APP_VERSION,
        source: source,
        operation_type_id: operationTypeId,
        session_guid: getPreferences()?.sessionGuid[0]?.session_guid || "",
    };

    try {
        if (comment) {
            body.comment = comment;
        }
        if (description) {
            body.description = description;
        }

        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const authPin = {
            failure: false,
            rs0: rs0Data,
        };
        return authPin;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Stores file in backend
 * @param {*} customerFileObj
 * @returns
 */
export const storeFile = async (customerFileObj) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.storeFile);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/storefile";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_file_definition_id: customerFileObj.customerFileDefinition || "",
        path: customerFileObj.path || "",
        file_name: customerFileObj.fileName || "",
        file_text: customerFileObj.file || "",
        error_msg: customerFileObj.errorMsg || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);
        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        return { failure: false, rs0: rs0Data, rs1: rs1Data, rs2: rs2Data };
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Stores the binary file
 * @param {*} customerFileObj
 * @returns
 */
export const storeFileBinary = async (customerFileObj) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.storeFileBinary);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/storefilebinary";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_file_definition_id: customerFileObj.customerFileDefinition || "",
        path: customerFileObj.path || "",
        file_name: customerFileObj.fileName || "",
        file_blob: customerFileObj.file || "",
        error_msg: customerFileObj.errorMsg || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);
        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        return { failure: false, rs0: rs0Data, rs1: rs1Data, rs2: rs2Data };
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * create Inventory
 * @param {*} inventoryObject
 * @returns
 */
export const createInventory = async (inventoryObject) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createNewInventory);
    traceStart();
    const headers = constructFbHeaders();
    const createInventoryURL = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/createInventry/createNewInventory";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        filter: inventoryObject.inventory || "",
        file_format_type: inventoryObject.fileFormat || "",
        include_in_transit_not_at_im_flag: inventoryObject.includeMedia || "",
        include_column_headings_flag: inventoryObject.includeHeader || "",
        locale_id: getPersonalLocalId(),
        container_constant: inventoryObject.containerConstant || "",
        openmedia_constant: inventoryObject.openMediaConstant || "",
        inventory_list_phrase: inventoryObject.inventoryListPhrase || "",
        customer_phrase: inventoryObject.customerPhrase || "",
        media_phrase: inventoryObject.mediaPhrase || "",
        media_identifier_phrase: inventoryObject.mediaIdentifierPhrase || "",
        logical_vault_phrase: inventoryObject.logicalVaultPhrase || "",
        drp_phrase: inventoryObject.drpPhrase || "",
        return_date_phrase: inventoryObject.returnDatePhrase || "",
        media_type_phrase: inventoryObject.mediaTypePhrase || "",
        description_phrase: inventoryObject.descriptionPhrase || "",
        container_om_phrase: inventoryObject.containerOmPhrase || "",
        slot_phrase: inventoryObject.slotPhrase || "",
        date_in_phrase: inventoryObject.dateInPhrase || "",
        in_employee_phrase: inventoryObject.inEmployeePhrase || "",
        date_out_phrase: inventoryObject.dateOutPhrase || "",
        out_employee_phrase: inventoryObject.outEmployeePhrase || "",
        customer_name: inventoryObject.customerName || "",
        createddate: inventoryObject.createdDate || "",
        current_status_code: inventoryObject.currentStatusCode || "",
        generation_phrase: inventoryObject.generationPhrase || "",
    };

    try {
        const res = await invokeApi("POST", createInventoryURL, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot] = await Promise.all([getDocs(apiResultSet0Collection(resDoc))]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0]?.error) {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        return { failure: false, inventoryData: rs0Data };
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Open media status
 * @param {*} openMediaNumber  openMediaNumber
 * @returns list of object array
 */
export const getOpenMediaStatus = async (openMediaNumber) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getOpenMediaStatus);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/distributionlistdata/getopenmediastatus";
    const body = {
        function: "ss_fs_process_media_hub_sp",
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        open_media_number: openMediaNumber || "",
        language_locale_id: getLanguageLocaleId(),
        personnel_id: getPersonalId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot] = await Promise.all([getDocs(apiResultSet0Collection(resDoc))]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const processMediaStatus = {
            failure: false,
            rs0: rs0Data,
        };
        return processMediaStatus;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Logical Vault Count
 * @returns list of object array
 */
export const getLogicalVaultCount = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getLogicalVaultCount);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/distributionlistdata/getlogicalvaultcount";
    const body = {
        customer_id: getSelectedCustomerId(),
        main_district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0]?.error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const data = {
            failure: false,
            rs0: rs0Data,
            rs1: rs1Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Global Attribute Values
 * @returns list of object array
 */
export const getGlobalAttributeValues = async ({ systemId = "60", mainDistrictId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getGlobalAttribute);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/globalattribute/getglobalattribute";
    const body = {
        system_id: systemId,
        main_district_id: mainDistrictId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0]?.error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const data = {
            failure: false,
            rs0: rs0Data,
            rs1: rs1Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Customer File Definition
 * @returns list of object array
 */
export const getCustomerFileDefinitions = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getCustomerFileDefinitions);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/getcustomerfiledefinitions";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0]?.error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const data = {
            failure: false,
            responseData: rs0Data,
            error: rs1Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Populate OM Scan Verify Controles
 * @returns list of object array
 */
export const populateOMScanVerifyControls = async (OMScanVerificationObj) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.populateOMScanVerifyControls);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/populateomscanverifycontrols";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        language_locale_id: getLanguageLocaleId(),
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        send_open_media_phrase: OMScanVerificationObj.sendOpenMediaPhrase || "",
        receive_open_media_phrase: OMScanVerificationObj.receiveOpenMediaPhrase || "",
        receive_close_container_phrase: OMScanVerificationObj.receiveCloseContainerPhrase || "",
        closed_container_phrase: OMScanVerificationObj.closedContainerPhrase || "",
        securesync_om_distribution_phrase: OMScanVerificationObj.secureSyncOmDistributionPhrase || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot, rs5Snapshot, rs6Snapshot] =
            await Promise.all([
                getDocs(apiResultSet0Collection(resDoc)),
                getDocs(apiResultSet1Collection(resDoc)),
                getDocs(apiResultSet2Collection(resDoc)),
                getDocs(apiResultSet3Collection(resDoc)),
                getDocs(apiResultSet4Collection(resDoc)),
                getDocs(apiResultSet5Collection(resDoc)),
                getDocs(apiResultSet6Collection(resDoc)),
            ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });
        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });
        const rs3Data = [];
        rs3Snapshot.forEach((doc) => {
            rs3Data.push(doc.data());
        });
        const rs4Data = [];
        rs4Snapshot.forEach((doc) => {
            rs4Data.push(doc.data());
        });
        const rs5Data = [];
        rs5Snapshot.forEach((doc) => {
            rs5Data.push(doc.data());
        });
        const rs6Data = [];
        rs6Snapshot.forEach((doc) => {
            rs6Data.push(doc.data());
        });

        const data = {
            failure: false,
            actionDropdown: rs0Data,
            listDropdown: rs1Data,
            logicalVaultDropdown: rs2Data,
            scanVerifyKeepAlive: rs3Data,
            mediaTypes: rs4Data,
            verifyResultRecords: rs5Data,
            responseData6: rs6Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Verify Result Summary And Issues
 * @returns list of object array
 */
export const getVerifyResultSummaryAndIssues = async (verifyResultId, scannedPhrase) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getVerifyResultSummaryAndIssues);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/getverifyresultsummaryandissues";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        verify_result_id: verifyResultId || "",
        scanned_phrase: scannedPhrase || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
            getDocs(apiResultSet3Collection(resDoc)),
            getDocs(apiResultSet4Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });
        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });
        const rs3Data = [];
        rs3Snapshot.forEach((doc) => {
            rs3Data.push(doc.data());
        });
        const rs4Data = [];
        rs4Snapshot.forEach((doc) => {
            rs4Data.push(doc.data());
        });

        const data = {
            failure: false,
            metaData: rs0Data,
            allSummaryDetails: rs1Data,
            unresolvedSummaryDetails: rs2Data,
            resolvedSummaryDetails: rs3Data,
            responseData4: rs4Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * Get Verify Result Summary And Issues
 * @returns list of object array
 */
export const getVerifyResultSummaryIssues = async (resultSummaryIssuesObj) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getVerifyResultSummaryIssues);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/getverifyresultsummaryissues";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
        customer_id: getSelectedCustomerId(),
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        verify_result_id: resultSummaryIssuesObj.verifyResultId || "",
        scanned_phrase: resultSummaryIssuesObj.scannedPhrase || "",
        customer_file_section_id: resultSummaryIssuesObj.customerFileSectionId || "",
        customer_file_id: resultSummaryIssuesObj.customerFileId || "",
        logical_vault_id: resultSummaryIssuesObj.logicalVaultId || "",
        request_id: resultSummaryIssuesObj.requestId || "",
        volser_list: resultSummaryIssuesObj.mediaList || "",
        container_id: resultSummaryIssuesObj.containerId || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });
        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        const data = {
            failure: false,
            metaData: rs0Data,
            allSummaryDetails: rs1Data,
            responseData2: rs2Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * addVerifySummaryObj
 * @returns list of object array
 */
export const addVerifySummary = async (addVerifySummaryObj) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.addVerifyResult);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/addverifyresult";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        offset_hours: getSelectedCustomerTimeZoneOffset(),
        verify_result_id: addVerifySummaryObj.verifyResultId || "",
        customer_file_section_id: addVerifySummaryObj.customerFileSectionId || "",
        request_id: addVerifySummaryObj.requestId || "",
        container_id: addVerifySummaryObj.containerId || "",
        verify_type_id: addVerifySummaryObj.verifyTypeId || "",
        count: addVerifySummaryObj.count || "",
        descr: addVerifySummaryObj.descr || "",
        list_creation_date: addVerifySummaryObj.listCreationDate || "",
        logical_vault_descr: addVerifySummaryObj.logicalVaultDescr || "",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const data = {
            failure: false,
            responseData0: rs0Data,
            responseData1: rs1Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

/**
 * addVerifySummaryObj
 * @returns list of object array
 */
export const verifyAndUpdateIssueItem = async (verifyResultId, xmlInformation, scanCount) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.verifySession);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sssendlistfile/verifysession";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        personnel_id: getPersonalId(),
        verify_result_id: verifyResultId || "",
        xml_information: xmlInformation || "",
        scan_count: scanCount || "0",
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers, true);
        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0]?.error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        const data = {
            failure: false,
            responseData0: rs0Data,
        };
        return data;
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};

export const searchContainer = async (values) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getContainers);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_PROCESSMEDIA + "/sspreparedatasource/getcontainers";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        prepare_for_pickup_flag: noFlag,
        container_number: values.containerNumber ? values.containerNumber : "",
        descr: values.description ? values.description : "",
        from_return_date: values.returnDateFrom ? formatDate(values.returnDateFrom, dateFormats.displayDate) : "",
        to_return_date: values.returnDateTo ? formatDate(values.returnDateTo, dateFormats.displayDate) : "",
        summary_type: values.currentStatus ? values.currentStatus : "",
        media_type_id: values.mediaType && values.mediaType !== getAll ? values.mediaType : "",
        container_id: values.containerId ? values.containerId : "",
        locale_id: getPersonalLocalId(),
        customer_media_descr: values.customerMediaIdentifier,
        language_locale_id: getLanguageLocaleId(),
        sent_to_phrase: t("localizedServiceDetails.sentTo0"),
        deleted_phrase: t("localizedServiceDetails.deleted0"),
        not_at_iron_mountain_phrase: t("localizedServiceDetails.notAtIronMountain"),
        indefinite_phrase: t("localizedServiceDetails.indefinite"),
        all_phrase: t("localizedServiceDetails.allPhrase"),
        all_excluding_deleted_phrase: t("localizedServiceDetails.allExcludingDeletedPhrase"),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];

        rs0Snapshot.forEach((doc) => {
            const data = doc.data();
            let reportReturnDate = data.Display_Return_Date;
            if (data.At_Iron_Mtn_Flag === noFlag && data.In_Transit_Flag === noFlag) {
                reportReturnDate = "";
            }
            let iconState = toolTipTypes.none;
            if (data.part_of_dr_bit === "true") {
                iconState = toolTipTypes.dRInformation;
            } else if (
                data.In_Discrepancy_Flag === yesFlag ||
                data.In_Transit_Flag === yesFlag ||
                data.To_Be_Issued_Flag === yesFlag
            ) {
                iconState = toolTipTypes.information;
            }
            const havePermission = getProgramIds().some((el) => el === programIds.programId_216);
            const isContainer = data.Container_Transport_Flag === "C" ? true : false;
            const isInDescrepancy = data.In_Discrepancy_Flag === "Y" ? true : false;
            const isNotAtIronMtn = data.Not_At_Iron_Mtn_Flag === "Y" ? true : false;
            const isInTransit = data.In_Transit_Flag === "Y" ? true : false;
            const isToBeIssued = data.To_Be_Issued_Flag === "Y" ? true : false;
            const isAtIronMtn = data.At_Iron_Mtn_Flag === "Y" ? true : false;
            const isDescriptionEditable =
                ((isAtIronMtn && !isToBeIssued) || isNotAtIronMtn) && havePermission && isContainer && !isInDescrepancy;
            const isProfileEditable =
                ((isAtIronMtn && !isToBeIssued) || isNotAtIronMtn) && havePermission && isContainer && !isInDescrepancy;

            let toolTipMsg = "";
            let failureToolTip = "";

            if (!isDescriptionEditable && !isProfileEditable) {
                if (iconState === toolTipTypes.dRInformation) {
                    toolTipMsg = t("apiErrorMessages.80101", { item: t("common.openMedia") });
                }
                if (data.In_Discrepancy_Flag === noFlag) {
                    if (data.In_Transit_Flag === yesFlag) {
                        failureToolTip = t("apiErrorMessages.63051", { buttonLabel: t("common.inTransit") });
                    } else if (data.To_Be_Issued_Flag === yesFlag) {
                        failureToolTip = t("apiErrorMessages.63054");
                    }
                }
            }

            rs0Data.push({
                ...data,
                Report_Return_Date: reportReturnDate,
                Return_Date_Null: data.Return_Date ? "1" : "0",
                Pending_Return_Date_Null: data.Pending_Return_Date_Local ? "1" : "0",
                Failure_Code: "0",
                Pending_Changes: "0",
                Include_On_Manifest_Flag: "0",
                In_Discrepancy_Report_Info: "",
                IconState: iconState,
                isDescriptionEditable,
                isToBeIssued,
                isAtIronMtn,
                isProfileEditable,
                isInTransit,
                isContainer,
                toolTipMsg: `${toolTipMsg} ${failureToolTip}`,
            });
        });
        const error = createArrayObj(rs1Snapshot);
        return {
            failure: error,
            containers: rs0Data,
        };
    } catch (err) {
        return { failure: true, message: err.message };
    } finally {
        traceStop();
    }
};
