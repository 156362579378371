import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./DescriptionSection.styles";

const DescriptionSection = ({ values, handleBlur, handleChange, touched, errors }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.boxPaddingBottom}>
                <TextField
                    id="description"
                    name="description"
                    label={t("containerPickup.description")}
                    variant="outlined"
                    multiline
                    fullWidth
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ maxLength: 2048 }}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    InputProps={{
                        sx: {
                            overflow: "auto",
                        },
                    }}
                    required
                    rows={4}
                />
            </Box>
        </>
    );
};
export default DescriptionSection;
