import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import styles from "./Footer.styles";
import footerLogo from "src/assets/images/irmLogoWithoutName.svg";
import { getVersion } from "src/utils/commonHelper";

export const Footer = () => {
    const version = getVersion();
    return (
        <Box component="footer">
            <Divider sx={styles.footer} />
            <Container maxWidth="off" sx={styles.footerContainer}>
                <Grid container spacing={2} sx={styles.gridContainer}>
                    <Grid item xs={12} sm={6} sx={styles.gridItemLeft}>
                        <img loading="lazy" src={footerLogo} alt="Iron Mountain Logo" style={styles.footerLogo} />
                        <Typography variant="body2" sx={styles.typography}>
                            <Trans
                                i18nKey={"common.pageFooter"}
                                values={{
                                    version,
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
