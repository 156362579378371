const styles = {
    redColorTxt: {
        color: "im_red.500",
    },
    accordionWrapper: {
        mb: 1,
    },
    mediaAccordion: {
        borderRadius: "8px 8px 0px 0px",
    },
    containerAccordion: {
        borderRadius: "0px 0px 8px 8px",
    },
    fieldContainer: {
        display: "flex",
        width: "250px",
        gap: "20px",
        mb: "20px",
    },
};

export default styles;
