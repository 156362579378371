import { useSelector } from "react-redux";
import { UnAuthorized } from "../UnAuthorized/UnAuthorized";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { getAccessRights } from "src/redux/userDetailsSlice";

export default function ProtectedRoute({ component: Component, hasAccessToView }) {
    const { status } = useSelector(getAccessRights);
    if (status !== fetchStatus.completed) {
        return <CircularLoaderFullPage show />;
    } else if (hasAccessToView) {
        return <Component />;
    } else {
        return <UnAuthorized />;
    }
}
