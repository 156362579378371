import { TextField } from "@mui/material";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SecureSyncDatePicker.styles";
import { CustomDatePickerHeader } from "./CustomDatePickerHeader/CustomDatePickerHeader";
import DateRangeFilled from "src/components/Icons/DateRangeFilled/DateRangeFilled";
import { getDisplayFormat } from "src/utils/dateHelper";

const defaultDatePropMethod = () => {
    return { highlight: false, disable: false, customStyle: {} };
};

const SecureSyncDatePicker = ({
    label,
    helperText,
    error,
    onChange,
    onChangeRaw,
    value = null,
    displayFormat = getDisplayFormat(),
    onBlur = () => {},
    getDateProp = defaultDatePropMethod,
    customDateValue,
    required = false,
    ...props
}) => {
    const [data, setData] = useState(value);
    const { t } = useTranslation();
    const handleDateChange = (date) => {
        setData(date);
        if (onChangeRaw) {
            onChangeRaw(date);
        }
        if (onChange) {
            onChange(date ? date.format(displayFormat) : null);
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur();
        }
    };
    useEffect(() => {
        if (value) {
            setData(dayjs(value, displayFormat));
        } else {
            setData(null);
        }
    }, [value, displayFormat]);

    const CustomerPickDay = useCallback(
        (props) => {
            const { disabled = false, highlight = false, customStyle } = getDateProp(props.day);

            const disableDate = disabled ? disabled : props.disabled;
            let dateStyle = {};
            if (customStyle) {
                dateStyle = customStyle;
            } else if (props.selected) {
                dateStyle = styles.selected;
            } else if (disableDate) {
                dateStyle = styles.disabledDay;
            } else if (highlight) {
                dateStyle = styles.highlightedDates;
            }

            return <PickersDay {...props} disabled={disableDate} sx={{ ...styles.hover, ...dateStyle }} />;
        },
        [getDateProp]
    );
    const CustomTextField = useCallback(
        (props) => {
            let value = props.value;
            if (customDateValue) {
                value = customDateValue;
            }
            return <TextField {...props} value={value} required={required} />;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customDateValue]
    );

    const validValue = dayjs(data).isValid() ? data : null;
    const isError = !validValue && data !== null;

    return (
        <DatePicker
            label={label}
            slots={{
                openPickerIcon: DateRangeFilled,
                day: CustomerPickDay,
                textField: CustomTextField,
                calendarHeader: CustomDatePickerHeader,
            }}
            format={displayFormat}
            value={validValue}
            slotProps={{
                textField: {
                    helperText,
                    error: error ? error : isError,
                    required,
                    title: t("messages.datePickerTooltip", { format: displayFormat }),
                },
            }}
            onChange={handleDateChange}
            onBlur={handleBlur}
            {...props}
        />
    );
};

export default SecureSyncDatePicker;
