import { IconButton, Tooltip, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import InfoCircle from "src/components/Icons/InfoCircle/InfoCircle";

const SecureSyncIconTooltip = ({ title, icon, onClick, buttonSx = {}, ...props }) => {
    const theme = useTheme();
    const tooltipIcon = icon || <InfoCircle fill={theme.palette.primary.main1} />;
    return (
        <Tooltip title={title} {...props}>
            <IconButton
                sx={buttonSx}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                {tooltipIcon}
            </IconButton>
        </Tooltip>
    );
};
SecureSyncIconTooltip.propTypes = {
    title: PropTypes.any,
    icon: PropTypes.node,
    props: PropTypes.any,
};
export default SecureSyncIconTooltip;
