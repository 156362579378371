import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { routePaths } from "../constants/routeConstants/routeConstants";
import AdministrationRouter from "./AdministrationRouter/AdministrationRouter";
import OperationsRouter from "./OperationsRouter/OperationsRouter";
import ProcessMediaRouter from "./ProcessMediaRouter/ProcessMediaRouter";
import RequestRouter from "./RequestRouter/RequestRouter";
import AuthRoute from "src/components/AppComponents/AuthRoute/AuthRoute";
import MainLayout from "src/components/AppComponents/MainLayout/MainLayout";
import { Dashboard } from "src/pages/Dashboard/Dashboard";
import { HealthCheck } from "src/pages/HealthCheck/HealthCheck";
import { NotFound } from "src/pages/NotFound/NotFound";
import PostLoginCheck from "src/pages/PostLoginCheck/PostLoginCheck";
import { SignIn } from "src/pages/SignIn/SignIn";
import ProtectedRoute from "src/components/AppComponents/ProtectedRoute/ProtectedRoute";
import { getAccessRights } from "src/redux/userDetailsSlice";
import { useShowMenuOptions } from "src/customHooks";
import Resources from "src/pages/Resources/Resources";
import Suggestions from "src/pages/Suggestions/Suggestions";

export const RouterConfig = () => {
    const {
        dashboard,
        administration,
        processMedia,
        request,
        healthCheck,
        operations,
        login,
        root,
        externalResources,
        suggestions,
    } = routePaths;
    const { data: accessRights } = useSelector(getAccessRights);
    const showMenuOptions = useShowMenuOptions();
    const menuAccess = accessRights.menu || {};
    return (
        <Routes>
            <Route element={<MainLayout />} errorElement={<NotFound />}>
                <Route exact path="/" element={<SignIn />} />
                <Route path={`${root}/*`} element={<AuthRoute />}>
                    <Route path={healthCheck} element={<HealthCheck />} />
                    <Route path={login} element={<PostLoginCheck />} />
                    <Route
                        path={dashboard}
                        element={<ProtectedRoute hasAccessToView={showMenuOptions} component={Dashboard} />}
                    />
                    <Route
                        path={externalResources}
                        element={<ProtectedRoute hasAccessToView={showMenuOptions} component={Resources} />}
                    />
                    <Route
                        path={suggestions}
                        element={
                            <ProtectedRoute hasAccessToView={accessRights.page?.suggestions} component={Suggestions} />
                        }
                    />
                    <Route
                        path={`${administration.home}/*`}
                        element={
                            <ProtectedRoute
                                hasAccessToView={menuAccess.authorization || menuAccess.admin}
                                component={AdministrationRouter}
                            />
                        }
                    />
                    <Route
                        path={`${processMedia.home}/*`}
                        element={
                            <ProtectedRoute hasAccessToView={menuAccess.processMedia} component={ProcessMediaRouter} />
                        }
                    />
                    <Route
                        path={`${request.home}/*`}
                        element={<ProtectedRoute hasAccessToView={menuAccess.request} component={RequestRouter} />}
                    />
                    <Route
                        path={`${operations.home}/*`}
                        element={
                            <ProtectedRoute
                                hasAccessToView={menuAccess.research || menuAccess.disasterRecovery}
                                component={OperationsRouter}
                            />
                        }
                    />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default RouterConfig;
