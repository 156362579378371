import { Box, Button, Divider, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./CancelRequest.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { cancelRequest } from "src/services/requestServices";
import { getBasicUserInfo } from "src/redux/userDetailsSlice";
import { getApiErrorMessage } from "src/services/utils";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import i18n from "src/i18n";
import { sortHelper } from "src/utils/sortHelper";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";
const statusMessage = {
    show: false,
    status: "",
    message: " ",
};

const CancelRequest = () => {
    const location = useLocation();
    const { requestDetails, cancelRequestData } = location.state || {};
    const { requestId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const userDetails = useSelector(getBasicUserInfo);
    const [responseMessage, setResponseMessage] = useState(statusMessage);
    const goBack = () => {
        navigate(-1);
    };

    const noDataMsg = (
        <Trans i18nKey={"messages.pleaseEnterUserSearchCriteria"} values={{ buttonLabel: t("buttonLabels.findAll") }} />
    );
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "container_number", headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")) },
            { field: "media_type_descr", headerName: convertToLocaleUpperCase(t("tableHeader.mediaType")) },
            { field: "new_service_date", headerName: convertToLocaleUpperCase(t("tableHeader.nextServiceDate")) },
            { field: "new_retention_flag", headerName: convertToLocaleUpperCase(t("tableHeader.indefiniteRetention")) },
            { field: "comment", headerName: t("common.comments").toLocaleUpperCase(i18n.language) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedContainerList = sortHelper({
        data: cancelRequestData,
        primaryProperty: "container_number",
        sortOrder: "asc",
    });

    const handleCancelRequest = async () => {
        const errorMsg = cancelRequestData[0].error;
        setLoading(true);
        if (errorMsg === "0" || errorMsg === "1") {
            setLoading(true);
            handleCancel();
        } else {
            const errorCodeMsg =
                errorMsg === "59511"
                    ? t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("25357") })
                    : t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("61220") });
            const statusMessage = {
                show: true,
                status: "error",
                message: errorCodeMsg,
            };
            setResponseMessage(statusMessage);
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        const payload = {
            requestId: requestDetails?.request_id,
            timeStamp: requestDetails?.timestamp,
            lastModUser: userDetails?.username,
            personnelID: userDetails?.sys_user_id,
        };

        const { requestUpdate } = await cancelRequest(payload);
        if (requestUpdate[0].err_msg === "0") {
            const statusMessage = {
                show: true,
                status: "success",
                message: t("messages.requestCancelledSuccessfully", { reqId: requestId }),
            };
            setResponseMessage(statusMessage);
            setLoading(false);
            setTimeout(() => {
                goBack();
            }, 5000);
        } else {
            handleSetError(requestUpdate[0].err_msg);
        }
    };

    const handleSetError = (errorMsg) => {
        const errorCodeMsg =
            errorMsg === "59511"
                ? t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("25357") })
                : t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("61220") });
        const statusMessage = {
            show: true,
            status: "error",
            message: errorCodeMsg,
        };
        setResponseMessage(statusMessage);
        setLoading(false);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <PageTitle>{t("cancelRequest.cancelRequest")}</PageTitle>
            {responseMessage.show && responseMessage.status === "error" && (
                <SecureSyncAlert
                    severity={"error"}
                    message={responseMessage.message}
                    onClose={() => setResponseMessage(statusMessage)}
                />
            )}
            <SecureSyncSnackBar
                openSnackBar={responseMessage.show && responseMessage.status === "success"}
                message={responseMessage.message}
                severity="success"
                onClose={() => setResponseMessage(statusMessage)}
            />
            <Typography sx={styles.cancelRequestMsg}>{t("cancelRequest.cancelRequestMsg")}</Typography>
            <Typography sx={styles.confirmCancelMsg}>
                <Trans i18nKey={"cancelRequest.confirmCancelMsg"} />
            </Typography>
            <Box sx={styles.container}>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        {cancelRequestData.length > 0 && (
                            <>
                                <Box>
                                    <Typography variant="body1">
                                        {t("messages.recordsFound", { count: cancelRequestData.length })}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={sortedContainerList}
                            columns={columns}
                            noDataMessage={cancelRequestData.length === 0 ? noDataMsg : t("messages.noMatchingRecords")}
                        />
                    </Box>
                </SecureSyncTableCard>
            </Box>
            <Divider />
            <Box sx={styles.buttonContainer}>
                <Button
                    id="cancel"
                    variant="outlined"
                    onClick={() => {
                        goBack();
                    }}
                >
                    {t("buttonLabels.cancel")}
                </Button>
                <Button
                    id="ok"
                    variant="contained"
                    onClick={() => {
                        handleCancelRequest();
                    }}
                    color="primary"
                >
                    {t("buttonLabels.ok")}
                </Button>
            </Box>
        </>
    );
};

export default CancelRequest;
