import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHotkeysContext } from "react-hotkeys-hook";
import styles from "./DeliverMediaModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { getApiErrorMessage } from "src/services/utils";
import { useSecureSyncHotKeys } from "src/customHooks";
import { hotKeysScopes } from "src/constants/shortCutKeys";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialErrorState = {
    status: "",
    show: false,
    message: "",
};

const useShortCutKeys = (onClose, onClear, addSelectedItems, rowData) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.ok,
        callback: () => {
            if (rowData.length === 0) {
                onClose();
            }
        },
        options: {
            scopes: hotKeysScopes.deliverMediaModal,
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => addSelectedItems(),
        options: {
            scopes: hotKeysScopes.deliverMediaModal,
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: onClear,
        options: {
            scopes: hotKeysScopes.deliverMediaModal,
        },
    });
    const { enableScope, disableScope } = useHotkeysContext();
    useEffect(() => {
        disableScope(hotKeysScopes.deliverMedia);
        enableScope(hotKeysScopes.deliverMediaModal);
        return () => {
            disableScope(hotKeysScopes.deliverMediaModal);
            enableScope(hotKeysScopes.deliverMedia);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const DeliverMediaModal = ({
    onClose,
    rowData,
    handleSubmit,
    modelTitle,
    description,
    columns,
    getRowId,
    selectedRow,
}) => {
    const shortCutKeys = getShortCutKeys();
    useShortCutKeys(
        () => onClose(),
        () => handleClearSelection(),
        () => addSelectedItems(),
        rowData
    );
    const { t } = useTranslation();
    const [selectedData, setSelectedData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(initialErrorState);
    const clearSelectionRef = useRef(null);
    const modalRef = useRef(null);
    useEffect(() => {
        if (rowData?.hasError === true) {
            setErrorMessage({
                status: "error",
                show: true,
                message: getApiErrorMessage(rowData?.error),
            });
        } else if (rowData?.length === 0) {
            if (modelTitle === t("deliverMedia.containerModel")) {
                setErrorMessage({
                    status: "error",
                    show: true,
                    message: t("apiErrorMessages.63840", { buttonLabel: t("buttonLabels.ok") }),
                });
            } else {
                setErrorMessage({
                    status: "error",
                    show: true,
                    message: (
                        <Trans
                            i18nKey="messages.mediaNumberNotExistOM"
                            values={{ mediaNumber: selectedRow.media_number, buttonLabel: t("buttonLabels.ok") }}
                        />
                    ),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClearSelection = () => {
        setErrorMessage({
            initialErrorState,
        });
        if (clearSelectionRef.current) {
            clearSelectionRef.current.onClearSelection();
        }
    };

    const handleSelectionChange = (newSelectedItems) => {
        setSelectedData(newSelectedItems);
    };

    useEffect(() => {
        if (errorMessage.show && modalRef.current) {
            modalRef.current.scrollIntoView({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [errorMessage]);

    const addSelectedItems = () => {
        if (selectedData.length === 0) {
            setErrorMessage({
                status: "error",
                show: true,
                message: (
                    <Trans
                        i18nKey={"messages.atLeastOneItemMustBeChecked"}
                        values={{
                            selectButtonLabel: t("buttonLabels.select"),
                            cancelButtonLabel: t("buttonLabels.cancel"),
                        }}
                    />
                ),
            });
        } else {
            handleSubmit(selectedData);
            onClose();
        }
    };

    return (
        <SecureSyncModal
            open={true}
            title={modelTitle}
            maxWidth={"md"}
            fullWidth
            footer={
                rowData?.length === 0 ? (
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="contained" onClick={onClose} title={getKeyBoardLabel(shortCutKeys.ok)}>
                            {t("buttonLabels.ok")}
                        </Button>
                    </Box>
                ) : (
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={onClose}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClearSelection}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            {t("buttonLabels.reset")}
                        </Button>
                        <Button
                            variant="contained"
                            title={getKeyBoardLabel(shortCutKeys.submit)}
                            onClick={() => {
                                addSelectedItems();
                            }}
                        >
                            {t("buttonLabels.select")}
                        </Button>
                    </Box>
                )
            }
        >
            <Box sx={styles.container}>
                <Box sx={styles.innerBox}>
                    {errorMessage.show && errorMessage?.status === "error" && (
                        <SecureSyncAlert
                            severity={"error"}
                            message={errorMessage.message}
                            alertRef={modalRef}
                            onClose={rowData.length !== 0 ? () => setErrorMessage(initialErrorState) : undefined}
                        />
                    )}
                    {rowData?.length > 0 && (
                        <>
                            <Typography variant="body1" sx={styles.subheader}>
                                {description}
                            </Typography>
                            <Box>
                                <SecureSyncTableCard>
                                    <Box sx={styles.recordCount}>
                                        {rowData?.length > 0 && (
                                            <Box>
                                                <Typography variant="body1">
                                                    {t("messages.recordsFound", {
                                                        count: rowData?.length,
                                                    })}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={styles.cardContent}>
                                        <SecureSyncTable
                                            rows={rowData}
                                            getRowId={getRowId}
                                            columns={columns}
                                            tableMinWidth="100px"
                                            tableRef={clearSelectionRef}
                                            headerHeight="36px"
                                            rowHeight="32px"
                                            enableSelection={true}
                                            onSelectionChange={handleSelectionChange}
                                            renderSelectAllHeaderCell={() => <></>}
                                        />
                                    </Box>
                                </SecureSyncTableCard>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </SecureSyncModal>
    );
};

export default DeliverMediaModal;
