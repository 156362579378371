export const errorList = [
    { errorCode: "25357", severity: "W" },
    { errorCode: "61014" },
    { errorCode: "61182" },
    { errorCode: "61211" },
    { errorCode: "61212" },
    { errorCode: "61213" },
    { errorCode: "61214" },
    { errorCode: "61220", severity: "W" },
    { errorCode: "25170", severity: "W" },
    { errorCode: "61014" },
    { errorCode: "61014", severity: "E" },
    { errorCode: "61182" },
    { errorCode: "61182", severity: "W" },
    { errorCode: "61211" },
    { errorCode: "61211", severity: "W" },
    { errorCode: "61212" },
    { errorCode: "61212", severity: "W" },
    { errorCode: "61213" },
    { errorCode: "61213", severity: "E" },
    { errorCode: "61214" },
    { errorCode: "61214", severity: "E" },
    { errorCode: "63000", severity: "W" },
    { errorCode: "63003", severity: "W" }, //| must be less than or equal to |.
    { errorCode: "63095", severity: "W" },
    { errorCode: "63111", severity: "W" }, //| must be greater than or equal to |.
    { errorCode: "63130", severity: "I" },
    { errorCode: "63131", severity: "I" },
    { errorCode: "63547" },
    { errorCode: "63548" },
    { errorCode: "63643" },
    { errorCode: "63645" },
    { errorCode: "80482", severity: "W" },
    { errorCode: "63535", severity: "I" },
    { errorCode: "63638", severity: "I" },
    { errorCode: "63513", severity: "I" },
    { errorCode: "59500", severity: "W" },
    { errorCode: "63840", severity: "I" },
    { errorCode: "63544", severity: "W" },
    { errorCode: "63443", severity: "W" },
    { errorCode: "26070", severity: "W" },
    { errorCode: "63529", severity: "W" },
    { errorCode: "26069", severity: "W" },
    { errorCode: "61218", severity: "W" },
    { errorCode: "61216", severity: "W" },
];
