import { Box, IconButton, SvgIcon, Typography, useTheme } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DeliverMediaModal from "../DeliverMediaModal/DeliverMediaModal";
import styles from "./MediaRequestedTable.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import { validateVolserSelectionService } from "src/services/requestServices";
import { convertToLocaleUpperCase, padEndStringWithSelectedCharacters } from "src/utils/commonHelper";
import { sortHelper } from "src/utils/sortHelper";
import { deliverMediaStatus, followTypeOrder, followUpTypes } from "src/constants/requestConstants";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { insertBulkData } from "src/utils/requestHelper";

const MediaRequestedTable = ({
    validatedData,
    setValidatedData,
    getCounts,
    allowCustomerMediaIdentifiers,
    openMediaTypeList,
    setOpenMediaTypeList,
    setIsLoading,
    sequenceNumber,
    incrementSequenceNumber,
    hasValidatedData,
}) => {
    const { t } = useTranslation();
    const [isSelectedContainerModalOpen, setIsSelectedContainerModalOpen] = useState(false);
    const [matchData, setMatchData] = useState();
    const noDataMsg = t("messages.mediaRequestTableNoDataFound");
    const maxMediaMatchCount = 50;
    const [modelTitle, setModelTitle] = useState();
    const [modalDescription, setModalDescription] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const theme = useTheme();
    const openMediaData = useMemo(() => {
        return validatedData?.filter((data) => data?.media_program_type === deliverMediaStatus.openMedia);
    }, [validatedData]);

    const containerCount = useMemo(() => {
        return validatedData?.filter((data) => data?.media_program_type === deliverMediaStatus.container).length;
    }, [validatedData]);

    const customSortFunction = (a, b, order) => {
        const indexA = followTypeOrder.indexOf(a.followup_type);
        const indexB = followTypeOrder.indexOf(b.followup_type);

        if (indexA === indexB) {
            if (order === "asc") {
                return a.entered_sequence - b.entered_sequence;
            } else {
                return b.entered_sequence - a.entered_sequence;
            }
        }

        if (order === "asc") {
            return indexA - indexB;
        } else {
            return indexB - indexA;
        }
    };
    useEffect(() => {
        getCounts({ openMediaCount: openMediaData.length, containerCount: containerCount });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMediaData, containerCount]);

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "delete",
                width: "10px",
                headerName: "",
                sortable: false,
                renderCell: (params) => (
                    <SecureSyncIconTooltip
                        title={t("deliverMedia.deleteMediaFromRequest")}
                        icon={
                            <IconButton
                                onClick={() => {
                                    deleteMediaRequested(params.entered_sequence);
                                }}
                            >
                                <ClearIcon sx={styles.clearIcon} />
                            </IconButton>
                        }
                    ></SecureSyncIconTooltip>
                ),
            },
            {
                field: "entered_sequence",
                headerName: convertToLocaleUpperCase(t("tableHeader.enteredSequence")),
                sortable: true,
                type: "number",
            },
            {
                field: "media_program_type",
                headerName: convertToLocaleUpperCase(t("tableHeader.openMediaContainer")),
                sortable: true,
                renderCell: (params) => {
                    if (params.media_program_type === deliverMediaStatus.container && params.followup_descr) {
                        return <span style={styles.redText}>{t("common.containers")}</span>;
                    } else {
                        return (
                            <span>
                                {params.media_program_type === deliverMediaStatus.container
                                    ? t("common.containers")
                                    : t("common.openMedia")}
                            </span>
                        );
                    }
                },
            },
            {
                field: "media_number",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")),
                sortable: true,
                renderCell: (params) => {
                    if (params.media_program_type === deliverMediaStatus.container && params.followup_descr) {
                        return <span style={styles.redText}>{params.media_number}</span>;
                    } else {
                        return <span>{params.media_number}</span>;
                    }
                },
            },
            allowCustomerMediaIdentifiers && {
                field: "customer_media_descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.customerMediaIdentifier")),
                sortable: true,
                renderCell: (params) => {
                    if (params.media_program_type === deliverMediaStatus.container && params.followup_descr) {
                        return <span style={styles.redText}>{params.customer_media_descr}</span>;
                    } else {
                        return <span>{params.customer_media_descr}</span>;
                    }
                },
            },
            {
                field: "status",
                headerName: convertToLocaleUpperCase(t("tableHeader.status")),
                sortable: true,
                renderCell: (params) => {
                    if (params.media_program_type === deliverMediaStatus.container && params.followup_descr) {
                        return <span style={styles.redText}>{params.status}</span>;
                    } else {
                        return <span>{params.status}</span>;
                    }
                },
            },
            {
                field: "followup_descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.followUp")),
                type: "followUp",
                sortable: true,
                customSort: customSortFunction,
                renderCell: (params) => (
                    <span style={styles.redText}>
                        {params.followup_descr}
                        {(params.followup_type === followUpTypes.possibleMatch ||
                            params.followup_type === followUpTypes.duplicate) && (
                            <SecureSyncIconTooltip
                                title={
                                    followUpTypes.possibleMatch
                                        ? t("deliverMedia.possibleMatchesToolTip")
                                        : t("deliverMedia.duplicateMatchesToolTip")
                                }
                                icon={
                                    <SvgIcon
                                        fill={theme.palette.primary.main}
                                        component={RemoveRedEyeFilled}
                                        onClick={() => {
                                            openSelectedListModalHandler(
                                                params.followup_descr,
                                                params.media_number,
                                                params.followup_type
                                            );
                                            setSelectedRow(params);
                                        }}
                                        arrow={true}
                                    />
                                }
                            ></SecureSyncIconTooltip>
                        )}
                    </span>
                ),
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowCustomerMediaIdentifiers]);

    const deleteMediaRequested = (id) => {
        setValidatedData((prevValidatedData) => {
            return prevValidatedData.filter((val) => val.entered_sequence !== id);
        });
    };

    const sortedValidationData = useMemo(() => {
        if (validatedData && validatedData.length > 0) {
            return sortHelper({
                data: validatedData,
                primaryProperty: "followup_type",
                secondaryProperty: "entered_sequence",
                sortOrder: "asc",
                customSortFunction: customSortFunction,
            });
        }
        return [];
    }, [validatedData]);

    const matchColumns = useMemo(() => {
        const baseColumns = [
            { field: "volser", headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")) },
            {
                field: "media_type_descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.mediaType")),
            },
            { field: "open_media_descr", headerName: convertToLocaleUpperCase(t("common.description")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openSelectedListModalHandler = async (followupDescr, mediaNumber, followUpType) => {
        setIsLoading(true);
        const volserListData = openMediaData
            .map((value) => {
                const paddedValue = padEndStringWithSelectedCharacters(value?.media_number, 20, " ");
                return paddedValue;
            })
            .join("");
        const payload = {
            volserList: volserListData,
            selectedVolser: mediaNumber,
            followUp: followupDescr,
            maxRowsReturned: maxMediaMatchCount + 1,
        };
        const data = await validateVolserSelectionService(payload);
        if (followUpType === followUpTypes.possibleMatch) {
            setModelTitle(t("deliverMedia.possibleMatch"));
            const desc =
                data.length === 0 ? (
                    <Trans
                        i18nKey="messages.mediaNumberNotExistOM"
                        values={{ mediaNumber: mediaNumber, buttonLabel: t("buttonLabels.ok") }}
                    />
                ) : (
                    <Trans
                        i18nKey="messages.selectCorrectMatches"
                        values={{
                            mediaNumber: mediaNumber,
                            selectButtonLabel: t("buttonLabels.select"),
                            cancelButtonLabel: t("buttonLabels.cancel"),
                        }}
                    />
                );
            setModalDescription(desc);
        } else if (followUpType === followUpTypes.duplicate) {
            setModelTitle(t("deliverMedia.duplicates"));
            const desc =
                data.length === 0 ? (
                    <Trans
                        i18nKey="messages.mediaNumberNotExistOM"
                        values={{ mediaNumber: mediaNumber, buttonLabel: t("buttonLabels.ok") }}
                    />
                ) : (
                    <Trans
                        i18nKey="messages.selectTheDuplicateValue"
                        values={{
                            mediaNumber: mediaNumber,
                            selectButtonLabel: t("buttonLabels.select"),
                            cancelButtonLabel: t("buttonLabels.cancel"),
                        }}
                    />
                );
            setModalDescription(desc);
        }
        setMatchData(data?.result);
        setIsSelectedContainerModalOpen(true);
        setIsLoading(false);
    };

    const closeSelectedListModalHandler = () => {
        setIsSelectedContainerModalOpen(false);
    };

    const getRowId = (row) => row.volser;

    const handleSubmit = (data) => {
        const openMediaData = insertBulkData(data, openMediaTypeList, sequenceNumber, hasValidatedData);
        setOpenMediaTypeList((prevData) => {
            return [...openMediaData.reverse(), ...prevData];
        });
        incrementSequenceNumber(openMediaData.length);
        deleteMediaRequested(selectedRow.entered_sequence);
        setSelectedRow();
    };

    return (
        <Box>
            <PageSubTitle sx={styles.pageHeader}>{t("titles.mediaRequested")}</PageSubTitle>
            <Box>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        {validatedData.length > 0 && (
                            <Box>
                                <Typography variant="body1">
                                    {t("messages.deliverMediaRecordsCount", {
                                        total: validatedData?.length,
                                        omCount: openMediaData.length,
                                        containerCount: containerCount,
                                    })}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={sortedValidationData}
                            columns={columns}
                            noDataMessage={noDataMsg}
                            getRowId={(row) => row.entered_sequence}
                        />
                    </Box>
                </SecureSyncTableCard>
                {isSelectedContainerModalOpen && (
                    <DeliverMediaModal
                        rowData={matchData}
                        onClose={closeSelectedListModalHandler}
                        allowCustomerMediaIdentifiers={allowCustomerMediaIdentifiers}
                        handleSubmit={handleSubmit}
                        getRowId={getRowId}
                        modelTitle={modelTitle}
                        description={modalDescription}
                        columns={matchColumns}
                        selectedRow={selectedRow}
                    />
                )}
            </Box>
        </Box>
    );
};

export default MediaRequestedTable;
