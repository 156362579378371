import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, SvgIcon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ImportIcon } from "../../../../assets/images/import.svg";
import { styles } from "./ServiceDetailsSection.styles";
import { downloadInBoundOutBoundReport } from "src/services/homeService";
import { downloadFileFromFB } from "src/services/utils";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { InLineLink } from "src/components/common/Link/InLineLink/InLineLink";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { inboundOutboundReports } from "src/constants/routeConstants/operationsRouterFullPaths";

const ServiceDetailsSection = (props) => {
    const navigate = useNavigate();
    const [rSRCount, setRSRCount] = useState(0);
    const [rows, setRow] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setRSRCount(props?.count ? props.count : 0);
    }, [props?.count]);

    useEffect(() => {
        setRow(props?.rows ? props.rows : []);
    }, [props?.rows]);

    const functionGenarateReport = async (params) => {
        setLoading(true);
        const res = await downloadInBoundOutBoundReport({
            customerId: params.customerId,
            districtId: params.districtId,
            districtName: params.branch,
            lastServiceDate: params.reportDate,
            customerNumber: params.customer,
        });
        await downloadFileFromFB({
            fbFilePath: res.path,
            fileName: res.filename,
            onComplete: clearBusy,
        });
    };
    const clearBusy = () => {
        setLoading(false);
    };

    const navigateOnBoundReport = () => {
        navigate(inboundOutboundReports);
    };

    const columns = [
        {
            field: "edit",
            headerName: "",
            width: 60,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton onClick={() => functionGenarateReport(params)}>
                        <SvgIcon component={ImportIcon} />
                    </IconButton>
                </Box>
            ),
        },
        { field: "customer", headerName: t("homeModule.Customer"), flex: 1, sortable: true },
        { field: "name", headerName: t("homeModule.Name"), flex: 1, sortable: true },
        { field: "branch", headerName: t("homeModule.Branch"), flex: 1, sortable: true },
        { field: "lastServiceDate", headerName: t("homeModule.LastServiceDate"), flex: 1, sortable: true },
    ];
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Accordion sx={styles.accord}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="service-details-content"
                    id="service-details"
                >
                    <Typography variant="body1" sx={styles.headerTypography}>
                        {t("homeModule.ServiceDetails")} ({rSRCount})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Typography variant="body1" sx={styles.bodyTypography}>
                            <span>{t("homeModule.serviceDetailsHeaderpart1")}</span>
                            <InLineLink onClickFunction={navigateOnBoundReport}>
                                {t("homeModule.onboundInboundUrl")}
                            </InLineLink>
                            <span> {t("homeModule.Page")}</span>
                        </Typography>
                        <Box style={styles.tableBox}>
                            <SecureSyncTable
                                propStyles={styles.dataGrid}
                                rows={rows}
                                columns={columns}
                                noDataMessage={t("messages.noRecordsFound")}
                                getRowId={(row) => row.id}
                                initialOrder="decs"
                                sx={styles.dataGrid}
                            />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

ServiceDetailsSection.propTypes = {
    count: PropTypes.number,
    rows: PropTypes.array,
};

export default ServiceDetailsSection;
