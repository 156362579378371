const styles = {
    card: {
        my: "16px",
    },
    eyeIcon: {
        color: "im_mid_blue.500",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "70px",
        p: 2,
    },
    recordStart: {
        display: "flex",
        justifyContent: "flex-start",
        height: "100%",
        alignItems: "center",
    },
    recordEnd: {
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    renderCell: {
        maxWidth: "38ch",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        verticalAlign: "top",
    },
};

export default styles;
