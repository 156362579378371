const styles = {
    searchButtons: {
        display: "flex",
        gap: "20px",
        mt: "20px",
    },
    desc: {
        mb: "20px",
    },
};

export default styles;
