import { Box, Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styles from "./SessionTimeoutModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import { clearUserDetails } from "src/redux/userDetailsSlice";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import WarningOutlined from "src/components/Icons/WarningOutlined/WarningOutlined";

const timeout = 9_00_000; // 15mins
const promptBeforeIdle = 3_00_000; // 5mins

export default function SessionTimeoutModal() {
    const [remaining, setRemaining] = useState(timeout);
    const [open, setOpen] = useState(false);
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const onIdle = () => {
        handleLogout();
        setOpen(false);
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    const handleStillHere = () => {
        activate();
    };

    const handleLogout = () => {
        dispatch(clearUserDetails());
        oktaAuth.signOut();
        navigate(routePaths.signIn);
    };

    const seconds = remaining % 60;
    const mins = Math.floor(remaining / 60);
    return (
        <>
            <SecureSyncModal
                width={"390px"}
                title={t("titles.sessionTimeOutModalTitle")}
                open={open}
                footer={
                    <Box sx={styles.confirmationModalFooter}>
                        <Button variant="outlined" onClick={handleLogout}>
                            <Trans i18nKey={"headerMenu.logout"} />
                        </Button>
                        <Button variant="contained" onClick={handleStillHere}>
                            <Trans i18nKey={"sessionTimeoutModal.remainLoggedIn"} />
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.descWrapper}>
                    <Box>
                        <WarningOutlined width="42px" height="42px" fill={theme.palette.im_orange[500]} />
                    </Box>
                    <Box sx={styles.desc}>
                        <Trans i18nKey={"sessionTimeoutModal.inactivityDescription"} />
                    </Box>
                </Box>
                <Box sx={styles.timer}>
                    <Trans i18nKey={"sessionTimeoutModal.timerText"} values={{ mins, seconds }} />
                </Box>
            </SecureSyncModal>
        </>
    );
}
