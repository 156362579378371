const styles = {
    heading: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: 1.34,
        color: "im_grey.900",
        paddingBottom: "20px",
    },
    requestDetails: {
        fontsize: "12px",
        fontWeight: 400,
        lineHeight: "12px",
        letterSpacing: "0.15px",
        color: "im_grey.400",
    },
    requestDetailBody: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: "0.17px",
    },
    container: {
        paddingBottom: "40px",
        paddingTop: "20px",
    },
};
export default styles;
