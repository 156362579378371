const styles = {
    container: {
        paddingBottom: "40px",
        paddingTop: "20px",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    heading: {
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: 1.6,
        letterSpacing: "0.15px",
        paddingBottom: "16px",
        color: "text.primary",
    },
    recordCount: {
        padding: "16px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
    },
};

export default styles;
