export const preferenceTypeId = {
    defaultCustomerAtLogin: "1",
    commentRequired: "2",
    customer: "2",
    userVerificationDays: "3",
    user: "4",
    customerNameDisplay: "6",
    retainOpenMediaDescription: "7",
    deleteContainerDescription: "11",
    allowUsertoSetDefaultCustomer: "12",
    defaultCustomerAtLoginOptions: "13",
    defaultReportFileFormat: "14",
    allowUserToScanVerify: "15",
    callResolveDiscrepancies: "18",
    customerDeliveryPickupNotification: "20",
    mediaDestructionRequest: "21",
    mediaRequestDeadLine: "22",
    vaultStatusReportEmailLanguage: "30",
    emailOnlyIfExceptionFlag: "31",
    holidayService: "39",
    bypassDestructionRequest: "40",
};

export const preferenceLevelTypeId = "2";
