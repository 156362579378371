import { Box, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./ContactSection.styles";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import { serviceType } from "src/constants/miscConstants";

const serviceHeaderMapping = {
    [serviceType.libraryAudit]: "miscService.contactSectionHeaderLibraryAudit",
    [serviceType.newCustomer]: "miscService.contactSectionHeaderNewCustomer",
    [serviceType.authCardReplacement]: "miscService.contactSectionHeaderAuthCardReplacement",
    [serviceType.bidService]: "miscService.contactSectionHeaderBidForService",
};

const ContactSection = ({
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    customValidations,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Typography variant="body1" sx={styles.typographyBody}>
                <Trans i18nKey={serviceHeaderMapping[values.serviceLocation]} />
            </Typography>
            <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                <TextField
                    label={t("formFields.requestorEmail")}
                    variant="outlined"
                    name="requestorEmail"
                    required
                    inputProps={{ maxLength: 60 }}
                    onBlur={handleBlur}
                    value={values.requestorEmail}
                    onChange={handleChange}
                    error={touched.requestorEmail && Boolean(errors.requestorEmail)}
                    helperText={touched.requestorEmail && errors.requestorEmail}
                    fullWidth
                />
                <TextField
                    label={t("formFields.requestorPhoneNumber")}
                    variant="outlined"
                    name="requestorPhoneNumber"
                    required
                    inputProps={{ maxLength: 25 }}
                    onBlur={handleBlur}
                    value={values.requestorPhoneNumber}
                    onChange={handleChange}
                    error={
                        touched.requestorPhoneNumber &&
                        (Boolean(errors.requestorPhoneNumber) || !customValidations.requestorPhone.validate)
                    }
                    helperText={
                        touched.requestorPhoneNumber &&
                        (errors.requestorPhoneNumber || customValidations.requestorPhone.message)
                    }
                    fullWidth
                />
                <TextField
                    label={t("formFields.ext")}
                    variant="outlined"
                    name="ext"
                    inputProps={{ maxLength: 6 }}
                    onBlur={handleBlur}
                    value={values.ext}
                    onChange={handleChange}
                    error={touched.ext && Boolean(errors.ext)}
                    helperText={touched.ext && errors.ext}
                    fullWidth
                />
                {[serviceType.newCustomer, serviceType.libraryAudit].includes(values.serviceLocation) && (
                    <SecureSyncDatePicker
                        required
                        disablePast
                        value={values.requestedCompletionDate}
                        label={t("formFields.requestedCompletionDate")}
                        helperText={touched.requestedCompletionDate && errors.requestedCompletionDate}
                        error={Boolean(touched.requestedCompletionDate && errors.requestedCompletionDate)}
                        onChangeRaw={async (value) => {
                            await setFieldValue("requestedCompletionDate", value);
                            setFieldTouched("requestedCompletionDate", true, true);
                        }}
                    />
                )}
            </Box>
        </>
    );
};
export default ContactSection;
