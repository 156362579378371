const styles = {
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        padding: "10px 0px",
    },
    boxPaddingBottom: {
        paddingBottom: "10px",
        display: "grid",
        gridTemplateColumns: "640px 200px 90px 310px",
    },
    formControlStyle: {
        "& .MuiFormControl-root": {
            paddingRight: "0px",
        },
    },
};

export default styles;
