const styles = {
    textContent: {
        fontWeight: "bold",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        p: 3,
        width: "100%",
        textAlign: "left",
    },
    fontWeight500: {
        fontWeight: 500,
    },
    infoText: {
        fontWeight: 500,
        mb: "16px",
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(3,310px)",
        gap: "20px",
        mb: "20px",
    },
    tooltipSection: {
        display: "flex",
        alignItems: "center",
    },
    buttonSection: {
        display: "flex",
        gap: "20px",
        pt: "30px",
    },
    cutOffDates: {
        backgroundColor: "im_light_blue.500",
        color: "im_grey.200",
    },
    disabledDay: {
        backgroundColor: "im_grey.50",
        color: "im_grey.200",
    },
};

export default styles;
