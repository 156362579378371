import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MediaRequestedDestructionTable.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { destructRequestTypeIds } from "src/constants/requestConstants";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";
import { sortHelper } from "src/utils/sortHelper";
import { formatDate } from "src/utils/dateHelper";

const MediaRequestedDestructionTable = ({ mediaDestructionDetails1, mediaDestructionDetails2 }) => {
    const { t } = useTranslation();
    const destructRequestTypeId = mediaDestructionDetails1[0]?.destruct_request_type_id;
    const isItemPanel = mediaDestructionDetails2 && destructRequestTypeId === destructRequestTypeIds.vaultItemized;
    const itemPanel = useMemo(
        () => [
            {
                field: "volser",
                headerName: convertToLocaleUpperCase(t("common.mediaNumber")),
                sortable: true,
            },
            {
                field: "destruct_item_status_descr",
                headerName: convertToLocaleUpperCase(t("common.status")),
                sortable: true,
            },
            {
                field: "open_media_descr",
                headerName: convertToLocaleUpperCase(t("common.description")),
                sortable: true,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const hasActualBulkQuantity =
        mediaDestructionDetails1[0]?.actual_bulk_quantity && mediaDestructionDetails1[0]?.actual_bulk_quantity !== "0";
    const hasApproxBulkQuantity =
        mediaDestructionDetails1[0]?.approx_bulk_quantity && mediaDestructionDetails1[0]?.approx_bulk_quantity !== "0";
    const sortedItemPanel = sortHelper({
        data: mediaDestructionDetails2,
        primaryProperty: "sort_group",
        secondaryProperty: "volser",
        sortOrder: "asc",
    });
    return (
        <>
            {isItemPanel ? (
                <>
                    <Box sx={styles.mainContainer}>
                        <PageSubTitle>{t("titles.mediaRequestedDestruction")}</PageSubTitle>
                        <Box sx={styles.cardContent}>
                            <SecureSyncTableCard>
                                <Box sx={styles.recordCount}>
                                    {mediaDestructionDetails2.length > 0 && (
                                        <Typography variant="body1">
                                            {t("messages.recordsFound", { count: mediaDestructionDetails2.length })}
                                        </Typography>
                                    )}
                                </Box>
                                <SecureSyncTable
                                    rows={sortedItemPanel}
                                    columns={itemPanel}
                                    noDataMessage={t("messages.noRecordsFound")}
                                    getRowId={(row) => row?.destruct_request_id}
                                />
                            </SecureSyncTableCard>
                        </Box>
                    </Box>
                    <Divider />
                </>
            ) : (
                <>
                    <PageSubTitle>{t("titles.mediaDestructionDescription")}</PageSubTitle>
                    <Box sx={styles.rowGrid}>
                        {mediaDestructionDetails1[0]?.service_date && (
                            <SecureSyncReadOnlyTextField
                                value={formatDate(mediaDestructionDetails1[0]?.service_date) || " "}
                                label={t("common.serviceDate")}
                            />
                        )}
                        {mediaDestructionDetails1[0]?.service_location && (
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails1[0]?.service_location || " "}
                                label={t("common.serviceLocation")}
                            />
                        )}
                        {hasApproxBulkQuantity && (
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails1[0]?.approx_bulk_quantity || " "}
                                label={t("formFields.quantityOfBoxesOnOrder")}
                            />
                        )}
                        {hasActualBulkQuantity && (
                            <SecureSyncReadOnlyTextField
                                value={mediaDestructionDetails1[0]?.actual_bulk_quantity || " "}
                                label={t("formFields.quantityOfBoxesPickedUp")}
                            />
                        )}
                    </Box>
                    <Box sx={styles.reqDetailRow}>
                        <SecureSyncReadOnlyTextField
                            value={mediaDestructionDetails1[0]?.pickup_instruction || " "}
                            label={t("formFields.pickUpInstructions")}
                            isMultiline={true}
                        />
                    </Box>
                    <Divider />
                </>
            )}
        </>
    );
};

export default MediaRequestedDestructionTable;
