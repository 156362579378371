const styles = {
    footer: {
        mb: 3,
        bgcolor: "grey.400",
    },
    footerContainer: { maxWidth: "1440px", m: "auto" },
    gridContainer: {
        marginBottom: "15px",
    },
    gridItemLeft: {
        display: "flex",
    },
    footerLogo: {
        width: 49,
        height: 40,
        marginRight: 16,
    },
    typography: {
        mt: "1px",
    },
    gridItemRight: {
        textAlign: { xs: "left", sm: "right" },
        mt: 2,
    },
    link: {
        color: "info.main",
        textDecoration: "none",
    },
    separator: {
        mx: 2,
        color: "info.main",
    },
};

export default styles;
