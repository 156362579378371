const styles = {
    containerContents: {
        mb: "20px",
    },
    backBtn: {
        my: "20px",
    },
};

export default styles;
