import { Link, Typography } from "@mui/material";
import CircleArrowRight from "../../../assets/images/circle_right_arrow.svg";
import styles from "./ServiceLink.styles";

export const ServiceLink = ({ href, children }) => {
    return (
        <Link href={href} sx={styles.link}>
            <img loading="lazy" src={CircleArrowRight} alt="Link Icon" style={styles.linkIcon} />
            <Typography sx={styles.textStyle}>{children}</Typography>
        </Link>
    );
};

export const OpenNewTabServiceLink = ({ href, children }) => {
    return (
        <Link target="_blank" href={href} sx={styles.link}>
            <img loading="lazy" src={CircleArrowRight} alt="Link Icon" style={styles.linkIcon} />
            <Typography sx={styles.textStyle}>{children}</Typography>
        </Link>
    );
};
