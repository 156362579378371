const styles = {
    h4Heading: {
        fontSize: "34px",
        lineHeight: "1.3",
        letterSpacing: "0.25px",
        paddingY: "20px",
    },
};

export default styles;
