/* eslint-disable max-lines */
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useMatch, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import ModifyHistory from "../ModifyHistory/ModifyHistory";
import CustomerList from "../../AddUser/CustomerList/CustomerList";
import styles from "./EditUserForm.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getAuthInfo, resetAuthNumber, userAlreadyExist, validatePhoneFormat } from "src/services/authorizationService";
import { getBasicUserInfo, getUserDetails, saveUserDetails } from "src/redux/userDetailsSlice";
import { paramSetService } from "src/utils/messageParamSet";
import { useFetchSecurityQuestions, useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import CountriesList from "src/components/common/CountriesList/CountriesList";
import { getDuplicateUsers, updatePersonService } from "src/services/userPersonalServices";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { editUser, searchUsers } from "src/constants/routeConstants/administrationRouterFullPaths";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import { displayAnswer } from "src/constants/autherizationConstants/challengeQuestionConstants";
import { authLevelCode } from "src/constants/autherizationConstants/authConstants";
import SecureSyncSupText from "src/components/common/SecureSyncSupText/SecureSyncSupText";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(t("authorization.firstNameValidation"))
            .max(30, t("authorization.firstNameValidation")),
        lastName: Yup.string()
            .required(t("authorization.lastNameValidation"))
            .max(30, t("authorization.firstNameValidation")),
        middleName: Yup.string().max(30, t("authorization.middleNameValidation")),
        businessEmail: Yup.string()
            .required(t("authorization.mailValidation"))
            .matches(/^\S+@\S+\.\S+$/, t("authorization.mailValidation")),
        contactInfoCountryCode: Yup.string().required(t("authorization.accountCodeValidation")),
        confirmChallengeResponse: Yup.string().oneOf(
            [Yup.ref("challengeResponse"), null],
            t("postLoginCheck.invalidConfirmChallengeResponseTheConfirmChallengeResponseMustMatchTheChallengeResponse")
        ),
    });

const myInfo = "my-info";

const initValue = {
    firstName: "",
    middleName: "",
    lastName: "",
    contactInfoCountryCode: "",
    homePhone: "",
    cellular: "",
    businessEmail: "",
    homeEmail: "",
    pager: "",
    pagerPin: "",
    enableSecureSync: false,
    selectedCustomers: [],
    challengeQuestion: "",
    challengeResponse: "",
    confirmChallengeResponse: "",
    personalId: "",
};

const defaultChallengeQuestionValue = "11";
//extension businessPhone businessFax customerEmployeeNo
const EditUserForm = (props) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            handleReset();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.changePin,
        callback: () => {
            isLoggedInUser && props.navigateToUserPin();
        },
    });
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const businessEmailRef = useRef(null);
    const cellPhoneRef = useRef(null);
    const homePhoneRef = useRef(null);
    const pagerPhoneRef = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [modUserOpen, setModUserOpen] = useState(false);
    const [customValidations, setCustomValidations] = useState({
        cellPhone: { validate: true, message: "" },
        homePhone: { validate: true, message: "" },
        businessPhone: { validate: true, message: "" },
        fax: { validate: true, message: "" },
        userName: { validate: true, message: "" },
        isCustomerSelected: { validate: true, message: "" },
        pager: { validate: true, message: "" },
    });
    const [activeCustomers, setActiveCustomers] = useState([]);
    const [notActiveCustomers, setNotActiveCustomers] = useState([]);
    const [authNumber, setAuthNumber] = useState("");
    const [authPin, setAuthPin] = useState("");
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const { pathname } = location;
    const { data: challengeQuestion } = useFetchSecurityQuestions();
    const { navigate, goBack } = useSecureSyncNavigate();
    const modifiedUserData = useSelector(getUserDetails);
    const userData = useSelector(getBasicUserInfo);
    const fromUserSearch = useMatch(editUser);
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.personalData) {
            setIntValues(props.personalData);
            fetchAuthInfo(params.userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authList, props.personalData]);

    const questions = useMemo(
        () =>
            challengeQuestion.map((item, index) => ({
                id: index.toString(),
                label: item.descr,
                value: item.dropdown_list_id,
            })),
        [challengeQuestion]
    );

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, submitCount } =
        useFormik({
            initialValues: initValue,
            validationSchema: validationSchema(t),
            onSubmit: async (values) => {
                await formSubmit();
            },
        });

    useEffect(() => {
        if (submitCount > 0) {
            const { businessEmail, firstName, lastName } = errors;
            if (firstName) {
                firstNameRef.current?.focus();
            } else if (lastName) {
                lastNameRef.current?.focus();
            } else if (businessEmail) {
                businessEmailRef.current?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    const formSubmit = async () => {
        try {
            const [validateCellPhone, validateHomePhone, validatePager, validateName] = await Promise.all([
                validatePhoneNumber("cellPhone", values.cellular, t("authorization.celluler")),
                validatePhoneNumber("homePhone", values.homePhone, t("authorization.homePhone")),
                validatePhoneNumber("pager", values.pager, t("authorization.pager")),
                validateUserName(),
            ]);
            if (!validateCellPhone || !validateHomePhone || !validateName || !validatePager) {
                if (!validateName) {
                    firstNameRef.current?.focus();
                } else if (!validateCellPhone) {
                    cellPhoneRef.current?.focus();
                } else if (!validateHomePhone) {
                    homePhoneRef.current?.focus();
                } else if (!validatePager) {
                    pagerPhoneRef.current?.focus();
                }
                return;
            }
            let ssLogin;
            if (props.personalData.isSecureSyncUser) {
                ssLogin = props.personalData.login;
            }
            const res = await updatePersonService(values, ssLogin);
            if (isLoggedInUser) {
                const userInfo = await oktaAuth.getUser();
                dispatch(saveUserDetails(userInfo));
            }
            props.openSuccessMsg(res);
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (!res.error) {
                clearReset();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const resetAuthNumberCall = async () => {
        setLoading(true);
        const activeCustomerString = [];
        activeCustomers.forEach((customer) => {
            activeCustomerString.push(customer?.customerNumber?.props?.name);
        });
        const modifyUserName = modifiedUserName();
        const res = await resetAuthNumber(
            props.personalData.personnel_id,
            null,
            null,
            activeCustomerString,
            props.displayName,
            modifyUserName,
            values.businessEmail,
            true
        );
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(false);
        props.openSuccessMsgResetAuth(res);
    };

    const modifiedUserName = () => {
        if (modifiedUserData) {
            // eslint-disable-next-line camelcase
            const { family_name = "", given_name = "" } = modifiedUserData;
            // eslint-disable-next-line camelcase
            return `${family_name}, ${given_name}`;
        } else {
            return "";
        }
    };

    const clearReset = () => {
        setIntValues();
    };

    const validateUserName = async () => {
        const { firstName, lastName, middleName } = values;
        let isValid = true;
        let msg = "";
        if (firstName !== "" && lastName !== "") {
            /* eslint-disable camelcase */
            const userObj = { firstName, lastName, middleName };
            try {
                const userValidate = await userAlreadyExist(userObj, null, params.userId);
                if (userValidate?.isUserNameTaken) {
                    const fullUserName = userValidate?.details?.personnel_name
                        ? userValidate.details.personnel_name
                        : "";
                    const associateCustomer = userValidate?.details?.customer_number
                        ? userValidate?.details.customer_number
                        : "";
                    msg = t("authorization.userNameTaken", {
                        name: fullUserName,
                        associateCustomer: associateCustomer,
                    });
                    isValid = false;
                } else {
                    isValid = true;
                }
            } catch (error) {
                return error;
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            userName: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const setIntValues = (personalData) => {
        let authNumber = "";
        let pinNumber = "";
        if (userData.sys_user_id === personalData.personnel_id) {
            authNumber = personalData.auth_number ? personalData.auth_number : "";
            pinNumber = personalData.auth_pin ? personalData.auth_pin : "";
        }
        setAuthNumber(authNumber);
        setAuthPin(pinNumber);
        setValues({
            ...values,
            firstName: personalData.first_name,
            middleName: personalData.middle_name,
            lastName: personalData.last_name,
            contactInfoCountryCode: personalData.locale_id,
            homePhone: personalData.home_phone,
            cellular: personalData.cellular_phone,
            businessEmail: personalData.business_email_addr,
            homeEmail: personalData.home_email_addr,
            pager: personalData.pager_phone,
            pagerPin: personalData.pager_pin_number,
            enableSecureSync: personalData.isSecureSyncUser,
            challengeQuestion: personalData.Challenge_Dropdown_List_Id
                ? personalData.Challenge_Dropdown_List_Id
                : defaultChallengeQuestionValue,
            challengeResponse: personalData.Challenge_Dropdown_List_Id ? displayAnswer : "",
            confirmChallengeResponse: personalData.Challenge_Dropdown_List_Id ? displayAnswer : "",
            personalId: personalData.personnel_id,
        });
    };

    const fetchAuthInfo = async (userId) => {
        const res = await getAuthInfo(userId);
        // setRaws
        setLoading(true);
        const tempActivaCustomers = [];
        const tempNotActiveCustomes = [];
        if (res.authList && res.authList.length !== 0) {
            const promises = res.authList.map(async (e, index) => {
                const rowObj = {
                    id: index + 1,
                    account: e.customer_id,
                    customerNumber: <SecureSyncSupText name={e.customer_number} />,
                    name: e.customer_name,
                    branch: e.branch_name,
                    interactionAuth: e.interaction_code,
                    drAuth: e.dr_code,
                    editPermission: e?.edit_permission_flag === "Y" || userId === userData.sys_user_id ? true : false,
                    editIntPermission: e?.edit_int_flag === "Y" ? true : false,
                };

                if (e.dr_code === authLevelCode.none && e.interaction_code === authLevelCode.none) {
                    const isDuplicate = await getDuplicateUsers(props.personalData, rowObj.account);
                    if (isDuplicate) {
                        rowObj.editPermission = false;
                        rowObj.isDuplicate = true;
                        rowObj.customerNumber = <SecureSyncSupText name={e.customer_number} sup="2" />;
                    }
                    tempNotActiveCustomes.push(rowObj);
                } else {
                    tempActivaCustomers.push(rowObj);
                }
            });
            await Promise.all(promises);
        }
        setActiveCustomers(tempActivaCustomers);
        setNotActiveCustomers(tempNotActiveCustomes);
        setLoading(false);
    };

    const validatePhoneNumber = async (targetField, phoneNumber, label) => {
        let isValid = true;
        let msg = "";
        if (phoneNumber !== "") {
            const res = await validatePhoneFormat(
                { [targetField]: phoneNumber },
                { personnel: values.contactInfoCountryCode, business: values.accountInfoCountryCode }
            );
            if (res.failure && res.errorCode === "1") {
                isValid = false;
                const localeMsg = t("authorization.phoneServiceValidation");
                msg = paramSetService(localeMsg, [label, res.data.bad_format_message]);
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            [targetField]: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const onClickBack = () => {
        if (fromUserSearch) {
            goBack(searchUsers);
        } else {
            navigate(dashboard);
        }
    };

    const handleReset = () => setIntValues(props.personalData);

    const isLoggedInUser = useMemo(() => {
        return userData.sys_user_id === props?.personalData?.personnel_id;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.personalData]);

    const isEditMyInfo = useMemo(() => {
        return pathname.includes(myInfo);
    }, [pathname]);
    return (
        <>
            <CircularLoaderFullPage show={isLoading || isSubmitting} />
            <form noValidate onSubmit={handleSubmit}>
                <PageSubTitle>{t("authorization.formName")}</PageSubTitle>
                {!customValidations.userName.validate && (
                    <Typography color="error" sx={styles.typographyError}>
                        {customValidations.userName.message}
                    </Typography>
                )}
                <Box display="flex" gap={2} sx={styles.dataGridContainer}>
                    <TextField
                        label={t("formFields.firstName")}
                        name="firstName"
                        variant="outlined"
                        required
                        inputProps={{ maxLength: 30 }}
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        fullWidth
                        style={{ flex: 1 }}
                        inputRef={firstNameRef}
                    />
                    <TextField
                        label={t("authorization.middleName")}
                        name="middleName"
                        variant="outlined"
                        inputProps={{ maxLength: 1 }}
                        onBlur={handleBlur}
                        value={values.middleName}
                        onChange={handleChange}
                        error={touched.middleName && Boolean(errors.middleName)}
                        helperText={touched.middleName && errors.middleName}
                        fullWidth
                        style={{ flex: 1 }}
                    />
                    <TextField
                        label={t("formFields.lastName")}
                        variant="outlined"
                        name="lastName"
                        inputProps={{ maxLength: 30 }}
                        onBlur={handleBlur}
                        required
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        fullWidth
                        style={{ flex: 1 }}
                        inputRef={lastNameRef}
                    />
                    <div style={{ flex: 1 }} />
                </Box>
                <Divider />
                <PageSubTitle>{t("authorization.contactInfo")}</PageSubTitle>
                <Box display="flex" flexWrap="wrap" gap={"20px"} sx={styles.dataGridContainer}>
                    <TextField
                        required
                        label={t("formFields.businessEmail")}
                        name="businessEmail"
                        variant="outlined"
                        value={values.businessEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.businessEmail && Boolean(errors.businessEmail)}
                        helperText={touched.businessEmail && errors.businessEmail}
                        fullWidth
                        inputRef={businessEmailRef}
                    />
                    <FormControl sx={styles.dataGrid}>
                        <CountriesList
                            id="country-code-select"
                            label={t("authorization.countryPhoneCode")}
                            name="contactInfoCountryCode"
                            value={values.contactInfoCountryCode}
                            onChangeCallBack={(item) => {
                                values.contactInfoCountryCode = item;
                            }}
                            onBlur={handleBlur}
                            error={touched.contactInfoCountryCode && Boolean(errors.contactInfoCountryCode)}
                            helperText={touched.contactInfoCountryCode && errors.contactInfoCountryCode}
                        />
                    </FormControl>
                    <TextField
                        label={t("authorization.homePhone")}
                        name="homePhone"
                        variant="outlined"
                        value={values.homePhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.homePhone && (Boolean(errors.homePhone) || !customValidations.homePhone.validate)
                        }
                        helperText={touched.homePhone && (errors.homePhone || customValidations.homePhone.message)}
                        fullWidth
                        inputRef={homePhoneRef}
                    />
                    <TextField
                        label={t("authorization.celluler")}
                        name="cellular"
                        variant="outlined"
                        value={values.cellular}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.cellular && (Boolean(errors.cellular) || !customValidations.cellPhone.validate)}
                        helperText={touched.cellular && (errors.cellular || customValidations.cellPhone.message)}
                        fullWidth
                        inputRef={cellPhoneRef}
                    />
                    <TextField
                        label={t("authorization.homeEmail")}
                        name="homeEmail"
                        variant="outlined"
                        value={values.homeEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <TextField
                        label={t("authorization.pager")}
                        name="pager"
                        variant="outlined"
                        value={values.pager}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.pager && (Boolean(errors.pager) || !customValidations.pager.validate)}
                        helperText={touched.pager && (errors.pager || customValidations.pager.message)}
                        fullWidth
                        inputRef={pagerPhoneRef}
                    />
                    <TextField
                        label={t("authorization.pagerPin")}
                        name="pagerPin"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        value={values.pagerPin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={styles.toggleButton}
                >
                    <Box>
                        <FormControlLabel
                            control={<Switch />}
                            label={t("authorization.secureSyncUserEnable")}
                            disabled={props?.personalData?.isSecureSyncUser}
                            checked={values.enableSecureSync}
                            onChange={handleChange}
                            name="enableSecureSync"
                        />
                    </Box>
                    {!props?.personalData?.isSecureSyncUser && (
                        <Box>
                            <Typography
                                variant="body1"
                                color="im_grey.900"
                                component="div"
                                sx={styles.switchDescription}
                            >
                                {t("authorization.ssToggleOn")}
                                <br />
                                {t("authorization.ssToggleOf")}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Divider />
                <PageSubTitle>{t("userDetails.securtyInfo")}</PageSubTitle>
                {isLoggedInUser && (
                    <Grid container spacing={1} sx={styles.securityGridContainer}>
                        {/* Adjust spacing as needed */}
                        <Grid item xs={3}>
                            <SecureSyncReadOnlyTextField
                                value={authNumber ? authNumber : " "}
                                label={t("userDetails.authNumber")}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <SecureSyncReadOnlyTextField value={authPin ? authPin : " "} label={t("userDetails.pin")} />
                        </Grid>
                    </Grid>
                )}
                <Box sx={styles.challengeQuestionContainer}>
                    <Box sx={styles.challengeAnswer}>
                        <SecureSyncSelect
                            id="challenge-question-select"
                            label={t("postLoginCheck.challengeQuestion")}
                            value={values.challengeQuestion}
                            required={isEditMyInfo}
                            defaultOption={
                                questions.length > 0
                                    ? values.challengeQuestion
                                        ? values.challengeQuestion
                                        : questions[0].descr
                                    : ""
                            }
                            options={questions}
                            name="challengeQuestion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Box>
                    <Box sx={styles.dataGridContainer}>
                        <Box sx={styles.challengeAnswer}>
                            <TextField
                                label={t("formFields.challengeResponse")}
                                variant="outlined"
                                name="challengeResponse"
                                type="password"
                                sx={styles.challengeField}
                                value={values.challengeResponse}
                                onChange={handleChange}
                                required={isEditMyInfo}
                            />
                        </Box>
                        <Box sx={styles.challengeAnswer}>
                            <TextField
                                label={t("formFields.confirmChallengeResponse")}
                                variant="outlined"
                                type="password"
                                sx={styles.challengeField}
                                name="confirmChallengeResponse"
                                value={values.confirmChallengeResponse}
                                error={touched.confirmChallengeResponse && Boolean(errors.confirmChallengeResponse)}
                                helperText={touched.confirmChallengeResponse && errors.confirmChallengeResponse}
                                required={isEditMyInfo}
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <PageSubTitle>{t("userDetails.activeAccount")}</PageSubTitle>
                <Typography variant="body2" sx={styles.userDetailBody1}>
                    <Trans i18nKey={"userDetails.editDesc"} />
                </Typography>
                <CustomerList rows={activeCustomers} enabledEdit={true} />
                <Divider />
                {notActiveCustomers && notActiveCustomers.length !== 0 && (
                    <>
                        <Typography variant="h5" sx={styles.dividerSpacing}>
                            {t("userDetails.otherAccount")}
                        </Typography>
                        <Typography variant="body2" sx={styles.userDetailBody1}>
                            <Trans i18nKey={"userDetails.editDesc"} />
                        </Typography>
                        <CustomerList rows={notActiveCustomers} activeRows={activeCustomers} enabledEdit={true} />
                    </>
                )}
                <ModifyHistory
                    modUserOpen={modUserOpen}
                    personalData={props.personalData}
                    closePopup={() => setModUserOpen(false)}
                />
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClickBack}
                        startIcon={fromUserSearch && <IconLeft />}
                    >
                        {fromUserSearch ? t("buttonLabels.back") : t("buttonLabels.cancel")}
                    </Button>
                    <Button variant="outlined" onClick={() => setModUserOpen(true)} color="primary">
                        {t("buttonLabels.lastModifyData")}
                    </Button>
                    <Button variant="outlined" onClick={resetAuthNumberCall} color="primary">
                        {t("buttonLabels.resetAuthNumber")}
                    </Button>
                    {isLoggedInUser && (
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.changePin)}
                            variant="outlined"
                            onClick={props.navigateToUserPin}
                            color="primary"
                        >
                            {t("buttonLabels.changePin")}
                        </Button>
                    )}
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.reset)}
                        variant="outlined"
                        color="primary"
                        onClick={handleReset}
                    >
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        variant="contained"
                        type="submit"
                        color="primary"
                        sx={styles.buttonSubmit}
                    >
                        {t("buttonLabels.submit")}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default EditUserForm;
