import { IconButton, SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./SearchUsersTable.styles";
import Pencil from "src/components/Icons/Pencil/Pencil";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { programIds } from "src/constants/programIdsConstants";
import { getBasicUserInfo, getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { getUserPath, searchUsers } from "src/constants/routeConstants/administrationRouterFullPaths";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const SearchUsersTable = ({ users, noDataMessage }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);
    const userDetails = useSelector(getBasicUserInfo);
    const hasEditAccess =
        isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_101) ||
        isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_223);
    const handleEditClick = (row) => {
        navigate(getUserPath(row.personnel_id));
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => {
                    if (hasEditAccess || params?.personnel_id === userDetails?.sys_user_id) {
                        return (
                            <IconButton
                                onClick={() => {
                                    handleEditClick(params);
                                }}
                            >
                                <SvgIcon component={Pencil} sx={styles.iconColor} />
                            </IconButton>
                        );
                    }
                    return null;
                },
            },
            { field: "personnel_name", headerName: convertToLocaleUpperCase(t("tableHeader.name")) },
            {
                field: "interaction_auth_code",
                headerName: convertToLocaleUpperCase(t("tableHeader.interactionAuthority")),
            },
            { field: "dr_auth_code", headerName: convertToLocaleUpperCase(t("tableHeader.drAuthority")) },
            { field: "business_phone", headerName: convertToLocaleUpperCase(t("tableHeader.businessPhone")) },
            { field: "employee_number", headerName: convertToLocaleUpperCase(t("tableHeader.employeeNo")) },
        ].filter(Boolean);
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasEditAccess]);

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(searchUsers, { state: { ...locationState, ...attributes }, replace: true });
    };

    return (
        <SecureSyncTable
            rows={users}
            columns={columns}
            noDataMessage={noDataMessage}
            initialPage={location.state?.pageNumber}
            initialRowsPerPage={location.state?.rowsPerPage}
            getRowId={(row) => row.id}
            onTableAttributesChange={onTableStateChange}
        />
    );
};

export default SearchUsersTable;
