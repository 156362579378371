import { Box, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./SendListFiles.styles";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import { forbiddenExtensions } from "src/constants/fileFormatConstants";
import { filterData, getFilenameFromPathFolderPath } from "src/utils/commonHelper";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getSelectedCustomerId } from "src/services/utils";
import { errorCodes } from "src/constants/errorCodes";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getApiErrorMessage } from "src/services/utils";
import { getFileContent, matchesSample } from "src/utils/processMediaHelper";
// eslint-disable-next-line camelcase
import { FILE_SIZE_LIMIT_Kb, MAXIMUM_FILE_SIZE_ALLOWED_Kb } from "src/constants/serviceConstants";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import {
    getCustomerFileDefinitions,
    insertLoggedEvent,
    storeFile,
    storeFileBinary,
} from "src/services/processMediaService";
export const SendListFiles = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [fileNames, setFileNames] = useState("");
    const [fileFormatError, setFileFormatError] = useState({
        fileName: "",
        hasError: false,
        errorMsg: "",
    });
    const [acceptableFileMasks, setAcceptableFileMasks] = useState([]);
    const [fileMasks, setFileMasks] = useState([]);
    const [alertObj, setAlertObj] = useState({
        severity: "",
        message: "",
        show: false,
    });

    const fileReadHandler = async (file) => {
        const buffer = await file.arrayBuffer();
        const byteArray = new Int8Array(buffer);
        return byteArray;
    };

    const handleFileRead = async (event) => {
        setIsLoading(true);
        setFileFormatError({ fileName: "", hasError: false, errorMsg: "" });
        setAlertObj({ message: "", severity: "", show: false });
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        try {
            if (file) {
                const fileType = file.type;
                const fileName = file.name;
                const fileSize = file.size;
                const fileExtension = fileName.split(".").pop().toLowerCase();
                var storeFileRes = null;
                const fileMatched = checkFileName(fileName, fileMasks);
                // eslint-disable-next-line camelcase
                const maximumFileSize = MAXIMUM_FILE_SIZE_ALLOWED_Kb * FILE_SIZE_LIMIT_Kb;
                if (fileSize < maximumFileSize) {
                    if (fileMatched.matched) {
                        const selectedFileType = filterData(
                            acceptableFileMasks,
                            "sampleFileName",
                            fileMatched.pattern.fileName
                        );
                        if (fileType === "application/zip" || fileName.endsWith(".zip")) {
                            try {
                                const byteArr = await fileReadHandler(file);
                                storeFileRes = await storeFileBinary({
                                    customerFileDefinition: selectedFileType[0]?.customer_file_definition_id,
                                    path: fileMatched.pattern.folderPath,
                                    fileName: fileName,
                                    file: byteArr,
                                });
                            } catch (error) {
                                console.error("fileReadHandler Error : ", error);
                            }
                        } else if (forbiddenExtensions.has(fileExtension)) {
                            setAlertObj({
                                message: t("SendListFile.invalidFileFormatError"),
                                severity: "error",
                                show: true,
                            });
                        } else {
                            try {
                                const fileContent = await getFileContent(file);
                                storeFileRes = await storeFile({
                                    customerFileDefinition: selectedFileType[0]?.customer_file_definition_id,
                                    path: fileMatched.pattern.folderPath,
                                    fileName: fileName,
                                    file: fileContent,
                                });
                            } catch (error) {
                                console.error("getFileContent Error : ", error);
                            }
                        }

                        if (!storeFileRes.failure) {
                            const errorObj = storeFileRes?.rs1[0];
                            const response0Error = storeFileRes.rs0[0].error_msg;
                            if (parseInt(errorObj.error) > 0) {
                                setFileFormatError({
                                    fileName: fileName,
                                    hasError: true,
                                    errorMsg: t(`apiErrorMessages.${errorObj.error}`),
                                });
                                if (errorObj.error === errorCodes.Code63643) {
                                    setAlertObj({
                                        severity: "error",
                                        message: t("messages.uploadFileNotFound"),
                                        show: true,
                                    });
                                } else if (errorObj.error === errorCodes.Code63645) {
                                    const comments = `${response0Error}. This problem occurs in Send List File when the destination folder does not exist on the parser server. File Path: ${selectedFileType[0]?.file_path}`;
                                    const logResponse = await createLog("", comments);

                                    setAlertObj({
                                        severity: "error",
                                        message: `${t("messages.errorInUploading")} L${logResponse?.rs0[0]?.logged_event_id}`,
                                        show: true,
                                    });
                                }
                            } else {
                                setFileNames(`${fileNames}${file.name}\n`);
                                const comment = `Send List File- File Upload Success - File:  ${selectedFileType[0]?.file_path} + ${fileName} Customer_Id = ${getSelectedCustomerId()}`;
                                await createLog("", comment);
                            }
                        } else {
                            setAlertObj({ severity: "error", message: "File upload failed", show: true });
                        }
                    } else {
                        setAlertObj({
                            message: getApiErrorMessage("63548"),
                            severity: "error",
                            show: true,
                        });
                    }
                } else {
                    setFileFormatError({
                        fileName: fileName,
                        hasError: true,
                        errorMsg: t("sendListFiles.fileSizeExceed"),
                    });
                }
            }
        } catch (error) {
            console.error("handleFileRead Error : ", error);
        } finally {
            setIsLoading(false);
        }
        event.target.value = "";
    };

    const checkFileName = (fileName, fileSet) => {
        // eslint-disable-next-line prefer-const
        for (let pattern of fileSet) {
            if (matchesSample(fileName, pattern)) {
                return { matched: true, pattern: pattern };
            }
        }
        return { matched: false, pattern: null };
    };

    useEffect(() => {
        const customerFileDefinition = async () => {
            setIsLoading(true);
            try {
                const fileNameSet = [];
                const response = await getCustomerFileDefinitions();
                if (!response.failure) {
                    const responseData = await response.responseData.map((value) => {
                        fileNameSet.push(getFilenameFromPathFolderPath(value.file_path));
                        return {
                            ...value,
                            sampleFileName: getFilenameFromPathFolderPath(value.file_path).fileName,
                            folderPath: getFilenameFromPathFolderPath(value.file_path).folderPath,
                        };
                    });
                    setAcceptableFileMasks(responseData);
                    setFileMasks(fileNameSet);
                }
            } catch (error) {
                console.error("Error : ", error);
            } finally {
                setIsLoading(false);
            }
        };

        customerFileDefinition();
    }, []);

    const createLog = async (description, comment) => {
        try {
            const response = await insertLoggedEvent(description, comment);
            return response;
        } catch (error) {
            console.error("createLog - catch error: ", error);
        }
    };

    const closeAlertHandler = () => {
        setAlertObj({ message: "", severity: "", show: false });
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <PageTitle>{t("sendListFiles.sendListFile")}</PageTitle>
                        {alertObj.show && (
                            <Box sx={styles.marginBottomBreak}>
                                <SecureSyncAlert
                                    message={<Typography fontWeight={500}>{alertObj.message}</Typography>}
                                    severity={alertObj.severity}
                                    onClose={closeAlertHandler}
                                />
                            </Box>
                        )}
                        {acceptableFileMasks.length > 0 ? (
                            <>
                                <Typography>
                                    <Trans
                                        i18nKey={"sendListFiles.submitYourListFile"}
                                        values={{ buttonLabel: t("common.uploadFile") }}
                                    />
                                </Typography>
                                <Box sx={styles.multilineTextFields}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label={t("sendListFiles.submittedFiles")}
                                        multiline
                                        rows={9}
                                        variant="outlined"
                                        value={fileNames}
                                        fullWidth
                                        sx={styles.defaultCursor}
                                    />
                                </Box>
                                <Box>
                                    <br />
                                    <Typography sx={styles.footNotes}>{t("sendListFiles.sendFile")}</Typography>
                                    <Box
                                        sx={styles.uploadBtn}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="left"
                                        gap={2}
                                    >
                                        <SecureSyncFileUpload
                                            startIcon={<UploadIcon />}
                                            variant="contained"
                                            onChange={handleFileRead}
                                            name={t("common.uploadFile")}
                                        />
                                    </Box>
                                    {fileFormatError.hasError !== true ? (
                                        <Typography sx={styles.footNotes}>
                                            {t("sendListFiles.browseFilesToUpload")} <br />
                                            <Box component="span">{t("sendListFiles.maxFileSize")}</Box>
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            <Box component="span" sx={styles.errorText}>
                                                {t("sendListFiles.importFailedForFile", {
                                                    fileName: fileFormatError.fileName,
                                                })}
                                            </Box>
                                            <br />
                                            <Box component="span" sx={styles.errorText}>
                                                {fileFormatError.errorMsg}
                                            </Box>
                                        </Typography>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <Box sx={styles.fullHeight}>
                                <SecureSyncAlert
                                    message={
                                        <>
                                            {t("sendListFiles.alertWarning1")}
                                            <br /> {t("sendListFiles.alertWarning2")}
                                        </>
                                    }
                                    severity="warning"
                                    onClose={() => {}}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </>
    );
};
