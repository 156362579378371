const ExclamationMark = ({ fill, width = "20px", height = "20px" }) => {
    return (
        <svg style={{ width, height }} viewBox="0 0 80 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40 50.0103C38.36 50.0103 37 48.6502 37 47.0103V26.0103C37 24.3703 38.36 23.0103 40 23.0103C41.64 23.0103 43 24.3703 43 26.0103V47.0103C43 48.6502 41.64 50.0103 40 50.0103Z"
                fill={fill}
            />
            <path
                d="M40 64.0101C38.92 64.0101 37.9199 63.6102 37.1599 62.8502C36.7999 62.4502 36.52 62.0102 36.28 61.5302C36.08 61.0502 36 60.5301 36 60.0101C36 58.9701 36.4399 57.9301 37.1599 57.1701C38.6399 55.6901 41.3601 55.6901 42.8401 57.1701C43.5601 57.9301 44 58.9701 44 60.0101C44 60.5301 43.8799 61.0502 43.6799 61.5302C43.4799 62.0102 43.2001 62.4502 42.8401 62.8502C42.0801 63.6102 41.08 64.0101 40 64.0101Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.2007 83.9301C34.6007 85.3301 37.3207 86.0101 40.0007 86.0101C42.6807 86.0101 45.4008 85.3301 47.8008 83.9301L71.5608 70.2101C76.3608 67.4101 79.3608 62.2501 79.3608 56.6901V29.3302C79.3608 23.7702 76.3608 18.6102 71.5608 15.8102L47.8008 2.09023C42.9608 -0.709766 37.0007 -0.709766 32.2007 2.09023L8.44067 15.8102C3.64067 18.6102 0.640625 23.7702 0.640625 29.3302V56.6901C0.640625 62.2501 3.64067 67.4101 8.44067 70.2101L32.2007 83.9301ZM35.2007 7.29018C36.6807 6.45018 38.3607 6.01016 40.0007 6.01016C41.6407 6.01016 43.3207 6.45018 44.7607 7.29018L68.5208 21.0102C71.4808 22.7302 73.3208 25.9302 73.3208 29.3302V56.6901C73.3208 60.1301 71.4808 63.2901 68.5208 65.0101L44.7607 78.7301C41.8407 80.4501 38.1607 80.4501 35.2007 78.7301L11.4407 65.0101C8.48067 63.2901 6.64062 60.0901 6.64062 56.6901V29.3302C6.64062 25.8902 8.48067 22.7302 11.4407 21.0102L35.2007 7.29018Z"
                fill={fill}
            />
        </svg>
    );
};

export default ExclamationMark;
