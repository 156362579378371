const styles = {
    typographyBody: {
        color: "im_grey.900",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
};

export default styles;
