import { getDocs, query, where } from "firebase/firestore";
import { sha256 } from "js-sha256";
import { t } from "i18next";
import { businessEmailAddress, equals, login, secureSyncUser, yesFlag } from "../constants/serviceConstants";
import { constructFbHeaders, getCustomers, getLoggedUserDetails, getSelectedCustomerDb, invokeApi } from "./utils";
import {
    apiResultSet,
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    apiResultSet4Collection,
    apiResultSet5Collection,
    personnel_details_collection,
} from "./firebase";
import { createArrayObj } from "./utils";
import { createAddCutomerPayload } from "./payloadService";
import { getPersonalInformation, getUserAddress } from "./authorizationService";
import { ssoGetUser, ssoHandleService } from "./ciamServices";
import { sendAuthMail, sendUserCreateMailCreated } from "./mailService";
import { traceWrapper } from "./firebasePerformance";
import { paramSetService } from "src/utils/messageParamSet";

const apiUrls = {
    validateUserLogin: "/validatelogin/validateuserlogin",
    getLoginInformation: "/getlogininformation/getlogininformation",
    updateLoginItems: "/updateuserinfo/updateloginitems",
    createPersonnel: "/ssautherization/createpersonnel",
    setSSOUser: "/authorization/setssouser",
};

/**
 * Returns logged in user details by quering the personnel collection
 *
 */
export const getSSUser = async (ssoLogin) => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-GetSSUser");
    traceStart();
    const q = query(
        personnel_details_collection,
        where(businessEmailAddress, equals, ssoLogin),
        where(secureSyncUser, equals, yesFlag),
        where(login, equals, ssoLogin)
    );

    const snapshot = await getDocs(q);
    const users = [];
    snapshot.forEach((doc) => {
        users.push(doc.data());
    });
    traceStop();
    return users[0];
};

/**
 * Method returns post login check details and any preference if logged in user has only 1 customer selected
 * @param {*} login
 * @param {*} subject
 * @returns user preference details object
 */

export const getLoggedInUserDetails = async (login, subject) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateUserLogin);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_LOGIN + "/validatelogin/validateuserlogin";
    const body = {
        main_district_id: "50",
        login: login,
        oag_uid: subject,
        ssie_web_server_name: process.env.REACT_APP_WEB_SERVER_NAME,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const rs2Snapshot = await getDocs(apiResultSet2Collection(resDoc));
        if (rs2Snapshot.empty) {
            const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
            const rs0Error = [];
            rs0Snapshot.forEach((doc) => {
                rs0Error.push(doc.data());
            });
            return { failed: true, errorCode: rs0Error[0].error };
        }
        const rs2Error = [];
        rs2Snapshot.forEach((doc) => {
            rs2Error.push(doc.data());
        });
        const isError = rs2Error[0].error !== "0";
        if (isError) {
            return new Error(" error with code ", rs2Error[0].error);
        }
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
        const rs1Snapshot = await getDocs(apiResultSet1Collection(resDoc));
        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });
        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            const customer = doc.data();
            if (customer?.ssie_web_server_name) {
                delete customer.ssie_web_server_name;
            }
            if (customer?.district_server_name) {
                delete customer.district_server_name;
            }
            rs1Data.push(customer);
        });

        rs1Data.sort((a, b) => {
            if (a.customer_number < b.customer_number) {
                return -1;
            }
            if (a.customer_number > b.customer_number) {
                return 1;
            }
            return 0;
        });
        const userRes = {
            postLoginCheck: rs0Data[0],
            customers: rs1Data,
        };
        return userRes;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns logged in user preference, customer information, menu, program ids, session guid. This method should
 * be called after the user has selected the customer
 * @param {*} userName - securesycn username
 * @param {*} systemUserId - securesync user id
 * @param {*} customerNumber - customer number selected
 * @param {*} customerId  - customer id selected
 * @returns An object with all preferences
 */
export const getUserPreferenceDetails = async (userName, systemUserId, customerNumber, customerId, districtId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getLoginInformation);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_LOGIN + "/getlogininformation/getlogininformation";
    const dbId = districtId ? districtId : getSelectedCustomerDb();
    const body = {
        main_district_id: dbId,
        login: userName,
        sys_user_id: systemUserId,
        customer_number: customerNumber,
        customer_id: customerId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        // check if resultSet_2 is present.. then it's most likely successful scenario
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs4Snapshot, rs5Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
            getDocs(apiResultSet4Collection(resDoc)),
            getDocs(apiResultSet5Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const personalTimeOffset = rs0Data[0]?.Time_Zone_Offset || "0";

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        const rs4Data = [];
        rs4Snapshot.forEach((doc) => {
            rs4Data.push(doc.data());
        });

        const rs5Data = [];
        rs5Snapshot.forEach((doc) => {
            rs5Data.push(doc.data());
        });

        const programIds = rs2Data[0]?.ProgIds || "";
        const preference = {
            personnel: rs1Data,
            personalTimeOffset,
            programIds: programIds.split(","),
            sessionGuid: rs4Data[0].session_guid,
            personnelCustomerPreference: rs0Data[0],
        };
        return preference;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Updates user with login page preferences
 * @param {*} userName
 * @param {*} defaultCustomerId
 * @param {*} defaultCustLogin
 * @param {*} challengeDropDownlistId
 * @param {*} challengeResponse
 * @returns
 */
export const updateLoginPreferences = async (
    userName,
    defaultCustomerId,
    defaultCustLogin,
    challengeDropDownlistId,
    challengeResponse
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateLoginItems);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_LOGIN + "/updateuserinfo/updateloginitems";
    const body = {
        main_district_id: "50",
        login: userName,
    };
    if (defaultCustomerId) {
        body.default_customer_id = defaultCustomerId;
    }

    if (defaultCustLogin) {
        body.default_cust_login_flag = defaultCustLogin;
    }

    if (challengeDropDownlistId) {
        body.challenge_dropdown_list_id = challengeDropDownlistId;
    }

    if (challengeResponse) {
        body.challenge_response = sha256(challengeResponse.toUpperCase()).toUpperCase();
    }

    if (challengeDropDownlistId) {
        body.challenge_dropdown_list_id = challengeDropDownlistId;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0].error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        return { failure: false };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

// eslint-disable-next-line max-lines-per-function
export const addNewPersonService = async (request, permission) => {
    const { traceStart, traceStop } = traceWrapper("Fn:NewPersonService");
    traceStart();
    let mailHasError = false;
    const custMetaData = getCustomers();
    let extraWarningMsg = "";
    let targetCustomer = null;
    if (custMetaData.custList.length === 1) {
        targetCustomer = custMetaData.selectedCustomer.customer_id ? custMetaData.selectedCustomer.customer_id : false;
    } else {
        targetCustomer = request.selectedCustomers[0];
    }
    const payload = createAddCutomerPayload(request);
    try {
        const userDetails = getLoggedUserDetails();
        const isSecureSyncUser = request.enableSecureSync;
        const res = await createUser(payload);
        if (res.errorCode && res.errorCode && res.errorCode.hasError) {
            if (res.errorCode.severity === "E") {
                return {
                    error: true,
                    message: res.errorCode.displayMessage, // res.errorCode[0]
                };
            } else {
                extraWarningMsg = t(`apiErrorMessages.${res.errorCode.error.errorCode}`);
            }
        }
        const sqlUserCreated = res.personalData;

        if (sqlUserCreated) {
            const userObj = {
                first_name: payload.payload.first_name,
                last_name: payload.payload.last_name,
                home_phone: "",
                business_phone: "",
                business_phone_ext: "",
                e_level_authorization: "",
                duplicate_check_flag: "Y",
                auth_number: "",
                middle_name: payload.payload.middle_name,
            };

            let user = await getUserAddress(userObj, targetCustomer);
            user = user.rs0[0] ? user.rs0[0] : null;
            if (isSecureSyncUser) {
                const sSoRes = await ssoHandleService(request.businessEmail, request, true);
                if (sSoRes) {
                    const oktaUser = await ssoGetUser(request.businessEmail);
                    const ssoPayload = {
                        main_district_id: "50",
                        personnel_id: user.personnel_id,
                        ciam_uid: oktaUser.userId,
                        rc: "1",
                    };
                    await ssoRegister(ssoPayload);
                }
            } else {
                const tempAuth = await getPersonalInformation(user.personnel_id);

                // sso creation
                const authto = [
                    {
                        emailAddress: payload.payload.business_email_addr,
                        name: payload.payload.first_name,
                    },
                    {
                        emailAddress: userDetails?.oktaUser?.email,
                        name: userDetails?.oktaUser?.name,
                    },
                ];
                const modifyTo = [
                    {
                        emailAddress: userDetails?.oktaUser?.email,
                        name: userDetails?.oktaUser?.name,
                    },
                ];
                const createdTo = [
                    {
                        emailAddress: payload.payload.business_email_addr,
                        name: payload.payload.first_name,
                    },
                ];
                // send auth data
                const authMailRes = await sendAuthMail(
                    tempAuth?.personalData?.personnel_id ? tempAuth.personalData.personnel_id : "",
                    user.auth_number,
                    payload.payload.last_mod_user,
                    authto
                );
                const userCreateMailRes = await sendUserCreateMailCreated(
                    user.customer_number,
                    user.personnel_name,
                    payload.payload.last_mod_user,
                    createdTo
                );

                const userCreateMailResAuther = await sendUserCreateMailCreated(
                    user.customer_number,
                    user.personnel_name,
                    payload.payload.last_mod_user,
                    modifyTo,
                    userDetails?.oktaUser?.email
                );
                if (!authMailRes.isSuccess || !userCreateMailRes.isSuccess || !userCreateMailResAuther.isSuccess) {
                    mailHasError = true;
                }
            }

            let sucMessage = "";
            let dRCancelledMessage = "";
            let interactionCancelledMessage = "";
            let cancelledMessage = "";

            if (res.successCustomerList && res.successCustomerList.length >= 1) {
                const tempListSuccess = [];
                res.successCustomerList.forEach((customer) => {
                    tempListSuccess.push(customer.number);
                });
                tempListSuccess.sort();
                sucMessage = t("authorization.createSuccess", {
                    name: user.personnel_name,
                    value: tempListSuccess.toString(),
                });
            }
            if (res.dRCancelledList && res.dRCancelledList.length >= 1 && permission?.canEditDrAuth) {
                const tempList = [];
                res.dRCancelledList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                dRCancelledMessage = `${paramSetService(t(`authorization.drCancelMsg`), [tempList.toString()])}`;
            }
            if (
                res.interactionCancelledCustomerList &&
                res.interactionCancelledCustomerList.length >= 1 &&
                permission?.canEditInt
            ) {
                const tempList = [];
                res.interactionCancelledCustomerList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                interactionCancelledMessage = t("authorization.interactionCancelMsg", { value: tempList.toString() });
            }
            if (res.cancelledList && res.cancelledList.length >= 1) {
                const tempList = [];
                res.cancelledList.forEach((customer) => {
                    tempList.push(customer.number);
                });
                tempList.sort();
                cancelledMessage = t("authorization.cancelListMsg", { value: tempList.toString() });
            }

            return {
                error: false,
                sucMessage,
                dRCancelledMessage,
                interactionCancelledMessage,
                cancelledMessage,
                extraWarningMsg,
                mailHasError,
            };
        } else {
            return {
                error: true,
                message: t("authorization.authLevelNotMatched"), // res.errorCode[0]
            };
        }
    } catch (error) {
        return error;
    } finally {
        traceStop();
    }
};

/**
 * Creates a securesync user with provided details
 * @param {*} personnel object which contains all personnel details
 * @returns
 */
export const createUser = async (request) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createPersonnel);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/createpersonnel";
    try {
        const custCount = request.customerCount;

        const res = await invokeApi("POST", loginUrl, request.payload, {}, headers);
        const resDoc = res?.docId;

        let startCollection = custCount;
        for (let index = 0; index < custCount; index++) {
            const snapShot = await getDocs(apiResultSet(resDoc, `resultSet_${index}`));
            const snapObj = await createArrayObj(snapShot);
            const notAnCustomerDetails = snapObj[0] && snapObj[0].number;
            if (notAnCustomerDetails) {
                startCollection = index;
                break;
            }
        }

        const personalDataString = `resultSet_0`;
        const successCustomerListString = `resultSet_${startCollection}`;
        const cancelledListString = `resultSet_${startCollection + 1}`;
        const interactionCancelledCustomerListString = `resultSet_${startCollection + 2}`;
        const dRCancelledListString = `resultSet_${startCollection + 3}`;
        const errorCodeString = `resultSet_${startCollection + 4}`;

        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot, rs5Snapshot] = await Promise.all([
            getDocs(apiResultSet(resDoc, personalDataString)),
            getDocs(apiResultSet(resDoc, successCustomerListString)),
            getDocs(apiResultSet(resDoc, cancelledListString)),
            getDocs(apiResultSet(resDoc, interactionCancelledCustomerListString)),
            getDocs(apiResultSet(resDoc, dRCancelledListString)),
            getDocs(apiResultSet(resDoc, errorCodeString)),
        ]);
        const personalData = await createArrayObj(rs0Snapshot);
        const successCustomerList = await createArrayObj(rs1Snapshot);
        const cancelledList = await createArrayObj(rs2Snapshot);
        const interactionCancelledCustomerList = await createArrayObj(rs3Snapshot);
        const dRCancelledList = await createArrayObj(rs4Snapshot);
        const errorCode = await createArrayObj(rs5Snapshot);
        return {
            personalData: personalData && personalData[0] ? personalData[0] : null,
            successCustomerList,
            cancelledList,
            interactionCancelledCustomerList,
            dRCancelledList,
            errorCode: errorCode[0],
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const ssoRegister = async (request) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.setSSOUser);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_SSO + "/authorization/setssouser";
    try {
        await invokeApi("POST", loginUrl, request, {}, headers);
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
