import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import styles from "./NewOrEmptyTransport.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getSelectedCustomer } from "src/services/utils";
import { getServiceFrequency, updatedDropdownData } from "src/utils/requestHelper";
import {
    createTransportRequestService,
    getActiveCustomersSiblingsService,
    getComboBoxDescriptionForNewOrEmptyContainer,
} from "src/services/requestServices";
import { formatDate, getMinAndMaxDate, getTomorrowDate } from "src/utils/dateHelper";
import { calculateAddOnCutOffFlag, generateTransportRequestDetails } from "src/utils/newContainerLockInsertHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import ServiceDateCalendarTooltip from "src/components/AppComponents/ServiceDateCalendarTooltip/ServiceDateCalendarTooltip";
import ContainerInformation from "src/components/Request/NewOrEmptyTransport/ContainerInformation/ContainerInformation";
import { errorCodes } from "src/constants/errorCodes";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import { requestActionIds, requestSortCriteria, transportTypeConst } from "src/constants/requestConstants";
import NewOrEmptyTransportSuccessModal from "src/components/Request/NewOrEmptyTransport/NewOrEmptyTransportSuccessModal/NewOrEmptyTransportSuccessModal";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import ServiceDateCalendar from "src/components/AppComponents/ServiceDateCalendar/ServiceDateCalendar";
import { multipleSort } from "src/utils/processMediaHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getNewOrEmptyTransportPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    serviceLocationId: "",
    serviceDate: getTomorrowDate(),
    comments: "",
};

const commentsMaxLength = 255;

const validationSchema = (t) => {
    const { minDateForApi: minDate, maxDateForApi: maxDate, displayMaxDate, displayMinDate } = getMinAndMaxDate();
    return Yup.object().shape({
        serviceLocationId: Yup.string(),
        serviceDate: Yup.string()
            .required(t("messages.serviceDateRequired"))
            .test("validateServiceDate", (value, { createError, path }) => {
                const isValidDate = dayjs(value).isValid();
                const isOutSideMinDate = dayjs(value).isBefore(minDate);
                const isOutSideMaxDate = dayjs(value).isAfter(maxDate);
                let message = "";
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate || isOutSideMinDate) {
                    message = (
                        <Trans
                            i18nKey={"messages.serviceDateRange"}
                            values={{
                                serviceDate: t("formFields.serviceDate"),
                                minDate: displayMinDate,
                                maxDate: displayMaxDate,
                            }}
                        />
                    );
                }

                if (message) {
                    return createError({
                        path,
                        message,
                    });
                } else {
                    return true;
                }
            }),
        comments: Yup.string().max(
            commentsMaxLength,
            t("messages.commentsMaxLength", { maxLength: commentsMaxLength })
        ),
    });
};

const NewOrEmptyTransportHeader = ({ isLoading }) => {
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <PageTitle>
                <Trans i18nKey={"titles.newOrEmptyTransport"} />
            </PageTitle>
        </>
    );
};

const initialAlertState = { show: false, message: "" };

const NewOrEmptyTransport = () => {
    const shortCutKeys = getShortCutKeys();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isScheduleServiceLoading, setIsScheduleServiceLoading] = useState(false);
    const [isServiceDateLoading, setIsServiceDateLoading] = useState(false);
    const [serviceLocations, setServiceLocations] = useState([]);
    const [dropdownData, setDropDownData] = useState({});
    const [serviceFrequencyValues, setServiceFrequencyValues] = useState({});
    const [requestedTransport, setRequestedTransport] = useState([]);
    const [transportType, setTransportType] = useState(transportTypeConst.tempTransport);
    const [transportInfoFormStatus, setTransportInformationFormStatus] = useState(true);
    const [simpleFormResetter, setSimpleFormResetter] = useState(0);
    const [runDetails, setRunDetails] = useState();
    const serviceDateRef = useRef();
    const [infoStatus, setInfoStatus] = useState(initialAlertState);
    const [errorStatus, setErrorStatus] = useState(initialAlertState);
    const [isTodayServiceDate, setIsTodayServiceDate] = useState(false);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => handleFormReset(),
    });
    const [successModalObj, setSuccessModalObj] = useState({
        show: false,
        values: {},
    });

    const vaultOptions = useMemo(() => {
        if (!dropdownData.vaultIds) {
            return [];
        }
        const sortedVault = multipleSort(updatedDropdownData(dropdownData.vaultIds), requestSortCriteria.comboBoxSort);
        return sortedVault.map(({ code_value: vaultCode, id }) => {
            return { id: id, value: id, label: vaultCode };
        });
    }, [dropdownData.vaultIds]);

    const serviceLocationOptions = useMemo(() => {
        return serviceLocations.map(({ customer_id: customerId, customer_number: customerNumber }) => ({
            id: customerId.toString(),
            value: customerId.toString(),
            label: customerNumber.toString(),
        }));
    }, [serviceLocations]);

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        setFieldValue,
        setValues,
        handleSubmit,
        handleBlur,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            await handleFormSubmit(values);
        },
    });

    const checkIsFormValid = () => {
        let message = "";
        if (transportInfoFormStatus) {
            message = t("messages.unSavedSection", { section: t("titles.transportInformation") });
        } else if (requestedTransport.length === 0) {
            message = t("messages.newOrEmptyTransportFormCriteria");
        }

        if (message) {
            setErrorStatus({
                show: true,
                message,
            });
        } else {
            setErrorStatus({
                show: false,
                message,
            });
        }
        return !message;
    };

    const handleFormSubmit = async (formValues) => {
        const isFormValid = checkIsFormValid();

        if (isFormValid) {
            const requestDetails = generateTransportRequestDetails({
                transports: requestedTransport,
                transportType: transportType,
                ...formValues,
            });

            const addOnCutOff = calculateAddOnCutOffFlag(serviceFrequencyValues, values.serviceDate);

            const response = await createTransportRequestService({
                serviceDate: formatDate(values.serviceDate, dateFormats.displayDate),
                comment: values.comments,
                detailData: requestDetails,
                cslCustomerId: values.serviceLocationId,
                addonCutoffFlag: addOnCutOff,
                requestActionId: requestActionIds.temporaryTransportDetails,
            });

            if (response.success) {
                setSuccessModalObj({
                    show: true,
                    values: {
                        ...response,
                        serviceDate: formValues.serviceDate,
                    },
                });
            } else if (response.error === errorCodes.Code63639 || response.error === errorCodes.Code63752) {
                getServiceDates();
                const message = t(`apiErrorMessages.${response.error}`);
                setErrorStatus({
                    show: true,
                    message,
                });
            } else {
                getServiceDates();
                setErrorStatus({
                    show: true,
                    message: response.displayMessage,
                });
            }
        }
    };

    useEffect(() => {
        setValues({
            ...initialValues,
            serviceLocationId: selectedCustomer.customer_id,
            vaultId: vaultOptions[0]?.value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer.customer_id, vaultOptions]);

    useEffect(() => {
        if (errorStatus.show || infoStatus.show) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [errorStatus.show, infoStatus.show]);

    useEffect(() => {
        const fetchCustomerServiceFrequency = async () => {
            setIsScheduleServiceLoading(true);
            const serviceFreqValues = await getServiceFrequency();
            setServiceFrequencyValues(serviceFreqValues);
            setIsScheduleServiceLoading(false);
        };
        fetchCustomerServiceFrequency();
    }, []);

    useEffect(() => {
        if (serviceFrequencyValues.customerHasSchedule) {
            const fetchInitialData = async () => {
                setIsLoading(true);
                const [data, comboBoxData] = await Promise.all([
                    getActiveCustomersSiblingsService(),
                    getComboBoxDescriptionForNewOrEmptyContainer(),
                ]);
                setServiceLocations(data);
                setDropDownData(comboBoxData);
                setIsLoading(false);
            };
            fetchInitialData();
        }
    }, [serviceFrequencyValues.customerHasSchedule]);

    const handleFormReset = () => {
        resetForm({
            values: {
                ...initialValues,
                serviceLocationId: selectedCustomer.customer_id,
                vaultId: vaultOptions[0]?.value,
            },
        });
        setRequestedTransport([]);
        setSimpleFormResetter(simpleFormResetter + 1);
        getServiceDates();
        setErrorStatus(initialAlertState);
    };

    const getServiceDates = async () => {
        if (serviceDateRef.current) {
            serviceDateRef.current.refreshServiceDates();
        }
    };

    const onSuccessModalClose = () => {
        handleFormReset();
        setSuccessModalObj({ show: false, values: {} });
    };

    const onCancel = () => {
        navigate(dashboard);
    };

    const onClear = () => {
        setErrorStatus(initialAlertState);
    };

    const isScheduleDataLoaded = "customerHasSchedule" in serviceFrequencyValues;

    const serviceDateHandler = async (value) => {
        if (!isTodayServiceDate && dayjs(value).isSame(dayjs(), dateTimeUnits.day)) {
            setErrorStatus({
                show: true,
                message: t("messages.requestCantMakeToday"),
            });
            setFieldValue("serviceDate", "");
        } else {
            const formattedDate = formatDate(value);
            await setFieldValue("serviceDate", formattedDate);
            setErrorStatus(initialAlertState);
        }
    };

    if (!isScheduleDataLoaded) {
        return <NewOrEmptyTransportHeader isLoading={isLoading || isScheduleServiceLoading} />;
    } else if (!serviceFrequencyValues.customerHasSchedule) {
        return (
            <Box>
                <NewOrEmptyTransportHeader isLoading={isLoading || isScheduleServiceLoading} />
                <SecureSyncAlert severity="info" message={t("messages.noScheduleDefine")} />
            </Box>
        );
    }

    const printReport = () => {
        setPrintPayload(
            getNewOrEmptyTransportPayload({
                requestId: successModalObj.values.requestId,
                reportLabel:
                    transportType === "2"
                        ? t("reports.newOrEmptyTransport.reportTitlePermanentTemporary")
                        : t("reports.newOrEmptyTransport.reportTitlePermanent"),
            })
        );
        printModalOpen(true);
    };

    return (
        <>
            <Box>
                <NewOrEmptyTransportHeader
                    isLoading={isLoading || isSubmitting || isScheduleServiceLoading || isServiceDateLoading}
                />
                <Box>
                    {infoStatus.show && (
                        <SecureSyncAlert
                            severity="info"
                            message={infoStatus.message}
                            onClose={() => setInfoStatus(initialAlertState)}
                        />
                    )}
                </Box>
                <Box>
                    {errorStatus.show && (
                        <SecureSyncAlert
                            severity="error"
                            message={errorStatus.message}
                            onClose={() => setErrorStatus(initialAlertState)}
                        />
                    )}
                </Box>
                <NewOrEmptyTransportSuccessModal
                    values={successModalObj.values}
                    open={successModalObj.show}
                    onClose={onSuccessModalClose}
                    printReport={printReport}
                >
                    {successModalObj.message}
                </NewOrEmptyTransportSuccessModal>

                <Typography sx={styles.marginBottom20}>
                    <Trans
                        i18nKey={"newOrEmptyTransport.pageDescription"}
                        values={{ buttonLabel: t("buttonLabels.submit") }}
                    />
                </Typography>
                <Divider />
                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={styles.marginBottom20}>
                        <PageSubTitle>{t("titles.deliveryInformation")}</PageSubTitle>
                        <Box sx={styles.containerWrapper}>
                            <SecureSyncSelect
                                disabled
                                value={values.serviceLocationId}
                                error={touched.serviceLocationId && Boolean(errors.serviceLocationId)}
                                helperText={touched.serviceLocationId && errors.serviceLocationId}
                                label={t("formFields.serviceLocation")}
                                options={serviceLocationOptions}
                                name="serviceLocationId"
                                onChange={handleChange}
                            />
                            <ServiceDateCalendar
                                runDetails={runDetails}
                                scheduleEndDate={serviceFrequencyValues.scheduleEndDate}
                                setServiceDateLoading={setIsServiceDateLoading}
                                ref={serviceDateRef}
                                label={t("formFields.serviceDate")}
                                value={values.serviceDate}
                                error={touched.serviceDate && Boolean(errors.serviceDate)}
                                helperText={touched.serviceDate && errors.serviceDate}
                                onChange={async (value) => {
                                    await serviceDateHandler(value);
                                }}
                                onBlur={handleBlur}
                                onRunDetailsFetch={(data) => setRunDetails(data)}
                                getTodayServiceDate={(data) => setIsTodayServiceDate(data)}
                            />
                            <Box sx={styles.tooltipSection}>
                                <ServiceDateCalendarTooltip />
                            </Box>
                        </Box>
                        <TextField
                            id="new-container-comments"
                            name="comments"
                            label={t("common.comments")}
                            variant="outlined"
                            multiline
                            fullWidth
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            inputProps={{ maxLength: commentsMaxLength }}
                            rows={4}
                        />
                    </Box>
                    <Divider />
                    <ContainerInformation
                        key={`containerForm${simpleFormResetter}`}
                        insertTypes={dropdownData.insertTypes}
                        vaultIds={dropdownData.vaultIds}
                        transportTypes={dropdownData.containerTypes}
                        requestedTransports={requestedTransport}
                        setRequestedTransports={setRequestedTransport}
                        onFormChange={(status) => {
                            setTransportInformationFormStatus(status);
                        }}
                        setTransportType={setTransportType}
                        onClear={onClear}
                    />
                    <Box sx={styles.btnSection}>
                        <Button variant="outlined" onClick={onCancel}>
                            <Trans i18nKey={"buttonLabels.cancel"} />
                        </Button>
                        <Button
                            variant="outlined"
                            type="reset"
                            onClick={handleFormReset}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            <Trans i18nKey={"buttonLabels.reset"} />
                        </Button>
                        <Button variant="contained" type="submit" title={getKeyBoardLabel(shortCutKeys.submit)}>
                            <Trans i18nKey={"buttonLabels.submit"} />
                        </Button>
                    </Box>
                </form>
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.newOrEmptyTransport}
            />
        </>
    );
};

export default NewOrEmptyTransport;
