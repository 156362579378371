const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    innerContainer: {
        width: "100%",
        textAlign: "left",
    },
    title: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "1.235" /* 41.99px */,
        letterSpacing: "0.25px",
        paddingBottom: "20px",
        paddingTop: "20px",
        color: "im_dark_blue.500",
    },
    body1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5" /* 24px */,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        color: "im_grey.900",
    },
    sectionTitle: {
        color: "im_grey.900",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.334" /* 32.016px */,
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    formControl: {
        flex: 1,
    },
    formLabel: {
        required: true,
    },
    checkBox: {
        color: "primary",
        "&.Mui-checked": {
            color: "primary",
        },
    },
    accountInfo: {
        color: "#252526",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.334" /* 32.016px */,
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    flexContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    formControl1: {
        width: 310,
        paddingRight: 2,
        paddingBottom: "20px",
    },
    selectIcon: {
        paddingRight: "15px",
    },
    textField: {
        flex: 1,
    },
    dataGridContainer: {
        paddingBottom: "20px",
    },
    dataGrid: {
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "im_grey.100",
            color: "black",
        },
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    customerAccountTitle: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.5" /* 24px */,
        letterSpacing: "0.15px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    customerAccountText: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5" /* 24px */,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    buttonSubmit: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
};

export default styles;
