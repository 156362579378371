import dayjs from "dayjs";
import { getCurrentDateTime } from "./dateHelper";
import { transportTypeConst } from "src/constants/requestConstants";

const fieldDelimiter = String.fromCharCode(31);
const recordDelimiter = String.fromCharCode(30);

const generateInsertStr = (insertTypeIds) => {
    const strArr = insertTypeIds.map((insertTypeId) => {
        return `${insertTypeId},1`;
    });
    return strArr.join(";");
};

const generateCMIList = (cmiList) => {
    const validLabels = cmiList.filter((label) => label);
    return validLabels.join(String.fromCharCode(9));
};

const generateExtraInsertStr = (extraInserts) => {
    const strArr = extraInserts.map((extraInsert) => {
        return `${extraInsert.insertTypeId},${extraInsert.insertQuantity}`;
    });
    return strArr.join(";");
};

export const generateRequestDetails = ({
    containers,
    inserts,
    purchase,
    extraLocksQuantity,
    extraLockType,
    clipQuantity,
}) => {
    const requestStr = generateContainerDetails(containers);
    const extraInsertStr = generateExtraInserts(inserts, purchase);
    const extraLocks = generateExtraLocks({
        lockQuantity: extraLocksQuantity,
        lockTypeId: extraLockType,
    });
    const clips = generateClips(clipQuantity);
    return `${requestStr}${extraInsertStr}${extraLocks}${clips}`;
};

const generateContainerDetails = (containers) => {
    let requestStr = "";
    if (containers && containers.length > 0) {
        containers.forEach((container) => {
            const { vaultId, containerTypeId, containerQuantity, isLocks, lockTypeId, insertTypeIds, cmiList } =
                container;
            requestStr = requestStr
                .concat("1")
                .concat(fieldDelimiter)
                .concat(vaultId)
                .concat(fieldDelimiter)
                .concat(containerTypeId)
                .concat(fieldDelimiter)
                .concat(containerQuantity)
                .concat(fieldDelimiter)
                .concat(isLocks ? `${lockTypeId}` : "")
                .concat(fieldDelimiter)
                .concat("N")
                .concat(fieldDelimiter)
                .concat("N")
                .concat(fieldDelimiter)
                .concat(generateInsertStr(insertTypeIds))
                .concat(fieldDelimiter)
                .concat(generateCMIList(cmiList))
                .concat(recordDelimiter);
        });
    }

    return requestStr;
};

const generateExtraInserts = (extraInserts, purchaseChecked) => {
    let requestStr = "";
    if (extraInserts && extraInserts.length > 0) {
        requestStr = requestStr
            .concat("4")
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat("N")
            .concat(fieldDelimiter)
            .concat(purchaseChecked ? "Y" : "N")
            .concat(fieldDelimiter)
            .concat(generateExtraInsertStr(extraInserts))
            .concat(fieldDelimiter)
            .concat(recordDelimiter);
    }
    return requestStr;
};

const generateExtraLocks = ({ lockQuantity, lockTypeId }) => {
    let requestStr = "";
    if (lockQuantity) {
        requestStr = requestStr
            .concat("5")
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(lockQuantity)
            .concat(fieldDelimiter)
            .concat(lockTypeId)
            .concat(fieldDelimiter)
            .concat("N")
            .concat(fieldDelimiter)
            .concat("N")
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(recordDelimiter);
    }
    return requestStr;
};

const generateClips = (clipQuantity) => {
    let requestStr = "";
    if (clipQuantity) {
        requestStr = requestStr
            .concat("6")
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(clipQuantity)
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat("N")
            .concat(fieldDelimiter)
            .concat("N")
            .concat(fieldDelimiter)
            .concat(fieldDelimiter)
            .concat(recordDelimiter);
    }
    return requestStr;
};

export const generateTransportRequestDetails = ({ transports, transportType }) => {
    const transportStr = generateTransportDetails(transports, transportType);
    return transportStr;
};

export const generateTransportDetails = (transportList, transportType) => {
    let requestStr = "";
    const isPermanentTransport = transportType === transportTypeConst.permanentTransport;
    if (transportList && transportList.length > 0) {
        transportList.forEach((transport) => {
            const { vaultId, transportTypeId, transportQuantity, isLocks, insertTypeIds } = transport;
            requestStr = requestStr.concat(transportType).concat(fieldDelimiter);
            if (isPermanentTransport) {
                requestStr = requestStr.concat(vaultId);
            }
            requestStr = requestStr
                .concat(fieldDelimiter)
                .concat(transportTypeId)
                .concat(fieldDelimiter)
                .concat(transportQuantity)
                .concat(fieldDelimiter)
                .concat(isPermanentTransport && isLocks ? "TRANS" : "")
                .concat(fieldDelimiter)
                .concat("N")
                .concat(fieldDelimiter)
                .concat("N")
                .concat(fieldDelimiter)
                .concat(generateInsertStr(insertTypeIds))
                .concat(fieldDelimiter)
                .concat(recordDelimiter);
        });
    }
    return requestStr;
};

export const calculateAddOnCutOffFlag = (flags, serviceDate) => {
    let addOnCutOff = "N";
    const selectedServiceDate = serviceDate ? serviceDate : flags.maxServiceDate;
    if (
        (flags.noOfServices >= 1 &&
            flags.addOnCutoffFlag &&
            dayjs(getCurrentDateTime()).isSame(dayjs(selectedServiceDate), "day")) ||
        (flags.noOfServicesTom >= 1 &&
            flags.previousDayFlag &&
            dayjs().add(1, "day").isSame(dayjs(selectedServiceDate), "day"))
    ) {
        addOnCutOff = "Y";
    }
    return addOnCutOff;
};

export const groupByInsertTypeId = (insertTypeIds, insertTypeOptions) => {
    const groupedInsertTypeIds = insertTypeIds.reduce((prevState, insertTypeId) => {
        if (prevState[insertTypeId]) {
            prevState[insertTypeId] = prevState[insertTypeId] + 1;
        } else {
            prevState[insertTypeId] = 1;
        }
        return prevState;
    }, {});

    const groupedInsertTypeData = Object.keys(groupedInsertTypeIds).map((insertTypeId) => {
        const label = insertTypeOptions.find((insertTypeOption) => insertTypeOption.value === insertTypeId).label;

        return {
            count: groupedInsertTypeIds[insertTypeId],
            insertTypeId,
            label,
        };
    });

    return groupedInsertTypeData;
};
