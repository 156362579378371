const styles = {
    requiredToLoginTitle: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "123.5%", // 41.99px
        letterSpacing: "0.25px",
        color: "#14477D",
        my: "25px",
    },
    bodyText: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "150%", // 24px
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    body2Text: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        color: "#999999",
        paddingBottom: "20px",
        paddingLeft: "14px",
    },
    submitButton: {
        width: "82px",
        height: "36px",
        borderRadius: "8px",
        "&:hover": {
            transition: "none",
            transform: "none",
            borderRadius: "8px",
        },
    },
    sectionTitle: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "150%", // 24px
        letterSpacing: "0.15px",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    formControl: {
        width: 310,
        paddingRight: 2,
        paddingBottom: "20px",
    },
    formControlWide: {
        width: 640,
        paddingBottom: "20px",
    },
    accountCheckbox: {
        paddingBottom: "20px",
    },
    selectIcon: {
        paddingRight: "15px",
    },
    submitText: {
        fontWeight: 700,
    },
    flexBox: {
        display: "flex",
    },
    flexBoxGap: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
    },
    container: {
        paddingBottom: "20px",
    },
    dataGrid: {
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            color: "black",
        },
    },
    buttonContainer: {
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    noRowsOverlay: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    alert: {
        paddingTop: "20px",
    },
    messageSection: {
        fontSize: "18px",
        my: "24px",
    },
    titleSection: {
        fontSize: "48px",
        color: "im_grey.900",
        my: "26px",
    },
};

export default styles;
