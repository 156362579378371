import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ExtraLocksAndClips.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const ExtraLocksAndClips = ({ extraLocksAndClipsData }) => {
    const { t } = useTranslation();
    const noDataMsg = t("messages.noLockClip");
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "item", headerName: convertToLocaleUpperCase(t("tableHeader.item")) },
            { field: "quantity", headerName: convertToLocaleUpperCase(t("tableHeader.quantity")) },
            { field: "type", headerName: convertToLocaleUpperCase(t("tableHeader.type")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box>
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    <Typography variant="h6" sx={styles.heading}>
                        {t("requestDetails.extraLocksAndClips")}
                    </Typography>
                    {extraLocksAndClipsData.length > 0 && (
                        <Box>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: extraLocksAndClipsData.length })}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable rows={extraLocksAndClipsData} columns={columns} noDataMessage={noDataMsg} />
                </Box>
            </SecureSyncTableCard>
        </Box>
    );
};

export default ExtraLocksAndClips;
