import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import DeleteConfirmation from "../ConfirmationModals/DeleteConfirmation/DeleteConfirmation";
import styles from "./DRFileTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import RetrieveFile from "src/components/Icons/RetrieveFile/RetrieveFile";
import { getCurrentDateTimeInLocalizedFormat } from "src/utils/dateHelper";
import { insertLoggedEvent } from "src/services/processMediaService";
import { deleteDrInstructionDesignatedCustomer, getDRFileContent } from "src/services/disasterService";
import { deleteFileFromFB, downloadFileFromFB } from "src/services/utils";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { removeFileExtension } from "src/utils/drHelper";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const deleteConfirmationInitial = {
    show: false,
    id: "",
    fileName: "",
};
const DRFileTable = ({ resultData, editPermission, downloadPermission, selectedCustomerId, setResetter }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isLoading, setLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(deleteConfirmationInitial);

    const columns = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {editPermission && (
                            <SecureSyncIconTooltip
                                onClick={() => {
                                    setDeleteConfirmation({
                                        show: true,
                                        id: params.dr_file_id,
                                        fileName: params.name,
                                    });
                                }}
                                icon={<ClearIcon sx={styles.clearIcon} />}
                                title={t("common.deleteFile")}
                            />
                        )}
                        {downloadPermission && (
                            <SecureSyncIconTooltip
                                onClick={() => {
                                    fetchDownloadDRFile(params.dr_file_id);
                                }}
                                icon={<RetrieveFile fill={theme.palette.im_mid_blue["500"]} />}
                                title={t("common.retrieveFile")}
                            />
                        )}
                    </>
                );
            },
        },
        {
            field: "name",
            headerName: convertToLocaleUpperCase(t("tableHeader.fileName")),
            sortable: true,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.name} arrow>
                        <Box component={"span"} style={styles.renderCell}>
                            {params.name}
                        </Box>
                    </Tooltip>
                );
            },
        },
        {
            field: "last_mod_date",
            headerName: convertToLocaleUpperCase(t("tableHeader.uploadDateTime")),
            sortable: true,
            renderCell: (params) => {
                return <>{getCurrentDateTimeInLocalizedFormat(params.last_mod_date)}</>;
            },
        },
        {
            field: "size",
            headerName: convertToLocaleUpperCase(t("tableHeader.size")),
            sortable: true,
            renderCell: (params) => {
                return <>{`${params.size} KB`}</>;
            },
        },
    ];

    const fetchDeleteDRFile = async (id, drFileName) => {
        setDeleteConfirmation(deleteConfirmationInitial);
        setLoading(true);
        await deleteDrInstructionDesignatedCustomer({ fileId: id });
        const source = "DRFiles.DeleteDRFile";
        const description = `Disaster Recovery - Delete File File: ${drFileName}  Customer Id: ${selectedCustomerId}`;
        await insertLoggedEvent("", description, source);
        const fileName = removeFileExtension(drFileName);
        const uploadedFolderPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}/DR_FILE/UPLOAD/${id}/${fileName}.txt`;
        await deleteFileFromFB({
            fbFilePath: uploadedFolderPath,
            onComplete: () => {
                setLoading(false);
            },
        });
        setResetter((prev) => prev + 1);
    };

    const fetchDownloadDRFile = async (id) => {
        setLoading(true);
        const result = await getDRFileContent({ drFileId: id });
        const fileName = result.fileContent[0].name;
        downloadFileFromFB({
            fbFilePath: result.gcsPathDownload,
            fileName: fileName,
            onComplete: () => {
                deleteFileFromFB({
                    fbFilePath: result.gcsPathDownload,
                    onComplete: () => {
                        setLoading(false);
                    },
                });
            },
        });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    {resultData.length > 0 ? (
                        <>
                            <Box sx={styles.recordStart}>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: resultData.length })}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Box sx={styles.recordStart} />
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable rows={resultData} columns={columns} getRowId={(row) => row.id} />
                </Box>
            </SecureSyncTableCard>
            <DeleteConfirmation
                handleConfirm={() => fetchDeleteDRFile(deleteConfirmation.id, deleteConfirmation.fileName)}
                instructionName={deleteConfirmation.fileName}
                onClose={() => setDeleteConfirmation(deleteConfirmationInitial)}
                open={deleteConfirmation.show}
            />
        </>
    );
};

export default DRFileTable;
