import { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SecureSyncSelect from "../SecureSyncSelect/SecureSyncSelect";
import { getCountryList } from "src/redux/applicationSlice";
export default function CountriesList({ onChangeCallBack, value = "", id, label, name, onBlur, error, helperText }) {
    const { t } = useTranslation();
    const counties = useSelector(getCountryList);
    const countryOptions = useMemo(() => {
        const uniqueArray = counties.filter(
            (obj, index, self) => index === self.findIndex((t) => t.country_id === obj.country_id)
        );
        const tempCountryList = uniqueArray.map((item) => {
            const countryCodeName = item.name.replace(/\s+/g, "").replace(/^./, (char) => char.toLowerCase());
            return {
                id: item.country_id,
                label: `${t(`countryList.${countryCodeName}`)}-${item.phone_country_code}`,
                value: item.locale_id,
            };
        });
        tempCountryList.sort((a, b) => {
            const countryNameA = a.label;
            const countryNameB = b.label;

            if (countryNameA < countryNameB) {
                return -1;
            }
            if (countryNameA > countryNameB) {
                return 1;
            }
            return 0;
        });
        return tempCountryList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counties, t]);

    return (
        <SecureSyncSelect
            id={id}
            label={label}
            defaultOption={countryOptions.length > 0 ? countryOptions[0].value : ""}
            options={countryOptions}
            value={countryOptions.length > 0 ? value : ""}
            name={name}
            onChange={(e) => {
                onChangeCallBack(e.target.value);
            }}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            required={true}
        />
    );
}
CountriesList.propTypes = {
    onChangeCallBack: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    helperText: PropTypes.string,
};
