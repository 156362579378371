import { Box, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./CloseModalConfirmation.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const CloseModalConfirmation = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title={t("containerPickup.addContainerContents")}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {t("buttonLabels.ok")}
                    </Button>
                    <Button autoFocus variant="outlined" onClick={onClose}>
                        {t("buttonLabels.cancel")}
                    </Button>
                </Box>
            }
        >
            <Trans i18nKey={"messages.closeAlert"} />
        </SecureSyncModal>
    );
};

export default CloseModalConfirmation;
