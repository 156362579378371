/* eslint-disable max-lines */
import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Box, Collapse, Container, Divider, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./AddUser.styles";
import AddUserForm from "src/components/Administration/Authorization/AddUser/AddUserForm/AddUserForm";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { useLoadCountries } from "src/customHooks";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";

//extension businessPhone businessFax customerEmployeeNo
const AddUsers = () => {
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgParams, setMsgParams] = useState({
        error: false,
        message: "",
    });
    const [sucessPopup, setSuccessPopup] = useState({
        sucMessage: "",
        dRCancelledMessage: "",
        interactionCancelledMessage: "",
        cancelledMessage: "",
        extraWarningMsg: "",
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const { t } = useTranslation();
    const { isLoading: isCountriesLoading } = useLoadCountries();

    const cleanMsg = () => {
        setSuccessPopup({
            sucMessage: "",
            dRCancelledMessage: "",
            interactionCancelledMessage: "",
            cancelledMessage: "",
            extraWarningMsg: "",
        });
        setMsgOpen(false);
        setMsgParams({
            error: false,
            message: "",
        });
    };

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const openSuccessMsgProd = (params) => {
        if (params.error) {
            setMsgParams(params);
        } else if (params.mailHasError) {
            setMsgOpen(true);
            setMsgParams({
                error: true,
                message: t("messages.systemErrorMessage"),
            });
        } else {
            handleOpenSnackbar();
            setSuccessPopup({
                sucMessage: params.sucMessage,
                dRCancelledMessage: params.dRCancelledMessage,
                interactionCancelledMessage: params.interactionCancelledMessage,
                cancelledMessage: params.cancelledMessage,
                extraWarningMsg: params.extraWarningMsg,
            });
            if (
                params.dRCancelledMessage ||
                params.interactionCancelledMessage ||
                params.cancelledMessage ||
                params.extraWarningMsg
            ) {
                setMsgOpen(true);
            }
        }
    };

    return (
        <Container disableGutters>
            <CircularLoaderFullPage loading={isCountriesLoading} />
            <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" width="100%">
                <Box width="100%" textAlign="left">
                    <PageTitle> {t("authorization.addUser")}</PageTitle>

                    <Typography variant="body1" sx={styles.typographyBody}>
                        <Trans i18nKey={"authorization.headerMsg"} />
                    </Typography>
                    <SecureSyncSnackBar
                        openSnackBar={snackbarOpen}
                        message={sucessPopup.sucMessage}
                        severity={msgParams?.error ? "error" : "success"}
                        onClose={handleCloseSnackbar}
                    />
                    <Collapse in={msgOpen}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setMsgOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={msgParams?.error ? "error" : "info"}
                            sx={styles.successAlert}
                        >
                            {msgParams?.error ? (
                                <>{msgParams.message && <AlertTitle> {msgParams.message}</AlertTitle>}</>
                            ) : (
                                <>
                                    {sucessPopup.interactionCancelledMessage && (
                                        <AlertTitle> {sucessPopup.interactionCancelledMessage}</AlertTitle>
                                    )}
                                    {sucessPopup.dRCancelledMessage && (
                                        <AlertTitle> {sucessPopup.dRCancelledMessage}</AlertTitle>
                                    )}
                                    {sucessPopup.cancelledMessage && (
                                        <AlertTitle> {sucessPopup.cancelledMessage}</AlertTitle>
                                    )}
                                    {sucessPopup.extraWarningMsg && (
                                        <AlertTitle> {sucessPopup.extraWarningMsg}</AlertTitle>
                                    )}
                                </>
                            )}
                        </Alert>
                    </Collapse>
                    <Divider />
                </Box>
            </Box>
            <AddUserForm openSuccessMsg={openSuccessMsgProd} cleanMsg={cleanMsg} />
        </Container>
    );
};

export default AddUsers;
