const styles = {
    contactFieldContainer: {
        width: "70%",
    },
    filterDesc: {
        fontWeight: 500,
        margin: "20px 0",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "20px",
        paddingBottom: "32px",
    },
    tableContainer: {
        marginBottom: "20px",
    },
    tooltip: {
        marginTop: "10px",
    },
    noteDescription: {
        fontSize: "12px",
        color: "im_grey.400",
        marginBottom: "20px",
    },
};

export default styles;
