import { Alert, Box, useTheme } from "@mui/material";
import styles from "./SecureSyncAlert.styles";
import ErrorOutlined from "src/components/Icons/ErrorOutlined/ErrorOutlined";
import WarningOutlined from "src/components/Icons/WarningOutlined/WarningOutlined";
import InfoCircle from "src/components/Icons/InfoCircle/InfoCircle";

const SecureSyncAlert = ({ severity = "info", variant = "", message, sx = {}, onClose, alertRef }) => {
    const { palette } = useTheme();
    let icon;
    if (severity === "error") {
        icon = <ErrorOutlined style={styles.iconSize} fill={palette.error.main} />;
    } else if (severity === "warning") {
        icon = <WarningOutlined {...styles.iconSize} fill={palette.warning.main} />;
    } else if (severity === "info") {
        icon = <InfoCircle {...styles.iconSize} fill={palette.info.main} />;
    }
    return (
        <Alert
            sx={{ ...styles.alert, ...sx }}
            icon={icon}
            severity={severity}
            variant={variant}
            onClose={onClose}
            ref={alertRef}
        >
            <Box sx={styles.alertMessage}>{message}</Box>
        </Alert>
    );
};

export default SecureSyncAlert;
