const styles = {
    drDocumentsBody1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "14px",
        textAlign: "justify",
        textJustify: "inter-word",
    },
    drDocumentsBody2: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "14px",
    },
    drDocumentsInstructions: {
        fontSize: "12px",
        color: "im_grey.900",
        marginBottom: "10px",
        fontWeight: 500,
    },
    drDocumentsInstructions2: {
        fontSize: "12px",
        color: "im_red.500",
    },
    drDocumentsInstructionsLabel: {
        paddingTop: "5px",
        paddingBottom: "20px",
    },
    defaultMarginBottom: {
        marginBottom: "20px",
    },

    contactFieldContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "20px",
    },
    buttonContainer: {
        mt: "28px",
    },
    buttonContainer20: {
        mt: "20px",
    },
    highlightTxt: {
        color: "im_red.500",
    },
    footerHelper: {
        marginBottom: "20px",
        marginTop: 0,
    },
    fontBold: {
        fontWeight: 500,
    },
};

export default styles;
