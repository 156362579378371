import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircleArrowRight from "../../assets/images/circle_right_arrow.svg";
import styles from "./Resources.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { getFBDownloadUrl } from "src/utils/generateFilePathForCurrentLocale";
import { getPrimaryLinks } from "src/utils/resourcesHelper";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { staticFile } from "src/constants/fileConstants";
import { OpenNewTabServiceLink } from "src/components/Dashboard/ServiceLink/ServiceLink";
import { suggestionPath } from "src/constants/routeConstants/rootRouterFullPaths";
import { useGetLanguagesByEurope } from "src/customHooks";
import { getAccessRights } from "src/redux/userDetailsSlice";

const DownloadAndOpenLink = ({ fileName, children, language }) => {
    const [isLoading, setIsLoading] = useState(false);
    const openFBLink = async () => {
        setIsLoading(true);
        const docUrl = await getFBDownloadUrl(fileName, language);
        window.open(docUrl, "_blank");
        setIsLoading(false);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.link}>
                <img loading="lazy" src={CircleArrowRight} alt="Link Icon" style={styles.linkIcon} />
                <Typography onClick={openFBLink} sx={styles.textStyle}>
                    {children}
                </Typography>
            </Box>
        </>
    );
};

const Resources = () => {
    const { data: accessRights } = useSelector(getAccessRights);
    const primaryLinks = getPrimaryLinks();
    const { europeLanguages, nonEuropeLanguages } = useGetLanguagesByEurope();
    const { t } = useTranslation();
    return (
        <Box>
            <PageTitle>{t("headerMenu.resources")}</PageTitle>
            <Box>
                <PageSubTitle sx={styles.subtitle}>{t("resources.availableResources")}</PageSubTitle>
                <Box sx={styles.listWrapper}>
                    {primaryLinks.map(({ name, fbFileName }, index) => {
                        return (
                            <DownloadAndOpenLink
                                key={"primaryLinks" + index}
                                fileName={fbFileName}
                                sx={styles.serviceLink}
                            >
                                {name}
                            </DownloadAndOpenLink>
                        );
                    })}
                </Box>
            </Box>
            <Box>
                <PageSubTitle sx={styles.subtitle}>{t("resources.otherResources")}</PageSubTitle>
                <Box sx={styles.otherResourceItemWrapper}>
                    <Box sx={styles.otherResourceItem}>
                        <Box sx={styles.label}>
                            {t("resources.dataProtectionHandbook", { region: t("resources.europeRegion") })}
                        </Box>
                        <Box sx={styles.listWrapper}>
                            {europeLanguages.map(
                                ({ language_display_name: name, iso_locale_code: language }, index) => {
                                    return (
                                        <DownloadAndOpenLink
                                            key={"dph-europe" + index}
                                            fileName={staticFile.dataProtectionCustomerHandbook}
                                            language={language}
                                            sx={styles.serviceLink}
                                        >
                                            {name}
                                        </DownloadAndOpenLink>
                                    );
                                }
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.otherResourceItem}>
                        <Box sx={styles.label}>
                            {t("resources.dataProtectionHandbook", { region: t("resources.nonEuropeRegion") })}
                        </Box>
                        <Box sx={styles.listWrapper}>
                            {nonEuropeLanguages.map(
                                ({ language_display_name: name, iso_locale_code: language }, index) => {
                                    return (
                                        <DownloadAndOpenLink
                                            key={"dph-non-europe" + index}
                                            fileName={staticFile.dataProtectionCustomerHandbook}
                                            language={language}
                                            sx={styles.serviceLink}
                                        >
                                            {name}
                                        </DownloadAndOpenLink>
                                    );
                                }
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.otherResourceItem}>
                        <Box sx={styles.label}>
                            {t("resources.dataSheetHandbook", { region: t("resources.europeRegion") })}
                        </Box>
                        <Box sx={styles.listWrapper}>
                            {europeLanguages.map(
                                ({ language_display_name: name, iso_locale_code: language }, index) => {
                                    return (
                                        <DownloadAndOpenLink
                                            key={"dph-europe" + index}
                                            fileName={staticFile.dataSheet}
                                            language={language}
                                            sx={styles.serviceLink}
                                        >
                                            {name}
                                        </DownloadAndOpenLink>
                                    );
                                }
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.otherResourceItem}>
                        <Box sx={styles.label}>
                            {t("resources.dataSheetHandbook", { region: t("resources.nonEuropeRegion") })}
                        </Box>
                        <Box sx={styles.listWrapper}>
                            {nonEuropeLanguages.map(
                                ({ language_display_name: name, iso_locale_code: language }, index) => {
                                    return (
                                        <DownloadAndOpenLink
                                            key={"dph-non-europe" + index}
                                            fileName={staticFile.dataSheet}
                                            language={language}
                                            sx={styles.serviceLink}
                                        >
                                            {name}
                                        </DownloadAndOpenLink>
                                    );
                                }
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.otherResourceItem}>
                        <Box sx={styles.label}>{t("resources.secureSyncInfo")}</Box>
                        <Box sx={styles.listWrapper}>
                            <OpenNewTabServiceLink
                                href={"https://www.ironmountain.com/support/how-it-works/data-protection/secure-sync"}
                            >
                                {t("resources.secureSyncInfo")}
                            </OpenNewTabServiceLink>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {accessRights.page.suggestions && (
                <Link to={suggestionPath}>
                    <Typography sx={styles.link2}>{t("resources.makeSuggestion")}</Typography>
                </Link>
            )}
        </Box>
    );
};

export default Resources;
