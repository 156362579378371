import { useCallback, useEffect, useState } from "react";
import { MenuItem, Checkbox, ListItemText, Select, FormControl, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./SecureSyncMultiSelect.styles";
import { getAllValue } from "src/constants/researchConstants";

const SecureSyncMultiSelect = ({
    options = [],
    required,
    type = "outlined",
    value: initialValue = [],
    onChange: handleParentChange,
    id,
    label,
    name,
    width,
    defaultSelectAll = false,
    error,
    sx = null,
}) => {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState(defaultSelectAll ? options : initialValue);
    const [isAllSelected, setIsAllSelected] = useState(defaultSelectAll);

    useEffect(() => {
        if (initialValue) {
            setSelectedOptions([...initialValue]);
            handleParentChangeWrapper([...initialValue]);
            setIsAllSelected(initialValue.length === options.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const handleParentChangeWrapper = useCallback(
        (value) => {
            handleParentChange({ target: { value } });
        },
        [handleParentChange]
    );

    const handleSelectChange = useCallback(
        (event) => {
            const { value } = event.target;
            if (value.includes(getAllValue)) {
                setSelectedOptions((prevOptions) => {
                    if (prevOptions.length === options.length) {
                        return [];
                    } else {
                        return [...options];
                    }
                });
                handleParentChangeWrapper(value.includes(getAllValue) ? [] : [...options]);
            } else {
                setSelectedOptions(value);
                handleParentChangeWrapper(value);
            }
        },
        [options, handleParentChangeWrapper]
    );

    const renderValue = (selected) => {
        if (selected.length === options.length) {
            setIsAllSelected(true);
            return t("common.all");
        } else {
            setIsAllSelected(false);
            return selected.join(", ");
        }
    };

    return (
        <FormControl required={required} variant={type} sx={styles.formControlWidth(width)} error={error}>
            {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
            <Select
                multiple
                value={selectedOptions}
                onChange={handleSelectChange}
                renderValue={renderValue}
                labelId={`${id}-label`}
                id={id}
                name={name}
                label={label}
                sx={sx ? sx : styles.selectField}
            >
                <MenuItem key="all" value={getAllValue}>
                    <Checkbox checked={isAllSelected} />
                    <ListItemText primary={t("common.all")} />
                </MenuItem>
                {options.map((option, i) => (
                    <MenuItem key={`${i}-${option}`} value={option}>
                        <Checkbox checked={selectedOptions.includes(option)} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SecureSyncMultiSelect;
