const styles = {
    description: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    rowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        paddingBottom: "20px",
    },
    titleContainer: {
        paddingBottom: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingTop: "32px",
        paddingBottom: "20px",
    },
};

export default styles;
