import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContainerDetails from "./ContainerDetails/ContainerDetails";
import OpenMediaDetails from "./OpenMediaDetails/OpenMediaDetails";
import styles from "./RequestDeliverMedia.styles";

const RequestDeliverMedia = ({ openMediaList, requestContainerDetails }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.container}>
                <Typography variant="h5" sx={styles.heading}>
                    {t("requestDetails.requestDeliverMedia")}
                </Typography>
                <OpenMediaDetails openMediaList={openMediaList} />
                <ContainerDetails requestContainerDetails={requestContainerDetails} />
            </Box>
            <Divider />
        </>
    );
};
export default RequestDeliverMedia;
