import CryptoJS from "crypto-js";
import { getDocs, query, where } from "firebase/firestore";
import { t } from "i18next";
import {
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    dropdown_list_collection,
    get_country_collection,
    get_local_collection,
    locale_collection,
    message_by_code_collection,
} from "./firebase";
import {
    constructFbHeaders,
    createArrayObj,
    encryptWith3DESCBC,
    getCustomers,
    getEmail,
    getLanguageLocaleId,
    getPersonalId,
    getSelectedCustomerDb,
    getTimeStamp,
    getUserName,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import {
    BILLTRUST_LINK,
    BILL_TRUST_BT_DATA,
    BILL_TRUST_CLIENT_ID,
    BILL_TRUST_E_TYPE,
    BILL_TRUST_KEY,
    IM_COLOCATION_DATA_SERVICE_LINK,
    IM_DATA_STORAGE_ON_ARCHIVAL_TAPSE_LINK,
    IM_DISPOSE_OF_RETIRED_ASSETS_LINK,
    IM_INSIDERS_LINK,
    IM_MEDIA_DESTRUCTION_LINK,
    IM_RESTORATION_AND_MIGRATION_LINK,
    SITAD_LINK,
    country_active_flag,
    defaultDistrictId,
    dropdownListType,
    equals,
    messageId,
    noFlag,
    supportedLangFlag,
    systemId,
    yesFlag,
} from "src/constants/serviceConstants";

const apiUrls = {
    billTrustTopIsAccountDetails: "/home/billtrustopisaccountdetails",
    retrieveGlobalAttribute: "/home/retreiveglobalattribute",
    returnSettingValues: "/home/returnsettingvalues",
    dropdownListInformationForSystemId: "/sscommon/dropdownlistinformationforsystemid",
    validAndActiveAuthorizationCodes: "/sscommon/validandactiveauthorizationcodes",
    destructRequestTypesDestructRequestStatus: "/sscommon/destructrequesttypesdestructrequeststatus",
};

/**
 * Method returns list of security challenge question that needs to be populated
 * @returns list of objects
 */
export const getSecurityQuestionsDropdown = async () => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-SecurityQuestions");
    traceStart();
    const q = query(dropdown_list_collection, where(dropdownListType, equals, "4"));

    const snapshot = await getDocs(q);
    const questions = [];
    snapshot.forEach((doc) => {
        questions.push(doc.data());
    });
    const sortedQuestions = Array.isArray(questions)
        ? [...questions].sort((question1, question2) => question1.display_order - question2.display_order)
        : [];
    const translatedChallengeQuestions = t("challengeQuestion", { returnObjects: true });
    const updatedQuestions = sortedQuestions
        .filter((question) => question.active_flag !== noFlag)
        .map((question) => {
            const translatedChallengeQuestion = translatedChallengeQuestions[question.dropdown_list_id.toString()];
            if (translatedChallengeQuestion) {
                return {
                    ...question,
                    descr: translatedChallengeQuestion,
                };
            }
            return question;
        });

    traceStop();
    return updatedQuestions;
};

export const getCountryListService = async () => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-CountryList");
    traceStart();
    const countryQuery = query(get_country_collection, where(country_active_flag, equals, yesFlag));
    const localQuery = query(get_local_collection, where(country_active_flag, equals, yesFlag));

    const [countrySnapShot, localSnapShot] = await Promise.all([getDocs(countryQuery), getDocs(localQuery)]);

    const countries = [];
    countrySnapShot.forEach((doc) => {
        countries.push(doc.data());
    });

    const locals = [];
    localSnapShot.forEach((doc) => {
        const data = doc.data();
        const country = countries.find((e) => e.country_id === data.country_id);
        if (country) {
            const localObj = {
                ...data,
                ...country,
            };
            locals.push(localObj);
        }
    });
    traceStop();
    return locals;
};

export const getLanguageDetails = async () => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-LanguageDetails");
    traceStart();
    const q = query(locale_collection, where(supportedLangFlag, equals, yesFlag));

    const snapshot = await getDocs(q);
    const language = [];
    snapshot.forEach((doc) => {
        language.push(doc.data());
    });
    traceStop();
    return language;
};

export const getMessageDetails = async (msgId) => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-MessageDetails");
    traceStart();
    const q = query(message_by_code_collection, where(messageId, equals, msgId));

    const snapshot = await getDocs(q);
    const msg = [];
    snapshot.forEach((doc) => {
        msg.push(doc.data());
    });
    traceStop();
    return msg;
};

const getAccountNumber = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.billTrustTopIsAccountDetails);
    traceStart();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData.selectedCustomer;
    const personId = getPersonalId();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/billtrustopisaccountdetails`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        personnel_id: personId,
        customer_id: selectedCustomer,
    };
    const headers = constructFbHeaders();
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            resultSet0.push(data);
        });
        if (resultSet0.find((e) => e.error === "0")) {
            return;
        }
        const accountNumber = resultSet0[0]?.account_number ? resultSet0[0].account_number : null;
        return accountNumber;
    } catch (error) {
        console.log(`error while getting account number => ${error}`);
        return;
    } finally {
        traceStop();
    }
};

const getLinksFromGlobalAttribute = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.retrieveGlobalAttribute);
    traceStart();
    const headers = constructFbHeaders();
    const urlGlobalSetting = `${process.env.REACT_APP_CF_URL_HOME}/home/retreiveglobalattribute`;
    const bodyGlobalSetting = {
        main_district_id: `${defaultDistrictId}`,
        system_id: systemId.defaultSystemId,
    };
    const resGlobal = await invokeApi("POST", urlGlobalSetting, bodyGlobalSetting, {}, headers);
    const resultSetGlobalSnapShot = await getDocs(apiResultSet0Collection(resGlobal.docId));
    const linkList = {
        ImMediaDestructionLink: null,
        ImRestorationAndMigrationLink: null,
    };
    resultSetGlobalSnapShot.forEach((e) => {
        const data = e.data();
        switch (data.global_attribute_type_id) {
            case IM_MEDIA_DESTRUCTION_LINK:
                linkList["ImMediaDestructionLink"] = data.global_attribute_value;
                break;
            case IM_RESTORATION_AND_MIGRATION_LINK:
                linkList["ImRestorationAndMigrationLink"] = data.global_attribute_value;
                break;
            default:
                break;
        }
    });
    traceStop();
    return linkList;
};

const getLinksFromSettings = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnSettingValues);
    traceStart();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/returnsettingvalues`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        system_id: systemId.defaultSystemId,
    };

    const headers = constructFbHeaders();
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const linkList = {
            BilltrustLink: null,
            SitadLink: null,
            ImInsidersLink: null,
            ImColocationDataServiceLink: null,
            ImDisposeOfRetiredAssetsLink: null,
            ImDataStorageOnArchivalTapseLink: null,
        };
        const settings = [];

        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            settings.push(data);
            switch (data.setting_type_id) {
                case BILLTRUST_LINK:
                    linkList["BilltrustLink"] = data.setting_value;
                    break;
                case SITAD_LINK:
                    linkList["SitadLink"] = data.setting_value;
                    break;
                case IM_INSIDERS_LINK:
                    linkList["ImInsidersLink"] = data.setting_value;
                    break;
                case IM_COLOCATION_DATA_SERVICE_LINK:
                    linkList["ImColocationDataServiceLink"] = data.setting_value;
                    break;
                case IM_DISPOSE_OF_RETIRED_ASSETS_LINK:
                    linkList["ImDisposeOfRetiredAssetsLink"] = data.setting_value;
                    break;
                case IM_DATA_STORAGE_ON_ARCHIVAL_TAPSE_LINK:
                    linkList["ImDataStorageOnArchivalTapseLink"] = data.setting_value;
                    break;
                default:
                    break;
            }
        });
        traceStop();
        if (linkList["BilltrustLink"]) {
            const bUrl = await createBillTrusterUrl(settings, linkList["BilltrustLink"]);
            linkList["BilltrustLink"] = bUrl;
        }
        return linkList;
    } catch (error) {
        traceStop();
        console.log(`error while getting account number => ${error}`);
        return;
    }
};

export const getSettingLink = async () => {
    try {
        const [list1, list2] = await Promise.all([getLinksFromSettings(), getLinksFromGlobalAttribute()]);
        return { ...list1, ...list2 };
    } catch (error) {
        console.log(`error while getting account number => ${error}`);
        return;
    }
};

const createBillTrusterUrl = async (settings, bTrustLink) => {
    // get bill trust data
    const bilTrustBdataObj = settings.find((e) => e.setting_type_descr === BILL_TRUST_BT_DATA);
    const bilTrustBdata = bilTrustBdataObj?.setting_value ? bilTrustBdataObj.setting_value : "DD=Y;MP=Y;";

    const accountNumber = await getAccountNumber();
    const email = getEmail();
    const userName = getUserName();
    const timeStamp = getTimeStamp();
    let billTrustedData;
    if (bilTrustBdata) {
        billTrustedData = `${bilTrustBdata}AN=${accountNumber.trim()};EA=${email};UN=${userName};TS=${timeStamp}`;
    }

    // get bill trust cliend it
    const billTrustedClientIdObj = settings.find((e) => e.setting_type_descr === BILL_TRUST_CLIENT_ID);
    const billTrustedClientId = billTrustedClientIdObj?.setting_value
        ? billTrustedClientIdObj.setting_value.trim()
        : null;

    // get bill trust entry type
    const billTrustedETypeObj = settings.find((e) => e.setting_type_descr === BILL_TRUST_E_TYPE);
    const billTrustedEType = billTrustedETypeObj?.setting_value ? billTrustedETypeObj.setting_value : null;

    // get bill trust key
    const billTrustedKeyObj = settings.find((e) => e.setting_type_descr === BILL_TRUST_KEY);
    const billTrustedKey = billTrustedKeyObj?.setting_value ? billTrustedKeyObj.setting_value : null;
    const sBTIV = billTrustedKey ? billTrustedKey.substring(0, 8) : null;
    let btLink;
    if (billTrustedEType === "0" && billTrustedData) {
        btLink = `${bTrustLink}BTDATA="${billTrustedData}"&CG="${billTrustedClientId}"&ETYPE=${billTrustedEType}`;
    } else if (sBTIV && billTrustedEType === "1" && billTrustedData) {
        const encodeValue = encryptWith3DESCBC(
            billTrustedData,
            CryptoJS.enc.Utf8.parse(billTrustedKey.toString()),
            CryptoJS.enc.Utf8.parse(sBTIV.toString())
        );
        btLink = `${bTrustLink}BTDATA="${encodeValue}"&CG="${billTrustedClientId}"&ETYPE=${billTrustedEType}`;
    }
    btLink = encodeURI(btLink);
    return btLink;
};

export const getDropdownList = async (dropdownListTypeId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.dropdownListInformationForSystemId);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/dropdownlistinformationforsystemid";
    const body = {
        main_district_id: "50",
        dropdown_list_type_id: dropdownListTypeId,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const programTypes = {
            failure: false,
            rs0: rs0Data,
        };
        return programTypes;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getReportFormatList = async () => {
    const { traceStart, traceStop } = traceWrapper("Fn:DirectFB-ReportFormat");
    traceStart();
    const q = query(dropdown_list_collection, where(dropdownListType, equals, "5"));
    const snapshot = await getDocs(q);
    const holidayLists = [];
    snapshot.forEach((doc) => {
        holidayLists.push(doc.data());
    });
    traceStop();
    return holidayLists;
};

/**
 * get valid and active auth codes
 * @returns
 */
export const getValidAndActiveAuthCodesService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validAndActiveAuthorizationCodes);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/validandactiveauthorizationcodes";
    try {
        const request = {
            main_district_id: "50",
        };
        const res = await invokeApi("POST", loginUrl, request, {}, headers);
        const resDoc = res?.docId;

        const [resultSnapShot, errorSnapShot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        const result = await createArrayObj(resultSnapShot);
        const error = await createArrayObj(errorSnapShot);

        return {
            result,
            error,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * getDestructionRequestTypesAndStatus
 * @returns
 */
export const getDestructionRequestTypesAndStatus = async (isSingleResultSet = "0") => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.destructRequestTypesDestructRequestStatus);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_COMMON + "/sscommon/destructrequesttypesdestructrequeststatus";
    try {
        const request = {
            main_district_id: getSelectedCustomerDb(),
            language_locale_id: getLanguageLocaleId(),
            system_id: "50",
        };
        request.single_resultset = isSingleResultSet;
        const res = await invokeApi("POST", loginUrl, request, {}, headers);
        const resDoc = res?.docId;

        const [destructionTypeSnapShot, destructionStatusSnapShot, errorSnapShot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);
        const destructionTypes = await createArrayObj(destructionTypeSnapShot);
        const destructionStatus = await createArrayObj(destructionStatusSnapShot);

        const error = await createArrayObj(errorSnapShot);

        return {
            destructionTypes,
            destructionStatus,
            error,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
