import { useSelector } from "react-redux";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { getAuthStatus } from "src/redux/authSlice";

const useAuthState = () => {
    const authState = useSelector(getAuthStatus);
    return {
        isLoaded: authState.status === fetchStatus.completed,
        ...authState.data,
    };
};

export default useAuthState;
