const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        p: 3,
        width: "100%",
        textAlign: "left",
    },
    textFieldContainer: {
        display: "flex",
        gap: 2,
        my: 3,
    },
    footNotes: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
        opacity: 0.5,
    },
    formControlLabel: {
        paddingBottom: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        my: 1,
    },
    boldText: {
        fontWeight: 700,
    },
    multilineTextFields: {
        mt: 3,
        width: "50%",
    },
    uploadBtn: {
        mt: 1,
        mb: 1,
    },
    fullHeight: {
        minHeight: `calc(100vh - 310px)`,
    },
    errorText: {
        fontWeight: 400,
        fontSize: "12px",
        color: "im_red.500",
    },
    marginBottomBreak: {
        mb: "16px",
    },
    defaultCursor: {
        "& .MuiInputBase-root": {
            cursor: "default",
        },
        "& .MuiInputBase-input": {
            cursor: "default",
            caretColor: "transparent",
        },
    },
};

export default styles;
