const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    innerContainer: {
        width: "100%",
        textAlign: "left",
    },
    userDetailTitle: {
        fontSize: "34px",
        fontWeight: 400,
        lineHeight: "1.235", // 41.99px
        letterSpacing: "0.25px",
        paddingTop: "20px",
    },
    userDetailBody1: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5", // 24px
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    dividerSpacing: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    nameFieldContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
    },
    contactFieldContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    formControl: {
        width: 310,
        paddingRight: 2,
        paddingBottom: "20px",
    },
    flexWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    singleColumnContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "20px",
    },
    switchLabelContainer: {},
    switchDescription: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.4px",
    },
    securityGridContainer: {
        paddingBottom: "20px",
    },
    securityGridItem: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "12px",
        letterSpacing: "0.15px",
    },
    challengeQuestionContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        paddingBottom: "20px",
    },
    dataGridContainer: {
        paddingBottom: "20px",
    },
    dataGrid: {
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "im_grey.100",
            color: "black",
        },
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    formHeader: {
        color: "#252526",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "133.4%",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    buttonSubmit: {
        paddingLeft: "12px",
        paddingRight: "12px",
    },
};

export default styles;
