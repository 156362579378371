import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, SvgIcon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./PMDRequestSection.styles";
import { ReactComponent as Edit } from "src/assets/images/RemoveRedEyeFilled.svg";
import { getPMDRListService } from "src/services/homeService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { searchMediaDestructionRequest } from "src/constants/routeConstants/requestRouterFullPaths";
import { getAllowedCustomers, updateSelectedCustomer, getSelectedCustomer } from "src/redux/userDetailsSlice";
import { destructRequestStatusIds } from "src/constants/requestConstants";

const PMDRequestSection = (props) => {
    const [pMDRount, setPMDRCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [rows, setRow] = useState([]);
    const navigate = useNavigate();
    const allowedCustomers = useSelector(getAllowedCustomers);
    const selectedCustomer = useSelector(getSelectedCustomer);

    useEffect(() => {
        setPMDRCount(props?.count ? props.count : 0);
    }, [props?.count]);

    const getPmdrList = async (panel, isExpanded) => {
        setLoading(true);
        try {
            if (isExpanded && pMDRount !== 0) {
                const pmdrList = await getPMDRListService();
                setRow(pmdrList);
            } else {
                setRow([]);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            //TODO ? handle error
        }
    };

    const navigateToMediaDistruction = (e) => {
        if (selectedCustomer.customer_id !== e.customerId) {
            dispatch(
                updateSelectedCustomer({
                    selectedCustomer: allowedCustomers?.find((customer) => customer.customer_id === e.customerId),
                })
            );
        }
        const values = {
            destructRequestTypeId: "all",
            destructionRequestID: "",
            destructRequestStatusId: destructRequestStatusIds.pendingConfirmation,
            serviceDateFrom: "",
            serviceDateTo: "",
        };
        navigate(searchMediaDestructionRequest, {
            state: values,
            replace: false,
        });
    };

    const columns = [
        {
            field: "edit",
            headerName: "",
            width: 60,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton onClick={() => navigateToMediaDistruction(params)}>
                        <SvgIcon component={Edit} />
                    </IconButton>
                </Box>
            ),
        },
        { field: "customer", headerName: t("homeModule.Customer"), flex: 1, sortable: true },
        { field: "name", headerName: t("homeModule.Name"), flex: 1, sortable: true },
        { field: "branch", headerName: t("homeModule.Branch"), flex: 1, sortable: true },
        { field: "count", headerName: t("homeModule.Count"), flex: 1, sortable: true },
    ];

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Accordion onChange={getPmdrList} sx={styles.accord}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="pending-media-destruction-content"
                    id="pending-media-destruction-requests"
                >
                    <Typography sx={styles.headerTypography}>
                        {t("homeModule.PendingMediaDestructionRequests")} ({pMDRount})
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Typography variant="body1" sx={styles.bodyTypography}>
                            {t(
                                "homeModule.TheFollowingAccountsHaveMediaDestructionRequestsThatAreInPendingConfirmationStatusAndRequireAuthorization"
                            )}
                        </Typography>
                        <Box style={styles.tableBox}>
                            <SecureSyncTable
                                propStyles={styles.dataGrid}
                                rows={rows}
                                columns={columns}
                                noDataMessage={t("messages.noRecordsFound")}
                                getRowId={(row) => row.customerId}
                                sx={styles.dataGrid}
                                initialOrder="decs"
                            />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

PMDRequestSection.propTypes = {
    count: PropTypes.number,
};

export default PMDRequestSection;
