import { Box, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import styles from "./MediaRequestedDestructionDetails.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { getMinAndMaxDate } from "src/utils/dateHelper";
import { getPageTitleAndDesc } from "src/utils/requestHelper";

const MediaRequestedDestructionDetails = ({
    mediaDestructionDetails,
    isAuthorizeRejectPossible,
    description,
    pageTitle,
    setDescription,
    setPageTitle,
}) => {
    const destructRequestTypeId = mediaDestructionDetails[0]?.destruct_request_type_id;
    const destructRequestStatusId = mediaDestructionDetails[0]?.destruct_request_status_id;
    const destructCancelReasonId = mediaDestructionDetails[0]?.destruct_cancel_reason_id;
    const { minDateForApi: minServiceDate } = getMinAndMaxDate();

    useEffect(() => {
        const { description, pageTitle } = getPageTitleAndDesc(
            destructRequestTypeId,
            destructRequestStatusId,
            destructCancelReasonId,
            isAuthorizeRejectPossible,
            mediaDestructionDetails,
            minServiceDate
        );
        setDescription(description);
        setPageTitle(pageTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box>
                <PageSubTitle sx={styles.heading}>{pageTitle}</PageSubTitle>
                <Typography sx={styles.textPadding}>{description}</Typography>
            </Box>
            <Divider />
        </>
    );
};

export default MediaRequestedDestructionDetails;
