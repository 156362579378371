import { useHotkeys } from "react-hotkeys-hook";
import shortCutKeys from "src/constants/shortCutKeys";
const useSecureSyncHotKeys = ({ hotkeys, callback, dependency, options = {} }) => {
    let allHotKeys = "";
    const isAlternativeKey = [shortCutKeys.shiftAltF, shortCutKeys.shiftAltD, shortCutKeys.shiftAltE].includes(hotkeys);
    if (!isAlternativeKey) {
        allHotKeys = `Shift+${hotkeys},${hotkeys}`;
    } else {
        allHotKeys = hotkeys;
    }

    return useHotkeys(allHotKeys, callback, { enableOnFormTags: true, ...options }, dependency);
};

export default useSecureSyncHotKeys;
