import i18n from "src/i18n";
export const verifyIssueTypeId = {
    sendOpenMediaToIronMountain: "1",
    receiveOpenMediaFromIronMountain: "2",
    receiveClosedContainerContents: "3",
};

export const resolved = {
    resolvedYes: "Y",
    resolvedNo: "N",
};

export const sourceType = {
    text: "text",
    keyboard: "keyboard",
};

export const getSourceTypeOptions = () => [
    { id: "1", label: i18n.t("common.textFile"), value: sourceType.text },
    { id: "2", label: i18n.t("common.keyboardWedgeScanner"), value: sourceType.keyboard },
];

export const sortingOrderType = {
    ascending: "asc",
    descending: "desc",
};

export const initialVerifyDataObj = {
    containerId: "",
    customerFileId: "",
    customerFileSectionId: "",
    requestId: "",
    logicalVaultId: "",
    scannedPhrase: "",
    verifyResultId: "",
    mediaList: "",
    filterDate: "",
    logicalVaultDesc: "",
    actionId: "",
    actionDes: "",
    listId: "",
    listDes: "",
};

export const sortCriteriaPickupManifest = [{ key: "Number", order: sortingOrderType.ascending }];
export const sortCriteriaSubmitContainer = [{ key: "Container_Number", order: sortingOrderType.descending }];
export const sortCriteriaDownloadInventory = [
    { key: "filter", order: sortingOrderType.ascending },
    { key: "logical_vault_invoice_descr", order: sortingOrderType.ascending },
    { key: "volser", order: sortingOrderType.ascending },
];
export const sortCriteriaDescScanVerification = [{ key: "descr", order: sortingOrderType.descending }];
export const sortCriteriaAscScanVerification = [{ key: "descr", order: sortingOrderType.ascending }];
export const sortCriteriaEditContainer = [
    { key: "includeOnManifestFlag", order: sortingOrderType.descending },
    { key: "Number", order: sortingOrderType.ascending },
];

export const enterKeyCode = 13;
