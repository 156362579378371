const styles = {
    gridContainer: {
        margin: "20px 0",
        gridRowGap: "20px",
    },
    buttonContainer: {
        display: "flex",
        gap: 2,
        paddingBottom: "20px",
        paddingTop: "20px",
    },
    gridItem: {
        paddingRight: "20px",
    },
};

export default styles;
