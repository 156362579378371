import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import styles from "./CustomDatePickerHeader.styles";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";

export function CustomDatePickerHeader(props) {
    const { t } = useTranslation();
    const { currentMonth, onMonthChange, disablePast, disableFuture, onViewChange, view: currentView } = props;

    const isPreviousMonthDisabled = disablePast && currentMonth.isSameOrBefore(dayjs(), dateTimeUnits.month);
    const isAfterMonthDisabled = disableFuture && currentMonth.isSameOrAfter(dayjs(), dateTimeUnits.month);

    const selectNextMonth = () => onMonthChange(currentMonth.add(1, dateTimeUnits.month), "left");
    const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, dateTimeUnits.month), "right");

    const handleYearButtonClick = () => {
        onViewChange(dateTimeUnits.year);
    };

    const handleDayButtonClick = () => {
        onViewChange(dateTimeUnits.day);
    };
    const currentMonthNum = currentMonth.month() + 1;
    const isYearView = currentView === dateTimeUnits.year;

    return (
        <Box sx={styles.headerRoot}>
            <Box sx={styles.labelSection}>
                <Typography sx={styles.headerLabel} variant="body2">
                    {t(`datePicker.month.${currentMonthNum}`, { year: currentMonth.format(dateFormats.yearFormat) })}
                </Typography>
                <IconButton
                    onClick={isYearView ? handleDayButtonClick : handleYearButtonClick}
                    title={t("datePicker.selectYear")}
                >
                    <ArrowDropDownIcon sx={styles.yearIcon(isYearView)} />
                </IconButton>
            </Box>
            {!isYearView && (
                <Stack spacing={1} direction="row">
                    <IconButton
                        onClick={selectPreviousMonth}
                        title={t("datePicker.prevMonth")}
                        disabled={isPreviousMonthDisabled}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <IconButton
                        onClick={selectNextMonth}
                        title={t("datePicker.nextMonth")}
                        disabled={isAfterMonthDisabled}
                    >
                        <ChevronRight />
                    </IconButton>
                </Stack>
            )}
        </Box>
    );
}
