const styles = {
    cardContent: {
        width: "100%",
        height: "auto",
    },
    clearIcon: {
        color: "im_dark_blue.500",
    },
    recordCount: {
        padding: "16px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
    },
    redText: { color: "red" },
    tableDesc: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "1.5",
        letterSpacing: "0.15px",
    },
    pageHeader: {
        paddingTop: "0",
        paddingBottom: "0",
    },
};

export default styles;
