import PropTypes from "prop-types";
import { styles } from "./InLineLink.styles";

export const InLineLink = (props) => {
    const { onClickFunction, children } = props;
    return (
        <span style={styles.urlSpanStyle} onClick={onClickFunction}>
            {" "}
            {children}
        </span>
    );
};

InLineLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    onClickFunction: PropTypes.func,
};
