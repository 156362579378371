import { Typography } from "@mui/material";
import styles from "./PageTitle.styles";
const PageTitle = ({ children }) => {
    return (
        <Typography variant="h4" sx={styles.h4Heading}>
            {children}
        </Typography>
    );
};

export default PageTitle;
