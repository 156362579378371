const styles = {
    primaryCheckBox: {
        color: "im_dark_blue.500",
        "&.Mui-checked": {
            color: "primary",
        },
    },
    checkBoxContainer: {
        display: "flex",
    },
    formLabelSx: {
        alignContent: "center",
        color: "im_grey.900",
        "&.Mui-focused": {
            color: "im_grey.900",
        },
    },
};

export default styles;
