const styles = {
    headerRoot: {
        display: "flex",
        justifyContent: "space-between",
        padding: "8px 16px",
        alignItems: "center",
    },
    labelSection: {
        p: 1,
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        cursor: "pointer",
        marginRight: "auto",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: 1.5,
    },
    headerLabel: {
        mr: "6px",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: 1.5,
    },
    yearIcon: (isTransform) => ({
        transform: isTransform ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease-in-out",
    }),
};
export default styles;
