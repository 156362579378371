/* eslint-disable max-lines */
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Switch,
    Radio,
    Typography,
    TextField,
    Grid,
    RadioGroup,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import styles from "./EditMyPreferences.styles";
import { getPreferenceValueByPreferenceType } from "src/utils/editMyPreferencesHelper";

import {
    getPreferencesService,
    getNonPreferenceDataService,
    updateUserPreferencesService,
} from "src/services/adminService";
import { allowedPrefList } from "src/constants/adminConstants";
import { preferenceTypeId } from "src/constants/preferenceServiceConstants";
import { getSelectedCustomer, getUserDetails, getAllowedCustomers } from "src/redux/userDetailsSlice";
import { getSupportedLanguageDetails } from "src/redux/languageSlice";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getReportFormatList } from "src/services/commonServices";
import CustomerListDropdown from "src/components/AppComponents/Header/CustomerListDropdown/CustomerListDropdown";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { getSelectedLanguageLocaleId } from "src/redux/languageSlice";
import { yesFlag, noFlag } from "src/constants/serviceConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";
const validationSchema = (t) =>
    Yup.object().shape({
        businessEmail: Yup.string()
            .matches(/^\S+@\S+\.\S+$/, t("editMyPreferences.businessEmailValidation"), { excludeEmptyString: true })
            .when("reportVaultStatusEmail", {
                is: true,
                then: (schema) => schema.required(t("editMyPreferences.businessEmailRequiredField")),
            }),
    });

const initValue = {
    language: "",
    defaultCustomerFlag: false,
    defaultCustomerAccount: "",
    defaultReportFormat: "",
    reportVaultStatusEmail: false,
    businessEmail: "",
    customerPreferenceFlag: "",
    defaultVaultStatusLanguage: "",
    emailVaultStatusFlag: false,
};

const EditMyPreferences = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            setIntValues();
        },
    });
    const selectedUser = useSelector(getSelectedCustomer);
    const userDetails = useSelector(getUserDetails);
    const customerLists = useSelector(getAllowedCustomers);
    const { t, i18n } = useTranslation();

    const { data: supportLanguageDetails } = useSelector(getSupportedLanguageDetails);
    const localeId = useSelector(getSelectedLanguageLocaleId);
    const languageOptions = useMemo(() => {
        return supportLanguageDetails
            .map((lang) => ({
                id: lang.locale_id,
                value: lang.locale_id,
                label: lang.language_display_name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [supportLanguageDetails]);
    const [userPreferences, setUserPreferences] = useState([]);
    const [customerNonPreferences, setCustomerNonPreferences] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [dropdownData, setDropdownData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ show: false, severity: "", message: "" });
    const handleOpenSnackbar = (msg) => {
        setSnackbarState({ show: true, severity: msg.severity, message: msg.message });
    };
    const handleCloseSnackbar = () => {
        setSnackbarState({ show: false, severity: "", message: "" });
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, isSubmitting } = useFormik({
        initialValues: initValue,
        validationSchema: validationSchema(t),
        onSubmit: async () => {
            await formSubmit();
        },
    });

    const formSubmit = async () => {
        const updatedUserPreferences = userPreferences.map((pref) => {
            switch (pref.preference_type_id) {
                case preferenceTypeId.defaultCustomerAtLogin:
                    return {
                        ...pref,
                        // eslint-disable-next-line
                        preference_value: values.defaultCustomerFlag ? values.defaultCustomerAccount : "0",
                    };
                case preferenceTypeId.defaultReportFileFormat:
                    return {
                        ...pref,
                        // eslint-disable-next-line
                        preference_value: values.defaultReportFormat,
                    };
                case preferenceTypeId.emailOnlyIfExceptionFlag:
                    return {
                        ...pref,
                        // eslint-disable-next-line
                        preference_value: values.emailVaultStatusFlag ? yesFlag : noFlag,
                    };
                case preferenceTypeId.vaultStatusReportEmailLanguage:
                    return {
                        ...pref,
                        // eslint-disable-next-line
                        preference_value: values.defaultVaultStatusLanguage,
                    };
                case preferenceTypeId.defaultCustomerAtLoginOptions:
                    return {
                        ...pref,
                        // eslint-disable-next-line
                        preference_value: values.defaultCustomerFlag ? yesFlag : noFlag,
                    };
                default:
                    return pref;
            }
        }, {});

        const findLocaleCodeById = supportLanguageDetails.find(
            (item) => item.locale_id === values.language
        ).iso_locale_code;

        const res = await updateUserPreferencesService(
            values.customerPreferenceFlag,
            values.reportVaultStatusEmail,
            values.businessEmail,
            updatedUserPreferences
        );
        if (!res.failure) {
            setIsLoading(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
            i18n.changeLanguage(findLocaleCodeById);
            getPreferences();
            handleOpenSnackbar({
                severity: "success",
                message: t("editMyPreferences.responseMessage"),
            });
        } else {
            setIsLoading(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
            handleOpenSnackbar({ severity: "error", message: res.errorCode });
        }
    };

    // TODO ? need to discuss in demo. remove this option until demo feed back
    // const preferredReportFormatOptions = useMemo(
    //     () =>
    //         dropdownData &&
    //         dropdownData.map((item) => ({
    //             id: item.dropdown_list_id,
    //             label: item.descr,
    //             value: item.list_value,
    //         })),
    //     [dropdownData]
    // );

    const resDefaultCustomerAccount = useMemo(
        () => {
            const res = getPreferenceValueByPreferenceType(userPreferences, preferenceTypeId.defaultCustomerAtLogin);
            return res;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userPreferences]
    );

    const getCustomerNumber = useMemo(() => {
        customerNonPreferences.sort((a, b) =>
            a.customer_number > b.customer_number ? 1 : b.customer_number > a.customer_number ? -1 : 0
        );
        return customerNonPreferences && customerNonPreferences.map((item) => item.customer_number).join(", ");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerNonPreferences]);

    const showDefaultCustomerAtLogin = useMemo(() => {
        if (customerNonPreferences.length > 0) {
            return customerNonPreferences.every((item) => item.Allow_Default_Customer === yesFlag);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerNonPreferences]);

    const setIntValues = () => {
        const isReception = getPreferenceValueByPreferenceType(
            userPreferences,
            preferenceTypeId.emailOnlyIfExceptionFlag
        );
        const emailLanguage = getPreferenceValueByPreferenceType(
            userPreferences,
            preferenceTypeId.vaultStatusReportEmailLanguage
        );
        const res = resDefaultCustomerAccount;
        const selectedCustomerNonPreferences = customerNonPreferences.find(
            (item) => item.customer_id === selectedUser.customer_id
        );
        let isSameBranch = true;
        if (res && res !== "0") {
            const defaultCustomer = customerLists.find((customer) => customer.customer_id === res);
            isSameBranch = defaultCustomer?.district_id === selectedCustomerNonPreferences?.District_Id;
        }
        setValues({
            ...values,
            reportVaultStatusEmail:
                customerNonPreferences.length > 0
                    ? selectedCustomerNonPreferences?.gets_vault_status_rpt_flag === yesFlag
                    : false,
            customerPreferenceFlag: noFlag,
            emailVaultStatusFlag: isReception === yesFlag,
            defaultCustomerFlag: res !== "0",
            language: localeId,
            defaultVaultStatusLanguage: emailLanguage,
            businessEmail: customerNonPreferences.length > 0 ? customerNonPreferences[0]?.business_email_addr : "",
            defaultCustomerAccount: res !== "0" && isSameBranch ? res : selectedUser.customer_id,
            defaultReportFormat: "5",
        });
    };
    useEffect(
        () => {
            setIntValues();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customerNonPreferences]
    );
    const getDropdownData = async () => {
        const reportFormatDropdown = await getReportFormatList();
        setDropdownData(reportFormatDropdown);
    };
    const getPreference = async () => {
        const prefList = await getPreferencesService(preferenceTypeId.user, allowedPrefList.user, "", true);
        setUserPreferences(prefList?.preferences ? prefList.preferences : []);
        const nonprefListCust = await getNonPreferenceDataService();
        setCustomerNonPreferences(nonprefListCust?.data ? nonprefListCust.data : []);
    };
    const getPreferences = async () => {
        setIsLoading(true);
        await getPreference();
        await getDropdownData();
        setIsLoading(false);
    };
    useEffect(() => {
        getPreferences();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <CircularLoaderFullPage show={isLoading || isSubmitting} />
            <Container>
                <Box sx={styles.mainContainer}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle>{t("editMyPreferences.editMyPreference")}</PageTitle>
                        <SecureSyncSnackBar
                            openSnackBar={snackbarState.show}
                            message={snackbarState.message}
                            severity={snackbarState.severity}
                            onClose={handleCloseSnackbar}
                        />
                        <Typography variant="body1" sx={styles.editPreferenceBody1}>
                            {t("editMyPreferences.updateReflectUser")} "<strong>{userDetails.email}</strong>":
                        </Typography>
                        <Divider />
                        <PageSubTitle>{t("editMyPreferences.SecureSyncLanguage")}</PageSubTitle>
                        <Box sx={styles.contactFieldContainer}>
                            <FormControl sx={styles.formControlDropdown}>
                                <SecureSyncSelect
                                    name="language"
                                    label={t("editMyPreferences.language")}
                                    options={languageOptions}
                                    value={values.language}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormControl>
                        </Box>
                        {showDefaultCustomerAtLogin && (
                            <>
                                <Divider />
                                <PageSubTitle>{t("editMyPreferences.defaultCustomerAtLogin")}</PageSubTitle>
                                <Typography variant="body1" sx={styles.editPreferenceBody2}>
                                    <Trans i18nKey={"editMyPreferences.selectYesOrNo"} />
                                </Typography>
                                <Box sx={styles.switchLabelContainer}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={4}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="defaultCustomerFlag"
                                                        checked={values.defaultCustomerFlag}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={t("editMyPreferences.defaultCustomerAtLogin")}
                                            />
                                            <Typography variant="body1" component="div" sx={styles.toggleContainer}>
                                                <Trans i18nKey={"common.toggleOnOff"} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormControl sx={styles.formControlCustomer}>
                                                <CustomerListDropdown
                                                    id="login-page"
                                                    label={t("editMyPreferences.defaultCustomerAccount")}
                                                    name="defaultCustomerAccount"
                                                    type="outlined"
                                                    required
                                                    displayEmpty={false}
                                                    value={values.defaultCustomerAccount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.defaultCustomerAccount &&
                                                        Boolean(errors.defaultCustomerAccount)
                                                    }
                                                    helperText={
                                                        touched.defaultCustomerAccount && errors.defaultCustomerAccount
                                                    }
                                                    disabled={!values.defaultCustomerFlag}
                                                    onlySelectedBranch={true}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}
                        {/* 
                        // TODO ? need to discuss in demo. remove this option until demo feed back
                        <Divider />
                        <PageSubTitle>{t("editMyPreferences.defaultReportFileFormat")}</PageSubTitle>
                        <Box sx={styles.contactFieldContainer}>
                            <FormControl sx={styles.formControlDropdown}>
                                <SecureSyncSelect
                                    name="defaultReportFormat"
                                    type="outlined"
                                    label={t("editMyPreferences.preferredReportFormatOptions")}
                                    defaultOption={"PDF"}
                                    options={preferredReportFormatOptions}
                                    value={values.defaultReportFormat}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Box> */}
                        <Divider />
                        <PageSubTitle>{t("editMyPreferences.vaultStatusReportEmail")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.userDetailBody1}>
                            <Trans i18nKey={"editMyPreferences.selectYes"} />
                        </Typography>
                        <Box sx={styles.switchLabelContainer2}>
                            <Grid container spacing={2}>
                                <Grid item lg={4} style={{ marginTop: "15px" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="reportVaultStatusEmail"
                                                checked={values?.reportVaultStatusEmail}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={t("editMyPreferences.vaultStatusReportEmail")}
                                    />
                                    <Typography variant="body1" component="div" sx={styles.toggleContainer}>
                                        <Trans i18nKey={"common.toggleOnOff"} />
                                    </Typography>
                                </Grid>
                                <Grid item lg={8}>
                                    <Box sx={styles.contactFieldContainerTop}>
                                        <FormControl sx={styles.businessEmailControl}>
                                            <TextField
                                                name="businessEmail"
                                                label={t("editMyPreferences.businessEmailAddress")}
                                                variant="outlined"
                                                value={values.businessEmail}
                                                required={values?.reportVaultStatusEmail}
                                                onChange={handleChange}
                                                error={touched.businessEmail && Boolean(errors.businessEmail)}
                                                helperText={touched.businessEmail && errors.businessEmail}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {customerLists.length > 1 && (
                            <Box sx={styles.contactFieldContainer2}>
                                <RadioGroup name="row-radio-buttons-group">
                                    <Box>
                                        <FormControlLabel
                                            name="customerPreferenceFlag"
                                            value="N"
                                            control={<Radio />}
                                            label={`${t("editMyPreferences.applyPreferenceToCustomer")} ${selectedUser.customer_number}`}
                                            checked={values.customerPreferenceFlag === noFlag}
                                            onChange={handleChange}
                                        />
                                        <FormControlLabel
                                            name="customerPreferenceFlag"
                                            value="Y"
                                            control={<Radio />}
                                            label={t("editMyPreferences.applypreferenceToAll")}
                                            checked={values.customerPreferenceFlag === yesFlag}
                                            onChange={handleChange}
                                        />
                                        <SecureSyncIconTooltip
                                            title={
                                                <>
                                                    <Typography>
                                                        <Box component="span">
                                                            {t("editMyPreferences.allCustomersHaveAccess")}
                                                        </Box>
                                                        <br />
                                                        <Box component="span" sx={styles.footNotes}>
                                                            {getCustomerNumber}
                                                        </Box>
                                                    </Typography>
                                                </>
                                            }
                                            placement="right"
                                        />
                                    </Box>
                                </RadioGroup>
                            </Box>
                        )}
                        <Divider />
                        <PageSubTitle>{t("editMyPreferences.defaultVaultStatus")}</PageSubTitle>
                        <Typography variant="body1" sx={styles.userDetailBody1}>
                            {t("editMyPreferences.selectLanguage")}
                        </Typography>
                        <Box sx={styles.dividerSpacing}>
                            <FormControl sx={styles.formControlDropdown}>
                                <SecureSyncSelect
                                    label={t("editMyPreferences.language")}
                                    options={languageOptions}
                                    name="defaultVaultStatusLanguage"
                                    value={values.defaultVaultStatusLanguage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormControl>
                        </Box>
                        <Divider />
                        <Box sx={styles.contactFieldContainer}>
                            <PageSubTitle>{t("editMyPreferences.emailVaultStatus")}</PageSubTitle>
                            <Typography variant="body1" sx={styles.contactFieldContainer2}>
                                <Trans i18nKey={"editMyPreferences.selectYesVaultStatus"} />
                            </Typography>
                            <Box>
                                <FormControlLabel
                                    control={<Switch />}
                                    name="emailVaultStatusFlag"
                                    label={t("editMyPreferences.emailVaultStatus")}
                                    checked={values.emailVaultStatusFlag}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Typography variant="body1" component="div" sx={styles.toggleContainer}>
                                <Trans i18nKey={"common.toggleOnOff"} />
                            </Typography>
                        </Box>
                        <Divider />
                        <Divider />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.reset)}
                                variant="outlined"
                                color="primary"
                                onClick={setIntValues}
                            >
                                {t("common.reset")}
                            </Button>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {t("common.submit")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default EditMyPreferences;
