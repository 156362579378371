import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./ModifyHistory.styles";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";
import { lastModifyDetailsService } from "src/services/authorizationService";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";

const ModifyHistory = (props) => {
    const [indMod, setIntMod] = useState(null);
    const [drMod, setDrMod] = useState(null);
    const [personalMod, setPersonalModMod] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.personalData) {
            setData(props.personalData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.personalData]);

    const handleClose = () => {
        props.closePopup(false);
    };

    const setData = async (person) => {
        setLoading(true);
        const res = await lastModifyDetailsService(person.personnel_id, selectedCustomer.customer_id);
        setIntMod(res?.modifyData?.intMod);
        setDrMod(res?.modifyData?.drMod);
        setPersonalModMod(res?.modifyData?.personalMod);
        setLoading(false);
    };

    const personName = useMemo(() => {
        if (props.personalData) {
            // eslint-disable-next-line camelcase
            const { first_name = "", middle_name = "", last_name = "" } = props.personalData;
            // eslint-disable-next-line camelcase
            const dotMiddleName = middle_name ? `${middle_name}.` : "";
            // eslint-disable-next-line camelcase
            return `${first_name} ${dotMiddleName} ${last_name}`;
        } else {
            return "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.personalData]);
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncModal
                open={props.modUserOpen}
                onClose={handleClose}
                title={t("userDetails.lastModification")}
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="contained" onClick={handleClose}>
                            {t("buttonLabels.close")}
                        </Button>
                    </Box>
                }
            >
                <Typography sx={styles.header}>
                    <Trans i18nKey="userDetails.lastModTitle" values={{ name: personName }} />
                </Typography>
                <Box>
                    <Box sx={styles.boxPaddingBottom}>
                        <SecureSyncReadOnlyTextField
                            value={personalMod?.last_mod_name ? personalMod?.last_mod_name : "-"}
                            label={t("userDetails.userInfo")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={personalMod?.last_mod_date ? personalMod?.last_mod_date : " "}
                            label=" "
                        />
                    </Box>
                    <Box sx={styles.boxPaddingBottom}>
                        <SecureSyncReadOnlyTextField
                            value={indMod?.last_mod_name ? indMod?.last_mod_name : "-"}
                            label={t("userDetails.intAuth")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={indMod?.last_mod_date ? indMod?.last_mod_date : " "}
                            label=" "
                        />
                    </Box>
                    <Box sx={styles.boxPaddingBottom}>
                        <SecureSyncReadOnlyTextField
                            value={drMod?.last_mod_name ? drMod?.last_mod_name : "-"}
                            label={t("userDetails.drAuth")}
                        />
                        <SecureSyncReadOnlyTextField
                            value={drMod?.last_mod_date ? drMod?.last_mod_date : " "}
                            label=" "
                        />
                    </Box>
                </Box>
            </SecureSyncModal>
        </>
    );
};

export default ModifyHistory;
