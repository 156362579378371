export const getCustomerListByStatus = (customers) => {
    const allowedCustomers = [];
    const onHoldCustomers = [];
    let defaultCustomer = null;
    for (const customer of customers) {
        if (customer.On_Hold === "Y") {
            onHoldCustomers.push(customer);
        } else {
            allowedCustomers.push(customer);
        }
        if (customer.default_customer_flag === "Y") {
            defaultCustomer = customer;
        }
    }

    return { allowedCustomers, onHoldCustomers, defaultCustomer };
};
