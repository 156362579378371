import { useState, useEffect, useRef, useMemo } from "react";
import { Box, Button, Container, Typography, TextField, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styles from "./SearchOpenMedia.styles";
import ExcessRecordModal from "src/components/Operations/Research/SearchOpenMedia/ExcessRecordModal/ExcessRecordModal";
import ListOpenMedia from "src/components/Operations/Research/SearchOpenMedia/ListOpenMedia/ListOpenMedia";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import { systemId } from "src/constants/serviceConstants";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { dateFormats } from "src/constants/dateFormatConstants";
import { formatDate, getMinAndMaxDate } from "src/utils/dateHelper";
import { downloadInstructionGuide } from "src/utils/editMyPreferencesHelper";
import { logicalVaultFileName, staticFile } from "src/constants/fileConstants";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { getDisplayFormat } from "src/utils/dateHelper";
import { getOpenMediaDetailPath, searchOpenMedia } from "src/constants/routeConstants/operationsRouterFullPaths";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { getSearchOpenMediaGlobalAttrs } from "src/utils/researchHelper";
import { getOpenMediaList, getOpenMediaSearch } from "src/services/researchService";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";
const validationSchema = (t) => {
    const { minDateForApi: minDate, displayMinDate } = getMinAndMaxDate();
    const maxDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const displayMaxDate = formatDate(dayjs());
    Yup.object().shape({
        mediaNumber: Yup.string(),
        mediaDescription: Yup.string(),
        drpCode: Yup.string(),
        returnDateFrom: Yup.string().test("validateStartDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value).isValid();
            const isToDateValid = dayjs(parent.returnDateTo).isValid();
            const isBeforeDate = dayjs(value).isSameOrBefore(dayjs(parent.returnDateTo));
            const isOutSideMinDate = dayjs(value).isBefore(minDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: formatDate(parent.returnDateTo),
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        minDate: getDisplayFormat(displayMinDate),
                        maxDate: getDisplayFormat(displayMaxDate),
                    });
                }
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        returnDateTo: Yup.string().test("validateEndDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value).isValid();
            const isOutSideMaxDate = dayjs(value).isAfter(maxDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        currentStatus: Yup.string(),
        logicalVault: Yup.string(),
    });
};

const initValues = {
    mediaNumber: "",
    mediaDescription: "",
    drpCode: "",
    returnDateFrom: "",
    returnDateTo: "",
    currentStatus: "",
    logicalVault: "",
};

const SearchOpenMedia = () => {
    const shortCutKeys = getShortCutKeys();
    const { state: locationState } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const valuesRef = useRef("");

    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setValues,
        handleReset,
    } = useFormik({
        initialValues: initValues,
        validationSchema: validationSchema(),
        onSubmit: async () => {
            setSearched(true);
            await formSubmit();
        },
    });

    const showWaiverFile = async (fileForms, fileName) => {
        const waiverLink = await downloadInstructionGuide(fileForms, fileName);
        window.open(waiverLink, "_blank");
    };

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
            newNewPageState.orderBy = locationState?.orderBy;
            newNewPageState.order = locationState?.order;
        }

        return newNewPageState;
    };

    const formSubmit = async () => {
        if (values.returnDateFrom !== "" && values.returnDateTo === "") {
            setFieldValue("returnDateTo", values.returnDateFrom);
        } else if (values.returnDateFrom === "" && values.returnDateTo !== "") {
            setFieldValue("returnDateFrom", values.returnDateTo);
        }
        const res = await getOpenMediaList(values);
        const pageState = getPageState({ ...values });
        navigate(searchOpenMedia, {
            state: pageState,
            replace: true,
        });
        valuesRef.current = values;
        if (!res?.failure) {
            if (
                res?.verifiedResultList.length > 0 &&
                Object.keys(res?.verifiedResultList[0]).includes("error") &&
                res?.verifiedResultList[0].error === "63004"
            ) {
                setExcessRecordModalShow(true);
            } else {
                const sortedMediaResultList = res?.verifiedResultList.sort((a, b) => a.volser.localeCompare(b.volser));
                setOpenMediaResultList(sortedMediaResultList);
            }
        }
    };

    const [drpCode, setDrpCode] = useState([]);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [logicalVault, setLogicalVault] = useState([]);
    const [openMediaResultList, setOpenMediaResultList] = useState([]);
    const [isSearched, setSearched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [excessRecordModalShow, setExcessRecordModalShow] = useState(false);
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    const { displayMaxDate } = getMinAndMaxDate();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            handleReset();
            setOpenMediaResultList([]);
        },
    });
    const { mediaNumberMaxLength } = useMemo(() => {
        return getSearchOpenMediaGlobalAttrs(globalAttributes?.data?.rs0);
    }, [globalAttributes]);

    const getDistrictGlobalAttributeAndOpenMediaResult = async () => {
        setIsLoading(true);
        const [res] = await Promise.all([getOpenMediaSearch()]);
        const drpCode = res.rs0
            .filter((item) => item.DRP_Code !== "")
            .map((item) => ({
                id: item.DRP_Code,
                value: item.DRP_Code,
                label: item.DRP_Code,
            }));

        const firstOption = { id: "1", value: "0", label: t("containerSearch.allDropDown") };
        setDrpCode([firstOption, ...drpCode]);

        const currentStatus = res.rs1
            .sort((a, b) => Number(a.Order_Column) - Number(b.Order_Column))
            .filter((item) => item.Summary_Type !== "")
            .map((item) => ({
                id: item.Summary_Type,
                value: item.Summary_Type,
                label: item.Summary_Type,
            }));
        setCurrentStatus([firstOption, ...currentStatus]);
        const logicalVault = res.rs2
            .sort(
                (a, b) =>
                    Number(a.Order_Column) - Number(b.Order_Column) || a.Invoice_Descr.localeCompare(b.Invoice_Descr)
            )
            .filter((item) => item.Invoice_Descr !== "")
            .map((item) => ({
                id: item.Logical_Vault_Id,
                value: item.Logical_Vault_Id,
                label: item.Invoice_Descr,
            }));

        setLogicalVault([firstOption, ...logicalVault]);
        setIsLoading(false);
    };

    const navigateToDetails = (media) => {
        const accountEditPath = getOpenMediaDetailPath({
            mediaId: media.open_media_id,
            ...valuesRef.current,
        });
        navigate(accountEditPath);
    };

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState) {
                const newFormValues = {
                    mediaNumber: locationState?.mediaNumber || "",
                    mediaDescription: locationState?.mediaDescription || "",
                    drpCode: locationState?.drpCode || "",
                    returnDateFrom: locationState?.returnDateFrom || "",
                    returnDateTo: locationState?.returnDateTo || "",
                    currentStatus: locationState?.currentStatus || "",
                    logicalVault: locationState?.logicalVault || "",
                };
                await setValues(newFormValues);
                submitForm();
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getDistrictGlobalAttributeAndOpenMediaResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLogicalVaultValue = () => {
        return values.logicalVault !== 0 && values.logicalVault !== ""
            ? logicalVault.find((item) => item.id === values.logicalVault)
            : "";
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading || isSubmitting} />
            <Container>
                <Box sx={styles.mainContainer}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle>{t("titles.searchOpenMedia")}</PageTitle>
                        <Typography sx={styles.filterDesc}>
                            <Trans
                                i18nKey={"searchOpenMedia.completeOpenMedia"}
                                values={{ buttonLabel: t("buttonLabels.find") }}
                            />
                        </Typography>
                        <Grid sx={styles.contactFieldContainer} container spacing={2}>
                            <Grid item md={4}>
                                <TextField
                                    label={t("common.mediaNumber")}
                                    name="mediaNumber"
                                    title={t("searchOpenMedia.findAllOpenMedia")}
                                    value={values.mediaNumber}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: mediaNumberMaxLength }}
                                />
                            </Grid>
                            <Grid item md={8}></Grid>
                            <Grid item md={12}>
                                <TextField
                                    label={t("common.description")}
                                    name="mediaDescription"
                                    title={t("searchOpenMedia.findOpenMediaContainsThisWord")}
                                    value={values.mediaDescription}
                                    onChange={handleChange}
                                    multiline={true}
                                    rows={3}
                                    maxRows={3}
                                    inputProps={{ maxLength: 30 }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SecureSyncSelect
                                    label={t("formFields.drpCode")}
                                    defaultOption={t("common.allWithBracket")}
                                    options={drpCode}
                                    name="drpCode"
                                    value={values.drpCode}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SecureSyncDatePicker
                                    label={t("formFields.returnDateFrom")}
                                    name="returnDateFrom"
                                    value={values.returnDateFrom}
                                    onChange={async (value) => {
                                        await setFieldValue("returnDateFrom", value);
                                        setFieldTouched("returnDateFrom", true, true);
                                    }}
                                    helperText={touched.returnDateFrom && errors.returnDateFrom}
                                    error={Boolean(touched.returnDateFrom && errors.returnDateFrom)}
                                    disablePast
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SecureSyncDatePicker
                                    label={t("formFields.returnDateTo")}
                                    name="returnDateTo"
                                    value={values.returnDateTo}
                                    onChange={async (value) => {
                                        await setFieldValue("returnDateTo", value);
                                        setFieldTouched("returnDateTo", true, true);
                                    }}
                                    helperText={touched.returnDateTo && errors.returnDateTo}
                                    error={Boolean(touched.returnDateTo && errors.returnDateTo)}
                                    disablePast
                                    maxDate={dayjs(displayMaxDate)}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SecureSyncSelect
                                    label={t("formFields.currentStatus")}
                                    defaultOption={t("common.allWithBracket")}
                                    options={currentStatus}
                                    name="currentStatus"
                                    value={values.currentStatus}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SecureSyncSelect
                                    label={t("formFields.logicalVault")}
                                    defaultOption={t("common.allWithBracket")}
                                    options={logicalVault}
                                    name="logicalVault"
                                    value={values.logicalVault}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={4} sx={styles.tooltip}>
                                <SecureSyncIconTooltip
                                    onClick={() => showWaiverFile(staticFile["logicalVault"], logicalVaultFileName)}
                                    title={t("searchOpenMedia.logicalVaultToolTip")}
                                    placement="left"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={styles.buttonContainer}>
                        <Button
                            id="back"
                            variant="outlined"
                            color="primary"
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                            onClick={() => {
                                handleReset();
                                setOpenMediaResultList([]);
                            }}
                        >
                            {t("buttonLabels.clear")}
                        </Button>
                        <Button
                            id="back"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            title={getKeyBoardLabel(shortCutKeys.search)}
                        >
                            {t("buttonLabels.find")}
                        </Button>
                    </Box>
                </Box>
                <Box sx={styles.tableContainer}>
                    <ListOpenMedia
                        mediaList={openMediaResultList}
                        isSearched={isSearched}
                        navigateToDetails={(media) => navigateToDetails(media)}
                        values={values}
                        logicalVaultValue={getLogicalVaultValue()}
                    />
                </Box>
                <Box>
                    <Typography variant="body1" component="div" sx={styles.noteDescription}>
                        {openMediaResultList.length > 0 && t("searchOpenMedia.ironMountainChangeDescription")}
                    </Typography>
                </Box>
            </Container>
            <ExcessRecordModal
                handleConfirm={() => {
                    setExcessRecordModalShow(false);
                }}
                onClose={() => {
                    setExcessRecordModalShow(false);
                }}
                open={excessRecordModalShow}
            />
        </>
    );
};

export default SearchOpenMedia;
