import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "./SearchUsers.styles";
import SearchUsersTable from "src/components/Administration/Authorization/SearchUsers/SearchUsersTable/SearchUsersTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { searchUsers } from "src/constants/routeConstants/administrationRouterFullPaths";
import { getUserDetails } from "src/services/authorizationService";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getSearchUserReportPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        firstName: Yup.string()
            .matches(/^[A-Za-z]+$/, t("messages.firstNameValidation"))
            .max(30, t("messages.firstNameValidation")),
        lastName: Yup.string()
            .matches(/^[A-Za-z]+$/, t("messages.lastNameValidation"))
            .max(30, t("messages.lastNameValidation")),
        businessPhone: Yup.string().matches(/^[0-9()\-.\s]+$/, t("messages.businessPhoneValidation")),
        extension: Yup.number().typeError(t("messages.businessExtensionValidation")),
    });

export const SearchUsers = () => {
    const shortCutKeys = getShortCutKeys();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [filteredRows, setFilteredRows] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState(
        <Trans i18nKey={"messages.pleaseEnterUserSearchCriteria"} values={{ buttonLabel: t("buttonLabels.findAll") }} />
    );
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleFind(values, true) });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.find,
        callback: () => onFindAllButtonClick(),
    });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.clear, callback: () => onClear() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });

    const { values, errors, touched, handleBlur, handleChange, resetForm, setValues } = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            businessPhone: "",
            extension: "",
            eLevelAuthorization: false,
            drAuthorization: false,
        },
        validationSchema: validationSchema(t),
    });

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (isLocationStateHaveSearchFields(locationState)) {
                if (locationState.findAll) {
                    handleFindAll();
                } else if (!isAnyOneFieldHasValue(locationState)) {
                    const newFormValues = {
                        firstName: locationState?.firstName || "",
                        lastName: locationState?.lastName || "",
                        businessPhone: locationState?.businessPhone || "",
                        extension: locationState?.extension || "",
                        eLevelAuthorization: Boolean(locationState?.eLevelAuthorization),
                        drAuthorization: Boolean(locationState?.drAuthorization),
                    };
                    await setValues(newFormValues);
                    handleFind(newFormValues);
                }
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSortedRows = (userRecords) => {
        let sortedRows = userRecords.rs0.map((row) => ({
            ...row,
            // eslint-disable-next-line camelcase
            business_phone:
                row?.business_phone_ext && row.business_phone_ext !== ""
                    ? `${row.business_phone}, X${row.business_phone_ext}`
                    : row.business_phone,
        }));
        return (sortedRows = sortedRows.sort((value1, value2) => {
            const lastName1 = value1.personnel_name.split(",")[0].trim();
            const lastName2 = value2.personnel_name.split(",")[0].trim();
            if (lastName1 < lastName2) {
                return -1;
            }
            if (lastName1 > lastName2) {
                return 1;
            }
            return 0;
        }));
    };

    const isAnyOneFieldHasValue = (fields) => {
        return (
            fields.firstName === "" &&
            fields.lastName === "" &&
            fields.businessPhone.replace(/\D/g, "") === "" &&
            fields.extension === "" &&
            !fields.eLevelAuthorization &&
            !fields.drAuthorization
        );
    };

    const isLocationStateHaveSearchFields = (stateValues = {}) => {
        const locationState = stateValues || {};
        return (
            "firstName" in locationState ||
            "lastName" in locationState ||
            "businessPhone" in locationState ||
            "extension" in locationState ||
            "eLevelAuthorization" in locationState ||
            "drAuthorization" in locationState ||
            "findAll" in locationState
        );
    };

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
        }
        return newNewPageState;
    };

    const handleFindAll = async (isButtonClick) => {
        const pageState = getPageState({ findAll: true }, !isButtonClick);
        setLoading(true);
        navigate(searchUsers, {
            state: pageState,
            replace: true,
        });
        const userRecords = await getUserDetails();
        const sortedRows = getSortedRows(userRecords);
        setFilteredRows(sortedRows);
        resetForm();
        setNoDataMsg(sortedRows || sortedRows.length === 0 ? t("messages.noMatchingRecords") : noDataMsg);
        setLoading(false);
    };

    const handleFind = async (searchValues, isButtonClick) => {
        if (isAnyOneFieldHasValue(searchValues)) {
            setAlertOpen(true);
        } else {
            const pageState = getPageState({ ...searchValues }, !isButtonClick);
            navigate(searchUsers, {
                state: pageState,
                replace: true,
            });
            setLoading(true);
            const userRecords = await getUserDetails({
                firstName: searchValues.firstName,
                lastName: searchValues.lastName,
                homePhone: "",
                businessPhone: searchValues.businessPhone.replace(/\D/g, ""),
                businessPhoneExt: searchValues.extension,
                eLevelAuthorization: searchValues.eLevelAuthorization === true ? "Y" : "N",
                customerParentID: "",
                secureSyncFlag: "N",
                authNumber: "",
                drLevelAuthorization: searchValues.drAuthorization === true ? "Y" : "N",
            });
            const sortedRows = getSortedRows(userRecords);
            setFilteredRows(sortedRows);
            setNoDataMsg(sortedRows || sortedRows.length === 0 ? t("messages.noMatchingRecords") : noDataMsg);
            setLoading(false);
        }
    };

    const handleCheckBoxChange = (e) => {
        handleChange(e);
        setAlertOpen(false);
    };

    const printReport = () => {
        if (filteredRows.length > 0) {
            setPrintPayload(
                getSearchUserReportPayload({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    drLevelAuth: values.drAuthorization ? "Y" : "N",
                    eLevelAuth: values.eLevelAuthorization ? "Y" : "N",
                    businessPhone: values.businessPhone,
                    extension: values.extension,
                })
            );
            printModalOpen(true);
        }
    };

    const onFindAllButtonClick = () => {
        handleFindAll(true);
        setAlertOpen(false);
    };

    const onClear = () => {
        resetForm();
        setFilteredRows([]);
        setNoDataMsg(
            <Trans
                i18nKey={"messages.pleaseEnterUserSearchCriteria"}
                values={{ buttonLabel: t("buttonLabels.findAll") }}
            />
        );
        setAlertOpen(false);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container disableGutters sx={styles.searchUsersContainer}>
                <form>
                    <Box sx={styles.container}>
                        <Box sx={styles.innerBox}>
                            <PageTitle>{t("searchUser.pageTitle")}</PageTitle>
                            {alertOpen && (
                                <div style={styles.commonPaddingBottom}>
                                    <SecureSyncAlert
                                        severity="error"
                                        message={t("messages.pleaseEnterAtLeastOneCriteria")}
                                        sx={styles.alertMsg}
                                        onClose={() => {
                                            setAlertOpen(false);
                                        }}
                                    ></SecureSyncAlert>
                                </div>
                            )}
                            <Typography variant="body1" sx={styles.subheader}>
                                {t("searchUser.enterUserSearchCriteria")}
                            </Typography>

                            <Box sx={styles.textFieldContainer}>
                                <TextField
                                    label={t("formFields.firstName")}
                                    name="firstName"
                                    variant="outlined"
                                    value={values.firstName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setAlertOpen(false);
                                    }}
                                    error={Boolean(errors.firstName && touched.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    onBlur={handleBlur}
                                    fullWidth
                                    sx={styles.textFeildFlex}
                                    inputProps={{ maxLength: 30 }}
                                />
                                <TextField
                                    label={t("formFields.lastName")}
                                    name="lastName"
                                    variant="outlined"
                                    value={values.lastName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setAlertOpen(false);
                                    }}
                                    error={Boolean(errors.lastName && touched.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    onBlur={handleBlur}
                                    fullWidth
                                    sx={styles.textFeildFlex}
                                    inputProps={{ maxLength: 30 }}
                                />
                                <TextField
                                    label={t("formFields.businessPhone")}
                                    name="businessPhone"
                                    variant="outlined"
                                    value={values.businessPhone}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setAlertOpen(false);
                                    }}
                                    onBlur={handleBlur}
                                    fullWidth
                                    error={Boolean(errors.businessPhone && touched.businessPhone)}
                                    helperText={touched.businessPhone && errors.businessPhone}
                                    sx={styles.textFeildFlex}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <TextField
                                    label={t("formFields.extension")}
                                    name="extension"
                                    variant="outlined"
                                    value={values.extension}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setAlertOpen(false);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.extension && touched.extension)}
                                    helperText={touched.extension && errors.extension}
                                    fullWidth
                                    sx={styles.extendtionTextFeildFlex}
                                    inputProps={{ maxLength: 6 }}
                                />
                            </Box>
                            <Box sx={styles.checkBoxContainer}>
                                <SecureSyncCheckBox
                                    label={
                                        <Box sx={styles.formControlLabel}>
                                            {t("formFields.eLevelAuthorizationOnly")}
                                        </Box>
                                    }
                                    name="eLevelAuthorization"
                                    checked={values.eLevelAuthorization}
                                    onChange={(e) => handleCheckBoxChange(e)}
                                />
                                <SecureSyncCheckBox
                                    label={
                                        <Box sx={styles.formControlLabel}>{t("formFields.drAuthorizationOnly")}</Box>
                                    }
                                    name="drAuthorization"
                                    checked={values.drAuthorization}
                                    onChange={(e) => handleCheckBoxChange(e)}
                                />
                            </Box>
                            <Box sx={styles.buttonContainer}>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.clear)}
                                    id="clear"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClear}
                                >
                                    {t("buttonLabels.clear")}
                                </Button>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.find)}
                                    id="find-all"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onFindAllButtonClick}
                                >
                                    {t("buttonLabels.findAll")}
                                </Button>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.search)}
                                    id="find"
                                    variant="contained"
                                    onClick={() => {
                                        handleFind(values, true);
                                    }}
                                    color="primary"
                                >
                                    {t("buttonLabels.find")}
                                </Button>
                            </Box>
                        </Box>
                        <SecureSyncTableCard>
                            <Box sx={styles.recordCount}>
                                {filteredRows.length > 0 && (
                                    <>
                                        <Box sx={styles.recordStart}>
                                            <Typography variant="body1">
                                                {t("messages.recordsFound", { count: filteredRows.length })}
                                            </Typography>
                                        </Box>
                                        <Box sx={styles.recordEnd}>
                                            <Button
                                                title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                                onClick={printReport}
                                                variant="outlined"
                                            >
                                                {t("buttonLabels.print")}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Box sx={styles.cardContent}>
                                <SearchUsersTable
                                    users={filteredRows}
                                    noDataMessage={
                                        filteredRows.length === 0 ? noDataMsg : t("messages.noMatchingRecords")
                                    }
                                />
                            </Box>
                        </SecureSyncTableCard>
                    </Box>
                </form>
            </Container>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.searchUserReport}
            />
        </>
    );
};
