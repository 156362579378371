const styles = {
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
        p: "16px",
    },
    footerCaption: {
        mt: "10px",
        mb: "20px",
        fontWeight: 500,
        lineHeight: 1.5,
        "& strong": {
            fontWeight: 700,
            lineHeight: 1.5,
        },
    },
    container: {
        paddingBottom: "20px",
    },
};

export default styles;
