import { createSearchParams, generatePath } from "react-router-dom";
import { routePaths } from "./routeConstants";

const securePath = routePaths.root;

//Operations
export const operationsHome = `${securePath}/${routePaths.operations.home}`;
export const researchHome = `${operationsHome}/${routePaths.operations.research.home}`;
export const disasterRecoveryHome = `${operationsHome}/${routePaths.operations.disasterRecovery.home}`;

//Operations -> Reseach
export const searchResearch = researchHome;
export const completedLibraryAudit = `${researchHome}/${routePaths.operations.research.completedLibraryAudit}`;
export const completedLibraryAuditResult = `${researchHome}/${routePaths.operations.research.completedLibraryAuditResult}`;
export const correctiveActionRequest = `${researchHome}/${routePaths.operations.research.correctiveActionRequest}`;
export const searchContainer = `${researchHome}/${routePaths.operations.research.searchContainer}`;
export const searchOpenMedia = `${researchHome}/${routePaths.operations.research.searchOpenMedia}`;
export const containerDetail = `${researchHome}/${routePaths.operations.research.containerDetail}`;
export const openMediaDetail = `${researchHome}/${routePaths.operations.research.openMediaDetail}`;
export const discrepancyManagement = `${researchHome}/${routePaths.operations.research.discrepancyManagement}`;
export const vaultStatusReport = `${researchHome}/${routePaths.operations.research.vaultStatusReport}`;
export const globalMediaLocator = `${researchHome}/${routePaths.operations.research.globalMediaLocator}`;
export const scanVerificationHistory = `${researchHome}/${routePaths.operations.research.scanVerificationHistory}`;
export const scanVerificationResultHistory = `${researchHome}/${routePaths.operations.research.scanVerificationResultHistory}`;
export const correctiveActionsDetails = `${researchHome}/${routePaths.operations.research.correctiveActionsDetails}`;
export const inboundOutboundReports = `${researchHome}/${routePaths.operations.research.inboundOutboundReports}`;
export const openMediaExceptionDetails = `${researchHome}/${routePaths.operations.research.openMediaExceptionDetails}`;

//Operations -> disasterRecovery
export const searchEmployees = `${disasterRecoveryHome}/${routePaths.operations.disasterRecovery.searchEmployees}`;
export const detailEmployees = `${disasterRecoveryHome}/${routePaths.operations.disasterRecovery.detailEmployees}`;
export const drDocument = `${disasterRecoveryHome}/${routePaths.operations.disasterRecovery.drDocument}`;

export const omDetails = `${researchHome}/${routePaths.operations.research.openMediaDetails}`;

export const mediaDetails = `${researchHome}/${routePaths.operations.research.mediaDetails}`;

export const getEmployeeDetailsPath = (employeeId) => {
    return generatePath(detailEmployees, { employeeId });
};

export const getAuditResultPath = (id, completionDate, auditType) => {
    return generatePath(completedLibraryAuditResult, { id, completionDate, auditType });
};
export const getScanVerificationResultHistoryPath = (verificationId) => {
    return generatePath(scanVerificationResultHistory, { verificationId });
};

export const getCorrectiveActionPath = (carId) => {
    return generatePath(correctiveActionsDetails, { carId });
};
export const getContainerDetailPath = ({ mediaId, districtId = "", state }) => {
    const path = generatePath(containerDetail, { mediaId });
    const searchParams = createSearchParams();
    if (districtId) {
        searchParams.append("districtId", districtId);
    }
    return `${path}?${searchParams.toString()}`;
};

export const getOpenMediaDetailPath = ({
    mediaId,
    districtId = "",
    currentStatus = "",
    drpCode = "",
    logicalVault = "",
    mediaDescription = "",
    mediaNumber = "",
    returnDateFrom = "",
    returnDateTo = "",
    customerNumber = "",
}) => {
    const paramValues = {
        districtId,
        currentStatus,
        drpCode,
        logicalVault,
        mediaDescription,
        mediaNumber,
        returnDateFrom,
        customerNumber,
        returnDateTo,
    };
    const searchParams = createSearchParams();

    Object.keys(paramValues).forEach((paramKey) => {
        if (paramValues[paramKey]) {
            searchParams.append(paramKey, paramValues[paramKey]);
        }
    });

    const path = generatePath(openMediaDetail, { mediaId });
    return `${path}?${searchParams.toString()}`;
};

export const getOpenMediaExceptionData = ({
    customerID = "",
    fileSectionTypeId = "",
    exceptionTypes = "",
    detectedDateFrom = "",
    detectedDateTo = "",
    logicalVaultCode = "",
    districtId = "",
}) => {
    const paramValues = {
        customerID,
        districtId,
        fileSectionTypeId,
        exceptionTypes,
        detectedDateFrom,
        detectedDateTo,
        logicalVaultCode,
    };
    const searchParams = createSearchParams();
    Object.keys(paramValues).forEach((paramKey) => {
        if (paramValues[paramKey]) {
            searchParams.append(paramKey, paramValues[paramKey]);
        }
    });
    return `${openMediaExceptionDetails}?${searchParams.toString()}`;
};

export const getSearchMediaDiscrepancy = ({
    customerId = "",
    fileSectionTypeId = "",
    detectedDateFrom = "",
    detectedDateTo = "",
    logicalVaultCode = "",
    mediaProgramTypeId = "",
    detectedInFormattedName = "",
    discrepancyStatus = "",
    districtId = "",
    fromHomePage = "",
}) => {
    const paramValues = {
        customerId,
        fileSectionTypeId,
        detectedDateFrom,
        detectedDateTo,
        logicalVaultCode,
        mediaProgramTypeId,
        detectedInFormattedName,
        discrepancyStatus,
        districtId,
        fromHomePage,
    };
    const searchParams = createSearchParams();
    Object.keys(paramValues).forEach((paramKey) => {
        if (paramValues[paramKey]) {
            searchParams.append(paramKey, paramValues[paramKey]);
        }
    });
    return `${discrepancyManagement}?${searchParams.toString()}`;
};

export const getMediaDetailsPath = () => {
    return generatePath(mediaDetails);
};
