/* eslint-disable max-lines */
import { useState, useEffect, useMemo } from "react";
import { Box, Button, Container, Typography, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styles from "./DiscrepancyManagement.styles";
import { getAllowedCustomers, getSelectedCustomer } from "src/redux/userDetailsSlice";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import CustomerListDropdown from "src/components/AppComponents/Header/CustomerListDropdown/CustomerListDropdown";
import {
    discrepancyStatusList,
    discrepancyStatusValues,
    mediaOptionsDCValue,
    mediaOptionsList,
} from "src/constants/discrepancyConstants";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getDMdropdownService, getLogicalVaultService, getDrManagementSearch } from "src/services/researchService";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { allOption, exportSearchAttributeNames, getAllCaps, getAllValue } from "src/constants/researchConstants";
import { useSecureSyncNavigate } from "src/customHooks";
import {
    capitalize1stLetter,
    detectedInDiscrepancySort,
    getDetectedInTranslatedArray,
    getDiscrepancyData,
    getDiscrepancyTypeTranslatedArray,
    getPayload,
    getSelectedDetectedInData,
    sortContainerGrid,
    sortOpenMediaGrid,
} from "src/utils/researchHelper";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import SecureSyncMultiSelect from "src/components/common/SecureSyncMultiSelect/SecureSyncMultiSelect";
import { formatDate, getCurrentDateTime, getDisplayFormat, startOfTheDate } from "src/utils/dateHelper";
import { dateTimeUnits } from "src/constants/dateFormatConstants";
import SearchMediaTable from "src/components/Operations/Research/DiscrepancyManagement/SearchMediaTable/SearchMediaTable";
import MediaDetails from "src/components/Operations/Research/DiscrepancyManagement/MediaDetails/MediaDetails";
import { retrieveGlobalAttribute } from "src/services/homeService";
import { filterData } from "src/utils/commonHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { yesFlag } from "src/constants/serviceConstants";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { discrepancySearchReport } from "src/services/printServices";

const validationSchema = ({ t }) => {
    const maxDate = dayjs(getCurrentDateTime());
    const minDate = dayjs(getCurrentDateTime()).subtract(1, dateTimeUnits.year).startOf(dateTimeUnits.day);

    const displayMaxDate = formatDate(maxDate);
    const displayMinDate = formatDate(minDate);
    return Yup.object().shape({
        detectedDateFrom: Yup.string().test("validateStartDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isToDateValid = dayjs(parent.detectedDateTo, getDisplayFormat()).isValid();
            const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                dayjs(parent.detectedDateTo, getDisplayFormat())
            );
            const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(
                dayjs(displayMinDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: parent.detectedDateTo,
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && parent.detectedDateTo) {
                message = t("messages.requiredField", { fieldName: t("formFields.detectedDateFrom") });
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        detectedDateTo: Yup.string().test("validateEndDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(
                dayjs(displayMaxDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && parent.detectedDateFrom) {
                message = t("messages.requiredField", { fieldName: t("formFields.detectedDateTo") });
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        resolvedDateFrom: Yup.string().test("validateResolvedFromDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isToDateValid = dayjs(parent.resolvedDateTo, getDisplayFormat()).isValid();
            const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                dayjs(parent.resolvedDateTo, getDisplayFormat())
            );
            const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(
                dayjs(displayMinDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: parent.resolvedDateTo,
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && parent.resolvedDateTo) {
                message = t("messages.requiredField", { fieldName: t("formFields.detectedDateFrom") });
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        resolvedDateTo: Yup.string().test("validateResolvedToDate", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(
                dayjs(displayMaxDate, getDisplayFormat())
            );
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            } else if (!value && parent.resolvedDateFrom) {
                message = t("messages.requiredField", { fieldName: t("formFields.detectedDateTo") });
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const initValues = {
    customer: getAllValue,
    media: getAllCaps,
    discrepancyStatus: discrepancyStatusValues.unresolved,
    logicalVault: getAllValue,
    discrepancyType: "",
    detectedIn: [],
    detectedDateFrom: "",
    detectedDateTo: "",
    resolvedDateFrom: "",
    resolvedDateTo: "",
    mediaNumber: "",
    districtId: "",
};

const initialSelectedMedia = { show: false, selectedMedia: {}, otherMediaRecords: [], type: "" };

const DiscrepancyManagement = () => {
    const { t, i18n } = useTranslation();

    const { goBack } = useSecureSyncNavigate();
    const [params] = useSearchParams();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const customerLists = useSelector(getAllowedCustomers);
    const [allLogicalVaultData, setAllLogicalVaultData] = useState([]);
    const [detectedMedia, setDetectedInMedia] = useState([]);
    const [detectedContainer, setDetectedInContainer] = useState([]);
    const [dMMedia, setDMMedia] = useState([]);
    const [dMContainer, setDMContainer] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [openMediaRecords, setOpenMediaRecords] = useState([]);
    const [containerRecords, setContainerRecords] = useState([]);
    const [showGoBackBtn, setShowGoBackBtn] = useState(false);
    const [selectedMediaRecords, setSelectedMediaRecords] = useState(initialSelectedMedia);
    const [globalMediaAttribute, setGlobalMediaAttribute] = useState({ globalMediaAt: [] });
    const [errorAlert, setErrorAlert] = useState("");
    const [printPayload, setPrintPayload] = useState(null);
    const [printModalShow, setPrintModalShow] = useState(false);

    useEffect(() => {
        const fetchDropdownsAndInitialValue = async () => {
            setLoading(true);
            const [dropDownRes, retrievedAttribute] = await Promise.all([
                getDMdropdownService(),
                retrieveGlobalAttribute({}),
            ]);

            if (!retrievedAttribute.failure) {
                setGlobalMediaAttribute(retrievedAttribute);
            }
            const containerRes = dropDownRes?.results[1] ? getDetectedInTranslatedArray(dropDownRes.results[1]) : [];
            const mediaRes = dropDownRes?.results[2] ? getDetectedInTranslatedArray(dropDownRes.results[2]) : [];
            const dMContainerRes = dropDownRes?.results[3]
                ? getDiscrepancyTypeTranslatedArray(dropDownRes.results[3], "container")
                : [];
            const dMMediaRes = dropDownRes?.results[4]
                ? getDiscrepancyTypeTranslatedArray(dropDownRes.results[4], "openMedia")
                : [];

            setDetectedInContainer(containerRes);
            setDetectedInMedia(mediaRes);
            setDMContainer(dMContainerRes);
            setDMMedia(dMMediaRes);

            if (params.size > 0) {
                const customerId = params.get("customerId");
                const districtId = params.get("districtId");
                const mediaType = params.get("mediaProgramTypeId");

                const logicalVaultRes = await getLogicalVaultService(customerId, districtId);
                const logicalVaults = logicalVaultRes.results[0] ? logicalVaultRes.results[0] : [];
                let logicalVaultId = params.get("fromHomePage") && params.get("fromHomePage") === yesFlag ? "" : "-1";
                if (params.get("logicalVaultCode") && params.get("logicalVaultCode") !== "-1") {
                    const logicalVaultValue = logicalVaults.filter(
                        (lv) => lv.logical_vault_code.trim() === params.get("logicalVaultCode").trim()
                    );
                    logicalVaultId = logicalVaultValue[0]?.logical_vault_id;
                }

                let detectedInMediaArr = [];
                let discrepancyArr = [];
                if (mediaType === getAllCaps) {
                    detectedInMediaArr = [...mediaRes, ...containerRes];
                    discrepancyArr = [...dMMediaRes, ...dMContainerRes];
                } else if (mediaType === mediaOptionsDCValue.container) {
                    detectedInMediaArr = containerRes;
                    discrepancyArr = dMContainerRes;
                } else {
                    detectedInMediaArr = mediaRes;
                    discrepancyArr = dMMediaRes;
                }
                const newObj = {
                    customer: customerId || getAllValue,
                    media: params.get("mediaProgramTypeId") || getAllCaps,
                    discrepancyStatus:
                        params.get("discrepancyStatus") === getAllCaps
                            ? getAllCaps
                            : params.get("discrepancyStatus") === discrepancyStatusValues.resolved
                              ? discrepancyStatusValues.resolved
                              : "" || discrepancyStatusValues.unresolved,
                    discrepancyType: getAllValue,
                    detectedDateFrom: params.get("detectedDateFrom") || "",
                    detectedDateTo: params.get("detectedDateTo") || "",
                    resolvedDateFrom: "",
                    resolvedDateTo: "",
                    mediaNumber: "",
                    districtId: districtId || "",
                    detectedIn: [params.get("detectedInFormattedName")],
                    logicalVault: logicalVaultId,
                };
                await loadLogicalVault(customerId, districtId);
                await setValues({ ...newObj, logicalVault: logicalVaultId === "-1" ? getAllValue : logicalVaultId });
                await formSubmit(newObj, discrepancyArr, detectedInMediaArr);
                setShowGoBackBtn(true);
            } else {
                await setIntValues();
            }
            setLoading(false);
        };
        fetchDropdownsAndInitialValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        setErrors,
    } = useFormik({
        initialValues: initValues,
        validationSchema: validationSchema({ t }),
        onSubmit: async (values) => {
            await formSubmit();
        },
    });

    const { discrepancyArray, discrepancyTypeValues } = useMemo(() => {
        const dMMediaArr = dMMedia.sort(detectedInDiscrepancySort);
        const dMContainerArr = dMContainer.sort(detectedInDiscrepancySort);

        let mergedValues = [];
        if (values.media === getAllCaps) {
            mergedValues = [...dMMediaArr, ...dMContainerArr];
        } else if (values.media === mediaOptionsDCValue.container) {
            mergedValues = dMContainerArr;
        } else {
            mergedValues = dMMediaArr;
        }

        const uniqueArr = Array.from(
            new Set(mergedValues.map((item) => item.translatedName)) // Extracting unique formatted names
        ).map((uniqueName, index) => {
            const foundItem = mergedValues.find((item) => item.translatedName === uniqueName);
            return {
                id: index,
                label: foundItem.translatedName,
                value: `${index}-${foundItem.uniqueid}`,
                formattedName: foundItem.formattedname,
            };
        });

        setFieldValue("discrepancyType", allOption().value);
        return { discrepancyArray: mergedValues, discrepancyTypeValues: [allOption(), ...uniqueArr] };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.media, dMMedia, dMContainer]);

    const { detectedInArray, detectedInValues } = useMemo(() => {
        const detectedMediaArr = detectedMedia.sort(detectedInDiscrepancySort);
        const detectedContainerArr = detectedContainer.sort(detectedInDiscrepancySort);

        let mergedValues = [];
        if (values.media === getAllCaps) {
            mergedValues = [...detectedMediaArr, ...detectedContainerArr];
        } else if (values.media === mediaOptionsDCValue.container) {
            mergedValues = detectedContainerArr;
        } else {
            mergedValues = detectedMediaArr;
        }

        const uniqueArr = Array.from(
            new Set(
                mergedValues.map((item) => item.translatedName) // Extracting unique formatted names
            )
        ).map((uniqueName) => uniqueName);

        if (params.get("detectedInFormattedName")) {
            setFieldValue("detectedIn", [params.get("detectedInFormattedName")]);
        } else {
            setFieldValue("detectedIn", [...uniqueArr]);
        }
        return { detectedInArray: mergedValues, detectedInValues: [...uniqueArr] };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.media, detectedMedia, detectedContainer]);

    const { maxDate, minDate } = useMemo(() => {
        return {
            maxDate: dayjs(getCurrentDateTime()),
            minDate: dayjs(getCurrentDateTime()).subtract(1, dateTimeUnits.year),
        };
    }, []);

    const searchMaxDisplay = useMemo(() => {
        const searchMaxDisplay = filterData(
            globalMediaAttribute.globalMediaAt,
            "global_attribute_type_descr",
            exportSearchAttributeNames.searchMaxDisplay
        );

        return parseInt(searchMaxDisplay[0]?.global_attribute_value) || 0;
    }, [globalMediaAttribute.globalMediaAt]);

    const getDefaultLogicalVault = useMemo(() => {
        if (params.get("customerId") === values.customer) {
            if (params.get("logicalVaultCode") && params.get("logicalVaultCode") !== "-1") {
                return params.get("logicalVaultCode");
            } else if (params.get("logicalVaultCode") === "-1") {
                return "";
            }
        }
        return getAllCaps;
    }, [params, values.customer]);

    const formSubmit = async (params, discrepancyArr, detectedInArr) => {
        setLoading(true);
        setErrorAlert("");

        const payload = getPayload(
            params ? params : values,
            allCustomers,
            discrepancyArr ? discrepancyArr : discrepancyArray,
            detectedInArr ? detectedInArr : detectedInArray,
            discrepancyTypeValues
        );
        const res = await getDrManagementSearch(payload);
        if (!res.failure) {
            if (!res.results[0]?.[0]?.hasError && !res.results[1]?.[0]?.hasError) {
                const modifiedOpenMediaResult = res.results[0].map((item) => {
                    const customerDetails = customerLists.filter(
                        (customer) => customer.customer_id === item.customer_id
                    );
                    return { ...item, districtName: customerDetails[0]?.district_name };
                });
                setOpenMediaRecords(sortOpenMediaGrid(modifiedOpenMediaResult));
                const modifiedSearchContainerResult = res.results[1].map((item) => {
                    const customerDetails = customerLists.filter(
                        (customer) => customer.customer_id === item.customer_id
                    );
                    return { ...item, districtName: customerDetails[0]?.district_name };
                });
                setContainerRecords(sortContainerGrid(modifiedSearchContainerResult, "discrepancy_Type_Code", "asc"));
            } else {
                setErrorAlert(t("apiErrorMessages.80168", { searchMaxDisplay: searchMaxDisplay }));
            }
        }
        setLoading(false);
    };

    const resetTable = () => {
        setContainerRecords([]);
        setOpenMediaRecords([]);
    };

    const setIntValues = () => {
        loadLogicalVault(selectedCustomer.customer_id, selectedCustomer.district_id);
        setValues({
            ...values,
            customer: selectedCustomer.customer_id,
            media: getAllCaps,
            discrepancyStatus: discrepancyStatusValues.unresolved,
            logicalVault: getAllValue,
            discrepancyType: getAllValue,
            detectedIn: detectedInValues,
            detectedDateFrom: "",
            detectedDateTo: "",
            resolvedDateFrom: "",
            resolvedDateTo: "",
            mediaNumber: "",
            districtId: selectedCustomer.district_id,
        });
        resetTable();
        setErrors({});
        setErrorAlert("");
    };

    const allCustomers = useMemo(() => {
        return customerLists.map((item) => item.customer_id).join(",");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLogicalVault = async (customer = values.customer, district = values.districtId) => {
        setLoading(true);
        const logicalVaultRes = await getLogicalVaultService(customer, district);

        if (!logicalVaultRes.failure) {
            setAllLogicalVaultData(logicalVaultRes.results[0]);
        }
        setLoading(false);
    };

    const logicalVaultDropdownValues = useMemo(() => {
        const logicalVaultsDownList = allLogicalVaultData
            .filter((lv) => {
                if (values.media === mediaOptionsDCValue.openMedia) {
                    return lv.container_media_flag === "M";
                } else if (values.media === mediaOptionsDCValue.container) {
                    return lv.container_media_flag === "C";
                }
                return lv;
            })
            .sort((a, b) => {
                return a.logical_vault_code > b.logical_vault_code
                    ? 1
                    : b.logical_vault_code > a.logical_vault_code
                      ? -1
                      : 0;
            })
            .map((e) => {
                return {
                    id: parseInt(e.logical_vault_id),
                    label: e.logical_vault_code ? e.logical_vault_code : "",
                    value: e.logical_vault_id,
                };
            });
        return [allOption(), ...logicalVaultsDownList];
    }, [allLogicalVaultData, values.media]);
    const handleCustomerChange = (e) => {
        setFieldValue("customer", e.target.value);
        const filteredCustomer = customerLists?.filter(
            (customer) => customer.customer_id.toString() === e.target.value
        );
        resetTable();
        setFieldValue("districtId", filteredCustomer[0]?.district_id);
        loadLogicalVault(e.target.value, filteredCustomer[0]?.district_id);
        setFieldValue("logicalVault", getAllValue);
    };

    const handleMediaTypeChange = (e) => {
        setFieldValue("media", e.target.value);
        setFieldValue("discrepancyType", getAllValue);
        setFieldValue("detectedIn", getAllValue);
        setFieldValue("logicalVault", getAllValue);
        resetTable();
    };

    const onChangeDetectedIn = (detectedInArr) => {
        setFieldValue("detectedIn", detectedInArr);
    };

    const navigateToDetails = (media, type) => {
        setSelectedMediaRecords({ show: true, selectedMedia: media, type: type });
    };

    const closeDetails = () => {
        setSelectedMediaRecords(initialSelectedMedia);
    };

    const printReport = () => {
        const selectedMediaUI = mediaOptionsList().filter((element) => element.value === values.media);
        const logicalVaultUI = logicalVaultDropdownValues.filter((element) => element.value === values.logicalVault);
        const discrepancyTypeUI = discrepancyTypeValues.filter((element) => element.value === values.discrepancyType);
        const detectedInUI =
            values.detectedIn.length === detectedInValues.length
                ? capitalize1stLetter(t("common.all"))
                : values.detectedIn.map((element) => element).join(", ");
        const { detectedInIds, detectedInDecs, detectedInContexts } = getSelectedDetectedInData(
            detectedInArray,
            values.detectedIn
        );
        const { uniqueIds, alternateId, idContext } = getDiscrepancyData(
            discrepancyArray,
            values.discrepancyType,
            discrepancyTypeValues
        );
        const payload = discrepancySearchReport({
            customerIdList: values.customer === getAllValue ? allCustomers : values.customer,
            mediaProgramType: values.media === getAllValue ? getAllCaps : values.media,
            discrepancyStatus: values.discrepancyStatus === getAllValue ? getAllCaps : values.discrepancyStatus,
            logicalVaultId: values.logicalVault === getAllValue ? "" : values.logicalVault,
            fromDetectedDate: values.detectedDateFrom ? startOfTheDate(values.detectedDateFrom) : "",
            toDetectedDate: values.detectedDateTo ? startOfTheDate(values.detectedDateTo) : "",
            fromResolvedDate: values.resolvedDateFrom ? startOfTheDate(values.resolvedDateFrom) : "",
            toResolvedDate: values.resolvedDateTo ? startOfTheDate(values.resolvedDateTo) : "",
            mediaNo: values.mediaNumber,
            uiDiscrepancyType: capitalize1stLetter(
                discrepancyTypeUI[0]?.label ? discrepancyTypeUI[0].label : t("common.all")
            ),
            uiMedia: capitalize1stLetter(selectedMediaUI[0]?.label ? selectedMediaUI[0].label : t("common.all")),
            uiLogicalVault: capitalize1stLetter(logicalVaultUI[0]?.label ? logicalVaultUI[0].label : t("common.all")),
            uiDetectedIn: detectedInUI,
            detectedInIds,
            detectedInDecs,
            detectedInContexts,
            uniqueIds,
            alternateId,
            idContext,
            culture: i18n.language,
        });
        setPrintPayload(payload);
        setPrintModalShow(true);
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            {selectedMediaRecords.show ? (
                <MediaDetails
                    onClose={closeDetails}
                    selectedRecord={selectedMediaRecords.selectedMedia}
                    otherMediaDetails={
                        selectedMediaRecords.type === mediaOptionsDCValue.openMedia
                            ? openMediaRecords
                            : containerRecords
                    }
                    type={selectedMediaRecords.type}
                />
            ) : (
                <Container>
                    <Box sx={styles.mainContainer}>
                        <Box sx={styles.innerContainer}>
                            <PageTitle>{t("titles.discrepancySearch")}</PageTitle>
                            {errorAlert && (
                                <SecureSyncAlert
                                    severity="error"
                                    message={errorAlert}
                                    onClose={() => {
                                        setErrorAlert("");
                                    }}
                                />
                            )}
                            <Typography sx={styles.filterDesc}>
                                <Trans
                                    i18nKey={"discrepanctManagement.completeOpenDiscrepancy"}
                                    values={{ buttonLabel: t("buttonLabels.find") }}
                                />
                            </Typography>
                            <Box sx={styles.contactFieldContainer}>
                                <Box sx={styles.customerDropdown}>
                                    <CustomerListDropdown
                                        id="login-page"
                                        label={t("formFields.customer")}
                                        name="customer"
                                        type="outlined"
                                        displayEmpty={false}
                                        value={values.customer}
                                        onChange={handleCustomerChange}
                                        onBlur={handleBlur}
                                        error={touched.customer && Boolean(errors.customer)}
                                        helperText={touched.customer && errors.customer}
                                        enableAll
                                    />
                                </Box>

                                <SecureSyncSelect
                                    label={t("formFields.media")}
                                    defaultOption={getAllCaps}
                                    options={mediaOptionsList()}
                                    name="media"
                                    value={values.media}
                                    onChange={handleMediaTypeChange}
                                />

                                <SecureSyncSelect
                                    label={t("formFields.discrepancyStatus")}
                                    defaultOption={getAllCaps}
                                    options={discrepancyStatusList()}
                                    name="discrepancyStatus"
                                    value={values.discrepancyStatus}
                                    onChange={(e) => {
                                        handleChange(e);
                                        resetTable();
                                    }}
                                />
                                <Box sx={styles.logicalVault}>
                                    <SecureSyncSelect
                                        label={t("formFields.logicalVault")}
                                        defaultOption={getDefaultLogicalVault}
                                        options={logicalVaultDropdownValues}
                                        name="logicalVault"
                                        value={values.logicalVault}
                                        onChange={(e) => {
                                            handleChange(e);
                                            resetTable();
                                        }}
                                        disabled={values.customer === getAllValue}
                                    />
                                </Box>

                                <SecureSyncSelect
                                    label={t("formFields.discrepancyType")}
                                    defaultOption={getAllCaps}
                                    options={discrepancyTypeValues}
                                    name="discrepancyType"
                                    value={values.discrepancyType}
                                    onChange={(e) => {
                                        handleChange(e);
                                        resetTable();
                                    }}
                                />

                                <SecureSyncMultiSelect
                                    label={t("formFields.detectedIn")}
                                    options={detectedInValues}
                                    name="detectedIn"
                                    value={values.detectedIn}
                                    onChange={(e) => onChangeDetectedIn(e.target.value)}
                                />
                                <div />

                                <SecureSyncDatePicker
                                    disableFuture
                                    value={
                                        values.detectedDateFrom
                                            ? dayjs(values.detectedDateFrom, getDisplayFormat())
                                            : ""
                                    }
                                    label={t("formFields.detectedDateFrom")}
                                    helperText={touched.detectedDateFrom && errors.detectedDateFrom}
                                    error={Boolean(touched.detectedDateFrom && errors.detectedDateFrom)}
                                    onChange={async (value) => {
                                        await setFieldValue("detectedDateFrom", value);
                                        setFieldTouched("detectedDateFrom", true, true);
                                    }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />

                                <SecureSyncDatePicker
                                    disableFuture
                                    value={
                                        values.detectedDateTo ? dayjs(values.detectedDateTo, getDisplayFormat()) : ""
                                    }
                                    label={t("formFields.detectedDateTo")}
                                    helperText={touched.detectedDateTo && errors.detectedDateTo}
                                    error={Boolean(touched.detectedDateTo && errors.detectedDateTo)}
                                    onChange={async (value) => {
                                        await setFieldValue("detectedDateTo", value);
                                        setFieldTouched("detectedDateTo", true, true);
                                    }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />

                                <SecureSyncDatePicker
                                    disableFuture
                                    value={
                                        values.resolvedDateFrom
                                            ? dayjs(values.resolvedDateFrom, getDisplayFormat())
                                            : ""
                                    }
                                    label={t("formFields.resolvedDateFrom")}
                                    helperText={touched.resolvedDateFrom && errors.resolvedDateFrom}
                                    error={Boolean(touched.resolvedDateFrom && errors.resolvedDateFrom)}
                                    onChange={async (value) => {
                                        await setFieldValue("resolvedDateFrom", value);
                                        setFieldTouched("resolvedDateFrom", true, true);
                                    }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />

                                <SecureSyncDatePicker
                                    disableFuture
                                    value={
                                        values.resolvedDateTo ? dayjs(values.resolvedDateTo, getDisplayFormat()) : ""
                                    }
                                    label={t("formFields.resolvedDateTo")}
                                    helperText={touched.resolvedDateTo && errors.resolvedDateTo}
                                    error={Boolean(touched.resolvedDateTo && errors.resolvedDateTo)}
                                    onChange={async (value) => {
                                        await setFieldValue("resolvedDateTo", value);
                                        setFieldTouched("resolvedDateTo", true, true);
                                    }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />

                                <TextField
                                    label={`${t("formFields.mediaDR")}`}
                                    name="mediaNumber"
                                    variant="outlined"
                                    inputProps={{ maxLength: 20 }}
                                    value={values.mediaNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.mediaNumber && Boolean(errors.mediaNumber)}
                                    helperText={touched.mediaNumber && errors.mediaNumber}
                                />
                            </Box>
                        </Box>
                        <Box sx={styles.buttonContainer}>
                            {showGoBackBtn && (
                                <Button
                                    variant="outlined"
                                    onClick={() => goBack(vaultStatusReport)}
                                    startIcon={<IconLeft />}
                                >
                                    {t("buttonLabels.back")}
                                </Button>
                            )}
                            <Button variant="outlined" color="primary" onClick={setIntValues}>
                                {t("buttonLabels.clear")}
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                {t("buttonLabels.find")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={openMediaRecords.length === 0 && containerRecords.length === 0}
                                onClick={printReport}
                            >
                                {t("buttonLabels.print")}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={styles.tableContainer}>
                        <Box>
                            <SearchMediaTable
                                records={openMediaRecords}
                                isSearched={true}
                                type="media"
                                navigateToDetails={(media) => navigateToDetails(media, mediaOptionsDCValue.openMedia)}
                            />
                        </Box>
                        <Box>
                            <SearchMediaTable
                                records={containerRecords}
                                isSearched={true}
                                type="container"
                                navigateToDetails={(media) => navigateToDetails(media, mediaOptionsDCValue.container)}
                            />
                        </Box>
                    </Box>
                </Container>
            )}
            <SecureSyncPrintModal
                open={printModalShow}
                payload={printPayload}
                onClose={() => setPrintModalShow(false)}
                targetReport={targetUrl.discrepancySearchReport}
            />
        </>
    );
};

export default DiscrepancyManagement;
