import { Alert, Box, Button, Container, Divider, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useOktaAuth } from "@okta/okta-react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import styles from "./PostLoginCheck.styles";
import ErrorPage from "src/components/AppComponents/ErrorPage/ErrorPage";
import CustomerListDropdown from "src/components/AppComponents/Header/CustomerListDropdown/CustomerListDropdown";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import { useFetchSecurityQuestions, useSecureSyncHotKeys } from "src/customHooks";
import { getSupportedLanguageDetails } from "src/redux/languageSlice";
import {
    clearUserDetails,
    getAllowedCustomers,
    getBasicUserInfo,
    getOnHoldCustomers,
    getUserDetails,
    getUserDetailsErrorMessage,
    setIsUserPreferredLanguageSet,
    updateSelectedCustomer,
} from "src/redux/userDetailsSlice";
import { updateLoginPreferences } from "src/services/userServices";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { sortHelper } from "src/utils/sortHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t, checkOnHoldActiveCustomer, allowedCustomersLength) =>
    Yup.object().shape({
        account: Yup.string().when("userFlags", {
            is: (userFlags) =>
                userFlags &&
                userFlags.show_default_customer_at_login === "Y" &&
                allowedCustomersLength > 1 &&
                checkOnHoldActiveCustomer,
            then: () => Yup.string().required(t("postLoginCheck.selectCustomerAccountIsRequired")),
            otherwise: () => Yup.string().notRequired(),
        }),
        challengeQuestion: Yup.string().when("userFlags", {
            is: (userFlags) =>
                userFlags && userFlags.required_fields_need_validation === "Y" && checkOnHoldActiveCustomer,
            then: () => Yup.string().required(t("messages.challengeQuestionIsrequired")),
            otherwise: () => Yup.string().notRequired(),
        }),
        challengeResponse: Yup.string().when("userFlags", {
            is: (userFlags) =>
                userFlags && userFlags.required_fields_need_validation === "Y" && checkOnHoldActiveCustomer,
            then: () =>
                Yup.string()
                    .required(t("messages.challengeResponseIsRequired"))
                    .matches(/^[a-zA-Z0-9]*$/, t("messages.challengeAndConfirmResponseValidation"))
                    .min(3, t("messages.challengeAndConfirmResponseValidation")),
            otherwise: () => Yup.string().notRequired(),
        }),
        confirmChallengeResponse: Yup.string().when("userFlags", {
            is: (userFlags) =>
                userFlags && userFlags.required_fields_need_validation === "Y" && checkOnHoldActiveCustomer,
            then: () =>
                Yup.string()
                    .oneOf(
                        [Yup.ref("challengeResponse"), null],
                        t("messages.challengeAndConfirmChallengeResponseMustBeTheSame")
                    )
                    .required(t("messages.challengeAndConfirmChallengeResponseMustBeTheSame")),
            otherwise: () => Yup.string().notRequired(),
        }),

        useAsDefaultLogin: Yup.boolean(),
        language: Yup.string(),
        businessEMail: Yup.string(),
    });

const PostLoginCheck = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => handleSubmit() });
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector(getUserDetails);
    const allowedCustomers = useSelector(getAllowedCustomers);
    const basicUserInfo = useSelector(getBasicUserInfo);
    const { t, i18n } = useTranslation();
    const { oktaAuth } = useOktaAuth();
    const onHoldCustomers = useSelector(getOnHoldCustomers);
    const errorMessage = useSelector(getUserDetailsErrorMessage);
    const { data: challengeQuestion } = useFetchSecurityQuestions();
    const checkOnHoldActiveCustomer = allowedCustomers.length === 0 && onHoldCustomers.length !== 0 ? true : false;
    const [questions, setQuestions] = useState([]);
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirectUrl");
    const { data: supportLanguageDetails } = useSelector(getSupportedLanguageDetails);
    const languageOptions = useMemo(() => {
        return supportLanguageDetails.map((lang) => ({
            id: lang.locale_id,
            value: lang.iso_locale_code,
            label: lang.language_display_name,
        }));
    }, [supportLanguageDetails]);
    useEffect(() => {
        const sortedQuestions = Array.isArray(challengeQuestion)
            ? [...challengeQuestion].sort((question1, question2) => question1.display_order - question2.display_order)
            : [];
        const translatedChallengeQuestions = t("challengeQuestion", { returnObjects: true });
        const updatedQuestions = sortedQuestions.map((question) => {
            const translatedChallengeQuestion = translatedChallengeQuestions[question.dropdown_list_id.toString()];
            if (translatedChallengeQuestion) {
                return {
                    ...question,
                    descr: translatedChallengeQuestion,
                };
            }
            return question;
        });
        setQuestions(updatedQuestions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeQuestion, i18n.language]);

    const allowedCustomersLength = allowedCustomers.length;
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setErrors } = useFormik({
        initialValues: {
            name: basicUserInfo.username,
            account: "",
            challengeQuestion: "",
            challengeResponse: "",
            confirmChallengeResponse: "",
            useAsDefaultLogin: false,
            language: i18n.language,
            businessEMail: userDetails.email,
            userFlags: basicUserInfo,
        },
        validationSchema: validationSchema(t, !checkOnHoldActiveCustomer, allowedCustomersLength),
        onSubmit: async (values) => {
            setLoading(true);
            const defaultCustomerLogin = values.useAsDefaultLogin ? "Y" : "N";
            try {
                if (checkOnHoldActiveCustomer) {
                    dispatch(clearUserDetails());
                    setLoading(false);
                    oktaAuth.signOut();
                    navigate(routePaths.signIn);
                } else {
                    await updateLoginPreferences(
                        values.name,
                        values.account,
                        defaultCustomerLogin,
                        values.challengeQuestion,
                        values.challengeResponse
                    );
                    setLoading(false);

                    if (values.account) {
                        const selectedCustomer = allowedCustomers?.find(
                            (customer) => customer.customer_id === values.account
                        );
                        dispatch(
                            updateSelectedCustomer({
                                selectedCustomer: selectedCustomer,
                            })
                        );
                    }

                    dispatch(setIsUserPreferredLanguageSet(true));
                    navigate(redirectUrl || dashboard);
                }
            } catch (error) {
                setLoading(false);
            }
        },
    });

    const columns = [
        {
            field: "customer_number",
            headerName: convertToLocaleUpperCase(t("tableHeader.account")),
            width: 350,
            sortable: true,
        },
        {
            field: "customer_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.name")),
            width: 350,
            sortable: true,
        },
        {
            field: "district_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.branch")),
            flex: 1,
            sortable: true,
        },
    ];

    if (errorMessage) {
        return (
            <ErrorPage title={t("errorPage.error")}>
                <Typography sx={styles.messageSection}>{errorMessage} </Typography>
            </ErrorPage>
        );
    }

    const changeLanguage = (event) => {
        const language = event.target.value;
        if (language) {
            setErrors({});
            i18n.changeLanguage(language);
        }
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container disableGutters>
                <form noValidate onSubmit={handleSubmit}>
                    <Box>
                        {checkOnHoldActiveCustomer && (
                            <Box sx={styles.alert}>
                                <Alert severity="error">
                                    {t("postLoginCheck.customerAccountOnHoldPleaseContactIronMountainCustomerSupport")}
                                </Alert>
                            </Box>
                        )}
                        <div>
                            {!checkOnHoldActiveCustomer && (
                                <div>
                                    <Typography variant="h4" sx={styles.requiredToLoginTitle}>
                                        {t("postLoginCheck.requiredtoLogin")}
                                    </Typography>
                                    <Typography variant="body1" sx={styles.bodyText} />
                                    <Trans
                                        i18nKey={"postLoginCheck.postLoginDesc"}
                                        values={{ buttonLabel: t("common.submit") }}
                                    />
                                    <Divider />
                                </div>
                            )}
                            <Typography variant="h5" sx={styles.sectionTitle}>
                                {t("postLoginCheck.languageAccount")}
                            </Typography>
                            <Box className="flex gap-5 mt-5 w-full tracking-normal max-w-[1300px] max-md:flex-wrap max-md:max-w-full">
                                <Box sx={styles.flexBox}>
                                    <Box sx={styles.formControl}>
                                        <SecureSyncSelect
                                            label={t("postLoginCheck.language")}
                                            options={sortHelper({
                                                data: languageOptions,
                                                primaryProperty: "label",
                                                sortOrder: "asc",
                                            })}
                                            name="language"
                                            value={values.language}
                                            onChange={changeLanguage}
                                            onBlur={handleBlur}
                                        />
                                    </Box>
                                    {!checkOnHoldActiveCustomer &&
                                        basicUserInfo.show_default_customer_at_login === "Y" &&
                                        allowedCustomers.length > 1 && (
                                            <>
                                                <Box sx={styles.formControlWide}>
                                                    <CustomerListDropdown
                                                        id="login-page"
                                                        defaultOption={t("postLoginCheck.selectTheCustomerAccount")}
                                                        showDefaultOption={true}
                                                        label={t("postLoginCheck.selectCustomerAccount")}
                                                        name="account"
                                                        type="outlined"
                                                        required
                                                        displayEmpty={false}
                                                        value={values.account}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={touched.account && Boolean(errors.account)}
                                                        helperText={touched.account && errors.account}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                </Box>
                            </Box>
                            {!checkOnHoldActiveCustomer &&
                                basicUserInfo.allow_default_customer_at_login === "Y" &&
                                allowedCustomers.length > 1 && (
                                    <SecureSyncCheckBox
                                        label={t("postLoginCheck.selectDefaultCustomerAccount")}
                                        name="useAccountAlways"
                                        checked={values.useAsDefaultLogin}
                                        onChange={(event) => setFieldValue("useAsDefaultLogin", event.target.checked)}
                                    />
                                )}
                            <Divider />
                            {!checkOnHoldActiveCustomer && basicUserInfo.required_fields_need_validation === "Y" && (
                                <>
                                    <div>
                                        <Typography variant="h5" sx={styles.sectionTitle}>
                                            {t("postLoginCheck.challengeQuestionResponse")}
                                        </Typography>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <SecureSyncSelect
                                                        id="challenge-question-select"
                                                        label={t("postLoginCheck.challengeQuestion")}
                                                        defaultOption={questions.length > 0 ? questions[0].descr : ""}
                                                        options={questions.slice(1).map((item, index) => ({
                                                            id: index.toString(),
                                                            label: item.descr,
                                                            value: item.dropdown_list_id,
                                                        }))}
                                                        required
                                                        value={values.challengeQuestion}
                                                        name="challengeQuestion"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.challengeQuestion &&
                                                            Boolean(errors.challengeQuestion)
                                                        }
                                                        helperText={
                                                            touched.challengeQuestion && errors.challengeQuestion
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Typography variant="body2" sx={styles.body2Text}>
                                            {t("postLoginCheck.selectAChallengeQuestionAndResponse")}
                                        </Typography>
                                        <Box sx={styles.flexBoxGap}>
                                            <TextField
                                                label={t("formFields.challengeResponse")}
                                                name="challengeResponse"
                                                type="password"
                                                variant="outlined"
                                                value={values.challengeResponse}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required
                                                error={touched.challengeResponse && Boolean(errors.challengeResponse)}
                                                helperText={touched.challengeResponse && errors.challengeResponse}
                                                fullWidth
                                                style={{ flex: 1 }}
                                            />
                                            <TextField
                                                label={t("formFields.confirmChallengeResponse")}
                                                name="confirmChallengeResponse"
                                                type="password"
                                                variant="outlined"
                                                required
                                                value={values.confirmChallengeResponse}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.confirmChallengeResponse &&
                                                    Boolean(errors.confirmChallengeResponse)
                                                }
                                                helperText={
                                                    touched.confirmChallengeResponse && errors.confirmChallengeResponse
                                                }
                                                fullWidth
                                                style={{ flex: 1 }}
                                            />
                                        </Box>
                                    </div>
                                    <Divider />
                                    <Typography variant="h5" sx={styles.sectionTitle}>
                                        {t("formFields.businessEmail")}
                                    </Typography>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth required>
                                                    <TextField
                                                        label={t("formFields.businessEmail")}
                                                        name="businessEMail"
                                                        variant="outlined"
                                                        required
                                                        value={values.businessEMail}
                                                        disabled
                                                        style={{ flex: 1 }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography variant="body2" sx={styles.body2Text}>
                                        {t("postLoginCheck.confirmOrEnterYourBusinessEmailAddress")}
                                    </Typography>
                                    <Divider />
                                </>
                            )}
                        </div>
                        {basicUserInfo.show_on_hold_customers_flag === "Y" && (
                            <>
                                <Typography variant="h5" sx={styles.sectionTitle}>
                                    {t("postLoginCheck.onHoldCustomerAccounts")}
                                </Typography>
                                <Box sx={styles.container}>
                                    <Paper>
                                        <DataGrid
                                            autoHeight
                                            rows={onHoldCustomers}
                                            columns={columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[2, 5, 7]}
                                            disableSelectionOnClick
                                            hideFooterSelectedRowCount
                                            getRowId={(onHoldCustomers) => onHoldCustomers.customer_id}
                                            sx={styles.dataGrid}
                                            slots={{
                                                NoRowsOverlay: () => (
                                                    <Box sx={styles.noRowsOverlay}>
                                                        <Typography variant="body1" color="textSecondary">
                                                            {t("postLoginCheck.noOnHoldCustomers")}
                                                        </Typography>
                                                    </Box>
                                                ),
                                            }}
                                            slotProps={{
                                                pagination: {
                                                    labelRowsPerPage: t("tableHeader.rowsPerPage"),
                                                },
                                            }}
                                        />
                                    </Paper>
                                </Box>
                                <Divider />
                            </>
                        )}

                        <Box />
                        <Box sx={styles.buttonContainer}>
                            <Button
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={styles.submitButton}
                            >
                                {checkOnHoldActiveCustomer ? t("buttonLabels.ok") : t("common.submit")}
                            </Button>
                        </Box>
                        <Box />
                    </Box>
                </form>
            </Container>
        </>
    );
};

export default PostLoginCheck;
