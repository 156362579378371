import PropTypes from "prop-types";

const SecureSyncSupText = ({ name, sup }) => {
    return (
        <>
            {name}
            {sup && <sup>{sup}</sup>}
        </>
    );
};
SecureSyncSupText.propTypes = {
    name: PropTypes.any,
    sup: PropTypes.node,
};
export default SecureSyncSupText;
