import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import DeleteConfirmation from "../ConfirmationModals/DeleteConfirmation/DeleteConfirmation";
import styles from "./DRInstructionTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import RetrieveFile from "src/components/Icons/RetrieveFile/RetrieveFile";
import { getCurrentDateTimeInLocalizedFormat } from "src/utils/dateHelper";
import { insertLoggedEvent } from "src/services/processMediaService";
import { deleteDRInstruction, getDRInstructionFileContent } from "src/services/disasterService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { downloadRTFFile } from "src/utils/drHelper";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { deleteFileFromFB, downloadFileFromFB, getDrInstructionFolderPath, getGsFilePath } from "src/services/utils";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const deleteConfirmationInitial = {
    show: false,
    id: "",
    instructionName: "",
};
const DRInstructionTable = ({ resultData, editPermission, downloadPermission, selectedCustomerId, setResetter }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isLoading, setLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(deleteConfirmationInitial);

    const columns = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {editPermission && (
                            <SecureSyncIconTooltip
                                onClick={() => {
                                    setDeleteConfirmation({
                                        show: true,
                                        id: params.dr_instruction_id,
                                        instructionName: params.dr_instruction_name,
                                    });
                                }}
                                icon={<ClearIcon sx={styles.clearIcon} />}
                                title={t("common.deleteFile")}
                            />
                        )}
                        {downloadPermission && (
                            <SecureSyncIconTooltip
                                onClick={() => {
                                    fetchDownloadDRInstruction(params.dr_instruction_id);
                                }}
                                icon={<RetrieveFile fill={theme.palette.im_mid_blue["500"]} />}
                                title={t("common.retrieveFile")}
                            />
                        )}
                    </>
                );
            },
        },
        {
            field: "dr_instruction_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.fileName")),
            sortable: true,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.dr_instruction_name} arrow>
                        <Box component={"span"} style={styles.renderCell}>
                            {params.dr_instruction_name}
                        </Box>
                    </Tooltip>
                );
            },
        },
        {
            field: "last_mod_date",
            headerName: convertToLocaleUpperCase(t("tableHeader.uploadDateTime")),
            sortable: true,
            renderCell: (params) => {
                return <>{getCurrentDateTimeInLocalizedFormat(params.last_mod_date)}</>;
            },
        },
    ];

    const fetchDeleteDRInstruction = async (id, drInstructionName) => {
        setDeleteConfirmation(deleteConfirmationInitial);
        setLoading(true);
        await deleteDRInstruction({ drInstructionId: id });

        const savedFileName = `${id}_${drInstructionName}`;
        const filePath = getDrInstructionFolderPath(selectedCustomerId, savedFileName);
        await deleteFileFromFB({
            fbFilePath: getGsFilePath(filePath),
            onComplete: async () => {
                const source = "DRFiles.DeleteDRInstruction";
                const description = `Disaster Recovery - Delete Instruction File: ${drInstructionName}  Customer Id: ${selectedCustomerId}`;
                await insertLoggedEvent("", description, source);
                setResetter((prev) => prev + 1);
                setLoading(false);
            },
        });
    };

    const fetchDownloadDRInstruction = async (id) => {
        setLoading(true);
        const result = await getDRInstructionFileContent({ drInstructionId: id });

        if (!result.failure) {
            const fileName = result.fileContent[0].dr_instruction_name;
            if (result.fileContent[0].dr_instruction) {
                await downloadRTFFile(fileName, result.fileContent[0].dr_instruction);
                setLoading(false);
            } else {
                const savedFileName = `${id}_${fileName}`;
                const filePath = getDrInstructionFolderPath(selectedCustomerId, savedFileName);
                downloadFileFromFB({
                    fbFilePath: getGsFilePath(filePath),
                    fileName: fileName,
                    onComplete: () => {
                        setLoading(false);
                    },
                });
            }
        }
    };
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    {resultData.length > 0 ? (
                        <>
                            <Box sx={styles.recordStart}>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: resultData.length })}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Box sx={styles.recordStart} />
                    )}
                </Box>
                <Box sx={styles.cardContent}>
                    <SecureSyncTable rows={resultData} columns={columns} getRowId={(row) => row.id} />
                </Box>
            </SecureSyncTableCard>
            <DeleteConfirmation
                handleConfirm={() =>
                    fetchDeleteDRInstruction(deleteConfirmation.id, deleteConfirmation.instructionName)
                }
                instructionName={deleteConfirmation.instructionName}
                onClose={() => setDeleteConfirmation(deleteConfirmationInitial)}
                open={deleteConfirmation.show}
            />
        </>
    );
};

export default DRInstructionTable;
