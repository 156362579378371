import { Box, Button, SvgIcon, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import styles from "./CorrectiveActionRequest.styles";
import { ReactComponent as Edit } from "src/assets/images/RemoveRedEyeFilled.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import { dateFormats } from "src/constants/dateFormatConstants";
import {
    correctiveActionRequest,
    getCorrectiveActionPath,
} from "src/constants/routeConstants/operationsRouterFullPaths";
import { formatDate, getCurrentDateTime, getDisplayFormat, getMinAndMaxDate } from "src/utils/dateHelper";
import { getCorrectiveActionRequests } from "src/services/researchService";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getCarSearchResultsPayload } from "src/services/printServices";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t }) => {
    const { minDateForApi: minDate, displayMinDate } = getMinAndMaxDate();
    const maxDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const displayMaxDate = formatDate(dayjs());
    return Yup.object().shape({
        correctiveActionRequestDateFrom: Yup.string().test(
            "validateServiceDateFrom",
            (value, { createError, path, parent }) => {
                const isValidDate = dayjs(value, getDisplayFormat()).isValid();
                const isToDateValid = dayjs(parent.correctiveActionRequestDateTo, getDisplayFormat()).isValid();
                const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                    dayjs(parent.correctiveActionRequestDateTo, getDisplayFormat())
                );
                const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(minDate);
                let message = "";
                if (value) {
                    if (!isValidDate) {
                        message = t("messages.invalidDate");
                    } else if (!isBeforeDate && isToDateValid) {
                        message = t("messages.fromDateValidationMsg", {
                            fromDate: formatDate(parent.correctiveActionRequestDateTo),
                        });
                    } else if (isOutSideMinDate) {
                        message = t("messages.correctiveFromDateBetweenTheSpecificDate", {
                            minDate: displayMinDate,
                            maxDate: displayMaxDate,
                        });
                    }
                }

                if (message) {
                    return createError({
                        path,
                        message,
                    });
                } else {
                    return true;
                }
            }
        ),
        correctiveActionRequestDateTo: Yup.string().test("validateServiceDateTo", (value, { createError, path }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(maxDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.correctiveToDateBetweenTheSpecificDate", {
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const initialValues = {
    correctiveActionRequestDateFrom: "",
    correctiveActionRequestDateTo: "",
};

const CorrectiveActionRequest = () => {
    const shortCutKeys = getShortCutKeys();
    const location = useLocation();
    const { state: locationState } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleReset() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.prepareReport, callback: () => printReport() });
    const {
        errors,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        setValues,
        setSubmitting,
        values,
        isSubmitting,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema({ t }),
        onSubmit: () => onFindClick(values, true),
    });
    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
        }
        return newNewPageState;
    };

    const initialSort = (requestData) => {
        requestData.sort((a, b) => {
            const dateA = dayjs(a.Date);
            const dateB = dayjs(b.Date);

            if (dateA.isAfter(dateB)) {
                return -1;
            }
            if (dateA.isBefore(dateB)) {
                return 1;
            }

            if (a.Id > b.Id) {
                return -1;
            }
            if (a.Id < b.Id) {
                return 1;
            }
            return 0;
        });
        return requestData;
    };

    const onFindClick = async (values, isButtonClick) => {
        setSubmitting(true);
        const fromDate = dayjs(values.correctiveActionRequestDateFrom, getDisplayFormat());
        const toDate = dayjs(values.correctiveActionRequestDateTo, getDisplayFormat());
        const correctiveFromDate = formatDate(fromDate, dateFormats.displayDate);
        const correctiveToDate = formatDate(toDate, dateFormats.displayDate);
        const payload = { fromDate: correctiveFromDate, toDate: correctiveToDate };
        const pageState = getPageState({ ...values }, !isButtonClick);
        navigate(correctiveActionRequest, {
            state: pageState,
            replace: true,
        });
        const result = await getCorrectiveActionRequests(payload);
        const sortedData = initialSort(result?.verifiedResultList);
        setRecords(sortedData);
        setSubmitting(false);
        setIsSearched(true);
    };

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(correctiveActionRequest, { state: { ...locationState, ...attributes }, replace: true });
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                field: "edit",
                headerName: "",
                width: 60,
                tooltip: t("messages.viewUserDetailsForAccount"),
                renderCell: (params) => (
                    <SvgIcon
                        component={Edit}
                        onClick={() => {
                            handleEditClick(params);
                        }}
                    />
                ),
            },
            { field: "Id", headerName: convertToLocaleUpperCase(t("tableHeader.id")), sortable: true },
            {
                field: "Date",
                headerName: convertToLocaleUpperCase(t("tableHeader.date")),
                sortable: true,
                type: "date",
                width: 100,
            },
            { field: "Type", headerName: convertToLocaleUpperCase(t("tableHeader.type")), sortable: true },
            {
                field: "Description",
                headerName: convertToLocaleUpperCase(t("tableHeader.description")),
                sortable: true,
            },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setInitialValues = () => {
        const today = getCurrentDateTime();
        const firstDayOfMonth = today.startOf("month");
        setFieldValue("correctiveActionRequestDateFrom", formatDate(firstDayOfMonth));
        setFieldValue("correctiveActionRequestDateTo", formatDate(today));
    };

    useEffect(() => {
        setInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState) {
                const newFormValues = {
                    correctiveActionRequestDateFrom:
                        locationState?.correctiveActionRequestDateFrom || values.correctiveActionRequestDateFrom,
                    correctiveActionRequestDateTo:
                        locationState?.correctiveActionRequestDateTo || values.correctiveActionRequestDateTo,
                };
                await setValues(newFormValues);
                onFindClick(newFormValues);
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditClick = (row) => {
        // Navigate to the edit page and pass the row data
        navigate(getCorrectiveActionPath(row?.Id));
    };

    const handleReset = () => {
        navigate(correctiveActionRequest, { state: { ...locationState, orderBy: "" }, replace: true });
        setInitialValues();
        setRecords([]);
        setIsSearched(false);
    };

    const printReport = () => {
        const fromDate = dayjs(values.correctiveActionRequestDateFrom, getDisplayFormat());
        const toDate = dayjs(values.correctiveActionRequestDateTo, getDisplayFormat());
        const correctiveFromDate = formatDate(fromDate, dateFormats.requestServiceDateFormat);
        const correctiveToDate = formatDate(toDate, dateFormats.requestServiceDateFormat);
        setPrintPayload(
            getCarSearchResultsPayload({
                fromDate: correctiveFromDate,
                toDate: correctiveToDate,
            })
        );
        setIsPrintModalOpen(true);
    };

    return (
        <>
            <Box>
                <CircularLoaderFullPage show={isSubmitting} />
                <PageTitle>{t("titles.searchCorrectiveActionRequest")}</PageTitle>
                <Typography sx={styles.description}>
                    <Trans
                        i18nKey={"correctiveActionRequest.searchCriteria"}
                        values={{ buttonLabel: t("buttonLabels.find") }}
                    />
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box sx={styles.searchFields}>
                        <SecureSyncDatePicker
                            name="correctiveActionRequestDateFrom"
                            required
                            value={values.correctiveActionRequestDateFrom}
                            label={t("formFields.correctiveActionRequestDateFrom")}
                            helperText={errors.correctiveActionRequestDateFrom}
                            error={Boolean(errors.correctiveActionRequestDateFrom)}
                            onBlur={handleBlur}
                            onChange={async (value) => {
                                await setFieldValue("correctiveActionRequestDateFrom", value);
                                setFieldTouched("correctiveActionRequestDateFrom", true, true);
                            }}
                            disableFuture
                        />
                        <SecureSyncDatePicker
                            name="correctiveActionRequestDateTo"
                            value={values.correctiveActionRequestDateTo}
                            label={t("formFields.correctiveActionRequestDateTo")}
                            required
                            helperText={errors.correctiveActionRequestDateTo}
                            error={Boolean(errors.correctiveActionRequestDateTo)}
                            onBlur={handleBlur}
                            onChange={async (value) => {
                                await setFieldValue("correctiveActionRequestDateTo", value);
                                setFieldTouched("correctiveActionRequestDateTo", true, true);
                            }}
                            disableFuture
                        />
                    </Box>
                    <Box sx={styles.buttonContainer}>
                        <Button
                            id="clear"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                handleReset();
                            }}
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                        >
                            {t("buttonLabels.clear")}
                        </Button>
                        <Button
                            id="find-all"
                            variant="contained"
                            color="primary"
                            type="submit"
                            title={getKeyBoardLabel(shortCutKeys.search)}
                        >
                            {t("buttonLabels.find")}
                        </Button>
                    </Box>
                </form>
                <SecureSyncTableCard>
                    {records?.length > 0 && (
                        <Box sx={styles.recordCount}>
                            <>
                                <Box sx={styles.recordStart}>
                                    <Typography variant="body1">
                                        {t("messages.recordsFound", { count: records.length })}
                                    </Typography>
                                </Box>
                                <Box sx={styles.recordEnd}>
                                    <Button
                                        onClick={() => {
                                            printReport();
                                        }}
                                        variant="outlined"
                                        title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                    >
                                        {t("buttonLabels.prepareReport")}
                                    </Button>
                                </Box>
                            </>
                        </Box>
                    )}
                    <SecureSyncTable
                        rows={records}
                        columns={columns}
                        initialPage={location.state?.pageNumber}
                        initialRowsPerPage={location.state?.rowsPerPage}
                        initialOrderBy={location.state?.orderBy || ""}
                        initialOrder={location.state?.order}
                        noDataMessage={
                            isSearched ? t("messages.noCorrectiveActionRequests") : t("messages.pleaseProvideTheInput")
                        }
                        getRowId={(row) => row.Request_Id}
                        onTableAttributesChange={onTableStateChange}
                    />
                </SecureSyncTableCard>
                {records?.length > 0 && (
                    <>
                        <Typography sx={styles.footerCaption}>
                            <Trans i18nKey={"common.tableFooterCaption"} />
                        </Typography>
                    </>
                )}
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => setIsPrintModalOpen(false)}
                targetReport={targetUrl.carSearchRequest}
            />
        </>
    );
};

export default CorrectiveActionRequest;
