import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./RequestDetails.styles";
import Comments from "src/components/Request/SearchDetails/Comments/Comments";
import EmptyTransports from "src/components/Request/SearchDetails/EmptyTransports/EmptyTransports";
import IssueClosedContainer from "src/components/Request/SearchDetails/IssueClosedContainer/IssueClosedContainer";
import IssuePermanentTransports from "src/components/Request/SearchDetails/IssuePermanentTransports/IssuePermanentTransports";
import OtherRequests from "src/components/Request/SearchDetails/Other Requests/OtherRequests";
import RequestContainerDeletion from "src/components/Request/SearchDetails/RequestContainerDeletion/RequestContainerDeletion";
import RequestData from "src/components/Request/SearchDetails/RequestData/RequestData";
import RequestDeliverMedia from "src/components/Request/SearchDetails/RequestDeliverMedia/RequestDeliverMedia";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { programIds } from "src/constants/programIdsConstants";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { UnAuthorized } from "src/components/AppComponents/UnAuthorized/UnAuthorized";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { requestActionIds, requestStatusCodes, requestTypeIds } from "src/constants/requestConstants";
import {
    getOmList,
    getRequestContainerDetails,
    getRequestDeleteContainer,
    getRequestDetails,
    getRequestTransportInfo,
    getSingleRequestData,
    validateRequestRelation,
    verifyRequestCancellation,
} from "src/utils/requestHelper";

const statusMessage = {
    show: false,
    status: "",
    message: " ",
};

const RequestDetails = () => {
    const { requestId } = useParams();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(statusMessage);
    const userLevelID = useSelector(getProgramIdsOfCurrentCustomer);
    const [requestData, setRequestData] = useState({
        requestDetails: null,
        transportDetails: [],
        isRelated: true,
        cancelRequest: null,
        singleRequestData: null,
        openMediaList: [],
        requestContainerDetails: [],
        requestDeleteContainer: [],
    });

    const hasSubmitAccess = () => {
        if (
            requestData?.requestDetails?.request_status_id === requestStatusCodes.cancelled ||
            requestData?.requestDetails?.request_status_id === requestStatusCodes.completed ||
            requestData?.requestDetails?.mdr_flag === "Y"
        ) {
            return false;
        } else {
            if (
                !isUserProgramLevelAvailable(userLevelID, programIds.programId_245) ||
                requestData?.requestDetails?.request_status_id === requestStatusCodes.cancelled ||
                requestData?.requestDetails?.request_status_id === requestStatusCodes.completed
            ) {
                return false;
            } else {
                return true;
            }
        }
    };

    const hasCancelAccess = () => {
        if (requestData?.requestDetails?.mdr_flag === "Y") {
            return false;
        } else if (
            isUserProgramLevelAvailable(userLevelID, programIds.programId_240) &&
            (requestData?.requestDetails?.request_type_id === requestTypeIds.scheduledService ||
                requestData?.requestDetails?.request_type_id === requestTypeIds.addOn ||
                (isUserProgramLevelAvailable(userLevelID, programIds.programId_153) &&
                    requestData?.requestDetails?.request_type_id === requestTypeIds.specialStandard))
        ) {
            if (
                requestData?.requestDetails?.Cancelled === "1" ||
                requestData?.requestDetails?.request_status_id === requestStatusCodes.completed
            ) {
                return false;
            } else if (
                requestData?.cancelRequest?.result[0]?.error !== "0" &&
                requestData?.cancelRequest?.result[0]?.error !== "1"
            ) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const [
                isRelatedResponse,
                requestDetails,
                transportData,
                cancelRequest,
                singleRequestData,
                openMediaList,
                requestContainerDetails,
                requestDeleteContainer,
            ] = await Promise.all([
                validateRequestRelation(requestId),
                getRequestDetails(requestId, setResponseMessage),
                getRequestTransportInfo(requestId),
                verifyRequestCancellation(requestId),
                getSingleRequestData(requestId),
                getOmList(requestId),
                getRequestContainerDetails(requestId),
                getRequestDeleteContainer(requestId),
            ]);
            setRequestData({
                requestDetails: requestDetails,
                transportDetails: transportData,
                isRelated: isRelatedResponse,
                cancelRequest: cancelRequest,
                singleRequestData: singleRequestData,
                openMediaList: openMediaList,
                requestContainerDetails: requestContainerDetails,
                requestDeleteContainer: requestDeleteContainer,
            });
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResponseStatus = (statusMessage) => {
        setResponseMessage(statusMessage);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            {requestData.isRelated ? (
                <>
                    <Box sx={styles.container}>
                        <PageTitle>{t("requestDetails.requestDetails")}</PageTitle>
                        <Typography sx={styles.requestId}>
                            {t("requestDetails.requestID")} {requestId}
                        </Typography>
                    </Box>
                    {responseMessage.show && responseMessage.status === "error" && (
                        <SecureSyncAlert
                            severity={"error"}
                            message={responseMessage.message}
                            onClose={() => setResponseMessage(statusMessage)}
                        />
                    )}

                    <SecureSyncSnackBar
                        openSnackBar={responseMessage.show && responseMessage.status === "success"}
                        message={responseMessage.message}
                        severity="success"
                        onClose={() => setResponseMessage(statusMessage)}
                    />
                    <RequestData
                        requestData={requestData?.requestDetails}
                        hasSubmitAccess={hasSubmitAccess()}
                        hasCancelAccess={hasCancelAccess()}
                    />
                    <Divider />
                    {requestData?.requestDetails?.request_action_id === requestActionIds.closedContainerDetails && (
                        <IssueClosedContainer singleRequestData={requestData?.singleRequestData} />
                    )}
                    {requestData?.requestDetails?.request_action_id === requestActionIds.temporaryTransportDetails && (
                        <>
                            {requestData?.transportDetails &&
                            requestData?.transportDetails[0]?.permanent_transport_flag === "Y" ? (
                                <IssuePermanentTransports transportDetails={requestData?.transportDetails} />
                            ) : (
                                <EmptyTransports transportDetails={requestData?.transportDetails} />
                            )}
                        </>
                    )}
                    {requestData?.requestDetails?.request_action_id === requestActionIds.otherDetails && (
                        <OtherRequests comment={requestData?.requestDetails?.comment} />
                    )}
                    {requestData?.requestDetails?.request_action_id === requestActionIds.openMediaDetails && (
                        <RequestDeliverMedia
                            openMediaList={requestData?.openMediaList}
                            requestContainerDetails={requestData?.requestContainerDetails}
                        />
                    )}
                    {requestData?.requestDetails?.request_action_id === requestActionIds.deleteContainerDetails && (
                        <RequestContainerDeletion requestDeleteContainer={requestData?.requestDeleteContainer} />
                    )}
                    <Comments
                        requestData={requestData?.requestDetails}
                        hasSubmitAccess={hasSubmitAccess}
                        hasCancelAccess={hasCancelAccess}
                        cancelRequestData={requestData?.cancelRequest}
                        handleResponseStatus={handleResponseStatus}
                    />
                </>
            ) : (
                <UnAuthorized />
            )}
        </>
    );
};

export default RequestDetails;
