const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.default",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        color: "im_grey.900",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
        marginBottom: "16px",
        textAlign: "justify",
    },
    textFieldContainer: {
        display: "flex",
        gap: 2,
        my: 3,
    },
    footNotes: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "166%",
        letterSpacing: "0.4px",
    },
    formControlLabel: {
        paddingBottom: "20px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5 /* 24px */,
        letterSpacing: "0.15px",
    },
    buttonContainer: {
        display: "flex",
        gap: "20px",
        my: 2,
    },
    card: {
        width: "100%",
        marginBottom: "16px",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        mb: 3,
        height: "35px",
    },
    recordStart: {
        justifyContent: "flex-start",
    },
    recordEnd: {
        justifyContent: "flex-end",
    },
    singleColumnContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "20px",
    },
    fontWeight700: {
        fontWeight: 700,
    },
    marginBottomBreak: {
        marginBottom: "16px",
    },
    editIcon: {
        width: 24,
        height: 24,
        transition: "none",
        transform: "none",
    },
    renderCell: {
        maxWidth: "50ch",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        verticalAlign: "top",
    },
    doneIcon: {
        color: "im_mid_blue.500",
    },
    clearIcon: {
        color: "im_dark_blue.500",
    },
};

export default styles;
