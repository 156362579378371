import { getDocs } from "firebase/firestore";
import store from "../redux/store";
import {
    apiResultSet,
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    apiResultSet3Collection,
    apiResultSet4Collection,
} from "./firebase";
import { sendAuthResetMail } from "./mailService";
import { createRemoveAccountPayload } from "./payloadService";
import {
    constructFbHeaders,
    createArrayObj,
    getCustomers,
    getLanguageLocaleId,
    getLoggedUserDetails,
    getPersonId,
    getPersonalId,
    getPersonalLocalId,
    getSelectedCustomerDb,
    getSessionGuid,
    invokeApi,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { formatDate, getDisplayFormat } from "src/utils/dateHelper";
import { dateFormats } from "src/constants/dateFormatConstants";
import { authLevelCode } from "src/constants/autherizationConstants/authConstants";

const apiUrls = {
    applyCustomerPersonalSetting: "/ssautherization/applycustomerpersonalsetting",
    authUpdate: "/ssautherization/authnupdate",
    lastModifiedCustomer: "/ssautherization/lastmodifiedcustomer",
    stalePersonnels: "/ssautherization/stalepersonnels",
    loggedEventRecords: "/ssautherization/loggedeventrecords",
    personnelInformation: "/ssautherization/personnelinformation",
    getPersonnelInformation: "/ssautherization/getpersonnelinformation",
    getCustomerPersonnelAuth: "/ssautherization/getcustomerpersonnelauth",
    getCustomerAuthInfo: "/ssautherization/getcustomerauthininfo",
    insertPersonnelAuthHistory: "/ssautherization/insertpersonnelauthhistory",
    personnelAddress: "/ssautherization/personnelanddress",
    resetAuthNumber: "/ssautherization/resetauthnumber",
    securityProfiles: "/ssautherization/securityprofiles",
    validatePhoneNumbers: "/ssautherization/validatephonenumbers",
    authInformation: "/ssautherization/authinformation",
    getLastModifyDate: "/ssautherization/getlastmodifydate",
};

export const removeCustomerService = async (request, contextCustomerId, personId, customerList) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.applyCustomerPersonalSetting);
    traceStart();
    const payload = createRemoveAccountPayload(request, contextCustomerId, personId, customerList);
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/applycustomerpersonalsetting";
    try {
        let startCollection = 1;
        const res = await invokeApi("POST", loginUrl, payload.payload, {}, headers);
        const resDoc = res?.docId;
        for (let index = 0; index < customerList.length; index++) {
            const snapShot = await getDocs(apiResultSet(resDoc, `resultSet_${index}`));
            const snapObj = await createArrayObj(snapShot);
            const notAnCustomerDetails = snapObj[0] && snapObj[0].number;
            startCollection = index;
            if (notAnCustomerDetails) {
                break;
            }
        }
        const personalDataString = `resultSet_0`;
        const successCustomerListString = `resultSet_${startCollection}`;
        const cancelledListString = `resultSet_${startCollection + 1}`;
        const interactionCancelledCustomerListString = `resultSet_${startCollection + 2}`;
        const dRCancelledListString = `resultSet_${startCollection + 3}`;
        const ciamString = `resultSet_${startCollection + 4}`;
        const errorCoude = `resultSet_${startCollection + 5}`;

        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot, rs5Snapshot, rs6Snapshot] =
            await Promise.all([
                getDocs(apiResultSet(resDoc, personalDataString)),
                getDocs(apiResultSet(resDoc, successCustomerListString)),
                getDocs(apiResultSet(resDoc, cancelledListString)),
                getDocs(apiResultSet(resDoc, interactionCancelledCustomerListString)),
                getDocs(apiResultSet(resDoc, dRCancelledListString)),
                getDocs(apiResultSet(resDoc, ciamString)),
                getDocs(apiResultSet(resDoc, errorCoude)),
            ]);
        const personalData = await createArrayObj(rs0Snapshot);
        const successCustomerList = await createArrayObj(rs1Snapshot);
        const cancelledList = await createArrayObj(rs2Snapshot);
        const interactionCancelledCustomerList = await createArrayObj(rs3Snapshot);
        const dRCancelledList = await createArrayObj(rs4Snapshot);
        const ciamData = await createArrayObj(rs5Snapshot);
        const errorCode = await createArrayObj(rs6Snapshot);
        return {
            personalData: personalData && personalData[0] ? personalData[0] : null,
            successCustomerList,
            cancelledList,
            interactionCancelledCustomerList,
            dRCancelledList,
            ciamData: ciamData[0],
            errorCode: errorCode[0],
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Updates authorization comments for customer
 * @param {*} customerId
 * @param {*} comment
 * @returns
 */
export const updateAuthorizationComments = async (comment, timeStamp) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.authUpdate);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const selectedCustomer = custMetaData.selectedCustomer;
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/authnupdate";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_id,
        authorization_comment: comment,
        last_mod_user: lastModifiedUser,
        ts1: timeStamp,
        suppress_resultset: "N",
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        // const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        return { failure: false };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns the last modified personnel details
 * @param {*} askingPersonnel
 * @param {*} personnelId
 * @returns
 */
export const getLastModifiedPersonnelDetails = async (askingPersonnel, personnelId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.lastModifiedCustomer);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/lastmodifiedcustomer";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        asking_personnel_id: askingPersonnel,
        personnel_id: personnelId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const modifiedUserDetails = {
            failure: false,
            rs0: rs0Data,
        };
        return modifiedUserDetails;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns list of stale personnels for a customer or for all customers
 * @param {*} personnelId
 * @param {*} customerInfoOnly
 * @param {*} localId
 * @param {*} limitCustomerTo
 * @returns
 */
export const getStalePersonnels = async (personnelId, customerInfoOnly, localId, limitCustomerTo) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.stalePersonnels);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/stalepersonnels";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_info_only: customerInfoOnly,
        personnel_id: personnelId,
        locale_id: localId,
        limit_to_customer_id: limitCustomerTo,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const stalePersonnels = {
            failure: false,
            rs0: rs0Data,
        };
        return stalePersonnels;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Insert log in events when user tries to authenticate
 * @param {*} sessionguid
 * @param {*} eventId
 * @param {*} description
 * @param {*} comment
 * @param {*} username
 * @param {*} eventId
 * @returns
 */
export const insertLoggedEvents = async (sessionguid, eventId, description, comment, username) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.loggedEventRecords);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/loggedeventrecords";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        app_version: process.env.REACT_APP_SS_APP_VERSION,
        system_id: 50,
        source: "SecureSync",
        user_name: username,
    };
    if (sessionguid) {
        body.session_guid = sessionguid;
    }
    if (eventId) {
        body.logged_event_id = eventId;
    }
    if (description) {
        body.description = description;
    }
    if (comment) {
        body.comment = comment;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const rs1Snapshot = await getDocs(apiResultSet1Collection(resDoc));

        const rs0Data = [];
        rs1Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        if (rs0Data[0].error !== "0") {
            return { failure: true, errorCode: rs0Data[0].error };
        }

        return { failure: false };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
/**
 * Returns the user details
 * @param {*} customerId
 * @returns
 */
export const getUserDetails = async (request = {}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.personnelInformation);
    traceStart();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData.selectedCustomer;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/personnelinformation";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_id,
    };

    if (request.firstName) {
        body.first_name = request.firstName;
    }
    if (request.lastName) {
        body.last_name = request.lastName;
    }
    if (request.homePhone) {
        body.home_phone = request.homePhone;
    }
    if (request.businessPhone) {
        body.business_phone = request.businessPhone;
    }
    if (request.businessPhoneExt) {
        body.business_phone_ext = request.businessPhoneExt;
    }
    if (request.eLevelAuthorization) {
        body.e_level_authorization = request.eLevelAuthorization;
    }
    if (request.customerParentID) {
        body.customer_parent_id = request.customerParentID;
    }
    if (request.secureSyncFlag) {
        body.secure_sync_flag = request.secureSyncFlag;
    }
    if (request.authNumber) {
        body.auth_number = request.authNumber;
    }
    if (request.drLevelAuthorization) {
        body.dr_level_authorization = request.drLevelAuthorization;
    }
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);
        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const personalInfo = {
            failure: false,
            rs0: rs0Data,
        };
        return personalInfo;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
/**
 * Returns the user details
 * @param {*} customerId
 * @returns
 */
export const getPersonalInformation = async (personId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getPersonnelInformation);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/getpersonnelinformation";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        personnel_id: personId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);
        const rs2Data = await createArrayObj(rs2Snapshot);
        const personalInfo = {
            personalData: rs0Data[0] ? rs0Data[0] : null,
            isSecureSyncUser: rs1Data[0] && rs1Data[0].is_securesync_login ? rs1Data[0].is_securesync_login : null,
            error: rs2Data,
        };
        return personalInfo;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns the user details
 * @param {*} customerId
 * @returns
 */
export const getPersonalAuthService = async ({ activeOnlyFlag, PersonnelId, customerId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getCustomerPersonnelAuth);
    traceStart();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData.selectedCustomer;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/getcustomerpersonnelauth";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        requesting_personnel_id: getPersonalId(),
        active_only_flag: activeOnlyFlag ? activeOnlyFlag : "Y",
        personnel_id: PersonnelId ? PersonnelId : getPersonalId(),
        customer_id: customerId ? customerId : selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);
        const rs2Data = await createArrayObj(rs2Snapshot);
        if (rs2Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const personalInfo = {
            failure: false,
            personalData: rs0Data[0],
            authData: rs1Data[0],
        };
        return personalInfo;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns the user details
 * @param {*} customerId
 * @returns
 */
export const getCustomerAuthInfo = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getCustomerAuthInfo);
    traceStart();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData.selectedCustomer;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/getcustomerauthininfo";
    const sessionGuid = getSessionGuid();
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_id,
        authentication_token: sessionGuid,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);
        const rs0Data = createArrayObj(rs0Snapshot);
        const rs1Data = createArrayObj(rs1Snapshot);
        const comments = {
            authData: rs0Data[0],
            error: rs1Data,
        };
        return comments;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
export const getPersonalInformationWrapper = async (personId) => {
    try {
        const personalData = await getPersonalInformation(personId);
        const authInfo = await getAuthInfo(personId);
        const res = {
            personalData,
            authInfo,
        };
        return res;
    } catch (error) {
        return error;
    }
};

/**
 * Returns the user details
 * @param {*} customerId
 * @returns
 */
export const getHistoryInfo = async (personalString, login) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertPersonnelAuthHistory);
    traceStart();
    const headers = constructFbHeaders();
    const userDetails = getLoggedUserDetails();
    const loginUser = userDetails.basicUserInfo.username;
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/insertpersonnelauthhistory";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_personnel_string: personalString,
        login: login ? login : loginUser,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);
        const comments = {
            history: rs0Data,
            error: rs1Data,
        };
        return comments;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const userAlreadyExist = async (
    { firstName, lastName, middleName },
    customerId = null,
    askingPerson = getPersonId()
) => {
    const userObj = {
        first_name: firstName,
        last_name: lastName,
        home_phone: "",
        business_phone: "",
        business_phone_ext: "",
        e_level_authorization: "",
        duplicate_check_flag: "Y",
        auth_number: "",
        middle_name: middleName,
        asking_personnel_id: askingPerson,
    };
    const res = await getUserAddress(userObj, customerId);
    let userNameTaken = false;
    if (res.rs0 && res.rs0[0]) {
        userNameTaken = true;
    }
    return { isUserNameTaken: userNameTaken, details: res.rs0[0] };
};

/**
 * Returns the user details
 * @param {*} personnel
 * @returns
 */
export const getUserAddress = async (request, customerId = null) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.personnelAddress);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();

    const selectedCustomer = custMetaData.selectedCustomer;
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/personnelanddress";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: customerId ? customerId : selectedCustomer.customer_id,
        ...request,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const address = {
            failure: false,
            rs0: rs0Data,
        };
        return address;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Resets auth number
 * @param {*} userId
 * @param {*} username
 * @param {*} authNumber
 * @param {*} authBypassReason
 * @returns
 */
export const resetAuthNumber = async (
    userId,
    authNumber,
    authBypassReason,
    accountNumbers,
    name,
    modified,
    mailSendTo,
    notified = false
) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.resetAuthNumber);
    traceStart();
    let mailHasError = false;
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/resetauthnumber";
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        sys_user_id: userId,
        last_mod_user: lastModifiedUser,
        new_auth_number: "",
    };
    if (authNumber) {
        body.new_auth_number = authNumber;
    }
    if (authBypassReason) {
        body.auth_bypass_reason = authBypassReason;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        if (notified) {
            const authTo = [
                {
                    emailAddress: mailSendTo,
                    name: name,
                },
            ];

            const mailRes = await sendAuthResetMail(accountNumbers, name, modified, authTo, mailSendTo);
            if (!mailRes.isSuccess) {
                mailHasError = true;
            }
        }

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const authNumber = {
            failure: false,
            rs0: rs0Data,
            mailHasError,
        };
        return authNumber;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Retrieves security info for user
 * @param {*} username
 * @param {*} customerNumber
 * @param {*} customerId
 * @returns
 */
export const getSecurityInfo = async (username, customerNumber, customerId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.securityProfiles);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/securityprofiles";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        login: username,
        customer_number: customerNumber,
        customer_id: customerId,
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        // const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot, rs4Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
            getDocs(apiResultSet2Collection(resDoc)),
            getDocs(apiResultSet3Collection(resDoc)),
            getDocs(apiResultSet4Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        const rs2Data = [];
        rs2Snapshot.forEach((doc) => {
            rs2Data.push(doc.data());
        });

        const rs3Data = [];
        rs3Snapshot.forEach((doc) => {
            rs3Data.push(doc.data());
        });

        const rs4Data = [];
        rs4Snapshot.forEach((doc) => {
            rs4Data.push(doc.data());
        });

        if (rs4Data[0].error !== "0") {
            return { failure: true, errorCode: rs4Data[0].error };
        }
        const securityProfiles = {
            failure: false,
            rs0: rs0Data,
            rs1: rs1Data,
            rs2: rs2Data,
            rs3: rs3Data,
        };
        return securityProfiles;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @param {*} phone  Object that contains phone related information
 * @param {*} locale Object that contains locale related information
 * @returns
 */
export const validatePhoneFormat = async (phone, locale) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validatePhoneNumbers);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/validatephonenumbers";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        personnel_locale_id: getPersonalLocalId(),
        language_locale_id: getLanguageLocaleId(),
    };
    if (phone.cellPhone) {
        body.cellular_phone = phone.cellPhone;
    }
    if (phone.homePhone) {
        body.home_phone = phone.homePhone;
    }
    if (phone.pager) {
        body.pager = phone.pager;
    }
    if (phone.fax) {
        body.business_fax = phone.fax;
    }
    if (phone.businessPhone) {
        body.business_phone = phone.businessPhone;
    }
    if (locale.personnel) {
        body.personnel_locale_id = locale.personnel;
    }
    if (locale.localeId) {
        body.language_locale_id = locale.localeId;
    }
    if (locale.business) {
        body.business_locale_id = locale.business;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error, data: rs0Data[0] };
        }

        const phoneFormat = {
            failure: false,
            rs0: rs0Data,
        };
        return phoneFormat;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns auth information for target personnel
 * @param {*} targetPersonnel
 * @param {*} requestingPersonnel
 * @returns
 */
export const getAuthInfo = async (targetPersonnel = false) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.authInformation);
    traceStart();
    const districtId = store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        ? store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        : "50";
    const headers = constructFbHeaders();
    const customerMetaData = getCustomers();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/authinformation";
    const body = {
        main_district_id: districtId,
        target_personnel_id: targetPersonnel ? targetPersonnel : getPersonalId(),
        requesting_personnel_id: getPersonalId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);
        const stateCustomerList = customerMetaData.custList;
        const customers = [];
        rs0Data.forEach((e) => {
            const customer = stateCustomerList.find((cus) => cus.customer_id === e.customer_id);
            const tempObj = {
                ...e,
                ...customer,
            };
            customers.push(tempObj);
        });

        customers.sort((a, b) => {
            if (a.customer_number < b.customer_number) {
                return -1;
            }
            if (a.customer_number > b.customer_number) {
                return 1;
            }
            return 0;
        });
        const authInfo = {
            failure: rs1Data,
            authList: customers,
        };
        return authInfo;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

/**
 * Returns auth information for target personnel
 * @param {*} targetPersonnel
 * @param {*} requestingPersonnel
 * @returns
 */
export const lastModifyDetailsService = async (personId, customerId = "") => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getLastModifyDate);
    traceStart();
    const districtId = store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        ? store?.getState()?.userDetails?.customer?.selectedCustomer?.district_id
        : "50";
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_AUTHORIZATION + "/ssautherization/getlastmodifydate";
    const body = {
        main_district_id: districtId,
        personnel_id: personId,
        customer_id: customerId,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = await createArrayObj(rs0Snapshot);
        const rs1Data = await createArrayObj(rs1Snapshot);

        let modifyData = {
            intMod: null,
            drMod: null,
            personalMod: null,
        };

        const format = `${getDisplayFormat()}${dateFormats.timeWithGmtStamp}`;

        rs0Data.forEach((modData) => {
            let formattedDate = "";
            const timeStamp = modData?.last_mod_date ? modData.last_mod_date : "";
            if (timeStamp !== "") {
                formattedDate = formatDate(timeStamp, format, true);
            }
            const populateData = {
                last_mod_date: formattedDate,
                last_mod_name: modData.last_mod_name,
                record_type: modData.record_type,
            };
            if (populateData.record_type === "I") {
                modifyData = {
                    ...modifyData,
                    intMod: populateData,
                };
            }
            if (populateData.record_type === "D") {
                modifyData = {
                    ...modifyData,
                    drMod: populateData,
                };
            }
            if (populateData.record_type === "P") {
                modifyData = {
                    ...modifyData,
                    personalMod: populateData,
                };
            }
        });
        const data = {
            failure: rs1Data,
            modifyData: modifyData,
        };
        return data;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
/**
 * Returns auth information for target personnel
 * @param {*} targetPersonnel
 * @param {*} requestingPersonnel
 * @returns
 */
export const getAuthInfoService = async (targetPersonnel = "") => {
    try {
        const authInfoData = {
            hasAccess: false,
        };
        const authInfoMeta = await getAuthInfo(targetPersonnel);
        const filterData = authInfoMeta.authList
            ? authInfoMeta.authList.find(
                  (auth) => auth.interaction_code !== authLevelCode.none || auth.dr_code !== authLevelCode.none
              )
            : null;
        if (filterData) {
            if (filterData.edit_permission_flag || targetPersonnel === getPersonalId()) {
                authInfoData.hasAccess = true;
            }
        }

        return authInfoData;
    } catch (err) {
        return err;
    }
};
