import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OpenNewTabServiceLink } from "../ServiceLink/ServiceLink";
import styles from "./AccountStatusSection.styles";
import { openFileFromFB } from "src/services/utils";
import { staticFile } from "src/constants/fileConstants";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { getCustomerAuthInfo } from "src/services/authorizationService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";

const ShortageListItem = ({ count, message }) => {
    return (
        count !== "0" && (
            <li style={styles.listItem}>
                {count}-{message}
            </li>
        )
    );
};

export const AccountStatusSection = (props) => {
    const [url, setUrl] = useState("#");
    const [shortageCount, setShortageCount] = useState({
        confirm: "0",
        declareDr: "0",
        eLevel: "0",
        reviseDrAuth: "0",
        revisePlan: "0",
    });
    const customer = useSelector(getSelectedCustomer);

    const [isLoading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
            const filePath = generateFilePathForCurrentLocale(bucketPath, staticFile["whatIsNewSecureSyncFile"]);
            const url = await openFileFromFB(filePath);
            if (url) {
                if (url.failed) {
                    setUrl("#");
                } else {
                    setUrl(url);
                }
            } else {
                setUrl("#");
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const { authData } = await getCustomerAuthInfo();
            setShortageCount({
                confirm: authData?.confirm ? populateShortage(authData.confirm) : "0",
                declareDr: authData?.declare_dr ? populateShortage(authData.declare_dr) : "0",
                eLevel: authData?.elevel ? populateShortage(authData.elevel) : "0",
                reviseDrAuth: authData?.revise_dr_auth ? populateShortage(authData.revise_dr_auth) : "0",
                revisePlan: authData?.revise_plan ? populateShortage(authData.revise_plan) : "0",
            });
            setLoading(false);
        };
        fetchData();
    }, [customer]);

    const populateShortage = (count) => {
        let shortage = "0";
        const populateCount = 2 - parseInt(count);
        if (populateCount > 0) {
            shortage = populateCount.toString();
        }
        return shortage;
    };

    const isAccountUptoDate = useMemo(() => {
        return (
            shortageCount.confirm === "0" &&
            shortageCount.declareDr === "0" &&
            shortageCount.eLevel === "0" &&
            shortageCount.reviseDrAuth === "0" &&
            shortageCount.revisePlan === "0"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortageCount]);

    return (
        <Box sx={styles.box}>
            <CircularLoaderFullPage loading={isLoading} />
            <Typography variant="h4" align="center" sx={styles.title}>
                {t("homeModule.AccountStatus")}
            </Typography>
            {isAccountUptoDate ? (
                <Typography sx={styles.description}>
                    {t("homeModule.YourAccountIsUpToDateInAllAuthorizationCategories")}
                </Typography>
            ) : (
                <>
                    <Typography sx={styles.description}>{t("homeModule.shortageMessage")}</Typography>
                    <Box sx={styles.boxShortage}>
                        <ShortageListItem count={shortageCount.eLevel} message={t("homeModule.eLevelAuthCount")} />
                        <ShortageListItem count={shortageCount.confirm} message={t("homeModule.confirmMediaCount")} />
                        <ShortageListItem count={shortageCount.reviseDrAuth} message={t("homeModule.manageDrCount")} />
                        <ShortageListItem
                            count={shortageCount.declareDr}
                            message={t("homeModule.declareVerifyCount")}
                        />
                        <ShortageListItem
                            count={shortageCount.revisePlan}
                            message={t("homeModule.reviseDrAuthCount")}
                        />
                    </Box>
                </>
            )}
            <OpenNewTabServiceLink href={url} sx={styles.serviceLink}>
                {t("homeModule.WhatIsNewInSecuresync")}
            </OpenNewTabServiceLink>
        </Box>
    );
};
