const Styles = {
    container: {
        my: 8,
        textAlign: "center",
    },
    cardContent: {
        py: 5,
        px: 10,
    },
    HomeBtn: {
        mt: 1,
    },
    card: {
        borderRadius: 3,
    },
    textContent: {
        fontSize: "14px",
        my: "20px",
    },
    companyLogo: {
        width: 201,
        height: 38,
    },
    logoSection: {
        mb: "40px",
    },
    iconSection: {
        my: "20px",
    },
    footerContent: {
        fontSize: "18px",
        color: "im_grey.500",
    },
    titleSection: {
        color: "im_grey.900",
    },
    btnArea: {
        display: "flex",
        gap: 2,
        justifyContent: "center",
        mt: 2,
    },
};

export default Styles;
