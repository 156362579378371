import "./styles/global.css";
// MUI
import { ThemeProvider } from "@mui/material";

// ROUTER
import { RouterProvider, createBrowserRouter } from "react-router-dom";
// REDUX
import { Provider as ReduxProvider } from "react-redux";
import { HotkeysProvider } from "react-hotkeys-hook";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { muiTheme } from "./styles/muiTheme";
import { OktaSecurityConfig } from "./components/AppComponents/OktaSecurityConfig/OktaSecurityConfig";
import store from "./redux/store";
import RootRouter from "src/routes/RootRouter";

const nonce = process.env.REACT_APP_CSP_NONCE || "";

const cache = createCache({
    key: "secure-sync",
    nonce: nonce,
    prepend: true,
});

const router = createBrowserRouter([
    {
        path: "*",
        element: (
            <OktaSecurityConfig>
                <RootRouter />
            </OktaSecurityConfig>
        ),
    },
]);

function App() {
    return (
        <CacheProvider value={cache}>
            <ReduxProvider store={store}>
                <ThemeProvider theme={muiTheme}>
                    <HotkeysProvider>
                        <RouterProvider router={router} />
                    </HotkeysProvider>
                </ThemeProvider>
            </ReduxProvider>
        </CacheProvider>
    );
}

export default App;
