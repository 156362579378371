const IconRight = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.28954 16.9479C7.13121 16.9479 6.97288 16.8896 6.84788 16.7646C6.60621 16.5229 6.60621 16.1229 6.84788 15.8812L12.2812 10.4479C12.6812 10.0479 12.6812 9.39792 12.2812 8.99792L6.84788 3.56458C6.60621 3.32292 6.60621 2.92292 6.84788 2.68125C7.08954 2.43958 7.48954 2.43958 7.73121 2.68125L13.1645 8.11458C13.5895 8.53958 13.8312 9.11458 13.8312 9.72292C13.8312 10.3313 13.5979 10.9062 13.1645 11.3313L7.73121 16.7646C7.60621 16.8812 7.44788 16.9479 7.28954 16.9479Z"
                fill="#1B75BC"
            />
        </svg>
    );
};

export default IconRight;
