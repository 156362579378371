import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "./ServiceSection.styles";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { downloadFileFromFB } from "src/services/utils";
import { staticFile } from "src/constants/fileConstants";

const ServiceSection = ({ values, handleBlur, handleChange, touched, errors, serviceLocations }) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const downloadFile = async () => {
        setLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const file = generateFilePathForCurrentLocale(bucketPath, staticFile["miscServicesHelper"]);
        await downloadFileFromFB({
            fbFilePath: file,
            fileName: "MiscServices.pdf",
            onComplete: () => setLoading(false),
        });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box sx={styles.boxPaddingBottom}>
                <SecureSyncSelect
                    showBlankOption
                    id="serviceLocation"
                    label={t("formFields.serviceLocationLabel")}
                    options={serviceLocations}
                    value={values.serviceLocation}
                    name="serviceLocation"
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    error={touched.serviceLocation && Boolean(errors.serviceLocation)}
                    helperText={touched.serviceLocation && errors.serviceLocation}
                    required={true}
                />
                <SecureSyncIconTooltip onClick={downloadFile} title={t("miscService.toolTip")} placement="right" />
            </Box>
        </>
    );
};

export default ServiceSection;
