const styles = {
    button: {
        headerButton: {
            color: "#1B75BC",
            paddingX: 2,
            fontSize: "15px",
            fontWeight: 500,
            lineHeight: "26px",
            letterSpacing: "0.46px",
            textTransform: "none",
        },
    },
    listIcon: {
        width: 18,
        height: 18,
    },
    menuList: (isOpen) => ({
        display: isOpen ? "block" : "none",
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        position: "absolute",
    }),
    relativeDisplay: {
        position: "relative",
    },
};

export default styles;
