const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "white",
        width: "100%",
    },
    innerBox: {
        width: "100%",
        textAlign: "left",
    },
    subheader: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "im_grey.900",
        paddingBottom: "20px",
    },
    textFieldContainer: {
        display: "flex",
        paddingBottom: "20px",
    },
    formControlLabel: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        alignContent: "center",
    },
    buttonContainer: {
        display: "flex",
        gap: 2.5,
        paddingBottom: "32px",
    },

    cardContent: {
        width: "100%",
        height: "auto",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
    },
    recordStart: {
        justifyContent: "flex-start",
        padding: "16px",
    },
    recordEnd: {
        justifyContent: "flex-end",
        padding: "16px",
    },
    alertMsg: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: "0.15px",
    },
    checkBox: {
        color: "primary",
        "&.Mui-checked": {
            color: "primary",
        },
    },
    textFeildFlex: {
        paddingRight: "20px",
        flex: 1,
    },
    extendtionTextFeildFlex: {
        flex: 1,
    },
    commonPaddingBottom: {
        paddingBottom: "20px",
    },
    iconSize: {
        width: 20,
        height: 20,
    },
    searchUsersContainer: {
        mb: "20px",
    },
    checkBoxContainer: {
        display: "flex",
        paddingBottom: "20px",
        gap: 2.5,
    },
};

export default styles;
