const styles = {
    textContent: {
        fontWeight: "bold",
    },

    fourColumnLayout: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "20px",
    },
    containerWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 2fr",
        columnGap: "20px",
        mb: "20px",
    },
    pageDesc: {
        fontWeight: 500,
        mb: "20px",
        "& strong": {
            fontWeight: 700,
        },
    },
    comments: {
        mb: "20px",
    },
    extraInserts: {
        mb: "20px",
    },
    extraLocksWrapper: { gridColumn: "span 3" },
    extraLocksFieldsWrapper: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" },
    calendarTooltip: { display: "flex", alignItems: "center" },
    footerButtons: { display: "flex", gap: "20px", py: "20px" },
};

export default styles;
