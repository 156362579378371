import { Box, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./RemoveUserSuccessModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const RemoveUserSuccessModal = ({ open, customerList, onClose, customerName }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title={t("userDetails.userDeletePopupTitle")}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button autoFocus variant="contained" color="primary" onClick={onClose}>
                        {t("buttonLabels.close")}
                    </Button>
                </Box>
            }
        >
            <Box sx={styles.confirmationModalFooter}>
                <Typography>
                    <Trans
                        i18nKey={"userDetails.userDeletePopup"}
                        values={{
                            customerList: customerList ? customerList : "",
                            customerName: customerName ? customerName : "",
                        }}
                    />
                </Typography>
            </Box>
            <Box sx={styles.confirmationModalFooter}>
                <Typography>
                    <Trans i18nKey={"userDetails.emailConfirmationSend"} />
                </Typography>
            </Box>
            <Box sx={styles.confirmationModalFooter}>
                <Typography>
                    <Trans i18nKey={"userDetails.deletePopupBody"} />
                </Typography>
            </Box>
        </SecureSyncModal>
    );
};

export default RemoveUserSuccessModal;
