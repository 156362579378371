import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DeliveryInformationSection.styles";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import ServiceDateCalendar from "src/components/AppComponents/ServiceDateCalendar/ServiceDateCalendar";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import ServiceDateCalendarTooltip from "src/components/AppComponents/ServiceDateCalendarTooltip/ServiceDateCalendarTooltip";

const DeliveryInformationSection = ({
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    serviceLocations = [],
    serviceFrequencyValues,
    serviceDateRef,
    serviceDateHandle,
    getTodayServiceDate,
}) => {
    const { t } = useTranslation();
    const [isServiceDateLoading, setIsServiceDateLoading] = useState(false);

    return (
        <>
            <CircularLoaderFullPage show={isServiceDateLoading} />
            <PageSubTitle>{t("otherRequest.titleServiceSection")}</PageSubTitle>
            <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                <SecureSyncSelect
                    id="serviceLocation"
                    disabled
                    label={t("formFields.serviceLocation")}
                    options={serviceLocations}
                    value={values.serviceLocation}
                    name="serviceLocation"
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    error={touched.serviceLocation && Boolean(errors.serviceLocation)}
                    helperText={touched.serviceLocation && errors.serviceLocation}
                    required={true}
                />
                <ServiceDateCalendar
                    scheduleEmptyAllowSelectDefault
                    scheduleEndDate={serviceFrequencyValues.scheduleEndDate}
                    setServiceDateLoading={setIsServiceDateLoading}
                    ref={serviceDateRef}
                    label={t("formFields.serviceDate")}
                    value={values.serviceDate}
                    error={touched.serviceDate && Boolean(errors.serviceDate)}
                    helperText={touched.serviceDate && errors.serviceDate}
                    onChange={async (value) => {
                        await serviceDateHandle(value);
                    }}
                    onBlur={handleBlur}
                    getTodayServiceDate={(data) => getTodayServiceDate(data)}
                />
                <ServiceDateCalendarTooltip />
            </Box>
        </>
    );
};
export default DeliveryInformationSection;
