const styles = {
    typographyHeader: {
        color: "im_grey.900",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "1.334",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
};

export default styles;
