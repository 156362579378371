const styles = {
    confirmationModalFooter: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    textDescription: {
        lineHeight: "1.7",
        letterSpacing: "0.4px",
        fontWeight: 500,
    },
};

export default styles;
