import { t } from "i18next";

export const AUTH_MAIL_SUBJECT = "Authorization Details";
export const genarateAuthMailBody = (AuthNumber, authPin, modified) => `
    <html>
    <body>
    <div>
    Do not reply to this email.  This email confirms your request is complete.
    <p></p>
    <p><b>Auth Details</b></p><table border="1" cellpadding="1" cellspacing="1">
    <tbody>
    <tr>
    <td>AuthNumber</td>
    <td>${AuthNumber}</td>
    </tr><tr>
    <td>Auth Pin</td>
    <td>${authPin}</td>
    </tr>
    </tbody>
    </table>
    <p><b>Additional Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
    <tbody>
    <tr>
    <td>Modified By</td>
    <td>${modified}</td>
    </tr>
    <tr>
    <td>Iron Mountain Customer Response</td>
    <td>+44(0)8445 60 70 80</td>
    </tr></tbody>
    </table>
    <p></p>
    <p>Thank you,<br><br>Iron Mountain</p><p></p><p></p><p></p><p></p></div>
    </body>
    </html>
`;

export const AUTH_CHANGED_MAIL_SUBJECT = "Iron Mountain Authorization Number Reset";
export const genarateAuthChangeMailBody = (accountNumbers, name, email, modified, irmTell, secureSyncUrl) => `
    <html>
<div>Do not reply to this email. This email confirms your request is complete.<p>The Authorization Number has been reset
        for the following SecureSync user:</p>
    <p><b>Identification Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
        <tbody>
            <tr>
                <td>Account Numbers</td>
                <td>${accountNumbers}</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>${name}</td>
            </tr>
            <tr>
                <td>User Name</td>
                <td><a href="mailto:${email}"
                        target="_blank">${email}</a></td>
            </tr>
        </tbody>
    </table>
    <p><b>Additional Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
        <tbody>
            <tr>
                <td>Modified By</td>
                <td>${modified}</td>
            </tr>
            <tr>
                <td>Iron Mountain Customer Response</td>
                <td>${irmTell}</td>
            </tr>
            <tr>
                <td>SecureSync website</td>
                <td><a href="${secureSyncUrl}" target="_blank"
                        data-saferedirecturl="${secureSyncUrl}">${secureSyncUrl}</a>
                </td>
            </tr>
        </tbody>
    </table>
    <p>To obtain your user information, log in to SecureSync and search for your name using the Authorization menu.</p>
    <p>Thank you,<br><br>Iron Mountain</p>
    <div class="yj6qo"></div>
    <div class="adL">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
    </div>
</div>
</html>
`;

export const AUTH_PIN_CHANGED_MAIL_SUBJECT = "Iron Mountain SecureSync Authorization PIN Change";
export const genarateAuthPinChangeMailBody = (accountNumbers, name, email, modified, irmTell, secureSyncUrl) => `
    <html>
<div>Do not reply to this email. This email confirms your request is complete.<p>The PIN has changed for the SecureSync
        user shown below. If you did not change your PIN, suspect someone else might be attempting to use your account,
        or think you received this message by mistake, contact Iron Mountain Support Center.</p>
    <p><b>Identification Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
        <tbody>
            <tr>
                <td>Account Numbers</td>
                <td>${accountNumbers}</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>${name}</td>
            </tr>
            <tr>
                <td>User Name</td>
                <td><a href="mailto:${email}"
                        target="_blank">${email}</a></td>
            </tr>
        </tbody>
    </table>
    <p><b>Additional Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
        <tbody>
            <tr>
                <td>Modified By</td>
                <td>${modified}</td>
            </tr>
            <tr>
                <td>Iron Mountain Customer Response</td>
                <td>${irmTell}</td>
            </tr>
            <tr>
                <td>SecureSync website</td>
                <td><a href="${secureSyncUrl}" target="_blank"
                        data-saferedirecturl="${secureSyncUrl}">${secureSyncUrl}</a>
                </td>
            </tr>
        </tbody>
    </table>
    <p>To obtain your user information, log in to SecureSync and search for your name using the Authorization menu.</p>
    <p>Thank you,<br><br>Iron Mountain</p>
    <div class="yj6qo"></div>
    <div class="adL">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
    </div>
</div>
</html>
`;

export const NEW_ACCOUNT_CREATE_TARGET_USER_SUBJECT = "Iron Mountain New Account.";
export const genarateNewAccountCreateMailBody = (accountNumber, name, modified, email = null) => `
    <html>
    <body>
    <div>Do not reply to this email.  This email confirms your request is complete.
    <p>A new account has been added.&nbsp;</p>
    <p><b>Identification Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
    <tbody>
    <tr>
    <td>Account Numbers</td>
    <td>${accountNumber}</td>
    </tr>
    <tr>
    <td>Name</td>
    <td>${name}</td>
    </tr>
    ${
        email
            ? `
        <tr>
    <td>E-mail</td>
    <td>${email}</td>
    </tr>
        `
            : ``
    }
    </tbody>
    </table>
    <p>
    <b>Additional Details</b></p><table border="1" cellpadding="1" cellspacing="1">
    <tbody><tr>
    <td>Modified By</td>
    <td>${modified}</td>
    </tr><tr>
    <td>Iron Mountain Customer Response</td>
    <td>+44(0)8445 60 70 80</td></tr>
    </tbody>
    </table>
    <p></p><p>Thank you,<br><br>Iron Mountain</p>
    <div class="yj6qo">
    </div><div class="adL"><p></p><p></p><p></p><p></p></div></div>
    </body>
    </html>
`;

export const AUTH_UPDATE_SUBJECT = "Iron Mountain Authorization Level Update.";
export const genarateAccountAuthUpdateMail = (
    accountNumber,
    name,
    modified,
    email = null,
    intCode = "none",
    drCode = "none",
    secureSyncUrl,
    mobileNumber
) => `<html>
    <body>
    <div>
    Do not reply to this email.  This email confirms your request is complete.
    <p>The Authorization Level has been changed for the following SecureSync user:</p>
    <p><b>Identification Details</b></p>
    <table border="1" cellpadding="1" cellspacing="1"><tbody>
    <tr>
    <td>Account Numbers</td>
    <td>${accountNumber}</td>
    </tr>
    <tr>
    <td>Name</td>
    <td>${name}</td>
    </tr>
    <tr>
    <td>User Name</td>
    <td>
    <a href="mailto:${email}" target="_blank">${email}
    </a>
    </td>
    </tr>
    </tbody>
    </table>
    <p><b>Authorization Levels</b></p>
    <table border="1" cellpadding="1" cellspacing="1">
    <tbody>
    <tr><td>Interaction</td>
    <td>${intCode}</td>
    </tr><tr>
    <td>Disaster Recovery</td>
    <td>${drCode}</td>
    </tr></tbody>
    </table><p>
    <b>Additional Details</b>
    </p><table border="1" cellpadding="1" cellspacing="1">
    <tbody><tr>
    <td>Modified By</td>
    <td>${modified}</td>
    </tr><tr>
    <td>Iron Mountain Customer Response</td>
    <td>${mobileNumber}</td>
    </tr>
    <tr>
    <td>SecureSync website</td>
    <td>
    <a href="${secureSyncUrl}" target="_blank">
    </a>
    </td>
    </tr>
    </tbody>
    </table>
    <p>To obtain your user information, log in to SecureSync and search for your name using the Authorization menu.</p>
    <p>Thank you,<br><br>Iron Mountain</p><p></p><p></p><p></p><p></p><p></p></div>
    </body>
    </html>`;

export const generateBidMailBody = (customer, requestedBy, requestorEmail, dateAndTime, phone, ext, desc) =>
    `
${t("miscService.customerLabel")}: ${customer}
${t("requestDetails.requestedBy")}: ${requestedBy}
${t("formFields.requestorEmailLabel")}: ${requestorEmail}
${t("formFields.requestorPhoneNumber")}: ${phone} ${t("miscService.labelExtension")}: ${ext}
${t("formFields.requestedDateTime")}: ${dateAndTime}
${t("containerPickup.description")}: ${desc}

${t("miscService.bidEmailNote")}
`;

export const generateLibraryAuditMailBody = (
    customer,
    requestedBy,
    requestorEmail,
    dateAndTime,
    phone,
    ext,
    comment,
    requestedCompletionDate
) =>
    `
${t("miscService.customerLabel")}: ${customer}
${t("requestDetails.requestedBy")}: ${requestedBy}
${t("formFields.requestorEmailLabel")}: ${requestorEmail}
${t("formFields.requestorPhoneNumber")}: ${phone} ${t("miscService.labelExtension")}: ${ext}
${t("formFields.requestedDateTime")}: ${dateAndTime}
${t("formFields.requestedCompletionDate")}: ${requestedCompletionDate}
${t("headerMenu.comments")}: ${comment}

${t("miscService.LibraryAuditEmailNote")}:
${t("miscService.libraryAuditDataOne")}
${t("miscService.libraryAuditDataTwo")}
${t("miscService.libraryAuditDataThree")}
${t("miscService.libraryAuditDataFour")}

`;

export const newCustomerMailBody = (
    customer,
    requestedBy,
    requestorEmail,
    dateAndTime,
    phone,
    ext,
    comment,
    requestedCompletionDate,
    newCustomerName,
    subAccount,
    parentAccountNumber,
    dataCenterName,
    dataCenterPhone,
    dataCenterExt
) =>
    `
${t("miscService.customerLabel")}: ${customer}
${t("requestDetails.requestedBy")}: ${requestedBy}
${t("formFields.requestorEmailLabel")}: ${requestorEmail}
${t("formFields.requestorPhoneNumber")}: ${phone} ${t("miscService.labelExtension")}: ${ext}
${t("formFields.requestedDateTime")}: ${dateAndTime}
${t("formFields.newCustomerName")}: ${newCustomerName}
${t("formFields.labelSubCustomer")}: ${subAccount ? "Yes" : "No"}
${t("formFields.parentAccountNumber")}: ${parentAccountNumber}
${t("formFields.requestedCompletionDate")}: ${requestedCompletionDate}
${t("formFields.dataCenterContactName")}: ${dataCenterName}
${t("formFields.dataCenterContactPhone")}: ${dataCenterPhone} ${t("miscService.labelExtension")}: ${dataCenterExt}
${t("headerMenu.comments")}: ${comment}

${t("miscService.newCustomerNoteOne")}:
${t("miscService.newCustomerNoteTwo")}:
`;

export const newAuthCardBody = (
    customer,
    requestedBy,
    requestorEmail,
    dateAndTime,
    phone,
    ext,
    comment,
    userName,
    authNumber
) =>
    `
${t("miscService.customerLabel")}: ${customer}
${t("requestDetails.requestedBy")}: ${requestedBy}
${t("formFields.requestorEmailLabel")}: ${requestorEmail}
${t("miscService.requestorPhoneNumber")}: ${phone} ${t("miscService.labelExtension")}: ${ext}
${t("formFields.requestedDateTime")}: ${dateAndTime}
${t("headerMenu.comments")}: ${comment}

${t("miscService.replacementUserLabel")} ${userName}
${t("miscService.authLabel")}: ${authNumber}
${t("miscService.authReplacementMailNote")}:
`;
