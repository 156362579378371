const styles = {
    appBar: {
        backgroundColor: "#ffffff",
        justifyContent: "space-evenly",
        borderRadius: "0px",
        boxShadow: "none",
        borderBottom: "1px solid",
        borderColor: "im_light_blue.100",
    },
    companyLogo: {
        width: 201,
        height: 38,
    },
    button: {
        headerButton: {
            color: "#1B75BC",
            paddingX: 2,
            fontSize: "15px",
            fontWeight: 500,
            lineHeight: "26px",
            letterSpacing: "0.46px",
            textTransform: "none",
        },
    },
    toolBar: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    companyLogoContainer: {
        display: "flex",
        alignItems: "center",
    },
    box: {
        flexPosition: {
            flexGrow: 1,
        },
        alignItemsCenter: { px: 6, py: 1, display: "flex", justifyContent: "flex-end" },
        rightEndList: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
        },
    },
};

export default styles;
