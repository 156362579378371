import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { OktaSignInWidget } from "src/components/common/OktaSignInWidget/OktaSignInWidget";
import { oktaAuthConfig } from "src/config/oktaConfig";
import { useAuthState } from "src/customHooks";
import { setAuthStatus } from "src/redux/authSlice";

export const SignIn = () => {
    const { oktaAuth } = useOktaAuth();
    const { isAuthenticated, isLoaded } = useAuthState();
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onSuccess = async (tokens) => {
        setLoading(true);
        //The below method not redirect to any page. We can you this method also - oktaAuth.tokenManager.setTokens(tokens);
        await oktaAuth.handleLoginRedirect(tokens);
        dispatch(setAuthStatus({ isAuthenticated: true }));
    };

    const onError = (err) => {
        console.error("error logging in", err);
    };

    useEffect(() => {
        // Change the background color when the component mounts
        document.documentElement.style.backgroundColor = "#f0f0f0";
        document.body.style.backgroundColor = "#f0f0f0";
        // Revert the background color when the component unmounts
        return () => {
            document.documentElement.style.backgroundColor = "#ffffff";
            document.body.style.backgroundColor = "#ffffff";
            setLoading(false);
        };
    }, []);

    return (
        <Box>
            <CircularLoaderFullPage show={isLoading || !isLoaded} />
            {isLoaded && !isAuthenticated && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OktaSignInWidget config={oktaAuthConfig} onSuccess={onSuccess} onError={onError} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
