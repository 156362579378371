import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./ContainerPickupDataTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";

const ContainerPickupDataTable = ({ columns, containerData, openAddContainerModalHandler, selectedRecordId }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncTableCard>
            <Box sx={styles.recordCount}>
                {containerData.length > 0 ? (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: containerData.length })}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.recordStart} />
                )}
                <Box sx={styles.recordEnd}>
                    <Button variant="outlined" onClick={openAddContainerModalHandler}>
                        {t("containerPickup.addContainerContents")}
                    </Button>
                </Box>
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={containerData}
                    columns={columns}
                    getRowId={(row) => row.Container_Id}
                    noDataMessage={t("containerPickup.noContainersAvailable")}
                    selectedRecordId={selectedRecordId}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default ContainerPickupDataTable;
