export const staticFile = {
    authLevel: "authorizationLevels.pdf",
    whatIsNewSecureSyncFile: "whatsNewInSecureSync.pdf",
    authorizationForms: "custAuthForm.pdf",
    miscServicesHelper: "miscServices.pdf",
    informationAboutImportingContainerContents: "informationAboutImportingContainerContents.pdf",
    acceptableFileFormatForImportMediaFile: "acceptableFileFormatForImportMediaFile.pdf",
    acceptableFileFormatForImportMediaOnDeliverMediaRequest:
        "acceptableFileFormatForImportMediaOnDeliverMediaRequest.pdf",
    troubleshootingGuideForContainerContents: "troubleshootingGuideForContainerContents.pdf",
    logicalVault: "logicalVault.pdf",
    informationAboutEnteringContainerContents: "InformationAboutEnteringContainerContents.pdf",
    destructionAuthorizationWaiver: "destructionAuthorizationWaiver.pdf",
    containerInsertGuide: "containerInsertGuideContainerInsertGuide.pdf",
    drInstructionTemplate: "drInstructionTemplates.rtf",
    contactUs: "contactUs.pdf",
    dataProtectionCustomerHandbook: "dataProtectionCustomerHandbook.pdf",
    secureSyncGlossary: "secureSyncGlossary.pdf",
    dataSheet: "datasheet.pdf",
    secureSyncHelpNotLoggedIn: "secureSyncHelpNotLoggedIn.pdf",
    homepagePersonnel: "homepagePersonnel.pdf",
    discrepancyManagementPersonnel: "discrepancyManagementPersonnel.pdf",
    vaultStatusReportPersonnel: "vaultStatusReportPersonnel.pdf",
};

//file name
export const importingContainerContentsFileName = "InformationAboutImportingContainerContents.pdf";
export const importMediaFileFileName = "AcceptableFileFormatForImportMediaFile.pdf";
export const importDeliverMediaFileFileName = "AcceptableFileFormatForImportMediaOnDeliverMediaRequest.pdf";
export const rejectReasonTroubleshoot = "TroubleshootingGuideForContainerContents.pdf";
export const logicalVaultFileName = "LogicalVault.pdf";
export const enteringContainerContent = "InformationAboutEnteringContainerContents.pdf";
export const destructionAuthorizationWaiverFileName = "DestructionAuthorizationWaiver.pdf";
export const ContainerInsertGuideFileName = "ContainerInsertGuide.pdf";
export const drInstructionTemplateFileName = "DR_Instructions_Template.language.rtf";
