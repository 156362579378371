const styles = {
    formControlWidth: (width) => ({
        width: width || "100%",
    }),

    selectField: {
        width: "100%",
        paddingRight: "12px",
    },
};

export default styles;
