const styles = {
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "72px",
        p: "16px",
    },
    footerCaption: {
        mt: "10px",
        mb: "20px",
        fontWeight: 400,
        lineHeight: "20px",
        fontSize: "12px",
        letterSpacing: "0.4px",
        color: "text.light",
    },
    container: {
        paddingBottom: "20px",
    },
    headerActionCol: {
        display: "grid",
        gridTemplateColumns: "repeat(2,20px)",
        gap: "20px",
    },
    gapBox: {
        display: "grid",
        gap: "20px",
    },
    boxGap: {
        display: "grid",
        gap: "20px",
    },
    tableAction: {
        display: "grid",
        gridTemplateColumns: "repeat(3,auto)",
        gap: "20px",
    },
    editDesc: {
        paddingTop: "10px",
        fontWeight: 500,
        lineHeight: 1.5,
        "& strong": {
            fontWeight: 900,
            lineHeight: 1.5,
        },
    },
    editDescTable: {
        fontWeight: 500,
        lineHeight: 1.5,
        mb: "20px",
        "& strong": {
            fontWeight: 900,
            lineHeight: 1.5,
        },
    },
    tooltipButtonStyle: {
        m: 0,
        p: 0,
    },
    doneIcon: {
        color: "im_mid_blue.500",
    },
};

export default styles;
