import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SecureSyncModal.styles";

const SecureSyncModal = ({ open, onClose, title, children, footer, maxWidth, fullWidth = false, width }) => {
    const handleClose = (event, reason) => {
        if (reason !== "backdropClick" && onClose) {
            onClose();
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                PaperProps={{
                    sx: { width },
                }}
            >
                <DialogTitle sx={styles.title}>{title}</DialogTitle>
                {onClose && (
                    <IconButton aria-label="close" onClick={onClose} sx={styles.closeButon}>
                        <CloseIcon />
                    </IconButton>
                )}
                <DialogContent dividers>{children}</DialogContent>
                <DialogActions>{footer}</DialogActions>
            </Dialog>
        </>
    );
};

export default SecureSyncModal;
