import { Box, Button, Container, TextField } from "@mui/material";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import styles from "./UserPinChange.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { resetAuthPin } from "src/services/adminService";
import { getUserDetails } from "src/redux/userDetailsSlice";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { getAuthInfo } from "src/services/authorizationService";
import { authLevelCode } from "src/constants/autherizationConstants/authConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        pinNumber: Yup.string()
            .required(t("changePin.pinNumberRequired"))
            .min(4, t("changePin.pinNotInPattern"))
            .matches(/^[0-9]*$/, t("changePin.pinNotInPattern")),
        pinNumberConfirm: Yup.string()
            .oneOf([Yup.ref("pinNumber"), null], t("changePin.pinNumberNotMatched"))
            .matches(/^[0-9]*$/, t("changePin.pinNotInPattern"))
            .required(t("changePin.confirmPinNumberRequired"))
            .min(4, t("changePin.pinNotInPattern")),
    });
const initValue = {
    pinNumber: "",
    pinNumberConfirm: "",
};
const UserPinChange = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            resetForm();
        },
    });
    const [isLoading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState("");
    const [sucessPopup, setSuccessPopup] = useState({
        type: "success",
    });
    const [activeCustomers, setActiveCustomers] = useState("");
    const params = useParams();

    const navigate = useNavigate();
    const user = useSelector(getUserDetails);

    const { t } = useTranslation();

    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initValue,
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            await formSubmit();
        },
    });
    useEffect(() => {
        if (params.userId) {
            fetchAuthInfo();
        }
    }, [params.userId]);

    const fetchAuthInfo = async (userId) => {
        const res = await getAuthInfo(userId);
        // setRaws
        setLoading(true);
        const tempActiveCustomers = [];

        if (res.authList && res.authList.length !== 0) {
            res.authList.forEach((customer) => {
                if (customer.dr_code !== authLevelCode.none || customer.interaction_code !== authLevelCode.none) {
                    tempActiveCustomers.push(customer.customer_number);
                }
            });
        }
        setActiveCustomers(tempActiveCustomers.toString());
        setLoading(false);
    };

    const formSubmit = async () => {
        setLoading(true);

        const res = await resetAuthPin(
            values.pinNumber,
            null,
            activeCustomers,
            displayName,
            displayName,
            user.email,
            true
        );
        if (!res.failure) {
            if (res.mailHasError) {
                setSnackBarMsg("messages.systemErrorMessage");
                setSuccessPopup({
                    type: "warning",
                });
                resetForm();
                handleOpenSnackbar();
            } else {
                setSnackBarMsg("changePin.pinChangeSuccess");
                setSuccessPopup({
                    type: "success",
                });
                resetForm();
                handleOpenSnackbar();
            }
        }
        setLoading(false);
    };

    const navigateToUserDetails = () => {
        navigate(-1);
    };

    const displayName = useMemo(() => {
        const middleName = user?.middle_name ? ` ${user.middle_name}.` : "";
        const givenName = user?.given_name || "";
        const familyName = user?.family_name || "";
        return `${givenName}${middleName} ${familyName}`;
    }, [user]);

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <SecureSyncSnackBar
                    openSnackBar={snackbarOpen}
                    message={
                        <Trans
                            i18nKey={snackBarMsg}
                            values={{
                                name: displayName ? displayName : "",
                            }}
                        />
                    }
                    severity={sucessPopup?.type ? sucessPopup.type : "success"}
                    onClose={handleCloseSnackbar}
                />
                <Box sx={styles.mainContainer}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle>{t("userDetails.changePin")}</PageTitle>
                    </Box>
                </Box>
                <form noValidate onSubmit={handleSubmit}>
                    <PageSubTitle>
                        <Trans i18nKey={"userDetails.enterPinSubmit"} />
                    </PageSubTitle>
                    <Box display="flex" gap={2} sx={styles.dataGridContainer}>
                        <TextField
                            label={t("userDetails.changePinTestBoxLabel")}
                            name="pinNumber"
                            variant="outlined"
                            type="password"
                            required
                            value={values.pinNumber}
                            onChange={handleChange}
                            inputProps={{ maxLength: 4 }}
                            error={touched.pinNumber && Boolean(errors.pinNumber)}
                            helperText={touched.pinNumber && errors.pinNumber}
                            fullWidth
                        />
                        <TextField
                            label={t("userDetails.confirmChangePinTestBoxLabel")}
                            variant="outlined"
                            name="pinNumberConfirm"
                            type="password"
                            required
                            value={values.pinNumberConfirm}
                            inputProps={{ maxLength: 4 }}
                            onChange={handleChange}
                            error={touched.pinNumberConfirm && Boolean(errors.pinNumberConfirm)}
                            helperText={touched.pinNumberConfirm && errors.pinNumberConfirm}
                            fullWidth
                        />
                    </Box>
                    <Box sx={styles.buttonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={navigateToUserDetails}
                            startIcon={<IconLeft />}
                        >
                            {t("buttonLabels.back")}
                        </Button>
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                            variant="outlined"
                            onClick={resetForm}
                            color="primary"
                        >
                            {t("buttonLabels.reset")}
                        </Button>
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.submit)}
                            variant="contained"
                            type="submit"
                            color="primary"
                            sx={styles.buttonSubmit}
                        >
                            {t("buttonLabels.submit")}
                        </Button>
                    </Box>
                </form>
            </Container>
        </>
    );
};

export default UserPinChange;
