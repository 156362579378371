import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import styles from "./Suggestions.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";
import { getRecipientAddress, sendEmail, submitFeedback } from "src/services/suggestionService";
import { getFeedbackText } from "src/utils/suggestionHelper";
import { insertLoggedEvent } from "src/services/processMediaService";
import { getPersonalName } from "src/services/utils";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";
import { dateFormats } from "src/constants/dateFormatConstants";
import { getDisplayFormat } from "src/utils/dateHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";

const validationSchema = (t) =>
    Yup.object({
        suggestion: Yup.string()
            .required(t("messages.suggestionsRequired"))
            .max(1940, t("messages.suggestionsMaxLength")),
    });

const Suggestions = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.clear,
        callback: () => {
            handleReset();
        },
    });

    const [isLoading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState([]);
    const { t } = useTranslation();
    const [feedbackSubmitStatus, setFeedbackSubmitStatus] = useState({
        status: false,
        dateTime: "",
    });
    const selectedCustomer = useSelector(getSelectedCustomer);

    const fetchEmailAddress = async () => {
        setLoading(true);
        const response = await getRecipientAddress();
        if (!response.failed) {
            const emailAddressList = response.rs0.map((obj) => obj.address);
            setEmailAddress([...emailAddressList]);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchEmailAddress();
    }, []);

    const { values, errors, touched, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
        initialValues: {
            suggestion: "",
        },
        validationSchema: validationSchema(t),
        onSubmit: async (values) => {
            setLoading(true);

            const feedbackText = getFeedbackText(values.suggestion);
            const subject = "Feedback";
            const { feedbackTime, feedbackTimeForEmail, localizedFeedbackTime } = getFormattedDates();
            const response = await submitFeedback(feedbackText, feedbackTime);
            if (!response.failed) {
                const emailResponse = await sendEmail(
                    values.suggestion,
                    getAddressList(),
                    subject,
                    feedbackTimeForEmail
                );
                if (!emailResponse.failed) {
                    setFeedbackSubmitStatus({
                        status: true,
                        dateTime: localizedFeedbackTime,
                    });
                    await insertLog(values.suggestion.length);
                }
            }
            setLoading(false);
        },
    });

    const getFormattedDates = () => {
        const feedbackTime = dayjs.utc().format(dateFormats.timeStampWithMilliSeconds);

        const dayjsObject = dayjs.utc(feedbackTime, dateFormats.timeStampWithMilliSeconds);
        const emailDateTime = dayjsObject.format(dateFormats.emailDateTime);

        const format = `${getDisplayFormat()}${dateFormats.timeWithGmtStamp}`;
        const localizedGMT = dayjsObject.format(format);
        return {
            feedbackTime,
            feedbackTimeForEmail: emailDateTime,
            localizedFeedbackTime: localizedGMT,
        };
    };

    const getAddressList = () => {
        return emailAddress.join(";");
    };

    const insertLog = async (feedbackLength) => {
        const comment = `EMailGroupCode=feedback~FeedbackText Len is = ${feedbackLength}`;
        await insertLoggedEvent(comment, "Send feedback", "", "1");
    };

    const handleReset = () => {
        resetForm({ values: { suggestion: "" } });
    };

    const personalName = getPersonalName();
    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Box>
                <PageTitle>{t("titles.suggestions")}</PageTitle>
                {feedbackSubmitStatus.status && (
                    <Box>
                        <SecureSyncAlert message={t("suggestions.successMsg")} severity="success" />
                        <Box sx={styles.title}>{t("suggestions.suggestionText")}:</Box>
                        <Box>{values.suggestion}</Box>
                        <Box>-- {personalName}</Box>
                        <Box>
                            {t("formFields.customer")} #: {selectedCustomer?.customer_number}
                        </Box>
                        <br />
                        <Box sx={styles.title}>{t("formFields.date")}: </Box>
                        <Box>{feedbackSubmitStatus.dateTime}</Box>
                    </Box>
                )}
                {!feedbackSubmitStatus.status && (
                    <>
                        <Typography variant="body1" sx={styles.bodyText}>
                            <Trans
                                i18nKey={"suggestions.pageDesc"}
                                values={{
                                    buttonLabel: t("common.submit"),
                                }}
                            />
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <Box sx={styles.textField}>
                                <TextField
                                    id="suggestion"
                                    name="suggestion"
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    value={values.suggestion}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.suggestion && Boolean(errors.suggestion)}
                                    helperText={touched.suggestion && errors.suggestion}
                                    InputProps={{
                                        sx: {
                                            overflow: "auto",
                                        },
                                    }}
                                    rows={4}
                                />
                            </Box>

                            <div>
                                <Divider />
                                <Box sx={styles.buttonContainer}>
                                    <Button
                                        title={getKeyBoardLabel(shortCutKeys.reset)}
                                        id="reset"
                                        variant="outlined"
                                        color="primary"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        {t("buttonLabels.clear")}
                                    </Button>
                                    <Button
                                        title={getKeyBoardLabel(shortCutKeys.submit)}
                                        id="submit"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        {t("buttonLabels.submit")}
                                    </Button>
                                </Box>
                            </div>
                        </form>
                    </>
                )}
            </Box>
        </>
    );
};

export default Suggestions;
