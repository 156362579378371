import { Box, Button, Container, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Comments.styles";
import { getCustomerAuthInfo, updateAuthorizationComments } from "src/services/authorizationService";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { programIds } from "src/constants/programIdsConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object({
        comments: Yup.string().max(2048, t("messages.maxLength2048")),
    });

const Comments = () => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            handleReset();
        },
    });
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [oldComment, setOldComment] = useState("");
    const [timeStamp, setTimeStamp] = useState("");
    const userLevelID = useSelector(getProgramIdsOfCurrentCustomer);
    const hasWriteAccess = isUserProgramLevelAvailable(userLevelID, programIds.programId_106) ? true : false;
    const handleOpenSnackbar = () => {
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const oldComment = await getCustomerAuthInfo();
            const commentMessage = oldComment?.authData?.authorization_comment;
            setOldComment(commentMessage);
            setTimeStamp(oldComment?.authData?.timestamp);
            setFieldValue("comments", commentMessage);
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { values, errors, touched, handleBlur, handleChange, resetForm, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            comments: "",
        },
        validationSchema: validationSchema(t),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            await updateAuthorizationComments(values.comments, timeStamp);
            setOldComment(values.comments);
            setLoading(false);
            handleOpenSnackbar();
            resetForm({ values: { comments: values.comments } });
        },
    });

    const handleReset = () => {
        resetForm({ values: { comments: oldComment } });
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <Container>
                <Box sx={styles.container}>
                    <Box sx={styles.innerContainer}>
                        <PageTitle> {t("comments.authorizationComments")}</PageTitle>
                        <SecureSyncSnackBar
                            openSnackBar={snackbarOpen}
                            message={t("comments.successMsg")}
                            severity="success"
                            onClose={handleCloseSnackbar}
                        />
                        <Typography variant="body1" sx={styles.bodyText}>
                            <Trans i18nKey={hasWriteAccess ? "comments.description" : "comments.readOnlyDesc"} />
                        </Typography>
                    </Box>
                </Box>

                <form onSubmit={handleSubmit}>
                    <Box sx={styles.textField}>
                        <TextField
                            id="authorization-comments"
                            name="comments"
                            label={t("comments.authorizationComments")}
                            variant="outlined"
                            multiline
                            fullWidth
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!hasWriteAccess}
                            error={touched.comments && Boolean(errors.comments)}
                            helperText={touched.comments && errors.comments}
                            InputProps={{
                                sx: {
                                    overflow: "auto",
                                },
                            }}
                            rows={4}
                            maxRows={10}
                        />
                    </Box>
                    {hasWriteAccess && (
                        <div>
                            <Divider />
                            <Box sx={styles.buttonContainer}>
                                <Button
                                    id="cancel"
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={() => navigate(dashboard)}
                                >
                                    {t("buttonLabels.cancel")}
                                </Button>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.reset)}
                                    id="reset"
                                    variant="outlined"
                                    color="primary"
                                    type="reset"
                                    onClick={handleReset}
                                >
                                    {t("common.reset")}
                                </Button>
                                <Button
                                    title={getKeyBoardLabel(shortCutKeys.submit)}
                                    id="submit"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {t("common.submit")}
                                </Button>
                            </Box>
                        </div>
                    )}
                </form>
            </Container>
        </>
    );
};

export default Comments;
