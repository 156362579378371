import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styles from "./ContainerInformation.styles";
import RequestedTransport from "./RequestedContainers/RequestedTransport";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { groupByInsertTypeId } from "src/utils/newContainerLockInsertHelper";
import { requestSortCriteria, transportTypeConst } from "src/constants/requestConstants";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { staticFile } from "src/constants/fileConstants";
import { openFileFromFB } from "src/services/utils";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { multipleSort } from "src/utils/processMediaHelper";
import { updatedDropdownData } from "src/utils/requestHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    vaultId: "",
    transportTypeId: "",
    transportQuantity: "",
    isLocks: true,
    lockTypeId: "",
    insertTypeIds: [],
};

const transportQtyRange = {
    min: 1,
    max: 150,
};
const validationSchema = (t, isEmptyTransport) => {
    const transportQtyErrorMessage = t("messages.transportQtyRange", {
        min: transportQtyRange.min,
        max: transportQtyRange.max,
    });
    return Yup.object().shape({
        vaultId: Yup.string().when("transportType", {
            is: () => !isEmptyTransport,
            then: () => Yup.string().required(t("messages.vaultIdRequired")),
            otherwise: () => Yup.string().notRequired(),
        }),
        transportTypeId: Yup.string().required(t("messages.transportTypeRequired")),
        transportQuantity: Yup.number()
            .typeError(transportQtyErrorMessage)
            .required(t("messages.transportQtyRequired"))
            .integer(transportQtyErrorMessage)
            .min(transportQtyRange.min, transportQtyErrorMessage)
            .max(transportQtyRange.max, transportQtyErrorMessage),
        isLocks: Yup.boolean().when("transportType", {
            is: () => !isEmptyTransport,
            then: () => Yup.boolean().required(),
            otherwise: () => Yup.boolean().notRequired(),
        }),
        insertTypeIds: Yup.array().of(Yup.string().required(t("messages.insertConfigRequired"))),
    });
};
const ContainerInformation = ({
    insertTypes,
    vaultIds,
    transportTypes,
    requestedTransports,
    onFormChange,
    setRequestedTransports,
    setTransportType,
    onClear,
}) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTransportType, setSelectedTransportType] = useState(transportTypeConst.tempTransport);
    const [configs, setConfigs] = useState([]);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.add, callback: () => handleSubmit() });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.clear,
        callback: () => handleResetForm(),
    });
    const insertTypesOptions = useMemo(() => {
        if (!insertTypes) {
            return [];
        }
        const sortedInsertTypes = multipleSort(updatedDropdownData(insertTypes), requestSortCriteria.comboBoxSort);
        return sortedInsertTypes.map(({ insert_type_id: typeId, descr: desc }) => {
            return { id: typeId.toString(), value: typeId.toString(), label: desc };
        });
    }, [insertTypes]);

    const vaultOptions = useMemo(() => {
        if (!vaultIds) {
            return [];
        }
        const sortedVault = multipleSort(updatedDropdownData(vaultIds), requestSortCriteria.comboBoxSort);
        return sortedVault.map(({ code_value: vaultCode, id }) => {
            return { id: id, value: id, label: vaultCode };
        });
    }, [vaultIds]);

    const transportTypeOptions = useMemo(() => {
        if (!transportTypes) {
            return [];
        }
        const sortedTransportType = multipleSort(updatedDropdownData(transportTypes), requestSortCriteria.comboBoxSort);
        return sortedTransportType.map(({ code_value: transportType, id }) => {
            return { id: id.toString(), value: id.toString(), label: transportType };
        });
    }, [transportTypes]);

    const isEmptyTransport = selectedTransportType === transportTypeConst.tempTransport;

    const {
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        resetForm,
        setFieldTouched,
    } = useFormik({
        initialValues,
        validationSchema: validationSchema(t, isEmptyTransport),
        onSubmit: async (values) => {
            await formSubmit(values);
        },
    });

    const maxInsertsAllowed = useMemo(() => {
        const selectedTransportType = transportTypes?.find(
            (transportType) => transportType.id === values.transportTypeId
        );
        return selectedTransportType ? parseInt(selectedTransportType.inserts_required) : 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.transportTypeId]);

    const getInitialFormValues = useCallback(() => {
        return {
            ...initialValues,
            vaultId: vaultOptions[0]?.value,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vaultOptions]);

    useEffect(() => {
        resetForm({ values: getInitialFormValues() });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInitialFormValues]);

    useEffect(() => {
        onFormChange(dirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    const formSubmit = async (values) => {
        const vaultLabel = vaultOptions.find((vaultOption) => vaultOption.value === values.vaultId).label;
        const transportTypeLabel = transportTypeOptions.find(
            (transportTypeOption) => transportTypeOption.value === values.transportTypeId
        ).label;

        const insertTypeList = groupByInsertTypeId(values.insertTypeIds, insertTypesOptions);

        const newContainer = {
            ...values,
            vaultLabel,
            transportTypeLabel,
            insertTypeList,
            id: uuidv4(),
        };
        const newRequestedTransports = [...requestedTransports];
        newRequestedTransports.push(newContainer);
        setRequestedTransports(newRequestedTransports);
        handleResetForm();
        setConfigs([]);
    };

    const handleResetForm = () => {
        resetForm({
            values: getInitialFormValues(),
        });
        onClear();
        setConfigs([]);
    };

    useEffect(() => {
        const setInsertValues = async () => {
            let updatedArray = [];
            if (configs.length < maxInsertsAllowed) {
                updatedArray = [...configs];
                while (updatedArray.length < maxInsertsAllowed) {
                    updatedArray.push(configs[0] || "");
                }
                setConfigs(updatedArray);
                await setFieldValue("insertTypeIds", updatedArray);
            } else {
                if (maxInsertsAllowed > 0) {
                    updatedArray = [...configs];
                    updatedArray = updatedArray.slice(0, maxInsertsAllowed);
                }
            }
            await setFieldValue("insertTypeIds", updatedArray);
            setFieldTouched("insertTypeIds", []);
        };

        setInsertValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxInsertsAllowed]);

    const onClickDelete = (id) => {
        const newRequestedTransports = requestedTransports.filter((container) => container.id !== id);
        setRequestedTransports(newRequestedTransports);
    };

    const onInsertConfigChange = (event, index) => {
        const isAnyOneHasValue = values.insertTypeIds.find((typeId) => typeId);
        if (!isAnyOneHasValue) {
            const insertTypeIds = [...Array(maxInsertsAllowed).keys()].map((key) => {
                return event.target.value;
            });

            setConfigs(insertTypeIds);
            setFieldValue("insertTypeIds", insertTypeIds);
        } else {
            const configArray = [...configs];
            configArray[index] = event.target.value;
            setConfigs(configArray);
            handleChange(event);
        }
    };

    const onTooltipClick = async () => {
        setIsLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
        const filePath = generateFilePathForCurrentLocale(bucketPath, staticFile["containerInsertGuide"]);
        const url = await openFileFromFB(filePath);
        if (url) {
            if (url.failed) {
                window.open("#", "_blank");
            } else {
                window.open(url, "_blank");
            }
        } else {
            window.open("#", "_blank");
        }
        setIsLoading(false);
    };

    return (
        <Box>
            <CircularLoaderFullPage show={isLoading} />
            <form noValidate>
                <Box>
                    <Box sx={styles.locksLabel}>
                        <PageSubTitle>{t("titles.transportInformation")}</PageSubTitle>
                        <SecureSyncIconTooltip
                            title={
                                <Typography sx={styles.tooltip}>
                                    {t("newOrEmptyTransport.transportContainerInfo")}
                                </Typography>
                            }
                        />
                    </Box>
                    <Typography sx={styles.marginBottom20}>
                        <Trans i18nKey={"newOrEmptyTransport.transportInformationDescription"} />
                    </Typography>
                    <FormControl sx={styles.marginBottom20}>
                        <RadioGroup
                            row
                            name="transportType"
                            value={selectedTransportType}
                            onChange={(event) => {
                                setTransportType(event.target.value);
                                setSelectedTransportType(event.target.value);
                            }}
                        >
                            <FormControlLabel
                                value={transportTypeConst.tempTransport}
                                control={<Radio />}
                                label={t("newOrEmptyTransport.emptyPermanentTemporaryTransport")}
                                disabled={requestedTransports.length > 0}
                            />
                            <FormControlLabel
                                value={transportTypeConst.permanentTransport}
                                control={<Radio />}
                                label={t("newOrEmptyTransport.issueTransportsPermanently")}
                                disabled={requestedTransports.length > 0}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Divider />
                <PageSubTitle>
                    {isEmptyTransport
                        ? t("newOrEmptyTransport.emptyTransportInformation")
                        : t("newOrEmptyTransport.newPermanentTransportInformation")}
                </PageSubTitle>
                <Box sx={styles.fourColumnLayout}>
                    {!isEmptyTransport && (
                        <SecureSyncSelect
                            required
                            error={touched.vaultId && Boolean(errors.vaultId)}
                            helperText={touched.vaultId && errors.vaultId}
                            showBlankOption={vaultOptions.length > 1}
                            label={t("formFields.vaultID")}
                            options={vaultOptions}
                            name="vaultId"
                            value={values.vaultId}
                            onChange={handleChange}
                        />
                    )}
                    <Box sx={styles.transportTypeSection}>
                        <SecureSyncSelect
                            required
                            showBlankOption
                            error={touched.transportTypeId && Boolean(errors.transportTypeId)}
                            helperText={touched.transportTypeId && errors.transportTypeId}
                            value={values.transportTypeId}
                            label={t("formFields.transportType")}
                            options={transportTypeOptions}
                            name="transportTypeId"
                            onChange={handleChange}
                        />
                    </Box>
                    <TextField
                        inputProps={{ maxLength: 3 }}
                        required
                        error={touched.transportQuantity && Boolean(errors.transportQuantity)}
                        helperText={touched.transportQuantity && errors.transportQuantity}
                        value={values.transportQuantity}
                        label={t("formFields.transportQuantity")}
                        name="transportQuantity"
                        onChange={handleChange}
                    />
                    {!isEmptyTransport && (
                        <Box sx={styles.locksFieldWrapper}>
                            <Switch checked={values.isLocks} name="isLocks" onChange={handleChange} />
                            <Box sx={styles.locksLabel}>
                                <Trans i18nKey={"formFields.locks"} />?
                            </Box>
                            <Typography sx={styles.locksCaption} variant="caption">
                                <Trans i18nKey={"newContainerLockInsert.locksCaption"} />
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box>
                    <Box sx={styles.insertConfigurationsSection}>
                        <Typography fontWeight={500}>
                            <Trans i18nKey={"formFields.insertConfiguration"} />
                        </Typography>
                        <SecureSyncIconTooltip
                            buttonSx={{ py: "0px" }}
                            onClick={onTooltipClick}
                            title={
                                <Box>
                                    <Typography sx={styles.cmiTooltipDesc}>
                                        <Trans i18nKey={"newContainerLockInsert.insertConfigurationTooltip"} />
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                    {values.transportTypeId && maxInsertsAllowed === 0 && (
                        <Box sx={styles.marginBottom20}>{t("common.notApplicable")}</Box>
                    )}
                    {maxInsertsAllowed > 0 && (
                        <Box sx={styles.fourColumnLayout} key={`insert-config-dropdowns-${maxInsertsAllowed}`}>
                            {[...Array(maxInsertsAllowed).keys()].map((index) => (
                                <SecureSyncSelect
                                    key={index}
                                    label={t("formFields.insertConfiguration")}
                                    error={touched.insertTypeIds?.[index] && Boolean(errors.insertTypeIds?.[index])}
                                    helperText={touched.insertTypeIds?.[index] && errors.insertTypeIds?.[index]}
                                    required
                                    showBlankOption
                                    value={values.insertTypeIds[index]}
                                    name={`insertTypeIds.${index}`}
                                    options={insertTypesOptions}
                                    onChange={(event) => onInsertConfigChange(event, index)}
                                    onBlur={handleBlur}
                                />
                            ))}
                        </Box>
                    )}
                </Box>

                <Typography sx={styles.linkSection}>
                    <Trans
                        i18nKey="newOrEmptyTransport.transportNotListed"
                        components={{
                            Link: <Link href={`mailto:${process.env.REACT_APP_HELP_DESK_EMAIL}`} />,
                        }}
                    />
                </Typography>
                <Box sx={styles.btnSection}>
                    <Button
                        variant="outlined"
                        type="reset"
                        onClick={handleResetForm}
                        title={getKeyBoardLabel(shortCutKeys.clear)}
                    >
                        <Trans i18nKey={"buttonLabels.clear"} />
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                        title={getKeyBoardLabel(shortCutKeys.add)}
                    >
                        <Trans i18nKey={"buttonLabels.add"} />
                    </Button>
                </Box>
                <RequestedTransport
                    records={requestedTransports}
                    onRemoveContainer={onClickDelete}
                    isEmptyTransport={isEmptyTransport}
                />
                <Divider />
            </form>
        </Box>
    );
};

export default ContainerInformation;
