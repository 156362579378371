const styles = {
    contactFieldContainer: {
        paddingBottom: "20px",
    },
    cardContent: {
        margin: "20px 0",
    },
};

export default styles;
