import { getDocs } from "firebase/firestore";
import { t } from "i18next";
import dayjs from "dayjs";
import {
    apiResultSet0Collection,
    apiResultSet1Collection,
    apiResultSet2Collection,
    apiResultSet3Collection,
} from "./firebase";
import {
    constructFbHeaders,
    createArrayObj,
    getLanguageLocaleId,
    getLoggedUserDetails,
    getPersonId,
    getResultSets,
    invokeApi,
    getCustomers,
    getPersonalId,
    getSelectedCustomerDb,
    getSelectedCustomerId,
    getPersonalLocalId,
} from "./utils";
import { traceWrapper } from "./firebasePerformance";
import { requestActionIds } from "src/constants/requestConstants";

import {
    generateBidMailBody,
    generateLibraryAuditMailBody,
    newAuthCardBody,
    newCustomerMailBody,
} from "src/utils/mailHelper";

import { dateFormats } from "src/constants/dateFormatConstants";
import { serviceType } from "src/constants/miscConstants";
import { noFlag } from "src/constants/serviceConstants";
import { formatDate, getSelectedCustomerLocaleTime } from "src/utils/dateHelper";

const apiUrls = {
    getMatchingRequest: "/request/getmatchingrequest",
    getDestructionMediaRequest: "/request/getdestructionmediarequest",
    createDeliveryMediaRequest: "/request/createdeliverymediarequest",
    addOnCutOffTimeAndGetServiceDetails: "/request/addoncutofftimeandgetservicedetails",
    getActiveCustomerSiblings: "/request/getactivecustomersibilings",
    createContainerOrTransportRequest: "/request/createcontainerortransportrequest",
    getServiceDateForCustomer: "/request/getservicedateforcustomer",
    createTemporaryMediaDeliveryRecord: "/request/createtemporymediadeliveryrecourd",
    createTemporaryTransportRecord: "/request/createtemporytransportrecord",
    createTemporaryContainerDeliveryRecord: "/request/createtemporycontainerdeliveryrecord",
    createOtherRequest: "/request/createotherrequest",
    getRequestDetails: "/request/getrequestdetails",
    getEligibleCustomersSchedules: "/request/geteligiblecustomerschedules",
    getComboBoxDescription: "/request/getcomboboxdescription",
    getCustomerServiceFrequency: "/request/getcustomerservicefrequency",
    validateCustomerOnCall: "/request/validatecustomeroncall",
    getRequest: "/request/getrequest",
    validateRequestRelation: "/request/validaterequestrelation",
    verifyRequestCancellation: "/request/verifyrequestcancellation",
    getOmList: "/request/getomlist",
    getRequestContainerDetails: "/request/getrequestcontainerdetails",
    getRequestMediaDetails: "/request/getrequestmediadetails",
    getMediaDestructionRequestDetails: "/request/getmediadestructionrequestdetails",
    validateVolserSelection: "/request/validatevolserselection",
    getIronMountainCustomerContainers: "/request/getatironmountaincustomercontainers",
    getSingleRequestData: "/request/getsinglerequestdata",
    getRequestTransportInfo: "/request/getrequesttransportinfo",
    scheduleRequests: "/request/reshedulerequestsandmanagetransport",
    sendMail: "/request/sendmail",
    getDeleteContainers: "/request/getdeletecontainers",
    requestDelete: "/request/requestupdate",
    requestCancel: "/request/requestcancel",
    updateDestructionRequestStatus: "/request/updatedestructionrequeststatus",
    getMiscServiceInfo: "/request/getmiscserviceinfo",
    validatePhone: "/request/validatephone",
};
/**
 * func name searchRequestService
 * @returns
 * @param {string} serviceDateFrom
 * @param {string} serviceDateTo
 * @param {string} requestTypeId
 * @param {string} requestStatusId
 * @param {string} requestId
 * @param {string} localeId
 * @param {string} namedSort
 * @param {string} sortDescending
 * @param {string} languageLocaleId
 * this will return @array with request details
 */
export const searchRequestService = async ({
    serviceDateFrom = "",
    serviceDateTo = "",
    requestTypeId = "",
    requestStatusId = "",
    requestId,
    localeId,
    namedSort,
    sortDescending,
    languageLocaleId,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getMatchingRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;
    if (!selectedCustomer) {
        return new Error("no selected customer");
    }
    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getmatchingrequest`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        service_date_from: serviceDateFrom,
        service_date_to: serviceDateTo,
        request_type_id: requestTypeId,
        request_status_id: requestStatusId,
        customer_id: selectedCustomer.customer_id,
        request_id: requestId,
        locale_id: localeId,
        named_sort: namedSort,
        sort_descending: sortDescending,
        language_locale_id: languageLocaleId,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return resultSet0;
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name searchDestructedRequestService
 * @returns
 * @param {string} customerId
 * @param {string} destructRequestTypeId
 * @param {string} destructRequestStatusId
 * @param {string} destructRequestId
 * @param {string} dateFrom
 * @param {string} dateTo
 * @param {string} languageLocaleId
 * @param {string} localeId
 * @param {string} rowLimit
 * @param {string} trackingNumber
 * @param {string} authorizedPendingReviewStatusFlag
 * this will return @array with request details
 */
export const searchDestructedRequestService = async ({
    destructRequestTypeId = "",
    destructRequestStatusId = "",
    destructRequestId = "",
    serviceDateFrom = "",
    serviceDateTo = "",
    localeId = "1",
    rowLimit = "1000",
    trackingNumber = "",
    authorizedPendingReviewStatusFlag = "N",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDestructionMediaRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;
    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getdestructionmediarequest`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id, // need check with customer
        destruct_request_type_id: destructRequestTypeId,
        destruct_request_status_id: destructRequestStatusId,
        destruct_request_id: destructRequestId,
        date_from: serviceDateFrom,
        date_to: serviceDateTo,
        language_locale_id: getLanguageLocaleId(),
        locale_id: localeId,
        row_limit: rowLimit,
        system_id: "50",
        tracking_number: trackingNumber,
        authorized_pending_review_status_flag: authorizedPendingReviewStatusFlag,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const tableData = resultSet0.map((row) => {
            return {
                ...row,
                Destruct_Request_Id: parseInt(row.Destruct_Request_Id),
            };
        });
        return tableData;
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createDeliveryMediaRequestService
 * @returns
 * @param {obj} mediaRequest
 * this will return @bool with request details
 */
export const createDeliveryMediaRequestService = async ({
    serviceDate = "01/01/2000",
    comment = "",
    openMediaList,
    openMediaSeqList,
    containerList,
    cslCustomerId = "0",
    validateOnlyFlag = "Y",
    lastModUser = "",
    invalidContainerFollowupMsg = t("localizedServiceDetails.invalidContainerFollowupMsg"),
    deliveredFollowupMsg = t("localizedServiceDetails.deliveredFollowupMsg"),
    toBeIssuedFollowupMsg = t("localizedServiceDetails.toBeIssuedFollowupMsg"),
    discrepancyFollowupMsg = t("localizedServiceDetails.discrepancyFollowupMsg"),
    deletedFollowupMsg = t("localizedServiceDetails.deletedFollowupMsg"),
    containsOpenMediaFollowupMsg = t("localizedServiceDetails.containsOpenMediaFollowupMsg"),
    containerInTransFollowupMsg = t("localizedServiceDetails.containerInTransFollowupMsg"),
    mediaNotFoundStatus = t("localizedServiceDetails.mediaNotFoundStatus"),
    possibleMatchesStatus = t("localizedServiceDetails.possibleMatchesStatus"),
    duplicatesStatus = t("localizedServiceDetails.duplicatesStatus"),
    sentToText = t("localizedServiceDetails.sentToText"),
    mediaInMdrStatus = t("localizedServiceDetails.mediaInMdrStatus"),
    mediaInMdrFollowup = t("localizedServiceDetails.mediaInMdrFollowup"),
    requestType = "",
    requestId = "",
    requestStatus = "",
    volsersInMdrList = "",
    addonCutoffFlag = "N",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createDeliveryMediaRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createdeliverymediarequest`;
    const body = {
        personnel_id: getPersonId(),
        language_locale_id: getLanguageLocaleId(),
        service_date: serviceDate,
        comment: comment,
        open_media_list: openMediaList,
        open_media_seq_list: openMediaSeqList,
        container_list: containerList,
        csl_customer_id: cslCustomerId,
        validate_only_flag: validateOnlyFlag,
        last_mod_user: lastModUser,
        invalid_container_followup_msg: invalidContainerFollowupMsg,
        delivered_followup_msg: deliveredFollowupMsg,
        to_be_issued_followup_msg: toBeIssuedFollowupMsg,
        discrepancy_followup_msg: discrepancyFollowupMsg,
        deleted_followup_msg: deletedFollowupMsg,
        contains_open_media_followup_msg: containsOpenMediaFollowupMsg,
        container_in_trans_followup_msg: containerInTransFollowupMsg,
        media_not_found_status: mediaNotFoundStatus,
        possible_matches_status: possibleMatchesStatus,
        duplicates_status: duplicatesStatus,
        sent_to_text: sentToText,
        media_in_mdr_status: mediaInMdrStatus,
        media_in_mdr_followup: mediaInMdrFollowup,
        request_type: requestType,
        request_id: requestId,
        request_status: requestStatus,
        volsers_in_mdr_list: volsersInMdrList,
        addon_cutoff_flag: addonCutoffFlag,
        main_district_id: getSelectedCustomerDb(),
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return { data: resultSet0, error: resultSet1 };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getCutOffTimeDetailsService
 * @returns
 * this will return @bool with request details
 */
export const getCutOffTimeDetailsService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.addOnCutOffTimeAndGetServiceDetails);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/addoncutofftimeandgetservicedetails`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return response;
        }

        return {
            resultSet0: response?.results[0],
            resultSet1: response?.results[1],
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name geActiveCustomersSiblingsService
 * @returns
 * this will return @array with request details
 */
export const getActiveCustomersSiblingsService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getActiveCustomerSiblings);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getactivecustomersibilings`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return resultSet0;
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createTransportRequestSample
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const createTransportRequestService = async ({
    serviceDate,
    comment,
    detailData,
    cslCustomerId,
    addonCutoffFlag,
    requestType = "1",
    requestId = "",
    requestStatus = "",
    requestActionId = requestActionIds.closedContainerDetails,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createContainerOrTransportRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createcontainerortransportrequest`;
    const body = {
        personnel_id: getPersonId(),
        service_date: serviceDate,
        request_action_id: requestActionId,
        comment: comment,
        detail_data: detailData,
        csl_customer_id: cslCustomerId,
        last_mod_user: lastModifiedUser,
        request_type: requestType,
        request_id: requestId,
        request_status: requestStatus,
        addon_cutoff_flag: addonCutoffFlag,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        if (!res.hasResults) {
            return { success: false };
        }
        const response = await getResultSets(res.docId, 5);
        const duplicateLabels = response?.results[0]?.[0]?.dup_customer_media_descr_list;
        if (response.failure) {
            return {
                success: false,
                duplicateCustomerMediaDescList: duplicateLabels,
                error: response.error,
                displayMessage: response.displayMessage,
                severity: response.severity,
            };
        }

        return {
            success: response?.results[4]?.[0].error === "0",
            duplicateCustomerMediaDescList: duplicateLabels,
            requestType: response?.results[1]?.[0].request_type,
            requestId: response?.results[2]?.[0].request_id,
            requestStatus: response?.results[3]?.[0].request_status,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getServiceDateForCustomerService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getServiceDateForCustomerService = async ({ fromDate, toDate, rescheduleFlag, addonCutoffFlag }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getServiceDateForCustomer);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getservicedateforcustomer`;
    const body = {
        from_date: fromDate || "",
        to_date: toDate || "",
        reschedule_flag: rescheduleFlag || "",
        addon_cutoff_flag: addonCutoffFlag || "",
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createTemporyMediaDeliveryRecordService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const createTemporyMediaDeliveryRecordService = async ({
    requestActionId,
    detailFlag,
    requestTypeId,
    dayOfWeekCode,
    exchangeLocationTypeCode,
    serviceDate,
    employeeId,
    employeeComment,
    personnelId,
    personnelComment,
    confirmPersonnelId,
    comment,
    nonBillableSpecialFlag,
    nonBillableSpecialComment,
    runPositionId,
    requestStatusId,
    cslCustomerId,
    parentRequestId,
    data,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createTemporaryMediaDeliveryRecord);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createtemporymediadeliveryrecourd`;
    const body = {
        request_action_id: requestActionId,
        detail_flag: detailFlag,
        request_type_id: requestTypeId,
        day_of_week_code: dayOfWeekCode,
        exchange_location_type_code: exchangeLocationTypeCode,
        service_date: serviceDate,
        employee_id: employeeId,
        employee_comment: employeeComment,
        personnel_id: personnelId,
        personnel_comment: personnelComment,
        confirm_personnel_id: confirmPersonnelId,
        comment: comment,
        non_billable_special_flag: nonBillableSpecialFlag,
        non_billable_special_comment: nonBillableSpecialComment,
        run_position_id: runPositionId,
        request_status_id: requestStatusId,
        csl_customer_id: cslCustomerId,
        parent_request_id: parentRequestId,
        data: data,
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            request: resultSet0[0] ? resultSet0[0] : null,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createTemporyTransportRecordService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const createTemporyTransportRecordService = async ({
    requestActionId,
    detailFlag,
    requestTypeId,
    dayOfWeekCode,
    exchangeLocationTypeCode,
    serviceDate,
    employeeId,
    employeeComment,
    personnelId,
    personnelComment,
    confirmPersonnelId,
    comment,
    nonBillableSpecialFlag,
    nonBillableSpecialComment,
    runPositionId,
    requestStatusId,
    cslCustomerId,
    parentRequestId,
    data,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createTemporaryTransportRecord);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createtemporytransportrecord`;
    const body = {
        request_action_id: requestActionId,
        detail_flag: detailFlag,
        request_type_id: requestTypeId,
        day_of_week_code: dayOfWeekCode,
        exchange_location_type_code: exchangeLocationTypeCode,
        service_date: serviceDate,
        employee_id: employeeId,
        employee_comment: employeeComment,
        personnel_id: personnelId,
        personnel_comment: personnelComment,
        confirm_personnel_id: confirmPersonnelId,
        comment: comment,
        non_billable_special_flag: nonBillableSpecialFlag,
        non_billable_special_comment: nonBillableSpecialComment,
        run_position_id: runPositionId,
        request_status_id: requestStatusId,
        csl_customer_id: cslCustomerId,
        parent_request_id: parentRequestId,
        data: data,
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            request: resultSet0[0] ? resultSet0[0] : null,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createEmployeContainerDeliveryRecordService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const createEmployeContainerDeliveryRecordService = async ({
    requestActionId,
    detailFlag,
    requestTypeId,
    dayOfWeekCode,
    exchangeLocationTypeCode,
    serviceDate,
    employeeId,
    employeeComment,
    personnelId,
    personnelComment,
    confirmPersonnelId,
    comment,
    nonBillableSpecialFlag,
    nonBillableSpecialComment,
    runPositionId,
    requestStatusId,
    cslCustomerId,
    parentRequestId,
    data,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createTemporaryContainerDeliveryRecord);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createtemporycontainerdeliveryrecord`;
    const body = {
        request_action_id: requestActionId,
        detail_flag: detailFlag,
        request_type_id: requestTypeId,
        day_of_week_code: dayOfWeekCode,
        exchange_location_type_code: exchangeLocationTypeCode,
        service_date: serviceDate,
        employee_id: employeeId,
        employee_comment: employeeComment,
        personnel_id: personnelId,
        personnel_comment: personnelComment,
        confirm_personnel_id: confirmPersonnelId,
        comment: comment,
        non_billable_special_flag: nonBillableSpecialFlag,
        non_billable_special_comment: nonBillableSpecialComment,
        run_position_id: runPositionId,
        request_status_id: requestStatusId,
        csl_customer_id: cslCustomerId,
        parent_request_id: parentRequestId,
        data: data,
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            request: resultSet0[0] ? resultSet0[0] : null,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name createOtherRequsetService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const createOtherRequsetService = async ({
    requestActionId,
    detailFlag,
    requestTypeId,
    dayOfWeekCode,
    exchangeLocationTypeCode,
    serviceDate,
    employeeId,
    employeeComment,
    personnelId,
    personnelComment,
    confirmPersonnelId,
    comment,
    nonBillableSpecialFlag,
    nonBillableSpecialComment,
    runPositionId,
    requestStatusId,
    cslCustomerId,
    parentRequestId,
    openMediaList,
    systemFunctionId,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.createOtherRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/createotherrequest`;
    const body = {
        request_action_id: requestActionId,
        detail_flag: detailFlag,
        request_type_id: requestTypeId,
        day_of_week_code: dayOfWeekCode,
        exchange_location_type_code: exchangeLocationTypeCode,
        service_date: serviceDate,
        employee_id: employeeId,
        employee_comment: employeeComment,
        personnel_id: personnelId,
        personnel_comment: personnelComment,
        confirm_personnel_id: confirmPersonnelId,
        comment: comment,
        non_billable_special_flag: nonBillableSpecialFlag,
        non_billable_special_comment: nonBillableSpecialComment,
        run_position_id: runPositionId,
        request_status_id: requestStatusId,
        csl_customer_id: cslCustomerId,
        parent_request_id: parentRequestId,
        open_media_list: openMediaList,
        system_function_id: systemFunctionId,
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            request: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestDetailsService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestDetailsService = async ({ requestId, localeId = "1" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRequestDetails);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getrequestdetails`;
    const body = {
        request_id: requestId,
        locale_id: getPersonalLocalId() || "1",
        language_locale_id: localeId || "1",
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            request: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getEligibleCustomerSchedulesService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getEligibleCustomerSchedulesService = async ({ rescheduleDate }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getEligibleCustomersSchedules);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/geteligiblecustomerschedules`;
    const body = {
        reschedule_date: rescheduleDate,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            result: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const getComboBoxDescriptionForSearchRequests = async () => {
    try {
        const res = await getComboBoxDescriptionService();
        if (res.failure) {
            return { failure: true };
        }
        const response = await getResultSets(res.docId, 2);
        if (response.failure) {
            return response;
        }

        return {
            requestTypeValues: response?.results[0],
            requestStatusValues: response?.results[1],
        };
    } catch (err) {
        console.log("err", err);
        return { failure: true };
    }
};

export const getComboBoxDescriptionForNewContainer = async () => {
    try {
        const res = await getComboBoxDescriptionService();
        if (res.failure) {
            return { failure: true };
        }
        const resultSetResponse = await getResultSets(res.docId, [2, 3, 4, 5, 6, 7]);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const [resultSet2, resultSet3, resultSet4, resultSet5, resultSet6, resultSet7] = resultSetResponse.results;
        return {
            containerTypes: resultSet2,
            lockTypes: resultSet3,
            insertTypes: resultSet4,
            extraInsertTypes: resultSet5,
            vaultIds: resultSet6,
            defaultLock: resultSet7,
        };
    } catch (err) {
        return { failure: true };
    }
};

export const getComboBoxDescriptionForNewOrEmptyContainer = async () => {
    try {
        const res = await getComboBoxDescriptionService("4");
        if (res.failure) {
            return { failure: true };
        }
        const resultSetResponse = await getResultSets(res.docId, [2, 4, 6]);
        if (resultSetResponse.failure) {
            return resultSetResponse;
        }
        const [resultSet2, resultSet4, resultSet6] = resultSetResponse.results;

        return {
            containerTypes: resultSet2,
            insertTypes: resultSet4,
            vaultIds: resultSet6,
        };
    } catch (err) {
        return { failure: true };
    }
};

/**
 * func name getComboBoxDescriptionService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getComboBoxDescriptionService = async (mediaProgramType = "3") => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getComboBoxDescription);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const languageLocaleId = getLanguageLocaleId();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getcomboboxdescription`;
    const body = {
        language_locale_id: languageLocaleId,
        media_program_type_id: mediaProgramType,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        return res;
    } catch (err) {
        return { failure: true };
    } finally {
        traceStop();
    }
};

/**
 * func name getCustomerServiceFrequencyService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getCustomerServiceFrequencyService = async (request = {}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getCustomerServiceFrequency);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getcustomerservicefrequency`;
    const body = {
        ...request,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            success: true,
            result: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name isValidCustomerOnCallService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const isValidCustomerOnCallService = async (request = {}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateCustomerOnCall);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/validatecustomeroncall`;
    const body = {
        ...request,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            result: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestService = async ({
    serviceDateFrom,
    serviceDateTo,
    routeCode,
    requestTypeId,
    requestStatusId,
    requestId,
    localeId = "1",
    languageLocaleId,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRequest);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getrequest`;
    const body = {
        service_date_from: serviceDateFrom,
        service_date_to: serviceDateTo,
        route_code: routeCode,
        request_type_id: requestTypeId,
        request_status_id: requestStatusId,
        request_id: requestId,
        locale_id: localeId,
        language_locale_id: languageLocaleId,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            result: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting getRequestService", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name validateRequsetTanslationService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const validateRequestRelationService = async ({ requestId, isRelated = "" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateRequestRelation);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/validaterequestrelation`;
    const body = {
        request_id: requestId,
        is_related: isRelated,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            isRelated: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name verifyRequestCancellationService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const verifyRequestCancellationService = async ({
    requestId,
    mediaDetail = "Y",
    assignedToOtherDeliveryMsg = "",
    thereAreNoFutureDatesMsg = "",
    theNextScheduledServiceRequestMsg = "",
    pageNumber = "0",
    noOfRecords = "0",
    lastModUser = "",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.verifyRequestCancellation);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/verifyrequestcancellation`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        request_id: requestId,
        media_detail: mediaDetail,
        language_locale_id: getLanguageLocaleId(),
        assigned_to_other_delivery_msg: assignedToOtherDeliveryMsg,
        there_are_no_future_dates_msg: thereAreNoFutureDatesMsg,
        the_next_scheduled_service_request_msg: theNextScheduledServiceRequestMsg,
        page_number: pageNumber,
        no_of_records: noOfRecords,
        last_mod_user: lastModUser,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot, false);
        return {
            result: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};
//Scheduled Service requests cannot be Canceled.  The Scheduled Service request must be rescheduled using the Reschedule Service form.
/**
 * func name getOmListService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getOmListService = async ({ requestId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getOmList);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getomlist`;
    const body = {
        request_id: requestId,
        language_locale_id: getLanguageLocaleId(),
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            result: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestContainerDetailsService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestContainerDetailsService = async ({
    requestId,
    secureSyncFlag = "N",
    noLongerTiedToRequestStatus = "No longer tied to request",
    pageNumber,
    noOfRecords,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRequestContainerDetails);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getrequestcontainerdetails`;
    const body = {
        request_id: requestId,
        secure_sync_flag: secureSyncFlag,
        language_locale_id: getLanguageLocaleId(),
        no_longer_tied_to_request_status: noLongerTiedToRequestStatus,
        page_number: pageNumber,
        no_of_records: noOfRecords,
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            result: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestMediaDetailsService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestMediaDetailsService = async ({
    requestId,
    localeId = "1",
    languageLocaleId,
    noLongerTiedToRequestStatus,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRequestMediaDetails);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getrequestmediadetails`;
    const body = {
        request_id: requestId,
        locale_id: localeId,
        language_locale_id: languageLocaleId,
        no_longer_tied_to_request_status: noLongerTiedToRequestStatus,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            result: resultSet0[0] ? resultSet0[0] : null,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getMediaDestructionRequestDetailsService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getMediaDestructionRequestDetailsService = async ({
    destructRequestId,
    systemId = "50",
    lastModUser,
    pageNumber = "0",
    noOfRecords = "0",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getMediaDestructionRequestDetails);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getmediadestructionrequestdetails`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
        destruct_request_id: destructRequestId,
        language_locale_id: getLanguageLocaleId(),
        system_id: systemId,
        last_mod_user: lastModUser,
        page_number: pageNumber,
        no_of_records: noOfRecords,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet2SnapShot = await getDocs(apiResultSet2Collection(res.docId));
        const resultSet3SnapShot = await getDocs(apiResultSet3Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        const resultSet2 = createArrayObj(resultSet2SnapShot);
        const resultSet3 = createArrayObj(resultSet3SnapShot);
        return {
            mediaDestructionDetail1: resultSet0,
            mediaDestructionDetail2: resultSet1,
            mediaDestructionDetail3: resultSet2,
            error: resultSet3,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name validateVolserSelectionService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const validateVolserSelectionService = async ({
    volserList,
    selectedVolser,
    followUp,
    maxRowsReturned,
    isSsieFlag = "N",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.validateVolserSelection);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/validatevolserselection`;
    const body = {
        volser_list: volserList,
        selected_volser: selectedVolser,
        follow_up: followUp,
        max_rows_returned: maxRowsReturned,
        is_ssie_flag: isSsieFlag,
        language_locale_id: getLanguageLocaleId(),
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            result: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name validateVolserSelectionService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getIronmountainCustomerContainersService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getIronMountainCustomerContainers);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getatironmountaincustomercontainers`;
    const body = {
        deliver_delete: "deliver",
        language_locale_id: getLanguageLocaleId(),
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            containerList: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getSingleRequestDataService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getSingleRequestDataService = async ({
    requestId,
    localeId = "1",
    itemtypeExtraInserts = "Extra Inserts",
    itemtypeLocks = "Locks",
    itemtypeClips = "Clips",
    pageNumber = "0",
    noOfRecords = "0",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getSingleRequestData);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getsinglerequestdata`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        request_id: requestId,
        locale_id: localeId,
        language_locale_id: getLanguageLocaleId(),
        itemtype_extra_inserts: itemtypeExtraInserts,
        itemtype_locks: itemtypeLocks,
        itemtype_clips: itemtypeClips,
        page_number: pageNumber,
        no_of_records: noOfRecords,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        const resultSet2SnapShot = await getDocs(apiResultSet2Collection(res.docId));
        const resultSet2 = createArrayObj(resultSet2SnapShot);
        return {
            closedContainer: resultSet0,
            extraContainer: resultSet1,
            error: resultSet2,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestTransportInfoService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestTransportInfoService = async ({ requestId, localeId = "1" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRequestTransportInfo);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getrequesttransportinfo`;
    const body = {
        request_id: requestId,
        locale_id: localeId,
        language_locale_id: getLanguageLocaleId(),
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            transportData: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name escheduleRequestsAndManageTransportsService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const scheduleRequestsAndManageTransportsService = async ({
    oldServiceDate,
    newServiceDate,
    personnelId = getPersonId(),
    employeeId,
    holidayFlag,
    cslNewRcsId,
    cslNewRequestId,
    lastModUser,
    rescheduledToText,
    rescheduledFromText,
    pageNumber = "0",
    noOfRecords = "0",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.scheduleRequests);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/reshedulerequestsandmanagetransport`;
    const body = {
        old_service_date: oldServiceDate,
        new_service_date: newServiceDate,
        personnel_id: personnelId,
        employee_id: employeeId,
        holiday_flag: holidayFlag,
        csl_new_rcs_id: cslNewRcsId,
        csl_new_request_id: cslNewRequestId,
        last_mod_user: lastModUser,
        rescheduled_to_text: rescheduledToText,
        rescheduled_from_text: rescheduledFromText,
        page_number: pageNumber,
        no_of_records: noOfRecords,
        main_district_id: selectedCustomer.district_id,
        csl_customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        const resultSet2SnapShot = await getDocs(apiResultSet2Collection(res.docId));
        const resultSet2 = createArrayObj(resultSet2SnapShot);
        const resultSet3SnapShot = await getDocs(apiResultSet3Collection(res.docId));
        const resultSet3 = createArrayObj(resultSet3SnapShot);
        return {
            result: resultSet0[0] ? resultSet0[0] : null,
            resultSet1,
            resultSet2,
            error: resultSet3,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name sendMailService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const sendMailService = async ({
    recipient = "test@example.com",
    message = "HI",
    subject = "",
    attachments = "",
    copyRecipients = "",
    noOutput = "TRUE",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.sendMail);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/sendmail`;
    const body = {
        recipient: recipient,
        message: message,
        subject: subject,
        attachments: attachments,
        copy_recipients: copyRecipients,
        no_output: noOutput,
        main_district_id: selectedCustomer.district_id,
        customer_id: selectedCustomer.customer_id,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            result: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 * func name getRequestTransportInfoService
 * @param {obj} request
 * @returns
 * this will return @array with request details
 */
export const getRequestDeleteContainerService = async ({ requestId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDeleteContainers);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getdeletecontainers`;
    const body = {
        request_id: requestId,
        secure_sync_flag: "N",
        language_locale_id: getLanguageLocaleId(),
        main_district_id: selectedCustomer.district_id,
        no_longer_tied_to_request_status: "No longer tied to request",
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            deleteContainerData: resultSet0,
            error: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const updateRequestService = async ({ requestId, timeStamp, personnelComments, lastModUser }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.requestDelete);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/requestupdate`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        request_id: requestId,
        request_status_id: null,
        confirm_personnel_id: null,
        non_billable_special_comment: null,
        non_billable_special_flag: null,
        employee_comment: null,
        personnel_comment: personnelComments,
        request_ts: timeStamp,
        secure_sync_flag: "Y",
        last_mod_user: lastModUser,
        re_run_comment: "TEST 11",
        regular_rate_special_flag: "N",
        trip_type_id: "",
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            requestUpdate: resultSet0,
            errorStatus: resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const cancelRequest = async ({ requestId, timeStamp, lastModUser, personnelID }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.requestCancel);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/requestcancel`;
    const body = {
        request_id: requestId,
        cancel_personnel_id: personnelID,
        cancel_employee_id: "1",
        timestamp: timeStamp,
        secure_sync_flag: "Y",
        last_mod_user: lastModUser,
        main_district_id: selectedCustomer.district_id,
        auth_bypass_reason: "",
        is_holiday_pref_cancel_flag: "N",
        return_results: "0",
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        return {
            requestUpdate: resultSet0,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const updateDestructionRequestStatus = async ({
    destructId,
    actionCode,
    personnelId,
    destructReqTimestamp,
    lastModUser,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.updateDestructionRequestStatus);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const selectedCustomer = custMetaData?.selectedCustomer ? custMetaData.selectedCustomer : null;

    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/updatedestructionrequeststatus`;
    const body = {
        main_district_id: selectedCustomer.district_id,
        destruct_request_id: destructId,
        action_code: actionCode,
        personnel_id: personnelId,
        destruct_req_timestamp: destructReqTimestamp,
        last_mod_user: lastModUser,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = createArrayObj(resultSet0SnapShot);
        const resultSet1SnapShot = await getDocs(apiResultSet1Collection(res.docId));
        const resultSet1 = createArrayObj(resultSet1SnapShot);
        return {
            requestUpdate: resultSet0,
            resultSet1,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const getMiscServiceInfo = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getMiscServiceInfo);
    traceStart();
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/getmiscserviceinfo`;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        personnel_id: getPersonalId(),
        language_locale_id: getLanguageLocaleId(),
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot, rs3Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(res.docId)),
            getDocs(apiResultSet1Collection(res.docId)),
            getDocs(apiResultSet2Collection(res.docId)),
            getDocs(apiResultSet3Collection(res.docId)),
        ]);
        const dropDownValuesTemp = createArrayObj(rs0Snapshot);
        let dropDownValues = dropDownValuesTemp
            .filter((e) => e.Dropdown_List_Id !== "1")
            .map((e) => {
                return {
                    id: parseInt(e.Display_Order),
                    label: `${e.Descr ? e.Descr : `  `}`,
                    value: e.List_Value,
                };
            });

        dropDownValues = [...dropDownValues];
        dropDownValues.sort((a, b) => a.id - b.id);
        const personalInfo = createArrayObj(rs1Snapshot)[0] ? createArrayObj(rs1Snapshot)[0] : "";
        const userListTemp = createArrayObj(rs2Snapshot);
        const userList = userListTemp.map((e) => {
            return {
                id: parseInt(e.personnel_id),
                label: e.name ? e.name : "",
                value: e.auth_number,
            };
        });
        const error = createArrayObj(rs3Snapshot);
        return {
            dropDownValues,
            personalInfo,
            userList,
            error,
        };
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const handleMiscSubmit = async (values, personalInfo, modifiedUserName, userList) => {
    let payload = null;
    const now = getSelectedCustomerLocaleTime();
    const mailDateAndTime = getSelectedCustomerLocaleTime(dateFormats.mistService);
    if (values.serviceLocation === serviceType.bidService) {
        payload = getBidPayload(values, personalInfo, modifiedUserName, now, mailDateAndTime);
    }
    if (values.serviceLocation === serviceType.libraryAudit) {
        payload = getLibraryAuditPayload(values, personalInfo, modifiedUserName, now, mailDateAndTime);
    }
    if (values.serviceLocation === serviceType.newCustomer) {
        payload = getNewCustomerPayload(values, personalInfo, modifiedUserName, now, mailDateAndTime);
    }
    if (values.serviceLocation === serviceType.authCardReplacement) {
        payload = getNewAuthCardPayload(values, personalInfo, modifiedUserName, now, userList, mailDateAndTime);
    }
    const { recipientMail = "", subject = "", body = "", resData = null, cc = "" } = payload;
    await mistMailSendService(recipientMail, body, subject, cc);
    return resData;
};

const getNewAuthCardPayload = (values, personalInfo, modifiedUserName, now, userList, mailDateAndTime) => {
    const { comments, requestorEmail, requestorPhoneNumber, ext, selectedUser } = values;
    const customerAccountId = getSelectedCustomerId();
    const { custList } = getCustomers();
    const selectedCustomer = custList.find((customer) => customer.customer_id === customerAccountId);
    const customerId = selectedCustomer?.customer_number ? selectedCustomer.customer_number : "";
    const recipientMail = personalInfo.personnel_created_email;
    const subject = t("miscService.authCardSubject") + " " + customerId;

    const userName = userList.find((user) => user.value === selectedUser);
    const body = newAuthCardBody(
        customerId,
        modifiedUserName,
        requestorEmail,
        mailDateAndTime,
        requestorPhoneNumber,
        ext,
        comments,
        userName?.label ? userName?.label : "",
        selectedUser
    );
    const resData = {
        customerId,
        modifiedUserName,
        requestorEmail,
        now,
        requestorPhoneNumber,
        serviceLocation: values.serviceLocation,
        ext,
        comments,
        userName: userName?.label ? userName?.label : "",
        selectedUser,
    };
    return {
        recipientMail,
        subject,
        body,
        resData,
        cc: "",
    };
};

const getNewCustomerPayload = (values, personalInfo, modifiedUserName, now, mailDateAndTime) => {
    const {
        comments,
        requestorEmail,
        requestorPhoneNumber,
        ext,
        requestedCompletionDate,
        newCustomerName,
        newCustomerIsSubAccount,
        parentAccountNumber,
        dataCenterContactName,
        dataCenterContactPhone,
        dataCenterExt,
        requestedCompletionDateMail,
    } = values;
    const customerAccountId = getSelectedCustomerId();
    const { custList } = getCustomers();
    const selectedCustomer = custList.find((customer) => customer.customer_id === customerAccountId);
    const customerId = selectedCustomer?.customer_number ? selectedCustomer.customer_number : "";
    const recipientMail = personalInfo.acct_mgr_email;
    const subject = t("miscService.newCustomerSubject") + " " + customerId;

    const body = newCustomerMailBody(
        customerId,
        modifiedUserName,
        requestorEmail,
        mailDateAndTime,
        requestorPhoneNumber,
        ext,
        comments,
        requestedCompletionDateMail,
        newCustomerName,
        newCustomerIsSubAccount,
        parentAccountNumber,
        dataCenterContactName,
        dataCenterContactPhone,
        dataCenterExt
    );
    const resData = {
        customerId,
        modifiedUserName,
        requestorEmail,
        now,
        requestorPhoneNumber,
        ext,
        comments,
        requestedCompletionDate,
        serviceLocation: values.serviceLocation,
        newCustomerName,
        newCustomerIsSubAccount: newCustomerIsSubAccount ? "Yes" : "No",
        parentAccountNumber,
        dataCenterContactName,
        dataCenterContactPhone,
        dataCenterExt,
    };
    return {
        recipientMail,
        subject,
        body,
        resData,
        cc: personalInfo.branch_mgr_email,
    };
};

const getLibraryAuditPayload = (values, personalInfo, modifiedUserName, now, mailDateAndTime) => {
    const {
        comments,
        requestorEmail,
        requestorPhoneNumber,
        ext,
        requestedCompletionDate,
        requestedCompletionDateMail,
    } = values;
    const customerAccountId = getSelectedCustomerId();
    const { custList } = getCustomers();
    const selectedCustomer = custList.find((customer) => customer.customer_id === customerAccountId);
    const customerId = selectedCustomer?.customer_number ? selectedCustomer.customer_number : "";
    const recipientMail = personalInfo.branch_mgr_email;
    const subject = t("miscService.logicalVaultSubject") + " " + customerId;

    const body = generateLibraryAuditMailBody(
        customerId,
        modifiedUserName,
        requestorEmail,
        mailDateAndTime,
        requestorPhoneNumber,
        ext,
        comments,
        requestedCompletionDateMail
    );
    const resData = {
        customerId,
        modifiedUserName,
        requestorEmail,
        now,
        requestorPhoneNumber,
        ext,
        comments,
        requestedCompletionDate,
        serviceLocation: values.serviceLocation,
    };
    return {
        recipientMail,
        subject,
        body,
        resData,
        cc: "",
    };
};

const getBidPayload = (values, personalInfo, modifiedUserName, now, mailDateAndTime) => {
    const { description, requestorEmail, requestorPhoneNumber, ext } = values;
    const customerAccountId = getSelectedCustomerId();
    const { custList } = getCustomers();
    const selectedCustomer = custList.find((customer) => customer.customer_id === customerAccountId);
    const customerId = selectedCustomer?.customer_number ? selectedCustomer.customer_number : "";
    const recipientMail = personalInfo.acct_mgr_email;
    const subject = t("miscService.bidSubject") + " " + customerId;

    const body = generateBidMailBody(
        customerId,
        modifiedUserName,
        requestorEmail,
        mailDateAndTime,
        requestorPhoneNumber,
        ext,
        description
    );
    const resData = {
        customerId,
        modifiedUserName,
        requestorEmail,
        now,
        requestorPhoneNumber,
        ext,
        description,
        serviceLocation: values.serviceLocation,
    };
    return {
        recipientMail,
        subject,
        body,
        resData,
        cc: "",
    };
};

const mistMailSendService = async (recipient, message, subject, cc) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.sendMail);
    traceStart();
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/sendmail`;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        recipient: recipient,
        message: message,
        subject: subject,
        attachments: "",
        copy_recipients: cc,
        no_output: "FALSE",
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const [rs0Snapshot] = await Promise.all([getDocs(apiResultSet0Collection(res.docId))]);
        const error = createArrayObj(rs0Snapshot);
        return error;
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const validatePhoneFormat = async (phone, country) => {
    const { country_id = 1, locale_id = 1 } = country;
    const { traceStart, traceStop } = traceWrapper(apiUrls.validatePhone);
    traceStart();
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_REQUEST}/request/validatephone`;
    const body = {
        main_district_id: getSelectedCustomerDb(),
        country_id: country_id,
        country_attribute_type_id: "1",
        value: phone,
        language_locale_id: locale_id,
        is_valid_flag: "",
        masked_value: "",
        format_example: "",
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const [rs0Snapshot, rs1Snapshot, rs2Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(res.docId)),
            getDocs(apiResultSet1Collection(res.docId)),
            getDocs(apiResultSet2Collection(res.docId)),
        ]);
        const valid = createArrayObj(rs0Snapshot);
        const masked = createArrayObj(rs1Snapshot);
        const formatExample = createArrayObj(rs2Snapshot);
        return {
            isValid: valid[0] && valid[0].is_valid_flag === "Y" ? true : false,
            masked: masked[0] ? masked[0] : null,
            formatExample: formatExample[0] ? formatExample[0] : null,
        };
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const otherServicesWrapper = async (location, date, description, flags) => {
    const serviceDate = formatDate(date, dateFormats.displayDate);
    const scheduledRequestRes = await getServiceDateForCustomerService({
        fromDate: serviceDate,
        toDate: serviceDate,
        rescheduleFlag: "N",
        addonCutoffFlag: flags.cutOffFlag,
    });
    const scheduledRequests = scheduledRequestRes.resultSet0 ? scheduledRequestRes.resultSet0 : [];
    scheduledRequests.sort(function (a, b) {
        return dayjs(a.service_date).isAfter(dayjs(b.service_date)) ? -1 : 1;
    });

    const payload = await generateOtherRequestPayload(scheduledRequests, flags, location, date, description);

    if (!payload.success) {
        return payload;
    }

    const res = await createOtherRequsetService(payload.data);
    if (res?.request[0]?.error) {
        return {
            success: false,
            message: t(`apiErrorMessages.${res.request[0].error}`),
        };
    }
    const requestId = res.request[0].request_id;
    const requestRes = await getRequestDetailsService({
        requestId: requestId,
        localeId: getLanguageLocaleId() ? getLanguageLocaleId() : "1",
    });
    return {
        data: requestRes.request[0],
        success: true,
    };
};

const generateOtherRequestPayload = async (scheduledRequests, flags, location, date, description) => {
    let requestedLocationId = location;
    let parentRequestID = "0";
    if (scheduledRequests.length > 0) {
        requestedLocationId = scheduledRequests[0]?.csl_customer_id ? scheduledRequests[0].csl_customer_id : "";
        if (flags.cutOffFlag === "Y") {
            if (scheduledRequests.length > 1) {
                parentRequestID = scheduledRequests[1].request_id;
            } else {
                parentRequestID = scheduledRequests[0].request_id;
            }
        } else {
            parentRequestID = scheduledRequests[0].request_id;
        }
    }

    const requestType = await getRequestTypeData(scheduledRequests, date, requestedLocationId);
    if (!requestType.success) {
        return requestType;
    }

    const dayOfWeekCode = formatDate(date, dateFormats.weekCode);
    const serviceDate = formatDate(date, dateFormats.displayDate);
    const userDetails = getLoggedUserDetails();
    const lastModifiedUser = userDetails.basicUserInfo.username;
    return {
        success: true,
        data: {
            requestActionId: "8",
            detailFlag: noFlag,
            requestTypeId: requestType.requestType,
            dayOfWeekCode: dayOfWeekCode,
            exchangeLocationTypeCode: "SL",
            serviceDate: serviceDate,
            employeeId: "",
            employeeComment: "",
            personnelId: getPersonId(),
            personnelComment: "",
            confirmPersonnelId: "",
            comment: description,
            nonBillableSpecialFlag: noFlag,
            nonBillableSpecialComment: "",
            runPositionId: "",
            requestStatusId: "1",
            cslCustomerId: location,
            parentRequestId: parentRequestID === "0" ? "" : parentRequestID,
            openMediaList: "",
            systemFunctionId: "",
            lastModUser: lastModifiedUser,
        },
    };
};

const getScheduleData = async () => {
    const serviceFreq = await getCustomerServiceFrequencyService();
    const onCallService = await isValidCustomerOnCallService();
    const isOnCall =
        onCallService?.result[0] && onCallService.result[0].on_call ? onCallService.result[0].on_call : "false";
    const generateDateStart = serviceFreq?.result[0]?.generated_date_start
        ? serviceFreq?.result[0]?.generated_date_start
        : "";
    const generateDateEnd = serviceFreq?.result[0]?.generated_date_end
        ? serviceFreq?.result[0]?.generated_date_end
        : "";
    return {
        isOnCall,
        generateDateStart,
        generateDateEnd,
    };
};

const getRequestTypeData = async (scheduledRequests, date, requestedLocationId) => {
    const { isOnCall, generateDateEnd } = await getScheduleData();
    const isScheduledServiceExists = scheduledRequests.length > 0;
    let requestType = "";
    let scheduledLocationID = "";
    let isSameLocation = false;
    if (isScheduledServiceExists) {
        scheduledLocationID = scheduledRequests[0].csl_customer_id;
        isSameLocation = scheduledLocationID === requestedLocationId;
        requestType = isSameLocation ? "2" : "4";
    } else {
        const endData = dayjs(generateDateEnd);
        const serviceData = dayjs(date);
        const beforeServiceData = endData.isSameOrAfter(serviceData);
        if (isOnCall === "false" && beforeServiceData) {
            requestType = "4";
        } else if (requestedLocationId === getSelectedCustomerId()) {
            requestType = "1";
        } else {
            requestType = "4";
        }
    }

    const { selectedCustomer } = getCustomers();
    const now = formatDate(dayjs(), null, true);
    const offSet = dayjs(now).utcOffset(parseFloat(selectedCustomer.time_zone_offset));

    if (dayjs(date).isSame(offSet)) {
        if (!isScheduledServiceExists) {
            return {
                success: false,
                message: t("apiErrorMessages.61218"),
            };
        }
        if (!isSameLocation) {
            return {
                success: false,
                message: t("apiErrorMessages.61216"),
            };
        }
    }
    return {
        success: true,
        requestType,
    };
};
