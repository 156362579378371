import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import styles from "./ErrorPage.styles";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import ExclamationMark from "src/components/Icons/ExclamationMark/ExclamationMark";

const ErrorPage = ({ children, title }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const onClick = async () => {
        await oktaAuth.signOut();
        navigate(routePaths.signIn);
    };

    return (
        <Grid container justifyContent="center" sx={styles.container}>
            <Grid item xs={6}>
                <ExclamationMark fill={theme.palette.im_red[700]} width={"80px"} height={"87px"} />
                <Typography component="p" sx={styles.textContent}>
                    <Trans i18nKey={"errorPage.problemDescription"} />
                </Typography>
                <Typography variant="h4" sx={styles.titleSection}>
                    {title}
                </Typography>
                <Box>{children}</Box>
                <Box sx={styles.btnArea}>
                    <Button variant="contained" color="primary" sx={styles.HomeBtn} onClick={onClick}>
                        <Trans i18nKey={"buttonLabels.goHome"} />
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
