import { InputLabel, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CenteredSelect, styles } from "../SecureSyncTable.styles";
import ArrowDown from "src/components/Icons/ArrowDown/ArrowDown";
import ChevronLeftFilled from "src/components/Icons/ChevronLeftFilled/ChevronLeftFilled";
import ChevronRightFilled from "src/components/Icons/ChevronRightFilled/ChevronRightFilled";

const TablePaginationActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, onJumpToPageChange } = props;
    const pages = [...Array(Math.ceil(count / rowsPerPage)).keys()];

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <>
            <Box className="jumpToPage" sx={styles.jumpToPageContainer}>
                <InputLabel sx={styles.jumpToPageLabel}>{t("tableHeader.jumpToPage")}</InputLabel>
                <CenteredSelect
                    value={page}
                    onChange={(event) => onJumpToPageChange(event, parseInt(event.target.value, 10))}
                    sx={styles.centeredSelect}
                    size="small"
                    disableUnderline
                    variant="standard"
                    IconComponent={(props) => <ArrowDown sx={styles.arrowIcon} fillOpacity={0.66} {...props} />}
                >
                    {pages.map((p) => (
                        <MenuItem key={p} value={p}>
                            {p + 1}
                        </MenuItem>
                    ))}
                </CenteredSelect>
            </Box>
            <Box className="pageArrows" sx={styles.arrowButtonContainer}>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === "rtl" ? (
                        //style={styles.arrowIcon}
                        <ChevronRightFilled style={styles.arrowIcon} />
                    ) : (
                        <ChevronLeftFilled style={styles.arrowIcon} />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <ChevronLeftFilled style={styles.arrowIcon} />
                    ) : (
                        <ChevronRightFilled style={styles.arrowIcon} />
                    )}
                </IconButton>
            </Box>
        </>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onJumpToPageChange: PropTypes.func.isRequired,
};

export default TablePaginationActions;
