import { Box, TextField, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import styles from "./DescriptionSection.styles";

const DescriptionSection = ({ value, handleChange, disable }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={styles.boxPaddingBottom}>
                <TextField
                    id="description"
                    name="description"
                    label={t("containerPickup.description") + " " + String.fromCharCode(185)}
                    variant="outlined"
                    multiline
                    fullWidth
                    disabled={disable}
                    value={value}
                    onChange={handleChange}
                    inputProps={{ maxLength: 1024 }}
                    InputProps={{
                        sx: {
                            overflow: "auto",
                        },
                    }}
                    rows={4}
                />
                <Typography sx={styles.footerCaption}>
                    <Trans i18nKey={"openMediaDetails.descrHelper"}></Trans>
                </Typography>
            </Box>
        </>
    );
};
export default DescriptionSection;
