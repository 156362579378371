import { getDoc, getDocs } from "firebase/firestore";
import {
    constructFbHeaders,
    getLanguageLocaleId,
    invokeApi,
    getSelectedCustomerDb,
    getSelectedCustomerId,
    getUserIdentifier,
    getResultSets,
} from "./utils";
import { apiResultSet0Collection, apiResultSet1Collection, docRef } from "./firebase";
import { traceWrapper } from "./firebasePerformance";
import { getCurrentDateTime } from "src/utils/dateHelper";

const apiUrls = {
    activeDistricts: "/disasterrecovery/activedistricts",
    jobTitleIdAndTitleValues: "/disasterrecovery/jobtitleidandtitlevalues",
    employeeInformation: "/disasterrecovery/employeeinformation",
    getEmployeeInfo: "/disasterrecovery/getemployeeinfo",
    getDistrictInfo: "/disasterrecovery/getdistrictinfo",
    getListDrFiles: "/disasterrecovery/getlistdrfiles",
    getDrInstruction: "/disasterrecovery/getdrinstruction",
    insertUpdatedDrInstruction: "/disasterrecovery/insertupdatedrinstruction",
    deletedDrInstruction: "/disasterrecovery/deletedrinstruction",
    getDrInstructionTwo: "/disasterrecovery/getdrinstructiontwo",
    insertDrInstructionDesignatedCustomer: "/disasterrecovery/insertdrinstructiondesignatedcutomer",
    deleteDrInstructionDesignatedCustomer: "/disasterrecovery/deletedrinstructiondesignatedcustomer",
    drDocumentDecrypt: "/drdocument/decrypt",
    drDocumentEncrypt: "/document/encrypt",
};

export const getDistrictLists = async (code) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.activeDistricts);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/activedistricts";
    const body = {
        main_district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const district = {
            failure: false,
            rs0: rs0Data,
        };
        return district;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getTitleList = async (code) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.jobTitleIdAndTitleValues);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/jobtitleidandtitlevalues";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
    };

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);

        const resDoc = res?.docId;
        // const rs0Snapshot = await getDocs(apiResultSet0Collection(resDoc));
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const title = {
            failure: false,
            rs0: rs0Data,
        };
        return title;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getAllEmployeeInformation = async (values, emergencyContact) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.employeeInformation);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/employeeinformation";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
        named_sort: "Name",
        sort_descending: "N",
    };
    if (values.firstName) {
        body.first_name = values.firstName;
    }
    if (values.lastName) {
        body.last_name = values.lastName;
    }
    if (values.district !== "0") {
        body.district_id = values.district;
    }
    if (values.title) {
        body.job_title_id = values.title;
    }
    if (emergencyContact) {
        body.emergency_contact_flag = emergencyContact;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const employeeInfo = {
            failure: false,
            rs0: rs0Data,
        };
        return employeeInfo;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getEmployeeInformationById = async (employeeId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getEmployeeInfo);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/getemployeeinfo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
    };
    if (employeeId) {
        body.employee_id = employeeId;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const employeeDetails = {
            failure: false,
            rs0: rs0Data,
        };
        return employeeDetails;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getDistrictInfoById = async (districtId) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDistrictInfo);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/getdistrictinfo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        language_locale_id: getLanguageLocaleId(),
    };
    if (districtId) {
        body.district_id = districtId;
    }

    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const resDoc = res?.docId;
        const [rs0Snapshot, rs1Snapshot] = await Promise.all([
            getDocs(apiResultSet0Collection(resDoc)),
            getDocs(apiResultSet1Collection(resDoc)),
        ]);

        const rs0Data = [];
        rs0Snapshot.forEach((doc) => {
            rs0Data.push(doc.data());
        });

        const rs1Data = [];
        rs1Snapshot.forEach((doc) => {
            rs1Data.push(doc.data());
        });

        if (rs1Data[0].error !== "0") {
            return { failure: true, errorCode: rs1Data[0].error };
        }

        const employeeDetails = {
            failure: false,
            rs0: rs0Data,
        };
        return employeeDetails;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getListCustomerDRFiles = async ({ maxStorageLimit = "0" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getListDrFiles);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/getlistdrfiles";
    const body = {
        main_district_id: "50",
        customer_id: getSelectedCustomerId(),
        max_storage_limit: maxStorageLimit,
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;

        const response = await getResultSets(resDoc, 3);
        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }
        const cusDRListFile = {
            failure: false,
            drFileList: response?.results[0],
            maxStorageLimit: response?.results[1],
        };
        return cusDRListFile;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getListDRInstructions = async ({ instructionTypeId = "1" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDrInstruction);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/getdrinstruction";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        dr_instruction_type_id: instructionTypeId,
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 2);

        if (response.failure) {
            return { failure: true, errorCode: response.error, drInstructions: [] };
        }

        const drInstructions = {
            failure: false,
            drInstructions: response?.results[0],
        };
        return drInstructions;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const insertAndUpdateDRInstructions = async ({
    instructionId,
    instructionTypeId = "1",
    drInstruction = "",
    drInstructionName,
    lastModDate = getCurrentDateTime(),
    gcsPathDownload = "",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertUpdatedDrInstruction);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/insertupdatedrinstruction";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        dr_instruction_id: instructionId,
        dr_instruction_type_id: instructionTypeId,
        dr_instruction: drInstruction,
        dr_instruction_name: drInstructionName,
        dr_instr_last_mod_date: lastModDate,
        login: getUserIdentifier(),
        district_id: getSelectedCustomerDb(),
        gcsPath: gcsPathDownload,
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 2);

        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }

        const drInstructions = {
            fileId: response?.results[0],
            failure: false,
        };
        return drInstructions;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const deleteDRInstruction = async ({ drInstructionId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.deletedDrInstruction);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/deletedrinstruction";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        dr_instruction_id: drInstructionId,
        district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 1);

        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }

        const drDeleteResponse = {
            failure: false,
        };
        return drDeleteResponse;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getDRInstructionFileContent = async ({ drInstructionId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getDrInstructionTwo);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/getdrinstructiontwo";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        dr_instruction_id: drInstructionId,
        district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 1);

        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }

        const drInstructionContent = {
            failure: false,
            fileContent: response?.results[0],
        };
        return drInstructionContent;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const insertDrInstructionDesignatedCustomer = async ({
    lastModDate = getCurrentDateTime(),
    fileName,
    fileSize,
    fileBlob,
    fileId = "",
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.insertDrInstructionDesignatedCustomer);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/insertdrinstructiondesignatedcutomer";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        district_id: getSelectedCustomerDb(),
        customer_id: getSelectedCustomerId(),
        dr_file_name: fileName,
        dr_file_size: fileSize,
        dr_file_blob: fileBlob,
        dr_file_last_mod_date: lastModDate,
        dr_file_id: fileId,
        login: getUserIdentifier(),
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 2);

        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }

        const drInstructions = {
            fileId: response?.results[0],
            failure: false,
        };
        return drInstructions;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const deleteDrInstructionDesignatedCustomer = async ({ fileId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.deleteDrInstructionDesignatedCustomer);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_URL_DR + "/disasterrecovery/deletedrinstructiondesignatedcustomer";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        dr_file_id: fileId,
        district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 1);

        if (response.failure) {
            return { failure: true, errorCode: response.error };
        }

        const drDeleteResponse = {
            failure: false,
        };
        return drDeleteResponse;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const getDRFileContent = async ({ drFileId }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.drDocumentDecrypt);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_FILE_ENCRYPTION + "/drdocument/decrypt";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        dr_file_id: drFileId,
        district_id: getSelectedCustomerDb(),
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resDoc = res?.docId;
        const response = await getResultSets(resDoc, 2);

        const ref = docRef(resDoc);
        const docSnap = await getDoc(ref);
        const docData = docSnap.data();

        if (response.failure) {
            return {
                failure: true,
                errorCode: response.error,
            };
        }

        const drInstructionContent = {
            failure: false,
            fileContent: response?.results[0],
            gcsPathDownload: docData.gcsPathDownload,
        };
        return drInstructionContent;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};

export const drDocumentEncryptService = async (payload) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.drDocumentEncrypt);
    traceStart();
    const headers = constructFbHeaders();
    const url = process.env.REACT_APP_CF_FILE_ENCRYPTION + "/document/encrypt";
    try {
        const res = await invokeApi("POST", url, payload, {}, headers);
        return res;
    } catch (err) {
        return err;
    } finally {
        traceStop();
    }
};
