import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import styles from "./LibraryAuditResultsTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const LibraryAuditResultsTable = ({ resultData, onPrintReport }) => {
    const { t } = useTranslation();

    const columns = [
        {
            field: "Code",
            headerName: convertToLocaleUpperCase(t("tableHeader.logicalVaults")),
            sortable: true,
        },
        {
            field: "Volser",
            headerName: convertToLocaleUpperCase(t("tableHeader.mediaNumber")),
            sortable: true,
            type: "string",
        },
        {
            field: "Descr",
            headerName: convertToLocaleUpperCase(t("tableHeader.exceptionType")),
            sortable: true,
        },
    ];

    return (
        <SecureSyncTableCard>
            <Box sx={styles.recordCount}>
                {resultData.length > 0 ? (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: resultData.length })}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.recordStart} />
                )}
                <Box sx={styles.recordEnd}>
                    <Button variant="outlined" onClick={onPrintReport}>
                        {t("libraryAuditResults.prepareReport")}
                    </Button>
                </Box>
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={resultData}
                    columns={columns}
                    getRowId={(row) => row.id}
                    noDataMessage={<Trans i18nKey={"libraryAuditResults.noExceptions"} />}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default LibraryAuditResultsTable;
