const ProtectedMenu = ({ hasAccess, component: MenuComponent, children }) => {
    if (hasAccess) {
        if (children) {
            return children;
        } else if (MenuComponent) {
            return <MenuComponent />;
        } else {
            return null;
        }
    }
    return null;
};

export default ProtectedMenu;
