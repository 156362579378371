export const businessEmailAddress = "business_email_addr";
export const equals = "==";
export const secureSyncUser = "is_securesync_login";
export const login = "login";
export const yesFlag = "Y";
export const noFlag = "N";
export const trueFlag = "true";
export const falseFlag = "false";
export const defaultDistrictId = 50;
export const defualtSystemId = "69";
export const mainDistrictId = "main_district_id";
export const oktaId = "oag_uid";
export const webServerName = "ssie_web_server_name";
export const sysUserId = "sys_user_id";
export const customerNumber = "customer_number";
export const customerId = "customer_id";
export const ISOLocalCode = "iso_locale_code";
export const supportedLangFlag = "supported_language_flag";
export const messageId = "message_id";
// URL constand
export const BILLTRUST_LINK = "1022";
export const SITAD_LINK = "1014";
export const IM_INSIDERS_LINK = "1020";
export const IM_COLOCATION_DATA_SERVICE_LINK = "1017";
export const IM_DISPOSE_OF_RETIRED_ASSETS_LINK = "1018";
export const IM_DATA_STORAGE_ON_ARCHIVAL_TAPSE_LINK = "1019";
export const IM_MEDIA_DESTRUCTION_LINK = "179";
export const IM_RESTORATION_AND_MIGRATION_LINK = "180";

// bilTrust url const

export const BILL_TRUST_BT_DATA = "BillTrust BTDATA";
export const BILL_TRUST_CLIENT_ID = "BillTrust ClientID";
export const BILL_TRUST_KEY = "BillTrust Key";
export const BILL_TRUST_E_TYPE = "BillTrust ETYPE";
// export const SITAD_LINK = "1014"
// export const IM_INSIDERS_LINK = "1020"
// export const IM_COLOCATION_DATA_SERVICE_LINK = "1017"
// export const IM_DISPOSE_OF_RETIRED_ASSETS_LINK = "1018"
// export const IM_DATA_STORAGE_ON_ARCHIVAL_TAPSE_LINK = "1019"
// export const IM_MEDIA_DESTRUCTION_LINK = "179"
// export const IM_RESTORATION_AND_MIGRATION_LINK = "180"
export const dropdownListType = "dropdown_list_type_id";
// eslint-disable-next-line camelcase
export const country_active_flag = "active_flag";
export const NOT_AT_IRON_MOUNTAIN_TYPE = "Missing";
export const NOT_ON_INVENTORY_TYPE = "Unexpected";

//File Size constants
// eslint-disable-next-line camelcase
export const MAXIMUM_FILE_SIZE_ALLOWED_Kb = 51200;
// eslint-disable-next-line camelcase
export const FILE_SIZE_LIMIT_Kb = 1024;

export const systemId = {
    id50: "50",
    id60: "60",
    defaultSystemId: "69",
};

export const globalAttributeTypeId = {
    id80: "80",
    id96: "96",
    id94: "94",
};

export const feedbackEmailGroupCode = "feedback";
export const secureSyncPreferences = "secure_sync_ui";
