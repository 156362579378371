import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import CancelConfirmation from "../CancelConfirmation/CancelConfirmation";
import styles from "./Comments.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import { getCancelRequestPath, requestDetails, requestHome } from "src/constants/routeConstants/requestRouterFullPaths";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { cancelRequest, updateRequestService } from "src/services/requestServices";
import { getBasicUserInfo } from "src/redux/userDetailsSlice";
import { requestActionIds, requestStatusCodes } from "src/constants/requestConstants";
import { useSecureSyncHotKeys, useSecureSyncNavigate } from "src/customHooks";
import { getApiErrorMessage } from "src/services/utils";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import {
    getDeliverMediaPayload,
    getNewContainerLocksPayload,
    getNewOrEmptyTransportPayload,
    getOtherServicePayload,
    getRequestDeleteContainerPayload,
} from "src/services/printServices";
import { targetUrl } from "src/constants/printConstants";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t, personnelComments) => {
    return Yup.object().shape({
        comments: Yup.string()
            .max(255, t("messages.max255Char"))
            .test("commentsRequiredForSubmission", t("messages.commentsRequiredForSubmission"), function (comments) {
                return !(
                    (personnelComments === undefined || personnelComments === "") &&
                    (comments === undefined || comments === "")
                );
            })
            .test(
                "updateCommentsBeforeSubmit",
                t("messages.updateCommentsBeforeSubmit", { buttonLabel: t("buttonLabels.submit") }),
                function (comments) {
                    return personnelComments !== comments;
                }
            ),
    });
};
const Comments = ({ requestData, hasCancelAccess, hasSubmitAccess, cancelRequestData, handleResponseStatus }) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    const { navigate, goBack } = useSecureSyncNavigate();
    const userDetails = useSelector(getBasicUserInfo);
    const personnelComments = requestData?.personnel_comment;
    const requestActionId = requestData?.request_action_id;
    const requestStatusCode = requestData?.request_status_id;
    const requestId = requestData?.request_id;
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            if (hasSubmitAccess()) {
                handleSubmit();
            }
        },
    });

    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: () => {
            if (hasSubmitAccess()) {
                handleReset();
            }
        },
    });

    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            printReport();
        },
    });
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [targetURL, setTargetURL] = useState("");
    const hasReportAccess =
        requestActionId === requestActionIds.openMediaDetails ||
        requestActionId === requestActionIds.temporaryTransportDetails ||
        requestActionId === requestActionIds.deleteContainerDetails ||
        requestActionId === requestActionIds.otherDetails ||
        requestActionId === requestActionIds.closedContainerDetails;
    useEffect(() => {
        if (requestStatusCode === requestStatusCodes.cancelled || requestStatusCode === requestStatusCodes.completed) {
            setFieldValue("comments", personnelComments || t("messages.none"));
        } else {
            setFieldValue("comments", personnelComments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personnelComments]);
    const fromRequest = useMatch(requestDetails);
    const onClickBack = () => {
        if (fromRequest) {
            goBack(requestHome);
        } else {
            navigate(dashboard);
        }
    };
    const handleCancelRequest = async () => {
        const errorMsg = cancelRequestData?.result[0]?.error;
        const containerNumber = cancelRequestData?.result[0]?.container_number;
        setLoading(true);
        if (
            (errorMsg === "0" || errorMsg === "1") &&
            containerNumber &&
            containerNumber !== null &&
            containerNumber !== ""
        ) {
            setLoading(false);
            navigate(getCancelRequestPath(requestId), {
                state: { requestDetails: requestData, cancelRequestData: cancelRequestData?.result },
            });
        } else if (errorMsg === "0" || errorMsg === "1") {
            setLoading(true);
            handleCancel();
            setShowCancelConfirmation(true);
        } else {
            handleSetError(errorMsg);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        const payload = {
            requestId: requestId,
            timeStamp: requestData?.timestamp,
            lastModUser: userDetails?.username,
            personnelID: userDetails?.sys_user_id,
        };

        const { requestUpdate } = await cancelRequest(payload);
        if (requestUpdate[0].err_msg === "0") {
            const statusMessage = {
                show: true,
                status: "success",
                message: t("messages.requestCancelledSuccessfully", { reqId: requestId }),
            };
            handleResponseStatus(statusMessage);
            setLoading(false);
            setTimeout(() => {
                goBack();
            }, 5000);
        } else {
            handleSetError(requestUpdate[0].err_msg);
        }
    };

    const onCloseHandler = () => {
        setShowCancelConfirmation(true);
    };

    const closeCancelConfirmation = () => {
        setShowCancelConfirmation(false);
    };

    const handleSetError = (errorMsg) => {
        const errorCodeMsg =
            errorMsg === "59511"
                ? t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("25357") })
                : t("messages.theRequestCannotBeProcessed ", { errorMsg: getApiErrorMessage("61220") });
        const statusMessage = {
            show: true,
            status: "error",
            message: errorCodeMsg,
        };
        handleResponseStatus(statusMessage);
        setLoading(false);
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: {
            comments: "",
        },
        validationSchema: validationSchema(t, personnelComments, ""),
        onSubmit: async (values) => {
            setLoading(true);
            const payLoad = {
                requestId: requestId,
                timeStamp: requestData?.timestamp,
                personnelComments: values.comments,
                lastModUser: userDetails?.username,
            };
            const { errorStatus } = await updateRequestService(payLoad);
            if (!errorStatus[0]?.hasError) {
                const statusMessage = {
                    show: true,
                    status: "success",
                    message: t("messages.updatedCommentsSuccessfully", { reqId: requestId }),
                };
                handleResponseStatus(statusMessage);
                setLoading(false);
                setTimeout(() => {
                    goBack();
                }, 5000);
            } else {
                const statusMessage = {
                    show: true,
                    status: "error",
                    message: errorStatus[0]?.displayMessage,
                };
                handleResponseStatus(statusMessage);
                setLoading(false);
            }
        },
    });

    const handleReset = () => {
        resetForm({ values: { comments: personnelComments } });
    };

    const printReport = () => {
        if (requestActionId === requestActionIds.openMediaDetails) {
            setTargetURL(targetUrl.deliverMedia);
            setPrintPayload(
                getDeliverMediaPayload({
                    requestId: requestId,
                })
            );
        } else if (requestActionId === requestActionIds.closedContainerDetails) {
            setTargetURL(targetUrl.newLockContainer);
            setPrintPayload(
                getNewContainerLocksPayload({
                    requestId: requestId,
                })
            );
        } else if (requestActionId === requestActionIds.otherDetails) {
            setTargetURL(targetUrl.otherServicePrint);
            setPrintPayload(getOtherServicePayload(requestId));
        } else if (requestActionId === requestActionIds.temporaryTransportDetails) {
            setTargetURL(targetUrl.newOrEmptyTransport);
            setPrintPayload(
                getNewOrEmptyTransportPayload({
                    requestId: requestId,
                })
            );
        } else if (requestActionId === requestActionIds.deleteContainerDetails) {
            setTargetURL(targetUrl.deletecontainersreport);
            setPrintPayload(
                getRequestDeleteContainerPayload({
                    requestId: requestId,
                })
            );
        }
        printModalOpen(true);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <form onSubmit={handleSubmit}>
                <Box sx={styles.mainContainer}>
                    <Typography variant="h5" sx={styles.heading}>
                        {t("common.comments")}
                    </Typography>
                    <TextField
                        id="filled-multiline-flexible"
                        label={t("common.comments")}
                        name="comments"
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={5}
                        maxRows={5}
                        inputProps={{ maxLength: 255 }}
                        fullWidth
                        disabled={
                            requestData?.mdr_flag === "Y" || requestStatusCode === "3" || requestStatusCode === "4"
                        }
                        error={touched.comments && Boolean(errors.comments)}
                        helperText={touched.comments && errors.comments}
                    />
                </Box>
                <CancelConfirmation
                    open={showCancelConfirmation}
                    onClose={closeCancelConfirmation}
                    handleConfirm={() => {
                        handleCancelRequest();
                        closeCancelConfirmation();
                    }}
                />
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button id="back" variant="outlined" color="primary" onClick={onClickBack} startIcon={<IconLeft />}>
                        {t("buttonLabels.back")}
                    </Button>
                    {hasCancelAccess() && (
                        <Button
                            id="cancelRequest"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                onCloseHandler();
                            }}
                        >
                            {t("buttonLabels.cancelRequest")}
                        </Button>
                    )}
                    {hasReportAccess && (
                        <Button
                            id="prepareReport"
                            variant="outlined"
                            color="primary"
                            title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                            onClick={() => {
                                printReport();
                            }}
                        >
                            {t("buttonLabels.prepareReport")}
                        </Button>
                    )}
                    {hasSubmitAccess() && (
                        <>
                            <Button
                                id="reset"
                                variant="outlined"
                                onClick={() => {
                                    handleReset();
                                }}
                                color="primary"
                                title={getKeyBoardLabel(shortCutKeys.reset)}
                            >
                                {t("buttonLabels.reset")}
                            </Button>
                            <Button
                                id="submit"
                                variant="contained"
                                color="primary"
                                type="submit"
                                title={getKeyBoardLabel(shortCutKeys.submit)}
                            >
                                {t("common.submit")}
                            </Button>
                        </>
                    )}
                </Box>
            </form>
            <Divider />
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetURL}
            />
        </>
    );
};

export default Comments;
