const styles = {
    card: {
        my: "16px",
    },
    eyeIcon: {
        color: "im_mid_blue.500",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        height: "70px",
        p: 2,
    },
    cardHederText: {
        color: "text.primary",
    },
};

export default styles;
