const styles = {
    typographyBody: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "1.5",
        letterSpacing: "0.15px",
        paddingBottom: "20px",
    },
    boxPaddingBottom: {
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 640px)",
    },
};

export default styles;
