import { TextField, useTheme } from "@mui/material";
import styles from "./SecureSyncReadOnlyTextField.styles";

const SecureSyncReadOnlyTextField = ({
    label,
    value,
    id,
    isError = false,
    isMultiline = false,
    shrink = false,
    variant = "standard",
    maxRows = 1,
    rows = 1,
}) => {
    const theme = useTheme();
    const inputLabelProps = {
        focused: false,
    };
    if (shrink) {
        inputLabelProps.shrink = true;
    }
    return (
        <TextField
            fullWidth
            id={id}
            variant={variant}
            multiline={isMultiline}
            label={label}
            value={value}
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: {
                    color: isError ? theme.palette.im_red[500] : theme.palette.text,
                    pointerEvents: "auto",
                    cursor: "default",
                },
            }}
            maxRows={maxRows}
            sx={styles.readOnlyInput}
            rows={rows}
            InputLabelProps={inputLabelProps}
        />
    );
};

export default SecureSyncReadOnlyTextField;
