/* eslint-disable camelcase */
import { Box, Button, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./ContainerDetails.styles";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import ContainerBasicDetails from "src/components/Operations/Research/ContainerDetails/ContainerBasicDetails/ContainerBasicDetails";
import ContainerContents from "src/components/Operations/Research/ContainerDetails/ContainerContents/ContainerContents";
import TransactionHistoryTable from "src/components/Operations/Research/ContainerDetails/TransactionHistoryTable/TransactionHistoryTable";
import { containerDetail, searchContainer } from "src/constants/routeConstants/operationsRouterFullPaths";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { globalMediaLocator } from "src/constants/routeConstants/operationsRouterFullPaths";
import { useSecureSyncNavigate } from "src/customHooks";
import { getDataForContainer } from "src/services/researchService";

const initialErrorState = {
    status: "",
    show: false,
    message: "",
};
const ContainerDetails = () => {
    const { mediaId } = useParams();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { navigate, goBack } = useSecureSyncNavigate();
    const [errorMessage, setErrorMessage] = useState(initialErrorState);
    const modalRef = useRef(null);
    const [containerDetails, setContainerDetails] = useState({
        containerData: [],
        transactionHistory: [],
        closedContainerContents: [],
        retentionProfile: [],
    });
    const location = useLocation();
    const [params] = useSearchParams();
    const districtId = params.get("districtId");
    const paramsContainerData = location.state;
    const initialSort = (requestData) => {
        requestData.sort((a, b) => {
            const dateA = dayjs(a.container_transaction_datetime);
            const dateB = dayjs(b.container_transaction_datetime);
            if (dateA.isAfter(dateB)) {
                return -1;
            }
            if (dateA.isBefore(dateB)) {
                return 1;
            }

            if (a.request_id > b.request_id) {
                return -1;
            }
            if (a.request_id < b.request_id) {
                return 1;
            }

            if (a.activity_display > b.activity_display) {
                return 1;
            }
            if (a.activity_display < b.activity_display) {
                return -1;
            }
            return 0;
        });
        return requestData;
    };
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const containerDataResponse = await getDataForContainer({ containerId: mediaId, districtId });
            if (!containerDataResponse?.failure) {
                setContainerDetails({
                    containerData: containerDataResponse?.containerData,
                    transactionHistory: initialSort(containerDataResponse?.transactionHistory),
                    closedContainerContents: containerDataResponse?.closedContainerContents,
                    retentionProfile: containerDataResponse?.retentionProfile,
                });
            } else {
                setErrorMessage({
                    severity: "error",
                    show: true,
                    msg: t(`apiErrorMessages.${containerDataResponse.error}`),
                });
            }
            setLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fromContainerDetails = useMatch(containerDetail);
    const onClickBack = () => {
        const fallbackUrl = districtId ? globalMediaLocator : searchContainer;
        if (fromContainerDetails) {
            goBack(fallbackUrl);
        } else {
            navigate(dashboard);
        }
    };
    return (
        <Box>
            <CircularLoaderFullPage show={loading} />
            <PageTitle>{t("titles.containerDetail")}</PageTitle>
            {errorMessage.show && errorMessage?.status === "error" && (
                <SecureSyncAlert severity={"error"} message={errorMessage.message} alertRef={modalRef} />
            )}
            <ContainerBasicDetails
                record={containerDetails?.containerData[0]}
                retentionProfile={containerDetails?.retentionProfile}
                setIsLoading={setLoading}
                paramsContainerData={paramsContainerData}
                setErrorMessage={setErrorMessage}
            />
            {containerDetails?.closedContainerContents.length > 0 && (
                <>
                    <Divider />
                    <Box sx={styles.containerContents}>
                        <ContainerContents records={containerDetails?.closedContainerContents} />
                    </Box>
                </>
            )}
            <Divider />
            <TransactionHistoryTable records={containerDetails?.transactionHistory} />
            <Divider />
            <Button
                sx={styles.backBtn}
                variant="outlined"
                color="primary"
                onClick={onClickBack}
                startIcon={<IconLeft />}
            >
                {t("buttonLabels.back")}
            </Button>
        </Box>
    );
};

export default ContainerDetails;
