import { useRef, useState } from "react";
import { Box, Button, FormHelperText, IconButton, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import styles from "./AddContainerContentModal.styles";
import SecureSyncFileUpload from "src/components/common/SecureSyncFileUpload/SecureSyncFileUpload";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";
import UploadIcon from "src/components/Icons/UploadIcon/UploadIcon";
import { downloadInstructionGuide, handleFileRead } from "src/utils/processMediaHelper";
import SecureSyncReadOnlyTextField from "src/components/common/SecureSyncReadOnlyTextField/SecureSyncReadOnlyTextField";
import DeleteAllConfirmation from "src/components/Operations/DisasterRecovery/ConfirmationModals/DeleteConfirmation/DeleteConfirmation";
import CloseModalConfirmation from "src/components/ProcessMedia/ConfirmationModals/CloseModalConfirmation/CloseModalConfirmation";
import { enterKeyCode, sourceType, getSourceTypeOptions } from "src/constants/processMediaConstants";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { importingContainerContentsFileName, enteringContainerContent, staticFile } from "src/constants/fileConstants";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const AddContainerContentModal = ({ onClose, onSubmit }) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.ok,
        callback: () => {
            handleOkClick();
        },
    });

    const { t } = useTranslation();

    const [mediaType, setMediaType] = useState(sourceType.text);
    const [fileFormatError, setFileFormatError] = useState({
        fileName: "",
        hasError: false,
        lastRecordCount: "",
    });
    const [scanType, setScanType] = useState("");
    const [mediaTypeList, setMediaTypeList] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCloseModalConfirmation, setShowCloseModalConfirmation] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const alertRef = useRef();
    const columns = [
        {
            field: "delete",
            headerName: "",
            width: "10px",
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        deleteSingleMediaType(params.id);
                    }}
                >
                    <ClearIcon sx={styles.clearIcon} />
                </IconButton>
            ),
        },
        { field: "mediaList", headerName: t("containerPickup.mediaList").toLocaleUpperCase(), sortable: false },
    ];

    const isMediaTypeText = mediaType === sourceType.text;

    const handleMediaTypeChange = (e) => {
        setMediaType(e.target.value);
        clearFileError();
    };

    const clearFileError = () => {
        setFileFormatError({ fileName: "", hasError: false, lastRecordCount: "" });
    };

    const fileReadHandler = async (event) => {
        const { csvData, fileName, hasError } = await handleFileRead(event);
        if (!hasError) {
            csvData.forEach((element) => {
                addMediaType(element);
            });
        }
        setFileFormatError({ fileName: fileName, hasError: hasError, lastRecordCount: csvData.length });
    };

    const addMediaType = (value) => {
        setShowErrorAlert(false);
        setMediaTypeList((prevData) => [...prevData, { id: uuidv4(), mediaList: value }]);
    };

    const processTableValue = (rowsArr) => {
        const rows = rowsArr.map((value) => value.mediaList);
        const arr = [];
        let currentContainer = null;
        let currentMedia = [];

        rows.forEach((line) => {
            if (line.toLowerCase() === "close") {
                if (currentContainer !== null) {
                    arr.push({ container: currentContainer, mediaNo: currentMedia });
                    currentContainer = null;
                    currentMedia = [];
                }
            } else {
                if (line.length === 14) {
                    if (currentContainer !== null) {
                        arr.push({ container: currentContainer, mediaNo: currentMedia });
                        currentContainer = null;
                        currentMedia = [];
                    }
                }
                if (currentContainer === null) {
                    if (line.length === 14) {
                        currentContainer = line.slice(8, 14).replace(/^0+/, "");
                    } else {
                        currentContainer = line.slice(0, 6).replace(/^0+/, "");
                    }
                } else {
                    currentMedia.push(line);
                }
            }
        });
        if (currentContainer !== null) {
            arr.push({ container: currentContainer, mediaNo: currentMedia });
        }
        return arr;
    };

    const deleteSingleMediaType = (id) => {
        setMediaTypeList((arr) => arr.filter((val) => val.id !== id));
    };

    const addScanTypeHandler = () => {
        if (scanType) {
            addMediaType(scanType);
            setScanType("");
        }
    };

    const enterToAddScan = (event) => {
        if (event.keyCode === enterKeyCode) {
            addScanTypeHandler();
        }
    };

    const resetModal = () => {
        setMediaType(sourceType.keyboard);
        clearFileError();
        setMediaTypeList([]);
        setScanType("");
        setShowErrorAlert(false);
    };

    const handleSubmit = () => {
        const processedData = processTableValue(mediaTypeList);
        if (processedData.length > 0) {
            onSubmit(processedData);
        }
    };

    const closeHandler = () => {
        if (mediaTypeList.length > 0) {
            setShowCloseModalConfirmation(true);
        } else {
            onClose();
        }
    };

    const closeDeleteAllConfirmation = () => setShowDeleteConfirmation(false);

    const getStatusValue = () => {
        if (isMediaTypeText) {
            if (fileFormatError.fileName && !fileFormatError.hasError) {
                return t("messages.fileSuccessfullyImported", { fileName: fileFormatError.fileName });
            } else {
                if (fileFormatError.hasError) {
                    return t("messages.fileErrorMessage", { fileName: fileFormatError.fileName });
                }
            }
        }
        return " ";
    };

    const getRecordCount = () => {
        const messageKey = fileFormatError.lastRecordCount ? "full" : "partial";
        return t(`messages.lastRecordCountFromTxtFile.${messageKey}`, {
            count: mediaTypeList.length,
            lastCount: fileFormatError.lastRecordCount,
        });
    };

    const downloadInstructionFile = (fileForms, fileName) => {
        setLoading(true);
        downloadInstructionGuide(fileForms, fileName, () => {
            setLoading(false);
        });
    };

    const handleOkClick = () => {
        if (mediaTypeList.length > 0) {
            handleSubmit();
        } else {
            alertRef.current.scrollIntoView({
                top: 0,
                behavior: "smooth",
            });
            setShowErrorAlert(true);
        }
    };

    return (
        <>
            <CircularLoaderFullPage show={loading} />
            <SecureSyncModal
                open={true}
                title={t("containerPickup.addContainerContents")}
                maxWidth={"sm"}
                fullWidth
                footer={
                    <Box sx={styles.footerBtnSection}>
                        <Button variant="outlined" onClick={closeHandler}>
                            {t("buttonLabels.cancel")}
                        </Button>
                        <Button title={getKeyBoardLabel(shortCutKeys.ok)} variant="contained" onClick={handleOkClick}>
                            {t("buttonLabels.ok")}
                        </Button>
                    </Box>
                }
            >
                <Box sx={styles.container}>
                    <Box sx={styles.innerBox}>
                        <Box ref={alertRef}>
                            {showErrorAlert && (
                                <Box sx={styles.marginBottomBrake}>
                                    <SecureSyncAlert
                                        message={t("messages.submitEmptyMediaList", {
                                            buttonLabel: t("buttonLabels.cancel"),
                                        })}
                                        severity="error"
                                        onClose={() => {
                                            setShowErrorAlert(false);
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                        {isMediaTypeText ? (
                            <Typography variant="body1" sx={styles.subheader}>
                                <Trans
                                    i18nKey={"common.selectAnImportFile"}
                                    values={{ buttonLabel: t("buttonLabels.ok") }}
                                />
                            </Typography>
                        ) : (
                            <Typography variant="body1" sx={styles.subheader}>
                                <Trans i18nKey={"omDistribution.keyWedgeScanMediaNumbers"} />
                            </Typography>
                        )}
                        <br />
                        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
                            <SecureSyncSelect
                                required={true}
                                type="outlined"
                                label={t("containerPickup.mediaType")}
                                options={getSourceTypeOptions()}
                                value={mediaType}
                                onChange={handleMediaTypeChange}
                            />
                            <SecureSyncIconTooltip
                                title={
                                    <>
                                        <Typography>
                                            {t("containerPickup.mediaSourceOptions")}
                                            {isMediaTypeText ? (
                                                <Box sx={styles.importFileTooltip}>
                                                    {t("containerPickup.importATextFile")}
                                                </Box>
                                            ) : (
                                                <Box sx={styles.importFileTooltip}>
                                                    {t("omDistribution.enterMediaKeyboard")}
                                                </Box>
                                            )}
                                        </Typography>
                                    </>
                                }
                                placement="right"
                            />
                        </Box>
                        {isMediaTypeText ? (
                            <Box>
                                <br />
                                <Typography sx={styles.typography}>{t("common.importFile")} *</Typography>
                                <Box sx={styles.flexCenterJustifyLeft} gap={2}>
                                    <SecureSyncFileUpload
                                        startIcon={<UploadIcon />}
                                        variant="contained"
                                        onChange={fileReadHandler}
                                        accept=".txt"
                                        name={t("common.uploadFile")}
                                    />
                                    <SecureSyncIconTooltip
                                        title={t("containerPickup.fileFormatHelp")}
                                        placement="right"
                                        onClick={() =>
                                            downloadInstructionFile(
                                                staticFile["informationAboutImportingContainerContents"],
                                                importingContainerContentsFileName
                                            )
                                        }
                                    />
                                </Box>
                                {fileFormatError.hasError !== true ? (
                                    <Typography sx={styles.footNoteText}>
                                        {t("containerPickup.browseFilesToUploadTxt")}
                                    </Typography>
                                ) : (
                                    <Typography sx={styles.errorText}>{t("messages.importFailedMessage")}</Typography>
                                )}
                            </Box>
                        ) : (
                            <>
                                <br />
                                <Box sx={styles.flexCenter}>
                                    <Typography variant="body1" sx={styles.subheader}>
                                        {t("containerPickup.enterContentInstruction")}
                                    </Typography>
                                    <SecureSyncIconTooltip
                                        title={t("containerPickup.sample")}
                                        placement="right"
                                        onClick={() =>
                                            downloadInstructionFile(
                                                staticFile["informationAboutEnteringContainerContents"],
                                                enteringContainerContent
                                            )
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={styles.instructionText}>
                                        <Trans i18nKey={"containerPickup.stepsToEnterTheOpenMediaItem"} />
                                    </Typography>
                                </Box>
                                <br />
                            </>
                        )}
                        <SecureSyncTableCard>
                            <Box sx={styles.recordCount}>
                                {isMediaTypeText ? (
                                    mediaTypeList.length > 0 ? (
                                        <Box sx={styles.statusRecord}>
                                            <Typography variant="body1">
                                                {t("messages.recordsFound", { count: mediaTypeList.length })}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={styles.statusRecord}>
                                            <Typography variant="body1"></Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box sx={styles.addMediaSection}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label={t("containerPickup.scanTypeHere")}
                                                required
                                                focused
                                                inputProps={{ maxLength: 20 }}
                                                size="small"
                                                value={scanType}
                                                onChange={(e) => setScanType(e.target.value)}
                                                onKeyDown={enterToAddScan}
                                            />
                                            <Box sx={styles.marginLeft}>
                                                <FormHelperText>{t("common.addOrEnter")}</FormHelperText>
                                            </Box>
                                        </Box>
                                        <Box sx={styles.addBtnSection}>
                                            <Button variant={"outlined"} onClick={addScanTypeHandler}>
                                                {t("buttonLabels.add")}
                                            </Button>
                                            <SecureSyncIconTooltip
                                                title={
                                                    <Typography>
                                                        {t("omDistribution.howToEnterMedia")}:
                                                        <Box fontSize={12}>
                                                            {t("omDistribution.howToEnterMediaDescription")}
                                                        </Box>
                                                    </Typography>
                                                }
                                                placement="right"
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={styles.recordEnd}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            if (mediaTypeList.length > 0) {
                                                setShowDeleteConfirmation(true);
                                            }
                                        }}
                                    >
                                        {t("buttonLabels.deleteAll")}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={styles.tblCardContent}>
                                <SecureSyncTable
                                    rows={mediaTypeList}
                                    columns={columns}
                                    tableMinWidth="100px"
                                    getRowId={(row) => row.id}
                                    noDataMessage={t("messages.noAvailableRecords")}
                                    disablePagination={true}
                                    headerHeight="36px"
                                    rowHeight="32px"
                                />
                            </Box>
                        </SecureSyncTableCard>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={mediaTypeList[mediaTypeList.length - 1]?.mediaList || " "}
                                label={t("containerPickup.lastItem")}
                            />
                        </Box>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={getStatusValue()}
                                isError={fileFormatError.hasError}
                                label={t("common.status")}
                            />
                        </Box>
                        <Box marginTop={2}>
                            <SecureSyncReadOnlyTextField
                                value={getRecordCount()}
                                label={t("containerPickup.recordCount")}
                            />
                        </Box>
                    </Box>
                </Box>
            </SecureSyncModal>
            <DeleteAllConfirmation
                open={showDeleteConfirmation}
                onClose={closeDeleteAllConfirmation}
                handleConfirm={() => {
                    setMediaTypeList([]);
                    closeDeleteAllConfirmation();
                    clearFileError();
                }}
            />
            <CloseModalConfirmation
                handleConfirm={() => {
                    onClose();
                    resetModal();
                }}
                onClose={() => {
                    setShowCloseModalConfirmation(false);
                }}
                open={showCloseModalConfirmation}
            />
        </>
    );
};

export default AddContainerContentModal;
