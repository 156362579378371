export const getXml = (
    customerId,
    sysUserId,
    preferenceTypeId,
    preferenceTypeDescr,
    preferenceValue,
    preferenceLevelTypeId,
    applyToAllCustomer,
    systemId
) => {
    return `<Table><Customer_Id>${customerId}</Customer_Id><Sys_User_Id>${sysUserId}</Sys_User_Id><Preference_Type_Id>${preferenceTypeId}</Preference_Type_Id><Preference_Type_Descr>${preferenceTypeDescr}</Preference_Type_Descr><Preference_Value>${preferenceValue}</Preference_Value><Preference_Level_Type_Id>${preferenceLevelTypeId}</Preference_Level_Type_Id><Apply_To_All_Customers_Bit>${applyToAllCustomer}</Apply_To_All_Customers_Bit><System_Id>${systemId}</System_Id></Table>`;
};
