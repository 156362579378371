import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { fetchGlobalAttr, getGlobalAttributes } from "src/redux/applicationSlice";
import { getSelectedCustomerDb } from "src/services/utils";

const useGetGlobalAttrs = (systemId, mainDistrictId = "") => {
    const dispatch = useDispatch();
    const globalAttrs = useSelector(getGlobalAttributes);
    const globalAttrsById = globalAttrs[systemId];
    mainDistrictId = mainDistrictId ? mainDistrictId : getSelectedCustomerDb();
    useEffect(() => {
        if (globalAttrsById.status === fetchStatus.notStarted || globalAttrsById.mainDistrictId !== mainDistrictId) {
            dispatch(fetchGlobalAttr({ systemId, mainDistrictId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { data: globalAttrsById?.data, isLoading: globalAttrsById?.status === fetchStatus?.loading };
};

export default useGetGlobalAttrs;
