import irmLogo from "../assets/images/IM_Logo_FINAL_Color.png";

const oktaAuthConfig = {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    scopes: ["CIAM_TENANT", "openid", "profile", "email"],
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window?.location?.origin + "/login/auth",
    logo: irmLogo,
    useClassicEngine: true,
};

export { oktaAuthConfig };
