import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../VaultStatusReportAccordionSection";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import { vaultStatusReport } from "src/constants/routeConstants/operationsRouterFullPaths";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const AccountSummary = ({ rows = [] }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(rows.length > 0);
    }, [rows]);

    const columns = [
        {
            field: "customer_number",
            headerName: convertToLocaleUpperCase(t("tableHeader.customer")),
            sortable: true,
        },
        {
            field: "customer_name",
            headerName: convertToLocaleUpperCase(t("tableHeader.name")),
            sortable: true,
        },
        {
            field: "media_type_descr",
            headerName: convertToLocaleUpperCase(t("tableHeader.media")),
            sortable: true,
        },
        {
            field: "inbound_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfInbound")),
            sortable: true,
        },
        {
            field: "outbound_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfOutbound")),
            sortable: true,
        },
        {
            field: "audited_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfMediaAudited")),
            sortable: true,
        },
        {
            field: "destruction_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfDestruction")),
            sortable: true,
        },
        {
            field: "unresolved_count",
            headerName: convertToLocaleUpperCase(t("tableHeader.noOfUnresolvedDiscrepancy")),
            sortable: true,
        },
    ];

    const onTableStateChange = ({ rowsPerPage, pageNumber, order, orderBy }) => {
        const locationState = location.state || {};
        const pageTable = {
            accountSummaryPageNumber: pageNumber,
            accountSummaryRowsPerPage: rowsPerPage,
            accountSummaryOrderBy: orderBy,
            accountSummaryOrder: order,
        };

        navigate(vaultStatusReport, { state: { ...locationState, ...pageTable }, replace: true });
    };

    return (
        <Accordion sx={styles.accord} expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="authorization-verification-content"
                id="authorization-verification"
                onClick={() => setExpanded(!expanded)}
            >
                <Typography variant="body1" sx={styles.headerTypography}>
                    <Trans i18nKey={"titles.accountSummary"} />
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box style={styles.tableBox}>
                        <Paper>
                            <SecureSyncTable
                                propStyles={styles.dataGrid}
                                rows={rows}
                                columns={columns}
                                noDataMessage={t("messages.noRecordsFound")}
                                sx={styles.dataGrid}
                                initialPage={location.state?.accountSummaryPageNumber}
                                initialRowsPerPage={location.state?.accountSummaryRowsPerPage}
                                initialOrderBy={location.state?.accountSummaryOrderBy}
                                initialOrder={location.state?.accountSummaryOrder}
                                onTableAttributesChange={onTableStateChange}
                            />
                        </Paper>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccountSummary;
