import { Box, Container, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import styles from "./MiscServices.styles";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import MiscServicesForm from "src/components/Request/MiscServices/MiscServicesForm/MiscServicesForm";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getMiscServiceInfo } from "src/services/requestServices";
import PostFormSubmit from "src/components/Request/MiscServices/PostFormSubmit/PostFormSubmit";
import SecureSyncSnackBar from "src/components/common/SecureSyncSnackBar/SecureSyncSnackBar";

const MiscServices = () => {
    const [initValues, setInitValues] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [values, setValues] = useState();
    const [formSubmit, setFormSubmit] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await loadPageData();
            setLoading(false);
        };
        fetchData();
    }, []);
    const loadPageData = async () => {
        const values = await getMiscServiceInfo();
        setInitValues(values);
    };

    const { t } = useTranslation();
    return (
        <Container disableGutters>
            <CircularLoaderFullPage show={isLoading} />
            <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" width="100%">
                <Box width="100%" textAlign="left">
                    <PageTitle> {t("titles.miscServiceHeader")}</PageTitle>
                    <Typography variant="body1" sx={styles.typographyBody}>
                        <Trans i18nKey={""} />
                        <Trans
                            i18nKey={"miscService.serviceLocationHeader"}
                            values={{ buttonLabel: t("buttonLabels.submit") }}
                        />
                    </Typography>
                </Box>
            </Box>
            <SecureSyncSnackBar
                openSnackBar={formSubmit}
                message={<PostFormSubmit values={values} />}
                severity="success"
                onClose={() => {
                    setFormSubmit(false);
                }}
            />
            <MiscServicesForm
                initValues={initValues}
                setFormSubmit={() => setFormSubmit(true)}
                setValues={(val) => setValues(val)}
            />
        </Container>
    );
};

export default MiscServices;
