const styles = {
    confirmationModalFooter: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    alertBody: {
        paddingBottom: "10px",
    },
};

export default styles;
