import { Box, Button, Divider } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./OpenMediaDetails.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import ActionContainer from "src/components/Operations/Research/OpenMediaDetails/ActionContainer/ActionContainer";
import DescriptionSection from "src/components/Operations/Research/OpenMediaDetails/DescriptionSection/DescriptionSection";
import OpenMediaDetailsContainer from "src/components/Operations/Research/OpenMediaDetails/OpenMediaDetailsContainer/OpenMediaDetailsContainer";
import TransactionHistoryContainer from "src/components/Operations/Research/OpenMediaDetails/TransactionHistoryContainer/TransactionHistoryContainer";
import { noFlag, trueFlag, yesFlag } from "src/constants/serviceConstants";
import { insertLoggedEvent } from "src/services/processMediaService";
import { getDisplayFormat } from "src/utils/dateHelper";
import { ReactComponent as IconLeft } from "src/assets/images/IconLeft.svg";
import { errorCodes } from "src/constants/errorCodes";
import { globalMediaLocator, searchOpenMedia } from "src/constants/routeConstants/operationsRouterFullPaths";
import { useSecureSyncNavigate } from "src/customHooks";
import { getMediaData, getOpenMediaList, omUpdate } from "src/services/researchService";
import { getSelectedCustomer } from "src/redux/userDetailsSlice";

const OpenMediaDetails = () => {
    const selectedCustomer = useSelector(getSelectedCustomer);
    const { t } = useTranslation();
    const { goBack } = useSecureSyncNavigate();
    const [loading, setLoading] = useState(false);
    const url = useParams();
    const [params] = useSearchParams();
    const [mediaData, setMediaData] = useState({
        mediaNumber: "",
        description: "",
        drpCode: "",
        status: "",
        logicalVault: "",
        returnDate: "",
        mediaType: "",
        timeStamp: "",
        dateDisplay: "",
        isEditable: false,
    });
    const [headerMsg, setHeaderMsg] = useState("");
    const [selectedMediaId, setSelectedMediaId] = useState("");
    const [mediaList, setMediaList] = useState();
    const [nextMedia, setNextMedia] = useState(null);
    const [prevMedia, setPrevMedia] = useState(null);
    const [requests, setRequests] = useState([]);
    const [description, setDescription] = useState("");
    const [drpCode, setDrpCode] = useState("");
    const [error, setError] = useState({
        show: false,
        msg: "",
    });

    const districtId = params.get("districtId");

    useEffect(() => {
        const fetchData = async () => {
            const id = url?.mediaId;
            const districtId = params.get("districtId");
            const payload = {
                mediaNumber: params.get("mediaNumber"),
                mediaDescription: params.get("mediaDescription"),
                drpCode: params.get("drpCode"),
                returnDateFrom: params.get("returnDateFrom"),
                returnDateTo: params.get("returnDateTo"),
                currentStatus: params.get("currentStatus"),
                logicalVault: params.get("logicalVault"),
            };
            if (id && payload && !districtId) {
                setLoading(true);
                const mediaList = await fetchDocData(payload);
                mediaList.sort((a, b) => {
                    if (a.volser > b.volser) {
                        return 1;
                    } else if (b.volser > a.volser) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                setMediaList(mediaList);
                setSelectedMediaId(id);
                setLoading(false);
            } else {
                setLoading(true);
                await fetchMediaData(id, districtId);
                setNextMedia(null);
                setPrevMedia(null);
                setLoading(false);
            }
        };
        if (url && params) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, params]);

    useEffect(() => {
        if (selectedMediaId && mediaList && mediaList.length > 0) {
            fetchDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMediaId, mediaList]);

    const fetchDate = async () => {
        setLoading(true);
        await fetchMediaData(selectedMediaId);
        setNextAndPreviosMedia(selectedMediaId, mediaList);
        setLoading(false);
    };

    const setNextAndPreviosMedia = (id, mediaList) => {
        const index = mediaList.findIndex((media) => media.open_media_id === id);
        setNextMedia(mediaList[index + 1]);
        setPrevMedia(index === 0 ? null : mediaList[index - 1]);
    };

    const populateHeader = (isEditable, mediaDetails) => {
        let headerMsg = "";
        if (isEditable) {
            headerMsg = (
                <Trans i18nKey={"containerSearch.editableMsg"} values={{ buttonLabel: t("buttonLabels.find") }} />
            );
        } else {
            if (mediaDetails.part_of_dr_bit === trueFlag) {
                headerMsg = t("apiErrorMessages.80101", { item: t("common.openMedia") });
            } else if (
                mediaDetails.in_discrepancy === trueFlag &&
                mediaDetails.display_discrepancy_to_customer_flag === yesFlag
            ) {
                headerMsg = "apiErrorMessages.63024";
            } else if (mediaDetails.priority_req_flag === yesFlag) {
                headerMsg = "apiErrorMessages.63046";
            } else if (
                (mediaDetails.in_inventory_flag === "" || mediaDetails.in_inventory_flag === noFlag) &&
                (mediaDetails.delivered_flag === trueFlag || mediaDetails.delivered_flag === noFlag)
            ) {
                headerMsg = "apiErrorMessages.63037";
            }
        }
        return headerMsg;
    };

    const fetchMediaData = async (id, districtId = "") => {
        const res = await getMediaData(id, districtId);
        if (!res.failure) {
            const { mediaDetails, requestData } = res;
            const isEditable =
                mediaDetails.in_inventory_flag === yesFlag &&
                !(
                    mediaDetails.in_discrepancy === trueFlag &&
                    mediaDetails.display_discrepancy_to_customer_flag === yesFlag
                ) &&
                (mediaDetails.priority_req_flag === "" || mediaDetails.priority_req_flag === noFlag);
            const headerMsg = populateHeader(isEditable, mediaDetails);
            setHeaderMsg(headerMsg);
            setDescription(mediaDetails.open_media_descr);
            setDrpCode(mediaDetails.drp_code);
            setMediaData({
                mediaNumber: mediaDetails.volser,
                description: mediaDetails.open_media_descr,
                drpCode: mediaDetails.drp_code,
                status: mediaDetails.mes_summary_type,
                logicalVault: mediaDetails.lv_invoice_descr,
                returnDate: mediaDetails.return_date_local,
                mediaType: mediaDetails.media_type_descr,
                timeStamp: mediaDetails.timestamp,
                dateDisplay: mediaDetails.delivered_flag === yesFlag ? "" : mediaDetails.date_display,
                isEditable: isEditable,
            });

            const displayFormat = getDisplayFormat();
            const request = [];
            requestData.forEach((req) => {
                request.push({
                    dateIn: req.date_in,
                    deliveryDate: req.date_out,
                    transportContainer: req.container_number,
                    requestType: req.request_type_descr,
                    requestId: req.request_id,
                });
            });
            request.sort((a, b) => {
                const dateA = dayjs(a.dateIn, displayFormat);
                const dateB = dayjs(b.dateIn, displayFormat);
                return dateA.isAfter(dateB) ? -1 : 1;
            });
            setRequests(request);
        }
    };

    const fetchDocData = async (payload) => {
        const res = await getOpenMediaList(payload);
        if (!res.failure) {
            return res.verifiedResultList ? res.verifiedResultList : [];
        }
    };

    const changeDescription = (e) => {
        setDescription(e.target.value);
    };

    const changeDrpCode = (e) => {
        setDrpCode(e.target.value);
    };

    const resetForm = () => {
        setError({
            show: false,
            msg: "",
        });
        fetchDate();
    };

    const updateValues = async () => {
        setLoading(true);
        setError({
            show: false,
            msg: "",
        });
        const payload = {
            omId: selectedMediaId,
            drpCode: drpCode,
            description: description,
            ts: mediaData.timeStamp,
            returnDate: mediaData.returnDate,
        };
        const res = await omUpdate(payload);
        if (res.failure) {
            setError({
                show: true,
                msg: res.response.displayMessage,
            });

            if (
                [errorCodes.Code63024, errorCodes.Code63046, errorCodes.Code63037, errorCodes.Code10001].includes(
                    res.response.error
                )
            ) {
                await fetchMediaData(selectedMediaId);
            }
        } else {
            setLoading(false);
            await fetchDate();
            const loggedEventMsg = "Open Media Detail Submit Click";
            await insertLoggedEvent(loggedEventMsg, loggedEventMsg);
        }
        setLoading(false);
    };

    const nextRecord = () => {
        setSelectedMediaId(nextMedia.open_media_id);
    };

    const previousRecord = () => {
        setSelectedMediaId(prevMedia.open_media_id);
    };

    const onClickBack = () => {
        goBack(params.get("districtId") ? globalMediaLocator : searchOpenMedia);
    };

    const customerNumber = params.get("customerNumber") || selectedCustomer.customer_number;

    return (
        <Box>
            <CircularLoaderFullPage show={loading} />
            <PageTitle>{t("titles.openMediaDetails")}</PageTitle>
            <OpenMediaDetailsContainer
                data={mediaData}
                disable={!mediaData.isEditable || districtId}
                changeDrpCode={changeDrpCode}
                value={drpCode}
                headerMsg={headerMsg}
                error={error}
                closeError={() =>
                    setError({
                        show: false,
                        msg: "",
                    })
                }
            />
            <DescriptionSection
                value={description}
                handleChange={changeDescription}
                disable={!mediaData.isEditable || districtId}
            />
            <ActionContainer
                nextMedia={nextMedia}
                prevMedia={prevMedia}
                resetForm={resetForm}
                updateValues={updateValues}
                nextRecord={nextRecord}
                previousRecord={previousRecord}
                disable={!mediaData.isEditable || districtId}
            />
            <Divider />
            <TransactionHistoryContainer requests={requests} customerNumber={customerNumber} />
            <Box sx={styles.buttonContainer}>
                <Button variant="outlined" color="primary" onClick={onClickBack} startIcon={<IconLeft />}>
                    {t("buttonLabels.back")}
                </Button>
            </Box>
        </Box>
    );
};

export default OpenMediaDetails;
