import { getDocs } from "firebase/firestore";
import { t } from "i18next";
import dayjs from "dayjs";
import {
    constructFbHeaders,
    getCustomers,
    getPersonalId,
    invokeApi,
    getResultSets,
    getSelectedCustomerDb,
    getPersonalLocalId,
    userLnameFname,
    getPersonId,
} from "./utils";
import { apiResultSet0Collection } from "./firebase";
import { getSettingLink } from "./commonServices";
import { traceWrapper } from "./firebasePerformance";
import { NOT_AT_IRON_MOUNTAIN_TYPE, NOT_ON_INVENTORY_TYPE, defaultDistrictId } from "src/constants/serviceConstants";
import { dateFormats } from "src/constants/dateFormatConstants";
import { getDisplayFormat, startOfTheDate } from "src/utils/dateHelper";

const apiUrls = {
    getSummaryCount: "/home/getsummarycount",
    getRecentServiceReportsInfo: "/home/getrecentservicereportsinfo",
    returnInventoryCompareSummary: "/home/returninventorycomparesummary",
    inboundOutboundInventoryDetailReport: "/ssrequestreport/inboundoutboundinventorydetailreport",
    returnPidCustomerList: "/home/returnpidcustomerlist",
    returnCustomerListCustomer: "/home/returncustlistcustomer",
    returnServiceDetailSummary: "/home/returnservicedetailsummary",
    retrieveGlobalAttribute: "/home/retreiveglobalattribute",
};

/**
 *
 * @returns
 * this will return @obj
 * authorizationReuiringVerificationCount
 * pendingMediaDistructionCount
 */
export const getSummeryCount = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getSummaryCount);
    traceStart();
    const headers = constructFbHeaders();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";
    const personId = getPersonalId();

    // regional call sp
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/getsummarycount`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        personnel_id: personId,
        cust_list: custListString,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        resultSet0SnapShot.forEach((e) => {
            resultSet0.push(e.data());
        });
        // if resultSet0 has error throw new error
        if (resultSet0.find((e) => e.error === "0")) {
            throw new Error("result set couldn't find");
        }

        const authorizationRequiringVerification = resultSet0.find((e) => e.count_type === "1");
        const authorizationRequiringVerificationCount = authorizationRequiringVerification?.count
            ? authorizationRequiringVerification.count
            : "0";

        const pendingMediaDestruction = resultSet0.find((e) => e.count_type === "2");
        const pendingMediaDestructionCount = pendingMediaDestruction?.count ? pendingMediaDestruction.count : "0";

        const obj = {
            authorizationRequiringVerificationCount,
            pendingMediaDestructionCount,
        };
        return obj;
    } catch (err) {
        console.log(" error in getting summary count ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @obj
 * recentServiceReportCount
 */

export const getRecentServiceReport = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.getRecentServiceReportsInfo);
    traceStart();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/getrecentservicereportsinfo`;

    const body = {
        main_district_id: `${defaultDistrictId}`,
        cust_list: custListString,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        resultSet0SnapShot.forEach((e) => {
            resultSet0.push(e.data());
        });
        // if resultSet0 has error throw new error
        if (resultSet0.find((e) => e.error === "0")) {
            throw new Error("result set couldn't find");
        }
        const recentServiceReport = resultSet0.find((e) => e.count_type === "4");
        const recentServiceReportCount = recentServiceReport?.count ? recentServiceReport.count : "0";

        const obj = {
            recentServiceReportCount,
        };
        return obj;
    } catch (err) {
        console.log(" error in getting recent service report ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @array
 * inventory service report mapping
 */
export const getInventoryServiceReport = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnInventoryCompareSummary);
    traceStart();
    const custMetaData = getCustomers();
    let custListString = custMetaData.tempCustListStringZeroPadding ? custMetaData.tempCustListStringZeroPadding : [];
    custListString = custListString.toString();
    const headers = constructFbHeaders();
    const customers = custMetaData.custList ? custMetaData.custList : [];
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/returninventorycomparesummary`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        cust_list: custListString,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        const dateFormat = getDisplayFormat();
        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            const completeData = data.completed_date ? dayjs(data.completed_date).format(dateFormat) : "";
            const discrepancyTypeCode = data.discrepancy_type_code ? data.discrepancy_type_code.trim() : null;
            if (data.error !== "0") {
                const cust = customers.find((e) => e.customer_id === data.customer_id);
                const tempObj = {
                    id: e.id,
                    completeData: completeData,
                    logicalVault: data.logical_vault_code ? data.logical_vault_code : "",
                    customer: cust?.customer_number ? cust.customer_number : "",
                    customerId: cust?.customer_id ? cust.customer_id : "",
                    name: cust?.customer_name ? cust.customer_name : "",
                    branch: cust?.district_name ? cust.district_name : "",
                    districtId: cust?.district_id ? cust.district_id : "",
                    notInIronMountain:
                        discrepancyTypeCode && discrepancyTypeCode === NOT_AT_IRON_MOUNTAIN_TYPE
                            ? data.record_count
                            : "",
                    notOnInventory:
                        discrepancyTypeCode && discrepancyTypeCode === NOT_ON_INVENTORY_TYPE ? data.record_count : "",
                };
                resultSet0.push(tempObj);
            }
        });
        const mergedData = {};
        resultSet0.forEach((item) => {
            const indexKey = `${item.customerId}${item.completeData}${item.logicalVault}`;
            if (!mergedData[indexKey]) {
                mergedData[indexKey] = { ...item };
            } else {
                // Merge properties from subsequent occurrences
                Object.keys(item).forEach((key) => {
                    if (item[key] !== "") {
                        if (key === "notInIronMountain") {
                            let notInIromMountainCount =
                                mergedData[indexKey][key] && mergedData[indexKey][key] !== ""
                                    ? parseInt(mergedData[indexKey][key])
                                    : 0;
                            const notInIromMountainElementCount =
                                item[key] && item[key] !== "" ? parseInt(item[key]) : 0;
                            notInIromMountainCount = notInIromMountainCount + notInIromMountainElementCount;
                            mergedData[indexKey][key] =
                                notInIromMountainCount && notInIromMountainCount !== 0
                                    ? notInIromMountainCount.toString()
                                    : "";
                        } else if (key === "notOnInventory") {
                            let notOnInventoryCount =
                                mergedData[indexKey][key] && mergedData[indexKey][key] !== ""
                                    ? parseInt(mergedData[indexKey][key])
                                    : 0;
                            const notOnInventoryElementCount = item[key] && item[key] !== "" ? parseInt(item[key]) : 0;
                            notOnInventoryCount = notOnInventoryCount + notOnInventoryElementCount;
                            mergedData[indexKey][key] =
                                notOnInventoryCount && notOnInventoryCount !== 0 ? notOnInventoryCount.toString() : "";
                        } else {
                            mergedData[indexKey][key] = item[key];
                        }
                    }
                });
            }
        });
        const mergedArray = Object.values(mergedData);
        mergedArray.sort((a, b) => {
            const dateA = new dayjs(a.completeData, dateFormats.sortDate);
            const dateB = new dayjs(b.completeData, dateFormats.sortDate);
            return dateB - dateA; // For descending order
        });
        return mergedArray;
    } catch (err) {
        console.log(" error in getting inventory service report ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @download
 * inboundoutboundreport
 */
export const downloadInBoundOutBoundReport = async ({
    customerId,
    customerNumber,
    districtId,
    districtName,
    lastServiceDate,
}) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.inboundOutboundInventoryDetailReport);
    traceStart();
    const headers = constructFbHeaders();

    const url = `${process.env.REACT_APP_CF_URL_SSRS}/ssrequestreport/inboundoutboundinventorydetailreport`;
    const personalId = getPersonId();
    const body = {
        main_district_id: districtId,
        district_id: districtId,
        program_type: "A",
        customer_id: customerId,
        customer_number: customerNumber,
        logical_vault: "",
        date_from: lastServiceDate,
        date_to: lastServiceDate,
        limit_rows: "N",
        detail_flag: "Y",
        locale_id: getPersonalLocalId(),
        label_report_title: t("reports.inboundOutboundSummaryReport.labelReportTitle"),
        label_customer_number: t("reports.labelCustomerNumber"),
        label_report_date: t("reports.labelReportDate"),
        label_printed_by: t("reports.labelPrintedBy"),
        label_Selection_Criteria: t("reports.inboundOutboundDetailReports.selectionCriteria"),
        label_user_name: t("reports.labelReportDate"),
        label_report_name: t("reports.inboundOutboundDetailReports.labelReportName"),
        label_page: t("reports.labelPage"),
        label_of: t("reports.labelOf"),
        label_Logical_Vault: t("reports.inboundOutboundSummaryReport.logicalVaultR"),
        label_Date: t("reports.inboundOutboundSummaryReport.dateValue"),
        label_Inbound_to_Iron_Mountain: t("reports.inboundOutboundDetailReports.labelInboundToIronMountain"),
        label_Newly_Issued: t("reports.inboundOutboundDetailReports.labelNewlyIssued"),
        label_Outbound_from_IronMountain: t("reports.inboundOutboundDetailReports.labelOutboundFromIronMountain"),
        district_name: districtName,
        user_name: userLnameFname(),
        report_format: "PDF",
        personnel_id: personalId,
    };

    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const path = res?.data?.response?.gcs_path ? res.data.response.gcs_path : null;
        const filename = res?.data?.request?.namePrefix ? res.data.request.namePrefix : "NA";
        return {
            path,
            filename,
        };
    } catch (err) {
        console.log(" error in getting inventory service report ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @array
 * map of auth requiring verification
 */
export const getARVListService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnPidCustomerList);
    traceStart();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";
    const personId = getPersonalId();
    const customers = custMetaData.custList ? custMetaData.custList : [];
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/returnpidcustomerlist`;

    const body = {
        main_district_id: `${defaultDistrictId}`,
        cust_list: custListString,
        personnel_id: personId,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            const cust = customers.find((e) => e.customer_id === data.customer_id);
            const tempObj = {
                customer: cust?.customer_number ? cust.customer_number : "",
                customerId: cust?.customer_id ? cust.customer_id : "",
                name: cust?.customer_name ? cust.customer_name : "",
                branch: cust?.district_name ? cust.district_name : "",
                count: data.count,
            };
            resultSet0.push(tempObj);
        });
        resultSet0.sort((a, b) => {
            if (a.customer < b.customer) {
                return -1;
            }
            if (a.customer > b.customer) {
                return 1;
            }
            return 0;
        });
        return resultSet0;
    } catch (err) {
        console.log(" error in getting ARV list ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @array
 * map of pending media destrution
 */
export const getPMDRListService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnCustomerListCustomer);
    traceStart();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";
    const custList = custMetaData.custList ? custMetaData.custList : [];
    const personId = getPersonalId();
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/returncustlistcustomer`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        cust_list: custListString,
        personnel_id: personId,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            const cust = custList.find((e) => e.customer_id === data.customer_id);
            const tempObj = {
                customer: cust?.customer_number ? cust.customer_number : "",
                customerId: cust?.customer_id ? cust.customer_id : "",
                name: cust?.customer_name ? cust.customer_name : "",
                branch: cust?.district_name ? cust.district_name : "",
                count: data.count,
            };
            resultSet0.push(tempObj);
        });
        resultSet0.sort((a, b) => {
            if (a.customer < b.customer) {
                return -1;
            }
            if (a.customer > b.customer) {
                return 1;
            }
            return 0;
        });
        return resultSet0;
    } catch (err) {
        console.log(" error in getting PMD ", err);
        return err;
    } finally {
        traceStop();
    }
};

/**
 *
 * @returns
 * this will return @array
 * map of recent service detail list
 */
export const getSDListService = async () => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.returnServiceDetailSummary);
    traceStart();
    const custMetaData = getCustomers();
    const custListString = custMetaData.tempCustListString ? custMetaData.tempCustListString : "";
    const custList = custMetaData.custList ? custMetaData.custList : [];
    const headers = constructFbHeaders();
    const url = `${process.env.REACT_APP_CF_URL_HOME}/home/returnservicedetailsummary`;
    const body = {
        main_district_id: `${defaultDistrictId}`,
        cust_list: custListString,
    };
    try {
        const res = await invokeApi("POST", url, body, {}, headers);
        const resultSet0SnapShot = await getDocs(apiResultSet0Collection(res.docId));
        const resultSet0 = [];
        const displayFormat = getDisplayFormat();
        resultSet0SnapShot.forEach((e) => {
            const data = e.data();
            const cust = custList.find((e) => e.customer_id === data.customer_id);
            const formattedDate = dayjs(data.last_serviced_datetime).format(displayFormat);
            const reportDate = startOfTheDate(formattedDate, displayFormat);
            const tempObj = {
                id: e.id,
                customerId: data?.customer_id ? data.customer_id : "",
                customer: cust?.customer_number ? cust.customer_number : "",
                name: cust?.customer_name ? cust.customer_name : "",
                branch: cust?.district_name ? cust.district_name : "",
                districtId: cust?.district_id ? cust?.district_id : "",
                lastServiceDate: formattedDate,
                dateAndTime: data.last_serviced_datetime,
                reportDate,
            };
            resultSet0.push(tempObj);
        });
        resultSet0.sort((a, b) => {
            const dateA = new dayjs(a.dateAndTime);
            const dateB = new dayjs(b.dateAndTime);
            return dateB - dateA; // For descending order
        });
        return resultSet0;
    } catch (err) {
        console.log(" error in getting SD list ", err);
        return err;
    } finally {
        traceStop();
    }
};

export const getUrlList = async () => {
    try {
        const settingLinks = await getSettingLink();
        return settingLinks;
    } catch (error) {
        console.log(" error in getting URLs ", error);
        return error;
    }
};

export const getBannerInfoService = async ({ locale }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.retrieveGlobalAttribute);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_HOME + "/home/retrievebulletins";
    const custMetaData = getCustomers();
    const localIsoCode = locale;
    const customerString = custMetaData.tempCustListStringZeroPadding.join(",");
    const body = {
        main_district_id: defaultDistrictId,
        customers: customerString,
        locale: localIsoCode,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);

        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            notifications: response?.results[0] ? response?.results[0] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};

export const retrieveGlobalAttribute = async ({ systemId = "69" }) => {
    const { traceStart, traceStop } = traceWrapper(apiUrls.retrieveGlobalAttribute);
    traceStart();
    const headers = constructFbHeaders();
    const loginUrl = process.env.REACT_APP_CF_URL_HOME + "/home/retreiveglobalattribute";
    const body = {
        main_district_id: getSelectedCustomerDb(),
        system_id: systemId,
    };
    try {
        const res = await invokeApi("POST", loginUrl, body, {}, headers);
        const response = await getResultSets(res.docId, 2);

        if (response.failure) {
            return {
                failure: true,
                response,
            };
        }
        return {
            failure: false,
            globalMediaAt: response?.results[0] ? response?.results[0] : [],
        };
    } catch (err) {
        return { failure: true, error: err };
    } finally {
        traceStop();
    }
};
