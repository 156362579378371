import { openFileFromFB } from "src/services/utils";

const { getSelectedLanguage } = require("./i18nHelper");

const generateFilePathForCurrentLocale = (bucketPath, file, fileLanguage) => {
    const selectedLang = getSelectedLanguage();
    let language = "";
    if (fileLanguage) {
        language = fileLanguage;
    } else if (selectedLang) {
        language = selectedLang;
    } else {
        language = "en-US";
    }
    const filePath = `${bucketPath}/languages/${language}/${file}`;
    return filePath;
};

export default generateFilePathForCurrentLocale;

export const getFBDownloadUrl = async (fileName, fileLanguage = "") => {
    const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
    const filePath = generateFilePathForCurrentLocale(bucketPath, fileName, fileLanguage);
    const url = await openFileFromFB(filePath);
    let docUrl = "#";
    if (url && !url.failed) {
        docUrl = url;
    }
    return docUrl;
};
