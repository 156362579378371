const styles = {
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        mb: 3,
        height: "35px",
    },
    iconColor: {
        marginTop: "5px",
        color: "primary.main1",
    },
    mbBr: {
        marginBottom: "20px",
        width: "100%",
    },
    cardContent: {
        width: "100%",
        height: "auto",
    },
    recordStart: {
        justifyContent: "flex-start",
        padding: "16px",
    },
    recordEnd: {
        justifyContent: "flex-end",
        padding: "9px 16px",
    },
};

export default styles;
