import { Box, Container } from "@mui/material";

import { Outlet } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { Footer } from "../Footer/Footer";
import styles from "./MainLayout.styles";
import { Header } from "src/components/AppComponents/Header/Header";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { version } from "src/config";
import { useInitialLoad } from "src/customHooks";
import { getDayJsLocaleCode } from "src/redux/languageSlice";

const MainLayout = () => {
    const { isLoading: isUserDetailsLoading } = useInitialLoad();
    const isoLocaleCode = useSelector(getDayJsLocaleCode);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={isoLocaleCode}>
            <Box sx={styles.rootContainer}>
                <CircularLoaderFullPage show={isUserDetailsLoading} />
                <Box component="header">
                    {process.env.REACT_APP_ENV !== "prod" && (
                        <Box sx={styles.testEnv}>
                            TEST ENVIRONMENT - {process.env.REACT_APP_ENV.toUpperCase()} v{version}
                        </Box>
                    )}
                    <Header />
                </Box>
                <Container maxWidth="off" sx={styles.body}>
                    <div className="App">
                        <Outlet />
                    </div>
                </Container>
                <Footer />
            </Box>
        </LocalizationProvider>
    );
};

export default MainLayout;
