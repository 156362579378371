const styles = {
    filterDesc: {
        fontWeight: 500,
        margin: "20px 0",
    },
    recordCount: {
        display: "flex",
        justifyContent: "space-between",
        mb: 3,
        height: "35px",
    },
    recordStart: {
        justifyContent: "flex-start",
        padding: "16px",
    },
    recordEnd: {
        justifyContent: "flex-end",
        padding: "9px 16px",
    },
};

export default styles;
