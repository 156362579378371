import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "src/components/AppComponents/ProtectedRoute/ProtectedRoute";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import CustomerPreferences from "src/pages/Administration/Admin/CustomerPreferences/CustomerPreferences";
import EditMyPreferences from "src/pages/Administration/Admin/EditMyPreferences/EditMyPreferences";
import HolidayLists from "src/pages/Administration/Admin/HolidayLists/HolidayLists";
import RetentionProfiles from "src/pages/Administration/Admin/RetentionProfiles/RetentionProfiles";
import UserAccountDetails from "src/pages/Administration/Admin/UserDetails/UserAccountDetails/UserAccountDetails";
import UserDetails from "src/pages/Administration/Admin/UserDetails/UserDetails";
import UserPinChange from "src/pages/Administration/Admin/UserDetails/UserPinChange/UserPinChange";
import AddUsers from "src/pages/Administration/Authorization/AddUser/AddUser";
import Comments from "src/pages/Administration/Authorization/Comments/Comments";
import { SearchUsers } from "src/pages/Administration/Authorization/SearchUsers/SearchUsers";
import { UserVerification } from "src/pages/Administration/Authorization/UserVerification/UserVerification";
import { NotFound } from "src/pages/NotFound/NotFound";
import { getAccessRights } from "src/redux/userDetailsSlice";

const AdministrationRouter = () => {
    const {
        administration: { authorization, admin },
    } = routePaths;
    const { data: accessRights } = useSelector(getAccessRights);
    const pageAccess = accessRights.page || {};
    return (
        <Routes>
            <Route
                path={`${authorization.home}/*`}
                element={
                    <Routes>
                        <Route
                            index
                            element={
                                <ProtectedRoute hasAccessToView={pageAccess.searchUsers} component={SearchUsers} />
                            }
                        />
                        <Route
                            path={`${authorization.addUser}/*`}
                            element={<ProtectedRoute hasAccessToView={pageAccess.addUsers} component={AddUsers} />}
                        />
                        <Route
                            path={`${authorization.editUser}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyInfoUserDetail}
                                    component={UserDetails}
                                />
                            }
                        />
                        <Route
                            path={`${authorization.editAccount}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyPreference}
                                    component={UserAccountDetails}
                                />
                            }
                        />
                        <Route
                            path={`${authorization.editPin}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyPreference}
                                    component={UserPinChange}
                                />
                            }
                        />
                        <Route
                            path={`${authorization.comments}/*`}
                            element={<ProtectedRoute hasAccessToView={pageAccess.comments} component={Comments} />}
                        />
                        <Route
                            path={`${authorization.userVerification}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.userVerification}
                                    component={UserVerification}
                                />
                            }
                        />
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                }
            />
            <Route
                path={`${admin.home}/*`}
                element={
                    <Routes>
                        <Route
                            path={`${admin.editInfo}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyInfoUserDetail}
                                    component={UserDetails}
                                />
                            }
                        />
                        <Route
                            path={`${admin.editMyAccountInfo}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyPreference}
                                    component={UserAccountDetails}
                                />
                            }
                        />
                        <Route
                            path={`${admin.editPreference}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.editMyPreference}
                                    component={EditMyPreferences}
                                />
                            }
                        />
                        <Route
                            path={`${admin.customerPreference}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.customerPreference}
                                    component={CustomerPreferences}
                                />
                            }
                        />
                        <Route
                            path={`${admin.holidayLists}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.customerPreference}
                                    component={HolidayLists}
                                />
                            }
                        />
                        <Route
                            path={`${admin.retentionProfile}/*`}
                            element={
                                <ProtectedRoute
                                    hasAccessToView={pageAccess.retentionProfiles}
                                    component={RetentionProfiles}
                                />
                            }
                        />
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                }
            />
        </Routes>
    );
};

export default AdministrationRouter;
