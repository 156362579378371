const styles = {
    textPadding: {
        paddingBottom: "20px",
        whiteSpace: "pre-line",
    },
    heading: {
        textAlign: "center",
    },
    certificateHeading: {
        textAlign: "center",
        color: "im_dark_blue.500",
    },
    certificateDetails: {
        paddingBottom: "20px",
        color: "im_dark_blue.500",
    },
};

export default styles;
