const styles = {
    filterDesc: {
        fontWeight: 500,
        lineHeight: 1.5,
        mb: "20px",
        "& strong": {
            fontWeight: 900,
            lineHeight: 1.5,
        },
    },
    textContent: {
        fontWeight: 700,
    },
    searchContainer: {
        display: "flex",
        flexDirection: "column",
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: "20px",
        mb: "20px",
    },
    description: {
        gridColumn: "1/4",
    },
    searchButtons: {
        display: "flex",
        gap: "20px",
        mt: "20px",
    },
    returnDateFrom: {
        gridColumn: 1,
    },
};

export default styles;
