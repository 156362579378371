import { Box, Button, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SearchMediaDestructionFilter.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import SecureSyncDatePicker from "src/components/common/SecureSyncDatePicker/SecureSyncDatePicker";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import { getServiceDateForCustomerService, searchDestructedRequestService } from "src/services/requestServices";
import { formatDate, getDisplayFormat, getMinAndMaxDate, serviceDateMapper } from "src/utils/dateHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import { getDestructionRequestTypesAndStatus } from "src/services/commonServices";
import { sortHelper } from "src/utils/sortHelper";
import { getApiErrorMessage } from "src/services/utils";
import { destructRequestStatusIds, destructRequestTypeIds } from "src/constants/requestConstants";
import { searchMediaDestructionRequest } from "src/constants/routeConstants/requestRouterFullPaths";
import { noFlag } from "src/constants/serviceConstants";
import { useSecureSyncHotKeys } from "src/customHooks";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = ({ t }) => {
    const { minDateForApi: minDate, displayMinDate } = getMinAndMaxDate();
    const maxDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const displayMaxDate = formatDate(dayjs());
    return Yup.object().shape({
        destructRequestTypeId: Yup.string(),
        destructionRequestID: Yup.number().typeError(t("messages.minCriteriaRequestIDMessage")),
        destructRequestStatusId: Yup.string().test("requestTypeStatusCheck", getApiErrorMessage("63095"), function () {
            const { destructRequestTypeId, destructRequestStatusId } = this.parent;
            return !(
                destructRequestTypeId === destructRequestTypeIds.vaultItemized &&
                destructRequestStatusId === destructRequestStatusIds.scheduled
            );
        }),
        serviceDateFrom: Yup.string().test("validateServiceDateFrom", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isToDateValid = dayjs(parent.serviceDateTo, getDisplayFormat()).isValid();
            const isBeforeDate = dayjs(value, getDisplayFormat()).isSameOrBefore(
                dayjs(parent.serviceDateTo, getDisplayFormat())
            );
            const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(minDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (!isBeforeDate && isToDateValid) {
                    message = t("messages.fromDateLessThanCurrentDate", {
                        fromDate: formatDate(parent.serviceDateTo),
                    });
                } else if (isOutSideMinDate) {
                    message = t("messages.fromDateBetweenTheSpecificDate", {
                        from: t("common.from"),
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            }

            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
        serviceDateTo: Yup.string().test("validateServiceDateTo", (value, { createError, path, parent }) => {
            const isValidDate = dayjs(value, getDisplayFormat()).isValid();
            const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(maxDate);
            let message = "";
            if (value) {
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate) {
                    message = t("messages.toDateBetweenTheSpecificDate", {
                        to: t("common.to"),
                        minDate: displayMinDate,
                        maxDate: displayMaxDate,
                    });
                }
            }
            if (message) {
                return createError({
                    path,
                    message,
                });
            } else {
                return true;
            }
        }),
    });
};

const initialErrorState = {
    show: false,
    message: "",
};

const SearchMediaDestructionFilter = ({ onSearch, setSearchCriteria }) => {
    const shortCutKeys = getShortCutKeys();
    const { state: locationState } = useLocation();
    const navigate = useNavigate();
    const [requestTypeOptions, setRequestTypeOptions] = useState([]);
    const [requestStatusOptions, setRequestStatusOptions] = useState([]);
    const [isDropdownValuesLoading, setIsDropdownValuesLoading] = useState(false);
    const { t } = useTranslation();
    const defaultOption = { id: "all", value: "all", label: convertToLocaleUpperCase(t("common.all")) };
    const [errorMessage, setErrorMessage] = useState(initialErrorState);
    const { minDateForApi: minServiceDate } = getMinAndMaxDate();
    const maxServiceDate = dayjs().format(dateFormats.requestServiceDateFormat);
    const [serviceDates, setServiceDates] = useState([]);
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.search, callback: () => handleSubmit() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => handleReset() });

    useEffect(() => {
        const getDestructRequestDropDown = async () => {
            setIsDropdownValuesLoading(true);
            const { destructionTypes, destructionStatus } = await getDestructionRequestTypesAndStatus();
            setIsDropdownValuesLoading(false);
            if (destructionTypes) {
                const newDestructionTypesOptions = destructionTypes.map((destructionTypes) => ({
                    id: destructionTypes.destruct_request_type_id,
                    value: destructionTypes.destruct_request_type_id,
                    label: destructionTypes.destruct_request_type_descr,
                }));
                const sortedDestructionTypesOptions = sortHelper({
                    data: newDestructionTypesOptions,
                    primaryProperty: "id",
                    sortOrder: "asc",
                });
                sortedDestructionTypesOptions.unshift({ ...defaultOption });
                setRequestTypeOptions(newDestructionTypesOptions);
            }

            if (destructionStatus) {
                const newDestructionStatusOptions = destructionStatus.map((destructionStatus) => ({
                    id: destructionStatus.destruct_request_status_id,
                    value: destructionStatus.destruct_request_status_id,
                    label: destructionStatus.destruct_request_status_descr,
                }));
                const sortedDestructionStatusOptions = sortHelper({
                    data: newDestructionStatusOptions,
                    primaryProperty: "id",
                    sortOrder: "asc",
                });
                sortedDestructionStatusOptions.unshift({ ...defaultOption });
                setRequestStatusOptions(newDestructionStatusOptions);
            }
        };
        getDestructRequestDropDown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const restoreSearchResults = async () => {
            if (locationState) {
                const newFormValues = {
                    destructRequestTypeId: locationState?.destructRequestTypeId || defaultOption.value,
                    destructionRequestID: locationState?.destructionRequestID || "",
                    destructRequestStatusId: locationState?.destructRequestStatusId || defaultOption.value,
                    serviceDateFrom: locationState?.serviceDateFrom || "",
                    serviceDateTo: locationState?.serviceDateTo || "",
                };
                await setValues(newFormValues);
                onFindClick(newFormValues);
            }
        };
        restoreSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getServiceDateList = async () => {
            const response = await getServiceDateForCustomerService({
                fromDate: minServiceDate,
                toDate: maxServiceDate,
                rescheduleFlag: noFlag,
                addonCutoffFlag: "",
            });
            if (!response.failure) {
                if (response?.resultSet0?.length > 0) {
                    setServiceDates(serviceDateMapper(response?.resultSet0));
                }
            }
        };
        getServiceDateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPageState = (existingPageState, keepPaginationInfo) => {
        const newNewPageState = { ...existingPageState };
        if (keepPaginationInfo) {
            newNewPageState.pageNumber = locationState?.pageNumber;
            newNewPageState.rowsPerPage = locationState?.rowsPerPage;
        }
        return newNewPageState;
    };

    const onFindClick = async (values, isButtonClick) => {
        //To get all request we need to pass empty value.
        const fromDate = dayjs(values.serviceDateFrom, getDisplayFormat());
        const toDate = dayjs(values.serviceDateTo, getDisplayFormat());
        const destructRequestTypeId =
            values.destructRequestTypeId === defaultOption.value ? "" : values.destructRequestTypeId;
        const destructRequestStatusId =
            values.destructRequestStatusId === defaultOption.value ? "" : values.destructRequestStatusId;
        let payload = {};
        let serviceFromDate = minServiceDate;
        if (values.serviceDateFrom) {
            serviceFromDate = formatDate(fromDate, dateFormats.displayDate);
        } else if (values.serviceDateTo) {
            serviceFromDate = formatDate(toDate, dateFormats.displayDate);
            setFieldValue("serviceDateFrom", values.serviceDateTo);
        }

        let serviceToDate = maxServiceDate;
        if (values.serviceDateTo) {
            serviceToDate = formatDate(toDate, dateFormats.displayDate);
        } else if (values.serviceDateFrom) {
            serviceToDate = formatDate(fromDate, dateFormats.displayDate);
            setFieldValue("serviceDateTo", values.serviceDateFrom);
        }

        if (values.destructionRequestID) {
            payload.destructRequestId = values.destructionRequestID;
        } else {
            payload = {
                ...payload,
                serviceDateFrom: serviceFromDate,
                serviceDateTo: serviceToDate,
                destructRequestTypeId,
                destructRequestStatusId,
                destructRequestId: "",
            };
        }
        const pageState = getPageState({ ...values }, !isButtonClick);
        navigate(searchMediaDestructionRequest, {
            state: pageState,
            replace: true,
        });
        const destructionRequestData = await searchDestructedRequestService(payload);
        if (destructionRequestData[0]?.hasError) {
            setErrorMessage({ show: true, message: destructionRequestData[0].displayMessage });
            onSearch({ error: true });
        } else {
            const result = sortHelper({
                data: destructionRequestData,
                primaryProperty: "Destruct_Request_Id",
                secondaryProperty: null,
                sortOrder: "asc",
            });
            setErrorMessage(initialErrorState);
            onSearch({ error: false, result });
            setSearchCriteria({
                serviceDateFrom: values.serviceDateFrom || values.serviceDateTo ? serviceFromDate : "",
                serviceDateTo: serviceToDate,
                destructRequestTypeId,
                destructRequestStatusId,
                destructRequestId: "",
            });
        }
    };

    const handleReset = () => {
        resetForm();
        navigate(searchMediaDestructionRequest, {
            state: {},
            replace: true,
        });
        onSearch({ error: true, result: [] });
    };

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldTouched,
        setFieldValue,
        setValues,
        touched,
        values,
    } = useFormik({
        initialValues: {
            destructRequestTypeId: defaultOption.value,
            destructionRequestID: "",
            destructRequestStatusId: defaultOption.value,
            serviceDateFrom: "",
            serviceDateTo: "",
        },
        validationSchema: validationSchema({ t }),
        onSubmit: () => onFindClick(values, true),
    });
    return (
        <>
            <CircularLoaderFullPage show={isSubmitting || isDropdownValuesLoading} />
            {errorMessage.show && (
                <SecureSyncAlert
                    severity={"error"}
                    message={errorMessage.message}
                    onClose={() => setErrorMessage(initialErrorState)}
                />
            )}
            <form onSubmit={handleSubmit}>
                <Box sx={styles.searchContainer}>
                    <Typography sx={styles.filterDesc}>
                        <Trans
                            i18nKey={"searchMediaDestructionRequests.searchMediaDesc"}
                            values={{ buttonLabel: t("buttonLabels.search") }}
                        ></Trans>
                    </Typography>
                    <Box sx={styles.searchFields}>
                        <SecureSyncSelect
                            label={t("formFields.requestType")}
                            options={requestTypeOptions}
                            name="destructRequestTypeId"
                            value={values.destructRequestTypeId}
                            onChange={handleChange}
                        />
                        <TextField
                            label={t("common.destructionRequestID")}
                            name="destructionRequestID"
                            value={values.destructionRequestID}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.destructionRequestID && errors.destructionRequestID}
                            error={Boolean(touched.destructionRequestID && errors.destructionRequestID)}
                            inputProps={{ maxLength: 7 }}
                        />
                        <SecureSyncSelect
                            label={t("formFields.requestStatus")}
                            options={requestStatusOptions}
                            name="destructRequestStatusId"
                            value={values.destructRequestStatusId}
                            onChange={handleChange}
                            helperText={touched.destructRequestStatusId && errors.destructRequestStatusId}
                            error={Boolean(touched.destructRequestStatusId && errors.destructRequestStatusId)}
                        />
                        <div />
                        <SecureSyncDatePicker
                            name="serviceDateFrom"
                            value={values.serviceDateFrom}
                            label={t("formFields.serviceDateFrom")}
                            helperText={touched.serviceDateFrom && errors.serviceDateFrom}
                            error={Boolean(touched.serviceDateFrom && errors.serviceDateFrom)}
                            onChange={async (value) => {
                                await setFieldValue("serviceDateFrom", value);
                                setFieldTouched("serviceDateFrom", true, true);
                            }}
                            disableFuture
                            onBlur={handleBlur}
                            getDateProp={(dateProp) => {
                                const isServiceDate = serviceDates.find((serviceDate) =>
                                    serviceDate.isSame(dateProp, dateTimeUnits.day)
                                );
                                return { highlight: isServiceDate };
                            }}
                        />
                        <SecureSyncDatePicker
                            name="serviceDateTo"
                            value={values.serviceDateTo}
                            label={t("formFields.serviceDateTo")}
                            helperText={touched.serviceDateTo && errors.serviceDateTo}
                            error={Boolean(touched.serviceDateTo && errors.serviceDateTo)}
                            onChange={(value) => {
                                setFieldValue("serviceDateTo", value);
                                setFieldTouched("serviceDateTo", true, true);
                            }}
                            disableFuture
                            onBlur={handleBlur}
                            getDateProp={(dateProp) => {
                                const isServiceDate = serviceDates.find((serviceDate) =>
                                    serviceDate.isSame(dateProp, dateTimeUnits.day)
                                );
                                return { highlight: isServiceDate };
                            }}
                        />
                    </Box>
                    <Box sx={styles.searchButtons}>
                        <Button
                            title={getKeyBoardLabel(shortCutKeys.reset)}
                            variant="outlined"
                            type="reset"
                            onClick={() => {
                                handleReset();
                            }}
                        >
                            {t("buttonLabels.clear")}
                        </Button>
                        <Button title={getKeyBoardLabel(shortCutKeys.search)} variant="contained" type="submit">
                            {t("buttonLabels.find")}
                        </Button>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default SearchMediaDestructionFilter;
