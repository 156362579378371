/* eslint-disable camelcase */
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
import dayjs from "dayjs";
import { formatDate } from "./dateHelper";
import { multipleSort } from "./processMediaHelper";
import generateFilePathForCurrentLocale from "./generateFilePathForCurrentLocale";
import { convertToLocaleUpperCase } from "./commonHelper";
import { forbiddenExtensions } from "src/constants/fileFormatConstants";
import { dateFormats } from "src/constants/dateFormatConstants";
import {
    destructCancelReasonIds,
    destructRequestStatusIds,
    destructRequestTypeIds,
    requestInitialData,
    requestSortCriteria,
} from "src/constants/requestConstants";
import { globalAttributeTypeId } from "src/constants/serviceConstants";
import {
    getCustomerServiceFrequencyService,
    getCutOffTimeDetailsService,
    getOmListService,
    getRequestContainerDetailsService,
    getRequestDeleteContainerService,
    getRequestDetailsService,
    getRequestTransportInfoService,
    getSingleRequestDataService,
    validateRequestRelationService,
    verifyRequestCancellationService,
} from "src/services/requestServices";
import { downloadFileFromFB, getApiErrorMessage } from "src/services/utils";

const statusMessage = {
    show: false,
    status: "",
    message: " ",
};

export const validateRequestRelation = async (requestId) => {
    const payload = { requestId: requestId };
    const isRelatedResponse = await validateRequestRelationService(payload);
    return await Boolean(isRelatedResponse?.isRelated[0]?.is_related === "true");
};

export const getRequestDetails = async (requestId, setResponseMessage) => {
    const request = { requestId: requestId };
    const requestDetails = await getRequestDetailsService(request);
    if (requestDetails.request[0]?.hasError) {
        setResponseMessage({ show: true, status: "error", message: requestDetails.request[0]?.displayMessage });
        return [];
    } else {
        setResponseMessage(statusMessage);
        return requestDetails.request[0];
    }
};

export const getRequestTransportInfo = async (requestId) => {
    const request = { requestId: requestId };
    const transportData = await getRequestTransportInfoService(request);
    if (!transportData[0]?.hasError) {
        return transportData.transportData;
    }
};

export const verifyRequestCancellation = async (requestId) => {
    const request = { requestId: requestId };
    const cancelRequest = await verifyRequestCancellationService(request);
    if (!cancelRequest[0]?.hasError) {
        return cancelRequest;
    }
};

export const getSingleRequestData = async (requestId) => {
    const request = { requestId: requestId };
    const closedContainerResponse = await getSingleRequestDataService(request);
    const filteredExtraLocksAndClips = [];
    const filteredExtraInsertPurchase = [];
    closedContainerResponse.extraContainer.forEach((data) => {
        if (data.request_new_item_type_id === "4") {
            filteredExtraInsertPurchase.push(data);
        } else {
            filteredExtraLocksAndClips.push(data);
        }
    });
    return {
        closedContainerData: closedContainerResponse?.closedContainer,
        filteredExtraLocksAndClips,
        filteredExtraInsertPurchase,
    };
};

export const getOmList = async (requestId) => {
    const request = { requestId };
    const openMediaList = await getOmListService(request);
    return openMediaList.result;
};

export const getRequestContainerDetails = async (requestId) => {
    const request = { requestId };
    const containerDetails = await getRequestContainerDetailsService(request);
    return containerDetails.result;
};

export const getRequestDeleteContainer = async (requestId) => {
    const request = { requestId: requestId };
    const requestDeleteContainer = await getRequestDeleteContainerService(request);
    return requestDeleteContainer.deleteContainerData;
};

export const getAddOnCutOff = async () => {
    const cutOffTimeServiceResponses = await getCutOffTimeDetailsService();
    if (!cutOffTimeServiceResponses?.resultSet1[0]?.hasError) {
        const runDetailsResponse = cutOffTimeServiceResponses?.resultSet0[0];
        return {
            noOfServices: parseInt(runDetailsResponse?.num_day_of_week_code) || 0,
            addOnCutoffFlag: runDetailsResponse?.addoncutoffflag === "true" || false,
            previousDayFlag: runDetailsResponse?.previousdayflag === "true" || false,
            noOfServicesTom: parseInt(runDetailsResponse?.tomorrow_service_count) || 0,
            maxServiceDate: runDetailsResponse?.service_date || "",
        };
    }
    return requestInitialData.initialRunDetails;
};

export const getServiceFrequency = async () => {
    const servicePeriod = await getCustomerServiceFrequencyService();
    if (!servicePeriod?.result[0]?.hasError) {
        if (servicePeriod?.result && servicePeriod?.result.length > 0) {
            return {
                customerHasSchedule: true,
                scheduleStartDate: servicePeriod?.result[0]?.generated_date_start,
                scheduleEndDate: servicePeriod?.result[0].generated_date_end,
            };
        }
    }
    return requestInitialData.initialServicePeriod;
};
export const createCslList = (serviceDateList) => {
    const sortedServiceDateList = multipleSort(serviceDateList, requestSortCriteria.serviceDatesServiceDateSort);
    return sortedServiceDateList.reduce((acc, current) => {
        const { service_date, csl_customer_number } = current;
        const existingEntry = acc.find((item) => item.csl === csl_customer_number);
        if (existingEntry) {
            existingEntry.serviceDate.push(service_date);
        } else {
            acc.push({ csl: csl_customer_number, serviceDate: [service_date] });
        }
        return acc;
    }, []);
};

export const getCslByServiceDate = (groupedData, targetDate) => {
    const formattedTargetDate = formatDate(targetDate, dateFormats.isoDateTimeZ);
    return groupedData.filter((item) => item.serviceDate.includes(formattedTargetDate)).map((item) => item.csl);
};

export const getNewContainerGlobalAttrs = (globalAttributes) => {
    const globalAttribute96 = globalAttributes.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id96
    );
    const globalAttribute80 = globalAttributes.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id80
    );
    const maxExtraInsert = parseInt(globalAttribute80.global_attribute_value)
        ? parseInt(globalAttribute80.global_attribute_value)
        : 1000;
    return {
        allowCustomerMediaIdentifiers: globalAttribute96.global_attribute_value === "Y",
        maxExtraInsert,
    };
};

export const getDeliverMediaGlobalAttrs = (globalAttributes) => {
    const globalAttribute96 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id96
    );
    const globalAttribute94 = globalAttributes?.find(
        (globalAttr) => globalAttr.global_attribute_type_id === globalAttributeTypeId.id94
    );
    const maxContainerLength = globalAttribute96?.global_attribute_value === "Y" ? 20 : 6;
    return {
        allowCustomerMediaIdentifiers: globalAttribute96?.global_attribute_value === "Y",
        volserMaxLength: globalAttribute94,
        maxContainerLength,
    };
};

export const getContainers = (mediaTypeList) => {
    return mediaTypeList;
};
export const getOpenMedia = (mediaTypeList) => {
    return mediaTypeList;
};

export const handleFileRead = (event, maxCount, maxLength, countInTheGridAndList) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
        return Promise.resolve({ csvData: [], fileName: "", hasError: true, errorMsg: "Not a file" });
    }

    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (forbiddenExtensions.has(fileExtension)) {
        return Promise.resolve({ csvData: [], fileName: "", hasError: true, errorMsg: getApiErrorMessage(63443) });
    }

    const fileSize = file.size;
    const maxFileSize = (maxLength + 2) * maxCount + 1000;
    if (fileSize > maxFileSize) {
        return Promise.resolve({ csvData: [], fileName: "", hasError: true, errorMsg: getApiErrorMessage(26070) });
    }

    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const parsedData = await processCSV(text, maxCount, countInTheGridAndList);
            if (!text) {
                resolve({ csvData: [], fileName: file.name, hasError: true, errorMsg: getApiErrorMessage(26069) });
            }
            if (parsedData.error) {
                resolve({
                    csvData: [],
                    fileName: file.name,
                    hasError: true,
                    errorMsg: t("apiErrorMessages.63529", { maxCount: maxCount }),
                });
            } else {
                resolve({ csvData: parsedData.data, fileName: file.name, hasError: false });
            }
        };
        reader.readAsText(file);
        event.target.value = "";
    });
};

export const processCSV = async (text, maxCount, countInTheGridAndList) => {
    const rows = text
        .trim()
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line !== "");
    const remainRowCount = maxCount - countInTheGridAndList;
    if (rows.length > remainRowCount) {
        return { error: true };
    }

    const mappedData = rows.map((row) => {
        return row.replace(/[^a-zA-Z0-9-]/g, "");
    });

    return { error: false, data: mappedData };
};

export const downloadInstructionGuide = async (fileForms, fileName, onComplete = () => {}) => {
    const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;
    const file = generateFilePathForCurrentLocale(bucketPath, fileForms);
    await downloadFileFromFB({
        fbFilePath: file,
        fileName: fileName,
        onComplete: onComplete,
    });
};

export const updatedDropdownData = (dataArr) => {
    if (dataArr) {
        return dataArr.map((item) => {
            if (item?.sequence_id) {
                return {
                    ...item,
                    sequence_id: Number(item?.sequence_id),
                };
            }
            return item;
        });
    } else {
        return [];
    }
};

export const insertBulkData = (dataArr, mediaData, sequenceNumber) => {
    const insertedData = [];
    dataArr.forEach((element, index) => {
        const haveDuplicates = mediaData.some((item) => item.mediaNumber === element);
        if (!haveDuplicates) {
            insertedData.push({
                id: uuidv4(),
                sequenceNumber: sequenceNumber + index,
                mediaNumber: convertToLocaleUpperCase(element),
            });
        }
    });
    return insertedData;
};

export const getPageTitleAndDesc = (
    destructRequestTypeId,
    destructRequestStatusId,
    destructCancelReasonId,
    isAuthorizeRejectPossible,
    mediaDestructionDetails,
    minServiceDate
) => {
    let pageTitle = "";
    let description = "";
    if (destructRequestStatusId === destructRequestStatusIds.pendingConfirmation) {
        pageTitle = t("titles.mediaDestructionRequestIsReadyForFinalAuthorization");
        if (destructRequestTypeId === destructRequestTypeIds.vaultItemized) {
            description += t("mediaDestructionRequestDetail.vaultItemizedRequest");
        }
        if (destructRequestTypeId === destructRequestTypeIds.vaultItemized && isAuthorizeRejectPossible) {
            if (description) {
                description += "\n\n";
            }
            description += t("mediaDestructionRequestDetail.vaultItemizedRequestCanBeAuthorized", {
                acceptButtonLabel: t("buttonLabels.authorize"),
                rejectButtonLabel: t("buttonLabels.reject"),
            });
        }
        if (destructRequestTypeId === destructRequestTypeIds.pickUp) {
            if (description) {
                description += "\n\n";
            }
            description += t("mediaDestructionRequestDetail.pickUpRequest");
        }
        if (destructRequestTypeId === destructRequestTypeIds.pickUp && isAuthorizeRejectPossible) {
            if (description) {
                description += "\n\n";
            }
            description += t("mediaDestructionRequestDetail.pickUpRequestCanBeAuthorized", {
                acceptButtonLabel: t("buttonLabels.authorize"),
                rejectButtonLabel: t("buttonLabels.reject"),
            });
        }
    } else if (destructRequestStatusId === destructRequestStatusIds.inProgress) {
        pageTitle = t("titles.mediaDestructionRequestIsInProcess");
        if (dayjs(mediaDestructionDetails[0]?.certified_datetime_ltz).isAfter(minServiceDate)) {
            description += t("mediaDestructionRequestDetail.mediaDestructionRequestInProcess");
        }
    } else if (destructRequestStatusId === destructRequestStatusIds.completed) {
        pageTitle = t("titles.certificateOfDestruction");
        if (destructRequestTypeId === destructRequestTypeIds.vaultItemized) {
            description += t("mediaDestructionRequestDetail.certificateOfDestructionVaultItemized");
        }
        if (destructRequestTypeId === destructRequestTypeIds.pickUp) {
            if (description) {
                description += "\n\n";
            }
            description += t("mediaDestructionRequestDetail.certificateOfDestructionPickUp");
        }
    } else if (destructRequestStatusId === destructRequestStatusIds.cancelled) {
        pageTitle = t("titles.mediaDestructionRequestIsCancelled");
        if (destructCancelReasonId === destructCancelReasonIds.noEligibleItem) {
            description += t("mediaDestructionRequestDetail.mediaDestructionRequestCancelledBecauseNoEligibleItem");
        }
        if (destructCancelReasonId === destructCancelReasonIds.onHoldRequest) {
            if (description) {
                description += "\n\n";
            }
            description += t("mediaDestructionRequestDetail.mediaDestructionRequestCancelledOnHold");
        }
    }
    return { description, pageTitle };
};
