import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchRequestFilters from "src/components/Request/SearchRequests/SearchRequestFilters/SearchRequestFilters";
import SearchRequestsTable from "src/components/Request/SearchRequests/SearchRequestsTable/SearchRequestsTable";
import PageTitle from "src/components/common/PageTitle/PageTitle";

const SearchRequests = () => {
    const { t } = useTranslation();
    const [searchResults, setSearchResults] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({});

    const handleSearchResults = ({ error, result }) => {
        if (!error) {
            setIsSearched(true);
            setSearchResults(result);
        } else {
            setSearchResults([]);
            setIsSearched(false);
        }
    };

    return (
        <Box>
            <PageTitle>{t("searchRequests.searchRequests")}</PageTitle>
            <SearchRequestFilters onSearch={handleSearchResults} setSearchCriteria={setSearchCriteria} />
            <SearchRequestsTable isSearched={isSearched} records={searchResults} searchCriteria={searchCriteria} />
        </Box>
    );
};

export default SearchRequests;
