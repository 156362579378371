import { t } from "i18next";
import { getAllCaps } from "./researchConstants";

export const mediaOptionsDCValue = {
    container: "Container",
    openMedia: "OpenMedia",
};

export const mediaOptionsList = () => [
    {
        id: 1,
        label: t("common.all"),
        value: getAllCaps,
    },
    { id: "container", label: t("common.container"), value: mediaOptionsDCValue.container },
    { id: "media", label: t("common.openMediaDR"), value: mediaOptionsDCValue.openMedia },
];

export const discrepancyStatusValues = {
    unresolved: "Unresolved",
    notStarted: "Not Started",
    pendingResolution: "Pending Resolution",
    resolved: "Resolved",
};
export const discrepancyStatusList = () => [
    {
        id: 1,
        label: t("common.all"),
        value: getAllCaps,
    },
    {
        id: 2,
        label: t("discrepanctManagement.unresolved"),
        value: discrepancyStatusValues.unresolved,
    },
    {
        id: 4,
        label: t("discrepanctManagement.pendingResolution"),
        value: discrepancyStatusValues.pendingResolution,
    },
    {
        id: 5,
        label: t("discrepanctManagement.resolved"),
        value: discrepancyStatusValues.resolved,
    },
];
