import { Box, Button, SvgIcon, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import styles from "./SearchMediaDestructionRequestsTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import {
    getRequestMediaDestructionDetailPath,
    searchMediaDestructionRequest,
} from "src/constants/routeConstants/requestRouterFullPaths";
import { formatDate } from "src/utils/dateHelper";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getMediaDestructionDetailsPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const SearchMediaDestructionRequestsTable = ({ records = [], isSearched, searchCriteria }) => {
    const shortCutKeys = getShortCutKeys();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const hasRequests = records.length > 0;
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            if (hasRequests) {
                printReport();
            }
        },
    });

    const columns = useMemo(
        () => [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => (
                    <SecureSyncIconTooltip
                        title={t("searchMediaDestructionRequests.viewDestructionRequestDetail")}
                        icon={
                            <SvgIcon
                                fill={theme.palette.primary.main}
                                component={RemoveRedEyeFilled}
                                onClick={() => handleEditClick(params)}
                            />
                        }
                    ></SecureSyncIconTooltip>
                ),
            },
            {
                field: "Destruct_Request_Id",
                headerName: convertToLocaleUpperCase(t("common.destructionRequestID")),
                sortable: true,
            },
            {
                field: "Destruct_Request_Type_Descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.requestType")),
                sortable: true,
            },
            {
                field: "Destruct_Request_Status_Descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.requestStatus")),
                sortable: true,
            },
            {
                field: "Requested_Datetime_LTZ",
                headerName: convertToLocaleUpperCase(t("tableHeader.loggedDate")),
                sortable: true,
                type: "date",
                renderCell: (params) => {
                    const formattedDate = formatDate(params.Requested_Datetime_LTZ, "L HH:mm");
                    return formattedDate;
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const handleEditClick = (params) => {
        // Navigate to the edit page and pass the row data
        navigate(getRequestMediaDestructionDetailPath(params?.Destruct_Request_Id));
    };

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(searchMediaDestructionRequest, { state: { ...locationState, ...attributes }, replace: true });
    };

    const printReport = () => {
        const mediaList = [];
        records.forEach((e) => {
            mediaList.push(e.mediaNo);
        });
        setPrintPayload(
            getMediaDestructionDetailsPayload({
                destructRequestTypeId: searchCriteria?.destructRequestTypeId,
                destructRequestStatusId: searchCriteria?.destructRequestStatusId,
                destructRequestId: searchCriteria?.destructRequestId,
                dateFrom: searchCriteria?.serviceDateFrom,
                dateTo: searchCriteria?.serviceDateTo,
            })
        );
        printModalOpen(true);
    };

    return (
        <>
            <Box sx={styles.container}>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        {hasRequests && (
                            <>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: records.length })}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        printReport();
                                    }}
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                >
                                    {t("buttonLabels.prepareReport")}
                                </Button>
                            </>
                        )}
                    </Box>
                    <SecureSyncTable
                        rows={records}
                        columns={columns}
                        initialPage={location.state?.pageNumber}
                        initialRowsPerPage={location.state?.rowsPerPage}
                        noDataMessage={
                            isSearched ? t("messages.noMatchingRecords") : t("messages.pleaseProvideTheInput")
                        }
                        getRowId={(row) => row.Request_Id}
                        onTableAttributesChange={onTableStateChange}
                    />
                </SecureSyncTableCard>
                {hasRequests && (
                    <Typography sx={styles.footerCaption}>
                        <Trans i18nKey={"common.tableFooterCaption"}></Trans>
                    </Typography>
                )}
            </Box>
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.searchMediaDestructionRequest}
            />
        </>
    );
};
export default SearchMediaDestructionRequestsTable;
