/* eslint-disable camelcase */
import en_usTranslation from "src/i18n/english_us.json";
import en_gbTranslation from "src/i18n/english_gb.json";
import esTranslation from "src/i18n/spanish.json";
import es_419Translation from "src/i18n/spanish_419.json";
import csTranslation from "src/i18n/cestina.json";
import deTranslation from "src/i18n/deutsch.json";
import nlTranslation from "src/i18n/dutch.json";
import fr_caTranslation from "src/i18n/francais_ca.json";
import fr_frTranslation from "src/i18n/francais_fr.json";
import pt_brTranslation from "src/i18n/portugues.json";

export const languages = Object.freeze({
    en_us: "en-US", // english united states
    en_gb: "en-GB", //english united kingdom
    es: "es-ES", //español spain
    es_419: "es-MX", //español Latin America
    cs: "cs-CZ", //čeština czech republic
    de: "de-DE", //Deutsch germany
    nl: "nl-NL", //Dutch Netherland
    fr_FR: "fr-FR", // Français France
    fr_CA: "fr-CA", // Français Canada
    pt_BR: "pt-BR", // Português Brazil
});

export const i18nResources = Object.freeze({
    [languages.en_us]: {
        translation: en_usTranslation,
    },
    [languages.en_gb]: {
        translation: en_gbTranslation,
    },
    [languages.es]: {
        translation: esTranslation,
    },
    [languages.es_419]: {
        translation: es_419Translation,
    },
    [languages.cs]: {
        translation: csTranslation,
    },
    [languages.de]: {
        translation: deTranslation,
    },
    [languages.nl]: {
        translation: nlTranslation,
    },
    [languages.fr_FR]: {
        translation: fr_frTranslation,
    },
    [languages.fr_CA]: {
        translation: fr_caTranslation,
    },
    [languages.pt_BR]: {
        translation: pt_brTranslation,
    },
});
