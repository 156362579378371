import { getApp } from "firebase/app";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { getDocs, query } from "firebase/firestore";
import { t } from "i18next";
import store from "../redux/store";
import { post, get } from "../http/basehttp";
import { apiResultSet, app_setting_collection } from "./firebase";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import { errorList } from "src/constants/errorConstants/errorConstant";

/**
 * Function to set user token in browser storage to persist authentication across user sessions.
 * @param {string} token as in signed in user's uid
 */
export const setAuthorizationToken = (token) => {
    localStorage.setItem("ssToken", token);
};

/**
 * Function to return user token from browser storage
 * @returns {string} token as in user's uid
 */
export const getAuthorizationToken = () => {
    return localStorage.getItem("ssToken");
};

/**
 * Function to remove user token from browser storage.
 */
export const removeAuthorizationToken = () => {
    localStorage.removeItem("ssToken");
};

export const convertTimeStampToString = (ts = {}) =>
    ts?.seconds ? new Date(ts?.seconds * 1000).toISOString() : ts || "";

/**
 *
 * @param {string} method GET/POST http method types
 * @param {string} apiUrl  endpoint url
 * @param {object} requestBody request body content
 * @param {object} params path/query param details
 * @param {object} headers header info
 * @returns {object} wsg call response
 */
export const invokeApi = async (method, apiUrl, requestBody, params, headers, ignoreSanitization) => {
    let response = null;
    switch (method) {
        case "GET":
            response = await get(apiUrl, headers);
            break;
        case "POST":
            response = await post(apiUrl, requestBody, headers, ignoreSanitization);
            break;
        default:
            response = await get(apiUrl, requestBody, headers);
    }
    return response;
};

/**
 * Utility method that returns headers for cloud function headers
 */
export const constructOktaHeaders = () => {
    const token = store?.getState()?.userDetails?.idToken;
    const region = process.env.REACT_APP_CF_REGION;
    const env = process.env.REACT_APP_CF_ENV;

    if (token) {
        const header = "Bearer " + token;
        const headers = { Authorization: header, "Content-Type": "application/json", region: region, environment: env };
        return headers;
    }

    return null;
};

/**
 * Utility method that returns headers for cloud function headers
 */
export const constructFbHeaders = () => {
    const token = store?.getState()?.userDetails?.fbToken;
    const region = process.env.REACT_APP_CF_REGION;
    const env = process.env.REACT_APP_CF_ENV;

    if (token) {
        const header = "Bearer " + token;
        const headers = { Authorization: header, "Content-Type": "application/json", region: region, environment: env };
        return headers;
    }

    return null;
};

export const downloadFileFromFB = async ({ fbFilePath, fileName, onComplete }) => {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp);
    try {
        const url = await getDownloadURL(ref(storage, fbFilePath));
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
            const blob = xhr.response;
            const urlb = URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = urlb;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent("click"));
            onComplete();
        };
        xhr.open("GET", url);
        xhr.send();
    } catch (error) {
        onComplete();
        switch (error.code) {
            case "storage/object-not-found":
                return { failed: true, message: "File not exist" };

            case "storage/unauthorized":
                return { failed: true, message: "No access to file" };

            default:
                return { failed: true, message: "Unknown error. Contact admin" };
        }
    }
};

export const openFileFromFB = async (fbFilePath) => {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp);
    try {
        const url = await getDownloadURL(ref(storage, fbFilePath));
        return url;
    } catch (error) {
        switch (error.code) {
            case "storage/object-not-found":
                return { failed: true, message: "File not exist" };

            case "storage/unauthorized":
                return { failed: true, message: "No access to file" };

            default:
                return { failed: true, message: "Unknown error. Contact admin" };
        }
    }
};

export const uploadDrFilesToGcs = async (array, drId = "2", fileName) => {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC);
    try {
        const storageRef = ref(storage, `DR_FILE/UPLOAD/${drId}/${fileName}`);

        await uploadBytes(storageRef, array);
    } catch (error) {
        switch (error.code) {
            case "storage/object-not-found":
                return { failed: true, message: "File not exist" };

            case "storage/unauthorized":
                return { failed: true, message: "No access to file" };

            default:
                return { failed: true, message: "Unknown error. Contact admin" };
        }
    }
};

export const deleteFileFromFB = async ({ fbFilePath, onComplete }) => {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp);
    const fileRef = ref(storage, fbFilePath);

    try {
        await deleteObject(fileRef);
        onComplete();
        return { success: true, message: "File deleted successfully" };
    } catch (error) {
        onComplete();
        switch (error.code) {
            case "storage/object-not-found":
                return { failed: true, message: "File not found" };

            case "storage/unauthorized":
                return { failed: true, message: "No access to delete the file" };

            default:
                return { failed: true, message: "Unknown error. Contact admin" };
        }
    }
};

export const uploadDrInstructionFilesToGcs = async (content, drId = "2", fileName) => {
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC);
    try {
        const uploadPath = getDrInstructionFolderPath(drId, fileName);
        const storageRef = ref(storage, uploadPath);
        // Convert string content to Blob
        const blob = new Blob([content], { type: "application/rtf" });

        // Upload the Blob
        await uploadBytes(storageRef, blob);

        return { failed: false, uploadPath };
    } catch (error) {
        switch (error.code) {
            case "storage/object-not-found":
                return { failed: true, message: "File not exist" };

            case "storage/unauthorized":
                return { failed: true, message: "No access to file" };

            default:
                return { failed: true, message: "Unknown error. Contact admin" };
        }
    }
};

export const getDrInstructionFolderPath = (folderId, fileName) => `DR_INSTRUCTION_FILES/UPLOAD/${folderId}/${fileName}`;
export const getGsFilePath = (filePath) => {
    return `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}/${filePath}`;
};

/**
 * Returns Selected customer
 * @returns selectedCustomer
 */
export const getSelectedCustomer = () => {
    const selectedCustomer = store?.getState()?.userDetails?.customer?.selectedCustomer
        ? store.getState().userDetails.customer.selectedCustomer
        : null;
    return selectedCustomer;
};

/**
 * Returns the db_id of selected customer or default customer district id
 * @returns db_id
 */
export const getSelectedCustomerDb = () => {
    const customer = getSelectedCustomer();
    let districtId = "50";
    if (customer) {
        districtId = customer?.district_id;
    } else {
        districtId = store?.getState()?.userDetails?.customer?.defaultCustomer?.district_id;
    }
    return districtId;
};

/**
 * Returns the db_id of selected customer or default customer district name
 * @returns db_id
 */
export const getSelectedCustomerDistrictName = () => {
    const customer = getSelectedCustomer();
    let districtName = "";
    if (customer) {
        districtName = customer?.district_name;
    } else {
        districtName = store?.getState()?.userDetails?.customer?.defaultCustomer?.district_name;
    }
    return districtName;
};

/**
 * Returns the customer id of selected customer
 * @returns
 */
export const getSelectedCustomerId = () => {
    const customer = getSelectedCustomer();
    let customerID = "";
    if (customer) {
        customerID = customer?.customer_id;
    } else {
        customerID = store?.getState()?.userDetails?.customer?.defaultCustomer?.customer_id;
    }
    return customerID;
};

/**
 * Returns the Personal Local id of selected customer
 * @returns
 */
export const getPersonalLocalId = () => {
    return getPreferences().personnel[0]?.Personnel_Locale_Id || "";
};

/**
 * Returns the Personal id
 * @returns
 */
export const getPersonalId = () => {
    const sysUserId = store?.getState()?.userDetails?.basicUserInfo?.sys_user_id
        ? store.getState().userDetails.basicUserInfo.sys_user_id
        : "";
    return sysUserId;
};

/**
 * Returns the Personal name
 * @returns
 */
export const getPersonalName = () => {
    const personalName = getPreferences().personnel[0]?.Personnel_Name || "";
    return personalName;
};

/**
 * return selected language locale id
 * @returns
 */
export const getLanguageLocaleId = () => {
    const selectedLanguage = store?.getState()?.language?.selectedLanguageDetails;

    if (selectedLanguage) {
        return selectedLanguage.locale_id;
    }

    return "";
};

/**
 * return personal Time zone offset
 * @returns
 */
export const getPersonalTimeZoneOffset = () => {
    const offset = getPreferences()?.personalTimeOffset;
    if (offset) {
        return offset;
    }
    return "0";
};

export const getPreferences = () => {
    const preferences = store?.getState()?.userDetails?.preferences;
    return preferences.status === fetchStatus.completed ? preferences.data : {};
};

export const getSelectedCustomerTimeZoneOffset = () => {
    const customer = getSelectedCustomer();
    let timeZoneOffset = "1";
    if (customer) {
        timeZoneOffset = +customer?.time_zone_offset;
    }
    return timeZoneOffset.toString();
};
// internal functions
export const getCustomers = () => {
    const custList = store?.getState()?.userDetails?.customer?.activeCustomerList
        ? store?.getState().userDetails.customer.activeCustomerList
        : [];
    const selectedCustomer = store?.getState()?.userDetails?.customer?.selectedCustomer
        ? store?.getState().userDetails.customer.selectedCustomer
        : null;
    let tempCustListString = "";
    const tempCustListStringZeroPadding = [];
    // convert array into string with padding value
    custList.forEach((e) => {
        const customerId = e.customer_id;
        if (customerId) {
            const customer = customerId.padStart(10, " ");
            tempCustListString = `${tempCustListString}${customer}`;
            tempCustListStringZeroPadding.push(customerId);
        }
    });
    return {
        tempCustListString,
        custList,
        selectedCustomer,
        tempCustListStringZeroPadding,
    };
};

export const getSessionGuid = () => {
    const guid = store?.getState()?.userDetails?.sessionGuid ? store.getState().userDetails.sessionGuid : null;
    return guid;
};

export const getLoggedUserDetails = () => {
    const loggedInUser = store?.getState()?.userDetails ? store.getState().userDetails : null;
    return loggedInUser;
};

export const getEmail = () => {
    const email = store?.getState()?.userDetails?.oktaUser?.email ? store.getState().userDetails.oktaUser.email : null;
    return email;
};

export const getUserName = () => {
    const email = store?.getState()?.userDetails?.oktaUser?.preferred_username
        ? store.getState().userDetails.oktaUser.preferred_username
        : null;
    return email;
};

export const userLnameFname = () => {
    const userDetails = getLoggedUserDetails();
    const lastName = userDetails?.ssUserDetails?.last_name ? userDetails?.ssUserDetails?.last_name : "";
    const firstName = userDetails?.ssUserDetails?.first_name ? userDetails?.ssUserDetails?.first_name : "";
    const middleName = userDetails?.ssUserDetails?.middle_name ? `${userDetails?.ssUserDetails?.middle_name}.` : "";
    return `${lastName}, ${firstName} ${middleName}`;
};
export const getUserIdentifier = () => {
    const personId = store?.getState()?.userDetails?.basicUserInfo?.username
        ? store.getState().userDetails.basicUserInfo.username
        : "";
    return personId;
};

export const getTimeStamp = () => {
    var currentDate = new Date();

    // Get the time zone offset in minutes
    var offset = currentDate.getTimezoneOffset();

    // Calculate the time difference for EST (UTC - 5 hours)
    var estOffset = offset - 5 * 60;

    // Calculate the time for EST
    var estTime = new Date(currentDate.getTime() + estOffset * 60 * 1000);

    // Output EST time in a readable format
    const timeStamp = dayjs(estTime).format("YYYY-MM-DD HH:mm:ss");
    return timeStamp;
};

export const encryptWith3DESCBC = (message, key, iv) => {
    const encrypted = CryptoJS.TripleDES.encrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};

/**
 * Returns an object which contains all the subcollection results and also error assuming the last record will always be error
 * @param {*} docId
 * @param {*} resultSetIndexes
 * @returns
 */
export const getResultSets = async (docId, resultSetIndexes) => {
    let resultSetKeys = [];
    if (typeof resultSetIndexes === "number") {
        resultSetKeys = [...Array(resultSetIndexes).keys()];
    } else if (typeof resultSetIndexes === "object") {
        resultSetKeys = [...resultSetIndexes];
    }
    const promises = resultSetKeys.map((i) => {
        return getDocs(apiResultSet(docId, `resultSet_${i}`));
    });
    const snapShots = await Promise.all(promises);
    const results = snapShots.map((snapShot) => createArrayObj(snapShot));

    const lastResult = results[resultSetKeys.length - 1];
    if (lastResult[0]?.error && lastResult[0]?.error !== "0") {
        const displayMsg = t(`apiErrorMessages.${lastResult[0].error}`);
        return {
            failure: true,
            error: lastResult[0].error,
            displayMessage: displayMsg,
            severity: lastResult[0].severity,
            results,
        };
    }

    const data = {
        failure: false,
        results,
    };
    return data;
};

export const getApiErrorMessage = (errorCode) => {
    const apiErrors = t("apiErrorMessages", { returnObjects: true });
    return apiErrors[errorCode];
};

export const createArrayObj = (snapShot, checkError = true) => {
    const arr = [];
    snapShot.forEach((e) => {
        let obj;
        const element = e.data();
        // if has an error push that to the returned object
        if (element.error && checkError) {
            let displayMessage = "";
            let severity = "I";
            let errorObj = errorList.find((er) => er.errorCode === element.error);
            if (errorObj) {
                displayMessage = getApiErrorMessage(element.error);
                severity = errorObj?.severity ? errorObj.severity : "I";
            }
            errorObj = errorObj ? errorObj : element.error;
            obj = {
                hasError: errorObj === "0" ? false : true,
                error: errorObj,
                displayMessage,
                severity,
            };
        } else {
            obj = element;
        }
        arr.push(obj);
    });
    return arr;
};

export const getPersonId = () => {
    const personId = store?.getState()?.userDetails?.basicUserInfo?.sys_user_id
        ? store.getState().userDetails.basicUserInfo.sys_user_id
        : "";
    return personId;
};

export const getProgramIds = () => {
    const programIds = store?.getState()?.userDetails?.programIds ? store.getState().userDetails.programIds : "";
    return programIds;
};

export const getRegion = () => {
    const region = process.env.REACT_APP_CF_REGION;
    return region;
};

export const checkAuthLevelValidity = (values) => {
    let isValid = true;
    let msg = "";
    if (
        (values.authorizationLevel.AReleaseMedia &&
            !values.authorizationLevel.BReceiveRequestMedia &&
            values.authorizationLevel.CAccessIronMountainFacilities &&
            !values.authorizationLevel.DPlaceEmergencyRequests &&
            !values.authorizationLevel.EModifyAuthorizations &&
            !values.authorizationLevel.RReceiveOnlyCannotRequestMedia) ||
        (values.authorizationLevel.AReleaseMedia &&
            !values.authorizationLevel.BReceiveRequestMedia &&
            values.authorizationLevel.CAccessIronMountainFacilities &&
            !values.authorizationLevel.DPlaceEmergencyRequests &&
            values.authorizationLevel.EModifyAuthorizations &&
            !values.authorizationLevel.RReceiveOnlyCannotRequestMedia) ||
        (values.authorizationLevel.BReceiveRequestMedia && values.authorizationLevel.RReceiveOnlyCannotRequestMedia) ||
        (values.authorizationLevel.DPlaceEmergencyRequests && values.authorizationLevel.RReceiveOnlyCannotRequestMedia)
    ) {
        isValid = false;
        msg = t("authorization.authLevelNotMatched");
    }
    return { validate: isValid, message: msg };
};

export const isPerformanceMonitoringEnabled = async () => {
    const q = query(app_setting_collection);
    const snapshot = await getDocs(q);
    let performancePreference;
    snapshot.forEach((e) => {
        if (e.id === "secure_sync_ui") {
            const tempData = e.data();
            performancePreference = tempData;
        }
    });
    const isEnabled = performancePreference?.performance_monitoring_enable;
    return isEnabled;
};

export const getReportConfig = async (targetReport) => {
    const q = query(app_setting_collection);
    const snapshot = await getDocs(q);
    let reportSettings;
    snapshot.forEach((e) => {
        if (e.id === "report_configuration_ss") {
            const tempData = e.data();
            reportSettings = tempData[targetReport];
        }
    });
    return reportSettings;
};
