/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProfileMenu.styles";
import PopUpMenu from "src/components/common/PopUpMenu/PopUpMenu";
import { clearUserDetails, getSSUserDetail } from "src/redux/userDetailsSlice";
import { routePaths } from "src/constants/routeConstants/routeConstants";
import { fbSignOut } from "src/services/authService";

function ProfileMenu() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { oktaAuth } = useOktaAuth();
    const user = useSelector(getSSUserDetail);
    const dispatch = useDispatch();
    const i18nextLng = localStorage.getItem("i18nextLng");
    let dropDownItems = [];

    const [dropOption, setDropOptions] = useState(dropDownItems);
    useEffect(() => {
        dropDownItems = [
            {
                label: t("headerMenu.logout"),
                action: () => signoutUser(),
            },
        ];
        setDropOptions(dropDownItems);
    }, [i18nextLng]);

    // sign out okta user
    const signoutUser = async () => {
        dispatch(clearUserDetails());
        fbSignOut();
        await oktaAuth.signOut();
        navigate(routePaths.signIn);
    };

    return (
        <Grid item xs={9} sx={styles.NavLinks} justifyContent="end">
            <Grid item sx={styles.display}>
                <Box sx={styles.avatarButtonStyle}>
                    <PopUpMenu
                        icon={
                            <Tooltip
                                title={
                                    <Box sx={styles.userPopup}>
                                        <Typography
                                            sx={styles.popupUserName}
                                        >{`${user?.first_name} ${user?.last_name}`}</Typography>
                                        <Typography sx={styles.popupEmail}>{user?.login}</Typography>
                                    </Box>
                                }
                                PopperProps={{ sx: styles.infoTooltip }}
                            >
                                <Avatar alt={user?.name || "Avatar"} sx={styles.Avatar} src={user?.photoURL} />
                            </Tooltip>
                        }
                        options={dropOption}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default ProfileMenu;
