export const styles = {
    headerTypography: {
        color: "#000000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%" /* 41.99px */,
        letterSpacing: "0.15px",
    },
    bodyTypography: {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%" /* 41.99px */,
        letterSpacing: "0.15px",
        paddingBottom: "20px",
        cursor: "pointer",
    },
    dataGrid: {
        maxHeight: 400,
    },
    accord: { mb: 3, borderTopLeftRadius: "8px", borderTopRightRadius: "8px" },
    tableBox: { height: 400, width: "100%" },
};
