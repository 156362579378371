import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CompleteLibraryAuditsTable.styles";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import { completedLibraryAudit } from "src/constants/routeConstants/operationsRouterFullPaths";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const CompleteLibraryAuditsTable = ({ auditData, setSelectedAudit }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const columns = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setSelectedAudit(params);
                        }}
                    >
                        <RemoveRedEyeFilled fill={theme.palette.im_mid_blue["500"]} />
                    </IconButton>
                );
            },
        },
        {
            field: "Completion_Date",
            headerName: convertToLocaleUpperCase(t("tableHeader.completionDate")),
            sortable: true,
            type: "date",
        },
        {
            field: "Requested_By",
            headerName: convertToLocaleUpperCase(t("tableHeader.requestedBy")),
            sortable: true,
        },
        {
            field: "Logical_Vaults",
            headerName: convertToLocaleUpperCase(t("tableHeader.logicalVaults")),
        },
        {
            field: "Exception_Count",
            headerName: convertToLocaleUpperCase(t("tableHeader.exceptionCount")),
            sortable: true,
            type: "number",
        },
    ];

    const onTableStateChange = (attributes) => {
        const locationState = location.state || {};
        navigate(completedLibraryAudit, { state: { ...locationState, ...attributes }, replace: true });
    };

    return (
        <SecureSyncTableCard
            title={
                <Typography variant="h6" sx={styles.cardHederText}>
                    {t("completedLibraryAudits.availableCompletedLibraryAudits")}
                </Typography>
            }
        >
            <Box sx={styles.recordCount}>
                {auditData.length > 0 ? (
                    <>
                        <Box sx={styles.recordStart}>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: auditData.length })}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.recordStart} />
                )}
            </Box>
            <Box sx={styles.cardContent}>
                <SecureSyncTable
                    rows={auditData}
                    columns={columns}
                    getRowId={(row) => row.Audit_Id}
                    noDataMessage={t("completedLibraryAudits.noRecordsFound")}
                    initialPage={location.state?.pageNumber}
                    initialRowsPerPage={location.state?.rowsPerPage}
                    initialOrderBy={location.state?.orderBy}
                    initialOrder={location.state?.order}
                    onTableAttributesChange={onTableStateChange}
                />
            </Box>
        </SecureSyncTableCard>
    );
};

export default CompleteLibraryAuditsTable;
