import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userDetailsSlice";
import languageSlice from "./languageSlice";
import applicationSlice from "./applicationSlice";
import authSlice from "./authSlice";

export default configureStore({
    reducer: {
        userDetails: userSlice,
        language: languageSlice,
        application: applicationSlice,
        auth: authSlice,
    },
});
