/* eslint-disable max-lines */
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useHotkeysContext } from "react-hotkeys-hook";
import styles from "./DeliverMedia.styles";
import ServiceDateCalendar from "src/components/AppComponents/ServiceDateCalendar/ServiceDateCalendar";
import ServiceDateCalendarTooltip from "src/components/AppComponents/ServiceDateCalendarTooltip/ServiceDateCalendarTooltip";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import SecureSyncSelect from "src/components/common/SecureSyncSelect/SecureSyncSelect";
import AddContainers from "src/components/Request/DeliverMedia/AddContainer/AddContainers";
import AddOpenMedia from "src/components/Request/DeliverMedia/AddOpenMedia/AddOpenMedia";
import { preferenceLevelTypeId, preferenceTypeId } from "src/constants/preferenceServiceConstants";
import { programIds } from "src/constants/programIdsConstants";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getBasicUserInfo, getProgramIdsOfCurrentCustomer } from "src/redux/userDetailsSlice";
import { getPreferencesService } from "src/services/adminService";
import {
    createDeliveryMediaRequestService,
    getActiveCustomersSiblingsService,
    getIronmountainCustomerContainersService,
} from "src/services/requestServices";
import { getPreferences, getSelectedCustomer } from "src/services/utils";
import { isUserProgramLevelAvailable } from "src/utils/applicationHelper";
import { formatDate, getdateObject, getDisplayFormat, getMinAndMaxDate } from "src/utils/dateHelper";
import { downloadInstructionGuide, getDeliverMediaGlobalAttrs, getServiceFrequency } from "src/utils/requestHelper";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import MediaRequestedTable from "src/components/Request/DeliverMedia/MediaRequestedTable/MediaRequestedTable";
import { getKeyBoardLabel, padEndStringWithSelectedCharacters } from "src/utils/commonHelper";
import { dateFormats, dateTimeUnits } from "src/constants/dateFormatConstants";
import { errorCodes } from "src/constants/errorCodes";
import { importDeliverMediaFileFileName, staticFile } from "src/constants/fileConstants";
import useGetGlobalAttrs from "src/customHooks/useGetGlobalAttrs";
import { systemId } from "src/constants/serviceConstants";
import { deliverMediaStatus } from "src/constants/requestConstants";
import DeliverMediaSuccessModal from "src/components/Request/DeliverMedia/DeliverMediaSuccessModal/DeliverMediaSuccessModal";
import SecureSyncPrintModal from "src/components/common/SecureSyncPrintModal/SecureSyncPrintModal";
import { targetUrl } from "src/constants/printConstants";
import { getDeliverMediaPayload } from "src/services/printServices";
import { useSecureSyncHotKeys } from "src/customHooks";
import { hotKeysScopes } from "src/constants/shortCutKeys";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const initialValues = {
    serviceLocationId: "",
    serviceDate: "",
    comments: "",
};

const initialErrorState = {
    status: "",
    show: false,
    message: "",
};

const commentsMaxLength = 255;

const validationSchema = (t, isCommentsRequired) => {
    const { minDateForApi: minDate, maxDateForApi: maxDate, displayMaxDate, displayMinDate } = getMinAndMaxDate();
    return Yup.object().shape({
        serviceLocationId: Yup.string(),
        serviceDate: Yup.string()
            .required(t("messages.serviceDateRequired"))
            .test("validateServiceDate", (value, { createError, path }) => {
                const isValidDate = dayjs(value, getDisplayFormat()).isValid();
                const isOutSideMinDate = dayjs(value, getDisplayFormat()).isBefore(minDate);
                const isOutSideMaxDate = dayjs(value, getDisplayFormat()).isAfter(maxDate);
                let message = "";
                if (!isValidDate) {
                    message = t("messages.invalidDate");
                } else if (isOutSideMaxDate || isOutSideMinDate) {
                    message = (
                        <Trans
                            i18nKey={"messages.serviceDateRange"}
                            values={{
                                serviceDate: t("formFields.serviceDate"),
                                minDate: displayMinDate,
                                maxDate: displayMaxDate,
                            }}
                        />
                    );
                }

                if (message) {
                    return createError({
                        path,
                        message,
                    });
                } else {
                    return true;
                }
            }),
        comments: Yup.string()
            .max(commentsMaxLength, t("messages.commentsMaxLength", { maxLength: commentsMaxLength }))
            .test("is-required", t("messages.commentRequired"), function (value) {
                if (isCommentsRequired) {
                    return !!value;
                }
                return true;
            }),
    });
};

const useShortCutKeys = (handleSubmit, handleReset, handleValidate) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: handleSubmit,
        options: { scopes: hotKeysScopes.deliverMedia },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.reset,
        callback: handleReset,
        options: { scopes: hotKeysScopes.deliverMedia },
    });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.verify, callback: handleValidate });
    const { enableScope } = useHotkeysContext();
    useEffect(() => {
        enableScope(hotKeysScopes.deliverMedia);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const DeliverMedia = () => {
    const shortCutKeys = getShortCutKeys();
    useShortCutKeys(
        () => handleSubmit(),
        () => {
            handleReset();
        },
        () => handleValidate()
    );
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectedCustomer = useSelector(getSelectedCustomer);
    const programIdsForCurrentCustomer = useSelector(getProgramIdsOfCurrentCustomer);
    const hasPermission = isUserProgramLevelAvailable(programIdsForCurrentCustomer, programIds.programId_213);
    const description = hasPermission ? (
        <Trans
            i18nKey={"apiErrorMessages.63535"}
            values={{ buttonLabel: t("common.validate"), buttonLabelSubmit: t("buttonLabels.submit") }}
        />
    ) : (
        <Trans
            i18nKey={"apiErrorMessages.63638"}
            values={{ buttonLabel: t("common.validate"), buttonLabelSubmit: t("buttonLabels.submit") }}
        />
    );
    const [isScheduleServiceLoading, setIsScheduleServiceLoading] = useState(false);
    const [isServiceDateLoading, setIsServiceDateLoading] = useState(false);
    const [runDetails, setRunDetails] = useState();
    const [serviceLocations, setServiceLocations] = useState([]);
    const [serviceFrequencyValues, setServiceFrequencyValues] = useState({});
    const [selectedContainer, setSelectedContainer] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openMediaList, setOpenMediaList] = useState([]);
    const [containerList, setContainerList] = useState([]);
    const [isCommentsRequired, setIsCommentsRequired] = useState([]);
    const [errorMessage, setErrorMessage] = useState(initialErrorState);
    const [validatedData, setValidatedData] = useState([]);
    const [recordCounts, setRecordCounts] = useState({ openMediaCount: 0, containerCount: 0 });
    const [openMediaTypeList, setOpenMediaTypeList] = useState([]);
    const [containerTypeList, setContainerTypeList] = useState([]);
    const globalAttributes = useGetGlobalAttrs(systemId.id50);
    const userDetails = useSelector(getBasicUserInfo);
    const [sequenceNumber, setSequenceNumber] = useState(1);
    const [isTodayServiceDate, setIsTodayServiceDate] = useState(false);
    const [isPrintModalOpen, printModalOpen] = useState(false);
    const [printPayload, setPrintPayload] = useState(null);

    const [mediaRequestDeadLineStatus, setMediaRequestDeadLineStatus] = useState({
        show: false,
        value: "",
    });

    const serviceDateRef = useRef(null);
    const commentsRef = useRef(null);

    const globalAttr = useMemo(() => {
        return getDeliverMediaGlobalAttrs(globalAttributes?.data?.rs0);
    }, [globalAttributes]);

    const [successModalObj, setSuccessModalObj] = useState({
        show: false,
        values: {},
    });

    const serviceLocationOptions = useMemo(() => {
        return serviceLocations.map(({ customer_id: customerId, customer_number: customerNumber }) => ({
            id: customerId.toString(),
            value: customerId.toString(),
            label: customerNumber.toString(),
        }));
    }, [serviceLocations]);

    const getSelectedContainerList = async () => {
        const selectedCustomerList = await getIronmountainCustomerContainersService();
        return selectedCustomerList;
    };

    useEffect(() => {
        const fetchCustomerServiceFrequency = async () => {
            setIsScheduleServiceLoading(true);
            const serviceFreqValues = await getServiceFrequency();
            setServiceFrequencyValues(serviceFreqValues);
            setSelectedContainer(await getSelectedContainerList());
            setIsScheduleServiceLoading(false);
        };
        fetchCustomerServiceFrequency();
    }, []);

    useEffect(() => {
        if (serviceFrequencyValues.customerHasSchedule) {
            const fetchInitialData = async () => {
                setIsLoading(true);
                const [data] = await Promise.all([
                    getActiveCustomersSiblingsService(),
                    checkCommentsRequired(),
                    fetchMediaRequestDeadLineStatus(),
                ]);
                setServiceLocations(data);
                setIsLoading(false);
            };
            fetchInitialData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceFrequencyValues.customerHasSchedule, globalAttributes.data]);

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        setFieldValue,
        setValues,
        handleSubmit,
        handleBlur,
        resetForm,
        submitCount,
    } = useFormik({
        initialValues,
        validationSchema: validationSchema(t, isCommentsRequired),
        onSubmit: async (values) => {
            await handleFormSubmit(values);
        },
    });
    useEffect(() => {
        if (submitCount > 0) {
            const { comments, serviceDate } = errors;
            if (comments) {
                commentsRef?.current?.focus();
            } else if (serviceDate) {
                serviceDateRef?.current?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    useEffect(() => {
        setValues({
            ...initialValues,
            serviceLocationId: selectedCustomer.customer_id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer.customer_id, values.serviceLocationId]);

    useEffect(() => {
        if (errorMessage.show) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [errorMessage]);

    const fetchMediaRequestDeadLineStatus = async () => {
        const preferences = await getPreferences(preferenceLevelTypeId, preferenceTypeId.mediaRequestDeadLine, 1);
        const preferenceValue = preferences[0]?.preference_value;
        setMediaRequestDeadLineStatus({
            show: Boolean(preferenceValue),
            value: preferenceValue,
        });
    };

    const checkCommentsRequired = async () => {
        const preferenceDetails1 = await getPreferencesService("4", [], "");
        const preferenceValue = preferenceDetails1?.preferences.filter((data) => data.preference_type_id === "2");
        setIsCommentsRequired(preferenceValue[0]?.preference_value === "Y");
    };

    const getServiceDates = async () => {
        if (serviceDateRef.current) {
            serviceDateRef.current.refreshServiceDates();
        }
    };

    const onCancel = () => {
        navigate(dashboard);
    };

    const handleValidate = async () => {
        if (openMediaList?.length !== 0 || containerList?.length !== 0) {
            setIsLoading(true);
            const { openMediaSequenceList, openMediaListData } = generateOpenMediaList(openMediaList);
            const containerListData = generateContainerList(containerList);
            const payload = {
                comment: values?.comment,
                openMediaList: openMediaListData,
                openMediaSeqList: openMediaSequenceList,
                containerList: containerListData,
            };
            const validatedResults = await createDeliveryMediaRequestService(payload);
            setOpenMediaTypeList([]);
            setContainerTypeList([]);
            const uniqueValidatedData = validatedResults.data.filter(
                (data) =>
                    !validatedData.some(
                        (item) =>
                            item.media_number === data.media_number &&
                            item.media_program_type === data.media_program_type
                    )
            );
            setValidatedData([...validatedData, ...uniqueValidatedData]);
            setErrorMessage(initialErrorState);
            setIsLoading(false);
        }
    };

    const isScheduleDataLoaded = "customerHasSchedule" in serviceFrequencyValues;

    const generateOpenMediaList = (openMediaValidationData, isSubmit = false) => {
        const openMediaData = openMediaValidationData;
        const openMediaSequenceList = openMediaData
            .map((value) => {
                const sequenceNumber = isSubmit ? value?.entered_sequence : value?.sequenceNumber;
                const paddedSequence = padEndStringWithSelectedCharacters(sequenceNumber.toString(), 4, " ");
                return paddedSequence;
            })
            .join("");
        const openMediaListData = openMediaData
            .map((value) => {
                const mediaNo = isSubmit ? value?.media_number : value?.mediaNumber;
                const paddedValue = padEndStringWithSelectedCharacters(mediaNo, 20, " ");
                return paddedValue;
            })
            .join("");
        return { openMediaSequenceList, openMediaListData };
    };

    const generateContainerList = (containerValidationData, isSubmit = false) => {
        const containerMediaList = containerValidationData;
        const containerListData = containerMediaList
            .map((value) => {
                const sequenceNumber = isSubmit ? value?.entered_sequence : value?.sequenceNumber;
                const paddedSequence = padEndStringWithSelectedCharacters(sequenceNumber.toString(), 4, " ");
                const containerMediaNo = isSubmit ? value?.media_number : value?.mediaNumber;
                const paddedValue = padEndStringWithSelectedCharacters(containerMediaNo, 20, " ");
                return paddedSequence + paddedValue;
            })
            .join("");
        return containerListData;
    };

    const getAddOnCutOffFlag = useMemo(() => {
        let flag = "N";
        if (runDetails) {
            const { noOfServices, addOnCutoffFlag, noOfServicesTom, previousDayFlag } = runDetails;
            const isServiceDateIsCurrentDate = dayjs().isSame(dayjs(values.serviceDate));
            const tomorrowDate = dayjs().add(1, dateTimeUnits.day);
            const isServiceDateIsTomorrow = tomorrowDate.isSame(dayjs(values.serviceDate));

            if (
                (noOfServices >= 1 && addOnCutoffFlag && isServiceDateIsCurrentDate) ||
                (noOfServicesTom >= 1 && previousDayFlag && isServiceDateIsTomorrow)
            ) {
                flag = "Y";
            }
        }
        return flag;
    }, [runDetails, values.serviceDate]);

    const handleFormSubmit = async (values) => {
        closeAlert();
        const openMediaData = validatedData?.filter(
            (validatedData) => validatedData?.media_program_type === deliverMediaStatus?.openMedia
        );
        const containerData = validatedData?.filter(
            (validatedData) => validatedData?.media_program_type === deliverMediaStatus?.container
        );
        const hasValidContainer = validatedData.some(
            (item) => item?.media_program_type === deliverMediaStatus?.container && item.followup_type !== ""
        );
        if (
            !hasValidContainer &&
            validatedData?.length > 0 &&
            openMediaList?.length === 0 &&
            containerList?.length === 0
        ) {
            const { openMediaSequenceList, openMediaListData } = await generateOpenMediaList(openMediaData, true);
            const containerListData = await generateContainerList(containerData, true);
            const payload = {
                serviceDate: formatDate(getdateObject(values?.serviceDate), dateFormats.displayDate),
                validateOnlyFlag: "N",
                openMediaList: openMediaListData,
                openMediaSeqList: openMediaSequenceList,
                containerList: containerListData,
                addonCutoffFlag: getAddOnCutOffFlag,
                comment: values?.comments,
                lastModUser: userDetails?.username,
                cslCustomerId: selectedCustomer.customer_id,
            };
            const validatedList = await createDeliveryMediaRequestService(payload);
            if (validatedList?.data[0]?.hasError) {
                const responseError = validatedList?.resultSet[0];
                if (responseError.error === errorCodes.Code63540) {
                    setValidatedData(validatedList.resultSet);
                    setErrorMessage({
                        show: true,
                        status: "error",
                        message: t("messages.errorsAttemptingToSave"),
                    });
                } else if (responseError.error === errorCodes.Code64030) {
                    setValidatedData(validatedList.resultSet);
                    setErrorMessage({
                        show: true,
                        status: "error",
                        message: t("messages.RequestIncludesInvalidOpenMedia"),
                    });
                } else if (responseError.error === errorCodes.Code63639) {
                    getServiceDates();
                    setErrorMessage({
                        show: true,
                        status: "error",
                        message: t("messages.dateRequestedNoLongerValid"),
                    });
                } else if (responseError.error === errorCodes.Code63752) {
                    getServiceDates();
                    setErrorMessage({
                        show: true,
                        status: "error",
                        message: t("messages.SecureSyncUserAccountNoAccess"),
                    });
                }
            } else {
                handleReset();
                setSuccessModalObj({
                    show: true,
                    values: {
                        ...validatedList?.data[0],
                        serviceDate: values.serviceDate,
                    },
                });
            }
        } else if (hasValidContainer) {
            setErrorMessage({
                show: true,
                status: "error",
                message: t("messages.hasInvalidContainer"),
            });
        } else if (openMediaList.length > 0 || containerList.length > 0) {
            setErrorMessage({
                show: true,
                status: "error",
                message: (
                    <Trans
                        i18nKey={"messages.removeOrValidateAvailableData"}
                        values={{ buttonLabel: t("buttonLabels.validateEnteredMedia") }}
                    />
                ),
            });
        } else {
            setErrorMessage({
                show: true,
                status: "error",
                message: t("messages.pleaseSelectEitherOpenMediaOrContainer"),
            });
        }
    };

    const onSuccessModalClose = () => {
        setSuccessModalObj({ show: false, values: {} });
    };

    const closeAlert = () => {
        setErrorMessage(initialErrorState);
    };

    const DeliverMediaHeader = ({ isLoading }) => {
        return (
            <>
                <CircularLoaderFullPage show={isLoading} />
                <PageTitle>
                    <Trans i18nKey={"titles.deliverMedia"} />
                </PageTitle>
            </>
        );
    };
    if (!isScheduleDataLoaded) {
        return <DeliverMediaHeader isLoading={isLoading || isScheduleServiceLoading} />;
    } else if (!serviceFrequencyValues.customerHasSchedule) {
        return (
            <Box>
                <DeliverMediaHeader isLoading={isLoading || isScheduleServiceLoading} />
                <SecureSyncAlert severity="info" message={t("messages.noScheduleDefine")} />
            </Box>
        );
    }

    const incrementSequenceNumber = (incrementBy) => {
        setSequenceNumber((prevId) => prevId + incrementBy);
    };

    const handleReset = () => {
        resetForm();
        setValidatedData([]);
        setOpenMediaTypeList([]);
        setContainerTypeList([]);
        setSequenceNumber(1);
        getServiceDates();
        setErrorMessage(initialErrorState);
    };

    const downloadInstructionFile = () => {
        setIsLoading(true);
        downloadInstructionGuide(
            staticFile["acceptableFileFormatForImportMediaOnDeliverMediaRequest"],
            importDeliverMediaFileFileName,
            () => {
                setIsLoading(false);
            }
        );
    };

    const serviceDateHandler = async (value) => {
        if (!isTodayServiceDate && dayjs(value).isSame(dayjs(), dateTimeUnits.day)) {
            setErrorMessage({
                show: true,
                status: "error",
                message: t("messages.requestCantMakeToday"),
            });
            setFieldValue("serviceDate", "");
        } else {
            const formattedDate = formatDate(value);
            await setFieldValue("serviceDate", formattedDate);
            setErrorMessage(initialErrorState);
        }
    };

    const printReport = () => {
        setPrintPayload(
            getDeliverMediaPayload({
                requestId: successModalObj.values.request_id,
            })
        );
        printModalOpen(true);
    };

    return (
        <>
            <Box>
                <DeliverMediaHeader
                    isLoading={isLoading || isScheduleServiceLoading || isSubmitting || isServiceDateLoading}
                />
                {mediaRequestDeadLineStatus.show && (
                    <SecureSyncAlert
                        message={t("messages.mediaRequestDeadLineStatus", { status: mediaRequestDeadLineStatus.value })}
                    />
                )}
                {errorMessage.show && errorMessage?.status === "error" && (
                    <SecureSyncAlert
                        severity={"error"}
                        message={errorMessage.message}
                        onClose={() => setErrorMessage(initialErrorState)}
                    />
                )}
                <Typography sx={styles.desc}>
                    {" "}
                    <Trans i18nKey={description} />
                </Typography>
                <Divider />
                <Box>
                    <PageSubTitle>
                        <Trans i18nKey={"titles.deliveryInformation"} />
                    </PageSubTitle>
                    <Box sx={styles.containerWrapper}>
                        <SecureSyncSelect
                            disabled
                            value={serviceLocationOptions.length ? values.serviceLocationId : ""}
                            error={touched.serviceLocationId && Boolean(errors.serviceLocationId)}
                            helperText={touched.serviceLocationId && errors.serviceLocationId}
                            label={t("formFields.serviceLocation")}
                            options={serviceLocationOptions}
                            name="serviceLocationId"
                            onChange={handleChange}
                        />
                        <ServiceDateCalendar
                            runDetails={runDetails}
                            scheduleEndDate={serviceFrequencyValues.scheduleEndDate}
                            setServiceDateLoading={setIsServiceDateLoading}
                            ref={serviceDateRef}
                            label={t("formFields.serviceDate")}
                            value={values.serviceDate}
                            error={touched.serviceDate && Boolean(errors.serviceDate)}
                            helperText={touched.serviceDate && errors.serviceDate}
                            onChange={async (value) => {
                                await serviceDateHandler(value);
                            }}
                            onBlur={handleBlur}
                            onRunDetailsFetch={(data) => setRunDetails(data)}
                            getTodayServiceDate={(data) => setIsTodayServiceDate(data)}
                            inputRef={serviceDateRef}
                        />
                        <Box sx={styles.tooltipSection}>
                            <ServiceDateCalendarTooltip />
                        </Box>
                    </Box>
                    <TextField
                        sx={styles.comments}
                        id="new-container-comments"
                        name="comments"
                        label={t("headerMenu.comments")}
                        variant="outlined"
                        multiline
                        required={isCommentsRequired}
                        fullWidth
                        error={touched.comments && Boolean(errors.comments)}
                        helperText={touched.comments && errors.comments}
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            sx: styles.overFlowAuto,
                        }}
                        inputProps={{ maxLength: commentsMaxLength }}
                        rows={4}
                        inputRef={commentsRef}
                    />
                </Box>
                <Divider />
                <Box sx={styles.bottomPadding}>
                    <PageSubTitle>
                        <Trans i18nKey={"deliverMedia.enterMediaForRequest"} />
                    </PageSubTitle>
                    <Box sx={styles.grid}>
                        <Box sx={styles.bottomRight}>
                            <AddOpenMedia
                                setOpenMediaList={setOpenMediaList}
                                setErrorMessage={setErrorMessage}
                                gridCount={recordCounts.openMediaCount}
                                openMediaTypeList={openMediaTypeList}
                                setOpenMediaTypeList={setOpenMediaTypeList}
                                downloadInstructionFile={downloadInstructionFile}
                                maxContainer={globalAttr?.maxContainer}
                                sequenceNumber={sequenceNumber}
                                incrementSequenceNumber={incrementSequenceNumber}
                                initialErrorState={initialErrorState}
                            />
                        </Box>
                        <Box sx={styles.bottomPadding}>
                            <AddContainers
                                selectedContainer={selectedContainer}
                                allowCustomerMediaIdentifiers={globalAttr?.allowCustomerMediaIdentifiers}
                                setContainerList={setContainerList}
                                setErrorMessage={setErrorMessage}
                                gridCount={recordCounts.containerCount}
                                containerTypeList={containerTypeList}
                                setContainerTypeList={setContainerTypeList}
                                downloadInstructionFile={downloadInstructionFile}
                                maxContainer={globalAttr?.maxContainer}
                                sequenceNumber={sequenceNumber}
                                incrementSequenceNumber={incrementSequenceNumber}
                                initialErrorState={initialErrorState}
                            />
                        </Box>
                    </Box>
                    <Box sx={styles.bottomPadding}>
                        <Button
                            variant="contained"
                            onClick={handleValidate}
                            title={getKeyBoardLabel(shortCutKeys.verify)}
                        >
                            {t("buttonLabels.validateEnteredMedia")}
                        </Button>
                    </Box>
                    <Typography sx={styles.bottomPadding}>
                        <Trans
                            i18nKey={"deliverMedia.ClickValidateEnteredMedia"}
                            values={{ buttonLabel: t("buttonLabels.validateEnteredMedia") }}
                        />
                    </Typography>
                    <MediaRequestedTable
                        validatedData={validatedData}
                        setValidatedData={setValidatedData}
                        recordCounts={recordCounts}
                        getCounts={setRecordCounts}
                        openMediaTypeList={openMediaTypeList}
                        setOpenMediaTypeList={setOpenMediaTypeList}
                        setIsLoading={setIsLoading}
                        incrementSequenceNumber={incrementSequenceNumber}
                        sequenceNumber={sequenceNumber}
                        allowCustomerMediaIdentifiers={globalAttr?.allowCustomerMediaIdentifiers}
                    />
                </Box>
                <Divider />
                <Box sx={styles.buttonContainer}>
                    <Button variant="outlined" onClick={onCancel}>
                        {t("buttonLabels.cancel")}
                    </Button>
                    <Button variant="outlined" onClick={handleReset} title={getKeyBoardLabel(shortCutKeys.reset)}>
                        {t("buttonLabels.reset")}
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} title={getKeyBoardLabel(shortCutKeys.submit)}>
                        {t("buttonLabels.submit")}
                    </Button>
                </Box>
            </Box>
            <DeliverMediaSuccessModal
                values={successModalObj.values}
                open={successModalObj.show}
                onClose={onSuccessModalClose}
                printReport={printReport}
            />
            <SecureSyncPrintModal
                open={isPrintModalOpen}
                payload={printPayload}
                onClose={() => printModalOpen(false)}
                targetReport={targetUrl.deliverMedia}
            />
        </>
    );
};

export default DeliverMedia;
