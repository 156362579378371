import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./ResolveDiscrepanciesModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const ResolveDiscrepanciesModal = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title=""
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {t("buttonLabels.ok")}
                    </Button>
                </Box>
            }
        >
            {t("customerPreferences.noEmailRecipient")}
        </SecureSyncModal>
    );
};

export default ResolveDiscrepanciesModal;
