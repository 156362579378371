export const routePaths = {
    root: "/ss",
    signIn: "/",
    login: "login-check",
    healthCheck: "health",
    dashboard: "dashboard",
    externalResources: "external-resources",
    suggestions: "suggestions",
    processMedia: {
        home: "process-media",
        containerPickup: "container-pickup",
        downloadInventory: "download-inventory",
        omDistributionList: "om-distribution-list",
        scanVerification: "scan-verification",
        sendListFile: "Send-listfile",
    },
    administration: {
        home: "administration",
        authorization: {
            home: "authorization/users",
            addUser: "user",
            editUser: "user/:userId",
            editPin: "user/edit-pin/:userId",
            editAccount: "user/:userId/:accountId",
            comments: "user/comments",
            userVerification: "verification",
        },
        admin: {
            home: "admin",
            editMyAccountInfo: "my-info/:userId/:accountId",
            editInfo: "my-info/:userId",
            editPreference: "user-preference",
            customerPreference: "customer-preference",
            holidayLists: "holidayLists",
            addRetentionProfile: "retention-profile/add",
            retentionProfile: "retention-profile",
        },
    },
    request: {
        home: "request",
        requestDetail: "request/:requestId",
        cancelRequest: "request/cancel-request/:requestId",
        searchMediaDestructionRequest: "destruction-request",
        searchMediaDestructionRequestDetail: "destruction-request/:destructId",
        deliverMedia: "deliver-media",
        newContainer: "new-container",
        newTransport: "new-transport",
        other: "other",
        rescheduleService: "reschedule-Service",
        micsServices: "mics-Services",
    },
    operations: {
        home: "operations",
        research: {
            home: "research",
            completedLibraryAudit: "library-audit",
            completedLibraryAuditResult: "library-audit-result/:id/:completionDate/:auditType",
            correctiveActionRequest: "corrective-action-request",
            searchContainer: "search-container",
            searchOpenMedia: "search-open-media",
            containerDetail: "search-container/:mediaId",
            openMediaDetail: "search-open-media/:mediaId",
            discrepancyManagement: "discrepancy-management",
            vaultStatusReport: "vault-status-report",
            globalMediaLocator: "global-media-locator",
            scanVerificationHistory: "scan-verification-history",
            scanVerificationResultHistory: "scan-verification-result-history/:verificationId",
            correctiveActionsDetails: "corrective-action-request/:carId",
            inboundOutboundReports: "inbound-outbound-reports",
            openMediaExceptionDetails: "open-media-exception-details",
        },
        disasterRecovery: {
            home: "disaster-recovery",
            searchEmployees: "employees",
            detailEmployees: "employee-details/:employeeId",
            drDocument: "drdocuments",
        },
    },
};
