const styles = {
    iconSize: {
        width: 20,
        height: 20,
    },
    alertMessage: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: 1.5,
    },
    alert: {
        my: "16px",
    },
};

export default styles;
