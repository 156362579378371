import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./ApplyPreferencesModal.styles";
import SecureSyncModal from "src/components/common/SecureSyncModal/SecureSyncModal";

const ApplyPreferencesModal = ({ open, handleConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SecureSyncModal
            open={open}
            title={t("buttonLabels.confirm")}
            footer={
                <Box sx={styles.confirmationModalFooter}>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {t("buttonLabels.ok")}
                    </Button>
                </Box>
            }
        >
            <Typography sx={styles.textDescription}>{t("customerPreferences.updateToReflect")}</Typography>
        </SecureSyncModal>
    );
};

export default ApplyPreferencesModal;
