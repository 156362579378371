import { Box, Button, SvgIcon, Typography, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "./SearchContainersTable.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import RemoveRedEyeFilled from "src/components/Icons/RemoveRedEyeFilled/RemoveRedEyeFilled";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import Pencil from "src/components/Icons/Pencil/Pencil";
import SecureSyncAlert from "src/components/common/SecureSyncAlert/SecureSyncAlert";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import { getContainerDetailPath } from "src/constants/routeConstants/operationsRouterFullPaths";
import { updateContainerItem } from "src/services/researchService";
import { convertToLocaleUpperCase, getKeyBoardLabel } from "src/utils/commonHelper";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const SearchContainersTable = ({
    records = [],
    isSearched,
    allowCustomerMediaIdentifiers = false,
    selectRecord,
    openEdit,
    resetTable,
    onTableStateChange,
    selectedRecord,
    printReport,
}) => {
    const shortCutKeys = getShortCutKeys();
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.searchContainersSave, callback: () => saveData() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => resetTable(false) });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.prepareReport,
        callback: () => {
            if (hasRequests) {
                printReport();
            }
        },
    });
    const [error, setError] = useState({
        show: false,
        msg: "",
    });
    const [loading, setLoading] = useState(false);

    const hasRequests = records.length > 0;

    useState(() => {
        setError({
            show: false,
            msg: "",
        });
    }, [records]);

    const isEnableEdit = useMemo(() => {
        return records.some(
            (record) => record.isContainer && (record.isProfileEditable || record.isDescriptionEditable)
        );
    }, [records]);
    const columns = useMemo(
        () => [
            {
                field: "edit",
                headerName: "",
                width: 60,
                sortable: false,
                renderCell: (params) => {
                    const onViewClick = () => {
                        navigate(getContainerDetailPath({ mediaId: params.Container_Id, state: params }), {
                            state: params,
                        });
                    };
                    return (
                        <Box sx={styles.headerActionCol}>
                            {params.isContainer && (params.isProfileEditable || params.isDescriptionEditable) ? (
                                <SecureSyncIconTooltip
                                    icon={
                                        selectedRecord.Number === params.Number ? (
                                            <DoneIcon sx={styles.doneIcon} />
                                        ) : (
                                            <SvgIcon
                                                fill={
                                                    params.Pending_Changes === "1"
                                                        ? theme.palette.im_orange["500"]
                                                        : theme.palette.im_mid_blue["500"]
                                                }
                                                component={Pencil}
                                            />
                                        )
                                    }
                                    onClick={() => {
                                        selectRecord(params);
                                        openEdit();
                                    }}
                                ></SecureSyncIconTooltip>
                            ) : params.toolTipMsg !== " " ? (
                                <SecureSyncIconTooltip
                                    title={
                                        <Typography component={"div"}>
                                            <Box fontSize={12}>{t(params.toolTipMsg)}</Box>
                                        </Typography>
                                    }
                                    buttonSx={styles.tooltipButtonStyle}
                                />
                            ) : (
                                <SecureSyncIconTooltip icon={<></>}></SecureSyncIconTooltip>
                            )}
                            <SecureSyncIconTooltip
                                onClick={onViewClick}
                                icon={<SvgIcon fill={theme.palette.primary.main} component={RemoveRedEyeFilled} />}
                            ></SecureSyncIconTooltip>
                        </Box>
                    );
                },
            },
            {
                field: "Number",
                headerName: convertToLocaleUpperCase(t("tableHeader.containerNumber")) + String.fromCharCode(185),
                sortable: true,
            },
            allowCustomerMediaIdentifiers && {
                field: "Customer_Media_Descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.customerMediaIdentifier")),
                sortable: true,
            },
            {
                field: "Display_Return_Date",
                headerName: convertToLocaleUpperCase(t("tableHeader.returnDateOrCurrentStatus")),
                sortable: true,
            },
            {
                field: "Descr",
                headerName: convertToLocaleUpperCase(t("tableHeader.description")) + String.fromCharCode(178),
                sortable: true,
            },
            {
                field: "Profile_Name",
                headerName: convertToLocaleUpperCase(t("tableHeader.retentionProfile")),
                sortable: true,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allowCustomerMediaIdentifiers, selectedRecord]
    );

    const saveData = async () => {
        setError({
            show: false,
            msg: "",
        });
        setLoading(true);
        const filteredValues = records.filter((e) => e.Pending_Changes === "1");
        const promises = filteredValues.map((record) => {
            return updateContainerItem({
                newReturnDate: record.Report_Return_Date,
                containerId: record.Container_Id,
                newDescription: record.Descr,
                retentionProfileId: record.Retention_Profile_Id,
                containerTimestamp: record.timestamp,
            });
        });
        const results = await Promise.all(promises);
        const failed = results.find((result) => result.failure);
        if (failed) {
            setError({
                show: true,
                msg: t(`apiErrorMessages.${failed.error}`),
            });
        } else {
            resetTable(true);
        }

        setLoading(false);
    };

    return (
        <Box sx={styles.container}>
            <CircularLoaderFullPage show={loading} />
            {error.show && (
                <SecureSyncAlert
                    message={error.msg}
                    severity="error"
                    onClose={() => {
                        setError({
                            show: false,
                            msg: "",
                        });
                    }}
                />
            )}
            {records.length > 0 && (
                <Box sx={styles.editDesc}>
                    {isEnableEdit && (
                        <Typography sx={styles.editDesc}>
                            <Trans
                                i18nKey={"containerSearch.editMsg"}
                                values={{ buttonLabel: t("buttonLabels.save") }}
                            />
                        </Typography>
                    )}
                    <Typography sx={styles.editDescTable}>
                        <Trans i18nKey={"containerSearch.tableTitle"}></Trans>
                    </Typography>
                </Box>
            )}
            <SecureSyncTableCard>
                <Box sx={styles.recordCount}>
                    {hasRequests && (
                        <>
                            <Typography variant="body1">
                                {t("messages.recordsFound", { count: records.length })}
                            </Typography>
                            <Box sx={styles.tableAction}>
                                <Button
                                    variant="outlined"
                                    onClick={printReport}
                                    title={getKeyBoardLabel(shortCutKeys.prepareReport)}
                                >
                                    {t("buttonLabels.prepareReport")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    disabled={!isEnableEdit}
                                    onClick={() => resetTable(false)}
                                    title={getKeyBoardLabel(shortCutKeys.reset)}
                                >
                                    {t("buttonLabels.reset")}
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    disabled={!isEnableEdit}
                                    onClick={saveData}
                                    color="primary"
                                    title={getKeyBoardLabel(shortCutKeys.submit)}
                                >
                                    {t("buttonLabels.save")}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
                <SecureSyncTable
                    rows={records}
                    columns={columns}
                    initialPage={location.state?.pageNumber}
                    initialRowsPerPage={location.state?.rowsPerPage}
                    initialOrderBy={location.state?.orderBy}
                    initialOrder={location.state?.order}
                    noDataMessage={isSearched ? t("containerSearch.noContainers") : t("messages.pleaseProvideTheInput")}
                    getRowId={(row) => row.Request_Id}
                    onTableAttributesChange={onTableStateChange}
                />
            </SecureSyncTableCard>
            <Typography sx={styles.footerCaption}>
                <Trans i18nKey={"containerSearch.footerCaption"}></Trans>
            </Typography>
        </Box>
    );
};

export default SearchContainersTable;
