import { Typography } from "@mui/material";
import styles from "./PageSubTitle.styles";
const PageSubTitle = ({ children, sx }) => {
    return (
        <Typography variant="h5" sx={{ ...styles.typographyHeader, ...sx }}>
            {children}
        </Typography>
    );
};

export default PageSubTitle;
