import { Box } from "@mui/material";
import { useState } from "react";
import { Trans } from "react-i18next";
import SearchMediaDestructionFilter from "src/components/Request/SearchMediaDestruction/SearchMediaDestructionFilter/SearchMediaDestructionFilter";
import SearchMediaDestructionRequestsTable from "src/components/Request/SearchMediaDestruction/SearchMediaDestructionRequestsTable/SearchMediaDestructionRequestsTable";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import PageTitle from "src/components/common/PageTitle/PageTitle";
import { sortHelper } from "src/utils/sortHelper";

const SearchMediaDestructionRequests = () => {
    const [searchMediaDestruction, setSearchMediaDestruction] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({});
    const handleSearchResults = ({ error, result }) => {
        if (!error) {
            setIsSearched(true);
            const sortedRecords = sortHelper({
                data: result,
                primaryProperty: "Destruct_Request_Id",
                sortOrder: "asc",
            });
            setSearchMediaDestruction(sortedRecords);
        } else {
            setIsSearched(false);
            setSearchMediaDestruction([]);
        }
    };

    return (
        <Box>
            <CircularLoaderFullPage />
            <PageTitle>
                <Trans i18nKey={"searchMediaDestructionRequests.searchMediaDestructionRequests"} />
            </PageTitle>
            <SearchMediaDestructionFilter onSearch={handleSearchResults} setSearchCriteria={setSearchCriteria} />
            <SearchMediaDestructionRequestsTable
                records={searchMediaDestruction}
                isSearched={isSearched}
                searchCriteria={searchCriteria}
            />
        </Box>
    );
};

export default SearchMediaDestructionRequests;
