const RetrieveFile = ({ fill, ...props }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.12988 1.00012V9.43036L6.84977 8.15024C6.55977 7.86024 6.07977 7.86024 5.78977 8.15024C5.49977 8.44024 5.49977 8.92024 5.78977 9.21024L8.34977 11.7702C8.49977 11.9202 8.68977 11.9902 8.87977 11.9902C9.06977 11.9902 9.25977 11.9202 9.40977 11.7702L11.9698 9.21024C12.2598 8.92024 12.2598 8.44024 11.9698 8.15024C11.6798 7.86024 11.1998 7.86024 10.9098 8.15024L9.62988 9.43012V1.00012C9.62988 0.590122 9.28988 0.250122 8.87988 0.250122C8.46988 0.250122 8.12988 0.590122 8.12988 1.00012Z"
                fill={fill}
            />
            <path
                d="M0.25 9.18005C0.25 14.3301 3.85 17.9301 9 17.9301C14.15 17.9301 17.75 14.3301 17.75 9.18005C17.75 8.77005 17.41 8.43005 17 8.43005C16.59 8.43005 16.25 8.77005 16.25 9.18005C16.25 13.4501 13.27 16.4301 9 16.4301C4.73 16.4301 1.75 13.4501 1.75 9.18005C1.75 8.77005 1.41 8.43005 1 8.43005C0.59 8.43005 0.25 8.77005 0.25 9.18005Z"
                fill={fill}
            />
        </svg>
    );
};

export default RetrieveFile;
