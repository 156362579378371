/* eslint-disable max-lines */
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../EditAccountForm/ConfirmationModal/ConfirmationModal";
import CustomerList from "../CustomerList/CustomerList";
import styles from "./AddUserForm.styles";
import CircularLoaderFullPage from "src/components/common/CircularLoaderFullPage/CircularLoaderFullPage";
import {
    getAuthInfo,
    getPersonalAuthService,
    userAlreadyExist,
    validatePhoneFormat,
} from "src/services/authorizationService";
import { authLevelCode } from "src/constants/autherizationConstants/authConstants";
import CountriesList from "src/components/common/CountriesList/CountriesList";
import { addNewPersonService } from "src/services/userServices";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { dashboard } from "src/constants/routeConstants/rootRouterFullPaths";
import { getSelectedCustomer, getUserPreferences } from "src/redux/userDetailsSlice";
import { checkAuthLevelValidity, downloadFileFromFB } from "src/services/utils";
import SecureSyncCheckBox from "src/components/common/SecureSyncCheckBox/SecureSyncCheckBox";
import generateFilePathForCurrentLocale from "src/utils/generateFilePathForCurrentLocale";
import { staticFile } from "src/constants/fileConstants";
import SecureSyncIconTooltip from "src/components/common/SecureSyncIconTooltip/SecureSyncIconTooltip";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getDrLevel, getIntAuth, getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const validationSchema = (t) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(t("authorization.firstNameValidation"))
            .max(30, t("authorization.firstNameValidation")),
        lastName: Yup.string()
            .required(t("authorization.lastNameValidation"))
            .max(30, t("authorization.firstNameValidation")),
        middleName: Yup.string().max(30, t("authorization.middleNameValidation")),
        businessEmail: Yup.string()
            .required(t("authorization.mailValidation"))
            .matches(/^\S+@\S+\.\S+$/, t("authorization.mailValidation")),
        contactInfoCountryCode: Yup.string().required(t("authorization.accountCodeValidation")),
        authorizationLevel: Yup.object()
            .shape({
                AReleaseMedia: Yup.boolean(),
                BReceiveRequestMedia: Yup.boolean(),
                CAccessIronMountainFacilities: Yup.boolean(),
                DPlaceEmergencyRequests: Yup.boolean(),
                EModifyAuthorizations: Yup.boolean(),
                RReceiveOnlyCannotRequestMedia: Yup.boolean(),
                ConfirmMediaToIncludeInDR: Yup.boolean(),
                ManageDRDocuments: Yup.boolean(),
                DeclareVerifyADR: Yup.boolean(),
                ReviseDRAuthorization: Yup.boolean(),
            })
            .test(t("authorization.authValidation"), (value) => Object.values(value).some((val) => val === true)),
        accountInfoCountryCode: Yup.string().required(t("authorization.accountCodeValidation")),
        businessPhone: Yup.string().required(t("authorization.phoneValidation")),
        selectedCustomers: Yup.array().min(1, t("authorization.customerValidation")),
    });

const formIntValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    contactInfoCountryCode: "1",
    homePhone: "",
    cellular: "",
    businessEmail: "",
    homeEmail: "",
    pager: "",
    pagerPin: "",
    authorizationLevel: {
        AReleaseMedia: false,
        BReceiveRequestMedia: false,
        CAccessIronMountainFacilities: false,
        DPlaceEmergencyRequests: false,
        EModifyAuthorizations: false,
        RReceiveOnlyCannotRequestMedia: false,
        ConfirmMediaToIncludeInDR: false,
        ManageDRDocuments: false,
        DeclareVerifyADR: false,
        ReviseDRAuthorization: false,
    },
    accountInfoCountryCode: "1",
    extension: "",
    businessPhone: "",
    businessFax: "",
    customerEmployeeNo: "",
    enableSecureSync: false,
    selectedCustomers: [],
};
//extension businessPhone businessFax customerEmployeeNo
const AddUserForm = (props) => {
    const shortCutKeys = getShortCutKeys();
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.submit,
        callback: () => {
            handleSubmit();
        },
    });
    useSecureSyncHotKeys({
        hotkeys: shortCutKeys.clear,
        callback: () => {
            clearReset();
        },
    });
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const businessEmailRef = useRef(null);
    const authorizationLevelRef = useRef(null);
    const cellPhoneRef = useRef(null);
    const homePhoneRef = useRef(null);
    const pagerPhoneRef = useRef(null);
    const faxRef = useRef(null);
    const businessPhoneRef = useRef(null);
    const drLevel = getDrLevel();
    const intAuth = getIntAuth();
    const [isLoading, setLoading] = useState(false);
    const [activeCustomerList, setActiveCustomerList] = useState([]);
    const [selectedCustomerHasIntPermission, setSelectedCustomerIntPermission] = useState(false);
    const [selectedCustomerHasPermission, setSelectedCustomerPermission] = useState(false);
    const [confirmMultipleAccountModal, setMultipleAccountSelect] = useState(false);
    const [initialCustomerSelections, setInitialCustomerSelections] = useState([]);
    const [customValidations, setCustomValidations] = useState({
        cellPhone: { validate: true, message: "" },
        homePhone: { validate: true, message: "" },
        businessPhone: { validate: true, message: "" },
        fax: { validate: true, message: "" },
        userName: { validate: true, message: "" },
        authLevelNotMatched: { validate: true, message: "" },
        pager: { validate: true, message: "" },
    });

    const clearSelectionRef = useRef(null);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const userPreference = useSelector(getUserPreferences);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        fetchAuthInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedCustomer?.customer_id) {
            setValues({
                ...values,
                selectedCustomers: [selectedCustomer.customer_id],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, resetForm, submitCount } =
        useFormik({
            initialValues: formIntValues,
            validationSchema: validationSchema(t),
            onSubmit: (values) => {
                formSubmitPostCheck();
            },
        });

    useEffect(() => {
        if (submitCount > 0) {
            const { authorizationLevel, businessEmail, businessPhone, firstName, lastName } = errors;
            if (firstName) {
                firstNameRef.current?.focus();
            } else if (lastName) {
                lastNameRef.current?.focus();
            } else if (businessEmail) {
                businessEmailRef.current?.focus();
            } else if (authorizationLevel) {
                const firstCheckbox = authorizationLevelRef.current.querySelector('input[type="checkbox"]');
                if (firstCheckbox) {
                    firstCheckbox.focus();
                }
            } else if (businessPhone) {
                businessPhoneRef.current?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    const formSubmitPostCheck = async () => {
        props.cleanMsg();
        if (values.selectedCustomers.length > 1) {
            setMultipleAccountSelect(true);
        } else {
            triggerSubmit();
        }
    };

    const triggerSubmit = async () => {
        setLoading(true);
        await formSubmit();
        setLoading(false);
    };
    const handleClearSelection = () => {
        if (clearSelectionRef.current) {
            clearSelectionRef.current.onClearSelection();
        }
    };

    const formSubmit = async () => {
        try {
            const [
                validateFaxPhone,
                validateBusinessPhone,
                validateCellPhone,
                validateHomePhone,
                validatePagerPhone,
                validateName,
                validateAuthLevel,
            ] = await Promise.all([
                validatePhoneNumber("fax", values.businessFax, t("authorization.businessFax")),
                validatePhoneNumber("businessPhone", values.businessPhone, t("authorization.businessPhone")),
                validatePhoneNumber("cellPhone", values.cellular, t("authorization.celluler")),
                validatePhoneNumber("homePhone", values.homePhone, t("authorization.homePhone")),
                validatePhoneNumber("pager", values.pager, t("authorization.pager")),
                validateUserName(),
                checkAuthLevelValidity(values),
            ]);
            setCustomValidations((prevState) => ({
                ...prevState,
                authLevelNotMatched: validateAuthLevel,
            }));
            if (
                !validateBusinessPhone ||
                !validateCellPhone ||
                !validateHomePhone ||
                !validateFaxPhone ||
                !validateName ||
                !validatePagerPhone ||
                !validateAuthLevel.validate
            ) {
                if (!validateName) {
                    firstNameRef.current?.focus();
                } else if (!validateFaxPhone) {
                    faxRef.current?.focus();
                } else if (!validateHomePhone) {
                    homePhoneRef.current?.focus();
                } else if (!validateCellPhone) {
                    cellPhoneRef.current?.focus();
                } else if (!validateBusinessPhone) {
                    businessPhoneRef.current?.focus();
                } else if (!validatePagerPhone) {
                    pagerPhoneRef.current?.focus();
                }
                if (!validateAuthLevel.validate) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
                return;
            }

            const permission = {
                canEditDrAuth: selectedCustomerHasPermission,
                canEditInt: selectedCustomerHasIntPermission,
            };
            const res = await addNewPersonService(values, permission);
            props.openSuccessMsg(res);
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (!res.error) {
                clearReset();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const validatePhoneNumber = async (targetField, phoneNumber, label) => {
        let isValid = true;
        let msg = "";
        if (phoneNumber !== "") {
            const res = await validatePhoneFormat(
                { [targetField]: phoneNumber },
                { personnel: values.contactInfoCountryCode, business: values.accountInfoCountryCode }
            );
            if (res.failure && res.errorCode === "1") {
                isValid = false;
                msg = t("authorization.phoneServiceValidation", {
                    label: label,
                    format: res.data.bad_format_message,
                });
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            [targetField]: { validate: isValid, message: msg },
        }));
        return isValid;
    };

    const clearReset = async () => {
        handleClearSelection();
        resetForm();
        const { Customer_Locale_Id: customerLocaleId = "", District_Locale_Id: districtLocaleId = "" } =
            userPreference?.data?.personnelCustomerPreference || {};
        const defaultCountry = customerLocaleId ? customerLocaleId : districtLocaleId;

        setValues({
            ...formIntValues,
            selectedCustomers: selectedCustomer?.customer_id ? [selectedCustomer.customer_id] : [],
            contactInfoCountryCode: defaultCountry || "1",
            accountInfoCountryCode: defaultCountry || "1",
        });
    };

    const onAuthorizationFormDownload = async () => {
        setLoading(true);
        const bucketPath = `gs://${process.env.REACT_APP_FB_STORAGE_BUCKET_STATIC}`;

        const file = generateFilePathForCurrentLocale(bucketPath, staticFile["authLevel"]);

        await downloadFileFromFB({
            fbFilePath: file,
            fileName: "AuthorizationLevels.pdf",
            onComplete: () => setLoading(false),
        });
    };

    const validateUserName = async () => {
        const { firstName, lastName, middleName } = values;
        let isValid = true;
        let msg = "";
        if (firstName !== "" && lastName !== "") {
            /* eslint-disable camelcase */
            try {
                const userValidate = await userAlreadyExist({ firstName, lastName, middleName });
                if (userValidate?.isUserNameTaken) {
                    const fullUserName = userValidate?.details?.personnel_name
                        ? userValidate.details.personnel_name
                        : "";
                    const associateCustomer = userValidate?.details?.customer_number
                        ? userValidate?.details.customer_number
                        : "";
                    msg = t("authorization.userNameTaken", {
                        name: fullUserName,
                        associateCustomer: associateCustomer,
                    });
                    isValid = false;
                } else {
                    isValid = true;
                }
            } catch (error) {
                return error;
            }
        }
        setCustomValidations((prevState) => ({
            ...prevState,
            userName: { validate: isValid, message: msg },
        }));

        return isValid;
    };

    const fetchAuthInfo = async () => {
        setLoading(true);
        const res = await getAuthInfo();
        const tempRows = [];
        const intSelection = [];
        const personalAuth = await getPersonalAuthService({
            activeOnlyFlag: "N",
        });

        const selectedCustomerHasIntPermission = personalAuth?.authData?.edit_int_auth_flag === "Y";
        const selectedCustomerHasPermission = personalAuth?.authData?.edit_dr_auth_flag === "Y";
        if (res.authList && res.authList.length !== 0) {
            const promises = res.authList.map(async (customer, index) => {
                const editIntPermission = customer?.edit_int_flag === "Y";
                const editPermission = customer?.edit_permission_flag === "Y";
                if (customer.customer_id === selectedCustomer.customer_id) {
                    intSelection.push(index + 1);
                }
                const rowObj = {
                    id: index + 1,
                    account: customer.customer_id,
                    customerNumber: customer.customer_number,
                    name: customer.customer_name,
                    branch: customer.branch_name,
                    interactionAuth: authLevelCode.none,
                    drAuth: authLevelCode.none,
                    editIntPermission: editIntPermission,
                    editPermission: editPermission,
                };
                if (editIntPermission || editPermission) {
                    tempRows.push(rowObj);
                }
            });
            await Promise.all(promises);
        }

        setSelectedCustomerIntPermission(selectedCustomerHasIntPermission);
        setSelectedCustomerPermission(selectedCustomerHasPermission);
        setInitialCustomerSelections(intSelection);
        setActiveCustomerList(tempRows);
        const { Customer_Locale_Id: customerLocaleId = "", District_Locale_Id: districtLocaleId = "" } =
            userPreference?.data?.personnelCustomerPreference || {};
        const defaultCountry = customerLocaleId ? customerLocaleId : districtLocaleId;
        setValues({
            ...values,
            selectedCustomers: [selectedCustomer.customer_id],
            contactInfoCountryCode: defaultCountry || "1",
            accountInfoCountryCode: defaultCountry || "1",
        });
        setLoading(false);
    };

    const onClickRowSelect = (event) => {
        const selectedCustomersTemp = [];
        event.forEach((el) => {
            const selectedRow = activeCustomerList.find((e) => e.id === el);
            selectedCustomersTemp.push(selectedRow.account);
        });
        setValues({
            ...values,
            selectedCustomers: selectedCustomersTemp,
        });
    };

    const navigateToHome = () => {
        navigate(dashboard);
    };

    return (
        <>
            <CircularLoaderFullPage show={isLoading} />
            <form noValidate onSubmit={handleSubmit}>
                <PageSubTitle>{t("authorization.formName")}</PageSubTitle>
                {!customValidations.userName.validate && (
                    <Typography color="error" sx={styles.typographyError}>
                        {customValidations.userName.message}
                    </Typography>
                )}
                <Box display="flex" gap={2} sx={styles.boxPaddingBottom}>
                    <TextField
                        label={t("formFields.firstName")}
                        name="firstName"
                        variant="outlined"
                        required
                        inputProps={{ maxLength: 30 }}
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        fullWidth
                        inputRef={firstNameRef}
                    />
                    <TextField
                        label={t("authorization.middleName")}
                        name="middleName"
                        variant="outlined"
                        inputProps={{ maxLength: 1 }}
                        onBlur={handleBlur}
                        value={values.middleName}
                        onChange={handleChange}
                        error={touched.middleName && Boolean(errors.middleName)}
                        helperText={touched.middleName && errors.middleName}
                        fullWidth
                    />
                    <TextField
                        label={t("formFields.lastName")}
                        variant="outlined"
                        name="lastName"
                        required
                        inputProps={{ maxLength: 30 }}
                        onBlur={handleBlur}
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        fullWidth
                        inputRef={lastNameRef}
                    />
                    <div style={styles.flexFormControl} />
                </Box>
                <Divider />
                <PageSubTitle>{t("authorization.contactInfo")}</PageSubTitle>
                <Box display="flex" flexWrap="wrap" gap={"20px"} sx={styles.boxPaddingBottom}>
                    <TextField
                        label={t("formFields.businessEmail")}
                        name="businessEmail"
                        variant="outlined"
                        value={values.businessEmail}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.businessEmail && Boolean(errors.businessEmail)}
                        helperText={touched.businessEmail && errors.businessEmail}
                        inputRef={businessEmailRef}
                        fullWidth
                    />
                    <FormControl sx={styles.formControlStyle}>
                        <CountriesList
                            id="country-code-select"
                            label={t("authorization.countryPhoneCode")}
                            name="contactInfoCountryCode"
                            value={values.contactInfoCountryCode}
                            onChangeCallBack={(item) => {
                                values.contactInfoCountryCode = item;
                            }}
                            onBlur={handleBlur}
                            error={touched.contactInfoCountryCode && Boolean(errors.contactInfoCountryCode)}
                            helperText={touched.contactInfoCountryCode && errors.contactInfoCountryCode}
                        />
                    </FormControl>
                    <TextField
                        label={t("authorization.homePhone")}
                        name="homePhone"
                        variant="outlined"
                        value={values.homePhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                            touched.homePhone && (Boolean(errors.homePhone) || !customValidations.homePhone.validate)
                        }
                        helperText={touched.homePhone && (errors.homePhone || customValidations.homePhone.message)}
                        fullWidth
                        inputRef={homePhoneRef}
                    />
                    <TextField
                        label={t("authorization.celluler")}
                        name="cellular"
                        variant="outlined"
                        value={values.cellular}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cellular && (Boolean(errors.cellular) || !customValidations.cellPhone.validate)}
                        helperText={touched.cellular && (errors.cellular || customValidations.cellPhone.message)}
                        fullWidth
                        inputRef={cellPhoneRef}
                    />
                    <TextField
                        label={t("authorization.homeEmail")}
                        name="homeEmail"
                        variant="outlined"
                        value={values.homeEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <TextField
                        label={t("authorization.pager")}
                        name="pager"
                        variant="outlined"
                        value={values.pager}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.pager && (Boolean(errors.pager) || !customValidations.pager.validate)}
                        helperText={touched.pager && (errors.pager || customValidations.pager.message)}
                        inputRef={pagerPhoneRef}
                        fullWidth
                    />
                    <TextField
                        label={t("authorization.pagerPin")}
                        name="pagerPin"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        value={values.pagerPin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={styles.toggleButton}
                >
                    <Box>
                        <FormControlLabel
                            control={<Switch />}
                            label={t("authorization.secureSyncUserEnable")}
                            checked={values.enableSecureSync}
                            onChange={handleChange}
                            name="enableSecureSync"
                        />
                        <Typography variant="body1" color="#252526" component="div" sx={styles.toggleTypography}>
                            {t("authorization.ssToggleOn")} <br />
                            {t("authorization.ssToggleOf")}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" gap={2}>
                    <PageSubTitle>{t("authorization.authLevel")}</PageSubTitle>
                    <SecureSyncIconTooltip
                        onClick={onAuthorizationFormDownload}
                        title={t("authorization.authHelperToolTip")}
                        placement="right"
                    />
                </Box>
                {touched.authorizationLevel && errors.authorizationLevel && (
                    <Typography color="error" sx={styles.authLevelHeaderTypography}>
                        {t("authorization.authValidation")}
                    </Typography>
                )}
                {customValidations.authLevelNotMatched.message && (
                    <Typography color="error" sx={styles.authLevelText}>
                        {t("authorization.authLevelNotMatched")}
                    </Typography>
                )}
                <Box sx={styles.flexBoxPaddingBottom}>
                    <FormControl component="fieldset" variant="standard" sx={styles.flexFormControl}>
                        <FormLabel required>{t("authorization.intAuth")}</FormLabel>
                        <FormGroup ref={authorizationLevelRef} sx={styles.leftPaddingGroup}>
                            {intAuth.map((e) => (
                                <>
                                    <SecureSyncCheckBox
                                        disabled={!selectedCustomerHasIntPermission}
                                        label={e.label}
                                        name={`authorizationLevel.${e.value}`}
                                        checked={values.authorizationLevel[e.value]}
                                        onChange={handleChange}
                                    />
                                </>
                            ))}
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard" sx={styles.flexFormControl}>
                        <FormLabel required>{t("authorization.drAuth")}</FormLabel>
                        <FormGroup sx={styles.leftPaddingGroup}>
                            {drLevel.map((e) => (
                                <>
                                    <SecureSyncCheckBox
                                        disabled={!selectedCustomerHasPermission}
                                        label={e.label}
                                        name={`authorizationLevel.${e.value}`}
                                        checked={values.authorizationLevel[e.value]}
                                        onChange={handleChange}
                                    />
                                </>
                            ))}
                        </FormGroup>
                        {touched.DRA && errors.DRA && <Typography color="error">{errors.DRA}</Typography>}
                    </FormControl>
                </Box>
                <Divider />
                <PageSubTitle>{t("authorization.accountInfo")}</PageSubTitle>
                <Box display="flex" flexWrap="wrap" gap={2} sx={styles.boxPaddingBottom}>
                    <FormControl sx={styles.formControlStyle}>
                        <CountriesList
                            id="country-code-select"
                            label={t("authorization.countryPhoneCode")}
                            name="accountInfoCountryCode"
                            value={values.accountInfoCountryCode}
                            onChangeCallBack={(item) => {
                                values.accountInfoCountryCode = item;
                            }}
                            onBlur={handleBlur}
                            error={touched.accountInfoCountryCode && Boolean(errors.accountInfoCountryCode)}
                            helperText={touched.accountInfoCountryCode && errors.accountInfoCountryCode}
                        />
                    </FormControl>
                    <TextField
                        label={t("authorization.businessPhone")}
                        name="businessPhone"
                        variant="outlined"
                        value={values.businessPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputRef={businessPhoneRef}
                        error={
                            touched.businessPhone &&
                            (Boolean(errors.businessPhone) || !customValidations.businessPhone.validate)
                        }
                        required
                        helperText={
                            touched.businessPhone && (errors.businessPhone || customValidations.businessPhone.message)
                        }
                        fullWidth
                    />
                    <TextField
                        label={t("authorization.extension")}
                        name="extension"
                        variant="outlined"
                        value={values.extension}
                        inputProps={{ maxLength: 6 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />

                    <TextField
                        label={t("authorization.businessFax")}
                        name="businessFax"
                        variant="outlined"
                        value={values.businessFax}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.businessFax && (Boolean(errors.businessFax) || !customValidations.fax.validate)}
                        helperText={touched.businessFax && (errors.businessFax || customValidations.fax.message)}
                        fullWidth
                        inputRef={faxRef}
                    />
                    <TextField
                        inputProps={{ maxLength: 15 }}
                        label={t("authorization.empNo")}
                        name="customerEmployeeNo"
                        variant="outlined"
                        value={values.customerEmployeeNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Box>
                <Divider />
                {activeCustomerList && activeCustomerList.length > 1 && (
                    <>
                        <CustomerList
                            touched={touched.selectedCustomers}
                            error={errors.selectedCustomers}
                            onClickRowSelect={onClickRowSelect}
                            rows={activeCustomerList}
                            clearSelectionRef={clearSelectionRef}
                            initialValues={initialCustomerSelections}
                        />
                    </>
                )}
                <Divider />
                <Box display="flex" gap={2.5} sx={styles.actionSection}>
                    <Button id="cancel-button" variant="outlined" onClick={navigateToHome} color="primary">
                        {t("buttonLabels.cancel")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.clear)}
                        id="clear-button"
                        variant="outlined"
                        color="primary"
                        onClick={clearReset}
                    >
                        {t("buttonLabels.clear")}
                    </Button>
                    <Button
                        title={getKeyBoardLabel(shortCutKeys.submit)}
                        id="submit-button"
                        variant="contained"
                        type="submit"
                        color="primary"
                    >
                        {t("common.submit")}
                    </Button>
                </Box>
            </form>
            <ConfirmationModal
                count={values?.selectedCustomers?.length}
                handleConfirm={() => {
                    setMultipleAccountSelect();
                    triggerSubmit();
                }}
                onClose={() => {
                    setMultipleAccountSelect(false);
                }}
                open={confirmMultipleAccountModal}
            />
        </>
    );
};

export default AddUserForm;
