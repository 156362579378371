/* eslint-disable camelcase */
import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./EmptyTransports.styles";
import SecureSyncTable from "src/components/common/SecureSyncTable/SecureSyncTable";
import SecureSyncTableCard from "src/components/common/SecureSyncTableCard/SecureSyncTableCard";
import PageSubTitle from "src/components/common/PageSubTitle/PageSubTitle";
import { convertToLocaleUpperCase } from "src/utils/commonHelper";

const EmptyTransports = ({ transportDetails }) => {
    const { t } = useTranslation();
    const noDataMsg = (
        <Trans i18nKey={"messages.pleaseEnterUserSearchCriteria"} values={{ buttonLabel: t("buttonLabels.findAll") }} />
    );
    const columns = useMemo(() => {
        const baseColumns = [
            { field: "media_type_descr", headerName: convertToLocaleUpperCase(t("tableHeader.transportType")) },
            { field: "inserts", headerName: convertToLocaleUpperCase(t("tableHeader.insertConfiguration")) },
            { field: "quantity", headerName: convertToLocaleUpperCase(t("tableHeader.transportQuantity")) },
        ];
        return baseColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Box sx={styles.container}>
                <PageSubTitle>{t("requestDetails.emptyTransports")}</PageSubTitle>
                <SecureSyncTableCard>
                    <Box sx={styles.recordCount}>
                        <Typography variant="h6" sx={styles.heading}>
                            {t("requestDetails.deliverTransportInfo")}
                        </Typography>
                        {transportDetails.length > 0 && (
                            <Box>
                                <Typography variant="body1">
                                    {t("messages.recordsFound", { count: transportDetails.length })}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={styles.cardContent}>
                        <SecureSyncTable
                            rows={transportDetails}
                            columns={columns}
                            noDataMessage={transportDetails.length === 0 ? noDataMsg : t("messages.noMatchingRecords")}
                        />
                    </Box>
                </SecureSyncTableCard>
            </Box>
            <Divider />
        </>
    );
};

export default EmptyTransports;
