const styles = {
    confirmationModalFooter: {
        p: 1,
        display: "flex",
        justifyContent: "left",
        width: "100%",
        gap: "20px",
    },
    timer: { color: "im_red.500", display: "flex", justifyContent: "center" },
    desc: { fontSize: "14px" },
    descWrapper: { display: "flex", gap: "20px" },
};

export default styles;
