const styles = {
    iconComponent: {
        width: 24,
        height: 24,
        padding: "8px 5px 8px 5px",
        transition: "none",
        transform: "none",
    },
    selectField: {
        width: "100%",
        paddingRight: "12px",
    },
    displayNone: { display: "none" },
    displayBlock: { display: "block" },
    formControlWidth: (width) => ({
        width: width || "100%",
    }),
    emptyOption: {
        height: 32,
    },
};

export default styles;
