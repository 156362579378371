import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./MiscServicesActionSection.styles";
import { useSecureSyncHotKeys } from "src/customHooks";
import { getKeyBoardLabel } from "src/utils/commonHelper";
import { getShortCutKeys } from "src/constants/shortCutKeys";

const MiscServicesActionSection = (props) => {
    const shortCutKeys = getShortCutKeys();
    const { t } = useTranslation();
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.reset, callback: () => props.resetForm() });
    useSecureSyncHotKeys({ hotkeys: shortCutKeys.submit, callback: () => props.handleSubmit() });
    return (
        <Box display="flex" gap={2.5} sx={styles.actionSection}>
            <Button
                id="clear-button"
                variant="outlined"
                onClick={props.resetForm}
                color="primary"
                title={getKeyBoardLabel(shortCutKeys.reset)}
            >
                {t("buttonLabels.reset")}
            </Button>
            <Button id="submit-button" variant="contained" type="submit" title={getKeyBoardLabel(shortCutKeys.submit)}>
                {t("common.submit")}
            </Button>
        </Box>
    );
};

export default MiscServicesActionSection;
