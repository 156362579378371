const styles = {
    messageSection: {
        fontSize: "18px",
        my: "24px",
    },
    container: {
        my: 8,
        textAlign: "center",
    },
    HomeBtn: {
        mt: 1,
    },
};

export default styles;
