const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    reqDetailsContainer: {
        paddingTop: "20px",
    },
    reqDetailRow: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
    },
    rowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
        paddingBottom: "20px",
    },
    twoGrid: {
        gridColumn: "span 2",
    },
};

export default styles;
