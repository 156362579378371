const styles = {
    textContent: {
        fontWeight: "bold",
    },
    searchFields: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "20px",
        mb: "20px",
    },
    gridSpan2: { gridColumn: "span 2" },
    locksFieldWrapper: { gridColumn: "1 / -1", display: "grid", gridTemplateColumns: "auto 1fr" },
    locksLabel: { display: "flex", alignItems: "center" },
    locksCaption: { gridColumnStart: 2 },
    footerButtons: { display: "flex", gap: "20px", mb: "20px" },
    accordionSection: { mb: "20px" },
};

export default styles;
