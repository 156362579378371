import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStatus } from "src/constants/fetchStatusConstants";
import {
    getBasicUserInfo,
    isUserCollectionLoaded,
    fetchUserPreferences,
    getSelectedCustomer,
    getUserPreferences,
} from "src/redux/userDetailsSlice";

const useFetchUserPreferences = () => {
    const selectedCustomer = useSelector(getSelectedCustomer);
    const { status } = useSelector(getUserPreferences);
    const isUserDataLoaded = useSelector(isUserCollectionLoaded);
    const { username, sys_user_id: userId } = useSelector(getBasicUserInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isUserDataLoaded) {
            if (selectedCustomer) {
                dispatch(
                    fetchUserPreferences({
                        userName: username,
                        userId,
                        customerNumber: selectedCustomer.customer_number,
                        customerId: selectedCustomer.customer_id,
                        districtId: selectedCustomer.district_id,
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserDataLoaded, selectedCustomer, username, userId]);
    return { isLoading: status === fetchStatus.loading };
};

export default useFetchUserPreferences;
