/* eslint-disable camelcase */
export const programIds = Object.freeze({
    programId_100: "100",
    programId_101: "101",
    programId_103: "103",
    programId_104: "104",
    programId_105: "105",
    programId_106: "106",
    programId_107: "107",
    programId_108: "108",
    programId_116: "116",
    programId_117: "117",
    programId_128: "128",
    programId_133: "133",
    programId_145: "145",
    programId_146: "146",
    programId_147: "147",
    programId_149: "149",
    programId_150: "150",
    programId_151: "151",
    programId_154: "154",
    programId_207: "207",
    programId_208: "208",
    programId_209: "209",
    programId_211: "211",
    programId_213: "213",
    programId_214: "214",
    programId_215: "215",
    programId_216: "216",
    programId_219: "219",
    programId_220: "220",
    programId_223: "223",
    programId_224: "224",
    programId_228: "228",
    programId_229: "229",
    programId_230: "230",
    programId_232: "232",
    programId_233: "233",
    programId_234: "234",
    programId_235: "235",
    programId_236: "236",
    programId_237: "237",
    programId_238: "238",
    programId_240: "240",
    programId_245: "245",
    programId_251: "251",
    programId_269: "269",
    programId_270: "270",
    programId_271: "271",
    programId_272: "272",
    programId_273: "273",
    programId_274: "274",
    programId_275: "275",
    programId_292: "292",
    programId_293: "293",
    programId_329: "329",
    programId_330: "330",
    programId_331: "331",
    programId_352: "352",
});

export const menuProgramIdsGroup = Object.freeze({
    dashboard: [],
    processMedia: [
        programIds.programId_211,
        programIds.programId_214,
        programIds.programId_213,
        programIds.programId_215,
        programIds.programId_230,
    ],
    authorization: [
        programIds.programId_100,
        programIds.programId_101,
        programIds.programId_103,
        programIds.programId_104,
        programIds.programId_105,
        programIds.programId_106,
        programIds.programId_108,
        programIds.programId_107,
        programIds.programId_117,
        programIds.programId_128,
        programIds.programId_219,
        programIds.programId_220,
    ],
    admin: [
        programIds.programId_234,
        programIds.programId_235,
        programIds.programId_236,
        programIds.programId_270,
        programIds.programId_269,
    ],
    request: [
        programIds.programId_147,
        programIds.programId_292,
        programIds.programId_149,
        programIds.programId_146,
        programIds.programId_150,
        programIds.programId_151,
        programIds.programId_145,
        programIds.programId_207,
    ],
    research: [
        programIds.programId_208,
        programIds.programId_116,
        programIds.programId_154,
        programIds.programId_209,
        programIds.programId_329,
        programIds.programId_330,
        programIds.programId_331,
        programIds.programId_224,
        programIds.programId_232,
        programIds.programId_233,
    ],
    disasterRecovery: [programIds.programId_133, programIds.programId_228],
});
